import React, { useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { routes } from "../../routes";
import { useDispatch, useSelector } from "react-redux";
import { showGlobalLoading } from "./../../actions/uiActions";
import { Session } from "../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";

//API
import { gql } from "apollo-boost";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import DisplayMenuSidebar from "../../hooks/Utils/DisplayMenuSidebar";
import { pathsWithoutChainOrProjectRef, HOTEL } from "hooks/Utils/Utils";

export default function Sidebar() {
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const isLoggedIn = Session.getSessionProp("token");
    const chainRef = Session.getSessionProp("chainRef");
    const currentHotelData = Session.getSessionProp("currentHotel");
    const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};
    const refSidebar = React.useRef();
    const sidebarOpen = useSelector((state) => state.ui.sidebar);
    const { permissions } = useSelector((state) => state.ui);
    const { ref } = useSelector((state) => state.hotel);
    const [visible, setVisible] = useState(false);
    const [wifiManagerConfig, setWifiManagerConfig] = useState(null);
    const [openLinkWifi, setOpenLinkWifi] = useState(false);
    const [applyWifiFilters, setApplyWifiFilters] = useState("");
    const [hideLinks, setHideLinks] = useState(false);

    const currentLang = i18n.language.split("-")[0];
    const hasCorporateUser =
        Session.getSessionProp("hasCorporateUser") && Session.getSessionProp("hasCorporateUser") !== "false";
    const menusDisplay = DisplayMenuSidebar();
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const isCorporate = Session.getSessionProp("projectRef") === "CORPORATE";
    const hasWiFiLink = permissions?.legacy?.wifi && (isCorporate || wifiManagerConfig?.enabled);
    const hasAdvancedConfigLink =
        permissions?.legacy?.tv ||
        permissions?.legacy?.mobile ||
        permissions?.legacy?.sales ||
        permissions?.legacy?.guest;

    const dispatch = useDispatch();

    const location = useLocation();

    const isNonChainRefOrProjectPath = pathsWithoutChainOrProjectRef.includes(location.pathname);

    const GET_SIDEBAR_INFO = gql`
    {
        wifiManagerConfig: wifiManagerConfig${applyWifiFilters} {
            results {
                enabled
                url
            }
        }
        ${
            ref !== HOTEL.GLOBAL_MANAGEMENT
                ? `permanentSocket: chromecastSettings(kind: "") {
            results {
                socket
            }
        }
        tvSettings {
            permanentSocket
        }`
                : ""
        }
    }
    `;
    const { data, refetch } = useQuery(GET_SIDEBAR_INFO, {
        skip: !applyWifiFilters || !chainRef || isNonChainRefOrProjectPath,
    });

    const GET_TV_URL = gql`
    {
        loginOldManager(lang :"${localStorage.getItem("lang") || "en"}") {
                isEnabled
                token
        }
    }
    `;
    const [executeTVQuery, tvQueryResponse] = useLazyQuery(GET_TV_URL, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (isLoggedIn && location.pathname !== "/select-project") {
            setVisible(true);
        } else {
            setVisible(false);
        }
        // eslint-disable-next-line
    }, [isLoggedIn]);

    useEffect(() => {
        if (!_.isEmpty(permissions)) {
            let filtersToApply = "";
            let isAdmin = false;
            let applyChanges = false;
            if (currentLang) {
                filtersToApply += `lang:"${currentLang}"`;
            }
            if (timeZone) {
                filtersToApply += `,timezone:"${timeZone}"`;
            }
            if (!hasCorporateUser) {
                if (
                    permissions.managerSetup &&
                    (permissions.managerSetup.networkManagement || permissions.managerSetup.networkAdmin)
                ) {
                    applyChanges = true;
                    if (permissions?.managerSetup?.networkAdmin) {
                        isAdmin = true;
                    }
                }
            } else {
                applyChanges = true;
                isAdmin = true;
            }
            if (hasCorporateUser && !chainRef) {
                //Chain ref not defined, so the query returns error
                applyChanges = false;
            }

            if (applyChanges) {
                filtersToApply += `,isAdmin:${isAdmin}`;
                setApplyWifiFilters(`(${filtersToApply})`);
            }
        }
        // eslint-disable-next-line
    }, [permissions]);

    useEffect(() => {
        if (data && data.wifiManagerConfig && data.wifiManagerConfig.results) {
            setWifiManagerConfig(data.wifiManagerConfig.results);
            if (data.wifiManagerConfig.results.enabled) {
                if (openLinkWifi) {
                    setOpenLinkWifi(false);
                    dispatch(showGlobalLoading(false));
                    window.open(data.wifiManagerConfig.results.url, "_blank").focus();
                }
            }
        }
        if (data && ((data.permanentSocket && data.permanentSocket.results) || data.tvSettings)) {
            Session.setSessionProp("ccPermanentSocket", data.permanentSocket.results.socket);
            Session.setSessionProp("tvPermanentSocket", data.tvSettings.permanentSocket);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (
            tvQueryResponse.data &&
            tvQueryResponse.data.loginOldManager &&
            tvQueryResponse.data.loginOldManager.isEnabled
        ) {
            dispatch(showGlobalLoading(false));
            Session.setLSProp(
                "oldManagerConfig",
                JSON.stringify({
                    url: currentHotel.oldmanagerurl,
                    users: currentHotel.oldmanagerusers,
                    tokenCloud: tvQueryResponse.data.loginOldManager.token,
                })
            );
            window.open("/ZAFIRO_TV", "_blank").focus();
        } else {
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [tvQueryResponse.data]);

    useEffect(() => {
        if (location && location.pathname) {
            if (location.pathname) {
                const routeSalesShopPattern = /^\/sales\/shop\/.*$/;
                if (routeSalesShopPattern.test(location.pathname)) {
                    setHideLinks(true);
                } else {
                    setHideLinks(false);
                }
            }
        }
    }, [location]);

    const handleWifiClick = (e) => {
        dispatch(showGlobalLoading(true));
        e.preventDefault();
        setOpenLinkWifi(true);
        refetch();
    };

    const handleTVClick = (e) => {
        dispatch(showGlobalLoading(true));
        e.preventDefault();
        executeTVQuery();
    };

    const getLinkPos = () => {
        if (hasWiFiLink) {
            return 3.5;
        }
        return 0;
    };

    if (menusDisplay.hideSidebar) return null;
    return (
        <div
            id="main_sidebar_nav_container"
            className={`w-1/6 left-0 block fixed mt-20  h-full bg-white z-10 ${!sidebarOpen ? "hidden" : ""}`}
        >
            <span className={!visible ? "hidden" : "block h-full"}>
                <nav
                    id={`main_sidebar_nav`}
                    ref={refSidebar}
                    className={`shadow-xl bg-white z-10 pb-6 pt-8 h-full overflow-y-auto flex-row flex-no-wrap overflow-hidden md:block ${
                        sidebarOpen ? "flex" : "hidden"
                    }`}
                >
                    {permissions ? (
                        <Routes>
                            {routes
                                .filter((route) => typeof route.sidebar !== "undefined")
                                .map((route, index) => (
                                    <Route
                                        key={index}
                                        {...route}
                                        element={<route.sidebar sectionID={route.sectionID || route.package} />}
                                    />
                                ))}
                        </Routes>
                    ) : null}

                    {hasWiFiLink && (!isCorporate || permissions?.legacy?.wifi) ? (
                        // eslint-disable-next-line
                        <a
                            id="WIFIMANAGER_LINK"
                            href="#"
                            rel="noopener noreferrer"
                            className={`border-t border-gray-200 pl-6 pt-4 pb-4  flex cursor-pointer fixed  w-1/6 ${
                                hideLinks ? "hidden" : ""
                            } `}
                            style={{ bottom: `0rem` }}
                            onClick={handleWifiClick}
                        >
                            <img
                                className="oldManagerLinkIcon float-left "
                                src={`${process.env.PUBLIC_URL}/assets/images/main-logo.png`}
                                alt="Logo"
                            />
                            <span className="text-lg pl-2  text-gray-800 float-left -mt-0.5">ZAFIRO Wifi</span>
                            <span className="icon icon-external-link text-3xl -mt-0.5 text-gray-800 absolute right-6"></span>
                        </a>
                    ) : null}
                    {hasAdvancedConfigLink && !isCorporate ? (
                        // eslint-disable-next-line
                        <a
                            id="ZAFIROTV_LINK"
                            href="#"
                            rel="noopener noreferrer"
                            target="_blank"
                            className={`border-t border-gray-200 pl-6 pt-4 pb-4  flex cursor-pointer fixed  w-1/6 ${
                                hideLinks ? "hidden" : ""
                            }`}
                            style={{ bottom: `${getLinkPos()}rem` }}
                            onClick={handleTVClick}
                        >
                            <img
                                className="oldManagerLinkIcon float-left my-auto"
                                src={`${process.env.PUBLIC_URL}/assets/images/main-logo.png`}
                                alt="Logo"
                            />
                            <span className="text-lg pl-2  text-gray-800 float-left -mt-0.5 pr-8">
                                {t("{{capitalize, capitalize}}", { capitalize: t("advanced-confg") })}
                            </span>
                            <span className="icon icon-external-link text-3xl -mt-0.5 text-gray-800 absolute right-6"></span>
                        </a>
                    ) : null}
                </nav>
            </span>
        </div>
    );
}
