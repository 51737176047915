import React, { useEffect, useState } from "react";
//Components
import { Link, useLocation } from "react-router-dom";
import UseIcon from "../../useIcon";
import CountUp from "../../../hooks/Utils/CountUp";
import { multipleTranslate, capitalizeFirst } from "../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import UseItemActions from "../../useItemActions";

const UseDashboardCard = ({ data }) => {
    const { t } = useTranslation();
    //Store data
    const {
        color,
        headerNumber,
        headerText,
        headerNumberColor,
        bodyNumber,
        bodyText,
        icons,
        link,
        colorOver,
        customFontColor,
        actions,
        actionsCont = {},
        hidden,
        headerTextColor,
        noShowLinkIcon,
        noHoverShadow
    } = data;

    const { actionsArr = [] } = actionsCont;

    //Data
    const fallbackRoute = useLocation().pathname;
    const [mouseOver, setMouseOver] = useState(false);
    const changeDBbackground = (value) => {
        setMouseOver(value);
    };
    const [fontColor, setFontColor] = useState("text-blue-400");
    const [backgroundColor, setBackgroundColor] = useState("bg-white");
    const hiddenStyles = {
        opacity: 0,
        pointerEvents: "none",
        minHeight: "0px",
        maxHeight: "0px",
        overflow: "hidden",
        padding: "0px",
    };
    useEffect(() => {
        if (color !== "white") {
            if (customFontColor) {
                setFontColor(customFontColor);
            } else {
                setFontColor("text-gray-100");
            }
            setBackgroundColor(color);
        }
        // eslint-disable-next-line
    }, []);

    const content = () => {
        return (
            <div
                className={`dc-card-container-noShadow ${fontColor}  ${mouseOver && colorOver ? colorOver : backgroundColor}  ${mouseOver && !noHoverShadow ? "shadow-lg" : ""}`}
                onMouseEnter={() => changeDBbackground(true)}
                onMouseLeave={() => changeDBbackground(false)}
                style={hidden ? hiddenStyles : null}
            >
                <div>
                    {icons ? (
                        <>
                            {icons.map((icon, index) =>
                                icon.name === "warning" ? (
                                    <div className="dc-icon text-5xl w-14" key={index}>
                                        <span className=" icon icon-warning text"></span>
                                        <span className=" icon icon-warning1 -ml-1em text-zafiro-300"></span>
                                        <span className=" icon icon-warning2 -ml-1em text-zafiro-300"></span>
                                    </div>
                                ) : (
                                    <UseIcon
                                        icon={icon}
                                        adjust={`dc-icon ${link ? "text-6xl" : "text-5xl mt-2"}`}
                                        key={index}
                                    />
                                )
                            )}
                        </>
                    ) : null}
                </div>
                <div>
                    <div className="block h-10 font-bold  text-right">
                        <div className={`text-5xl inline ${headerNumberColor ? headerNumberColor : ""}`}>
                            {headerNumber !== undefined ? <CountUp key={headerNumber} number={headerNumber} /> : null}
                        </div>
                        {headerText && headerText !== "" ? (
                            <div className={`text-3xl pl-3 mt-3 inline ${headerTextColor ? headerTextColor : ""}`}>
                                {capitalizeFirst(multipleTranslate(headerText, t))}
                            </div>
                        ) : null}
                    </div>
                    <div className="block mt-6 text-right">
                        <span className="text-lg font-light">
                            {bodyNumber !== undefined ? <CountUp number={bodyNumber} /> : null}{" "}
                            {bodyText ? (
                                <div className="first-capital inline">
                                    {capitalizeFirst(multipleTranslate(bodyText, t))}
                                </div>
                            ) : null}
                        </span>
                    </div>
                </div>
                {(link && !noShowLinkIcon) && <UseIcon icon={{ name: "chevron-right" }} adjust="dc-icon text-3xl my-auto" />}
                {actions && actionsArr.length ? (
                    <div className={`-mt-5 -mr-8 ${mouseOver ? " opacity-100" : " opacity-0"} `}>
                        <UseItemActions
                            dataType={actionsCont && actionsCont.dataType ? actionsCont.dataType : "actions-card"}
                            id={actionsCont && actionsCont.id ? actionsCont.id : "actions-id-card"}
                            executeSectionQuery={actionsCont.executeQuery ? true : false}
                            actions={actionsCont && actionsCont.actionsArr ? actionsCont.actionsArr : []}
                        />
                    </div>
                ) : null}
            </div>
        );
    };

    return (
        <>
            {link ? (
                <Link
                    className={link ? "cursor-pointer" : "cursor-default"}
                    to={link ? link : fallbackRoute}
                    key={color}
                >
                    {content()}
                </Link>
            ) : (
                <div className=" cursor-default relative" key={color}>
                    {content()}
                </div>
            )}
        </>
    );
};

export default UseDashboardCard;
