//Utils
import { capitalizeFirst } from "../../../Utils/Utils";
import NewGuestGroupModal from "../modals/NewGuestGroupModal";

const GuestListViewModal = (data, name, ggId, langStrings) => {
    const guestQuery = `{
                data: guests(filter: {guestGroup:${ggId}}){
                        results { name surname }
                    }
        }`;
    return [
        {
            id: ggId, //id,
            bodyAdjust: "w-4/12",
            title: `${name} - ${capitalizeFirst(langStrings["group-guests"])}`,
            textAdjust: "font-bold pb-3",
            inputs: [
                {
                    text: ["guests-added", ":"],
                    name: "guests-of-group",
                    ph: "guests",
                    type: "selectedView",
                    optionsQuery: guestQuery,
                },
            ],

            buttons: [
                {
                    name: "edit",
                    style: "link",
                    customClass: "pl-0 pt-2 pb-2 cursor-pointer",
                    action: "load-next-modal",
                },
                { name: "accept", style: "blue", action: "close" },
            ],
            buttonAlignment: "between",
        },
        NewGuestGroupModal(name, ggId)[0],
    ];
};

export default GuestListViewModal;
