//Utils
import { capitalizeFirst } from "../../../Utils/Utils";

export const getItemText = (data, lang) => {
    const text = data?.itemText;
    if (typeof text === "string") {
        return text;
    } else if (typeof text === "object") {
        if (text?.text) {
            return text.text;
        } else if (text?.[lang]) {
            return text[lang];
        } else if (text?.en) {
            return text.en;
        } else if (text && Object.keys(text)?.length > 0) {
            const key = Object.keys(text)[0];
            if (key !== "id") {
                return text[key];
            }
        }
    }
    return null;
};

const WidgetItemNew = (t, widgetID, rowContent = {}, lang) => {
    const { data } = rowContent;

    return {
        id: "widget-new-item",
        bodyAdjust: "w-full",
        title: data?.itemName || t("New item"),
        textAdjust: "",
        executeSectionQuery: true,
        twoColumns: true,
        inputs: [
            {
                text: "",
                name: "widget-item-name",
                value: data?.itemName,
                type: "text",
                cStyle: "hidden",
                style: "hidden",
            },
            {
                text: capitalizeFirst(t("image")) + " *",
                type: "void",
                style: "col-start-1 row-start-0 row-end-1 font-bold pr-10 pt-0 pb-0",
                icon: "info",
                iconTooltip: "image-recommended-resolution",
                adjust: "flex items-center",
                adjustIcon: " text-3xl text-gray-700 ml-2",
            },
            {
                text: t("Add an image") + ":",
                type: "selectImage",
                style: "col-start-1 row-start-1 row-end-3 pr-10 pt-0 pb-0",
                closeBrowserModalAction: "open-modal-widget-new-item",
                globalAction: "select-image-widget-new-item",
                value: data?.resourceValue,
            },
            {
                text: capitalizeFirst(t("text")),
                type: "void",
                style: "col-start-1 row-start-3 row-end-4 font-bold pr-10 pt-0 pb-0",
            },
            {
                text: t("Add any text to the item") + ":",
                name: "widget-item-text",
                value: getItemText(data, lang),
                type: "text",
                style: "col-start-1 row-start-4 row-end-5 pr-10 pt-0 pb-0",
            },
            {
                text: "",
                name: "save-widget-item-text",
                value: getItemText(data, lang),
                type: "text",
                cStyle: "hidden",
                style: "hidden",
            },
            {
                text: "",
                name: "save-item-id",
                value: data?.itemId || "",
                type: "text",
                cStyle: "hidden",
                style: "hidden",
            },
            {
                text: capitalizeFirst(t("navigation")) + " *",
                type: "void",
                style: "col-start-2 row-start-0 row-end-1 font-bold pl-10 pt-0 pb-0",
            },
            {
                text: capitalizeFirst(t("select-navigation-destination")),
                type: "void",
                style: "col-start-2 row-start-1 row-end-2 pl-10 pt-0 pb-0",
            },
            {
                text: capitalizeFirst(t("select-navigation-destination")),
                type: "actionsSliderDestinations",
                widgetID,
                style: "col-start-2 row-start-2 row-end-6 pl-10 pt-0 pb-0",
                cValue: data?.itemActions,
                globalAction: "select-asset-for-modal-actions-item-widget-item",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: data?.itemId ? "save" : "add",
                style: "blue",
                action: "return-data-for-widget-items",
            },
        ],
    };
};

export default WidgetItemNew;
