import { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//MODELS
import { useTranslation } from "react-i18next";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetSalesTablesHeaders from "../../Utils/Table/Headers/GetSalesTableHeaders";
import {
    setHeaderButtons,
    setCountPosition,
    setItemsPerpage,
    setTableResults,
    setCustomError,
    setSearchable,
    setLoading,
    setHeaders,
    setCount,
    setError,
} from "../../../actions/tableActions";
import { POLLINTERVAL_15S } from "../../Utils/Utils";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { getProjectLangs, parseTranslation } from "../../Utils/SalesUtils";
import ShopOrderSummary from "./models/ShopOrderSummary";

const ListOrderSummary = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentPage, perPage, activeFilters, count } = useSelector((state) => state.table);
    const { projectLangs } = useSelector((state) => state.ui);
    const [langDefault, setLangDefault] = useState("en");
    const SECTION_NAME = "list-order-summary";

    const GET_ZAFIRO_SHOPS_ORDERS_DATA = gql`
        query {
            shops(includeTotals:true, page: ${currentPage} , size: ${perPage} , 
                    ${activeFilters?.search ? `filter:{search: "${activeFilters.search}"}` : ""} 
                ) {
                info {
                    count
                    page
                    size
                    }
                    results {
                        id
                        name: nameTranslations {
                            lang
                            text
                        }
                        totalOrders{
                            inProgress
                            pending
                            ready
                        }
                    }
            }
        }
    `;

    const [executeQuery, { data, loading, error }] = useLazyQuery(GET_ZAFIRO_SHOPS_ORDERS_DATA, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    const tableFiltersContainer = document.querySelector("#table-container .t-filters-container");

    useLayoutEffect(() => {
        if (tableFiltersContainer) {
            tableFiltersContainer.style.marginBottom = "0rem";
        }
    }, [tableFiltersContainer]);

    useEffect(() => {
        dispatch(setItemsPerpage(8));
        executeQuery();
        dispatch(setHeaders(GetSalesTablesHeaders(SECTION_NAME)));
        dispatch(setSearchable(true));
        dispatch(setCustomError(t("no-shops-yet")));
        dispatch(setCountPosition("table-header"));
    }, []);

    useEffect(() => {
        if (!loading && data?.shops?.results) {
            const tableResults = arrangeDataShopsOrders(data.shops.results, t, langDefault);
            dispatch(setTableResults(tableResults));
            dispatch(setLoading(false));
            dispatch(setCount(data.shops.info.count));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (data) {
            dispatch(
                setHeaderButtons(GetTableHeaderButton(SECTION_NAME, { disabled: count === 0 ? true : false }, null, t))
            );
        }
        // eslint-disable-next-line
    }, [data, count]);

    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            setLangDefault(langDefault);
        }
        // eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        dispatch(setError(error));
    }, [error]);

    return null;
};

export default ListOrderSummary;

const arrangeDataShopsOrders = (data, t, langDefault) => {
    return data.map(({ id, name, totalOrders }) => {
        return ShopOrderSummary(
            {
                id,
                name: parseTranslation(name, langDefault),
                pending: totalOrders?.pending ? totalOrders?.pending : 0,
                inProgress: totalOrders?.inProgress ? totalOrders?.inProgress : 0,
                ready: totalOrders?.ready ? totalOrders?.ready : 0,
            },
            t
        );
    });
};
