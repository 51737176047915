import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseSelectMultipleWithSearchOptionsQuery from "../../../../useSelectMultipleWithSearchOptionsQuery";
//Utils
import { GetLanguageOptions } from "../../../../../hooks/Utils/LanguagesUtils";
import { GetCountriesOptions } from "../../../../../hooks/Utils/CountriesUtils";
import UseEditableField from "../../../../../components/useEditableField";
import Label from "../../../../Inputs/useLabel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { calculateAge, isValidDate } from "../../../../../hooks/Utils/Utils";
import useLangStrings from "../../../../../lang/useLangStrings";
//Actions
import { changeActionValues } from "../../../../../actions/globalActions";
import UseCustomPhoneInput from "components/Inputs/UseCustomPhoneInput";
import { GetNationalitiesOptions } from "hooks/Utils/NationalityUtils";

const useNewGuestForm = () => {
    const dispatch = useDispatch();
    const { lang } = useSelector((state) => state.ui);
    const { birthdate } = useSelector((state) => state.action.values);
    const [age, setAge] = useState(null);
    const years = useLangStrings("years");
    useEffect(() => {
        if (birthdate && isValidDate(new window.ZDate(birthdate))) setAge(calculateAge(new window.ZDate(birthdate)));
    }, [birthdate]);

    const groupsQuery = `{
        data: guestGroups{
          info { count }
          results { id name  }
        }       
    }`;

    return (
        <div className={`grid grid-cols-2 gap-x-4 gap-y-4 pt-8`}>
            <UseEditableField
                data={{
                    label: "pms-id",
                    value: "",
                    inEditionMode: true,
                    type: "text",
                    id: "new-guest-form-pms-id",
                }}
            ></UseEditableField>
            <UseEditableField
                data={{
                    label: "title",
                    value: "",
                    inEditionMode: true,
                    type: "text",
                    id: "new-guest-form-pms-title",
                }}
            ></UseEditableField>
            <UseEditableField
                data={{
                    label: "name",
                    required: true,
                    value: "",
                    inEditionMode: true,
                    type: "text",
                    id: "new-guest-form-pms-name",
                }}
            ></UseEditableField>
            <UseEditableField
                data={{
                    label: "surname",
                    required: true,
                    value: "",
                    inEditionMode: true,
                    type: "text",
                    id: "new-guest-form-pms-surname",
                }}
            ></UseEditableField>
            <UseEditableField
                data={{
                    label: "nationality",
                    value: "",
                    inEditionMode: true,
                    type: "select-with-search",
                    oneSelected: true,
                    optionData: GetNationalitiesOptions(),
                    selectedIds: [],
                }}
            ></UseEditableField>
            <UseEditableField
                data={{
                    label: "lang",
                    value: "",
                    inEditionMode: true,
                    type: "select-with-search",
                    oneSelected: true,
                    optionData: GetLanguageOptions(),
                    selectedIds: [],
                }}
            ></UseEditableField>
            <UseEditableField
                data={{
                    label: "birthdate",
                    labelOnEdition: "birthdate",
                    value: "",
                    inEditionMode: true,
                    dateAdjust: "text-gray-600",
                    type: "date",
                }}
            ></UseEditableField>
            <div className="mt-8 text-sm text-gray-700">{age && birthdate !== null ? `${age} ${years}` : null}</div>
            <div className="col-span-2">
                <UseSelectMultipleWithSearchOptionsQuery
                    dataSelect={{
                        text: ["guest-group"],
                        textStyle:
                            "text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden",
                        name: "guest-groups",
                        optionsQuery: groupsQuery,
                        selectPlaceHolder: "select-group",
                        cStyle: "w-full",
                        sort: true,
                        type: "selectMultipleWithSearch",
                        noSelect: true,
                        id: "new-guest-form-select-guest-group",
                    }}
                ></UseSelectMultipleWithSearchOptionsQuery>
            </div>
            <div className="col-span-2">
                <UseEditableField
                    data={{
                        label: "email",
                        value: "",
                        inEditionMode: true,
                        placeholder: "example@gmail.com",
                        type: "text",
                        styleAdjust: "col-span-2 break-all",
                        inputAdjust: "inputPHlower",
                        onBlurValidation: true,
                        id: "new-guest-form-email",
                    }}
                ></UseEditableField>
            </div>
            <div className="col-span-2">
                <Label
                    label={"mobile-phone"}
                    translatable={true}
                    adjust="text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden"
                ></Label>
                <div className="w-full">
                    <UseCustomPhoneInput fieldActionValues={"mobile-phone"} actionValues={true} />
                </div>
            </div>
        </div>
    );
};

export default useNewGuestForm;
