import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";

//Actions
import { showGlobalLoading } from "../../../actions/uiActions";
import { setRefreshContentData, setExecuteQuery } from "../../../actions/sectionActions";
//Utils
import { toast } from "react-toastify";
import { removeApolloCacheKeys, bdDateToDateInput, escapeSpecialChars } from "../../Utils/Utils";

const UpdateGuestData = ({ client }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { values } = useSelector((state) => state.action);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const cd = useSelector((state) => state.sectionContent.data);

    //State
    const [id, setId] = useState();

    //Actions
    const dispatch = useDispatch();
    const completeEmpty = (val) => {
        return !values || !values[val] ? "" : escapeSpecialChars(values[val]);
    };

    const hasChanges = (key, key_value) => {
        const value = cd && key_value ? cd[key_value] : null;
        switch (key) {
            case "lang":
                return cd && values[key] && values[key][0] && values[key][0].toLowerCase() !== value;
            case "nationality":
                return cd && values[key] && values[key][0] && values[key][0].toUpperCase() !== value;
            case "guestCountry":
                return cd && values[key] && (!values.country || (values.country && !values.country[0]));
            case "guestLang":
                return cd && values[key] && (!values.lang || (values.lang && !values.lang[0]));
            default:
                return cd && values[key] !== value;
        }
    };
    const birthdate =
        values && values.age && new window.ZDate(values.age) ? bdDateToDateInput(new window.ZDate(values.age)) : "";

    const dataToUpdate = `
        ${hasChanges("pms-id", "pmsID") ? `pmsID: "${completeEmpty("pms-id")}"` : ""}
        ${hasChanges("title", "guestTitle") ? `title: "${completeEmpty("title")}"` : ""}
        ${hasChanges("name", "guestName") ? `name: "${completeEmpty("name")}"` : ""} 
        ${hasChanges("surname", "guestSurname") ? `surname: "${completeEmpty("surname")}"` : ""} 
        ${hasChanges("phone", "guestPhone") ? `phoneNumber: "${completeEmpty("phone")}"` : ""} 
        ${hasChanges("email", "guestEmail") ? `email: "${completeEmpty("email")}"` : ""} 
        ${hasChanges("age", "guestBirthdate") ? `birthDate: "${birthdate}"` : ""} 
        ${
            hasChanges("nationality", "guestCountry")
                ? `countryRef: "${values.nationality[0].toUpperCase()}"`
                : hasChanges("guestCountry")
                ? `countryRef: ""`
                : ""
        } 
        ${
            hasChanges("lang", "guestLang")
                ? `languageRef: "${values.lang[0].toLowerCase()}"`
                : hasChanges("guestLang")
                ? `languageRef: ""`
                : ""
        }
    `;
    const regex = /\s|\t|\n/g;
    const UPDATE_GUEST = gql`
    mutation {
        updateGuest(id:${id} ${dataToUpdate.replaceAll(regex, "") !== "" ? `, data:{${dataToUpdate}}` : ""}
                    ${
                        values["guest-group"] && values["guest-group"].length > 0
                            ? `groups:[${values["guest-group"].join(",")}]`
                            : "groups:[]"
                    }
                 ){
            error
            id
            ok
        }
    }
`;

    const [executeMutation, { data }] = useMutation(UPDATE_GUEST, {
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            toast.error(langStrings["mutation-error"]);
        },
    });

    useEffect(() => {
        if (executeQuery && executeQuery.action === "updateGuestData") {
            dispatch(showGlobalLoading(true));
            dispatch(setExecuteQuery(null));
            setId(executeQuery.params.id);
            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && data.updateGuest && data.updateGuest.ok) {
            toast.success(langStrings["operation-successful"]);
            dispatch(setRefreshContentData(true));

            //remove guest list cache to show updated info
            removeApolloCacheKeys(client.cache, "guests");
        } else if (data && data.updateGuest && data.updateGuest.error) {
            toast.error(langStrings["mutation-error"]);
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateGuestData);
