import { DEVICE_FUNCTIONS, DEVICE_KINDS } from "../../../../Utils/Hotel/Monitor/ListDevicesUtils";
import { Session } from "../../../../Utils/Session";
import { parseBoolean } from "../../../../Utils/Utils";

const DeviceCards = (data, permissions, dataWifiLicenses, t, superUser) => {

    const wifiLicensesAvailable = dataWifiLicenses?.available ? Number(dataWifiLicenses.available) : 0;
    const wifiLicensesUsed = dataWifiLicenses?.used ? Number(dataWifiLicenses.used) : 0;
    const percentLicenses = Math.floor(wifiLicensesAvailable * 0.95);
    const tvWarnigns = countWarnings({
        kind: DEVICE_KINDS.tv.value,
        devices: data
    });
    const chromecastWarnings = countWarnings({
        kind: DEVICE_KINDS.chromecast.value,
        devices: data
    });

    const makeCards = () => {
        let _cards = [];
        if (permissions?.product.tv) {
            _cards.push({
                color: "bg-white",
                colorOver: "bg-white",
                customFontColor: "text-zafiro-800",
                headerNumber: tvWarnigns,
                headerNumberColor: tvWarnigns >= 1 ? "text-red-100" : null,
                headerText: "warnings",
                headerTextColor: tvWarnigns >= 1 ? "text-red-100" : null,
                bodyText: "tv-devices",
                icons: [{ name: "tv" }],
                visible: true,
                noShowLinkIcon: true,
                noHoverShadow: true,
            });
        }
        if (permissions?.product.cast) {
            _cards.push({
                color: "bg-white",
                colorOver: "bg-white",
                customFontColor: "text-zafiro-800",
                headerNumber: chromecastWarnings,
                headerNumberColor: chromecastWarnings >= 1 ? "text-red-100" : null,
                headerText: "warnings",
                headerTextColor: chromecastWarnings >= 1 ? "text-red-100" : null,
                bodyText: "chromecasts",
                icons: [{ name: "chromecast" }],
                visible: true,
                noShowLinkIcon: true,
                noHoverShadow: true,
            });
        }

        if (permissions?.product.wifi) {
            const actionsCont = [];
            if (superUser) {
                actionsCont.push({
                    name: t("reset-wifi-licenses"),
                    action: "reset-wifi-licenses",
                    modal_btn_name: "save",
                    modal_title: "reset-wifi-licenses",
                    modal_bodyAdjust: "w-1/12",
                    t,
                    disabled: wifiLicensesAvailable === 0,
                });
            }

            _cards.push({
                color: "bg-white",
                colorOver: "bg-white",
                customFontColor: "text-zafiro-800",
                headerNumber: wifiLicensesUsed,
                headerNumberColor: wifiLicensesUsed >= percentLicenses ? "text-orange-100" : null,
                actions: true,
                actionsCont: {
                    id: "actions-wifi-licenses-card",
                    actionsArr: [...actionsCont],
                    dataType: "wifi-licenses",
                    executeQuery: true,
                },
                headerText: `/ ${wifiLicensesAvailable}`,
                bodyText: "wifi-licenses",
                icons: [{ name: "Wifi-access " }],
                visible: true,
                noHoverShadow: superUser ? false : true,
            });
        }

        return _cards;
    };

    return makeCards();
};

const countWarnings = (props) => {
    const { kind, devices } = props;
    let count = 0;
    if (kind && (devices && devices.length > 0)) {
        let validatedFunctions = [];
        let activeSocket = false;
        if (kind === DEVICE_KINDS.tv.value) {
            activeSocket = Session.getSessionProp("tvPermanentSocket") && parseBoolean(Session.getSessionProp("tvPermanentSocket")) ? true : false;
            validatedFunctions.push(
                DEVICE_FUNCTIONS.tv.value,
                DEVICE_FUNCTIONS.tvAndcast.value
            )
        } else if (kind === DEVICE_KINDS.chromecast.value) {
            activeSocket = Session.getSessionProp("ccPermanentSocket") && parseBoolean(Session.getSessionProp("ccPermanentSocket")) ? true : false;
            validatedFunctions.push(
                DEVICE_FUNCTIONS.cast.value,
                DEVICE_FUNCTIONS.tvAndcast.value
            );
        }
        devices.forEach(device => {
            let increment = false;
            let level = device.warning ? device.warning.level : 0;
            if (level < 2 && validatedFunctions.includes(device.function)) {
                // if(level === 1 && device.warning?.info !== "low-signal-level"){
                //     increment = activeSocket;
                // }else{
                //     increment = true;
                // }
                increment = true;
            }
            if (increment) {
                count++;
            }
        });
    }
    return count;
}

export default DeviceCards;
