import { Session } from "./Session";
import { parseBoolean } from "./Utils";

const ComposePermissionsTree = (
    permissions,
    userPermissions,
    userManagerRole,
    isSuperUser,
    agregatedPermissions,
    hasCorporateUser
) => {
    const corporateAccess = Session.getSessionProp("projectRef") === "CORPORATE";
    const hasAccessByRole = (accesse) => {
        return userPermissions.includes(accesse) || userManagerRole || isSuperUser;
    };

    const allPermissions = Session.allPermissions();

    const hasProjectPermission = (permLabel) => {
        return permissions?.includes(permLabel) || agregatedPermissions?.includes(permLabel);
    };
    const hotelCastConfig = hasAccessByRole("castConfig") && permissions.includes("castConfig");
    const hotelTopology =
        !corporateAccess &&
        (isSuperUser ||
            (hasAccessByRole("topology") && permissions.includes("topology") && !permissions.includes("legacyTV")));
    const hotelTvSettings = hasAccessByRole("tvConfig") && permissions.includes("tvConfig");

    return {
        product: {
            advancedDesign: allPermissions || hasProjectPermission("productADVANCEDDESIGN"),
            cast: allPermissions || hasProjectPermission("productCAST"),
            chain: allPermissions || hasProjectPermission("productCHAIN"),
            channels: allPermissions || hasProjectPermission("productCHANNELS"),
            corporate: allPermissions || hasProjectPermission("productCORPORATE"),
            guest: allPermissions || hasProjectPermission("productGUEST"),
            house: allPermissions || hasProjectPermission("productHOUSE"),
            mobile: allPermissions || hasProjectPermission("productMOBILE"),
            sales: allPermissions || hasProjectPermission("productSALES"),
            tv: allPermissions || hasProjectPermission("productTV"),
            wifi: allPermissions || hasProjectPermission("productWIFI"),
            salesPremium: allPermissions || (hasProjectPermission("productSALES") && hasProjectPermission("myOrders")), // SO_SALE+
            tvPremium: allPermissions || (hasProjectPermission("productTV") && hasProjectPermission("optTVPremium")), // SO_TVDE+
            legacy:
                hasProjectPermission("legacyWIFI") ||
                hasProjectPermission("legacyTV") ||
                hasProjectPermission("legacyMOBILE") ||
                hasProjectPermission("legacyGUEST") ||
                hasProjectPermission("legacySALES"),
        },
        integrations: {
            pos: allPermissions || permissions.includes("posIntegration"),
        },
        hotel: {
            map: hasAccessByRole("map") && permissions.includes("map"),
            monitoring: hasAccessByRole("monitoring") && permissions.includes("monitoring"),
            networkAlerts:
                (hasAccessByRole("networkAlerts") && permissions.includes("networkAlerts")) ||
                (isSuperUser && !permissions.includes("networkAlerts")),
            statusMail: hasAccessByRole("statusMail") && permissions.includes("statusMail"),
            roomList: hasAccessByRole("roomList") && permissions.includes("roomList"),
            roomGroups: hasAccessByRole("roomGroups") && permissions.includes("roomGroups"),
            castConfig: hotelCastConfig,
            topology: hotelTopology,
            tvInputs: hasAccessByRole("tvInputs") && permissions.includes("tvInputs"),
            TVSettings: hotelTvSettings,
            wifiSettings: hotelCastConfig || hotelTopology || hotelTvSettings,
            wifiConfig: hasAccessByRole("wifiConfig") && permissions.includes("wifiConfig"),
            mobileConfig: hasAccessByRole("mobileConfig") && permissions.includes("mobileConfig"),
            nativeApps: hasAccessByRole("nativeApps") && permissions.includes("nativeApps"),
        },
        design: {
            interfacePreview: hasAccessByRole("interfacePreview") && permissions.includes("interfacePreview"),
            interfaceEditor: hasAccessByRole("interfaceEditor") && permissions.includes("interfaceEditor"),
            multipleInterface: hasAccessByRole("interfaceEditor") && permissions.includes("multipleInterface"),
            corporateIdentity: hasAccessByRole("corporateIdentity") && permissions.includes("corporateIdentity"),
            library: hasAccessByRole("library") && permissions.includes("library"),
            libraryVideos: permissions?.includes("libraryVideos") || agregatedPermissions?.includes("libraryVideos"),
            templates: isSuperUser && parseBoolean(Session.getSessionProp("ESChain")),
            appearances: hasAccessByRole("appearancesEditor") && permissions.includes("appearancesEditor"),
            tags: corporateAccess && hasAccessByRole("interfaceEditor") && permissions.includes("interfaceEditor"),
            apps: permissions.includes("apps") || agregatedPermissions?.includes("apps"),
            mobileAppearance: hasAccessByRole("mobileConfig") && permissions.includes("mobileConfig"),
        },
        guests: {
            guestList: hasAccessByRole("guestList") && permissions.includes("guestList"),
            guestGroups: hasAccessByRole("guestGroups") && permissions.includes("guestGroups"),
            guestConfig: hasAccessByRole("guestSettings") && permissions?.includes("guestSettings"),
            loyaltyCustomers:
                (!corporateAccess && hasAccessByRole("loyaltyCustomers") && permissions.includes("loyaltyCustomers")) ||
                (corporateAccess && hasAccessByRole("loyaltyCustomers") && hasProjectPermission("loyaltyCustomers")),
            digitalKey: hasAccessByRole("digitalKey") && permissions.includes("digitalKey"),
            messages:
                hasAccessByRole("messaging") &&
                permissions.includes("messaging") &&
                (hasProjectPermission("productTV") || hasProjectPermission("productMOBILE")),
            alarms: hasAccessByRole("alarms") && permissions.includes("alarms") && hasProjectPermission("productTV"),
            welcomeScreens:
                (!corporateAccess && permissions.includes("welcomeScreens")) ||
                (corporateAccess && agregatedPermissions?.includes("welcomeScreens")),
            wifi: hasAccessByRole("productWIFI") && permissions.includes("productWIFI"),
            wifiPasscodes: hasAccessByRole("wifiPasscodes") && permissions.includes("wifiPasscodes"),
            preCheckin:
                hasAccessByRole("guestList") &&
                permissions.includes("guestList") &&
                permissions?.includes("preCheckin"),
        },
        stats: {
            cast: hasAccessByRole("statsCast") && permissions.includes("statsCast"),
            wifi: hasAccessByRole("statsWifi") && permissions.includes("statsWifi"),
            mobile: hasAccessByRole("statsMobile") && permissions.includes("statsMobile"),
            internal: isSuperUser,
        },
        goOutSafe: {
            bookings: hasAccessByRole("bookings") && permissions.includes("bookings"),
            capacity: hasAccessByRole("capacity") && permissions.includes("capacity"),
            cleaning: hasAccessByRole("cleaning") && permissions.includes("cleaning"),
            staff: hasAccessByRole("staff") && permissions.includes("staff"),
            areas: hasAccessByRole("a&a") && permissions.includes("a&a"),
            categories: hasAccessByRole("a&a") && permissions.includes("a&a"),
        },
        managerSetup: {
            manageUsers: hasAccessByRole("manageUsers"),
            manageLanguages: hasAccessByRole("manageLanguages"),
            manageProperties: hasAccessByRole("manageProperties"),
            manageBrands: hasAccessByRole("manageBrands"),
            manageRoles: hasAccessByRole("manageRoles"),
            staff: hasAccessByRole("staff") && !corporateAccess,
            networkManagement: hasAccessByRole("networkManagement"),
            networkAdmin: hasAccessByRole("networkAdmin"),
            wifiOrMobile:
                (hasAccessByRole("productWIFI") && agregatedPermissions?.includes("productWIFI")) ||
                (hasAccessByRole("productMOBILE") && agregatedPermissions?.includes("productMOBILE")),
            taxManagement:
                hasAccessByRole("taxManagement") && hasProjectPermission("taxManagement") && hasCorporateUser,
        },
        legacy: {
            wifi:
                (hasAccessByRole("networkManagement") || hasAccessByRole("networkAdmin")) &&
                hasProjectPermission("legacyWIFI"),
            tv: hasAccessByRole("legacyAdmin") && hasProjectPermission("legacyTV"),
            // Legacy sales can't coexist with cloud sales, so in development mode (where perms are always true) we force it to be false
            sales: hasAccessByRole("legacyAdmin") && hasProjectPermission("legacySALES") && !Session.develMode(),
            mobile: hasAccessByRole("legacyAdmin") && hasProjectPermission("legacyMOBILE"),
            guest: hasAccessByRole("legacyAdmin") && hasProjectPermission("legacyGUEST"),
        },
        sales: {
            wdgParental: hasAccessByRole("wdgParental") && permissions.includes("wdgParental"),
        },
        services: {
            productSALES: !corporateAccess && hasAccessByRole("productSALES") && permissions.includes("productSALES"),
            shopsAdministrator:
                !corporateAccess &&
                hasAccessByRole("shopsAdministrator") &&
                (permissions.includes("shopsManager") || permissions.includes("shopsAdministrator")),
            shopsManager:
                (!corporateAccess &&
                    hasAccessByRole("shopsManager") &&
                    (permissions.includes("shopsManager") || permissions.includes("shopsAdministrator"))) ||
                (!corporateAccess &&
                    hasAccessByRole("shopsAdministrator") &&
                    (permissions.includes("shopsManager") || permissions.includes("shopsAdministrator"))),
            shopsOrders: !corporateAccess && hasAccessByRole("shopsOrders") && permissions.includes("shopsOrders"),
            shopsGeneralconfig:
                (!corporateAccess &&
                    hasAccessByRole("shopsGeneralconfig") &&
                    permissions.includes("shopsGeneralconfig")) ||
                (corporateAccess &&
                    hasAccessByRole("shopsGeneralconfig") &&
                    agregatedPermissions?.includes("shopsGeneralconfig")),
            myOrders: hasAccessByRole("myOrders") && hasProjectPermission("myOrders"),
            channelList: !corporateAccess && hasProjectPermission("channelList") && hasAccessByRole("channelList"),
            channelGroups:
                !corporateAccess && hasProjectPermission("channelGroups") && hasAccessByRole("channelGroups"),
            channelGrids: !corporateAccess && hasProjectPermission("channelGrids") && hasAccessByRole("channelGrids"),
            multipleGrids: !corporateAccess && hasProjectPermission("multipleGrids"),
            corporateChannelsInGlobalManagement:
                corporateAccess &&
                hasAccessByRole("corporateChannels") &&
                agregatedPermissions?.includes("corporateChannels") &&
                hasCorporateUser,
            corporateChannelsWithoutGlobalManagement: hasAccessByRole("corporateChannels") && !hasCorporateUser,
            predefinedChannels: isSuperUser && hasProjectPermission("productCHANNELS"),
            checkoutInterface: hasAccessByRole("checkoutInterface") && hasProjectPermission("checkoutInterface"),
            channelSettings:
                !corporateAccess && hasProjectPermission("channelSettings") && hasAccessByRole("channelSettings"),
            corporateChannels:
                (!corporateAccess &&
                    hasAccessByRole("corporateChannels") &&
                    permissions.includes("corporateChannels")) ||
                (corporateAccess &&
                    hasAccessByRole("corporateChannels") &&
                    agregatedPermissions?.includes("corporateChannels")),
        },
        widgets: [
            hasProjectPermission("wdgText") ? "TEXT" : null,
            hasProjectPermission("wdgButton") ? "BUTTON" : null,
            hasProjectPermission("wdgLogo") ? "LOGO" : null,
            hasProjectPermission("wdgImage") ? "IMAGE" : null,
            hasProjectPermission("wdgVideo") ? "VIDEO" : null,
            hasProjectPermission("wdgCarrousel") ? "CAROUSEL" : null,
            hasProjectPermission("wdgIcon") ? "ICON" : null,
            hasProjectPermission("wdgWeather") ? "WEATHER" : null,
            hasProjectPermission("wdgClock") ? "CLOCK" : null,
            hasProjectPermission("wdgCalendar") ? "CALENDAR" : null,
            hasProjectPermission("wdgMessages") ? "MESSAGES" : null,
            hasProjectPermission("wdgQrcode") ? "QR" : null,
            hasProjectPermission("wdgIframe") ? "IFRAME" : null,
            hasProjectPermission("wdgHtml") ? "HTML" : null,
            hasProjectPermission("wdgContainer") ? "CONTAINER" : null,
            hasProjectPermission("wdgChat") ? "CHATBUTTON" : null,
            hasProjectPermission("wdgAlarm") ? "ALARM" : null,
            hasProjectPermission("wdgLogin") ? "WIFILOGIN" : null,
            hasProjectPermission("interfaceMenu") ? "TVMENU" : null,
            hasProjectPermission("wdgNavigElements") ? "TVMENUNAV" : null,
            hasProjectPermission("wdgSlider") ? "SLIDER" : null,
        ],
        actions: {
            tvInputs: hasProjectPermission("actTvinputs"),
            bill: hasProjectPermission("actBill"),
            areas: hasProjectPermission("actAreas"),
            bookings: hasProjectPermission("actMybookings"),
            pos: hasProjectPermission("actPos"),
            surverys: hasProjectPermission("actSurveys"),
            checkout: hasProjectPermission("actCheckout"),
            channel: hasProjectPermission("actChannel"),
            listChannels: hasProjectPermission("actListChannels"),
            sales: hasProjectPermission("actSales"),
            actParental: hasProjectPermission("actParental"),
            customQr: hasProjectPermission("customQr"),
        },
        tags: [
            hasProjectPermission("tagRoomnumber") ? "roomNumber" : null,
            hasProjectPermission("tagRoomname") ? "roomName" : null,
            hasProjectPermission("tagGuesttitle") ? "guestTitle" : null,
            hasProjectPermission("tagGuestname") ? "guestName" : null,
            hasProjectPermission("tagGuestsurname") ? "guestSurname" : null,
            hasProjectPermission("tagGuestfullname") ? "guestFullname" : null,
            hasProjectPermission("tagGuestgroup") ? "guestGroup" : null,
            hasProjectPermission("tagChekcoutdate") ? "checkoutDate" : null,
            hasProjectPermission("tagPairingurl") ? "pairingURL" : null,
            hasProjectPermission("tagRoomcode") ? "pairingCode" : null,
            hasProjectPermission("tagWifissid") ? "wifiSSID" : null,
            hasProjectPermission("tagWifipassword") ? "wifiPassword" : null,
            hasProjectPermission("tagChromecastname") ? "CCName" : null,
        ],
    };
};
export default ComposePermissionsTree;
