import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";
import CustomColorPicker from "../Section/Design/Widgets/WidgetToolbars/OptionsDetails/CustomColorPicker";
import { useDispatch } from "react-redux";
import { setColorPickerReference } from "../../actions/designActions";
import UseInputText from "../Table/useInputText";
import UseInputText2 from "../Inputs/useInputText";
import { useSelector } from "react-redux";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import UseRadio from "../useRadio";
import { setExecuteQuery } from "../../actions/sectionActions";
import { hexToRgbA } from "../../hooks/Utils/DesignUtils";
import { rgba2hex } from "../../hooks/Utils/ColorUtils";
import UseInputPercent from "../Inputs/useInputPercent";
import { toast } from "react-toastify";
import { ACCEPTEDFORMATS, checkFileType } from "../../hooks/Utils/FileUtils";
import { executeVendureQuery, getVendureApiData } from "../../hooks/Utils/Integrations/useVendure";
import { setRefreshContentShopData } from "../../actions/sectionActions";
import { changeActionValues } from "../../actions/globalActions";
import { categoriesPrefix, getAssetUrl, getProjectLangs } from "../../hooks/Utils/SalesUtils";

const UseModalNewCategory = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { projectLangs } = useSelector((state) => state.ui);
    const { executeQuery, refreshContentShopData } = useSelector((state) => state.sectionContent);
    const { idShop, levelCategory, idCategory } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { validations } = useSelector((state) => state.action);

    const [langDefault, setLangDefault] = useState("");
    const [option, setOption] = useState("");
    const [optionCategory, setOptionCategory] = useState("");
    const [colorHex, setColorHex] = useState(null);
    const [colorRgba, setColorRgba] = useState(null);
    const [tokenShop, setTokenShop] = useState(null);
    const [colorAlpha, setAlpha] = useState(100);
    const [disabledImageButton, setImageDisabledButton] = useState(false);
    const [imgSrc, setImageSrc] = useState(null);

    const NAME_CATEGORY = "name-category-shop";
    const IMG_CATEGORY_SHOP = "img-category-shop";
    const ID_CATEGORY_FACET = "id-category-facet-shop";
    const TYPE_CONTENT = "type-category-shop";
    const DEF_LANGUAGE_CATEGORY = "def-lang-category-shop";
    const LEVEL_CATEGORY = "level-category-shop";
    const ID_PARENT_CATEGORY = "id-category-parent";
    const IMAGE = "IMAGE";
    const COLOR = "COLOR";
    const SUBCATEGORIES = "SUBCATEGORIES";
    const PRODUCTS = "PRODUCTS";

    const rgbaColor =
        executeQuery &&
        executeQuery.action &&
        executeQuery.action === "update-appearance-colors" &&
        executeQuery.params &&
        executeQuery.params.property &&
        executeQuery.params.property === "newCategory-bgColor" &&
        executeQuery.params.value
            ? executeQuery.params.value
            : null;

    const imgPreview =
        executeQuery && executeQuery.params && executeQuery.params.preview ? executeQuery.params.preview : null;
    const disableRadioSubcategories = levelCategory === 2 ? true : false;

    const GET_SHOP_DATA = `
    query {
        channel(id: ${idShop}) {
          token
        }
    }`;

    const GET_FACETS_VALUES = `
    query GetFacetList {
        facets {
          items {
            id
            code
          }
        }
      }
    `;

    const refetch = async () => {
        const response = await executeVendureQuery(getVendureApiData(), { queryBody: GET_SHOP_DATA });
        if (response && response.data && !response.errors) {
            if (response.data.channel && response.data.channel.token) {
                setTokenShop(response.data.channel.token);
                dispatch(
                    changeActionValues({
                        tokenShop: response.data.channel.token,
                    })
                );
            }
        } else {
            toast.error(t("mutation-error"));
        }
    };

    const fetchFacets = async () => {
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: GET_FACETS_VALUES },
            { "vendure-token": tokenShop }
        );
        if (response && response.data && !response.errors) {
            if (response.data.facets.items) {
                const idCategoryFacet = response.data.facets.items.find((item) => {
                    return item.code === `${categoriesPrefix}_${tokenShop}`;
                })?.id;
                dispatch(
                    changeActionValues({
                        [ID_CATEGORY_FACET]: idCategoryFacet,
                    })
                );
            }
        } else {
            toast.error(t("mutation-error"));
        }
    };

    useEffect(() => {
        setOption(COLOR);
        // setOptionCategory(SUBCATEGORIES);
        refetch();
        return () => {
            toast.dismiss();
            dispatch(setExecuteQuery(null));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(
            changeActionValues({
                [LEVEL_CATEGORY]: levelCategory,
            })
        );
        // eslint-disable-next-line
    }, [levelCategory]);

    useEffect(() => {
        if (tokenShop) {
            fetchFacets();
        }
        // eslint-disable-next-line
    }, [tokenShop]);

    useEffect(() => {
        if (levelCategory > 0) {
            dispatch(
                changeActionValues({
                    [ID_PARENT_CATEGORY]: idCategory,
                })
            );
        }
        if (levelCategory === 2) {
            setOptionCategory(PRODUCTS);
        }
        // eslint-disable-next-line
    }, [levelCategory]);

    useEffect(() => {
        if (projectLangs) {
            setLangDefault(getProjectLangs(projectLangs, t).langDefault);
            dispatch(
                changeActionValues({
                    [DEF_LANGUAGE_CATEGORY]: getProjectLangs(projectLangs, t).langDefault,
                })
            );
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        if (rgbaColor) {
            setColorHex(rgba2hex(rgbaColor));
            setColorRgba(rgbaColor);
            setAlpha(Math.round(Number(rgbaColor.replace(")", "").split(",")[3]) * 100));
        }
        // eslint-disable-next-line
    }, [rgbaColor]);

    useEffect(() => {
        if (imgPreview) {
            setImageSrc(imgPreview);
        }
        // eslint-disable-next-line
    }, [imgPreview]);

    useEffect(() => {
        if (optionCategory) {
            if (optionCategory === PRODUCTS) {
                dispatch(
                    changeActionValues({
                        [TYPE_CONTENT]: PRODUCTS,
                    })
                );
            } else if (optionCategory === SUBCATEGORIES) {
                dispatch(
                    changeActionValues({
                        [TYPE_CONTENT]: SUBCATEGORIES,
                    })
                );
            }
        }
        // eslint-disable-next-line
    }, [optionCategory]);

    useEffect(() => {
        if (option) {
            if (option === IMAGE) {
                dispatch(
                    changeActionValues({
                        [IMG_CATEGORY_SHOP]: imgSrc,
                    })
                );
            } else if (option === COLOR) {
                dispatch(
                    changeActionValues({
                        [IMG_CATEGORY_SHOP]: colorRgba,
                    })
                );
            }
        }
        // eslint-disable-next-line
    }, [option, imgSrc, colorRgba]);

    useEffect(() => {
        if (refreshContentShopData?.state) {
            setImageDisabledButton(false);
        }
    }, [refreshContentShopData]);

    const handleChangeImageInput = (e) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            Array.from(files).forEach((file) => {
                const validImage = checkFileType(file, ACCEPTEDFORMATS["image"]);

                if (!validImage) {
                    toast.error(`${file.name} ${t("error-file-not-valid-dataType")}`);
                    return;
                } else if (!file.size || (validImage && file.size / 1000 / 1000 > 11)) {
                    toast.error(`${file.name} ${t("error-exceeds-allowed-size")}`);
                    return;
                } else {
                    dispatch(
                        setExecuteQuery({
                            action: "create-asset-image-categorie",
                            params: {
                                file: file,
                                idShop: idShop,
                                token: tokenShop,
                            },
                        })
                    );
                    dispatch(setRefreshContentShopData({ action: "uploading" }));
                    setImageDisabledButton(true);
                }
            });
        }
    };

    const handleChangeHex = (hex) => {
        let colorData = null;

        try {
            colorData = hexToRgbA(hex, colorAlpha);
        } catch {
            colorData = "rgba(255,255,255,1)";
        }

        dispatch(
            setExecuteQuery({
                action: "update-appearance-colors",
                params: {
                    property: "newCategory-bgColor",
                    value: colorData,
                },
            })
        );
    };

    const handleChangeAlpha = (alpha) => {
        let colorData;
        colorData = hexToRgbA(colorHex, alpha);
        dispatch(
            setExecuteQuery({
                action: "update-appearance-colors",
                params: {
                    property: "newCategory-bgColor",
                    value: colorData,
                },
            })
        );
    };

    return (
        <div
            onClick={() => {
                dispatch(setColorPickerReference(null));
            }}
        >
            <CustomColorPicker customAction={"update-appearance-colors"} />
            <div className=" mb-4">
                <span>{`${levelCategory <= 0 ? t("configure-new-category") : t("configure-new-subcategory")}.`}</span>
            </div>
            <div className=" mb-4 font-bold">
                <span> {`${t("category-name")} *`} </span>
            </div>

            <div className=" flex items-center justify-between  w-full mb-8 ">
                <div className=" whitespace-nowrap ">
                    <span> {`${t("language:" + langDefault)} (${capitalizeFirst(t("default-lang"))})`} </span>
                </div>
                <div className=" flex-grow ml-4">
                    <UseInputText
                        id={NAME_CATEGORY}
                        validation={validations[NAME_CATEGORY] === false ? false : true}
                        inputData={{ name: NAME_CATEGORY }}
                    />
                </div>
            </div>
            {levelCategory === 0 ? (
                <div className=" w-full ">
                    <div className=" flex items-center h-full font-bold">
                        <span className=" mr-3">{capitalizeFirst(t("image"))}</span>
                        <span
                            data-tip={t("recommend-resolution-minimum-77")}
                            data-for="image-categories"
                            className=" icon icon-info text-gray-700 text-2xl"
                        ></span>
                    </div>
                </div>
            ) : null}
            {levelCategory === 0 ? (
                <div className=" flex mb-8">
                    <div className=" mt-4 relative mr-10 w-32 h-32 rounded border-gray-600 border">
                        {imgSrc && option === IMAGE ? (
                            <img
                                className=" w-full h-full rounded"
                                alt=""
                                style={{ objectFit: "cover" }}
                                src={getAssetUrl(imgSrc)}
                            />
                        ) : colorRgba && option === COLOR ? (
                            <div
                                className=" w-full h-full rounded"
                                style={{ backgroundColor: colorRgba ? colorRgba : null }}
                            ></div>
                        ) : (
                            <div className=" flex rounded items-center justify-center text-xs text-gray-700 h-full w-full bg-gray-100">
                                {t("not-image-yet")}
                            </div>
                        )}
                        {imgSrc && option === IMAGE ? (
                            <div className=" absolute flex items-center justify-center w-full h-8 bottom-0 left-0 bg-gray-700 bg-opacity-70 ">
                                <span
                                    className=" text-white cursor-pointer icon icon-delete text-lg"
                                    onClick={() => {
                                        setImageSrc(null);
                                    }}
                                ></span>
                            </div>
                        ) : null}
                    </div>

                    <div className=" flex flex-col mt-8  ">
                        <div className=" flex flex-col">
                            <UseRadio
                                id={"new-category-radio-color-picker"}
                                selected={option}
                                value={COLOR}
                                handleClick={() => {
                                    setOption(COLOR);
                                }}
                            />
                            <div className=" ml-6 mb-10 w-full flex " style={{ marginTop: "-0.65rem" }}>
                                <div
                                    id={`new-category-color-picker-modal`}
                                    className={`rounded h-10 w-10 border bg-gray-200 border-gray-600 ${
                                        option !== COLOR || !colorRgba
                                            ? "icon-no-color font-bold text-45xl text-red-100 p-px "
                                            : ""
                                    } `}
                                    style={{
                                        backgroundColor:
                                            option === COLOR ? (colorHex ? colorHex : "#fffff") : "#ffffff",
                                        fontSize: "2.3rem",
                                    }}
                                    onClick={(e) => {
                                        if (option === COLOR) {
                                            dispatch(
                                                setColorPickerReference({
                                                    id: `new-category-color-picker-modal`,
                                                    property: `newCategory-bgColor`,
                                                    color: colorRgba ? colorRgba : null,
                                                })
                                            );
                                            e.stopPropagation();
                                        }
                                    }}
                                ></div>

                                <div className=" w-20 ml-2 ">
                                    <UseInputText2
                                        id={"shop-bg"}
                                        name={"background-code"}
                                        key={colorHex}
                                        ph={"#"}
                                        adjust={"w-full h-10"}
                                        value={colorHex ? colorHex : null}
                                        disabled={option !== COLOR}
                                        action={handleChangeHex}
                                    />
                                </div>
                                <div className=" w-24 ml-2 flex ">
                                    <UseInputPercent
                                        id={"shop-settings-image-color-picker"}
                                        adjust={"w-full h-10 text-center"}
                                        adjustArrowTop={"text-sm pb-2"}
                                        adjustArrowBottom={"text-sm"}
                                        key={colorAlpha}
                                        value={colorAlpha ? colorAlpha : 100}
                                        disabled={option !== COLOR || colorRgba === null}
                                        action={handleChangeAlpha}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className=" flex flex-col">
                            <UseRadio
                                id={"new-category-radio-image-picker"}
                                selected={option}
                                value={IMAGE}
                                handleClick={() => {
                                    setOption(IMAGE);
                                }}
                            />
                            <div
                                className=" ml-6 mb-6 w-full "
                                style={{ marginTop: "-0.65rem" }}
                                onClick={() => {
                                    if (option === COLOR || disabledImageButton) {
                                        return;
                                    }
                                }}
                            >
                                <span
                                    id="getImageShopNewCategoryButton"
                                    className={` inline-flex items-center justify-center h-10 rounded border w-auto px-3 text-base text-white ${
                                        option === COLOR || disabledImageButton
                                            ? " bg-gray-400 cursor-default "
                                            : " bg-zafiro-600 cursor-pointer "
                                    }  `}
                                    onClick={() => {
                                        const inputFyle = document.getElementById("getImageShopNewCategory");
                                        if (option === IMAGE && inputFyle && !disabledImageButton) {
                                            inputFyle.click();
                                        }
                                    }}
                                >
                                    {capitalizeFirst(t("choose-an-image"))}
                                </span>
                                <input
                                    type="file"
                                    id="getImageShopNewCategory"
                                    style={{ display: "none" }}
                                    accept="image/png, image/jpeg, image/webp, image/avif"
                                    multiple={false}
                                    onChange={(e) => {
                                        handleChangeImageInput(e);
                                    }}
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className=" flex flex-col">
                <span className=" font-bold mb-2"> {`${t("type-of-content")} *`} </span>
                <span className=" mb-4">
                    {`${
                        levelCategory <= 0
                            ? t("select-the-type-of-content-category")
                            : t("select-the-type-of-content-subcategory")
                    }.`}
                </span>

                <div className=" relative mb-4">
                    {disableRadioSubcategories ? (
                        <div
                            className=" bg-gray-200 rounded-full w-4 h-4 absolute top-0 left-0 "
                            style={{ marginTop: "0.05rem", outline: "1px solid", outlineColor: "#E9EDF0" }}
                        ></div>
                    ) : (
                        <div className=" relative">
                            {TYPE_CONTENT in validations && validations[TYPE_CONTENT] === false ? (
                                <span
                                    style={{ left: "-0.01rem", top: "0.01rem" }}
                                    className=" w-5 h-5 rounded-full bg-red-100 absolute"
                                ></span>
                            ) : null}
                            <UseRadio
                                id={"new-category-radio-subproduct-option"}
                                selected={optionCategory}
                                value={SUBCATEGORIES}
                                handleClick={() => {
                                    if (disableRadioSubcategories) {
                                        return;
                                    }
                                    setOptionCategory(SUBCATEGORIES);
                                }}
                            />
                        </div>
                    )}
                    <div
                        className={` ml-6 cursor-pointer ${
                            disableRadioSubcategories ? " text-gray-500" : "hover:text-zafiro-600"
                        }`}
                        id={NAME_CATEGORY + "-" + SUBCATEGORIES}
                        onClick={() => {
                            if (disableRadioSubcategories) {
                                return;
                            }
                            setOptionCategory(SUBCATEGORIES);
                        }}
                        style={{ marginTop: "-0.2rem" }}
                    >
                        {t("subcategories")}
                    </div>
                </div>
                <div className=" mb-4">
                    <div className="relative">
                        {TYPE_CONTENT in validations && validations[TYPE_CONTENT] === false ? (
                            <span
                                style={{ left: "-0.01rem" }}
                                className=" w-5 h-5 rounded-full bg-red-100 absolute"
                            ></span>
                        ) : null}
                        <UseRadio
                            id={"new-category-radio-product-option"}
                            selected={optionCategory}
                            value={PRODUCTS}
                            handleClick={() => {
                                setOptionCategory(PRODUCTS);
                            }}
                        />
                    </div>
                    <div
                        id={NAME_CATEGORY + "-" + PRODUCTS}
                        className=" ml-6 cursor-pointer hover:text-zafiro-600"
                        onClick={() => {
                            setOptionCategory(PRODUCTS);
                        }}
                        style={{ marginTop: "-0.2rem" }}
                    >
                        {t("products")}
                    </div>
                </div>
            </div>
            <ReactTooltip
                id="image-categories"
                place="bottom"
                type="light"
                offset={{ top: -8, left: -8 }}
                html={true}
                border={true}
                borderColor="#D3DAE1"
                wrapper="span"
            />
        </div>
    );
};

export default UseModalNewCategory;
