import { GetLanguageName } from "../../../Utils/LanguagesUtils";
import { Session } from "../../../Utils/Session";
import { calculateAge, bdDateTransform, capitalizeFirst } from "../../../Utils/Utils";
import DeleteCompanion from "../modals/DeleteCompanion";
import i18n from "i18n";
import EditDocumentation from "../modals/EditDocumentation";
import { GET_COLOR_AND_TEXT_DOCUMENTATION_STATUS } from "components/Section/Customers/Precheckin/ReservationDetails/utils";
import { GetNationalityName } from "hooks/Utils/NationalityUtils";
import { GetCountryName } from "hooks/Utils/CountriesUtils";

const Companion = (companion, rooms, data) => {
    const {
        id,
        title,
        name,
        surname,
        email,
        languageRef,
        birthDate,
        rooms: currentRooms,
        documentNumber,
        documentType,
        countryRef,
        phone,
        gender,
        documentExtraInformation,
    } = companion;

    const currentHotelData = Session.getSessionProp("currentHotel");
    const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};
    const precheckinAvailable = data?.precheckinAvailable ? true : false;
    const dataType = "companion";
    const genderVal = () => {
        if (gender && gender === "male") {
            return i18n.t("male-gender");
        } else if (gender && gender === "female") {
            return i18n.t("female-gender");
        } else {
            return "-";
        }
    };

    return {
        dataType: dataType,
        id: id,
        actions: [
            {
                type: dataType,
                value: id,
            },
        ],
        actionValues: {
            rooms: rooms,
            id: id,
        },
        data: {
            id,
            title,
            name,
            surname,
            countryRef,
            languageRef,
            gender,
            birthDate,
            email,
            phone,
            currentRooms,
            rooms,
        },
        name: `${name} ${surname}`,
        executeSectionQuery: true,
        blockGuestManagement: currentHotel?.blockGuestManagement ? true : false,
        info: [
            {
                name: "title",
                cellContent: "text",
                value: title,
                specialWidth: "w-1/12 ",
            },
            {
                name: "name",
                cellContent: "text",
                value: name,
                specialWidth: precheckinAvailable ? "w-2/12 " : "w-3/12",
                adjust: "truncate",
            },
            {
                name: "surname",
                cellContent: "text",
                value: surname,
                specialWidth: precheckinAvailable ? "w-2/12 ml-2 " : "w-3/12 ml-2 ",
                adjust: "truncate",
            },
            {
                name: "email",
                cellContent: "text",
                value: email,
                specialWidth: precheckinAvailable ? "w-2/12 ml-3 " : "w-3/12 ml-3 ",
                adjust: "truncate",
            },
            {
                name: "roomnumber",
                cellContent: "link",
                value: currentRooms && currentRooms[0] ? currentRooms[0].number : "",
                specialWidth: "w-1/12 ml-3 ",
                link: currentRooms && currentRooms[0] ? `/hotel/rooms/room/detail/${currentRooms[0].roomID}` : null,
                permissionToLink: { section: "hotel", attribute: "roomList" },
            },
            {
                name: "age",
                cellContent: "text",
                value: birthDate ? calculateAge(new window.ZDate(bdDateTransform(birthDate))) : "",
                birthday: birthDate,
                specialWidth: "w-1/12 ",
            },
            precheckinAvailable && {
                name: "age",
                cellContent: "text-action-cell",
                text: {
                    value: GET_COLOR_AND_TEXT_DOCUMENTATION_STATUS(documentNumber, documentType).text,
                    style: GET_COLOR_AND_TEXT_DOCUMENTATION_STATUS(documentNumber, documentType).color,
                },
                // text: { value: "aaa", style: "" },
                textAction: {
                    value: i18n.t("edit"),
                    style: "",
                    typeAction: "open-modal",
                    modal: () =>
                        EditDocumentation({
                            documentExtraInformation,
                            documentNumber,
                            documentType,
                            id,
                            name,
                            surname,
                        }),
                },
                specialWidth: "w-3/12 ml-2",
            },
            // {
            //     name: "language",
            //     cellContent: "text",
            //     value: languageRef ? GetLanguageName(languageRef) : "",
            //     specialWidth: "w-1/12",
            // },
            // !currentHotel.blockGuestManagement
            //     ? {
            //           name: "edit-companion",
            //           cellContent: "icon",
            //           value: id,
            //           evaluate: true,
            //           isAction: true,
            //           specialWidth: "w-0.5/12 text-right",
            //           icon: {
            //               name: "edit",
            //               id: id,
            //               itemType: dataType,
            //               action: "edit-companion",
            //           },
            //       }
            //     : {},
            // !currentHotel.blockGuestManagement
            //     ? {
            //           name: "companion",
            //           cellContent: "icon",
            //           value: id,
            //           evaluate: true,
            //           specialWidth: "w-0.5/12",
            //           isAction: true,
            //           modal: DeleteCompanion(id, `${name} ${surname}`),
            //           icon: {
            //               name: "delete",
            //               values: id,
            //               itemType: dataType,
            //           },
            //       }
            //     : { specialWidth: "w-1/12" },
        ],
        extendedInfo: [
            {
                name: "description",
                cellContent: "value-extended-info",
                columns: 2,
                columnStyle: "w-1/2",
                specialWidth: "w-full",
                columnsInfo: [
                    {
                        columnStyle: "w-1/2",
                        info: [
                            { label: "nationality", value: GetNationalityName(countryRef?.toLowerCase()) || "-" },
                            { label: "language", value: GetLanguageName(languageRef?.toLowerCase()) || "-" },
                        ],
                    },
                    {
                        columnStyle: "w-1/2",
                        info: [
                            { label: "phone", value: phone || "-" },
                            { label: "gender", value: genderVal() },
                        ],
                    },
                ],
                // title: "description",
                // translateTitle: true,
                // // separator: true,
                // link: `/common-zones/zones-configuration/area/description/${12}`,
                // // icon: {
                // //     name: "edit",
                // //     itemType: "activity-description",
                // //     position: "right",
                // // },
                // // modal: EditDescriptionModal(area),
                // specialWidth: "w-6/12",
            },
        ],
    };
};

export default Companion;
