const EditProperty = (name, id, allBrandsForEdit = null) => {
    return [
        {
            title: [name, "-", "edit-property"],
            id: id,
            inputs: [
                {
                    name: name,
                    type: "data",
                },
                {
                    text: ["select-properties-groups", ":"],
                    name: "properties-groups",
                    type: "selectMultipleWithSearch",
                    optionData: allBrandsForEdit,
                    labelsColors: "bg-orange-100 text-white",
                    cStyle: "w-9/12",
                    translateOptions: true,
                    selectedIdsPath: "data.selected.results[0].brands",
                    selectPlaceHolder: "property-groups",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "save",
                    style: "blue",
                    action: "edit-property",
                },
            ],
        },
    ];
};

export default EditProperty;
