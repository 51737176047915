import GridFilters from "../modals/GridFilters";

const Grid = (data, t) => {
    const { id, name, numberGroups, numberFilters, isDefault, destination, multipleGrids, actionMakeGridEnabled } =
        data;

    let textMakeGridEnabled = "";

    if (multipleGrids) {
        textMakeGridEnabled = !isDefault ? `${t("make-default")}` : `${t("default")}`;
    } else {
        textMakeGridEnabled = !isDefault ? `${t("apply")}` : `${t("applied")}`;
    }

    return {
        dataType: "gridChannel",
        id: id,
        numberFilters: numberFilters,
        executeSectionQuery: true,
        adjust: "h-auto",
        actions: [
            {
                type: "gridChannel",
                value: id,
            },
        ],
        name: name,
        t: t,
        multipleGrids: multipleGrids,
        isDefault: isDefault,
        actionComponentStyle: { left: "-160px" },
        destination: destination,
        info: [
            {
                cellContent: "link",
                value: name,
                link: `/services/tv-channels/grids/${id}`,
                specialWidth: "w-3/12 pl-1",
                wrap: "truncate",
                adjust: "truncate",
            },
            {
                cellContent: "text",
                value: `${numberGroups} ${t("groups")}`,
                specialWidth: "w-3/12 pl-1",
                wrap: "truncate",
            },
            {
                cellContent: "button",
                modal: numberFilters > 0 && multipleGrids ? GridFilters({ name, destination }) : null,
                adjust: numberFilters > 0 ? " font-bold text-zafiro-600 cursor-pointer px-0" : " px-0",
                name: !isDefault ? `${numberFilters} ${t("filters")}` : "",
                specialWidth: multipleGrids ? "w-3/12 pl-1" : "w-3/12 pl-1 opacity-0",
                wrap: "truncate",
            },
            {
                cellContent: "button",
                adjust: !isDefault
                    ? " text-zafiro-600 cursor-pointer"
                    : " text-white px-2 bg-zafiro-400 rounded w-auto w-fit-content ",
                name: textMakeGridEnabled,
                action: !isDefault
                    ? () => {
                          if (typeof actionMakeGridEnabled === "function") {
                              actionMakeGridEnabled({ id, name });
                          }
                      }
                    : null,
                specialWidth: "w-3/12 pl-1",
                wrap: "truncate",
            },
        ],
    };
};

export default Grid;
