import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";
import {
    MUTATION_OBJECT,
    PENDING_VALIDATION_CAST_NETWORKS,
    VALIDATE_CAST_NETWORKS,
} from "../services/getReportQueries";

export const useGetReport = () => {
    const [isPending, setIsPending] = useState(true);
    const [textData, setTextData] = useState(null);
    const [control, setControl] = useState(null);

    const [executeMutation, { data: mutationData }] = useMutation(MUTATION_OBJECT);

    const [executeQuery, { data }] = useLazyQuery(PENDING_VALIDATION_CAST_NETWORKS, {
        pollInterval: 20000,
    });

    const [executeValidateCastNetwork, { data: networkValidated }] = useLazyQuery(VALIDATE_CAST_NETWORKS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeMutation();
    }, []);

    useEffect(() => {
        if (mutationData?.validateCastNetworks?.ok) {
            executeQuery();
        }
    }, [mutationData?.validateCastNetworks?.ok]);

    useEffect(() => {
        if (data?.pendingValidateCastNetwork?.isPending) {
            setControl(data?.pendingValidateCastNetwork?.isPending);
        } else if (control && !data?.pendingValidateCastNetwork?.isPending) {
            setControl(false);
        }
    }, [data?.pendingValidateCastNetwork?.isPending]);

    useEffect(() => {
        if (control === false) {
            executeValidateCastNetwork();
        }
    }, [control]);

    useEffect(() => {
        if (
            networkValidated?.getValidateCastNetworks?.data?.information &&
            data?.pendingValidateCastNetwork?.isPending === false
        ) {
            setTextData({
                information: networkValidated?.getValidateCastNetworks?.data?.information,
                isValid: networkValidated?.getValidateCastNetworks?.data?.isValid,
                lastUpdate: networkValidated?.getValidateCastNetworks?.lastUpdate,
            });
            setIsPending(false);
        }
    }, [networkValidated, data?.pendingValidateCastNetwork?.isPending]);

    return { isPending, textData };
};
