import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";
//Utils
import ListAreas from "../../../hooks/GraphqlCalls/CommonZones/ListAreas";

const Areas = () => {
    ListAreas();

    return (
        <>
            <UseSectionHeader title={["areas-list"]} navToPreviousPage={true} />
            <UseTable />
        </>
    );
};

export default Areas;
