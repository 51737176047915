import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseSelectOption from "./useSelectOption";
//Actions
import {
    setActiveFilters,
    getFilterQuery,
    setActiveParams,
    getParamsQuery,
    setLoading,
    setPage,
} from "../../actions/tableActions";
//Utils
import useComponentVisible from "../../components/useComponentVisible";
import { useTranslation } from "react-i18next";

const UseSelectSearch = ({ index, value }) => {
    const { t } = useTranslation();

    //Store data
    const { name, label, data, itemType, icons, translatable, addParamToQuery } = useSelector(
        (state) => state.table.availableFilters[index]
    );
    const activeParams = useSelector((state) => state.table.activeParams);
    const activeFilters = useSelector((state) => state.table.activeFilters);
    const activeValue = activeFilters[name];
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [selectActive, setSelectActive] = useState({});
    const [activeParam, setActiveParam] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(data);

    //Actions
    const dispatch = useDispatch();

    const changeHandler = (e) => {
        let newOptions = [];
        const term = e.target.value;
        //If it wasn´t left empty
        if (e.target.value !== "") {
            if (!Array.isArray(data[1])) {
                newOptions = data.filter((item) => item.toLowerCase().indexOf(term.toLowerCase()) > -1);
                setFilteredOptions(newOptions);
            } else {
                newOptions = data.filter(
                    (item) => item === "" || item[0].toLowerCase().indexOf(term.toLowerCase()) > -1
                );
                setFilteredOptions(newOptions);
            }
        } else {
            setFilteredOptions(data);
        }
    };

    const setSelectValue = (option) => {
        if (option) {
            const [name, value, type] = option;
            handleChangeFilters({
                currentTarget: {
                    dataset: {
                        type: type,
                        value: value,
                        name: name,
                        optId: name,
                    },
                },
            });
        }
    };

    const handleChangeFilters = (e) => {
        if (e.currentTarget.dataset.value === activeValue) {
            setIsComponentVisible(false);
            return;
        }
        if (e.currentTarget.dataset.value || e.currentTarget.dataset.optId) {
            dispatch(setLoading(true));
            dispatch(setPage(Number(1)));
        }

        if (!addParamToQuery) {
            if (e.currentTarget.dataset.type === "int") {
                dispatch(setActiveFilters(name, parseInt(e.currentTarget.dataset.value)));
                dispatch(
                    getFilterQuery({
                        ...activeFilters,
                        [name === "responsible" ? "lastCleaningStaffId" : name]: parseInt(
                            e.currentTarget.dataset.value
                        ),
                    })
                );
            } else if (e.currentTarget.dataset.type === "str" || e.currentTarget.dataset.value) {
                dispatch(setActiveFilters(name, e.currentTarget.dataset.value));
                dispatch(
                    getFilterQuery({
                        ...activeFilters,
                        [name]: e.currentTarget.dataset.value,
                    })
                );
            } else {
                dispatch(setActiveFilters(name, ""));
                delete activeFilters[name];
                dispatch(getFilterQuery(activeFilters));
            }
        } else {
            if (e.currentTarget.dataset.value) {
                if (name === "category") {
                    dispatch(setActiveParams(name, e.currentTarget.dataset.value));
                    dispatch(getParamsQuery(activeParams));
                    setActiveParam(e.currentTarget.dataset.name);
                } else {
                    dispatch(setActiveParams(name, e.currentTarget.dataset.optId));
                    dispatch(getParamsQuery(activeParams));
                }
            } else {
                dispatch(getParamsQuery(""));
                dispatch(setActiveParams(name, ""));
                setActiveParam("");
            }
        }
        setIsComponentVisible(false);
    };

    const changeVisibility = () => {
        if (!isComponentVisible) {
            setTimeout(function () {
                const inputSearch = document.querySelector(`#${name}_input`);
                if (inputSearch) {
                    inputSearch.focus();
                }
            }, 150);
        }
        setIsComponentVisible(isComponentVisible ? false : true);
    };

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        setSelectValue(value);
        // eslint-disable-next-line
    }, []);
    return (
        <div ref={ref} className={`w-full h-10 bg-gray-200 rounded relative ${selectActive.container}`}>
            <div
                className={`t-filter-ph  first-capital ${selectActive.text} ${
                    activeValue || activeParam ? "text-black" : "text-gray-600"
                }`}
                id={name ? `${name}-${itemType ? itemType : ""}-select-search` : null}
                onClick={() => changeVisibility()}
            >
                {getCurrentText({ activeValue, activeParam, label, name, translatable, data, t })}
            </div>
            <span
                className={`field-icon icon-chevron animated ${selectActive.icon}`}
                onClick={() => changeVisibility()}
            ></span>
            {isComponentVisible ? (
                <div className="t-options-container">
                    <div>
                        <input
                            id={`${name}_input`}
                            type="text"
                            className="t-filter-search"
                            style={{ width: "calc(100% - 1rem)" }}
                            placeholder={`${t("search")}...`}
                            onChange={changeHandler}
                        ></input>
                        <span className="field-icon icon-search table mt-5" style={{ height: "2rem" }}></span>
                    </div>
                    <div className="with-scroll m-2">
                        {filteredOptions.map((option, index) => (
                            <span key={Array.isArray(option) ? option[0] : option}>
                                {Array.isArray(option) ? (
                                    <div
                                        className="h-10 w-full hover:bg-gray-100 "
                                        onClick={handleChangeFilters}
                                        id={
                                            itemType
                                                ? `option-${itemType}-${index}${option[0] ? `-${option[0]}` : "-all"}`
                                                : null
                                        }
                                        data-opt-id={option[0]}
                                        data-id={option[1]}
                                        data-type={getOptionType({ option })}
                                        data-value={option[2] === "int" || option[2] === "str" ? option[1] : ""}
                                        data-name={option[0]}
                                    >
                                        <UseSelectOption
                                            filterName={name}
                                            filterValue={option[0]}
                                            icons={icons}
                                            itemType={itemType}
                                            translatable={translatable}
                                            selected={activeValue}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="h-10 w-full hover:bg-gray-100 "
                                        onClick={handleChangeFilters}
                                        id={itemType ? `option-${itemType}-${index}-all` : null}
                                        data-opt-id={option}
                                        data-value={option}
                                    >
                                        <UseSelectOption
                                            filterName={name}
                                            filterValue={option}
                                            icons={icons}
                                            itemType={itemType}
                                            translatable={translatable}
                                            selected={activeValue}
                                        />
                                    </div>
                                )}
                            </span>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const getOptionType = (props) => {
    let response = "";
    const { option } = props;
    if (option) {
        if (option[2] === "int") {
            response = "int";
        } else if (option[2] === "str") {
            response = "str";
        }
    }
    return response;
};

const getCurrentText = (props) => {
    let response = "";
    const { activeValue, activeParam, label, name, translatable, data, t } = props;
    if (!activeValue && !activeParam) {
        response += t(label ? label : name);
    }
    if (activeValue) {
        if (translatable || activeValue === "") {
            response += t(activeValue);
        } else if (
            data.filter((item) => String(item[1]) === String(activeValue)) &&
            data.filter((item) => String(item[1]) === String(activeValue))[0]
        ) {
            response += data.filter((item) => String(item[1]) === String(activeValue))[0][0];
        } else {
            response += activeValue;
        }
    }
    if (activeParam) {
        response += translatable || activeValue === "" ? t(activeParam) : activeParam;
    }
    return response;
};

export default UseSelectSearch;
