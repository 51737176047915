const NewLabel = (id, code, translations, facetId) => {
    const isNew = !id;
    return {
        title: isNew ? "add-label" : "edit-label",
        text: "add-label-description",
        bodyAdjust: "w-4/12",
        executeSectionQuery: true,
        id: id,
        inputs: [
            {
                name: "translations",
                type: "general-translations",
                style: "",
                data: { translations: translations },
            },
            {
                name: "code",
                value: code,
                type: "text",
                style: "hidden",
            },
            {
                name: "facetId",
                value: facetId,
                type: "text",
                style: "hidden",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: isNew ? "add-label" : "edit-label",
            },
        ],
    };
};

export default NewLabel;
