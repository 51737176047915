import { useTranslation } from "react-i18next";

export const DELIVERY_METHOD_ROOM = "delivery";
export const DELIVERY_METHOD_PICKUP = "pickup";
export const DELIVERY_METHOD_GEOLOCATION = "geolocation";
export const DELIVERY_METHODS = [DELIVERY_METHOD_ROOM, DELIVERY_METHOD_PICKUP]; //, DELIVERY_METHOD_GEOLOCATION];

export const ORDER_STATUS_CANCELLED = "Cancelled";
export const ORDER_STATUS_DELIVERED = "Delivered";
export const ORDER_STATUS_INPROGRESS = "InProgress";
export const ORDER_STATUS_PENDING = "Pending";
export const ORDER_STATUS_READY = "Ready";
export const ORDER_STATUS_EXPIRED = "Expired";
export const ORDER_STATUS_DELIVERY_FAILED = "DeliveryFailed";
export const ORDER_STATUS_UNKNOWN = "Unknown";

export const ORDER_DELAYED = "Delayed";

export const FILTER_ORDER_STATUS_CANCELLED = "cancelled";
export const FILTER_ORDER_STATUS_EXPIRED = "expired";
export const FILTER_ORDER_STATUS_PENDING = "pending";
export const FILTER_ORDER_STATUS_INPROGRESS = "inprogress";
export const FILTER_ORDER_STATUS_DELIVERY_FAILED = "delivery failed";
export const FILTER_ORDER_STATUS_READY = "ready";
export const FILTER_ORDER_STATUS_DELIVERED = "delivered";

export const FILTER_ORDER_DELAYED = "delayed";

/**
 * Get Order status display text
 * @param {string} status - Order status
 * @returns {string} - Translation key
 */
export const getStatusText = (status) => {
    if (!status) {
        return null;
    }
    switch (status) {
        case ORDER_STATUS_CANCELLED:
            return "cancelled";
        case ORDER_STATUS_DELIVERY_FAILED:
            return "delivery failed";
        case ORDER_STATUS_DELIVERED:
            return "delivered";
        case ORDER_STATUS_INPROGRESS:
            return "in progress";
        case ORDER_STATUS_PENDING:
            return "pending";
        case ORDER_STATUS_READY:
            return "ready";
        case ORDER_STATUS_EXPIRED:
            return "expired";
        case ORDER_STATUS_UNKNOWN:
            return "unknown";
        case ORDER_DELAYED:
            return "delayed";
        default:
            return `${status}??`;
    }
};

/**
 * Get Order status color class
 * @param {string} status - Order status
 * @returns {string} - Tailwind CSS color class
 */
export const getStatusColor = (status) => {
    switch (status) {
        case ORDER_STATUS_CANCELLED:
        case ORDER_DELAYED:
            return "text-red-100";
        case ORDER_STATUS_DELIVERED:
        case ORDER_STATUS_INPROGRESS:
        case ORDER_STATUS_READY:
            return "text-green-100";
        case ORDER_STATUS_DELIVERY_FAILED:
        case ORDER_STATUS_PENDING:
            return "text-orange-100";
        case ORDER_STATUS_EXPIRED:
        case ORDER_STATUS_UNKNOWN:
            return "text-gray-700";
        default:
            return null;
    }
};

export const getDeliveryMethodText = (method) => {
    switch (method) {
        case DELIVERY_METHOD_ROOM:
            return "room-service";
        case DELIVERY_METHOD_PICKUP:
            return "pick-up";
        case DELIVERY_METHOD_GEOLOCATION:
            return "custom-location";
        default:
            return method + "??";
    }
};

export const useDeliveryLocationInfo = () => {
    const { t } = useTranslation();

    return (method, location, orderInfo) => {
        if (parseInt(location) && orderInfo?.customFields?.pickUp?.location) {
            location = orderInfo?.customFields?.pickUp?.location;
        }

        switch (method) {
            case DELIVERY_METHOD_ROOM:
                return {
                    name: t("room-service"),
                    place: location || t("room"),
                };
            case DELIVERY_METHOD_PICKUP:
                return {
                    name: t("pick-up-location"),
                    place: location || t("unknown"),
                };
            case DELIVERY_METHOD_GEOLOCATION: {
                let coords = null;
                if (location) {
                    try {
                        coords = JSON.parse(location);
                    } catch (e) {
                        console.warn("Error parsing geolocation", e);
                    }
                }
                return {
                    name: t("custom-location"),
                    coords,
                    link: coords ? `https://www.google.com/maps/search/?api=1&query=${coords.lat},${coords.lng}` : null,
                };
            }
            default:
                return {
                    name: method + "??",
                    place: location,
                };
        }
    };
};

export const getDeliveryMethodIcon = (method) => {
    switch (method) {
        case DELIVERY_METHOD_ROOM:
            return "roomservice";
        case DELIVERY_METHOD_PICKUP:
            return "pick-up";
        case DELIVERY_METHOD_GEOLOCATION:
            return "custom-location";
        default:
            return "warning";
    }
};

export const sortDeliveryMethods = (methods) => {
    const sortOrder = {
        [DELIVERY_METHOD_ROOM]: 1,
        [DELIVERY_METHOD_PICKUP]: 2,
        [DELIVERY_METHOD_GEOLOCATION]: 3,
    };
    methods.sort((a, b) => {
        return (sortOrder[a] || 99) - (sortOrder[b] || 99);
    });
    return methods;
};

export const GEOLOCATION_MESSAGE_TEMPLATE = [
    {
        languageCode: "en",
        name: `Only orders with "as soon as possible" delivery and within the hotel outdoor facilities will be accepted.`,
    },
    {
        languageCode: "es",
        name: `Solo se aceptarán pedidos con entrega "lo antes posible" y dentro de las instalaciones exteriores del hotel.`,
    },
];
