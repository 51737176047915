import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";

const UpdateAccessProfiles = ({ client }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const [mutation, setMutation] = useState(`
    mutation {
        createHotspot(name: "test") {
          ok
          error
          id
        }
      }`);

    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            toast.error(err.message);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case "edit-access-profile-access-type":
                    let id = executeQuery.params.value ? executeQuery.params.value["id"] : executeQuery.params["id"];
                    let oldIDs = executeQuery.params.value
                        ? executeQuery.params.value["oldIDs"]
                        : executeQuery.params["oldIDs"];
                    let newIDs = executeQuery.params.value
                        ? executeQuery.params.value["newIDs"]
                        : executeQuery.params["newIDs"];
                    setMutation(`mutation{
                        updateWifiAccessProfile(id:${id}, oldIDs:[${oldIDs}], newIDs:[${newIDs}]){
                          ok
                          error
                          id
                        }
                      }`);
                    executeQuery.mutationName = "updateWifiAccessProfile";
                    executeQuery.cacheKeyToDelete = "ResultPost";
                    break;
                case "delete-access-profile":
                    setMutation(`
                        mutation{deleteWifiAccessProfile(id:  ${actionData.itemsAffected[0]}){
                            error
                            ok
                        }}
                    `);
                    executeQuery.mutationName = "deleteWifiAccessProfile";
                    executeQuery.cacheKeyToDelete = "ResultPost";
                    break;
                case "add-access-profile":
                    setMutation(`
                        mutation{
                            createWifiAccessProfile(
                                name: "${actionData.values["name"]}"
                                ${
                                    actionData.values["downloadbwmin"]
                                        ? `downloadBWMin: ${actionData.values["downloadbwmin"]}`
                                        : ``
                                }
                                ${
                                    actionData.values["uploadbwmax"]
                                        ? `uploadBWMax: ${actionData.values["uploadbwmax"]}`
                                        : ``
                                }
                                ${
                                    actionData.values["uploadbwmin"]
                                        ? `uploadBWMin: ${actionData.values["uploadbwmin"]}`
                                        : ``
                                }
                                ${
                                    actionData.values["downloadbwmax"]
                                        ? `downloadBWMax: ${actionData.values["downloadbwmax"]}`
                                        : ``
                                }
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "createWifiAccessProfile";
                    executeQuery.goBack = !executeQuery.params.noGoBack;
                    break;
                case "edit-access-profile":
                    setMutation(`
                        mutation{
                            updateWifiAccessProfile(
                                id: ${executeQuery.params.id}
                                name: "${actionData.values["name"]}"
                                downloadBWMin: ${
                                    actionData.values["downloadbwmin"] ? actionData.values["downloadbwmin"] : `0`
                                }
                                uploadBWMax: ${
                                    actionData.values["uploadbwmax"] ? actionData.values["uploadbwmax"] : `0`
                                }
                                uploadBWMin: ${
                                    actionData.values["uploadbwmin"] ? actionData.values["uploadbwmin"] : `0`
                                }
                                downloadBWMax: ${
                                    actionData.values["downloadbwmax"] ? actionData.values["downloadbwmax"] : `0`
                                }
                            ){ error ok }}
                    `);
                    executeQuery.mutationName = "updateWifiAccessProfile";
                    executeQuery.goBack = !executeQuery.params.noGoBack;
                    break;
                default:
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery && data[executeQuery.mutationName] && data[executeQuery.mutationName].ok === true) {
            toast.success(t("operation-successful"));
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }

            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(showGlobalLoading(false));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));

            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateAccessProfiles);
