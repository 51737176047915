import FilterCCsGuestUsersContext from "components/Section/Hotel/context/FilterCCsGuestUsersContext";
import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const CheckList = ({ filterData, title }) => {
    const [showFilter, setShowFilter] = useState(false);
    const [value, setValue] = useState(filterData?.ownership);
    const [selectedItems, setSelectedItems] = useState([]);
    const { setOwnership } = useContext(FilterCCsGuestUsersContext);
    const { t } = useTranslation();

    const handleClick = () => {
        setShowFilter(!showFilter);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showFilter && !event.target.closest(".bg-gray-200")) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFilter]);

    const handleOnChange = (index) => {
        const updatedModel = value.map((item, i) => {
            if (index === i) {
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });
        const selected = updatedModel.filter((sub) => sub.isChecked);
        setSelectedItems(selected);
        setOwnership(selected);
        setValue(updatedModel);
    };

    const handleRemoveSelectedItem = (index) => {
        const updatedModel = value.map((item) => {
            if (selectedItems[index].name === item.name && selectedItems[index].id === item.id) {
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });
        setValue(updatedModel);
        setSelectedItems(updatedModel.filter((sub) => sub.isChecked));
        setOwnership(updatedModel.filter((sub) => sub.isChecked));
    };

    useEffect(() => {
        return () => {
            setOwnership([]);
        };
    }, []);

    return (
        <div className="flex items-center my-8 relative">
            <div>
                <div className="mx-2 p-2 bg-gray-200 rounded relative">
                    <div onClick={handleClick} className="flex items-center justify-between gap-6 cursor-pointer w-48">
                        {selectedItems?.length > 0 ? (
                            <p className="ml-2 text-black">
                                {selectedItems.length} {capitalizeFirst(t("selected"))}
                            </p>
                        ) : (
                            <p className="ml-2 text-gray-600">{title}</p>
                        )}
                        <span
                            className={`${showFilter ? "-rotate-180 mr-4" : ""} icon-chevron animated field-icon`}
                        ></span>
                    </div>
                    {showFilter ? (
                        <div className="absolute bg-white border p-4 mt-2 top-10 w-full rounded-md z-100">
                            {selectedItems?.length > 0 ? (
                                <>
                                    <h5 className="mb-2 font-bold">{capitalizeFirst(t("selected"))}:</h5>
                                    <div className="flex flex-wrap gap-2 mb-6">
                                        {selectedItems?.map((item, index) => (
                                            <div
                                                className="bg-zafiro-400 items-center gap-2 rounded-lg px-2 py-1 flex text-white"
                                                key={item.name}
                                            >
                                                <span key={item.name}>{item.name}</span>
                                                <span
                                                    className="icon-remove text-white cursor-pointer"
                                                    onClick={() => handleRemoveSelectedItem(index)}
                                                ></span>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : null}
                            {value.map((item, index) => (
                                <div className="flex justify-center flex-col cursor-pointer" key={item?.name}>
                                    <div className={`flex items-center ${value.length > 1 ? "mb-4" : ""}`}>
                                        <input
                                            id={item?.name}
                                            type="checkbox"
                                            className={`w-5 h-5 checkbox z-50 block checkbox mr-2 ${
                                                item.isChecked ? "block" : "hidden"
                                            }`}
                                            value={item?.name}
                                            checked={value[index].isChecked}
                                            onChange={() => handleOnChange(index)}
                                        />
                                        <span
                                            id={item?.name}
                                            className={`w-5 h-5 bg-gray-200 hover:bg-gray-300 ${
                                                !item.isChecked ? "block" : "hidden"
                                            } mr-2`}
                                            onClick={() => handleOnChange(index)}
                                        ></span>
                                        <span className="hover:text-zafiro-400 text-gray-900">{item?.name}</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
