import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UseSwitch from "../../../Inputs/useSwitch";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import classNames from "classnames";
import { useNavigate as useHistory } from "react-router-dom";
import UpdateAutomaticMessages from "../../../../hooks/GraphqlCalls/Sales/UpdateAutomaticMessages";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { setExecuteQuery, setRefreshContentData } from "../../../../actions/sectionActions";
import { useDispatch, useSelector } from "react-redux";
import UseLoading from "../../../Table/useLoading";

const GeneralSettingsAutomaticMessages = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { refreshData } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);
    const [loading, setLoading] = useState(true);

    const hasOrdersManagement = permissions?.services?.shopsOrders;

    const MESSSAGES_TYPES = [
        { text: "instant-success-message", type: "instant-success", key: "INSTANT_SUCCESS" },
        { text: "scheduled-success-message", type: "scheduled-success", key: "SCHEDULED_SUCCESS" },
        { text: "accepted-message", type: "accepted", key: "ACCEPTED" },
        { text: "ready-message", type: "ready", key: "READY" },
        { text: "deleted-message", type: "deleted", key: "DELETED" },
    ];

    let messagesTypesToRender = hasOrdersManagement ? MESSSAGES_TYPES : MESSSAGES_TYPES.slice(0, 2);

    const [stateMessages, setStateMessages] = useState({
        INSTANT_SUCCESS: false,
        SCHEDULED_SUCCESS: false,
        ACCEPTED: false,
        READY: false,
        DELETED: false,
        DELIVERY_FAILED: false,
    });

    const GET_MESSAGES = gql(`
    query{
        shopMessages{
          results{
            enabled
            type
          }
        }
      }
    `);

    const [executeQuery, { data, refetch }] = useLazyQuery(GET_MESSAGES, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        setLoading(true);
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (data?.shopMessages?.results) {
            const messagesState = convertArrayToObject(data.shopMessages.results);
            setStateMessages({ ...stateMessages, ...messagesState });
            setTimeout(() => {
                setLoading(false);
            }, 400);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (refreshData && refetch) {
            refetch();
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const messageType = (type, index) => {
        const isFirst = index === 0;
        const isLast = index === messagesTypesToRender.length - 1;
        return (
            <div
                key={`message-type-${index}`}
                className={classNames(
                    " py-5 flex items-center justify-between border-gray-200 ",
                    isFirst ? "border-t" : "",
                    isLast ? "" : "border-b"
                )}
            >
                <span className=" text-base"> {t(`${type.text}`)}</span>
                <div className=" flex">
                    <button
                        id={`edit-link-button-${type.type}`}
                        onClick={() => {
                            history(`/services/sales/general-settings/automatic-messages/${type.type}`);
                        }}
                        className=" focus:outline-none cursor-pointer text-zafiro-600"
                    >
                        {capitalizeFirst(t("edit"))}
                    </button>
                    <div className=" ml-6 mr-2" style={{ marginTop: "0.12rem" }}>
                        <UseSwitch
                            id={`${type.type}`}
                            checked={stateMessages[type.key]}
                            action={() => {
                                dispatch(
                                    setExecuteQuery({
                                        action: "update-state-message",
                                        params: {
                                            value: !stateMessages[type.key],
                                            type: type.key,
                                        },
                                    })
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className=" relative">
            <div className=" text-left mb-4">
                <span className="font-bold text-gray-900 text-2xl">{t("automatic-messages")}</span>
            </div>
            <div className=" text-left mb-6">
                <span>{t("these-message-will-be-shown-status-during")}</span>
            </div>
            <div className=" text-left mb-2">
                <span className=" text-xl"> {capitalizeFirst(t("type"))} </span>
            </div>
            <div>
                {messagesTypesToRender.map((message, index) => {
                    return messageType(message, index);
                })}
            </div>
            {loading ? (
                <div className=" flex items-center z-205 bg-white justify-center absolute h-full w-full top-0 left-0">
                    <UseLoading adjust={"contents"} />
                </div>
            ) : null}
            <UpdateAutomaticMessages />
        </div>
    );
};

export default GeneralSettingsAutomaticMessages;

function convertArrayToObject(array) {
    return array.reduce((obj, item) => {
        obj[item.type] = item.enabled;
        return obj;
    }, {});
}
