import React from "react";
import { useSelector } from "react-redux";

const UseGlobalLoading = () => {
    //Store data
    const { showGlobalLoading } = useSelector((state) => state.ui);

    return (
        <>
            {showGlobalLoading ? (
                <div className="t-loading z-500 fixed m-0 t-0 l-0 w-full h-full">
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseGlobalLoading;
