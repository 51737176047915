import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";
import {
    ADD_PRODUCT_EXTRA,
    DELETE_PRODUCT_EXTRA,
    UPDATE_PRODUCT_EXTRA,
    UPDATE_PRODUCT_EXTRAS,
} from "../../Utils/Integrations/ShopSettingsUtils";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, getVendureApiData, uploadVendureMutation } from "../../Utils/Integrations/useVendure";
import { arrangeToastMessagesUploadingFiles } from "../../Utils/DesignUtils";
import UseToast from "../../../components/Notifications/useToast";
import _ from "lodash";

const UpdateExtras = ({ client }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const { useGlobalLoading } = useSelector((state) => state.ui.modalContent);
    const dispatch = useDispatch();

    useEffect(() => {
        let execute = false;
        let mutation = null;
        let executeAnotherQuery = null;
        let avoidToast = false;
        let noCleanAction = true;
        let mutationFile = null;
        let aditionalHeader = null;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case ADD_PRODUCT_EXTRA:
                    mutation = `
                    mutation{
                        createExtra(
                            extra:{
                                available: false
                                appliesTo: ${actionData.values["appliesTo"]}
                                taxCategory: ${actionData.values["taxCategory"]}
                                order: ${actionData.values["order"]}
                                type: ${actionData.values["add-product-extra-type"]}
                                translations: [{
                                    languageCode: "${actionData.values["langDefault"]}"
                                    name: "${actionData.values["add-product-extra-name"]}"
                                }]
                                options: { min: 0, max:0, priceWithoutTax: 0 }
                            }
                        ){
                            id
                            __typename
                        }
                    }
                    `;
                    executeQuery.closeModal = true;
                    executeQuery.mutationName = "createExtra";
                    noCleanAction = false;
                    break;
                case UPDATE_PRODUCT_EXTRA:
                    let stringLangs = ``;
                    let stringOptions = ``;
                    if (executeQuery.params.translations && executeQuery.params.translations.length > 0) {
                        executeQuery.params.translations.forEach((translation) => {
                            if (translation.name && translation.name !== "") {
                                stringLangs += `{ languageCode: "${translation.languageCode}", name: "${translation.name}" }, `;
                            }
                        });
                    }
                    if (executeQuery.params.options) {
                        let optionData = executeQuery.params.options;
                        let stringChoices = ``;
                        if (optionData.min !== null && optionData.min !== undefined && optionData.min !== "")
                            stringOptions += ` min:${optionData.min} `;
                        if (optionData.max !== null && optionData.max !== undefined && optionData.max !== "")
                            stringOptions += ` max:${optionData.max} `;
                        if (
                            optionData.priceWithoutTax !== null &&
                            optionData.priceWithoutTax !== undefined &&
                            optionData.priceWithoutTax !== ""
                        )
                            stringOptions += ` priceWithoutTax:${optionData.priceWithoutTax} `;
                        if (optionData.choices && optionData.choices.length > 0) {
                            optionData.choices.forEach((choice) => {
                                let stringChoiceTranslations = ``;
                                if (choice.translations && choice.translations.length > 0) {
                                    choice.translations.forEach((translation) => {
                                        if (translation.name && translation.name !== "") {
                                            stringChoiceTranslations += `{ languageCode: "${translation.languageCode}", name: "${translation.name}" }, `;
                                        }
                                    });
                                }
                                stringChoices += `{
                                    ${
                                        choice.available !== null &&
                                        choice.available !== undefined &&
                                        choice.available !== ""
                                            ? `available: ${choice.available}`
                                            : ``
                                    }
                                    ${stringChoiceTranslations ? `translations: [${stringChoiceTranslations}]` : ``}
                                    ${choice.priceWithoutTax ? `priceWithoutTax: ${choice.priceWithoutTax}` : ``}
                                    ${choice.taxCategory ? `taxCategory: ${choice.taxCategory}` : ``}
                                    ${choice.order ? `order: ${choice.order}` : ``}
                                }, `;
                            });
                            stringOptions += ` choices:[${stringChoices}] `;
                        }
                    }
                    mutation = `
                    mutation{
                        updateExtra(
                            extra:{
                                id:${executeQuery.params.id}
                                ${executeQuery.params.type ? `type: ${executeQuery.params.type}` : ``}
                                ${
                                    executeQuery.params.available !== null &&
                                    executeQuery.params.available !== undefined &&
                                    executeQuery.params.available !== ""
                                        ? `available: ${
                                              (executeQuery.params.type === "multicombobox" ||
                                                  executeQuery.params.type === "combobox") &&
                                              (executeQuery.params.options?.choices.length === 0 ||
                                                  executeQuery.params.options?.choices.every(
                                                      (opt) => opt.available === false
                                                  ))
                                                  ? false
                                                  : executeQuery.params.available
                                          }`
                                        : ``
                                }
                                ${executeQuery.params.order ? `order: ${executeQuery.params.order}` : ``}
                                ${executeQuery.params.appliesTo ? `appliesTo: ${executeQuery.params.appliesTo}` : ``}
                                ${
                                    executeQuery.params.taxCategory
                                        ? `taxCategory: ${executeQuery.params.taxCategory}`
                                        : ``
                                }
                                ${
                                    executeQuery.params.featuredAsset
                                        ? `featuredAsset: ${executeQuery.params.featuredAsset}`
                                        : ``
                                }
                                ${stringOptions ? `options: {${stringOptions}}` : ``}
                                ${stringLangs ? `translations: [${stringLangs}]` : ``}
                                ${
                                    executeQuery.params.priceWithoutTax
                                        ? `priceWithoutTax: ${executeQuery.params.priceWithoutTax}`
                                        : ``
                                }
                            }
                        ){
                            id
                            __typename
                        }
                    }
                    `;
                    executeQuery.mutationName = "updateExtra";
                    break;
                case UPDATE_PRODUCT_EXTRAS:
                    mutation = `mutation{`;
                    let items = executeQuery.params.items;
                    if (items && items.length > 0) {
                        items.forEach((item, index) => {
                            stringLangs = ``;
                            stringOptions = ``;
                            if (item.translations && item.translations.length > 0) {
                                item.translations.forEach((translation) => {
                                    if (translation.name && translation.name !== "") {
                                        stringLangs += `{ languageCode: "${translation.languageCode}", name: "${translation.name}" }, `;
                                    }
                                });
                            }
                            if (item.options) {
                                let optionData = item.options;
                                let stringChoices = ``;
                                if (optionData.min !== null && optionData.min !== undefined && optionData.min !== "")
                                    stringOptions += ` min:${optionData.min} `;
                                if (optionData.max !== null && optionData.max !== undefined && optionData.max !== "")
                                    stringOptions += ` max:${optionData.max} `;
                                if (
                                    optionData.priceWithoutTax !== null &&
                                    optionData.priceWithoutTax !== undefined &&
                                    optionData.priceWithoutTax !== ""
                                )
                                    stringOptions += ` priceWithoutTax:${optionData.priceWithoutTax} `;
                                if (optionData.choices && optionData.choices.length > 0) {
                                    optionData.choices.forEach((choice) => {
                                        let stringChoiceTranslations = ``;
                                        if (choice.translations && choice.translations.length > 0) {
                                            choice.translations.forEach((translation) => {
                                                if (translation.name && translation.name !== "") {
                                                    stringChoiceTranslations += `{ languageCode: "${translation.languageCode}", name: "${translation.name}" }, `;
                                                }
                                            });
                                        }
                                        stringChoices += `{
                                            ${
                                                choice.available !== null &&
                                                choice.available !== undefined &&
                                                choice.available !== ""
                                                    ? `available: ${choice.available}`
                                                    : ``
                                            }
                                            ${
                                                stringChoiceTranslations
                                                    ? `translations: [${stringChoiceTranslations}]`
                                                    : ``
                                            }
                                            ${
                                                choice.priceWithoutTax
                                                    ? `priceWithoutTax: ${choice.priceWithoutTax}`
                                                    : ``
                                            }
                                            ${choice.taxCategory ? `taxCategory: ${choice.taxCategory}` : ``}
                                            ${choice.order ? `order: ${choice.order}` : ``}
                                        }, `;
                                    });
                                    stringOptions += ` choices:[${stringChoices}] `;
                                }
                            }
                            mutation += `
                                updateExtra${index}: updateExtra(
                                    extra:{
                                        id:${item.id}
                                        ${item.type ? `type: ${item.type}` : ``}
                                        ${
                                            item.available !== null &&
                                            item.available !== undefined &&
                                            item.available !== ""
                                                ? `available: ${item.available}`
                                                : ``
                                        }
                                        ${item.order ? `order: ${item.order}` : ``}
                                        ${item.appliesTo ? `appliesTo: ${item.appliesTo}` : ``}
                                        ${item.taxCategory ? `taxCategory: ${item.taxCategory}` : ``}
                                        ${item.featuredAsset ? `featuredAsset: ${item.featuredAsset}` : ``}
                                        ${stringOptions ? `options: {${stringOptions}}` : ``}
                                        ${stringLangs ? `translations: [${stringLangs}]` : ``}
                                        ${item.priceWithoutTax ? `priceWithoutTax: ${item.priceWithoutTax}` : ``}
                                    }
                                ){
                                    id
                                    __typename
                                }
                            `;
                        });
                    }
                    mutation += `}`;
                    executeQuery.mutationName = "updateExtras";
                    break;
                case DELETE_PRODUCT_EXTRA:
                    mutation = `
                        mutation{
                            deleteExtra(extraId:${actionData.itemsAffected[0]}){
                                result
                            }
                        }
                    `;
                    executeQuery.closeModal = true;
                    executeQuery.mutationName = "deleteExtra";
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (useGlobalLoading) dispatch(showGlobalLoading(true));
            dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation(
                    mutation,
                    executeAnotherQuery,
                    avoidToast,
                    noCleanAction,
                    mutationFile,
                    aditionalHeader
                );
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async (
        mutation,
        executeAnotherQuery,
        avoidToast,
        noCleanAction,
        mutationFile = null,
        aditionalHeader = null
    ) => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }
        let errorMutation = false;
        let response = null;
        let files = [];
        let toastId = null;

        if (mutationFile) {
            files = [
                {
                    name: mutationFile.file.name,
                    error: errorMutation,
                    textTooltip: mutationFile.file.name,
                    status: 1,
                    ref: "",
                    size: mutationFile.file.size,
                },
            ];
            let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);
            toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false,
                className: "use-toast",
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                hideProgressBar: true,
                icon: false,
            });
            response = await uploadVendureMutation(
                getVendureApiData(),
                {
                    queryBody: mutation,
                    file: mutationFile.file,
                },
                typeof aditionalHeader === "object" ? aditionalHeader : {}
            );

            if (response && response.data && !response.data.errors) {
                if (mutationFile.mutationName) {
                    let mutationObject = response.data[mutationFile.mutationName];
                    if (mutationObject?.result?.toUpperCase().includes("NOT")) {
                        errorMutation = true;
                    } else if (mutationObject instanceof Array && mutationObject.length > 0 && executeAnotherQuery) {
                        executeAnotherQuery.params = { ...executeAnotherQuery.params, ...mutationObject[0] };
                    }
                }
            } else {
                errorMutation = true;
            }
        } else {
            response = await executeVendureQuery(
                getVendureApiData(),
                { queryBody: mutation },
                typeof aditionalHeader === "object" ? aditionalHeader : {}
            );
            if (response && response.data && !response.data.errors) {
                if (executeQuery.mutationName) {
                    let mutationObject = response.data[executeQuery.mutationName];
                    if (mutationObject?.result?.toUpperCase().includes("NOT")) {
                        errorMutation = true;
                    }
                }
            } else {
                errorMutation = true;
            }
        }

        if (!errorMutation) {
            if (mutationFile) {
                files.forEach((file) => {
                    file["status"] = 2;
                });

                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });
            } else if (!avoidToast) {
                toast.success(t("operation-successful"));
            }
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            if (!noCleanAction) dispatch(cleanAction());
            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
            if (executeAnotherQuery) {
                let params = executeAnotherQuery.extraParams ? executeAnotherQuery.extraParams : {};
                if (executeAnotherQuery.useResponsePrevQueryData) {
                    let param = executeAnotherQuery.useResponsePrevQueryData;
                    params[param.name] = _.get(response.data, param.path);
                }
                dispatch(
                    setExecuteQuery({
                        action: executeAnotherQuery.action,
                        params: params,
                    })
                );
            }
        } else {
            if (!noCleanAction) dispatch(cleanAction());
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            if (mutationFile) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 4;
                    file["errorMessage"] = "";
                });
                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);
                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });
            } else {
                toast.error(t("mutation-error"));
            }
        }
    };

    return null;
};

export default withApollo(UpdateExtras);
