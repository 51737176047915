import { capitalizeFirst } from "../../../Utils/Utils";
import { formatPriceValueVendure } from "../../../Utils/SalesUtils";
const Product = (product, t) => {
    const { id, name, currency, imgSrc, tax, price, shopId } = product;
    const available = product && product.enabled ? true : false;

    return {
        dataType: "product",
        id: id,
        shopId: shopId,
        adjust: "h-auto",
        actions: [
            {
                type: "products",
                value: id,
            },
        ],
        name: name,
        available: available,
        executeSectionQuery: true,
        t: t,
        actionComponentStyle: { left: "-160px" },

        info: [
            {
                cellContent: "image",
                isFromUrl: true,
                urlPath: imgSrc,
                path: "icons/",
                type: "svg",
                name: "image",
                linkText: name,
                noImgText: true,
                specialWidth: "w-5/12 ",
                adjust: "w-18 h-12 object-cover rounded mr-4",
                notImgFontSize: "0.65rem",
                adjustNotImageIcon: "icon icon-no-image text-gray-400 text-xl",
                adjustNotImage:
                    "border border-gray-300 flex items-center justify-center bg-gray-200 w-18 h-12 rounded mr-4",
                adjustText: " text-zafiro-600 cursor-pointer",
                value: name,
                redirectTo: `/services/sales/shop/${shopId}/product/${id}`,
            },
            {
                cellContent: "text",
                value: tax ? `${tax}%` : "0%",
                specialWidth: "w-2/12 pl-2",
                wrap: "truncate",
            },
            {
                cellContent: "text",
                value: `${formatPriceValueVendure(price, currency)}`,
                specialWidth: "w-2/12 pl-2",
                wrap: "truncate",
            },
            {
                cellContent: "text",
                value: capitalizeFirst(available ? t("available") : t("unavailable")),
                specialWidth: `w-3/12 pl-2 text-left ${available ? "text-green-100" : "text-red-100"} `,
                wrap: "truncate",
            },
        ],
    };
};

export default Product;
