import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import MenuSideBar from "./MenuSideBar";
import { showGlobalLoading } from "../../actions/uiActions";

import { DashboardsKibana } from "../../hooks/GraphqlCalls/Stats/LoginKibana";

import Sitemap from "../../sitemap";
import { UseSectionMap } from "./utils";

export default function StatsSidebar() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { getDashboards, loading, dashboards } = DashboardsKibana();

    const { getItems } = UseSectionMap();

    useEffect(() => {
        getDashboards();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(showGlobalLoading(loading));
        // eslint-disable-next-line
    }, [loading]);

    const castMenu = [];
    const wifiMenu = [];
    const internalMenu = [];
    const mobileMenu = [];
    const salesMenu = [];
    if (dashboards) {
        Object.keys(dashboards).forEach((key) => {
            let group = dashboards[key];
            if (group.name === "Cast") {
                // eslint-disable-next-line
                group.items.map((dashboard, index) => {
                    castMenu.push({
                        to: "/stats/cast/" + (index + 1),
                        name: dashboard.default ? t("kibana:" + dashboard.name) : dashboard.name,
                        i18n: false,
                    });
                });
            } else if (group.name === "WiFi") {
                // eslint-disable-next-line
                group.items.map((dashboard, index) => {
                    wifiMenu.push({
                        to: "/stats/wifi/" + (index + 1),
                        name: dashboard.default ? t("kibana:" + dashboard.name) : dashboard.name,
                        i18n: false,
                    });
                });
            } else if (group.name === "Internal") {
                // eslint-disable-next-line
                group.items.map((dashboard, index) => {
                    internalMenu.push({
                        to: "/stats/internal/" + (index + 1),
                        name: dashboard.default ? t("kibana:" + dashboard.name) : dashboard.name,
                        i18n: false,
                    });
                });
            } else if (group.name === "Sales") {
                group.items.forEach((dashboard, index) => {
                    salesMenu.push({
                        to: "/stats/sales/" + (index + 1),
                        name: dashboard.default ? t("kibana:" + dashboard.name) : dashboard.name,
                        i18n: false,
                    });
                });
            } else if (group.name === "Mobile") {
                // eslint-disable-next-line
                group.items.map((dashboard, index) => {
                    mobileMenu.push({
                        to: "/stats/mobile/" + (index + 1),
                        name: dashboard.default ? t("kibana:" + dashboard.name) : dashboard.name,
                        i18n: false,
                    });
                });
            }
        });
    }

    const menuItems = getItems("stats", Sitemap.stats);
    if (menuItems) {
        menuItems.map((item) => {
            switch (item.id) {
                case "stats_cast-statistics":
                    item.menu = castMenu;
                    break;
                case "stats_wifi-statistics":
                    item.menu = wifiMenu;
                    break;
                case "stats_internal-statistics":
                    item.menu = internalMenu;
                    break;
                case "stats_mobile-statistics":
                    item.menu = mobileMenu;
                    break;
                case "stats_sales-statistics":
                    item.menu = salesMenu;
                    break;
                default:
            }
            return item;
        });
    }
    return <MenuSideBar menuItems={menuItems} />;
}
