import { useTranslation } from "react-i18next";
export const GetCellContent = (data) => {
    //Store data
    const { t } = useTranslation();

    let res = ``;
    const { prefix, value, sufix, translatePrefix, translateSufix, twoDots, translate } = data || {};
    //Check prefix
    if (prefix) {
        if (translatePrefix) {
            res += `${t(prefix)}`;
        } else {
            res += `${prefix}`;
        }
        if (twoDots) {
            res += `:`;
        }
        res += ` `;
    }
    //Show value
    if (Array.isArray(value)) {
        // eslint-disable-next-line
        value.map((item) => {
            res += translate ? (t(item).length > 1 ? t(item) : item) : t(item);
            return null;
        });
        /* } else if (langStrings[value] !== undefined)
        res += `${langStrings[value]} `;*/
    } else if (value || value === 0) {
        res += `${translate ? (t(value).length > 1 ? t(value) : value) : value} `;
    }
    //Check sufix
    if (sufix) {
        if (translateSufix) {
            res += `${t(sufix)} `;
        } else {
            res += `${sufix} `;
        }
    }
    return res;
};
