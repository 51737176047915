import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table/";
//Utils
import ListGroups from "../../../../hooks/GraphqlCalls/Customers/ListGroups";

const GuestGroups = () => {
    ListGroups();
    return (
        <>
            <UseSectionHeader title={"guestgroups"} />
            <UseTable />
        </>
    );
};

export default GuestGroups;
