import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLangStings from "../../../lang/useLangStrings";
import sanitizeHtml from "sanitize-html";
import { capitalizeFirst } from "../../../hooks/Utils/Utils";
import { GetCellContent } from "../../../hooks/Utils/GetCellContent";
import Parser from "hooks/Utils/Parser";
import { useNavigate as useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { openModal, setModalContent } from "actions/uiActions";
import { modal } from "components/Section/Monitor/modalContent/ValidateNetworkModal";

const UseTextCell = ({ cellData, rowIndex, colIndex }) => {
    const {
        hidden,
        adjust,
        subvalue,
        icon,
        iconAdjust,
        iconTooltip,
        textTooltip,
        link,
        spinner,
        spinnerAdjust,
        spinnerStyle,
        modal,
    } = cellData || {};
    //Store data
    const { t } = useTranslation();
    const { disabled } = useSelector((state) => state.table);
    const dispatch = useDispatch();

    const content = GetCellContent(cellData);
    const iconTooltipText = useLangStings(iconTooltip ? iconTooltip : "");
    const tooltipText = textTooltip ? t(textTooltip) : content;
    const [disableTooltip, setDisableTooltip] = useState(false);
    const prefixId = "text-cell";
    const showTooltip = (e) => {
        if (textTooltip || e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
            setDisableTooltip(false);
        } else {
            setDisableTooltip(true);
        }
    };
    //used in Font management navigating to datail
    const history = useHistory();
    const onClickCell = (e) => {
        e.preventDefault();
        if (link) {
            history(link);
        }

        console.log(modal);

        if (modal?.[0]) {
            dispatch(setModalContent(modal[0]));
            dispatch(openModal());
        }
    };

    return (
        <>
            {!hidden ? (
                <>
                    {icon ? (
                        <>
                            <div
                                className={`icon-${icon} ${iconAdjust} float-left mr-2 pt-1`}
                                data-tip={iconTooltipText && sanitizeHtml(capitalizeFirst(iconTooltipText))}
                                data-for="default-tooltip"
                            ></div>
                        </>
                    ) : null}
                    {spinner ? (
                        <div className={`float-left mr-2 pt-1 ${spinnerAdjust} `}>
                            <div
                                className="lds-roller"
                                style={
                                    spinnerStyle
                                        ? spinnerStyle
                                        : {
                                              transform: "scale(0.2)",
                                              transformOrigin: "top left",
                                              width: "14px",
                                              height: "14px",
                                          }
                                }
                            >
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    ) : null}
                    <div
                        className={`text-left truncate ${adjust} ${disabled ? "text-gray-600" : ""}`}
                        data-tip={`${tooltipText ? sanitizeHtml(capitalizeFirst(tooltipText)) : ""}`}
                        onMouseOver={(e) => showTooltip(e)}
                        data-tip-disable={disableTooltip}
                        data-for="default-tooltip"
                        onClick={(e) => onClickCell(e)}
                        id={`${prefixId}-r${rowIndex}-c${colIndex}`}
                    >
                        {Parser(content)}
                    </div>
                    {subvalue ? <div className={`text-left text-gray-500`}>{subvalue}</div> : null}
                </>
            ) : null}
        </>
    );
};

export default UseTextCell;
