/**
 * Finds the specific input from a particular ID.
 *
 * @param {String} tvInputId - ID of input tv
 * @param {Array} tvInputs - List inputs tv
 *
 * @returns {Array | Object} - Information of the input found or in its default one.
 */
export const getInputGroup = (tvInputId, tvInputs) => {
    if (!tvInputs) {
        return {};
    }

	if (tvInputId) {
		return tvInputs.find((tvInput) => tvInput.id === tvInputId) || {};
	} else {
		return tvInputs.find((tvInput) => tvInput.isDefault) || {};
	}
};

export const DEVICE_FUNCTIONS = {
	tv: {
		value: "TV",
		enumValue: "TV",
		typeValue:"tv",
		label: "tv-device",
		canAssign:true,
		icons: [{
			name:"tv-settings",
			color:{
				hex:"06376B",
				class:"bg-zafiro-800"
			}
		}]
	},
	cast: {
		value: "CAST",
		enumValue: "CAST",
		typeValue:"chromecast",
		label: "Chromecast",
		canAssign:true,
		icons: [{
			name:"wificast",
			color:{
				hex:"2286EF",
				class:"bg-blue-200"
			}
		}]
	},
	tvAndcast: {
		value: "TV+CAST",
		enumValue: "TVCAST",
		typeValue: "tvchromecast",
		label: "chromecast-and-stb",
		canAssign:true,
		icons: [
			{
				name:"tv-settings",
				color:{
					hex:"06376B",
					class:"bg-zafiro-800"
				}
			},
			{
				name:"wificast",
				color:{
					hex:"2286EF",
					class:"bg-blue-200"
				}
			}
		]
	},
	unspecified: {
		value: "UNSPECIFIED",
		enumValue: "UNSPECIFIED",
		typeValue:"unspecified",
		label: "unspecified",
		canAssign:false,
		icons:[]
	}
}

export const DEVICE_STATUS = {
	online: {
		value: "online",
		label: "online",
		icon:{
			name:"alert",
			color:{
				class:"success"
			}
		}
	},
	standby: {
		value: "standby",
		label: "online-standby",
		compressedLabel:"online",
		subLabel:"standby",
		icon:{
			name:"alert",
			color:{
				class:"success"
			}
		}
	},
	lowSignal: {
		value: "low-signal-level",
		label: "online-low-signal",
		compressedLabel:"online",
		subLabel:"low-signal",
		icon:{
			name:"alert",
			color:{
				class:"warning"
			}
		}
	},
	offline: {
		value: "offline",
		label: "offline",
		icon:{
			name:"alert",
			color:{
				class:"fail"
			}
		}
	},
	unassigned: {
		value: "unassigned",
		label: "unassigned",
		icon:{
			name:"alert",
			color:{
				class:"warning"
			}
		}
	},
	unauthorized: {
		value: "unauthorized",
		label: "unauthorized",
		icon:{
			name:"hidenstatus",
			color:{
				class:"fail"
			}
		}
	},
	hiddenStatus: {
		value: "disabled",//hidden-status
		label: "warning-chromecasts-4",
		labelAdjust: "text-gray-500",
		icon:{
			name:"hidenstatus",
			color:{
				class:"disabled"
			}
		}
	},
	runningTask: {
		value: "running-task",
		label: "running-task",
		notFilterable:true,
		spinner: true,
		icon:{
			name:"refresh",
			color:{
				class:"disabled text-xl"
			}
		}
	},
	unavailable: {
		value: "unavailable",
		label: "unavailable",
		notFilterable:true,
		icon:{
			name:"alert",
			color:{
				class:"disabled"
			}
		}
	}
}

export const DEVICE_KINDS = {
	chromecast: {
		value: "CHROMECAST",
		label: "chromecast",
	},
	stb: {
		value: "STB",
		label: "stb",
	},
	tv: {
		value: "TV",
		label: "tv-device",
	},
}

export const DEVICE_TYPES = {
	chromecast: {
		value: "Chromecast",
		label: "chromecast",
	},
	googleTV: {
		value: "GoogleTV",
		label: "google-tv",
	},
	estbA: {
		value: "ESTB-A+",
		label: "ESTB-A+",
	},
	estbQp: {
		value: "ESTB-Qp",
		label: "ESTB-Qp",
	},
	estbQpd: {
		value: "ESTB-Qpd",
		label: "ESTB-Qpd",
	},
	pc: {
		value: "PC",
		label: "PC",
	},
	samsung: {
		value: "Samsung",
		label: "Samsung",
	},
	samsungOrsay: {
		value: "Samsung-Orsay",
		label: "Samsung-Orsay",
	},
	samsungTizen: {
		value: "Samsung-Tizen",
		label: "Samsung-Tizen",
	},
	lg: {
		value: "LG",
		label: "LG",
	},
	philips: {
		value: "Philips",
		label: "philips-chromecast",
	}
}
