import React, { useContext, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import GetGuestOrders from "./GetGuestOrders";
import GuestOrderRow from "./GuestOrderRow";
import { useSelector } from "react-redux";
import UseLoading from "../../../../Table/useLoading";
import UsePaginationLocalState from "../../../../Table/UsePaginationLocalState";
import GuestOrdersFilters from "./GuestOrdersFilters";

import { SalesContext } from "contexts/Sales";

const GuestOrders = ({ minHeight = null, maxHeight = null, isModal = false }) => {
    const { t } = useTranslation();

    const { permissions, pricesIncludeTax } = useContext(SalesContext);

    const hasOrdersManagement = permissions?.ordersManagement;
    const hasPMSManagement = permissions?.pmsManagement;

    const [dataOrders, setDataOrders] = useState([]);

    const { data, guest, room } = useSelector((state) => state.sectionContent);

    const [rowInfoId, setRowInfoId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState(null);
    const [shopsSelected, setShopsSelected] = useState([]);
    const [search, setSearch] = useState("");
    const [paginationState, setPaginationState] = useState({
        count: 0,
        currentPage: 1,
        perPage: 9,
        disabled: false,
    });
    const [count, setCount] = useState(0);
    const [dataOrdersVendure, setDataOrdersVendure] = useState([]);
    const [includeTax, setIncludeTax] = useState(null);

    useEffect(() => {
        pricesIncludeTax()
            .then((res) => {
                setIncludeTax(res);
            })
            .catch((e) => {
                console.error("Error getting pricesIncludeTax", e);
            });
    }, []);

    const buttonsData = [
        { value: "id", label: "order", width: isModal ? " w-3/12" : "w-2/12" },
        { value: "roomName", label: "room", width: !isModal ? "w-2/12" : " hidden" },
        { value: "date", label: "date", width: "w-2/12" },
        { value: "shop", label: "shop", width: isModal ? " w-3/12" : "w-2/12" },
        {
            value: "totalPrice",
            label: "price",
            tooltip: includeTax ? t("Price listed with taxes") : t("Price listed without taxes"),
            width: "w-2/12",
        },
        hasOrdersManagement || hasPMSManagement
            ? { value: "state", label: hasOrdersManagement ? "state" : "PMS charges", width: `w-1/12` }
            : null,
    ];

    const guestID = guest?.guestID || data?.guestID;
    const guestRoomID = guest && room ? guest?.rooms?.find((r) => r.RoomID === room?.id)?.stayGuestRoomID : null;

    return (
        <div className=" relative">
            <GetGuestOrders
                guestID={guestID}
                guestRoomID={guestRoomID}
                shopsTokens={shopsSelected.length > 0 ? shopsSelected : null}
                search={search}
                orderBy={orderBy?.field}
                orderDir={orderBy?.criteria}
                pagination={!isModal ? paginationState : null}
                setCount={setCount}
                setLoading={setLoading}
                setDataOrders={setDataOrders}
            />
            <div className=" w-full relative h-auto mt-2">
                <GuestOrdersFilters setLoading={setLoading} setShopsSelected={setShopsSelected} setSearch={setSearch} />
                <div className=" w-full px-3 border-b border-gray-200 pb-5">
                    {buttonsData.map((button) =>
                        button ? (
                            <SortingButton
                                key={button.value}
                                currentOrder={orderBy}
                                setOrder={setOrderBy}
                                setLoading={setLoading}
                                {...button}
                            />
                        ) : null
                    )}
                    <div className=" inline-block text-right text-sm w-1/12 ">{`${count} ${t("orders")}`}</div>
                </div>
                <div className=" px-3" style={{ minHeight: minHeight, maxHeight: maxHeight, overflowY: "scroll" }}>
                    {dataOrders
                        ? dataOrders.map((order, index) => {
                              return (
                                  <GuestOrderRow
                                      key={`order-id-${order.id}`}
                                      dataOrderVendure={dataOrdersVendure}
                                      setDataOrdersVendure={setDataOrdersVendure}
                                      dataRow={order}
                                      rowId={index}
                                      setRowInfoId={setRowInfoId}
                                      rowInfoId={rowInfoId}
                                      isModal={isModal}
                                      index={index}
                                  />
                              );
                          })
                        : null}
                    {dataOrders && dataOrders.length === 0 ? (
                        <div className=" w-full text-lg mt-5 text-center">{t("no-orders-yet")}</div>
                    ) : null}
                </div>
            </div>
            <div className=" relative pt-6">
                {!isModal ? (
                    <UsePaginationLocalState
                        initialState={paginationState}
                        onStateChange={setPaginationState}
                        countProp={count}
                        setLoading={setLoading}
                    />
                ) : null}
            </div>
            {loading ? (
                <div className=" flex items-center justify-center absolute h-full w-full  bg-white bg-opacity-50 top-0 left-0">
                    <UseLoading adjust={"contents"} />
                </div>
            ) : null}
        </div>
    );
};

export default GuestOrders;

const SortingButton = ({ currentOrder, setOrder, value, label, tooltip, width, setLoading }) => {
    const { t } = useTranslation();
    const isActive = currentOrder?.field === value;
    const isASC = currentOrder?.criteria === "asc";
    const sort = {
        field: value,
        criteria: isActive && isASC ? "desc" : "asc",
    };

    const id = `list-orders-sort-button-${sort.field}`;
    const icon = isActive ? `icon-sort-${sort.criteria}` : "icon-table-sort";
    const sortAction = () => {
        setOrder(sort);
        setLoading(true);
    };

    return (
        <button id={id} onClick={sortAction} className={` focus:outline-none font-bold text-left ${width}`}>
            <span data-tip={tooltip} data-for={"default-tooltip"}>
                {capitalizeFirst(t(label))}
            </span>
            <i className={`${icon} t-icon-headers`}></i>
        </button>
    );
};
