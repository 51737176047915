import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { ShopContext } from "contexts/Sales/Shop";

import { usePMSConfigUpdate } from "hooks/Data/useShopConfig";
import { openModal, closeModal, setModalContent } from "../../../../../../actions/uiActions";

import Loading from "components/Loading";
import SettingsCard from "components/SettingsCard";
import ErrorInfo from "components/ErrorInfo";
import TextInput from "components/TextInput";

import UseSwitch from "components/Inputs/useSwitch";
import UseButton from "components/useButton";

const ShopSettingsPMS = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const { refresh, id, permissions, pms } = useContext(ShopContext);

    let pmsChargesDescription = `${t("pms-charges-settings-description")}`;
    if (permissions?.ordersManagement) {
        pmsChargesDescription += `\n${t("pms-charges-settings-process-with-orders")}`;
    } else {
        pmsChargesDescription += `\n${t("pms-charges-settings-process-without-orders")}`;
    }

    const editPMSConfig = () => {
        const close = () => {
            dispatch(setModalContent({}));
            dispatch(closeModal());
        };

        dispatch(
            setModalContent({
                id: id,
                useGlobalLoading: true,
                bodyAdjust: "w-4/12 m-0",
                executeSectionQuery: true,
                inputs: [
                    {
                        type: "component",
                        component: (
                            <EditPMSConfig
                                id={id}
                                values={pms?.data}
                                onCancel={close}
                                onSuccess={() => {
                                    refresh();
                                    close();
                                }}
                            />
                        ),
                    },
                ],
            })
        );
        dispatch(openModal());
    };

    useEffect(() => {
        if (pms && !pms.called) {
            pms.load();
        }
    }, []);

    return (
        <SettingsCard
            id="settings-pms-charges"
            title={t("PMS charges")}
            description={pmsChargesDescription}
            edit={{
                disabled: !pms?.ready,
                onClick: editPMSConfig,
            }}
        >
            {(() => {
                if (pms?.loading) {
                    return <Loading />;
                }

                if (pms?.error) {
                    return <ErrorInfo retry={pms.get}>{pms.error}</ErrorInfo>;
                }

                if (!pms?.data?.enabled) {
                    return (
                        <div id="pms-charges-disabled" className="text-gray-500">
                            {t("PMS charges disabled")}
                        </div>
                    );
                }

                return (
                    <SettingsCard.ParamsList
                        data={[
                            {
                                id: "pms-charges-id",
                                name: t("PMS charge ID"),
                                description: pms?.data?.id,
                            },
                            {
                                id: "pms-charges-desc",
                                name: t("PMS charge description"),
                                description: pms?.data?.description,
                            },
                        ]}
                    />
                );
            })()}
        </SettingsCard>
    );
};

const EditPMSConfig = ({ id, values, onCancel, onSuccess }) => {
    const { t } = useTranslation();

    const { update, loading, error, success } = usePMSConfigUpdate();

    const [inputValues, setInputValues] = useState({
        enabled: values?.enabled,
        id: values?.id,
        description: values?.description,
    });

    const updateSettings = () => {
        if (!loading) {
            const isValid = !inputValues?.enabled || (inputValues?.id && inputValues?.description);
            if (isValid) {
                update({ id, pmsConfig: inputValues });
            }
        }
    };

    useEffect(() => {
        const inputID = document.getElementById("pms-charge-id");
        const inputDescription = document.getElementById("pms-charge-description");
        if (inputID) {
            if (inputValues?.enabled) {
                inputID.focus();
            }
            inputID.value = inputValues?.id;
        }
        if (inputDescription) {
            inputDescription.value = inputValues?.description;
        }
    }, []);

    if (success) {
        if (onSuccess) {
            onSuccess();
        }
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <h2 id="section-title" className="section-title first-capital mb-5">
                {t("PMS charges")} - {t("edit")}
            </h2>
            {(() => {
                if (error) {
                    return <ErrorInfo>{error}</ErrorInfo>;
                }

                return (
                    <>
                        <div className="mb-5">
                            {t("pms-charges-enable-switch")}
                            <UseSwitch
                                id={`pms-charges-enable`}
                                checked={inputValues?.enabled}
                                adjust="inline-block ml-2"
                                action={(checked) => {
                                    setInputValues({ ...inputValues, enabled: checked });
                                }}
                            />
                        </div>

                        <div className="mb-5">{t("pms-charges-edit-description")}</div>

                        <div className="mb-3">
                            <div className="font-bold">{t("PMS charge ID")}*</div>
                            <div className="mt-1">
                                <TextInput
                                    id="pms-charge-id"
                                    required={true}
                                    placeholder={t("example something", { something: "111" })}
                                    disabled={!inputValues?.enabled}
                                    value={inputValues?.id}
                                    onChange={(value) => setInputValues({ ...inputValues, id: value })}
                                    autoFocus={inputValues?.enabled}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="font-bold">{t("PMS charge description")}*</div>
                            <div className="mt-1">
                                <TextInput
                                    id="pms-charge-description"
                                    required={true}
                                    placeholder={t("example something", { something: t("room-service") })}
                                    disabled={!inputValues?.enabled}
                                    value={inputValues?.description}
                                    onChange={(value) => setInputValues({ ...inputValues, description: value })}
                                />
                            </div>
                        </div>
                    </>
                );
            })()}

            <div id="modal-buttons" className={`pt-8 text-center flex justify-end`}>
                {onCancel ? (
                    <UseButton
                        buttonName={"cancel"}
                        buttonColor="btn-white"
                        id={`modal-button-cancel`}
                        action={onCancel}
                    />
                ) : null}
                {!error ? (
                    <UseButton
                        buttonName={"save"}
                        buttonColor="btn-blue"
                        id={`modal-button-save`}
                        action={updateSettings}
                        customClass="ml-4"
                    />
                ) : null}
            </div>
        </>
    );
};

export default ShopSettingsPMS;
