import React, { useEffect, useState } from "react";
import { useNavigate as useHistory } from "react-router-dom";

export const Body = ({ cols, currentRows, colsAlign, sort, searchValue }) => {
    const IP = "ip";
    const [filterSearch, setFilterSearch] = useState(currentRows || []);
    const history = useHistory();

    useEffect(() => {
        const filterSearch = currentRows?.filter((row) => {
            let isMatch = false;
            for (const col of cols) {
                if (
                    row[col]?.name?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                    row[col]?.subItem?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
                    (col === IP && row[col]?.toString().toLowerCase().includes(searchValue.toLowerCase()))
                ) {
                    isMatch = true;
                }
            }
            return isMatch;
        });
        setTimeout(() => {
            setFilterSearch(filterSearch);
        }, "1000");
    }, [searchValue, currentRows]);

    const sortedRows = filterSearch
        ? [...filterSearch]?.sort((a, b) => {
              if (sort?.order === "asc") {
                  if (typeof a[sort.col] === "string") return a[sort.col].localeCompare(b[sort.col]);
                  return b[sort.col]?.name?.toString().localeCompare(a[sort?.col]?.name);
              } else if (sort?.order === "desc") {
                  if (typeof a[sort.col] === "string") return b[sort.col].localeCompare(a[sort.col]);
                  return a[sort.col]?.name?.toString().localeCompare(b[sort?.col]?.name);
              } else {
                  return 0;
              }
          })
        : [];

    const push = (e, row) => {
        e.preventDefault();
        history(row?.link);
    };

    const renderedRows = sortedRows?.map((row, index) => (
        <tr key={row.id || `row_${index}`} className="text-left">
            {cols?.map((col, index) => (
                <td
                    key={col}
                    className={`border-b border-gray-200 px-4 py-2 ${
                        colsAlign?.[index] ? `text-${colsAlign[index]}` : null
                    }`}
                >
                    {row[col]?.name ? (
                        row[col]?.link ? (
                            <p
                                role="button"
                                className="text-blue-300 cursor-pointer"
                                onClick={(e) => push(e, row[col])}
                            >
                                {row[col]?.name}
                            </p>
                        ) : (
                            <p>{row[col]?.name}</p>
                        )
                    ) : (
                        <p>{row[col]}</p>
                    )}
                    {row[col]?.subItem ? <span className="text-gray-700">{row[col]?.subItem}</span> : null}
                </td>
            ))}
        </tr>
    ));

    return (
        <>
            {cols?.length > 0 || currentRows === undefined ? (
                <tbody>
                    {renderedRows?.length > 0 ? (
                        renderedRows
                    ) : (
                        <tr className="text-center">
                            <td colSpan={cols?.length || 1} className="px-4 py-10">
                                No data
                            </td>
                        </tr>
                    )}
                </tbody>
            ) : null}
        </>
    );
};
