import React, { useState } from "react";
import UseEditableField from "../../../../../components/useEditableField";
import { useTranslation } from "react-i18next";

import UseSelectMultipleWithSearchOptionsQuery from "../../../../useSelectMultipleWithSearchOptionsQuery";
import SessionDate from "../../../../../date";

const NewStayData = ({ roomId }) => {
    const { t } = useTranslation();

    const roomsQuery = `{
        data: rooms(filter: {free:true}){
          info { count }
          results { id name  }
        }       
    }`;

    const [arrivalDate, setArrivalDate] = useState(null);

    function tick() {
        setArrivalDate(
            SessionDate().toLocaleString({
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            })
        );
    }
    setInterval(tick, 1000);

    return (
        <div className="p-8">
            <div className="border-b border-gray-200 text-lg pb-2 flex justify-between">
                <div className="first-capital font-bold">{t("stay-information")}</div>
            </div>

            <div className="mt-5 grid gap-4 grid-cols-2">
                <div className="relative">
                    <div className="text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden">
                        {t("arrival")}
                    </div>
                    <div className="text-gray-600 mt-2 py-2">{arrivalDate}</div>
                </div>
                <UseEditableField
                    data={{
                        label: "departure",
                        labelOnEdition: "departure",
                        value: "",
                        inEditionMode: true,
                        dateAdjust: "text-sm text-gray-600",
                        type: "date&time",
                        id: "new-stay-data-departure",
                    }}
                ></UseEditableField>
                <div className="col-span-2">
                    <UseSelectMultipleWithSearchOptionsQuery
                        dataSelect={{
                            text: ["room-number", "*"],
                            textStyle:
                                "text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden",
                            name: "room-number",
                            optionsQuery: roomsQuery,
                            selectPlaceHolder: "select-room",
                            cStyle: "w-96",
                            sort: true,
                            type: "selectMultipleWithSearch",
                            id: "new-guest-form-select-number-room",
                            // noSelect: true,
                            selectedIds: roomId ? [{ id: parseInt(roomId) }] : null,
                        }}
                    ></UseSelectMultipleWithSearchOptionsQuery>
                </div>
            </div>
        </div>
    );
};

export default NewStayData;
