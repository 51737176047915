import React, { useContext, useEffect, useState } from "react";
import TabReservationDetails from "./TabReservationDetails";
import { PreCheckInContext } from "./ReservationDetailsContext";
import {
    verifyCheckInAvailableArrivalDate,
    BOOKING_INFORMATION,
    GUESTS,
    PENDING_VALIDATION_STATE,
    PRE_CHECKIN_HISTORY,
    VALIDATED,
    PENDING_MODIFICATION_STATE,
    PENDING_COMPLETION_STATE,
    formatAvalibaleOnCheckInDate,
} from "./utils";
import BookingInformation from "./BookingInformation";
import Guest from "./Guest";
import PreCheckinHistory from "./PreCheckinHistory";
import UseSectionHeader from "../../../../useSectionHeader";
import UseButton from "components/useButton";
import useSelect from "components/useSelect";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setModalContent } from "actions/uiActions";
import Checkin from "./modals/Checkin";
import { openModal } from "actions/uiActions";
import RequestModifications from "./modals/RequestModification";
import ResendPrechekin from "./modals/ResendPrecheckin";
import UpdateReservationDetails from "./UpdateReservationDetails";
import UseComponentLoading from "components/useComponentLoading";
import { Session } from "hooks/Utils/Session";
import { setFolder } from "actions/tableActions";
import ValidateData from "./modals/ValidateData";
import ReactTooltip from "components/ReactTooltip";

const ReservationDetails = () => {
    const {
        tabSelected,
        loading,
        data: dataContext,
        setInEdition: setInEditionContext,
    } = useContext(PreCheckInContext);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [titleHeader, setTitleHeader] = useState("");
    const tableFolder = Session.getSessionProp("tableFolder");

    const reservationState = dataContext?.stayBookings?.state;

    useEffect(() => {
        if (tableFolder) {
            dispatch(setFolder(JSON.parse(tableFolder)));
        }
    }, [tableFolder]);

    useEffect(() => {
        if (dataContext?.stayBookings?.code) {
            setTitleHeader(t("stay-n") + " " + dataContext.stayBookings.code);
        }
    }, [dataContext]);

    useEffect(() => {
        return () => {
            setInEditionContext(false);
        };
    }, []);

    const Select = useSelect();

    const breadcrumbs = [
        {
            name: "customers",
            translate: true,
            route: "/services/",
        },
        {
            name: "guest",
            translate: true,
            route: "/services/",
        },
        {
            name: "guest-list",
            translate: true,
            route: "/services/sales/order-summary",
        },
    ];

    return (
        <div className=" relative">
            <UseSectionHeader
                navToPreviousPage={true}
                title={titleHeader}
                customBreadCrumbs={breadcrumbs}
                name="fsdf"
            />
            <div style={{ top: "2rem", right: "0rem" }} className="  flex space-x-3 justify-end absolute ">
                <Select.Handler id={"pre-check-in-actions-button-container"}>
                    <UseButton
                        buttonName={"pre-check-in"}
                        buttonColor={"btn-white"}
                        dropdown={true}
                        adjust="w-auto px-3 min-w-60 text-zafiro-600 font-bold"
                        id={"pre-check-in-actions-button"}
                    />
                </Select.Handler>
                <Select.Body
                    style={{ minWidth: "15rem" }}
                    className="dropdown-body-flags absolute max-h-80 overflow-y-scroll"
                >
                    {[
                        {
                            id: "resend-pre-check-in",
                            name: t("resend-pre-check-in"),
                            modal: ResendPrechekin,
                        },
                        {
                            id: "request-modification",
                            name: t("request-modification"),
                            modal: RequestModifications,
                        },
                    ].map((option) => {
                        const style = "px-4 mb-2 cursor-pointer flex items-center text-gray-900";
                        return (
                            <div
                                className={style}
                                id={`${option.id}-option`}
                                key={option.key}
                                onClick={() => {
                                    dispatch(setModalContent(option.modal(dataContext?.stayBookings?.code)));
                                    dispatch(openModal());
                                }}
                            >
                                {option.name}
                            </div>
                        );
                    })}
                </Select.Body>
                {reservationState === VALIDATED && (
                    <div data-tip data-for={"check-in-button-tooltip"}>
                        <UseButton
                            action={() => {
                                dispatch(setModalContent(Checkin(dataContext?.stayBookings?.code, dataContext)));
                                dispatch(openModal());
                            }}
                            buttonName={"check-in"}
                            buttonColor={"btn-blue"}
                            disabled={verifyCheckInAvailableArrivalDate(dataContext?.stayBookings?.arrivalDate)}
                            id={"check-in-button"}
                        />
                    </div>
                )}
                {verifyCheckInAvailableArrivalDate(dataContext?.stayBookings?.arrivalDate) && (
                    <ReactTooltip
                        id="check-in-button-tooltip"
                        place="bottom"
                        type="light"
                        offset={{ top: -8, left: -8 }}
                        html={true}
                        border={true}
                        borderColor="#D3DAE1"
                        wrapper="span"
                    >
                        <span>
                            {t("available-on", {
                                date: formatAvalibaleOnCheckInDate(dataContext?.stayBookings?.arrivalDate),
                            })}
                        </span>
                    </ReactTooltip>
                )}
                {reservationState === PENDING_VALIDATION_STATE ||
                reservationState === PENDING_COMPLETION_STATE ||
                reservationState === PENDING_MODIFICATION_STATE ? (
                    <UseButton
                        action={() => {
                            dispatch(setModalContent(ValidateData({ code: dataContext?.stayBookings?.code })));
                            dispatch(openModal());
                        }}
                        id={"validate-data-button"}
                        buttonName={"validate-data"}
                        buttonColor={"btn-blue"}
                    />
                ) : null}
            </div>
            <div className=" bg-white relative py-6 px-8 rounded">
                <TabReservationDetails />
                {tabSelected === BOOKING_INFORMATION ? <BookingInformation /> : null}
                {tabSelected === GUESTS ? <Guest /> : null}
                {tabSelected === PRE_CHECKIN_HISTORY ? <PreCheckinHistory /> : null}
                {loading ? (
                    <div className=" rounded absolute w-full h-full top-0 left-0 bg-white">
                        <UseComponentLoading />
                    </div>
                ) : null}
            </div>

            <UpdateReservationDetails />
        </div>
    );
};

export default ReservationDetails;
