import React, { useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { UPDATE_PRODUCT_SETTINGS } from "../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { changeGlobalAction } from "../../../../../../../actions/globalActions";
import { openModal, setModalContent } from "../../../../../../../actions/uiActions";
import { SalesContextDeprecated } from "contexts/Sales";

function ProductSettingsSettingsCard({ data }) {
    const dispatch = useDispatch();

    const { data: dataContext, loadingContext } = useContext(SalesContextDeprecated);

    //Consts&states

    const { id, customFields } = dataContext?.productVariants?.items?.[0] || {};
    const { t } = useTranslation();
    const maxUnitsPerOrder = customFields ? customFields.max_units_per_order : null;
    const maxUnitsPerOrderEnabled = customFields ? customFields.max_units_per_order_enabled : false;

    //queries&mutations

    //effects

    //arrangeData

    //handle&functions
    const handleShowTranslateModal = () => {
        const modal = {
            id: id,
            title: ["settings", " - ", "edit"],
            useGlobalLoading: true,
            executeSectionQuery: true,
            inputs: [
                {
                    type: "switch",
                    name: "active-max-quantity-per-order",
                    text: "max-quantity-per-order",
                    checked: maxUnitsPerOrderEnabled,
                    textAdjust: "font-bold",
                    elementsUpdateDisabled: [
                        {
                            attr: "id",
                            value: "max-quantity-per-order",
                        },
                    ],
                },
                {
                    text: "define-maximum-quantity",
                    type: "htmltext",
                },
                {
                    type: "number",
                    id: "max-quantity-per-order",
                    name: "max-quantity-per-order",
                    style: "float-left",
                    currentValue: maxUnitsPerOrder ? maxUnitsPerOrder : null,
                    disabled: !maxUnitsPerOrderEnabled,
                    min: 1,
                },
                {
                    text: "products",
                    type: "htmltext",
                    style: "float-left mt-6 ml-5",
                },
                {
                    text: "",
                    type: "htmltext",
                    style: "block w-full mb-20",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "save", style: "blue", action: UPDATE_PRODUCT_SETTINGS },
            ],
        };
        dispatch(
            changeGlobalAction({
                actionName: UPDATE_PRODUCT_SETTINGS,
                itemsAffected: [id],
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    //renders

    //response
    return (
        <div className="w-full my-2" style={{ height: "8.4rem" }}>
            <div className="flex items-center w-full justify-between mb-5">
                <span className="font-bold text-gray-900 text-1xl">{t("settings")}</span>
                <span
                    className={`icon icon-edit cursor-pointer text-1xl`}
                    onClick={() => {
                        handleShowTranslateModal();
                    }}
                ></span>
            </div>
            <div className="flex items-center w-full justify-between mb-2 pr-20">
                <span className="">{t("max-quantity-per-order")}</span>
                <span className="font-bold">
                    {customFields && customFields.max_units_per_order && maxUnitsPerOrderEnabled
                        ? `${customFields.max_units_per_order}
                        ${t("{{capitalize, capitalize}}", {
                            capitalize: customFields.max_units_per_order > 1 ? t("products") : t("product"),
                        })}`
                        : `-`}
                </span>
            </div>
        </div>
    );
}

export default ProductSettingsSettingsCard;
