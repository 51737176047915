import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { normalizeToSearch } from "../../hooks/Utils/Utils";
import UseSearch from "../useSearch";

const UseModalCustomerAccessList = ({ index }) => {
    const { t } = useTranslation();

    //Store
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);

    //States
    const [items, setItems] = useState([]);
    const [itemsFiltered, setItemsFiltered] = useState(null);
    const [search, setSearch] = useState("");

    //Listeners
    useEffect(() => {
        if (data && data.logins && data.logins.length > 0) {
            setItems(arrangeItems(data.logins, data.mobileApps, data.properties));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (items && items.length > 0) {
            setItemsFiltered(items);
        }
        // eslint-disable-next-line
    }, [items]);

    useEffect(() => {
        if (search && search !== "") {
            let itemsClone = [];
            // eslint-disable-next-line
            items.map((item) => {
                let add = false;
                if (normalizeToSearch(item.name).includes(normalizeToSearch(search))){
                    add = true;
                }
                // eslint-disable-next-line
                item.accessDates.map((accessDate) => {
                    if (normalizeToSearch(accessDate).includes(normalizeToSearch(search))){
                        add = true;
                    }
                });
                if (add){
                    itemsClone.push(item);
                }
            });
            setItemsFiltered(itemsClone);
        } else {
            setItemsFiltered(items);
        }
        // eslint-disable-next-line
    }, [search]);

    //Arrange functions
    const arrangeItems = (logins, mobileApps, properties) => {
        let response = [];
        if (logins && logins.length > 0) {
            // eslint-disable-next-line
            logins.map((login) => {
                let accessDate = new window.ZDate(login.date).toLocaleString();
                let ref = login.app ? login.app : login.wifi;
                let name = "";
                if(login.app && mobileApps && mobileApps.filter((mobileApp) => mobileApp.url === login.app).length > 0){
                    name = mobileApps.filter((mobileApp) => mobileApp.url === login.app)[0].name;
                }else if(login.wifi && properties && properties.filter((property) => property.ref === login.wifi).length > 0){
                    name = properties.filter((property) => property.ref === login.wifi)[0].name;
                }
                let indexOfItem = response.findIndex((responseObject) => responseObject.ref === ref);
                if (indexOfItem === -1) {
                    response.push({
                        ref: ref,
                        collapsed: true,
                        firstDate: accessDate,
                        name: name,
                        accessDates: [accessDate],
                    });
                } else {
                    response[indexOfItem].accessDates.push(accessDate);
                }
            });
        }
        return response;
    };

    //handle functions
    const handleCollapse = (index) => {
        let itemsClone = [...itemsFiltered];
        itemsClone[index].collapsed = !itemsClone[index].collapsed;
        setItemsFiltered(itemsClone);
    };

    return (
        <>
            <div className=" w-2/4 mb-10">
                <UseSearch
                    value={search}
                    onChange={setSearch}
                    id="search-input-modal-browser"
                    placeholder={"search-for"}
                />
            </div>
            <div className="t-body">
                <div className="t-head-container">
                    <div className="t-row">
                        <div className="w-6/12">
                            <div className="flex items-center">
                                <span className="font-bold text-gray-900 first-capital">{t("accesses-text")}</span>
                            </div>
                        </div>
                        <div className="w-6/12 items-center text-center">
                            <div className="flex items-center">
                                <span className="font-bold text-gray-900 first-capital">{t("date-and-time")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="rows">
                    {itemsFiltered
                        ? itemsFiltered.map((item, index) => (
                              <div onClick={(e) => handleCollapse(index)} key={item.id ? item.id : item.name}>
                                  <div className="border-b border-gray-200">
                                      <div className="t-info-container cursor-pointer">
                                          <span
                                              className={`icon-chevron animated mr-4 ml-6 ${
                                                  !item.collapsed ? "-rotate-180" : ""
                                              }`}
                                          ></span>
                                          <div className="t-cell w-5/12">
                                              <div className="text-left">
                                                  <span className="rounded py-1 px-4 m-1 text-white inline-block bg-blue-400 first-capital mr-5">
                                                      {item.name}
                                                  </span>
                                              </div>
                                          </div>
                                          <div className="t-cell w-1/12"></div>
                                          <div className="t-cell w-6/12">
                                              <div className="text-left truncate">
                                                  {item.collapsed ? (
                                                      <span className="block">{item.firstDate}</span>
                                                  ) : (
                                                      item.accessDates &&
                                                      item.accessDates.map((accessDate) => (
                                                          <span className="block my-2" key={`accessDate-${accessDate}`}>{accessDate}</span>
                                                      ))
                                                  )}
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          ))
                        : null}
                </div>
            </div>
        </>
    );
};

export default UseModalCustomerAccessList;
