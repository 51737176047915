import React, { useEffect, useRef, useState, useContext, useLayoutEffect } from "react";
import SalesLibraryMOCKShop from "./libraryMock/SalesLibrabyMOCKShop";
import {
    SALES_SEQUENCE,
    generateFocusInSalesLibrary,
    getProp,
    getSalesTexts,
    parseColorsToStyles,
} from "../../../../hooks/Utils/Design/SalesUtils";
import { useDispatch, useSelector } from "react-redux";
import vendureShops from "./dataMock/listadoTiendasVendureJSON.json";
import { Roomshops, Subcategories, Cart, Details, MyOrders } from "sales-layout-library/web";
import { IFrame } from "../../../useIframe";
import UseInputText from "../../../Inputs/useInputText";
import { Session } from "../../../../hooks/Utils/Session";
import SalesTextOption from "./stylesEditor/SalesTextOption";
import { setSectionContent } from "../../../../actions/sectionActions";
import { getFromDAS } from "../../../../hooks/Utils/Utils";
import { EditorContext } from "../../../../contexts/editor";
import { DEVICE } from "../../../../hooks/Utils/Design/EditorUtils";

import Shops from "./layouts/Shops";
import ShopProducts from "./layouts/ShopProducts";
import ShopProduct from "./layouts/ShopProduct";
import ShopCart from "./layouts/ShopCart";
import ShopOrders from "./layouts/ShopOrders";
import { useTranslation } from "react-i18next";
import { px2vw } from "hooks/Utils/DesignUtils";

const SalesPreview = () => {
    const { t } = useTranslation();
    const { currentScreen, salesTitleData, salesStyleData, highlightedSection, text, title, background, ordersButton } =
        useSelector((state) => state.sectionContent);

    const { values } = useSelector((state) => state.action);
    const { cLang } = useSelector((state) => state.design);

    const { screenType, deviceType, theme } = useContext(EditorContext);
    const dispatch = useDispatch();

    const [SHOPS_DATA, setSHOPS_DATA] = useState(null);

    const isTV = deviceType === DEVICE.TYPE.TV;
    const isMobile = deviceType === DEVICE.TYPE.MOBILE;

    const sequence = screenType ? SALES_SEQUENCE[screenType] : null;
    const customizeTitle = isTV && currentScreen === sequence?.SHOPS;

    const cssTemplating = useRef();
    cssTemplating.current = parseColorsToStyles(screenType, theme, salesStyleData, highlightedSection);

    const titleData = useRef();
    titleData.current = salesTitleData;

    const [mainBgImage, setMainBgImage] = useState(null);

    const setPreviewBG = (bg) => {
        if (bg?.libraryRef) {
            setMainBgImage(getFromDAS(bg.libraryRef, cLang));
        } else if (bg?.externalUrl) {
            setMainBgImage(bg.externalUrl);
        } else {
            setMainBgImage(null);
        }
    };

    useLayoutEffect(() => {
        if (theme.themeReady) {
            generateFocusInSalesLibrary(currentScreen.divToFocus, salesStyleData, theme);
        }
    }, [currentScreen, theme.focusBorderSize, salesStyleData]);

    useEffect(() => {
        titleData.current = salesTitleData;
    }, [salesTitleData]);

    useEffect(() => {
        setMainBgImage(null);
        if (values?.bgStyles && background.useHomeBg) {
            const bgImages = JSON.parse(values.bgStyles).images?.[0] ? JSON.parse(values.bgStyles).images[0] : null;
            setPreviewBG(bgImages);
        } else if (!background.useHomeBg) {
            setPreviewBG(background);
        }
        // eslint-disable-next-line
    }, [values, background]);

    useEffect(() => {
        if (!SHOPS_DATA) {
            setSHOPS_DATA(
                vendureShops.data.channels.map((_shop) => {
                    if (!_shop?._parsed) {
                        _shop.customFields.delivery_schedule = JSON.stringify(_shop.customFields.delivery_schedule);
                    }
                    _shop._parsed = true;
                    return _shop;
                })
            );
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        cssTemplating.current = parseColorsToStyles(screenType, theme, salesStyleData, highlightedSection);
        // eslint-disable-next-line
    }, [highlightedSection]);

    useEffect(() => {
        if (text || title) {
            if (isTV) {
                changeiFrameStyles(text, title, salesStyleData);
            }
        }
        // eslint-disable-next-line
    }, [text, title, salesStyleData]);

    const printSalesPreview = () => {
        const config = isTV
            ? { css: cssTemplating.current, languageCode: cLang, texts: getSalesTexts(cLang) }
            : {
                  css: cssTemplating.current,
                  titleFont: title
                      ? {
                            name: title.name,
                            externalUrl: title.externalUrl,
                            libraryRef: title.libraryRef,
                        }
                      : null,
                  textFont: text
                      ? {
                            name: text.name,
                            externalUrl: text.externalUrl,
                            libraryRef: text.libraryRef,
                        }
                      : null,
                  languageCode: cLang,
              };
        switch (currentScreen) {
            case sequence?.SHOPS:
                return isTV ? (
                    <Roomshops
                        templating={{
                            ...config,
                            strings: { welcomeMessage: "\xa0", showMyOrders: ordersButton },
                        }}
                    />
                ) : (
                    <Shops templating={config} />
                );
            case sequence?.PRODUCTS_LIST:
                return isTV ? <Subcategories templating={config} /> : <ShopProducts templating={config} />;
            case sequence?.PRODUCT_DETAIL:
                return isTV ? <Details templating={config} /> : <ShopProduct templating={config} />;
            case sequence?.CART:
                return isTV ? <Cart templating={config} /> : <ShopCart templating={config} />;
            case sequence?.ORDERS:
                return isTV ? <MyOrders templating={config} /> : <ShopOrders templating={config} />;
            default:
                return <SalesLibraryMOCKShop />;
        }
    };

    return (
        <div
            id="salesPreview"
            className={`relative mb-8 mr-4 mt-2 bg-white shadow ${isMobile ? "overflow-auto" : "overflow-hidden"}`}
        >
            {customizeTitle ? (
                <div
                    className="absolute w-full"
                    style={{
                        fontSize: `${px2vw(36, 1922)}vw`,
                        height: "14%",
                        fontFamily: `${title.name}`,
                    }}
                >
                    <div
                        className="block"
                        style={{
                            height: "-webkit-fill-available",
                            paddingRight: ordersButton ? "9vw" : "0.2vw",
                            paddingLeft: "0.2vw",
                            fontSize: `${px2vw(26, 1922)}vw`,
                            color: cssTemplating.current?.roomshops?.section1?.default?.color,
                        }}
                    >
                        <UseInputText
                            id="salesInputText"
                            adjust={`text-${titleData.current?.alignment} bg-white h-full py-0`}
                            fitHeightToParent={true}
                            forceBgColor="transparent"
                            value={titleData.current?.text}
                            action={(value) => {
                                if (value !== titleData.current?.text) {
                                    dispatch(
                                        setSectionContent({
                                            salesTitleData: { ...titleData.current, text: value },
                                            styleInputData: { generalSalesSave: true, value },
                                        })
                                    );
                                }
                            }}
                            onFocus={() => {
                                dispatch(
                                    setSectionContent({
                                        salesTitleData: { ...titleData.current, showOptions: true },
                                    })
                                );
                            }}
                        />
                    </div>
                    {titleData.current?.showOptions && <SalesTextOption />}
                </div>
            ) : null}
            {isTV ? (
                <IFrame
                    id={"salesPreviewiFrame"}
                    onLayoutCallBack={() => {
                        changeiFrameStyles(text, title, salesStyleData);
                    }}
                >
                    <>
                        <div
                            id="bgColor-mainBgImage"
                            style={{
                                height: "100%",
                                position: "absolute",
                                backgroundImage: mainBgImage ? `url('${mainBgImage}')` : "",
                                backgroundRepeat: "round",
                                backgroundAttachment: "fixed",
                                backgroundSize: "cover",
                                opacity: getProp(salesStyleData, "background.opacity") / 100,
                                width: "100%",
                                zIndex: 0,
                            }}
                        ></div>
                        <div
                            id="overlayGray"
                            style={{
                                height: "100%",
                                position: "absolute",
                                backgroundColor: "rgba(22,22,22,0.8)",
                                width: "100%",
                                zIndex: 10,
                                display: `${highlightedSection ? "block" : "none"}`,
                            }}
                        ></div>
                        {printSalesPreview()}
                    </>
                </IFrame>
            ) : (
                <>{printSalesPreview()}</>
            )}
        </div>
    );
};

const changeiFrameStyles = (text, title, salesStyleData) => {
    const iframe = document.getElementById("salesPreviewiFrame");
    if (iframe) {
        const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        const cssContent = `
           * {
                overflow: hidden!important;
                box-sizing: border-box;
            }
            body::-webkit-scrollbar {
                display:none;
            }
            body{
                -ms-overflow-style:none;
                scrollbar-width:none;
                margin: 0px;
                font-family: ${text?.name};
                background-color: ${getProp(salesStyleData, "background.color")};
            }
            .title{
                font-family: ${title?.name || ""} !important;
            }
        `;
        const styleElement = iframeDocument.createElement("style");
        styleElement.innerHTML = cssContent;
        iframeDocument.head.appendChild(styleElement);

        const linkElement = iframeDocument.createElement("link");
        linkElement.rel = "stylesheet";
        linkElement.href = "https://drive.zafiro.cloud/fonts/icomoon/icomoon.css";
        iframeDocument.head.appendChild(linkElement);

        if (title) {
            const titleLink = iframeDocument.createElement("link");
            titleLink.rel = "stylesheet";
            const titleHref =
                title.ref === "EXTERNAL" ? title.externalUrl : Session.getDasUrl(title.ref + "?format=css");
            titleLink.href = titleHref;
            iframeDocument.head.appendChild(titleLink);
        }
        if (text) {
            const textLink = iframeDocument.createElement("link");
            textLink.rel = "stylesheet";
            const textHref = text.ref === "EXTERNAL" ? text.externalUrl : Session.getDasUrl(text.ref + "?format=css");
            textLink.href = textHref;
            iframeDocument.head.appendChild(textLink);
        }
    }
};

export default SalesPreview;
