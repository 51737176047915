import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setActions,
    setAvailableFilters,
    setCount,
    setCountPosition,
    setDateHeader,
    setError,
    setExtendedInfo,
    setHeaderButtons,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setTitle,
    setRowGroups,
    setDateFilter,
    setFormNewContent,
    setCustomError,
    cleanActiveFilters,
    setRefreshData,
    setReload,
    setCustomConfig,
} from "../../../actions/tableActions";
//Utils
import { useParams } from "react-router-dom";
import GetTableHeaders from "../../Utils/Table/Headers/GetCZTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import GetTableForm from "../../Utils/Table/GetTableForm";
import GetFilterFormattedDate from "../../Utils/GetFilterFormattedDate";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
//Model
import BookingCategory from "./models/BookingCategory";
import { useTranslation } from "react-i18next";
import { POLLINTERVAL_15S } from "../../Utils/Utils";

const ListBookingCategory = () => {
    const { t } = useTranslation();
    const {
        currentPage,
        perPage,
        filterQuery,
        dateFilter,
        sortCriteria,
        refreshData,
        reload,
        loading: currentLoadingTable,
    } = useSelector((state) => state.table);
    const { actionName } = useSelector((state) => state.action);

    //Data
    const { id, date } = useParams();
    const sectionName = "booking-category";
    const itemType = "booking-category";

    //States
    const [sorting] = useState(`,orderBy:{field:"hour", criteria:"asc"}`);

    //Actions
    const dispatch = useDispatch();

    //API

    const GET_AREA_BOOKINGS = gql`{
        bookings(page: ${currentPage}, size: ${perPage}, area: "${id}" ${
        GetFilterFormattedDate(dateFilter) ? `, currentDate:"${GetFilterFormattedDate(dateFilter)}"` : ""
    }${sortCriteria ? sortCriteria : sorting}${filterQuery}) {
                info{count}
            results{ id bookingDate bookingDone email name peopleAmount phone status  reasonCanceled}
        }
        commonzones: commonzones(page: 1, size: 1, filter:{ref:"${id}"}) {
            results {
            name isOver bookingFreq slotsPerBooking
            }
        }
    }`;
    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_AREA_BOOKINGS, {
        pollInterval: POLLINTERVAL_15S,
        onCompleted: () => {
            dispatch(setLoading(false));
        },
        notifyOnNetworkStatusChange: "true",
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        if (date) {
            dispatch(setDateFilter(new window.ZDate(date)));
        } else if (dateFilter === false) {
            dispatch(setDateFilter(new window.ZDate()));
        }
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let timeOut;
        if (currentLoadingTable) {
            timeOut = setTimeout(() => {
                dispatch(setLoading(false));
            }, 1500);
        }
        console.log(currentLoadingTable);

        return () => {
            if (timeOut) {
                clearTimeout(timeOut);
            }
        };
    }, [currentLoadingTable]);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if ((!loading && !error && data && data.bookings) || reload) {
            //Get Data
            const dataItems = arrangeData(data);
            const tableHeaders = GetTableHeaders(sectionName);
            const availableFilters = GetAvailableFilters(sectionName);
            //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable());
            dispatch(setHeaders(tableHeaders));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.bookings.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setDateHeader());
            dispatch(setFormNewContent(GetTableForm(itemType)));
            dispatch(setExtendedInfo(true));
            dispatch(
                setCustomConfig({
                    searchText: "search-name-email",
                    searchNoResultText: "no-booking-for-name-email",
                    searchStyle: "col-span-3",
                    filtersStyle: "col-span-9",
                })
            );
            dispatch(setCustomError(t("no bookings available")));

            switch (dateFilter) {
                //Set today as filter if no date selected
                case false:
                    dispatch(setDateFilter(new window.ZDate()));
                    break;
                //Show groups is no date selected
                case "":
                    dispatch(setRowGroups(true));
                    break;
                default:
                    dispatch(setRowGroups(false));
            }
            if (dataItems.length > 0) {
                if (dataItems[0].actions) {
                    dispatch(setActions());
                }
            }
            dispatch(setTitle(data.commonzones.results[0].name));
            if (!data.commonzones.results[0].isOver) {
                dispatch(setHeaderButtons(GetTableHeaderButton(itemType)));
            }
            if (actionName === "new-area-booking") {
                dispatch(showRowForm(true));
            } else {
                dispatch(showRowForm(false));
            }
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
            dispatch(setReload(false));
        }
        // eslint-disable-next-line
    }, [data, reload]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);
};

const arrangeData = (data) => {
    const tempArray = [];
    const displayedDates = [];
    // eslint-disable-next-line
    data.bookings.results.map((booking) => {
        const { bookingDate } = booking;
        tempArray.push(BookingCategory(booking, displayedDates, data.commonzones.results[0]));
        displayedDates.push(new window.ZDate(bookingDate).toLocaleDateString());
    });
    return tempArray;
};

export default ListBookingCategory;
