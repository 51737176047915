import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";

//Utils
import ListProperties from "../../../hooks/GraphqlCalls/Settings/ListProperties";

const Properties = () => {
    ListProperties();
    return (
        <>
            <UseSectionHeader title={"manager-properties"} />
            <UseTable />
        </>
    );
};

export default Properties;
