import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table/";
//Utils
import ListGuests from "../../../../hooks/GraphqlCalls/Customers/ListGuests";
import UpdateBooking from "hooks/GraphqlCalls/Booking/UpdateBooking";

const Guests = () => {
    ListGuests();
    return (
        <>
            <UpdateBooking></UpdateBooking>
            <UseSectionHeader title={"hotel-guests"} />
            <UseTable />
        </>
    );
};

export default Guests;
