import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "components/ReactTooltip";
import { changeActionValues } from "../../actions/globalActions";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import _ from "lodash";
import { allergensDefaultLang } from "../../hooks/Utils/SalesUtils";

const UseModalSalesAllergensTranslate = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //Store
    const { data, name } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const projectRef = useSelector((state) => state.hotel.ref);

    //States
    const [items, setItems] = useState(null);
    const [openTab, setOpenTab] = useState(0);

    //Listeners
    useEffect(() => {
        if (data) {
            setItems(arrangeItems(data.projectLangs, data.allergens, data.facetValues));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(changeActionValues({ [name]: items }));
        // eslint-disable-next-line
    }, [items]);

    //Arrange functions
    const arrangeItems = (projectLangs, allergens, facetValues) => {
        const response = [];

        if (projectLangs && projectLangs.length > 0) {
            const sortedProjectLangs = projectLangs.sort((a, b) => a.name.localeCompare(b.name));
            // eslint-disable-next-line
            sortedProjectLangs.map((projectLang) => {
                let item = {
                    ref: projectLang.id,
                    name: projectLang.name,
                    allergens: [],
                    isDefault: projectLang.isDefault,
                    translationsCompleted: true,
                };
                if (allergens && allergens.length > 0) {
                    // eslint-disable-next-line
                    allergens.map((allergen) => {
                        let facetValue =
                            facetValues && facetValues.length > 0 ? _.find(facetValues, { code: allergen.code }) : null;
                        let translation = null;
                        if (facetValue) {
                            if (facetValue.translations && facetValue.translations.length > 0) {
                                translation = facetValue.translations.filter(
                                    (translationFiltered) => translationFiltered.languageCode === projectLang.id
                                );
                                if (translation && translation.length > 0) {
                                    translation = translation[0];
                                    if (!translation.name) {
                                        item.translationsCompleted = false;
                                    }
                                } else {
                                    let defaultTranslation = facetValue.translations.filter(
                                        (translationFiltered) =>
                                            translationFiltered.languageCode === allergensDefaultLang
                                    );
                                    if (defaultTranslation && defaultTranslation.length > 0) {
                                        defaultTranslation = defaultTranslation[0];
                                        if (defaultTranslation.name) {
                                            translation = {
                                                name: defaultTranslation.name,
                                                languageCode: allergensDefaultLang,
                                            };
                                        }
                                    }
                                    item.translationsCompleted = false;
                                }
                            } else {
                                item.translationsCompleted = false;
                            }
                        } else {
                            item.translationsCompleted = false;
                        }
                        let allergenData = {
                            facetId: facetValue ? facetValue.id : null,
                            facetName: t(allergen.label),
                            facetCode: allergen.code,
                            translationId: translation ? translation.id : null,
                            translationValue: translation && translation.name ? translation.name : "",
                            translationLang: translation ? translation.languageCode : projectLang.id,
                        };
                        item.allergens.push(allergenData);
                    });
                }
                response.push(item);
            });
        }
        return response;
    };

    //handle functions
    const handleChange = (index, indexAllergen, value) => {
        let itemsClone = [...items];
        itemsClone[index].allergens[indexAllergen].translationValue = value;
        setItems(itemsClone);
    };

    return (
        <div className="w-full">
            <div className="flex flex-wrap">
                <div className="w-full">
                    <ul className="flex mb-0 list-none flex-wrap flex-row" role="tablist">
                        {items && items.length > 0
                            ? items.map((item, index) => (
                                  <li key={index} className={`text-center`}>
                                      <a
                                          className={`first-capital px-10 py-3 block border-b-4  font-bold ${
                                              openTab === index ? `border-blue-600 t-link` : `text-gray-800 border-b-0`
                                          }`}
                                          onClick={(e) => {
                                              e.preventDefault();
                                              setOpenTab(index);
                                          }}
                                          data-toggle="tab"
                                          href={`#link${index}`}
                                          role="tablist"
                                          data-tip={
                                              item.isDefault && projectRef !== "CORPORATE"
                                                  ? capitalizeFirst(t("default-lang"))
                                                  : ""
                                          }
                                      >
                                          {item.name}{" "}
                                          {item.translationsCompleted ? (
                                              <img
                                                  alt="active btn"
                                                  className="float-right w-5 h-5 ml-1"
                                                  src={require(`../../assets/images/icons/save-circle-green.svg`)}
                                              ></img>
                                          ) : null}
                                      </a>
                                      <ReactTooltip
                                          place="bottom"
                                          type="light"
                                          offset={{ top: -8, left: -8 }}
                                          html={true}
                                          border={true}
                                          borderColor="#D3DAE1"
                                      />
                                  </li>
                              ))
                            : null}
                    </ul>
                    <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 ">
                        <div className={`py-5 flex-auto`}>
                            <div className="tab-content tab-space">
                                {items && items.length > 0
                                    ? items.map((item, index) => (
                                          <div
                                              key={`contentlink${index}`}
                                              className={openTab === index ? "block" : "hidden"}
                                              id={`link${index}`}
                                          >
                                              <div className="w-full text-left">
                                                  {item.allergens.map((allergen, indexAllergen) => (
                                                      <div className="w-4/12 inline-block align-top pr-20 mb-5">
                                                          <span className="block mb-1 text-gray-800 font-bold">
                                                              {allergen.facetName}
                                                          </span>
                                                          <input
                                                              type="text"
                                                              className="w-full h-10 bg-gray-200 rounded p-2 hover:text-zafiro-400"
                                                              value={allergen.translationValue}
                                                              onChange={(e) =>
                                                                  handleChange(index, indexAllergen, e.target.value)
                                                              }
                                                              id={`allergen-input-${indexAllergen}-${item.ref}`}
                                                          />
                                                      </div>
                                                  ))}
                                              </div>
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UseModalSalesAllergensTranslate;
