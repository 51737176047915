import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { useState, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import UseButton from "../../../../useButton";
import UseInputDate from "components/useInputDate";
import {
    BOOKING_INFORMATION_INITIAL_DATA,
    VALIDATE_BOOKING_INFORMATION_FIELDS,
    HANDLER_INTEGER_INPUT,
    PENDING_COMPLETION_STATE,
    ARRANGE_BOOKING_INFORMATION_DATA,
    FORMAT_DATE_TO_DDMMYYYY,
    UPDATE_BOOKING_INFORMATION,
    GET_COLOR_AND_TEXT,
    CONVERT_DATE_YMD_TO_DMY,
    VALIDATED,
    GET_ID_OF_EXTRA_GUESTS,
} from "./utils";
import { toast } from "react-toastify";
import { PreCheckInContext } from "./ReservationDetailsContext";
import { useDispatch } from "react-redux";
import { setExecuteQuery } from "actions/sectionActions";
import Section from "components/SettingsCard/Section";
import ReactTooltip from "components/ReactTooltip";

const BookingInformation = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { data: dataContext, setValuesContext, setInEdition: setInEditionContext } = useContext(PreCheckInContext);
    const [originalData, setOriginalData] = useState({ ...BOOKING_INFORMATION_INITIAL_DATA });
    const [data, setData] = useState({ ...BOOKING_INFORMATION_INITIAL_DATA });
    const [validations, setValidations] = useState({});
    const [inEdition, setInEdition] = useState(false);
    const prefixId = "reservation-details-booking-information";

    useEffect(() => {
        if (dataContext?.stayBookings && inEdition === false) {
            const data = ARRANGE_BOOKING_INFORMATION_DATA(dataContext?.stayBookings);
            setOriginalData({ ...data });
            setData({ ...data });
        }
    }, [dataContext]);

    useEffect(() => {
        setInEditionContext(inEdition);
    }, [inEdition]);

    console.log(data);

    return (
        <div className=" bg-white w-full px-8 h mt-8">
            <Section
                title={capitalizeFirst(t("information"))}
                className="mb-4"
                titleClassName="h-10"
                id={"booking-information-section"}
                edit={
                    inEdition === false
                        ? {
                              onClick: () => {
                                  setInEdition(true);
                              },
                          }
                        : false
                }
            />
            <div className=" flex w-full">
                <div style={{ width: "25%" }} className="">
                    <div className=" font-bold mb-4">{capitalizeFirst(t("personal-information"))}</div>
                    <div>
                        <div className=" font-bold text-sm text-gray-800">{t("rooms-reserved")} *</div>
                        <div className=" mt-2 flex items-center ">
                            <div className=" pr-4 " style={{ maxWidth: "100px" }}>
                                <BookingInformationCustomInput
                                    type={"number"}
                                    step={1}
                                    id={`${prefixId}-input-number-rooms`}
                                    validations={validations}
                                    keyPath={["PERSONAL_INFORMATION", "numberOfRooms"]}
                                    variablesStateData={{ data, setData }}
                                    inEdition={inEdition}
                                />
                            </div>
                            <div className={`${!inEdition ? "mb-4" : ""}`}>{t("rooms")}</div>
                        </div>
                    </div>
                    <div className=" mt-4">
                        <div className=" font-bold text-sm text-gray-800">{t("number-of-guest")} *</div>
                        <div className=" mt-2 flex items-center">
                            <div className=" pr-4" style={{ maxWidth: "100px" }}>
                                <BookingInformationCustomInput
                                    type={"number"}
                                    step={1}
                                    id={`${prefixId}-input-number-guest`}
                                    validations={validations}
                                    keyPath={["PERSONAL_INFORMATION", "numberOfGuests"]}
                                    variablesStateData={{ data, setData }}
                                    inEdition={inEdition}
                                />
                            </div>
                            <div className={`${!inEdition ? "mb-4" : ""}`}>{t("guest")}</div>
                        </div>
                    </div>
                    <div className=" mt-4 w-full ">
                        <div className=" font-bold text-sm text-gray-800">{t("arrival-date")} *</div>
                        <div className=" w-full mt-2 " style={{ overflow: "hidden" }}>
                            <BookingInformationCustomInput
                                type={"date"}
                                id={`${prefixId}-input-date-arrival`}
                                validations={validations}
                                keyPath={["PERSONAL_INFORMATION", "arrivalDate"]}
                                variablesStateData={{ data, setData }}
                                inEdition={inEdition}
                            />
                        </div>
                    </div>
                    <div className=" mt-4 w-full ">
                        <div className=" font-bold text-sm text-gray-800">{t("departure-date")}</div>

                        <div className=" w-full mt-2 flex flex-row" style={{ overflow: "hidden" }}>
                            <BookingInformationCustomInput
                                type={"date"}
                                id={`${prefixId}-input-date-departure`}
                                validations={validations}
                                keyPath={["PERSONAL_INFORMATION", "departureDate"]}
                                variablesStateData={{ data, setData }}
                                inEdition={inEdition}
                            />
                        </div>
                    </div>
                </div>

                <div style={{ width: "25%" }} className=" ml-12 ">
                    <div className=" font-bold mb-4">{capitalizeFirst(t("booking-holder"))}</div>

                    <div className=" mt-4">
                        <div className=" font-bold text-sm text-gray-800">{capitalizeFirst(t("name"))} *</div>
                        <div className=" mt-2 flex items-center">
                            <BookingInformationCustomInput
                                type={"text"}
                                id={`${prefixId}-input-text-name`}
                                validations={validations}
                                keyPath={["BOOKING_HOLDER", "name"]}
                                variablesStateData={{ data, setData }}
                                inEdition={inEdition}
                            />
                        </div>
                    </div>
                    <div className=" mt-4">
                        <div className=" font-bold text-sm text-gray-800">{capitalizeFirst(t("surname"))} *</div>
                        <div className=" mt-2 flex items-center">
                            <BookingInformationCustomInput
                                type={"text"}
                                id={`${prefixId}-input-text-surname`}
                                validations={validations}
                                keyPath={["BOOKING_HOLDER", "surname"]}
                                variablesStateData={{ data, setData }}
                                inEdition={inEdition}
                            />
                        </div>
                    </div>
                    <div className=" mt-4">
                        <div className=" font-bold text-sm text-gray-800">{t("email-address")} *</div>
                        <div className=" mt-2 flex items-center">
                            <BookingInformationCustomInput
                                type={"text"}
                                id={`${prefixId}-input-text-email`}
                                validations={validations}
                                keyPath={["BOOKING_HOLDER", "email"]}
                                variablesStateData={{ data, setData }}
                                inEdition={inEdition}
                            />
                        </div>
                    </div>
                </div>

                <div style={{ width: "30%" }}>
                    <div className=" ml-12  ">
                        <div className=" font-bold mb-4 opacity-0">--</div>
                        <div className=" font-bold text-sm text-gray-800">{t("observations")}</div>
                        <div className=" mt-2 flex items-center">
                            <BookingInformationCustomInput
                                type={"textArea"}
                                id={`${prefixId}-observations-text-area`}
                                validations={validations}
                                keyPath={["OBSERVATIONS"]}
                                variablesStateData={{ data, setData }}
                                inEdition={inEdition}
                            />
                        </div>
                    </div>
                </div>

                <div style={{ width: "20%" }} className=" ml-12  ">
                    <div className=" font-bold mb-4">{t("more-info")}</div>
                    <div className=" font-bold text-sm text-gray-800">{t("PMS id")}</div>
                    <div className=" mb-8 mt-2">{data?.BOOKING_HOLDER?.pmsID || "-"}</div>
                    <div className=" font-bold text-sm text-gray-800">{t("booking-date")}</div>
                    <div className=" mb-8">{FORMAT_DATE_TO_DDMMYYYY(data.MORE_INFO.reservationDate)}</div>
                    <div className=" font-bold text-sm text-gray-800">{t("booking-status")}</div>
                    <div
                        className={`${
                            data.PERSONAL_INFORMATION.pendingChangesSend
                                ? "text-gray-800"
                                : GET_COLOR_AND_TEXT(data.MORE_INFO.state).color
                        }`}
                    >
                        {data.PERSONAL_INFORMATION.pendingChangesSend
                            ? t("data-in-modification")
                            : `${GET_COLOR_AND_TEXT(data.MORE_INFO.state).text}`}
                    </div>
                </div>
            </div>

            {inEdition ? (
                <div className=" w-full flex mt-6 justify-end space-x-4">
                    <UseButton
                        action={() => {
                            setValidations({});
                            setData({ ...originalData });
                            setInEdition(false);
                        }}
                        id={`${prefixId}-cancel-button`}
                        buttonName={t("cancel")}
                        buttonColor={"btn-white"}
                    />
                    <UseButton
                        action={() => {
                            const { validations, reasons } = VALIDATE_BOOKING_INFORMATION_FIELDS(data);
                            reasons.forEach((reason) => {
                                toast.error(t(reason));
                            });
                            setValidations(validations);

                            if (Object.keys(validations).length === 0) {
                                setValuesContext(data);
                                let IDS_EXTRAS_GUESTS = [];

                                if (data.PERSONAL_INFORMATION.numberOfGuests - 1 < data?.GUESTS?.length) {
                                    IDS_EXTRAS_GUESTS = GET_ID_OF_EXTRA_GUESTS(
                                        data?.GUESTS,
                                        data.PERSONAL_INFORMATION.numberOfGuests - 1
                                    );
                                }

                                dispatch(
                                    setExecuteQuery({
                                        action: UPDATE_BOOKING_INFORMATION,
                                        params: { data: { ...data, IDS_EXTRAS_GUESTS } },
                                    })
                                );
                                setInEdition(false);
                            }
                        }}
                        id={`${prefixId}-save-button`}
                        buttonName={t("save")}
                        buttonColor={"btn-blue"}
                    />
                </div>
            ) : null}
        </div>
    );
};

const BookingInformationCustomInput = ({ type, step, id, variablesStateData, validations, keyPath, inEdition }) => {
    const { data, setData } = variablesStateData;
    const inputValue = keyPath.reduce((acc, key) => acc[key], data);
    const key = keyPath[keyPath.length - 1];

    const updateData = (newValue) => {
        const dataModified = JSON.parse(JSON.stringify(data));
        let current = dataModified;
        for (let i = 0; i < keyPath.length - 1; i++) {
            current = current[keyPath[i]];
        }
        current[keyPath[keyPath.length - 1]] = newValue;
        setData(dataModified);
    };

    const textValue = () => {
        if (
            (!inEdition && key === "arrivalDate" && inputValue) ||
            (!inEdition && key === "departureDate" && inputValue)
        ) {
            return CONVERT_DATE_YMD_TO_DMY(inputValue);
        } else if (!inEdition && inputValue) {
            return inputValue + "";
        } else if (!inEdition && !inputValue) {
            return "-";
        } else if (inEdition && inputValue) {
            return "";
        }
    };

    return (
        <>
            {(type === "text" || type === "number") && inEdition ? (
                <input
                    type={type}
                    step={step}
                    id={id}
                    className={` ${
                        validations[key] ? "border-red-100 border" : " border border-transparent"
                    }  h-11 bg-gray-200 rounded w-full  p-2`}
                    onChange={(e) => {
                        if (type === "number") {
                            HANDLER_INTEGER_INPUT(e, (intValue) => {
                                updateData(intValue);
                            });
                        } else {
                            const val = e.target.value;
                            updateData(val);
                        }
                    }}
                    value={inputValue}
                ></input>
            ) : null}
            {type === "textArea" && inEdition ? (
                <textarea
                    className=" h-36 resize-none p-2 bg-gray-200 rounded"
                    name="textarea"
                    rows="10"
                    cols="50"
                    value={inputValue}
                    id={id}
                    onChange={(e) => {
                        const val = e.target.value;
                        updateData(val);
                    }}
                ></textarea>
            ) : null}
            {type === "date" && inEdition ? (
                <UseInputDate
                    data={{
                        name: id,
                        fullwidth: true,
                        nonInitialDate: true,
                        date: inputValue,
                        validation: validations[key] ? false : true,
                        noCapitalizePlaceholder: true,
                        placeholder: "dd/mm/yyyy",
                        styleAdjust: `"h-11" ${!inputValue ? " text-gray-600" : ""}`,
                        id: id,
                        onChange: (date) => {
                            updateData(date);
                        },
                    }}
                />
            ) : null}

            {!inEdition ? (
                <div className=" mb-4" id={`${id}-value-label`}>
                    {textValue()}
                </div>
            ) : null}
        </>
    );
};

export default BookingInformation;
