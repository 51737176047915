import React from "react";
import { useNavigate as useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import NewGuestData from "./NewGuest/NewGuestData";
import NewStayData from "./NewGuest/NewStayData";
import NewDigitalKey from "./NewGuest/NewDigitalKey";
import UseButton from "../../../useButton";
//Actions
import AddNewGuest from "../../../../hooks/GraphqlCalls/Customers/AddNewGuest";
import { changeActionValues } from "../../../../actions/globalActions";

const AddGuest = ({ backToRoomDetail, breadcrumbsLimit, roomId }) => {
    const history = useHistory();
    const { permissions } = useSelector((state) => state.ui);

    //Constantes
    const hasDigitalKeys = permissions && permissions.guests && permissions.guests.digitalKey;

    //state
    const dispatch = useDispatch();
    return (
        <>
            <AddNewGuest backToRoomDetail={backToRoomDetail} />
            <UseSectionHeader
                title={"check-in"}
                navToPreviousPage={roomId ? true : false}
                actionOnBack={
                    roomId
                        ? function () {
                              backToRoomDetail();
                          }
                        : null
                }
                breadcrumbsLimit={breadcrumbsLimit || 0}
            />
            <div className="grid grid-cols-3 gap-8 mb-5">
                <div className="bg-white row-span-2 rounded">
                    <NewGuestData />
                </div>
                <div className={`bg-white col-span-2 rounded ${!hasDigitalKeys ? "row-span-2" : ""}`}>
                    <NewStayData roomId={roomId} />
                </div>
                {hasDigitalKeys ? (
                    <div className="bg-white col-span-2 rounded">
                        <NewDigitalKey />
                    </div>
                ) : null}
            </div>
            <div className="w-full">
                <div className="float-right ml-5">
                    <UseButton
                        buttonName="check-in"
                        buttonColor="btn-blue"
                        id={"new-guest-form-check-in-button"}
                        action={() => {
                            dispatch(changeActionValues({ save: true }));
                        }}
                    />
                </div>
                <div className="float-right" onClick={(e) => (roomId ? backToRoomDetail() : null)}>
                    <UseButton
                        id={"new-guest-form-cancel-button"}
                        buttonName="cancel"
                        buttonColor="btn-white"
                        action={roomId ? null : () => history(-1)}
                    />
                </div>
            </div>
        </>
    );
};

export default AddGuest;
