export const QUERY_GET_PRODUCT_DATA = (id) => {
    return `query {
        channel(id: ${id}) {
          id
          code
          token
          customFields {
            default_tax {
              id
            }
            default_tax_category {
              id
            }
            is_active
          }
          currencyCode
        }
        products {
          items {
            id
            name
            description
            slug
            enabled
            translations {
              name
              languageCode
            }
            featuredAsset{
              id
              preview
            }
            assets {
              preview
            }
            variants {
              name
              enabled
              priceWithTax
              price
              taxRateApplied {
                value
              }
            }
            facetValues {
              facet {
                id
                name
              }
            }
          }
        }
      }
      `;
};

export const QUERY_SALES_PRODUCT_SETTINGS_VENDURE_TOKEN = ({ shopId, productId }) => {
    return `query{
        channel: channel(id:${shopId}){
			currencyCode
			defaultTaxZone{
				id
			}
            code
            token
            pricesIncludeTax
		}
		product(id:${productId}){
			id
			enabled
			translations{
				id
				languageCode,
				name
				description
				slug
			}
      facetValues{
				id
				code
				translations{
					id
					languageCode
					name
				}
				facet{
					code
				}
			}
      assets{
        id 
        preview
      }
      featuredAsset{
          id
          preview
      }
		}
		productVariants: productVariants(productId:${productId}){
			items{
				id
				price
				priceWithTax
				taxCategory{
					id
				}
				customFields{
                    are_allergens_enabled
					max_units_per_order
                    max_units_per_order_enabled
				}
			}
		}
        extras: extras(productId:${productId}){
            id
            available
            type
            appliesTo
            taxCategory
            order
            translations{
                languageCode
                name
            }
            options{
                min
                max
                priceWithoutTax
                choices{
                    available
                    priceWithoutTax
                    taxCategory
                    order
                    translations{
                        languageCode
                        name
                    }
                }
            }
        }
        taxRates: taxRates{
            items{
                id
                name
                value
                zone{
                    id
                    name
                }
                category{
                    id
                }
            }
        
        }
	}`;
};

export const QUERY_SALES_PRODUCT_SETTINGS_WITHOUT_VENDURE_TOKEN = ({ productId, allergensPrefix, labelsPrefix }) => {
    return `query{
        allergens: facets(options:{
            filter:{code:{eq:"${allergensPrefix}"}}
        }){
            items{
                code
                values{
                    id
                    code
                    translations{
                        id
                        languageCode
                        name
                    }
                }
            }
        }
        labels: facets(options: {
            filter:{code:{contains:"${labelsPrefix}"}}
        }){
            items {
                code
                values{
                    id
                    code
                    translations{
                        id
                        languageCode
                        name
                    }
                }
            }
        }
    }`;
};

export const QUERY_SALES_CATEGORIES_DATA = (id) => {
    return `query {
        ${
            id
                ? `
                collection: collection(id: "${id}") {
                    id
                    name
                    id
                    slug
                    translations {
                      languageCode
                      name
                      slug
                      id
                    }
                    customFields {
                      image_url
                      enabled
                      can_have_children
                    }
                    breadcrumbs {
                      id
                      name
                      slug
                    }
                    filters {
                      args {
                        name
                        value
                      }
                    }
                  } 
            `
                : ""
        }
        products {
            items {
              id
              name
              enabled
              translations {
                name
                languageCode
              }
              facetValues {
                id
                name
                facet {
                  id
                  name
                }
              }
              collections {
                breadcrumbs {
                  id
                  slug
                  name
                }
              }
            }
        }
        collections: collections (options: { filter: { ${id ? `, id: { eq: "${id}" }` : ""} }}) {
            items {
              id
              slug
              breadcrumbs {
                id
                name
                slug
              }
              translations {
                languageCode
                name
                slug
                id
              }
              customFields {
                image_url
                enabled
                can_have_children
              }
              filters {
                args {
                  name
                  value
                }
              }
              productVariants {
                totalItems
              }
        
              children {
                id
                slug
                translations {
                  languageCode
                  name
                  slug
                }
                customFields {
                  image_url
                  enabled
                  can_have_children
                }
                filters {
                  args {
                    name
                    value
                  }
                }
                productVariants {
                  totalItems
                }
                children {
                  id
                  slug
                  translations {
                    languageCode
                    name
                    slug
                  }
                  customFields {
                    image_url
                    enabled
                    can_have_children
                  }
                  filters {
                    args {
                      name
                      value
                    }
                  }
                  productVariants {
                    totalItems
                  }
                  children {
                    id
                    slug
                    translations {
                      languageCode
                      name
                      slug
                    }
                    customFields {
                      image_url
                      enabled
                      can_have_children
                    }
                    filters {
                      args {
                        name
                        value
                      }
                    }
                    productVariants {
                      totalItems
                    }
                  }
                }
              }
            }
          }
      }`;
};

export const QUERY_SALES_SHOP_ASSIGNED_USERS_DATA = (tokenShop) => {
    return `query {
      shops(token: "${tokenShop}") {
        results {
          users
          usersInfo {
            accountname
            fullName
            id
          }
          nameTranslations {
            lang
            text
          }
          token
        }
      }
      users {
        results {
          id
          email
          managerRole
          ref
        }
      }
    }
    `;
};

export const QUERY_SALES_GET_LABELS_DATA = ({ shopId, labelsPrefix }) => {
    return `query{
      channel: channel(id: "${shopId}") {
        code
        token
      }
      facets: facets(options: { filter: { code: { contains: "${labelsPrefix}" } } }) {
        items {
          id
          code
          values {
            id
            code
            translations {
              id
              languageCode
              name
            }
          }
        }
      }
  }`;
};
