import React, { Fragment } from "react";
import { formatDate } from "./services/formatDate";
import { useTranslation } from "react-i18next";
import { Warning } from "./Warning";
import { NetworkHeader } from "./NetworkHeader";

export const NetworkInfo = ({ networkInfo, called, loading }) => {
    const { t } = useTranslation();

    const { mdnsData, lastUpdate, updateTimeMin, areUsers } = networkInfo || {};
    const lastUpdateDate = formatDate(lastUpdate);

    if (!called || loading) return null;

    return (
        <section className="bg-white p-4 rounded-b-md flex flex-col">
            <section>
                <NetworkHeader title="mdns" lastUpdateDate={lastUpdateDate} updateTimeMin={updateTimeMin} />
                <section className={`border-b border-gray-300 pb-4 ${areUsers ? "mx-4" : ""} `}>
                    {!areUsers && (
                        <div className="mb-8">
                            <div className="flex items-center">
                                <span className="icon-info text-xl ml-3"></span>
                                <span className="ml-2">{t("no-user-data")}</span>
                            </div>
                            <span className="ml-10 ">{t("not-possible-to-monitor")}</span>
                        </div>
                    )}
                    <ul className="flex flex-col gap-4 ml-10 list-disc">
                        {Object.keys(mdnsData).map((key) => {
                            const { value, warning, empty } = mdnsData[key];
                            return (
                                <Fragment key={key}>
                                    <li>
                                        {t(key)}:{" "}
                                        {empty ? (
                                            <span>-</span>
                                        ) : typeof value === "string" ? (
                                            <span className={"font-bold"}>{value}</span>
                                        ) : value ? (
                                            <i className="icon-tick-right text-green-100 mt-1"></i>
                                        ) : (
                                            <i className="icon-remove text-red-100"></i>
                                        )}
                                    </li>
                                    {areUsers ? (
                                        <>
                                            {warning && key === "mdns" ? (
                                                <Warning text={t("mdns-filter")} color={"red"} />
                                            ) : null}
                                            {warning.critical && key === "cc-connectivity" ? (
                                                <div className="flex gap-2 ml-3 mr-4  text-red-100">
                                                    <span className="icon icon-warning"></span>
                                                    <p className=" text-gray-800" id="problems-in-user-network">
                                                        {t("arp-proxy-or-hotspot-mac", {
                                                            network: t("user"),
                                                            proxy: "ARP",
                                                        })}{" "}
                                                        {warning?.arpMacs?.map((mac) => (
                                                            <span key={mac}>{mac}</span>
                                                        ))}
                                                        . {t("hotspot-case")} ({t("ip")}:{" "}
                                                        {warning?.serverIPS?.map((ip) => (
                                                            <span className="mr-2" key={ip}>
                                                                {ip}
                                                            </span>
                                                        ))}{" "}
                                                        / {t("mac")}{" "}
                                                        {warning?.serverMacs?.map((mac) => (
                                                            <span key={mac}>{mac}</span>
                                                        ))}
                                                        )<span className="block my-2">{t("warning-persist")}</span>
                                                    </p>
                                                </div>
                                            ) : null}
                                        </>
                                    ) : null}
                                </Fragment>
                            );
                        })}
                    </ul>
                </section>
            </section>
        </section>
    );
};
