import { ADD_PRODUCT_EXTRA } from "../../../Utils/Integrations/ShopSettingsUtils";

const NewExtra = (props) => {
    const { appliesTo, taxCategory, order, langDefault } = props;
    return {
        title: "new-extra",
        //bodyAdjust: "w-4/12",
        executeSectionQuery: true,
        useGlobalLoading: true,
        inputs: [
            {
                name: "productAddExtra",
                type: "product-add-extra",
                data: { langDefault: langDefault },
            },
            {
                name: "appliesTo",
                value: appliesTo,
                type: "text",
                style: "hidden",
            },
            {
                name: "taxCategory",
                value: taxCategory,
                type: "text",
                style: "hidden",
            },
            {
                name: "order",
                value: order,
                type: "text",
                style: "hidden",
            },
            {
                name: "langDefault",
                value: langDefault,
                type: "text",
                style: "hidden",
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: ADD_PRODUCT_EXTRA,
            },
        ],
    };
};

export default NewExtra;
