const ShopOrderSummary = (shop, t) => {
    const { id, name, pending, inProgress, ready } = shop;

    return {
        dataType: "shopOrders",
        id: id,
        adjust: "h-auto",
        info: [
            {
                value: name,
                link: `/services/sales/order-monitor/${id}`,
                cellContent: "link",
                specialWidth: "w-4/12 ",
            },
            {
                cellContent: "text",
                value: `${pending} ${t("orders")}`,
                specialWidth: "w-2/12",
                wrap: "truncate",
            },
            {
                cellContent: "text",
                value: `${inProgress} ${t("orders")}`,
                specialWidth: "w-2/12 ",
                wrap: "truncate",
            },
            {
                cellContent: "text",
                value: `${ready} ${t("orders")}`,
                specialWidth: "w-2/12 ",
                wrap: "truncate",
            },
            {
                value: t("monitor_"),
                link: `/services/sales/order-monitor/${id}`,
                cellContent: "link",
                specialWidth: "w-1/12 ",
                noFontBold: true,
            },
        ],
    };
};

export default ShopOrderSummary;
