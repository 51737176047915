import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import UseInputText from "../Table/useInputText";
import { changeActionValues } from "../../actions/globalActions";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import UseLoading from "../Table/useLoading";
import { getProjectLangs } from "../../hooks/Utils/SalesUtils";

const UseModalEditNameShop = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { validations } = useSelector((state) => state.action);
    const { projectLangs } = useSelector((state) => state.ui);

    const [langsArray, setLangsArray] = useState([]);
    const [langDefault, setLangDefault] = useState(null);
    const [objNames, setObjNames] = useState({});
    const [placeholder, setPlaceHolder] = useState("");
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);

    const GET_ZAFIRO_SHOP = gql`
        query {
            shops(token: "${data.token}") {
                results {
                    name: nameTranslations {
                        text
                        lang
                    }
                }
            }
        }
    `;

    const [executeQuery, { loading, data: dataNames }] = useLazyQuery(GET_ZAFIRO_SHOP);

    useEffect(() => {
        if (data?.id) {
            dispatch(changeActionValues({ "id-shop": data.id }));
            executeQuery();
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (projectLangs) {
            setLangDefault(getProjectLangs(projectLangs, t).langDefault);
            setLangsArray(getProjectLangs(projectLangs, t).langs);
        }
        // eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        if (dataNames?.shops?.results?.[0]?.name) {
            const arrNames = dataNames.shops.results[0].name;

            const objNames = arrNames.reduce((nameData, item) => {
                nameData[item.lang] = item.text.replace(/\u200b/g, "").trim();
                return nameData;
            }, {});

            setObjNames(objNames);
            setPlaceHolder(objNames[langDefault] ? objNames[langDefault] : "");
        }
        // eslint-disable-next-line
    }, [dataNames]);

    useEffect(() => {
        console.log({ validations });
    }, [validations]);

    const defaultNameKey = "default-name-shop-" + langDefault;
    const defaultTranslationKey = "name-shop-";

    return (
        <div className=" relative">
            <div>
                <div className=" mt-4"> {capitalizeFirst(t("edit-name-translations-shop"))} </div>
                <div className=" px-4">
                    {langDefault ? (
                        <div className=" mt-4 w-full pr-3 ">
                            <div className=" w-full"> {capitalizeFirst(t("default-language"))}</div>
                            <div className=" w-full flex mt-4 font-bold ">
                                <div className=" w-1/6 flex items-center"> {t("language:" + langDefault)}</div>
                                <div className=" w-5/6">
                                    <UseInputText
                                        inputData={{ name: defaultNameKey }}
                                        validation={validations?.[defaultNameKey] !== false}
                                        currentValue={objNames?.[langDefault]}
                                        id={defaultNameKey}
                                        key={defaultNameKey}
                                        onChangeAction={(val) => {
                                            setPlaceHolder(val);
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {langsArray && langsArray.length > 0 ? (
                        <div className=" w-full mt-4"> {capitalizeFirst(t("translations"))}</div>
                    ) : null}
                    {langsArray && langsArray.length > 0 ? (
                        <div
                            className=" w-full overflow-y-scroll pr-2"
                            style={{ minHeight: "0rem", maxHeight: "11rem" }}
                        >
                            {langsArray.map((lang) => {
                                return (
                                    <div className=" mt-4 w-full " key={defaultTranslationKey + lang.languageRef}>
                                        <div className=" w-full flex font-bold ">
                                            <div className=" w-1/6 flex items-center">
                                                {t("language:" + lang.languageRef)}
                                            </div>
                                            <div className=" w-5/6">
                                                <UseInputText
                                                    inputData={{ name: defaultTranslationKey + lang.languageRef }}
                                                    validation={true}
                                                    currentValue={
                                                        objNames[lang.languageRef] ? objNames[lang.languageRef] : ""
                                                    }
                                                    id={defaultTranslationKey + lang.languageRef}
                                                    key={defaultTranslationKey + lang.languageRef}
                                                    ph={placeholder}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                </div>
            </div>

            {loading ? (
                <div className=" flex items-center justify-center absolute h-full w-full  bg-white top-0 left-0">
                    <UseLoading adjust={"contents"} />
                </div>
            ) : null}
        </div>
    );
};

export default UseModalEditNameShop;
