import { useEffect, useState } from "react";
import { VALIDATE_CAST_NETWORKS } from "../services/getReportQueries";
import { useLazyQuery } from "react-apollo";

export const useLastReport = () => {
    const [lastUpdate, setLastUpdate] = useState(null);
    const [information, setInformation] = useState(null);
    const [isValid, setIsValid] = useState(null);

    const [executeValidateCastNetwork, { data: networkValidated }] = useLazyQuery(VALIDATE_CAST_NETWORKS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeValidateCastNetwork();
    }, []);

    useEffect(() => {
        if (networkValidated?.getValidateCastNetworks) {
            setLastUpdate(networkValidated?.getValidateCastNetworks?.lastUpdate);
            setInformation(networkValidated?.getValidateCastNetworks?.data?.information);
            setIsValid(networkValidated?.getValidateCastNetworks?.data?.isValid);
        }
    }, [networkValidated?.getValidateCastNetworks]);

    return { lastUpdate, information, isValid };
};
