const NewCategory = (data) => {
    return {
        title: data.level === 0 ? "add-category" : "add-subcategory",
        bodyAdjust: "w-3/12",
        executeSectionQuery: true,
        inputs: [
            {
                type: "newCategory",
                name: "new-category",
                data: data ? data : null,
                idShop: data.id ? data.id : null,
                levelCategory: data.level || data.level === 0 ? data.level : null,
                idCategory: data.idCategory ? data.idCategory : null,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "create-facet-categorie-value",
            },
        ],
    };
};

export default NewCategory;
