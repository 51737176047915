import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";
//Utils
import ListAlarms from "../../../hooks/GraphqlCalls/Customers/ListAlarms";
import UpdateAlarm from "../../../hooks/GraphqlCalls/Customers/UpdateAlarm";
import GetTVChannels from "../../../hooks/GraphqlCalls/Customers/GetTVChannels";

const Alarms = () => {
    GetTVChannels();
    ListAlarms();

    return (
        <>
            <UpdateAlarm />
            <UseSectionHeader title={["list-alarms"]} />
            <UseTable />
        </>
    );
};

export default Alarms;
