import React from "react";

/**
 * @description A loader with a spinner
 * @component
 * @param {(block|absolute|full|inline)} adjust Adjust the position of the loader (default: block)
 * @param {number} zoom Zoom the loader (default: 100)
 * @param {string} className Add custom classes to the loader
 * @param {object} style Add custom styles to the loader
 * @returns {JSX.Element} Loader with spinner
 * @example
 * <Loading adjust="full" className="bg-white" />
 */
const Loading = ({ adjust, zoom = 100, className = "", style }) => {
    let loadingClassName;
    switch (adjust) {
        case "absolute":
            loadingClassName = "flex absolute z-500 top-0 bottom-0 left-0 right-0 items-center justify-center";
            break;
        case "full":
            loadingClassName = "flex fixed z-500 top-0 bottom-0 left-0 right-0 items-center justify-center";
            break;
        case "inline":
            loadingClassName = "inline-block";
            break;
        case "block":
        default:
            loadingClassName = "flex";
    }
    return (
        <div className={`${loadingClassName} ${className}`} style={style}>
            <div
                className="lds-roller m-auto"
                style={{
                    zoom: `${zoom}%`,
                }}
            >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loading;
