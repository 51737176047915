import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseButton from "../../../useButton";
import UseTable from "../../../Table/";

//Actions
import { setActionName } from "../../../../actions/globalActions";
import { showRowForm } from "../../../../actions/sectionActions";

//Utils
import GetHotelMap from "../../../../hooks/GraphqlCalls/Hotel/GetHotelMap";
import UpdateTopology from "../../../../hooks/GraphqlCalls/Hotel/Settings/UpdateTopology";
import ListRooms from "../../../../hooks/GraphqlCalls/Hotel/Settings/ListRooms";
import ExportMap from "../../../../hooks/GraphqlCalls/Hotel/Settings/ExportMap";
import { toast } from "react-toastify";

const Topology = () => {
    //Actions
    const dispatch = useDispatch();
    const sectionName = "topology";

    //State
    const [viewMode, setViewMode] = useState("map");
    const [startMapExport, setStartMapExport] = useState(false);

    //Store
    const { langStrings } = useSelector((state) => state.ui);
    const { rowForm } = useSelector((state) => state.sectionContent);

    ListRooms();

    //Functions
    const addRoom = (e) => {
        if (rowForm) {
            toast.error(langStrings["save-before-continue"]);
            return;
        }
        dispatch(showRowForm(true));
        dispatch(setActionName("add-topology-room"));
    };

    const exportMap = (e) => {
        setStartMapExport(true);
    };

    return (
        <>
            <UpdateTopology viewMode={viewMode} />
            <ExportMap startMapExport={startMapExport} setStartMapExport={setStartMapExport}></ExportMap>
            <UseSectionHeader title={["topology"]} />
            <div className=" z-20 absolute -mt-12" style={{ right: "3.5rem" }}>
                <div className="float-left pr-2 first-capital">{langStrings["view"]}:</div>
                <div className="inline-block -mt-2 ra">
                    <UseButton
                        id={`${sectionName}-view-map`}
                        action={(e) => setViewMode("map")}
                        buttonName={"map"}
                        buttonColor={`${viewMode === "map" ? "btn-dark-blue" : "btn-white"}`}
                        adjust="w-16 float-left min-w-24 rounded-l-sm rounded-r-none"
                    />
                    <UseButton
                        id={`${sectionName}-view-table`}
                        action={(e) => setViewMode("table")}
                        buttonName={"table"}
                        buttonColor={`${viewMode === "table" ? "btn-dark-blue" : "btn-white"}`}
                        adjust="w-16 float-left min-w-24 rounded-l-none rounded-r-sm"
                    />
                </div>
            </div>

            {viewMode === "map" ? (
                <>
                    <div className="inline-flex export-btn z-20 ">
                        <div id={`${sectionName}-export-map-container`} onClick={(e) => exportMap(e)}>
                            <UseButton
                                id={`${sectionName}-export-map-button`}
                                buttonName={"export-map"}
                                buttonColor={"btn-white"}
                                adjust="w-auto px-4 min-w-40"
                            />
                        </div>
                    </div>
                    <GetHotelMap topology={true}></GetHotelMap>
                </>
            ) : (
                <div>
                    <div className="inline-flex export-btn z-20 mt-24">
                        <div onClick={(e) => exportMap(e)}>
                            <UseButton
                                buttonName={"export-map"}
                                buttonColor={"btn-white"}
                                adjust="w-auto px-4 min-w-24 mr-2 mt-1"
                            />
                        </div>
                        <div onClick={(e) => addRoom(e)}>
                            <UseButton
                                buttonName={"add-room"}
                                buttonColor={"btn-blue"}
                                adjust="w-auto px-4 min-w-24 mt-1"
                            />
                        </div>
                    </div>
                    <UseTable preventClean={true} />
                </div>
            )}
        </>
    );
};

export default Topology;
