import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { useTranslation } from "react-i18next";

import Passcode from "./models/Passcode";

import {
    setHeaders,
    setLoading,
    setSearchable,
    setAvailableFilters,
    setCheckable,
    setFolder,
    setCustomConfig,
    setBatchActive,
    setHeaderButtons,
    setTableResults,
    setCount,
    setCountPosition,
    cleanTableStore,
    cleanActiveFilters,
    setRefreshData,
    cleanSortCriteria,
} from "../../../actions/tableActions";
import { showGlobalLoading } from "../../../actions/uiActions";

import GetCustomersTableHeaders from "../../Utils/Table/Headers/GetCustomersTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { capitalizeFirst, parseBoolean, POLLINTERVAL_15S } from "../../Utils/Utils";

const ListPasscodes = () => {
    const { currentPage, perPage, sortCriteria, refreshData, folder, activeFilters } = useSelector(
        (state) => state.table
    );

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [sectionName, setSectionName] = React.useState("list-passcodes-");
    const [filterQuery, setFilterQuery] = React.useState(null);
    const [filtersReady, setFiltersReady] = React.useState(false);

    const folders = [
        { name: "active", value: true, key: "active" },
        { name: "expired", value: false, key: "active" },
    ];

    const GET_PASSCODES_AND_ACCESS_PROFILES = gql`
        query {
            wifiAccessCodes(page: ${Number(currentPage)}, size: ${Number(perPage)} 
            ${sortCriteria ? sortCriteria : ""} 
            ${filterQuery ? filterQuery : "filter: { active : true , }"}
            ) {
                info {
                    count
                    size
                    page
                }
                results {
                    ID
                    accessprofileID
                    code
                    creationDate
                    duration
                    expirationDate
                    init
                    renovation
                    usersnumber
                    maxusers
                }
            }
            wifiAccessProfiles {
                results {
                    id
                    name
                    downloadbwmax
                    downloadbwmin
                    uploadbwmin
                    uploadbwmax
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useLazyQuery(
        GET_PASSCODES_AND_ACCESS_PROFILES,
        {
            pollInterval: POLLINTERVAL_15S,
        }
    );

    useEffect(() => {
        executeQuery();

        setTimeout(() => {
            setFiltersReady(true);
        }, 1000);

        return () => {
            dispatch(cleanTableStore());
            dispatch(cleanActiveFilters());
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            dispatch(cleanSortCriteria());
            refetch();
            dispatch(setRefreshData(false));
        }
        //eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (sortCriteria === "" && data) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 800);
        }
        //eslint-disable-next-line
    }, [sortCriteria]);

    useEffect(() => {
        if (data) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 2000);
        }
        //eslint-disable-next-line
    }, [loading, sortCriteria]);

    useEffect(() => {
        if (!folder) {
            dispatch(setFolder(folders[0]));
        } else if (folder && folder.name === folders[0].name) {
            setSectionName("list-passcodes-active");
        } else if (folder && folder.name === folders[1].name) {
            setSectionName("list-passcodes-expired");
        }
        // eslint-disable-next-line
    }, [folder]);

    useEffect(() => {
        if (activeFilters && filtersReady) {
            const arrayActiveFilters = Object.keys(activeFilters);
            let filterQuery = "";
            arrayActiveFilters.forEach((obj, idx) => {
                if (idx === 0) filterQuery = filterQuery + "filter: { ";

                if ((activeFilters[obj] && activeFilters[obj] === "true") || activeFilters[obj] === "false") {
                    filterQuery = filterQuery + `${obj} : ${parseBoolean(activeFilters[obj])} ,`;
                } else if (activeFilters[obj] === false || activeFilters[obj] !== null) {
                    if (obj === "search") {
                        filterQuery = filterQuery + `${obj} : "${activeFilters[obj]}" ,`;
                    } else {
                        filterQuery = filterQuery + `${obj} : ${activeFilters[obj]} ,`;
                    }
                }
                if (idx === arrayActiveFilters.length - 1) filterQuery = filterQuery + " }";
            });
            setFilterQuery(filterQuery);
        }
        //eslint-disable-next-line
    }, [activeFilters, filtersReady]);

    const arrangeData = (data, folder) => {
        const accessCodes = [];
        const accesProfilesData = {};

        if (data.wifiAccessProfiles && data.wifiAccessProfiles.results) {
            // eslint-disable-next-line
            data.wifiAccessProfiles.results.map((item) => {
                if (item.name && item.id && !accesProfilesData[item.id]) {
                    accesProfilesData[item.id] = {
                        name: item.name,
                        dbwmax: item.downloadbwmax ? Number(item.downloadbwmax) : "0",
                        dbwmin: item.downloadbwmin ? Number(item.downloadbwmin) : "0",
                        ubwmax: item.uploadbwmax ? Number(item.uploadbwmax) : "0",
                        ubwmin: item.uploadbwmin ? Number(item.uploadbwmin) : "0",
                    };
                }
            });
        }

        if (data.wifiAccessCodes && data.wifiAccessCodes.results) {
            // eslint-disable-next-line
            data.wifiAccessCodes.results.map((itemData) => {
                accessCodes.push(Passcode(itemData, accesProfilesData, t, capitalizeFirst, sectionName));
            });
        }

        return accessCodes;
    };

    const arrangeIDsAccessProfiles = () => {
        const IDsAccessProfiles = [];
        let noRepValues = {};

        // eslint-disable-next-line
        data.wifiAccessProfiles.results.map((item) => {
            if (item.id && item.name && !noRepValues[item.id]) {
                IDsAccessProfiles.push({ id: item.id, value: item.name, visible: true });
                noRepValues[item.id] = true;
            }
        });

        return IDsAccessProfiles;
    };

    useEffect(() => {
        if (!loading && !error && data && data.wifiAccessCodes && networkStatus === 7) {
            dispatch(setHeaders(GetCustomersTableHeaders(sectionName)));
            const dataItems = arrangeData(data, folder);
            dispatch(setTableResults(dataItems));
            // //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            // dispatch(setTableType("list-alarms"));
            dispatch(setAvailableFilters(GetAvailableFilters(sectionName, [arrangeIDsAccessProfiles()])));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            dispatch(setCount(data.wifiAccessCodes.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setCheckable(true));
            dispatch(setBatchActive(BatchActions()));
            dispatch(
                setCustomConfig({
                    folders: folders,
                })
            );
            // dispatch(setCustomError(`${t("theres-not")} ${t("alarms")} ${t("available")}`));
            dispatch(setLoading(false));
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);
};

export default ListPasscodes;

const BatchActions = () => {
    const actions = [
        {
            name: "delete",
            action: "delete-passcodes",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-passcodes-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "delete", style: "red", action: "delete-passcodes" },
            ],
        },
    ];
    return actions;
};
