import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../../actions/sectionActions";
import {
    cleanTableStore,
    setAvailableFilters,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setLoadingMoreResults,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setActions,
    setHeaderButtons,
    setCustomConfig,
    setFormNewContent,
} from "../../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../../Utils/Table/Headers/GetHotelTableHeaders";
import GetAvailableFilters from "../../../Utils/GetAvailableFilters";
import GetTableHeaderButton from "../../../Utils/Table/GetTableHeaderButton";
import { useTranslation } from "react-i18next";

//Model
import TopologyRoom from "../models/TopologyRoom";
import { useMSQuery } from "../../useMSQuery";
import { POLLINTERVAL_15S } from "../../../Utils/Utils";
import _ from "lodash";

const ListRooms = () => {
    const { t } = useTranslation();
    const { filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);
    const { rowForm } = useSelector((state) => state.sectionContent);

    //States
    const [sorting] = useState(`,orderBy:{field: "number", criteria: "asc"}`);
    const RESULTS_INCREMENT = 20;
    const [page, setPage] = useState(1);

    //Data
    const sectionName = "list-topology-rooms";

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_ROOMS_INFO = gql`{
    rooms: rooms(page: ${page}, size: ${RESULTS_INCREMENT}${sortCriteria ? sortCriteria : sorting}${filterQuery}){
      info { count }
      results { floorID floorName id name number pmsNumber roomTVs { id name cec} wingID wingName}
  }}`;

    const FILTERS_INFO = gql`
        {
            rooms: rooms {
                results {
                    wingID
                    floorID
                    floorName
                    wingName
                }
            }
            locations: locations {
                results
            }
            floors: installationMap {
                results {
                    id
                    name
                    floors {
                        id
                        name
                    }
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_ROOMS_INFO, {
        pollInterval: POLLINTERVAL_15S,
    });
    const [executeFilterQuery, filtersData] = useLazyQuery(FILTERS_INFO, {
        pollInterval: 60000,
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        executeQuery();
        executeFilterQuery();
        return () => {
            dispatch(setCustomConfig(null));
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            executeFilterQuery();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (rowForm) {
            return;
        }
        if (
            !loading &&
            !error &&
            data &&
            data.rooms &&
            filtersData.data &&
            filtersData.data.rooms &&
            networkStatus === 7
        ) {
            //Get data
            const dataItems = arrangeData(data);
            const tableHeaders = GetTableHeaders(sectionName);
            const availableFilters = GetAvailableFilters(sectionName, {
                wings: extractWingList(filtersData.data.rooms),
                floors: extractFloorList(filtersData.data.rooms),
                locations: extractLocationList(filtersData.data.locations),
            });
            //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            dispatch(
                setCustomConfig({
                    pagination: false,
                    tableContainerAdjust: "min-h-0 max-h-70",
                    rowsContainerAdjust: "overflow-scroll max-h-40",
                    onScrollCallback: function (e) {
                        const elem = document.getElementById("rows");
                        if (elem.getAttribute("listener") !== "true") {
                            elem.addEventListener("wheel", checkScrollDirection);
                            elem.setAttribute("listener", "true");

                            function checkScrollDirection(event) {
                                if (checkScrollDirectionIsUp(event)) {
                                    document.getElementById("rows").setAttribute("wheelDirection", "up");
                                } else {
                                    document.getElementById("rows").setAttribute("wheelDirection", "down");
                                }
                            }
                            function checkScrollDirectionIsUp(event) {
                                if (event.wheelDelta) {
                                    return event.wheelDelta > 0;
                                }
                                return event.deltaY < 0;
                            }
                        }
                        if (
                            e.target.scrollTop + e.target.offsetHeight > e.target.scrollHeight &&
                            page < data.rooms.info.count / 20 &&
                            document.getElementById("rows").getAttribute("wheelDirection") === "down"
                        ) {
                            setPage(page + 1);
                            document.getElementById("rows").scrollTop = 10;
                            if (!loading) {
                                dispatch(setLoadingMoreResults(true));
                            }
                        } else if (
                            e.target.scrollTop === 0 &&
                            page > 1 &&
                            document.getElementById("rows").getAttribute("wheelDirection") === "up"
                        ) {
                            setPage(page - 1);
                            document.getElementById("rows").scrollTo(0, e.target.scrollHeight - e.target.offsetHeight);
                            if (!loading) {
                                dispatch(setLoadingMoreResults(true));
                            }
                        }
                    },
                })
            );
            dispatch(setHeaders(tableHeaders));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.rooms.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setExtendedInfo(false));
            dispatch(showRowForm(false));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            if (dataItems && dataItems[0] && dataItems[0].actions) {
                dispatch(setActions());
            }
            dispatch(setCustomError(t("no rooms available")));

            dispatch(
                setFormNewContent({
                    data: {
                        wings: extractWingList(filtersData.data.rooms),
                        floors: extractFloorList(filtersData.data.rooms),
                        rawFloors: filtersData.data.floors,
                        locations: filtersData.data.locations,
                    },
                })
            );
            dispatch(setLoading(false));
        }

        // eslint-disable-next-line
    }, [data, filtersData.data, networkStatus]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        if (!loading) {
            dispatch(setLoadingMoreResults(false));
        }
        // eslint-disable-next-line
    }, [loading]);
};

const arrangeData = (data) => {
    const roomsData = [];
    // eslint-disable-next-line
    data.rooms.results.map((room) => {
        roomsData.push(TopologyRoom(room));
    });
    return roomsData;
};

const extractWingList = (data) => {
    let wingArray = [""];
    data.results.map((room) =>
        wingArray.filter((r) => r[1] === room.wingID).length === 0
            ? wingArray.push([room.wingName, room.wingID, "int"])
            : null
    );

    wingArray.sort(function (a, b) {
        return a[0] < b[0];
    });

    return wingArray;
};

const extractFloorList = (data) => {
    let floorsArray = [""];
    data.results.map((room) =>
        floorsArray.filter((r) => r.id === room.floorID).length === 0
            ? floorsArray.push({
                  id: room.floorID,
                  value: room.floorName,
                  visible: true,
                  optGroup: room.wingName,
              })
            : null
    );
    floorsArray = _.orderBy(floorsArray, ['optGroup','value'], ['asc','asc']);
    return floorsArray;
};

const extractLocationList = (data) => {
    let locationsArray = [""];
    data.results.map((location) =>
        locationsArray.filter((r) => r[1] === location).length === 0
            ? locationsArray.push([location, location, "str"])
            : null
    );

    locationsArray.sort(function (a, b) {
        return a[0] < b[0];
    });

    return locationsArray;
};

export default ListRooms;
