import React, { useEffect, useState } from "react";
import UseButton from "../../../useButton";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    openModal,
    setModalContent,
    showGlobalLoading as setShowGlobalLoading,
    closeModal,
} from "../../../../actions/uiActions";
import { toast } from "react-toastify";
import { gql } from "apollo-boost";
import { Session } from "hooks/Utils/Session";
import { setActionValues } from "actions/globalActions";
import { setRefreshContentData } from "actions/sectionActions";

export const MobileSettingsAddMobileCard = () => {
    const projectRef = Session.getSessionProp("projectRef");
    const { host } = window.location;
    const { values, validations } = useSelector((state) => state.action);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [change, setChange] = useState(false);
    const [mobileAppDomain, setMobileAppDomain] = useState("testing.zafiro.link");

    const GET_MOBILE_APP_DOMAIN = gql(`query {
        getSystemConfigs{
          mobileAppDomain
        }
      }`);

    const [executeQuery, { data }] = useLazyQuery(GET_MOBILE_APP_DOMAIN);

    useEffect(() => {
        if (executeQuery) {
            executeQuery();
        }
    }, []);

    useEffect(() => {
        if (data?.getSystemConfigs?.mobileAppDomain) {
            setMobileAppDomain(data.getSystemConfigs.mobileAppDomain);
        }
    }, [data]);

    const ParseURL = () => {
        let appName = values["app-name"] || "";
        return `${appName.trim().replace(/\s+/g, "_").toLowerCase()}.${mobileAppDomain}`;
    };

    useEffect(() => {
        if (change && validations["app-name"]) {
            dispatch(setShowGlobalLoading(true));
            setChange(false);
            addMobileApp();
        }
    }, [validations]);

    const MUTATION = gql`
        mutation {
            createMobileApp(name: "${values["app-name"]}", 
            shortName:"${values["app-name"]}", 
            url:"${ParseURL()}", 
            projectRefs:"${projectRef}") {
                error
                id
                ok
            }
        }
    `;

    const [addMobileApp] = useMutation(MUTATION, {
        onError() {
            dispatch(setShowGlobalLoading(false));
            toast.error(t("mutation-error"));
        },
        onCompleted() {
            dispatch(closeModal());
            dispatch(setShowGlobalLoading(false));
            dispatch(setRefreshContentData(true));
        },
    });

    function openModalAddMobileApp() {
        dispatch(
            setModalContent({
                title: "add-mobile-app-title-modal",
                bodyAdjust: "w-2/5 text-justify max-h-80 overflow-y-scroll",
                inputs: [
                    {
                        type: "addMobileApp",
                    },
                ],
                buttons: [
                    { name: "cancel", style: "white", action: "close" },
                    {
                        name: "add",
                        style: "blue",
                        action: "add-mobile-app",
                        actionCallback: () => {
                            setChange(true);
                        },
                    },
                ],
            })
        );
        dispatch(setActionValues({}));
        dispatch(openModal());
    }

    return (
        <div className="py-4 flex flex-col items-center bg-white rounded-md">
            <h1 className="pb-2 font-bold text-3xl mt-3">{t("add-mobile-app-title")}</h1>
            <span className=" pb-6">{t("add-mobile-app-description")}</span>
            <UseButton
                buttonColor="btn-blue"
                buttonName={"add-app"}
                action={() => {
                    openModalAddMobileApp();
                }}
            ></UseButton>
        </div>
    );
};
