import React, { useEffect, useState } from "react";
import UseSectionHeader from "../../useSectionHeader";
import MobileSettingsCards from "./MobileSettings/MobileSettingsCards";
import { MobileSettingsAddMobileCard } from "./MobileSettings/MobileSettingsAddMobileAppCard";
import { useSelector, useDispatch } from "react-redux";
import { gql } from "apollo-boost";
import { Session } from "hooks/Utils/Session";
import { POLLINTERVAL_15S } from "hooks/Utils/Utils";
// import { getMobileAppQuery } from "./MobileSettings/MobileSettingsUtils";
import { useLazyQuery } from "react-apollo";
import { getMobileAppQuery } from "./MobileSettings/MobileSettingsUtils";
import { setRefreshContentData } from "actions/sectionActions";
import { showGlobalLoading } from "actions/uiActions";

const MobileSettings = () => {
    const projectRef = Session.getSessionProp("projectRef");
    const [projectHasAMobileApp, setProjectHasAMobileApp] = useState(null);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const [loading, setLoading] = useState(true);
    const [cardInEdition, setCardInEdition] = useState(null);
    const dispatch = useDispatch();

    const GET_DATA_MOBILE_APP = gql(getMobileAppQuery(projectRef));

    const [executeQuery, { data, error, refetch }] = useLazyQuery(GET_DATA_MOBILE_APP, {
        fetchPolicy: "no-cache",
    });

    useEffect(() => {
        const intervalId = setInterval(() => {
            refetch();
        }, POLLINTERVAL_15S);

        return () => clearInterval(intervalId);
    }, [refetch]);

    useEffect(() => {
        if (executeQuery) {
            executeQuery();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            refetch();
            dispatch(setRefreshContentData(false));
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data?.mobileApps?.results?.length > 0) {
            setProjectHasAMobileApp(true);
            setLoading(false);
        } else if (data?.mobileApps?.results.length <= 0) {
            setProjectHasAMobileApp(false);
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [data]);

    const renderContent = () => {
        if (loading || error) {
            return null;
        } else if (!loading && projectHasAMobileApp === true) {
            return (
                <MobileSettingsCards
                    data={data?.mobileApps?.results?.[0]}
                    cardInEdition={cardInEdition}
                    setCardInEdition={setCardInEdition}
                />
            );
        } else if (!loading && projectHasAMobileApp === false) {
            return <MobileSettingsAddMobileCard />;
        }
    };

    return (
        <>
            <UseSectionHeader title={["mobile-settings"]} />
            {renderContent()}
        </>
    );
};

export default MobileSettings;
