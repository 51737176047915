export const GetCustomersTableHeaders = (section, props) => {
    let res = [];
    //Sort icon
    const tableSortIcon = { name: "table-sort" };

    //Special width classes
    const w0512 = "w-0.5/12";
    const w112 = "w-1/12";
    const w212 = "w-2/12";
    const w312 = "w-3/12";
    const w412 = "w-4/12";
    const w512 = "w-5/12";
    const w612 = "w-6/12";
    const w712 = "w-7/12";

    //Header types
    let number = { name: "number" };
    let title = { name: "title" };
    let name = { name: "name" };
    let age = { name: "age" };
    let surname = { name: "surname" };
    let email = { name: "email" };
    let documentation = { name: "documentation" };
    // let country = { name: "country" };
    let guest = { name: "guest" };
    let type = { name: "type" };
    let hour = { name: "hour" };
    let lastUpdate = { name: "last-update" };
    let startTime = { name: "date", sortName: "startTime" };
    let accesses = { name: "accesses-text", sortName: "accesses" };
    let language = { name: "lang", sortName: "language" };
    // let signupDate = { name: "signupDate" };
    // let lastAccess = { name: "last-access", sortName: "lastAccess" };
    let roomAmount = { name: "roomAmount" };
    let roomPopulation = { name: "roomPopulation" };
    let checkInOut = { name: "checkInOut" };
    let bookingStatus = { name: "bookingStatus" };
    let group = { name: "group", sortName: "guestGroups" };
    let roomNumber = { name: "room-number-short", sortName: "rooms" };
    let rooms = { name: "rooms" };
    let stayDate = { name: "stay-date", sortName: "staydate" };
    let area = { name: "area-sym-activity" };
    let dateAndTime = { name: "date-and-time" };
    let peopleNumber = { name: "people-number" };
    let status = { name: "status" };
    let groupName = { name: "group-name", sortName: "name" };
    let numberOfGuest = { name: "number-of-guests", sortName: "guestNumber" };
    let subject = { name: "subject", sortName: "title" };
    let content = { name: "content" };
    let recipients = { name: "recipients" };
    let priority = { name: "type", sortName: "priority" };
    let sentDate = { name: "sent date", sortName: "sentTime" };
    let deleteDate = { name: "deleted date", sortName: "deletedTime" };
    let templateDate = { name: "date", sortName: "createdTime" };
    let passcode = { name: "passcode", sortName: "code" };
    let accessProfile = { name: "access-profile", sortName: "access_profile_id" };
    let limitOfDevices = { name: "limit-of-devices", sortName: "max_users" };
    let duration = { name: "duration", sortName: "duration" };
    let state = { name: "state", sortName: "init" };

    let guestManagement = { name: "guest-management", hidden: true };
    let signUp = { name: "signUp" };
    let access = { name: "accesses-text", sortName: "logins.app" };
    let communications = { name: "communications", sortName: "marketing_accepted" };
    let verification = { name: "verification", sortName: "email_verified" };
    let emptyCell = { name: "" };
    let code = { name: "code", sortName: "code" };
    let booking = { name: "booking", sortName: "booking" };

    switch (section) {
        case "list-customers":
            email.specialWidth = w312;
            name.specialWidth = w312;
            surname.specialWidth = w212;
            signUp.specialWidth = w312;
            access.specialWidth = w512;
            communications.specialWidth = w212;
            verification.specialWidth = w212;
            emptyCell.specialWidth = w112;
            email.icon = tableSortIcon;
            name.icon = tableSortIcon;
            surname.icon = tableSortIcon;
            access.icon = tableSortIcon;
            communications.icon = tableSortIcon;
            verification.icon = tableSortIcon;
            if (props && props.advancedFilters) {
                res.push(email, access, communications, verification);
            } else {
                res.push(email, name, surname, signUp, emptyCell);
            }
            break;
        case "list-guests-upcoming-check-ins":
            code.specialWidth = w212;
            email.specialWidth = w312;
            name.specialWidth = "w-1.5/12";
            booking.specialWidth = "w-1.5/12";
            stayDate.specialWidth = w212;
            status.specialWidth = w212;
            code.icon = tableSortIcon;
            email.icon = tableSortIcon;
            name.icon = tableSortIcon;
            booking.icon = tableSortIcon;
            stayDate.icon = tableSortIcon;
            status.icon = tableSortIcon;
            email.sortName = "guests.email";
            name.sortName = "guests.name";
            stayDate.sortName = "arrival_date";
            status.sortName = "state";
            booking.sortName = "number_of_guests";
            code.name = "booking-code";

            res.push(code, email, name, booking, stayDate, status);
            break;
        case "list-guests-currents":
            name.specialWidth = w312;
            email.specialWidth = w312;
            group.specialWidth = "w-2/12";
            roomNumber.specialWidth = "w-1.5/12";
            stayDate.specialWidth = "w-1.5/12";
            guestManagement.specialWidth = w112;
            name.icon = tableSortIcon;
            email.icon = tableSortIcon;
            group.icon = tableSortIcon;
            roomNumber.icon = tableSortIcon;
            stayDate.icon = tableSortIcon;

            res.push(name, email, group, roomNumber, stayDate, guestManagement);
            break;
        case "list-companions":
            title.specialWidth = `${w112} ml-8 `;
            name.specialWidth = props?.precheckinAvailable ? w212 : w312;
            surname.specialWidth = props?.precheckinAvailable ? w212 : w312;
            email.specialWidth = props?.precheckinAvailable ? w212 : w312;
            roomNumber.specialWidth = w112;
            age.specialWidth = w112;
            documentation.specialWidth = `${w312} `;
            res.push(title, name, surname, email, roomNumber, age);

            if (props?.precheckinAvailable) {
                res.push(documentation);
            }
            break;
        case "list-bookings":
            area.specialWidth = w312;
            dateAndTime.specialWidth = w312;
            peopleNumber.specialWidth = w212;
            status.specialWidth = w212;
            emptyCell.specialWidth = w212;

            res.push(area, dateAndTime, peopleNumber, status, emptyCell);
            break;
        case "guest-groups":
            groupName.specialWidth = w412;
            numberOfGuest.specialWidth = w612;

            groupName.icon = tableSortIcon;
            numberOfGuest.icon = tableSortIcon;

            res.push(groupName, numberOfGuest, guestManagement, { specialWidth: w112 }, { specialWidth: w112 });
            break;
        case "booking-list":
            number.specialWidth = w112;
            email.specialWidth = w312;
            name.specialWidth = w112;
            surname.specialWidth = w212;
            roomAmount.specialWidth = w112;
            roomPopulation.specialWidth = w112;
            checkInOut.specialWidth = w212;
            bookingStatus.specialWidth = w112;

            number.icon = tableSortIcon;
            email.icon = tableSortIcon;
            name.icon = tableSortIcon;
            surname.icon = tableSortIcon;
            roomAmount.icon = tableSortIcon;
            roomPopulation.icon = tableSortIcon;
            checkInOut.icon = tableSortIcon;

            res.push(number, email, name, surname, roomAmount, roomPopulation, checkInOut, bookingStatus);
            break;
        case "list-digital-keys":
            guest.specialWidth = w212;
            type.specialWidth = w212;
            roomNumber.specialWidth = w212;
            accesses.specialWidth = w412;
            res.push(guest, type, roomNumber, accesses);
            break;
        case "list-messages-drafts":
            subject.specialWidth = w312;
            recipients.specialWidth = w712;
            priority.specialWidth = w212;

            subject.icon = tableSortIcon;
            priority.icon = tableSortIcon;

            res.push(subject, recipients, priority);
            break;
        case "list-messages-templates":
            subject.specialWidth = w312;
            content.specialWidth = w612;
            templateDate.specialWidth = w212;

            subject.icon = tableSortIcon;
            templateDate.icon = tableSortIcon;

            res.push(subject, content, templateDate);
            break;
        case "list-messages-bin":
            subject.specialWidth = w312;
            recipients.specialWidth = w312;
            priority.specialWidth = w212;
            deleteDate.specialWidth = w412;

            subject.icon = tableSortIcon;
            priority.icon = tableSortIcon;
            deleteDate.icon = tableSortIcon;
            status.icon = tableSortIcon;

            res.push(subject, recipients, priority, deleteDate);
            break;
        case "list-messages-sent":
            subject.specialWidth = w312;
            recipients.specialWidth = w312;
            priority.specialWidth = w212;
            sentDate.specialWidth = w212;
            emptyCell.specialWidth = w212;

            subject.icon = tableSortIcon;
            priority.icon = tableSortIcon;
            sentDate.icon = tableSortIcon;

            res.push(subject, recipients, priority, sentDate, emptyCell);
            break;
        case "list-alarms-":
        case "list-alarms-active":
        case "list-alarms-expired":
            //widths
            guest.specialWidth = w312;
            rooms.specialWidth = w412;
            hour.specialWidth = w112;
            startTime.specialWidth = w112;
            lastUpdate.specialWidth = w212;
            emptyCell.specialWidth = w112;
            //icons
            guest.icon = tableSortIcon;
            // hour.icon = tableSortIcon;
            startTime.icon = tableSortIcon;
            lastUpdate.icon = tableSortIcon;
            //cell add
            res.push(guest, rooms, hour, startTime, lastUpdate, emptyCell);
            break;
        case "list-passcodes-":
        case "list-passcodes-active":
        case "list-passcodes-expired":
            const pActive = section === "list-passcodes-active";
            passcode.icon = tableSortIcon;
            accessProfile.icon = tableSortIcon;
            limitOfDevices.icon = tableSortIcon;
            duration.icon = tableSortIcon;
            state.icon = pActive ? tableSortIcon : "";
            passcode.specialWidth = pActive ? w212 : w312;
            accessProfile.specialWidth = w312;
            limitOfDevices.specialWidth = pActive ? w212 : w312;
            duration.specialWidth = w212;
            state.specialWidth = pActive ? w212 : "";
            emptyCell.specialWidth = w112;
            res.push(passcode, accessProfile, limitOfDevices, duration, pActive ? state : "", emptyCell);
            break;
        default:
            res = [""];
    }
    return res;
};

export default GetCustomersTableHeaders;
