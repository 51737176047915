import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

//Components
import UseButton from "../../../../../useButton";
import UseRadio from "../../../../../useRadio";
import EmptyWidget from "./EmptyWidget";
import Widget from "../../Widget";
//Actions
import { openModal, setModalContent } from "../../../../../../actions/uiActions";
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import { changeGlobalAction } from "../../../../../../actions/globalActions";

//Utils
import { Session } from "../../../../../../hooks/Utils/Session";
import { capitalizeFirst, translate } from "../../../../../../hooks/Utils/Utils";
import BrowserMediaLibraryModal from "../../../../../../hooks/GraphqlCalls/MediaLibrary/modals/BrowserMediaLibraryModal";
import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";
import { SCREEN, DEVICE } from "../../../../../../hooks/Utils/Design/EditorUtils";
import ReactTooltip from "components/ReactTooltip";
import LogoAssetName from "../../../../../../hooks/GraphqlCalls/Appearances/LogoAssetName";
import { useTranslation } from "react-i18next";

const AddImage = ({ isBackground, isCarousel, multipleImages, deviceSpecific }) => {
    const { t } = useTranslation();

    const { deviceType } = useContext(EditorContext);
    const { id } = useContext(WidgetContext);

    const widgetID = isBackground ? "bg" : id;

    const { lang, type: screenType } = useParams();

    const dispatch = useDispatch();

    //Store
    const { langStrings } = useSelector((state) => state.ui);
    const { cScreenData, gridItems, previewMenuIdSelected } = useSelector((state) => state.design);
    const { appearanceSelected } = useSelector((state) => state.sectionContent);
    const isGeneralTV = screenType === SCREEN.TYPE.GENERAL && deviceType === DEVICE.TYPE.TV ? true : false;
    const isGeneralMobile = screenType === SCREEN.TYPE.GENERAL && deviceType === DEVICE.TYPE.MOBILE ? true : false;

    //States
    const [imageInputSelected, setImageInputSelected] = useState("libraryImage");
    const [libraryRef, setLibraryRef] = useState(null);
    const [libraryRefUrl, setLibraryRefUrl] = useState(null);
    const [externalUrl, setExternalUrl] = useState(null);
    const [isValidUrlImage, setIsValidUrlImage] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isUsingHomeBg, setIsUsingHomeBg] = useState(null);

    LogoAssetName(libraryRef, true);

    //Listeners
    useEffect(() => {
        const url = getImageUrl();
        if (!libraryRefUrl && !isCarousel) {
            const input = document.querySelector(`#input_${widgetID}`);
            if (input) {
                input.value = url;
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        getImageUrl();
        // eslint-disable-next-line
    }, [cScreenData, gridItems]);

    useEffect(() => {
        if (cScreenData && cScreenData.contentStyle) {
            if (cScreenData.contentStyle.useHomeBackground) {
                setIsUsingHomeBg(true);
            } else {
                setIsUsingHomeBg(false);
            }
        }
        // eslint-disable-next-line
    }, [cScreenData, previewMenuIdSelected]);

    useEffect(() => {
        if (screenType !== SCREEN.TYPE.PORTAL) {
            const selectorInputWidget = document.querySelector(`#input_${widgetID}`);

            if (selectorInputWidget) {
                document.querySelector(`#input_${widgetID}`).value = externalUrl || null;
                const checkUrl = validateImageUrl(externalUrl);
                setIsValidUrlImage(checkUrl);
            }
        }
        // eslint-disable-next-line
    }, [externalUrl, isUsingHomeBg]);

    const getImageUrl = () => {
        let url = null;
        let isLibrary = false;
        const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
        const style = Widget.ParseStyle(widget ? widget.widgetStyle : null, deviceType);
        const data = Widget.ParseData(widget ? widget.data : null, deviceType);

        if (isBackground) {
            if (
                cScreenData &&
                cScreenData.contentStyle &&
                cScreenData.contentStyle.images &&
                cScreenData.contentStyle.images[0]
            ) {
                const image = cScreenData.contentStyle.images[0];

                if (image.externalUrl) {
                    url = image.externalUrl;
                } else if (image.libraryRef) {
                    const libraryRef = image.libraryRef;
                    url = Session.getDasUrl(`${libraryRef}?lang=${lang}`);
                    isLibrary = true;
                }
            }
            setLibraryRef(
                cScreenData &&
                    cScreenData.contentStyle &&
                    cScreenData.contentStyle.images &&
                    cScreenData.contentStyle.images[0] &&
                    cScreenData.contentStyle.images[0].libraryRef
                    ? cScreenData.contentStyle.images[0].libraryRef
                    : null
            );
        } else if (style && style.images && style.images[0]) {
            if (style.images[0].libraryRef) {
                url = Session.getDasUrl(style.images[0].libraryRef + "?lang=" + lang);
                setLibraryRef(style.images[0].libraryRef);
                isLibrary = true;
            } else {
                url = style.images[0].externalUrl || null;
            }
        } else {
            if (data) {
                if (data.libraryRef) {
                    url = Session.getDasUrl(data.libraryRef + "?lang=" + lang);
                    setLibraryRef(data.libraryRef);
                    isLibrary = true;
                } else {
                    url = data.externalUrl || null;
                }
            }
        }
        if (url) {
            if (isLibrary) {
                setLibraryRefUrl(url);
                setExternalUrl(null);
                setImageInputSelected("libraryImage");
            } else {
                setExternalUrl(url);
                setLibraryRefUrl(null);
                setImageInputSelected("externalImage");
            }
        } else {
            setImageInputSelected("libraryImage");
            setExternalUrl(null);
            setLibraryRefUrl(null);
        }

        return url;
    };

    const updateImageUrl = () => {
        const val = document.querySelector(`#input_${widgetID}`).value;
        const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;

        if (isBackground) {
            const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
            contentStyle.images = [{ externalUrl: val }];
            dispatch(
                setExecuteQuery({
                    action: "updateContentStyle",
                    params: {
                        contentStyle: contentStyle,
                    },
                })
            );
        } else if (multipleImages) {
            let newData = [];
            newData = [{ externalUrl: val }];
            dispatch(
                setExecuteQuery(
                    Widget.UpdateStyle({
                        widget,
                        property: "images",
                        value: newData,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                    })
                )
            );
        } else if (isCarousel) {
            let newData = [];
            if (widget && widget.data && widget.data.images) newData = widget.data.images;
            newData.push({ externalUrl: val, libraryRef: null });
            dispatch(setExecuteQuery(Widget.UpdateData({ widget, property: "images", value: newData })));
            document.querySelector(`#input_${widgetID}`).value = "";
        } else {
            let newData = {};
            if (widget && widget.data) newData = widget.data;
            newData.externalUrl = val;
            newData.libraryRef = null;
            dispatch(setExecuteQuery(Widget.UpdateData({ widget, value: newData })));
        }
    };
    const deleteImage = () => {
        const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
        if (isBackground) {
            const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
            contentStyle.images = [{ externalUrl: null }];
            dispatch(
                setExecuteQuery({
                    action: "updateContentStyle",
                    params: {
                        contentStyle: contentStyle,
                    },
                })
            );
        } else if (multipleImages) {
            dispatch(
                setExecuteQuery(
                    Widget.UpdateStyle({
                        widget,
                        property: "images",
                        value: null,
                        deviceSpecific: deviceSpecific ? deviceType : null,
                    })
                )
            );
        } else {
            let newData = widget.data || {};

            newData.externalUrl = null;
            newData.libraryRef = null;

            dispatch(setExecuteQuery(Widget.UpdateData({ widget, value: newData })));
        }
    };

    const openModalLibrary = () => {
        const modal = BrowserMediaLibraryModal(t, {
            title: "choose-an-image",
            text: "images-of-the-media-library",
            bodyAdjust: "w-10/12",
            textAdjust: "",
            executeSectionQuery: true,
            buttonSelectedAssetName: isCarousel ? "choose-images" : "choose-an-image",
            dataMediaLibrary: {
                selected_ref: null,
                select_multiple: isCarousel,
                type: "asset",
                lang: lang,
                add_asset: {
                    active: true,
                    return_added: false,
                },
            },
        });
        dispatch(
            changeGlobalAction({
                actionName: "browser-media-library",
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const validateImageUrl = (url) => {
        const regex = /(http(s?):\/\/)([^\s(["<,>/]*)(\/)[^\s[",><]*(.png|.jpg|.jpeg|.gif|.webp)(\?[^\s[",><]*)?/g;
        const isAValidUrl = regex.test(url);
        return isAValidUrl;
    };

    const [tooltipAsset, settooltipAsset] = useState("");
    useEffect(() => {
        if (appearanceSelected && appearanceSelected.assetName) {
            settooltipAsset(appearanceSelected.assetName);
        }
    }, [appearanceSelected]);

    const deleteBinPlusInfo = () => {
        return (
            <div className={`z-201 absolute h-10 w-full rounded-b-md`} style={{ marginTop: "-35px" }}>
                <div
                    className="text-1xl grid h-full  grid-flow-col grid-cols-3 items-center  rounded-b-md font-black text-white"
                    style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
                >
                    <div className="ml-2"></div>
                    <div className=" items-center text-center align-middle">
                        <span className="icon icon-delete-editor cursor-pointer " onClick={(e) => deleteImage()}></span>
                    </div>
                    <div className={`mr-2 items-end text-right ${externalUrl ? "opacity-0" : "opacity-1"} `}>
                        <span
                            className="icon icon-info"
                            data-tip={externalUrl ? "" : tooltipAsset}
                            onMouseOver={ReactTooltip.rebuild()}
                        ></span>
                        <ReactTooltip
                            place="bottom"
                            type="light"
                            offset={{ top: -8, left: -8 }}
                            html={true}
                            border={true}
                            multiline={true}
                            borderColor="#D3DAE1"
                        />
                    </div>
                </div>
            </div>
        );
    };

    const renderImageSection = () => {
        if (!externalUrl && !libraryRefUrl) {
            return (
                <div className="mb-8 h-32">
                    <EmptyWidget type="IMAGE"></EmptyWidget>
                </div>
            );
        } else {
            return (
                <div className="relative mb-4 bg-gray-200">
                    <img
                        alt=""
                        className="h-36 w-full object-contain"
                        style={{ display: `${imageLoaded ? "block" : "none"}` }}
                        src={externalUrl || libraryRefUrl}
                        onError={() => {
                            setImageLoaded(false);
                            setIsValidUrlImage(false);
                        }}
                        onLoad={() => {
                            setImageLoaded(true);
                        }}
                    ></img>
                    {!imageLoaded ? (
                        <div className="h-36 mb-8">
                            <EmptyWidget type="IMAGE"></EmptyWidget>
                        </div>
                    ) : (
                        deleteBinPlusInfo()
                    )}
                </div>
            );
        }
    };

    const updateUseHomeBackground = (value) => {
        const contentStyle = JSON.parse(JSON.stringify(cScreenData.contentStyle));
        contentStyle.useHomeBackground = value;
        setIsUsingHomeBg(value);
        dispatch(
            setExecuteQuery({
                action: "updateContentStyle",
                params: {
                    contentStyle: contentStyle,
                },
            })
        );
    };

    return (
        <>
            <div className="border-b border-gray-200 p-8 " id="add-image-container">
                {isGeneralTV && isBackground ? (
                    <>
                        <div className="first-capital mb-6 font-bold">{translate("background-image", langStrings)}</div>

                        <div
                            className={`first-capital mt-6 flex w-full cursor-pointer ${isUsingHomeBg ? "" : " mb-6"}`}
                        >
                            <div className=" first-capital inline-block  w-11/12 text-gray-900 ">
                                {capitalizeFirst(translate("use-home-background", langStrings))}
                            </div>
                            <div className=" w-1/12 ">
                                <label className="switch cursor-pointer ">
                                    <input
                                        type="checkbox"
                                        checked={isUsingHomeBg}
                                        onChange={() => updateUseHomeBackground(!isUsingHomeBg)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className="first-capital mb-8 font-bold">
                        {translate(isBackground ? "background-image" : "add-image", langStrings)}
                    </div>
                )}

                {(!isCarousel && isBackground !== true) ||
                (isBackground === true && isUsingHomeBg === false) ||
                (isBackground === true && isUsingHomeBg === true && isGeneralMobile)
                    ? renderImageSection()
                    : null}

                {(isUsingHomeBg !== true && isBackground) ||
                !isBackground ||
                (isUsingHomeBg === true && isBackground && isGeneralMobile) ? (
                    <div className="pb-6">
                        {screenType !== SCREEN.TYPE.PORTAL || isUsingHomeBg !== true ? (
                            <div
                                className="first-capital mb-4 cursor-pointer"
                                onClick={(e) => setImageInputSelected("libraryImage")}
                            >
                                <UseRadio
                                    selected={imageInputSelected}
                                    value={"libraryImage"}
                                    id={"input-radio-add-image"}
                                />
                                <div className="ml-8">{translate("add", langStrings)}:</div>
                            </div>
                        ) : null}
                        <UseButton
                            id={isCarousel ? "choose-images" : "choose-an-image"}
                            buttonName={translate(isCarousel ? "choose-images" : "choose-an-image", langStrings)}
                            disabled={imageInputSelected !== "libraryImage"}
                            buttonColor={imageInputSelected === "libraryImage" ? "btn-white" : "btn-disabled"}
                            customClass={"w-full"}
                            action={(e) => openModalLibrary()}
                        />
                    </div>
                ) : null}
                {screenType !== SCREEN.TYPE.PORTAL &&
                ((isUsingHomeBg !== true && isBackground) ||
                    !isBackground ||
                    (isUsingHomeBg === true && isBackground && isGeneralMobile)) ? (
                    <div className="table w-full">
                        <div
                            className="first-capital mb-4  cursor-pointer"
                            onClick={(e) => setImageInputSelected("externalImage")}
                        >
                            <UseRadio
                                selected={imageInputSelected}
                                value={"externalImage"}
                                id={"input-radio-external-url"}
                            />
                            <div className="ml-8">
                                {translate("link", langStrings)} (URL - {translate("https-only", langStrings)}):
                            </div>
                        </div>
                        <input
                            type="text"
                            id={`input_${widgetID}`}
                            onChange={(e) => {
                                const checkUrl = validateImageUrl(e.target.value);
                                setIsValidUrlImage(checkUrl);
                            }}
                            disabled={imageInputSelected === "externalImage" ? null : "disabled"}
                            className={`rounded mb-4 w-full bg-gray-200 px-4 py-2 ${
                                imageInputSelected !== "externalImage" ? "text-gray-600" : ""
                            }`}
                        />
                        <UseButton
                            id={`${externalUrl ? "update" : "insert"}-image-button`}
                            buttonName={externalUrl ? "update" : "insert"}
                            disabled={!isValidUrlImage || imageInputSelected !== "externalImage"}
                            buttonColor={imageInputSelected === "externalImage" ? "btn-blue" : "btn-disabled"}
                            adjust="inline-flex float-right"
                            action={updateImageUrl}
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};

export default AddImage;
