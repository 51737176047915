import { useState, useEffect, useContext } from "react";
import { useLazyQuery } from "react-apollo";
import { GET_GUEST_NETWORK_INFO } from "../services/getMonitorInfo";
import { NetworkAlertsContext } from "contexts/NetworkWarnings/NetworkAlerts";
import { useTranslation } from "react-i18next";

export const useNetworkInfo = () => {
    const { t } = useTranslation();
    const [networkInfo, setNetworkInfo] = useState();
    const { setNetworkCriticalWarning, setNetworkLowWarning } = useContext(NetworkAlertsContext);

    const [executeGetInfoQuery, { data, called, loading, networkStatus, refetch }] = useLazyQuery(
        GET_GUEST_NETWORK_INFO,
        {
            pollInterval: 200000,
        }
    );

    useEffect(() => {
        executeGetInfoQuery();
    }, []);

    useEffect(() => {
        if (!loading && networkStatus === 8) {
            setNetworkInfo((prevNetworkInfo) => prevNetworkInfo);
        }
    }, [loading, networkStatus]);

    useEffect(() => {
        const info = data?.getGuestNetworkInfo;
        if (!loading && info && networkStatus === 7) {
            setNetworkInfo(networkModel(info, t));
            setNetworkCriticalWarning(
                !info?.data?.mdnsData?.isMdnsActive ||
                    !info?.data?.mdnsData?.isConnectivityActive ||
                    info?.data?.anomalousTrafficData?.isRoutingToCastNetwork
            );
            setNetworkLowWarning(info?.data?.anomalousTrafficData?.isTrafficFromOtherNetwork);
        }
    }, [data]);

    return { networkInfo, called, loading, refetch };
};

const networkModel = (info, t) => {
    const { lastUpdate, updateTimeMin, data } = info || {};

    const { mdnsData, anomalousTrafficData } = data || {};

    const { ipListTrafficFromOtherNetwork, isRoutingToCastNetwork, isTrafficFromOtherNetwork } =
        anomalousTrafficData || {};
    const { isMdnsActive, isConnectivityActive, arpMacs, serverIPS, serverMacs, areUsers } = mdnsData || {};

    return {
        lastUpdate,
        updateTimeMin,
        areUsers,
        mdnsData: {
            mdns: {
                value: isMdnsActive ? t("active") : t("inactive"),
                warning: !isMdnsActive,
                empty: !areUsers,
            },
            "cc-connectivity": {
                value: isConnectivityActive,
                warning: {
                    critical: !isConnectivityActive,
                    arpMacs,
                    serverIPS,
                    serverMacs,
                },
                empty: !areUsers,
            },
        },
        anomalousTrafficData: {
            ipListTrafficFromOtherNetwork,
            isRoutingToCastNetwork,
            isTrafficFromOtherNetwork,
        },
    };
};
