import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import UseInputTextArea from "../Inputs/useInputTextArea";
import { setActionInput } from "../../actions/globalActions";
import { getProjectLangs } from "../../hooks/Utils/SalesUtils";
import _ from "lodash";

const UseModalEditDescriptionProduct = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { projectLangs } = useSelector((state) => state.ui);
    const modalInfo = useSelector((state) => state.ui.modalContent.inputs[index]);
    const idProduct = modalInfo && modalInfo.data && modalInfo.data.idProduct ? modalInfo.data.idProduct : "";
    const [langDefault, setLangDefault] = useState("");
    const [langsArray, setLangsArray] = useState([]);
    const [stateDescriptions, setStateDescriptions] = useState({});
    const [placeHolder, setPlaceHolder] = useState("");

    const descriptionsObj =
        modalInfo && modalInfo.data && modalInfo.data && modalInfo.data.objTranslations
            ? modalInfo.data.objTranslations
            : {};

    useEffect(() => {
        const newObj = {};
        for (const langCode in descriptionsObj) {
            newObj[langCode] = {
                description: descriptionsObj[langCode].description,
            };
        }
        setStateDescriptions(newObj);
    }, [descriptionsObj]);

    useEffect(() => {
        if (projectLangs) {
            let projectLangsOrdered = [...projectLangs];
            projectLangsOrdered.map((projectLang) => {
                projectLang.langName = t("language:" + projectLang.languageRef);
            });
            projectLangsOrdered = _.orderBy(projectLangsOrdered, ['langName'],['asc']);
            setLangDefault(getProjectLangs(projectLangsOrdered, t).langDefault);
            setLangsArray(getProjectLangs(projectLangsOrdered, t).langs);
        }
        //eslint-disable-next-line
    }, [projectLangs]);

    useEffect(() => {
        if (stateDescriptions[langDefault] && stateDescriptions[langDefault]["description"]) {
            setPlaceHolder(stateDescriptions[langDefault]["description"]);
        } else {
            setPlaceHolder("");
        }
        //eslint-disable-next-line
    }, [stateDescriptions]);

    useEffect(() => {
        const dataToAction = { "id-product": idProduct };
        dispatch(setActionInput(dataToAction));
        //eslint-disable-next-line
    }, [idProduct]);

    return (
        <div className=" w-full flex">
            <div className=" w-1/2 pr-8">
                <div className=" text-base font-bold"> {capitalizeFirst(t("default-language"))} </div>
                <div className=" mt-6 flex">
                    <span className=" mr-8 font-bold">{t("language:" + langDefault)}</span>
                    <div className=" mt-2 w-full">
                        <UseInputTextArea
                            id={`change-description-product-${langDefault}`}
                            ph="write-description"
                            value={
                                stateDescriptions[langDefault] && stateDescriptions[langDefault]["description"]
                                    ? stateDescriptions[langDefault]["description"]
                                    : ""
                            }
                            rows={5}
                            changeAction={(val) => {
                                setStateDescriptions({ ...stateDescriptions, [langDefault]: { description: val } });
                            }}
                            noResize={true}
                        />
                    </div>
                </div>
            </div>
            <div className=" border-l pl-3 w-1/2">
                <div className=" text-base font-bold"> {capitalizeFirst(t("translations"))} </div>
                <div className=" mt-2 px-6 overflow-scroll py-12 rounded border " style={{ maxHeight: "24rem" }}>
                    {langsArray.map((el) => (
                        <div className=" flex mb-4" key={el.name}>
                            <span className=" w-36 font-bold"> {el.name} </span>
                            <div className=" mt-2 w-full">
                                <UseInputTextArea
                                    id={`change-description-product-${el.languageRef}`}
                                    ph={placeHolder || t("enter-translation-here")}
                                    noTranslatePh={true}
                                    value={
                                        stateDescriptions[el.languageRef] &&
                                        stateDescriptions[el.languageRef]["description"]
                                            ? stateDescriptions[el.languageRef]["description"]
                                            : ""
                                    }
                                    rows={5}
                                    changeAction={(val) => {
                                        setStateDescriptions({
                                            ...stateDescriptions,
                                            [el.languageRef]: { description: val },
                                        });
                                    }}
                                    noResize={true}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default UseModalEditDescriptionProduct;
