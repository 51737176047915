import React, { useState, useEffect } from "react";
import UseRadio from "../useRadio";
import { useTranslation } from "react-i18next";
import UseInputTextArea from "../Inputs/useInputTextArea";
import UseSelectMultipleWithSearchOptionsQuery from "../useSelectMultipleWithSearchOptionsQuery";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import UseCompactTable from "../UseCompactTable";
import uuid from "react-uuid";
import { useDispatch, useSelector } from "react-redux";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { changeActionValues } from "../../actions/globalActions";
import { Session } from "../../hooks/Utils/Session";
import { setLoadingModalContent } from "actions/uiActions";

const UseModalEditEmailOrders = () => {
    const { t } = useTranslation();
    const { permissions } = useSelector((state) => state.ui);
    const hasOrdersManagement = permissions?.services?.shopsOrders;
    const [textInputEmails, setTextInputEmails] = useState("");
    const [emailFromUsers, setEmailsFromUsers] = useState([]);
    const { values } = useSelector((state) => state.action);
    const [emailsWritten, setEmailsWritten] = useState([]);
    const [dataEmails, setDataEmails] = useState([]);
    const [optionSelected, setOptionSelected] = useState("shop");
    const [usersShopSelectData, setUsersShopSelectData] = useState([]);
    const [usersShopOriginal, setUsersShopOriginal] = useState([]);
    const [usersWithSalesAccess, setUsersWithSalesAccess] = useState([]);
    const [usersWithSalesAccessSetted, setUsersWithSalesAccessSetted] = useState(false);
    const dispatch = useDispatch();

    const GET_EMAILS = gql(`
    query{
        shops(token: "${Session.getSessionProp("tokenShop")}"){
          results{
            id
            destinationUsers
            destinationEmails
            usersInfo{
                accountname
                id
            }
          }
        }
      }
    `);

    const GET_USERS = gql(`
    {
        users{
          results{
            email
            id
            roles{
              accesses{
                category
                name
              }
            }
          }
        }
      }
    `);

    const [executeQuery, { data }] = useLazyQuery(GET_EMAILS, {
        fetchPolicy: "network-only",
    });

    const [executeQueryUsers, { data: users }] = useLazyQuery(GET_USERS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeQuery();
        if (!hasOrdersManagement) {
            executeQueryUsers();
        }
        dispatch(setLoadingModalContent(true));
        return () => {
            dispatch(setLoadingModalContent(false));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const shopResult = data?.shops?.results?.[0];

        if (!shopResult) {
            return;
        }
        if (hasOrdersManagement) {
            const { destinationUsers, destinationEmails, usersInfo } = shopResult;

            const dataUsersShop = usersInfo.map((user) => ({
                id: user.id,
                name: user.accountname,
                selected: false,
                visible: true,
            }));

            const dataEmails = destinationEmails.map((email) => ({
                value: uuid(),
                text: email,
            }));

            const matchedUsersInfo = usersInfo.filter((user) => destinationUsers.includes(user.id));

            const dataEmailsUser = matchedUsersInfo.map((user) => ({
                value: user.id,
                text: user.accountname,
                user: true,
            }));
            setDataEmails([...dataEmails, ...dataEmailsUser]);
            setUsersShopSelectData(dataUsersShop);
            setUsersShopOriginal(dataUsersShop);
        }
        dispatch(setLoadingModalContent(false));

        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (!hasOrdersManagement) {
            const usersResults = users?.users?.results;
            const findUsersWithManagerPermission = usersResults?.filter((user) => {
                const usersWithRole = user.roles.some((role) => {
                    const hasAccess = role?.accesses?.some((access) => {
                        return access.name == "shopsAdministrator";
                    });
                    return hasAccess;
                });
                if (usersWithRole) {
                    return user.email;
                }
            });

            const setUserStructure = findUsersWithManagerPermission?.map((user) => {
                return {
                    id: user.id,
                    name: user.email,
                    visible: true,
                    selected: false,
                };
            });
            setUsersWithSalesAccess(setUserStructure);
            setUsersWithSalesAccessSetted(true);
            setUsersShopOriginal(setUserStructure);
        }
    }, [users]);

    useEffect(() => {
        if (!hasOrdersManagement && data && users && usersWithSalesAccessSetted) {
            const shopResult = data?.shops?.results?.[0];
            if (shopResult) {
                const { destinationEmails } = shopResult;
                const usersFilter = usersWithSalesAccess
                    .filter((user) => destinationEmails?.includes(user.name))
                    .map((user) => ({
                        value: user.id,
                        text: user.name,
                        user: false,
                    }));
                const dataEmails = destinationEmails
                    .filter((email) => !usersFilter?.some((user) => user.text === email))
                    .map((email) => ({
                        value: uuid(),
                        text: email,
                    }));
                setDataEmails((prev) => [...prev, ...usersFilter, ...dataEmails]);
                setUsersWithSalesAccessSetted(false);
            }
        }
    }, [usersWithSalesAccess, data, usersWithSalesAccessSetted]);

    useEffect(() => {
        if (optionSelected === "shop") {
            if (hasOrdersManagement) {
                setUsersShopSelectData(filteringUsersInEmails(dataEmails, usersShopSelectData));
            } else {
                setUsersWithSalesAccess(filteringUsersInEmails(dataEmails, usersWithSalesAccess));
            }
        }
        const emailsWithoutUserTrue = dataEmails.filter((email) => email.user !== true).map((email) => email.text);
        const emailsWithUserTrue = dataEmails.filter((email) => email.user === true).map((email) => email.value);

        dispatch(
            changeActionValues({
                "emails-orders-shop": emailsWithoutUserTrue,
                "emails-orders-users-shop": emailsWithUserTrue,
            })
        );

        // eslint-disable-next-line
    }, [dataEmails]);

    useEffect(() => {
        if (values?.["assign-users-shop"]) {
            if (hasOrdersManagement) {
                setEmailsFromUsers(matchEmails(values["assign-users-shop"], usersShopSelectData));
            } else {
                setEmailsFromUsers(matchEmails(values["assign-users-shop"], usersWithSalesAccess));
            }
        }
        // eslint-disable-next-line
    }, [values]);

    return (
        <div className=" w-full flex relative">
            <div className=" w-1/2 mr-10  ">
                <p className=" block mb-4">{t("select-the-users-who-will-receive-an-email")}</p>
                <div className=" mb-6">
                    <div className=" mb-2">
                        <UseRadio
                            value={"shop"}
                            selected={optionSelected}
                            handleClick={() => setOptionSelected("shop")}
                        />
                        <span onClick={() => setOptionSelected("shop")} className=" cursor-default block ml-6 -mt-1">
                            {t(`${hasOrdersManagement ? "shop-users" : "users-with-sales-access"}`)}
                        </span>
                    </div>
                    <div className=" pl-6">
                        <UseSelectMultipleWithSearchOptionsQuery
                            dataSelect={{
                                textStyle:
                                    "text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden",
                                name: "assign-users-shop",
                                optionData: hasOrdersManagement ? usersShopSelectData : usersWithSalesAccess,
                                selectPlaceHolder: "select-users",
                                cStyle: "w-full",
                                sort: true,
                                type: "selectMultipleWithSearch",
                                noSelect: true,
                                disabled: optionSelected !== "shop" ? true : false,
                            }}
                            key={hasOrdersManagement ? usersShopSelectData : usersWithSalesAccess}
                        ></UseSelectMultipleWithSearchOptionsQuery>
                    </div>
                </div>
                <div className=" mb-16">
                    <div className=" mb-2">
                        <UseRadio
                            value={"write"}
                            selected={optionSelected}
                            handleClick={() => setOptionSelected("write")}
                        />
                        <span onClick={() => setOptionSelected("write")} className=" cursor-default block ml-6 -mt-1">
                            {t("write-emails")}
                        </span>
                    </div>
                    <div className=" pl-6 ">
                        <div className={`mb-2 ${optionSelected !== "write" ? "text-gray-400" : ""}`}>
                            {t("enter-emails-separated-by-symbol", { symbol: ";" })}
                        </div>
                        <UseInputTextArea
                            id={"edit-mail-orders-text-area"}
                            disabled={optionSelected !== "write"}
                            value={textInputEmails}
                            adjust={"h-28"}
                            changeAction={(val) => {
                                setTextInputEmails(val);
                                const emails = parseTextToEmails(val, dataEmails);
                                setEmailsWritten(emails);
                            }}
                            noResize={true}
                        />
                    </div>
                </div>
                <div className=" w-full">
                    <button
                        onClick={() => {
                            if (optionSelected === "write") {
                                setTextInputEmails("");
                            }
                            if (emailsWritten.length > 0 && optionSelected === "write") {
                                emailsWritten.forEach((email) => {
                                    const userMatch = usersShopSelectData.find((user) => user.name === email.text);
                                    if (userMatch) {
                                        email.value = userMatch.id;
                                        email.user = true;
                                    }
                                });
                                setDataEmails([...dataEmails, ...emailsWritten]);
                                const newUserShopSelecteData = [
                                    ...deleteEmailFromUsers(usersShopSelectData, emailsWritten),
                                ].map((element) => {
                                    if (values && values["assign-users-shop"].includes(element.id)) {
                                        element.selected = true;
                                    }
                                    return element;
                                });
                                setUsersShopSelectData(newUserShopSelecteData);
                                setTextInputEmails("");
                                setEmailsWritten([]);
                            } else if (emailFromUsers.length > 0 && optionSelected === "shop") {
                                const updatedEmails = emailFromUsers.map((emailObj) => {
                                    return {
                                        ...emailObj,
                                        user: hasOrdersManagement,
                                    };
                                });
                                setDataEmails([...dataEmails, ...updatedEmails]);
                            }
                        }}
                        className=" w-full py-2 font-bold text-white flex items-center justify-center rounded bg-zafiro-600 focus:outline-none"
                    >
                        {capitalizeFirst(t("add"))}
                        <span className=" ml-2 text-lg mt-1 block icon icon-arrow-show"></span>
                    </button>
                </div>
            </div>
            <div className=" w-1/2 ml-10">
                <UseCompactTable
                    data={dataEmails}
                    countName="emails"
                    bottomText="delete-selected-emails"
                    columnName="email"
                    tableName="emails-orders"
                    emptyList="no-emails-yet"
                    heightTable="20rem"
                    actionButton={(val) => {
                        const newUserShopSelecteData = [...usersShopSelectData, ...usersWithSalesAccess].map(
                            (element) => {
                                if (values && values["assign-users-shop"].includes(element.id)) {
                                    element.selected = true;
                                }
                                return element;
                            }
                        );
                        if (checkEmailExistInUsers(val, dataEmails, usersShopOriginal)) {
                            if (hasOrdersManagement) {
                                setUsersShopSelectData([
                                    ...newUserShopSelecteData,
                                    {
                                        id: val,
                                        name: checkEmailExistInUsers(val, dataEmails, usersShopOriginal),
                                        selected: false,
                                        visible: true,
                                    },
                                ]);
                            } else {
                                setUsersWithSalesAccess([
                                    ...newUserShopSelecteData,
                                    {
                                        id: val,
                                        name: checkEmailExistInUsers(val, dataEmails, usersShopOriginal),
                                        selected: false,
                                        visible: true,
                                    },
                                ]);
                            }
                        }
                        setDataEmails((prev) => removeValues(prev, String(val)));
                    }}
                    actionBottomButton={(checkedItems) => {
                        const newUsersShopSelectData = [...usersShopSelectData, ...usersWithSalesAccess].map(
                            (element) => {
                                if (values && values["assign-users-shop"].includes(element.id)) {
                                    element.selected = true;
                                }
                                return element;
                            }
                        );
                        checkedItems.forEach((item) => {
                            if (checkEmailExistInUsers(item, dataEmails, usersShopOriginal)) {
                                newUsersShopSelectData.push({
                                    id: item,
                                    name: checkEmailExistInUsers(item, dataEmails, usersShopOriginal),
                                    selected: false,
                                    visible: true,
                                });
                            }
                        });
                        if (hasOrdersManagement) {
                            setUsersShopSelectData(newUsersShopSelectData);
                        } else {
                            setUsersWithSalesAccess(newUsersShopSelectData);
                        }

                        if (checkedItems.length > 0) {
                            setDataEmails(removeValues(dataEmails, checkedItems));
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default UseModalEditEmailOrders;

const checkEmailExistInUsers = (id, emails = [], usersShop = []) => {
    let result = null;
    const userName = emails.find((email) => email.value === id)?.text;
    if (userName && usersShop.some((obj) => obj.name === userName)) {
        result = userName;
    }
    return result;
};

function deleteEmailFromUsers(array1, array2) {
    const valuesToRemove = array2.map((obj) => obj.text);
    return array1.filter((item) => !valuesToRemove.includes(item.name));
}

const parseTextToEmails = (emails, dataEmails) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailArray = emails
        .split(";")
        .map((email) => email.trim())
        .filter((email) => regex.test(email));

    const uniqueEmails = emailArray.filter((element) => !dataEmails.some((email) => email.text === element));

    const parsedEmails = uniqueEmails.map((email) => ({
        value: uuid(),
        text: email,
    }));

    return parsedEmails;
};

const matchEmails = (array1, array2) => {
    const results = [];

    for (const idToSearch of array1) {
        for (const data of array2) {
            if (data.id === idToSearch) {
                results.push({ value: data.id, text: data.name });
                break;
            }
        }
    }

    return results;
};

const filteringUsersInEmails = (array1, array2) => {
    const usersExcluded = array1.map((obj) => obj.text);
    const result = array2.filter((obj) => !usersExcluded.includes(obj.name));
    return result;
};

const removeValues = (mainArray, valuesToRemove) => {
    return mainArray.filter((element) => !valuesToRemove.includes(element.value));
};
