import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";
import { PENDING_VALIDATION_CAST_NETWORKS } from "../services/getReportQueries";

export const useIsPending = () => {
    const [isPending, setIsPending] = useState(null);

    const [executeQuery, { data }] = useLazyQuery(PENDING_VALIDATION_CAST_NETWORKS, {
        fetchPolicy: "network-only",
        pollInterval: 20000,
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (data) {
            setIsPending(data?.pendingValidateCastNetwork?.isPending);
        }
    }, [data]);

    return { isPending };
};
