import DeleteTaxZone from "../modals/DeleteTaxZone";
import NewTaxZone from "../modals/NewTaxZone";
import TaxInUse from "../modals/TaxInUse";

const TaxZone = (taxZone, taxRates, t, shopsData) => {
    const dataType = "tax-zone";
    let taxRatesTooltip = "";
    let numberShopsAssociatedToTax = 0;
    if (taxRates && taxRates.length > 0) {
        taxRatesTooltip = "<div class='w-auto min-w-40 mb-3 text-left'>";
        // eslint-disable-next-line
        taxRates.map((taxRate) => {
            taxRatesTooltip += `
                <div class='flex items-center w-full justify-between my-2'>
                    <span class=''>${taxRate.name}</span>
                    <span class=''>${taxRate.value}%</span>
                </div>
            `;
        });
        taxRatesTooltip += "</div>";
    }

    shopsData.forEach((shop) => {
        if (Number(taxZone.id) === Number(shop.defaultTaxZone.id)) {
            numberShopsAssociatedToTax++;
        }
    });

    return {
        id: taxZone.id,
        dataType: dataType,
        executeSectionQuery: true,
        name: taxZone.name,
        taxRates: taxRates,
        info: [
            {
                name: "name",
                value: taxZone.name,
                cellContent: "text",
                specialWidth: "w-3/12",
            },
            {
                cellContent: "labels",
                value: [[0, `${taxRates ? taxRates.length : 0} ${t("taxes")}`, "#0F63BD", taxRatesTooltip, true]],
                specialWidth: "w-3/12 truncate",
                cellAdjust: "labels",
            },
            {
                cellContent: "button",
                modal: TaxInUse(taxZone, t, { taxZone, shopsData }),
                name:
                    numberShopsAssociatedToTax > 0 ? t("used-in-n-shops", { number: numberShopsAssociatedToTax }) : "",
                specialWidth: "w-5/12 truncate",
                adjust: "text-zafiro-600 pl-1 w-auto w-fit-content cursor-pointer",
                adjustName: "auto",
            },
            {
                cellContent: "icon",
                value: taxZone.id,
                evaluate: true,
                specialWidth: "w-0.5/12",
                isAction: true,
                modal: [NewTaxZone(taxZone, taxRates)],
                name: "tax-zone",
                icon: {
                    name: "edit",
                    id: taxZone.id,
                    values: taxZone.id,
                    itemType: dataType,
                },
            },
            {
                cellContent: "icon",
                value: taxZone.id,
                evaluate: true,
                specialWidth: "w-0.5/12",
                isAction: true,
                modal: DeleteTaxZone(taxZone.id, taxZone.name, taxRates),
                name: "tax-zone",
                disabledIcon: numberShopsAssociatedToTax > 0,
                tooltip: numberShopsAssociatedToTax > 0 ? t("tax-zone-in-use") : null,
                icon: {
                    name: "delete",
                    values: taxZone.id,
                    itemType: dataType,
                },
            },
        ],
    };
};

export default TaxZone;
