import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { formatDate } from "./services/formatDate";
import { Warning } from "./Warning";
import ReactTooltip from "components/ReactTooltip";
import { NetworkHeader } from "./NetworkHeader";

export const MdnsInfo = ({ mdnsInfo, called, loading }) => {
    const { t } = useTranslation();
    const { mdnsData, lastUpdate, updateTimeMin, ccTotalNumber } = mdnsInfo || {};

    const lastUpdateDate = formatDate(lastUpdate);

    if (loading || !called) return null;

    return (
        <main className="bg-white p-4">
            <NetworkHeader title="mdns" lastUpdateDate={lastUpdateDate} updateTimeMin={updateTimeMin} />
            {ccTotalNumber == 0 ? (
                <div className="mb-8">
                    <div className="flex items-center">
                        <span className="icon-info text-xl ml-3"></span>
                        <span className="ml-2">{t("no-cast-data")}</span>
                    </div>
                    <span className="ml-10 ">{t("not-possible-to-monitor")}</span>
                </div>
            ) : null}
            <ul className="flex flex-col gap-4 list-disc ml-10">
                {Object.keys(mdnsData).map((key) => {
                    return (
                        <Fragment key={key}>
                            <li>
                                {capitalizeFirst(t(key))}:{" "}
                                <span className="font-bold" id={`mdns-${key}`}>
                                    {typeof mdnsData[key] === "object" ? (
                                        mdnsData[key]?.empty ? (
                                            "-"
                                        ) : (
                                            <span data-tip data-for={`ccs-${key}`}>
                                                {mdnsData[key]?.value}
                                                {mdnsData[key]?.tooltip && (
                                                    <ReactTooltip id={`ccs-${key}`} place="right" effect="solid">
                                                        <span>{mdnsData[key]?.tooltip}</span>
                                                    </ReactTooltip>
                                                )}
                                            </span>
                                        )
                                    ) : (
                                        mdnsData[key]
                                    )}
                                </span>
                            </li>
                            {ccTotalNumber !== 0 ? (
                                <>
                                    {mdnsData[key]?.warning && key === "mdns" ? (
                                        <Warning color={"red"} text={t("mdns-filter")} />
                                    ) : null}
                                    {mdnsData[key]?.warning?.critical && key === "ccs-discovered" ? (
                                        <Warning color={"red"} text={t("mdns-proxy-found")} />
                                    ) : null}
                                    {mdnsData[key]?.warning?.minor && key === "ccs-discovered" ? (
                                        <>
                                            <Warning color={"orange"} text={t("low-level-cc")} />
                                            <span className="ml-10 text-gray-800">{t("mdns-affect-monitoring")}</span>
                                        </>
                                    ) : null}
                                    {mdnsData[key]?.warning?.minorOrCritical && key === "ccs-discovered" ? (
                                        <>
                                            <Warning
                                                text={t(`ccs-never-discovered`, {
                                                    ccNumber: mdnsData[key]?.warning?.textValue,
                                                })}
                                                color={mdnsData[key]?.warning?.color}
                                            />
                                            <span className="ml-10 text-gray-800">{t("neither-monitoring")}</span>
                                        </>
                                    ) : null}
                                    {mdnsData[key]?.warning && key === "average-latency" ? (
                                        <>
                                            <div className="flex flex-col ml-3 justify-center text-orange-100">
                                                <Warning text={t("porcentage-latency-cc")} ml={0} color={"orange"} />
                                                <p className="text-gray-800 ml-6">{t("mdns-flood-detected")}</p>
                                                <p className="text-gray-800 ml-6">{t("mdns-filter-apply")}</p>
                                            </div>
                                        </>
                                    ) : null}
                                    {mdnsData[key]?.warning && key === "bandwidth" ? (
                                        <>
                                            <div className="flex flex-col ml-3 justify-center text-orange-100">
                                                <Warning text={t("high-traffic-detected")} ml={0} color={"orange"} />
                                                <p className="text-gray-800 ml-6">{t("mdns-filter-apply")}</p>
                                            </div>
                                        </>
                                    ) : null}
                                </>
                            ) : null}
                        </Fragment>
                    );
                })}
            </ul>
        </main>
    );
};
