import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { withApollo } from "@apollo/react-hoc";
//Actions
import { showGlobalLoading, setModalContent, openModal, closeModal } from "../../../actions/uiActions";
import { setRefreshContentData, setExecuteQuery } from "../../../actions/sectionActions";
import { cleanAction, cleanActionValues } from "../../../actions/globalActions";

//Utils
import { toast } from "react-toastify";
import {
    joinFormDateTime,
    removeApolloCacheKeys,
    dateString,
    capitalizeFirst,
    bdDateToDateInput,
    escapeSpecialChars,
} from "../../Utils/Utils";
import { COMPOSE_MUTAION_UPDATE_GUEST_DATA } from "components/Section/Customers/Precheckin/ReservationDetails/utils";

const UpdateStay = ({ client }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const [checkLoyalty, setCheckLoyalty] = useState(null);

    //Actions
    const dispatch = useDispatch();

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        onError(err) {
            console.log(err);
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(langStrings["mutation-error"]);
        },
    });

    const ISLOYALTY = gql`{isLoyalty: isLoyalty(email: "${checkLoyalty ? checkLoyalty.email : null}")}`;

    const [executeLoyaltyQuery, loyaltyData] = useLazyQuery(ISLOYALTY);

    const completeEmpty = (val) => {
        return !actionData.values || !actionData.values[val] ? "" : escapeSpecialChars(actionData.values[val]);
    };

    const hasChanges = (text) => {
        return actionData.values[text] && actionData.values[text].length > 0 ? true : false;
    };

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            console.log(executeQuery);

            execute = true;
            switch (executeQuery.action) {
                case "regenerateRoomcodes":
                    setMutation(`
                        mutation{
                            regenerateRoomcodes(
                                roomIDs: [${executeQuery.params.id}]
                            ){ ok error }}
                    `);
                    removeApolloCacheKeys(client.cache, ["hotelRooms", "rooms"]);

                    break;
                case "updateStay":
                    const arrivalDate = joinFormDateTime(
                        actionData.values["arrival-date"],
                        actionData.values["arrival-hour"]
                    );
                    const departureDate = joinFormDateTime(
                        actionData.values["departure-date"],
                        actionData.values["departure-hour"]
                    );

                    setMutation(`
                    mutation{
                        updateStay(
                            id: ${executeQuery.params.id},
                              arrival:"${dateString(arrivalDate)}"
                     ${
                         actionData.values["departure-date"]
                             ? `departure:"${dateString(departureDate)}"`
                             : `departure:""`
                     }
                        ){ error ok }}
                `);
                    break;

                case "checkIn":
                    if (actionData.values["email"]) {
                        setCheckLoyalty({
                            email: actionData.values["email"],
                            roomId: actionData.values["room"][0],
                            rooms: executeQuery.params.rooms,
                            operation: "checkin",
                        });
                    }

                    setMutation(`
                    mutation{
                        checkIn(
                            guest:{
                            guestRole: "COMPANION",
                            name: "${escapeSpecialChars(actionData.values["name"])}",
                            surname: "${escapeSpecialChars(actionData.values["surname"])}",
                            ${
                                actionData.values["title"]
                                    ? `title: "${escapeSpecialChars(actionData.values["title"])}",`
                                    : ""
                            }
                            ${actionData.values["email"] ? `email: "${actionData.values["email"]}",` : ""}
                            ${
                                actionData.values["birthday"]
                                    ? `birthDate: "${bdDateToDateInput(new window.ZDate(actionData.values.birthday))}",`
                                    : ""
                            }                           
                            languageRef: "${
                                actionData.values.lang && actionData.values.lang[0]
                                    ? actionData.values.lang[0].toLowerCase()
                                    : ""
                            }"
                        },
                        rooms: [${actionData.values["room"][0]}]
                        ){ error ok }}`);
                    break;
                case "add-companion":
                    if (actionData.values["email"]) {
                        setCheckLoyalty({
                            email: actionData.values["email"],
                            roomId: actionData.values["room"],
                            rooms: actionData.values["rooms"],
                            operation: "checkin",
                        });
                    }

                    setMutation(`
                    mutation{
                        checkIn(
                            guest:{
                            guestRole: "COMPANION",
                            name: "${escapeSpecialChars(actionData.values["name"])}",
                            surname: "${escapeSpecialChars(actionData.values["surname"])}",
                            phoneNumber: "${escapeSpecialChars(actionData.values["mobilePhone"])}",
                            ${
                                actionData.values["title"]
                                    ? `title: "${escapeSpecialChars(actionData.values["title"])}",`
                                    : ""
                            }
                            ${actionData.values["email"] ? `email: "${actionData.values["email"]}",` : ""}
                            ${
                                actionData.values["birthday"]
                                    ? `birthDate: "${bdDateToDateInput(new window.ZDate(actionData.values.birthday))}",`
                                    : ""
                            }            
                            ${
                                actionData.values["nationality"]
                                    ? `countryRef: "${actionData.values["nationality"]}"`
                                    : ""
                            }      
                            ${actionData.values["gender"] ? `gender: ${actionData.values["gender"]}` : ""}         
                            languageRef: "${
                                actionData?.values?.language ? actionData.values.language.toLowerCase() : ""
                            }"
                        },
                        rooms: [${actionData.values["room"]}]
                        ){ error ok }}`);
                    executeQuery.action = "checkIn";
                    executeQuery.closeModal = true;
                    break;
                case "delete-companion":
                    const roomId = actionData.values.rooms?.[0]?.roomID ? actionData.values.rooms?.[0].roomID : "";
                    const guestId = actionData.values.id ? actionData.values.id : "";
                    setMutation(`mutation {
                            checkOut(rooms: [${roomId}], stayGuestID: ${guestId}) {
                              error
                              id
                              ok
                            }
                          }
                          `);
                    executeQuery.action = "checkOut";
                    executeQuery.closeModal = true;
                    break;
                case "edit-companion":
                    const { mutationString, emailChanged, roomChanged } = COMPOSE_MUTAION_UPDATE_GUEST_DATA(
                        actionData.values
                    );
                    if (emailChanged) {
                        setCheckLoyalty({
                            email: actionData.values["email"],
                            roomId: actionData.values["room"],
                            rooms: actionData.values["rooms"],
                            operation: "checkin",
                        });
                    }
                    setMutation(`mutation {
                        updateGuest(
                            ${actionData.values.id ? `id: ${actionData.values.id}` : ""}, 
                            , data: {
                                ${mutationString ? mutationString : `name: "${actionData.values.name}"`}
                        }) {
                          error
                          id
                          ok
                          __typename
                        }
                        ${
                            roomChanged
                                ? ` changeRoom(stayGuestID: ${actionData.values.id}, 
                                    oldRoomID: ${roomChanged.oldRoom}, 
                                    newRoomID: ${roomChanged.newRoom}) {
                                id
                                ok
                                }`
                                : ""
                        }
                      }`);
                    executeQuery.action = "updateGuest";
                    executeQuery.closeModal = true;
                    break;
                case "edit-documentation":
                    const onlyDocumentTypeOrNotValues =
                        (actionData.values.documentType?.trim() &&
                            !actionData.values.documentNumber?.trim() &&
                            !actionData.values.dateOfIssue?.trim()) ||
                        (!actionData.values.documentType?.trim() &&
                            !actionData.values.documentNumber?.trim() &&
                            !actionData.values.dateOfIssue?.trim());

                    setMutation(`mutation {
                            updateGuest(
                              id: ${actionData.values.id}
                              data: { 
                                ${
                                    onlyDocumentTypeOrNotValues
                                        ? `documentType: "<null>" , name: "${actionData.values.name}" , documentNumber: "" , documentExtraInformation: { expeditionDate: "" }`
                                        : ` documentType: "${
                                              actionData.values.documentType ? actionData.values.documentType : ""
                                          }", 
                                        ${
                                            actionData.values.documentNumber
                                                ? `documentNumber: "${actionData.values.documentNumber}",`
                                                : `documentNumber: "",`
                                        }
                                        ${
                                            actionData.values.dateOfIssue
                                                ? `documentExtraInformation: { expeditionDate: "${actionData.values.dateOfIssue}" }`
                                                : `documentExtraInformation: { expeditionDate: "" }`
                                        } `
                                }
                            }
                            ) {
                              error
                              id
                              ok
                              __typename
                            }}
                          `);

                    executeQuery.action = "updateGuest";
                    executeQuery.closeModal = true;

                    break;
                case "updateGuest":
                    if (actionData.values["email"] && executeQuery.params.oldEmail !== actionData.values["email"]) {
                        setCheckLoyalty({
                            email: actionData.values["email"],
                            roomId: actionData.values["room"][0],
                            rooms: executeQuery.params.rooms,
                            operation: "update",
                        });
                    }
                    const birthdate =
                        actionData &&
                        actionData.values &&
                        actionData.values.birthday &&
                        new window.ZDate(actionData.values.birthday)
                            ? bdDateToDateInput(new window.ZDate(actionData.values.birthday))
                            : "";
                    const dataToUpdate = `
                        ${hasChanges("title") ? `title: "${completeEmpty("title")}"` : ""}
                        ${hasChanges("name") ? `name: "${completeEmpty("name")}"` : ""} 
                        ${hasChanges("surname") ? `surname: "${completeEmpty("surname")}"` : ""} 
                        ${hasChanges("email") ? `email: "${completeEmpty("email")}"` : ""} 
                        ${hasChanges("birthday") ? `birthDate: "${birthdate}"` : ""} 
                        ${
                            actionData.values.lang && actionData.values.lang[0]
                                ? `languageRef: "${actionData.values.lang[0].toLowerCase()}"`
                                : ""
                        }
                    `;
                    const regex = /\s|\t|\n/g;
                    setMutation(`
                    mutation {
                        updateGuest(id:${actionData.itemsAffected}
                        ${dataToUpdate.replaceAll(regex, "") !== "" ? `, data:{${dataToUpdate}}` : ""}
                       ){
                            error
                            id
                            ok
                        }

                        ${
                            actionData.values["room"] && actionData.values["room"][0] !== executeQuery.params.oldRoomID
                                ? `changeRoom(stayGuestID:${actionData.itemsAffected},oldRoomID: ${executeQuery.params.oldRoomID}, newRoomID: ${actionData.values["room"][0]}){ id ok }`
                                : ""
                        }
                    }
                `);
                    break;

                default:
                    execute = false;
                    break;
            }
        }
        if (execute) {
            dispatch(showGlobalLoading(true));

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            data[executeQuery.action] &&
            (data[executeQuery.action].isOk || data[executeQuery.action].ok)
        ) {
            if (checkLoyalty) {
                executeLoyaltyQuery();
            }

            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }

            toast.success(langStrings["operation-successful"]);
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            dispatch(setRefreshContentData(true));

            //remove guest list cache to show updated info
            removeApolloCacheKeys(client.cache, ["guests", "installationMap"]);
        } else if (
            data &&
            executeQuery &&
            data[executeQuery.action] &&
            (data[executeQuery.action].errorMsg || data[executeQuery.action].error)
        ) {
            toast.error(langStrings["mutation-error"]);
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
        }
        // eslint-disable-next-line
    }, [data]);

    //modal to inform about the creation of digital key for companions
    useEffect(() => {
        if (loyaltyData && loyaltyData.data && loyaltyData.data.isLoyalty && checkLoyalty) {
            const room = checkLoyalty.rooms.filter((room) => room.RoomID === checkLoyalty.roomId)[0];
            dispatch(
                setModalContent({
                    title: checkLoyalty.operation === "checkin" ? "new-companion" : "loyal-customer",
                    bodyAdjust: "w-3/12",
                    buttons: [{ name: "accept", style: "blue", action: "close" }],
                    inputs: [
                        {
                            text: `${capitalizeFirst(
                                langStrings[checkLoyalty.operation === "checkin" ? "companion-added" : "guest-is-loyal"]
                            )}.<br> ${capitalizeFirst(langStrings["access-to-room"]).replace(
                                "%0",
                                room ? room.number : checkLoyalty.roomId
                            )}.`,
                            type: "htmltext",
                        },
                    ],
                })
            );
            //Launch modal
            dispatch(openModal());
            setCheckLoyalty(null);
        }
        // eslint-disable-next-line
    }, [loyaltyData]);

    return null;
};

export default withApollo(UpdateStay);
