export const CustomersSidebar = [
    "pre-checking",
    "booking-list",
    "configuration",
    "loyal-customers",
    "guests",
    "guest-list",
    "guest-groups",
    "guestgroups",
];
