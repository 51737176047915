import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal, openModal } from "../../../actions/uiActions";
import { toast } from "react-toastify";
import BrowserMediaLibraryModal from "../MediaLibrary/modals/BrowserMediaLibraryModal";
import { gql } from "apollo-boost";
import { capitalizeFirst, removeApolloCacheKeys } from "../../Utils/Utils";
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { cleanAction, changeGlobalAction, changeActionValues } from "../../../actions/globalActions";
import { withApollo } from "@apollo/react-hoc";
import { useNavigate as useHistory } from "react-router-dom";

const UpdateCorporateChannels = ({ client, id }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { values, itemsAffected } = useSelector((state) => state.action);
    const { lang } = useSelector((state) => state.ui);
    const { checkedItems } = useSelector((state) => state.table);
    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const [mutationName, setMutationName] = useState(null);

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            let errorMsg = err.message;

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.mutationName = "";
            switch (executeQuery.action) {
                case "add-corporate-channel":
                    execute = true;
                    setMutation(`
                    mutation {
                        createCorporateTvChannel(
                          name: "${values["name-new-corporate-channel"]}"
                        ) {
                          id
                          error
                          ok
                        }
                      }
                    `);
                    setMutationName("createCorporateTvChannel");
                    break;
                case "delete-corporate-channel":
                    execute = true;
                    setMutation(`
                    mutation {
                        deleteCorporateTvChannel(
                          ids: [${itemsAffected[0]}]
                        ) {
                          id
                          error
                          ok
                        }
                      }
                    `);
                    setMutationName("deleteCorporateTvChannel");
                    break;
                case "add-video-to-corporate-channel":
                    execute = true;
                    setMutation(`
                    mutation{
                        addVideoToCorporateChannel(
                          corpChannelID: ${id},
                          videoRef:"${values["browser-media-library-items-selected"][0]}"
                        ){
                          error
                          id
                          ok
                        }
                      }
                    `);
                    setMutationName("addVideoToCorporateChannel");
                    break;
                case "replace-corporate-channel-video-select-video":
                    execute = false;
                    const modal = BrowserMediaLibraryModal(t, {
                        title: "select-a-video",
                        bodyAdjust: "w-10/12",
                        closeAction: "closeResetExecute",
                        text: "videos-of-media-library",
                        textAdjust: "",
                        buttonSelectedAssetName: "replace",
                        executeSectionQuery: true,
                        dataMediaLibrary: {
                            select_multiple: false,
                            selected_ref: null,
                            type: "video",
                            lang: lang,
                            search_input: true,
                        },
                    });
                    setTimeout(() => {
                        dispatch(
                            changeGlobalAction({
                                actionName: "replace-corporate-channel-video",
                            })
                        );
                    }, 1000);
                    dispatch(changeActionValues({ params: executeQuery.params }));
                    dispatch(setModalContent(modal));
                    dispatch(openModal());
                    break;
                case "replace-corporate-channel-video":
                    execute = true;
                    setMutation(`mutation{
                            replaceVideoRefInCorpChannelVideo(
                                corpChannelID: ${values["params"]["idCorporate"]}
                                videoRef:"${values["browser-media-library-items-selected"][0]}"
                                id: ${values["params"]["id"]}
                                ){
                                    error
                                    id
                                    ok
                                }
                            }`);
                    setMutationName("replaceVideoRefInCorpChannelVideo");
                    break;
                case "delete-video-from-corporate-channel":
                    execute = true;
                    setMutation(`mutation{
                        deleteVideoFromCorporateTvChannel(
                          corpChannelID: ${values["delete-video-from-corporate-channel"]["idCorporate"]},
                          videoID: ${values["delete-video-from-corporate-channel"]["id"]}
                        ){
                          error
                          id
                          ok
                        }
                      }`);
                    setMutationName("deleteVideoFromCorporateTvChannel");
                    break;
                case "update-name-corp-channel":
                    execute = true;
                    setMutation(`mutation{
                        updateCorporateTvChannel(
                        id: ${id}
                        name: "${executeQuery.params.nameVal}"
                        ){
                            error
                            id
                            ok
                        }
                    }`);
                    setMutationName("updateCorporateTvChannel");
                    break;
                case "add-image-to-corporate-channel":
                    execute = true;
                    setMutation(`mutation{
                        updateCorporateTvChannel(
                        id: ${id}
                        logo: "${values["browser-media-library-items-selected"][0]}"
                        ){
                            error
                            id
                            ok
                        }
                    }`);
                    setMutationName("updateCorporateTvChannel");
                    break;
                case "delete-image-corporate-channel":
                    execute = true;
                    setMutation(`mutation{
                        updateCorporateTvChannel(
                        id: ${id}
                        logo: ""
                        ){
                            error
                            id
                            ok
                        }
                    }`);
                    setMutationName("updateCorporateTvChannel");
                    break;
                case "move-video-position-corporate-channel":
                    execute = true;
                    setMutation(`
                        mutation{
                            setVideoOrderInCorpChannel(
                                id: ${executeQuery.params.tuneID}
                                corpChannelID: ${executeQuery.params.tuneGroupID}
                                pos: ${executeQuery.params.newIndex + 1}
                            ){
                                error
                                id
                                ok
                            }
                        }

                    `);
                    setMutationName("setVideoOrderInCorpChannel");
                    break;
                case "batch-delete-corporate-channels":
                    execute = true;
                    setMutation(`
                    mutation {
                        deleteCorporateTvChannel(
                          ids: [${checkedItems.join(",")}]
                        ) {
                          id
                          error
                          ok
                        }
                      }
                    `);
                    setMutationName("deleteCorporateTvChannel");
                    break;
                default:
                    setMutationName(null);
                    break;
            }
        }
        if (execute) {
            if (!showGlobalLoading) {
                dispatch(setLoading(true));
            }
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[mutationName] && (data[mutationName].isOk || data[mutationName].ok)))
        ) {
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (!executeQuery.preventToast) {
                toast.success(capitalizeFirst(t(executeQuery.customToast || "operation-successful")));
            }

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            removeApolloCacheKeys(client.cache, "all");
            setMutationName(null);
            if (mutationName === "createCorporateTvChannel") {
                history(`/services/tv-channels/corporate-channels/${data[mutationName].id}`);
            } else {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[mutationName] && (data[mutationName].errorMsg || data[mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error.msg
                        ? data[executeQuery.action].error.msg
                        : data[executeQuery.action].error
                    : data[mutationName] && data[mutationName].error
                    ? data[mutationName].error
                    : null;

            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            setMutationName(null);
            if (errorMsg !== "Code already in use!") {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateCorporateChannels);
