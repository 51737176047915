import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { changeGlobalAction } from "../../../../actions/globalActions";
import { openModal, setLoadingModalContent, setModalContent } from "../../../../actions/uiActions";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useDispatch, useSelector } from "react-redux";
import { Recipients } from "./Recipients";
import UseTagTruncateCell from "../../../Table/cells/useTagTruncateCell";

const Destinations = ({ msgData, inEdition }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { validations } = useSelector((state) => state.action);
    const [passValidation, setPassValidation] = useState(true);

    const addRecipients = () => {
        if (!inEdition) {
            return null;
        }
        dispatch(
            changeGlobalAction({
                actionName: "edit-message-destinations",
                filterOccupied: true,
            })
        );

        //Modal content
        const modalContent = {
            useGlobalLoading: true,
            preventCleanOnClose: true,
            preventSuccessToast: true,
            bodyAdjust: "w-8/12",
            // twoColumns: true,
            noCapitalizeTitle: true,
            title: "add recipients",
            inputs: [
                {
                    type: "destinations",
                    occupiedRooms: true,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close-destiantions" },
                {
                    name: "accept",
                    style: "blue",
                    action: "edit-message-destinations",
                },
            ],
        };
        dispatch(setLoadingModalContent(true));
        dispatch(setModalContent(modalContent));
        //Launch modal
        dispatch(openModal());
    };

    useEffect(() => {
        if (typeof validations["destination"] !== "undefined" && inEdition) {
            setPassValidation(validations["destination"]);
        }
        // eslint-disable-next-line
    }, [validations]);

    return (
        <>
            <div className={`fieldLabel ${passValidation ? "" : "text-red-100"}`}>
                {t("recipients")}
                {inEdition && "*"}
            </div>
            <div
                id="destinations-add-recipients-button"
                className={`${inEdition ? "clickable" : ""} link first-capital mt-2 font-medium`}
                onClick={() => addRecipients()}
            >
                {/* <div className="floatLeft">{Parser(msgData ? msgData.destinationLabels : "" || "")}</div> */}
                <div className="floatLeft" id="r001_c001">
                    {msgData && msgData.destinationLabels ? (
                        <UseTagTruncateCell
                            cellData={{
                                rowIndex: "001",
                                colIndex: "001",
                                value: msgData.destinationLabels,
                            }}
                        />
                    ) : null}
                </div>
                {inEdition ? (
                    <div className="pt-1">
                        <span className={`icon icon-${msgData && msgData.destinationLabels ? "edit" : "add"}`}></span>{" "}
                        {capitalizeFirst(t(`${msgData && msgData.destinationLabels ? "edit" : "add"} recipients`))}
                    </div>
                ) : null}
            </div>
            <Recipients
                recipients={msgData && msgData.recipients ? msgData.recipients : []}
                destinations={msgData && msgData.destination ? msgData.destination : {}}
                inEdition={inEdition}
            />
        </>
    );
};

export default Destinations;
