const Portals = (data) => {
    let portals = [
        {
            id: "hotelwifi",
            title: "wifi-hotel",
            icon: "wing",
            description: "WiFi signal offered to guests",
            adjust: "col-start-1 col-end-2",
            fields: [
                {
                    id: "hotelwifiname",
                    type: "input",
                    name: "wifi-name",
                    value: data.hotelwifi.results.name,
                },
                {
                    id: "hotelwifipass",
                    type: "input",
                    name: "password",
                    value: data.hotelwifi.results.password,
                },
            ],
            save: true,
        },
    ];

    return portals;
};
export default Portals;
