import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseNetworkGrid from "../../useNetworkGrid";
import { setActionInput } from "../../../actions/globalActions";
import { setNetworks } from "../../../actions/hotelActions";
import CastConfig from "../../../hooks/GraphqlCalls/Hotel/CastConfig";
import SetCastConfig from "../../../hooks/GraphqlCalls/Hotel/SetCastConfig";

const CastSettings = () => {
    CastConfig();
    SetCastConfig();

    //Actions
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActionInput({ save: false, refreshData: false }));
        return () => {
            dispatch(setNetworks(false));
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <UseSectionHeader title={["cast-settings"]} />
            <UseNetworkGrid gridStyles={`grid grid-rows-2 grid-cols-6 gap-4`} />
        </>
    );
};
export default CastSettings;
