import { gql } from "apollo-boost";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// Actions
import { setSectionContent, cleanSectionContent } from "../../../../actions/sectionActions";
import { useMSQuery } from "../../useMSQuery";

const GetTVSettings = () => {
    const { lang } = useSelector((state) => state.ui);
    const { refreshCode } = useSelector((state) => state.sectionContent);
    const dispatch = useDispatch();

    const GET_TV_SETTINGS = gql`
        {
            getInstallationCodeExpiration {
                code
                expiration
                error
            }
            tvSettings {
                permanentSocket
                statusReport
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch }] = useMSQuery(GET_TV_SETTINGS, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        dispatch(setSectionContent({ loadingCode: true }));
        executeQuery();
        return () => {
            dispatch(cleanSectionContent());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!error && !loading && data && data.getInstallationCodeExpiration && data.tvSettings) {
            let _date = new window.ZDate().toLocaleString(lang === "es" ? "es-ES" : "en-Gb", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
            });
            let _hour = new window.ZDate().toLocaleString(lang === "es" ? "es-ES" : "en-Gb", {
                hour: "2-digit",
                minute: "2-digit",
            });
            if (data.getInstallationCodeExpiration.expiration) {
                _date = new window.ZDate(data.getInstallationCodeExpiration.expiration).toLocaleString(
                    lang === "es" ? "es-ES" : "en-Gb",
                    {
                        year: "2-digit",
                        month: "2-digit",
                        day: "2-digit",
                    }
                );
                _hour = new window.ZDate(data.getInstallationCodeExpiration.expiration).toLocaleString(
                    lang === "es" ? "es-ES" : "en-Gb",
                    {
                        hour: "2-digit",
                        minute: "2-digit",
                    }
                );
            }
            // -1 code means code is expired or there is no installation code active
            dispatch(
                setSectionContent({
                    installationCode:
                        data.getInstallationCodeExpiration.code === "-1"
                            ? null
                            : data.getInstallationCodeExpiration.code,
                    expiration_date: _date,
                    expiration_hour: _hour,
                    permanentSocket: data.tvSettings.permanentSocket,
                    statusReport: data.tvSettings.statusReport,
                    loadingCode: false,
                    contentLoaded: true,
                })
            );
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (error) {
            dispatch(setSectionContent({ errorCode: error }));
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        if (!loading) {
            dispatch(setSectionContent({ loadingCode: false }));
        }
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        if (refreshCode) {
            refetch();
            dispatch(setSectionContent({ refreshCode: false }));
        }
        // eslint-disable-next-line
    }, [refreshCode]);
};

export default GetTVSettings;
