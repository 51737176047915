import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setAvailableFilters,
    setColumns,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setFormNewContent,
    setRowGroups,
    setCustomError,
    cleanActiveFilters,
    setRefreshData,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCZTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import GetTableForm from "../../Utils/Table/GetTableForm";
//Model
import Capacity from "./models/Capacity";
import { useTranslation } from "react-i18next";
import { POLLINTERVAL_15S } from "../../Utils/Utils";

const ListCapacity = () => {
    const { t } = useTranslation();
    const {
        currentPage,
        perPage,
        filterQuery,
        sortCriteria,
        paramsQuery,
        refreshData,
        loading: loadingTable,
    } = useSelector((state) => state.table);

    //Data
    const sectionName = "list-capacity";
    let categoriesList = [""];

    //States
    const [sorting] = useState(`,orderBy:{field: "ID", criteria: "desc"}`);

    //States
    const defaultFilter = "currentStatus: true";
    const [filtering, setFiltering] = useState(`,filter:{${defaultFilter}}`);

    //Actions
    const dispatch = useDispatch();

    //API

    const GET_CAPACITY_INFO = gql`{
    capacity_monitor: commonzones(page: ${currentPage}, size: ${perPage}${paramsQuery}${
        sortCriteria ? sortCriteria : sorting
    }${filtering}) {
      info { count }
      results {
        id name parentCategoryName parentCategoryID currentCapacity capacityLimit currentCapacityUpdated isPercentage }}
      cz_categories: cz_categories(page: 1, size: 10000){
        info { count }
        results { id name }}}`;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_CAPACITY_INFO, {
        pollInterval: POLLINTERVAL_15S,
        onCompleted: () => {
            dispatch(setLoading(false));
        },
        notifyOnNetworkStatusChange: "true",
    });

    useEffect(() => {
        let timer;
        if (loadingTable) {
            timer = setTimeout(() => {
                dispatch(setLoading(false));
            }, 2000);
        }

        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [loadingTable]);

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (filterQuery !== "") {
            setFiltering(filterQuery.replace("}", `, ${defaultFilter}}`));
        } else {
            setFiltering(`,filter:{${defaultFilter}}`);
        }
    }, [filterQuery]);

    useEffect(() => {
        if (!loading && !error && data && data.capacity_monitor) {
            //Get data
            const dataItems = arrangeData(data, sortCriteria, categoriesList);
            const tableHeaders = GetTableHeaders(sectionName);
            const availableFilters = GetAvailableFilters(sectionName, categoriesList);
            //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.capacity_monitor.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setFormNewContent(GetTableForm("capacity-edit")));
            dispatch(setCustomError(t("no areas available")));

            //Show row groups if no sort selected
            dispatch(setRowGroups(isRowGroupsVisible(filterQuery, sortCriteria)));
            //Set special column adjusts
            dispatch(setColumns(getSpecialColumns(dataItems)));
            dispatch(setExtendedInfo(false));
            dispatch(showRowForm(false));
            if (filterQuery === "" && paramsQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);
};

const isRowGroupsVisible = (filterQuery, sortCriteria) => {
    //Returns true if group rows must be shown
    let res;
    if (filterQuery.length < 3 && (!sortCriteria || sortCriteria === "")) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

const getSpecialColumns = (dataItems) => {
    //Returns number of w-n/m class column width
    let res;
    if (dataItems.length > 0) {
        //Column adjust if there are cell with only icon on it
        if (dataItems[0].withAction) {
            res = dataItems[0].info.length - 1;
        } else {
            res = dataItems[0].info.length;
        }
    }
    return res;
};

const arrangeData = (data, sortCriteria, categoriesList) => {
    const tempArray = [];
    let categories = [];
    // eslint-disable-next-line
    data.capacity_monitor.results.map((areaCapacity) => {
        const { parentCategoryName } = areaCapacity;
        tempArray.push(Capacity(areaCapacity, categories, sortCriteria));
        categories.push(parentCategoryName);
    });

    //Get categories for filter
    // eslint-disable-next-line
    data.cz_categories.results.map((category) => {
        categoriesList.push([category.name, category.id, "int"]);
    });
    return tempArray;
};

export default ListCapacity;
