import { Session } from "../../../Utils/Session";
const User = (user, t) => {
    console.log(user);

    const currentUserId = Session.getSessionProp("userID");
    const { id, ref, fullName, email, roles, properties, hasCorporate, brands, managerRole, assignedAll } = user;
    const dataType = "user";
    let isEditable = true;
    let sendPassword = true;
    let listRoles = [];
    let listProperties = [];
    let temporalIndex = 0;
    let disableCheckbox = false;
    if (roles.length > 0) {
        roles.forEach((role, index) => {
            // let listAcceses = [`<strong>${capitalizeFirst(langStrings["accesses-text"])}:</strong>`];
            let listAcceses = [`<strong>${t("{{accesses, capitalize}}", { accesses: t("accesses-text") })}:</strong>`];
            role.accesses.map((accesse) => listAcceses.push(accesse.name));
            listRoles.push([index, `${role.name}`, "bg-zafiro-800", listAcceses]);
        });
    }
    if (brands.length > 0) {
        brands.forEach((brand) => {
            listProperties.push([temporalIndex, `${brand.name}`, "bg-orange-100"]);
            temporalIndex++;
        });
    }
    if (properties.length > 0) {
        properties.forEach((property) => {
            listProperties.push([temporalIndex, `${property.name}`, "bg-teal-500"]);
            temporalIndex++;
        });
    }
    if (assignedAll) {
        listProperties = [[1, Session.getSessionProp("chainName"), "bg-purple-600"]];
    }

    if (user.id === parseInt(currentUserId) || managerRole) {
        disableCheckbox = true;
        isEditable = false;
        if (managerRole) {
            sendPassword = false;
        }
    }

    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        executeSectionQuery: true,
        actions: [{ type: dataType, value: id }],
        isEditable: isEditable,
        hasCorporate: hasCorporate,
        sendPassword: sendPassword,
        disableCheckbox: disableCheckbox,
        mutationParams: {
            id: id,
            name: fullName,
            email: email,
            ref: ref,
            roles: roles,
            properties: properties,
            assignedAll: assignedAll,
            brands: brands,
            corporateUser: hasCorporate,
        },
        info: [
            {
                cellContent: "text",
                value: fullName,
                specialWidth: "w-3/12",
                icon: hasCorporate ? "corporate-access" : null,
                iconAdjust: "text-lg",
                // adjust: "inline",
                iconTooltip: "corporate-access",
                tooltip: "",
                wrap: "truncate",
            },

            {
                cellContent: "text",
                value: email,
                adjust: "inline",
                specialWidth: "w-3/12",
                wrap: "truncate",
            },
            /*{
                cellContent: "text",
                value: email,
                specialWidth: "w-3/12",
            },*/
            {
                cellContent: "labelsTruncate",
                value: listProperties,
                specialWidth: "w-5/12",
                cellAdjust: "labels",
            },
            {
                cellContent: "labelsTruncate",
                value: listRoles,
                specialWidth: "w-5/12",
                cellAdjust: "labels",
            },
            /*,
            {
                name: "room-number",
                cellContent: "text",
                value: `55`,
                specialWidth: "w-3/12",
            },
            {
                name: "pms-number",
                cellContent: "text",
                value: `666`,
                specialWidth: "w-2/12",
            },*/
        ],
    };
};

export default User;
