import React from "react";
import { getDateFormated, PriorityOptions, SENT_MSG } from "../../../../hooks/Utils/Customers/MessagesUtils";
import UseEditableField from "../../../useEditableField";
import { useTranslation } from "react-i18next";
import { SessionDateToNew } from "../../../../date";

export const SentMessageDetail = ({ msgData, inEdition, previusSection = SENT_MSG.value }) => {
    const { t } = useTranslation();

    const sentDate = msgData?.sentTime
        ? getDateFormated(msgData.sentTime)
        : t("{{capitalize, capitalize}}", { capitalize: t("unspecified") });
    const _expirationDay = msgData?.expirationTime
        ? new window.ZDate(msgData.expirationTime).toLocaleDateString("en-Gb", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
          })
        : null;
    const _expirationHour = msgData?.expirationTime
        ? new window.ZDate(msgData.expirationTime).toLocaleTimeString("es-ES", {
              hour: "2-digit",
              minute: "2-digit",
          })
        : null;

    const expirationDate = msgData?.expirationTime
        ? getDateFormated(msgData.expirationTime)
        : t("{{capitalize, capitalize}}", { capitalize: t("unspecified") });

    const _sentBy = msgData?.sentBy?.fullName || "";
    const spanSentBy = msgData?.sentBy
        ? ` <span class="text-gray-600">- ${t("{{capitalize, capitalize}}", {
              capitalize: t("sent-by", { name: _sentBy }),
          })} -</span>`
        : "";
    return (
        <div className="bg-white col-span-3 rounded p-6">
            <div className="pb-6 w-4/6">
                <UseEditableField
                    data={{
                        label: "type",
                        labelAdjust: "font-bold text-gray-900",
                        required: inEdition,
                        inEditionMode: inEdition,
                        hideSearch: true,
                        type: "select-with-search",
                        placeholder: "select type",
                        oneSelected: true,
                        optionData: PriorityOptions(t),
                        selectedIds: msgData?.priority ? [msgData.priority.toLowerCase()] : null,
                        noEditionAdjust: "text-lg",
                        adjustContainerOption: "flex-col items-start justify-start",
                        value: msgData?.priority
                            ? [t("{{capitalize, capitalize}}", { capitalize: t(msgData.priority.toLowerCase()) })]
                            : null,
                    }}
                ></UseEditableField>
            </div>
            <UseEditableField
                data={{
                    label: "expiration",
                    labelAdjust: "font-bold text-gray-900",
                    value: expirationDate,
                    date: _expirationDay,
                    hour: _expirationHour,
                    inEditionMode: inEdition,
                    noEditionAdjust: "text-lg pb-6",
                    type: "date&time",
                    minDate: SessionDateToNew(),
                    id: "message-section-expiration",
                }}
            ></UseEditableField>
            {!inEdition && previusSection === SENT_MSG.value && (
                <UseEditableField
                    data={{
                        label: "sent date",
                        labelAdjust: "font-bold text-gray-900",
                        value: sentDate ? sentDate + spanSentBy : null,
                        date: null,
                        hour: null,
                        inEditionMode: inEdition,
                        noEditionAdjust: "text-lg",
                        type: "date&time",
                        id: "message-section-sent-date",
                    }}
                ></UseEditableField>
            )}
        </div>
    );
};
