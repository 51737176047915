import React from "react";
import translate from "../../lang/es/hotel/translate";

const NotFound = (props) => {
    let title = props.title;
    let desc = props.desc;
    if (title === undefined) {
        title = translate("404-err-title");
    }
    if (desc === undefined) {
        desc = translate("404-err-detail");
    }
    return (
        <>
            {title ? <h1 className="text-xl text-red-100 font-bold mb-2">{title}</h1> : ""}
            {desc ? <p>{desc}</p> : ""}
        </>
    );
};

export default NotFound;
