import React, { useContext, useState } from "react";
import { useNavigate as useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { MonitorContext } from "contexts/Sales/Monitor";

export default function OrdersHeadMenu() {
    //Consts&states
    const { t } = useTranslation();

    const { impersonated } = useSelector((state) => state.auth);
    const { warningsMinimized } = useSelector((state) => state.ui);
    const { title } = useContext(MonitorContext);

    const [showTooltip, setShowToltip] = useState(false);
    const uiWarnings = useSelector((state) => state.ui.warnings);
    const history = useHistory();
    const sectionName = `order-monitor-head-menu`;

    //handle & Functions
    const showTooltipFunc = (e) => {
        if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
            setShowToltip(true);
        } else {
            setShowToltip(false);
        }
    };

    //arrangeData

    //Response
    return (
        <div
            className=" fixed left-0 flex w-full h-16 bg-white z-100"
            id={`${sectionName}-mainContainer`}
            style={{
                top: calculateTopStyle({ impersonated, uiWarnings, warningsMinimized }),
            }}
        >
            <div
                id="sales-back-to-shop-list"
                className=" absolute border-r top-0 left-0  flex h-full items-center text-gray-800 hover:text-zafiro-400 mt justify-center border-b  cursor-pointer "
                style={{ width: "13.7rem" }}
                onClick={() => {
                    history("/services/sales/order-summary");
                }}
            >
                <span className=" icon icon-chevron-left mr-3 mt-1 text-lg"></span>
                <span className=" text-lg ">{t("back-to-shop-list")}</span>
            </div>
            <div className=" flex w-full pl-64 h-full items-center justify-between px-4 border ">
                <div className=" flex items-center text-gray-800 justify-center">
                    <div
                        id="sales-name-shop"
                        data-tooltip-id="name-shop-tooltip"
                        onMouseOver={(e) => showTooltipFunc(e)}
                        className=" font-bold mr-4 text-lg truncate"
                        style={{ maxWidth: "16rem" }}
                        data-tip={showTooltip ? title : ""}
                    >
                        {title}
                    </div>
                </div>
            </div>
        </div>
    );
}

function calculateTopStyle({ impersonated, uiWarnings, warningsMinimized }) {
    if (impersonated && !uiWarnings) {
        return "3rem";
    } else if (uiWarnings && !impersonated) {
        return warningsMinimized ? "2rem" : "5rem";
    } else if (uiWarnings && impersonated) {
        return warningsMinimized ? "5rem" : "8rem";
    }
    return "0rem";
}
