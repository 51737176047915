import { SET_ACTIVE_HOTEL, SET_NETWORKS_DATA, CLEAR_PROJECT_REF, SET_PROJECT_REF } from "../actions/hotelActions";
import { Session } from "../hooks/Utils/Session";

const currentHotelData = Session.getSessionProp("currentHotel");
const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};

const initialState = {
    id: currentHotel && currentHotel.id ? currentHotel.id : false,
    name: currentHotel && currentHotel.name ? currentHotel.name : false,
    location: currentHotel && currentHotel.location ? currentHotel.location : false,
    ref: currentHotel && currentHotel.ref ? currentHotel.ref : false,
    networks: currentHotel && currentHotel.networks ? currentHotel.networks : false,
    global: currentHotel && currentHotel.global ? currentHotel.global : false,
    timezone: currentHotel && currentHotel.timezone ? currentHotel.timezone : false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_HOTEL:
            return {
                ...state,
                ...action.payload,
            };
        case SET_NETWORKS_DATA:
            return {
                ...state,
                networks: action.payload,
            };
        case CLEAR_PROJECT_REF:
            return {
                ...state,
                ref: null,
            };
        case SET_PROJECT_REF:
            return {
                ...state,
                ref: action.playload.ref,
                global: action.playload.global,
            };
        default:
            return state;
    }
}
