import React from "react";
import UseCard from "../../../../useCard";
import { useSelector } from "react-redux";

//Components
import UseSectionHeader from "../../../../useSectionHeader";
import UpdateChannels from "../../../../../hooks/GraphqlCalls/Services/UpdateChannels";
import { Session } from "hooks/Utils/Session";

const ChannelsGeneralSettings = () => {
    //Consts&states
    const breadcrumbs = [
        {
            name: "services",
            translate: true,
            route: `/services`,
        },
        {
            name: "tv-channels",
            translate: true,
            route: `/services/tv-channels`,
        },
    ];
    const { permissions } = useSelector((state) => state.ui);
    const checkoutInterfacePerm = permissions?.services?.checkoutInterface;

    //queries&mutations

    //useQueries&useMutations

    //effects

    //arrangeData

    //handle&Functions

    //renders
    return (
        <>
            <UseSectionHeader title={["general-settings"]} customBreadCrumbs={breadcrumbs} />
            <UpdateChannels />
            <div className="flex w-full">
                <div className="flex w-full">
                    <div className="w-6/12">
                        <div className="w-full h-48 mb-10 pr-2">
                            <UseCard type="channels-general-settings-remember-last" adjust="h-full" />
                        </div>
                        <div className="w-full mb-10 pr-2">
                            <UseCard type="high-availability" />
                        </div>
                    </div>
                    {checkoutInterfacePerm && (
                        <div className="w-6/12">
                            <div className="w-full h-48 mb-10 pr-2">
                                <UseCard type="always-display-tv" adjust="h-full" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default ChannelsGeneralSettings;
