import React from "react";
import { useSelector } from "react-redux";
//Components
import UseNetworkCard from "./useNetworkCard";

const UseNetworkGrid = ({ gridStyles }) => {
    //Store data
    const { networks } = useSelector((state) => state.hotel);
    return (
        <>
            {networks ? (
                <div className={gridStyles}>
                    {networks.map((network) => (
                        <UseNetworkCard network={network} key={network.id} />
                    ))}
                </div>
            ) : null}
        </>
    );
};

export default UseNetworkGrid;
