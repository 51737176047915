import React, { useEffect, useState, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setExecuteQuery } from "../../../../../../../actions/sectionActions";
import {
    UPDATE_ENABLED_ALLERGENS,
    UPDATE_PRODUCT_ALLERGENS_AND_LABELS,
} from "../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { executeVendureQuery, getVendureApiData } from "../../../../../../../hooks/Utils/Integrations/useVendure";
import { allergensPrefix, labelsPrefix } from "../../../../../../../hooks/Utils/SalesUtils";
import UseSelectWithSearch from "../../../../../../useSelectWithSearch";
import { Session } from "../../../../../../../hooks/Utils/Session";
import ReactTooltip from "components/ReactTooltip";
import UseSwitch from "../../../../../../Inputs/useSwitch";
import { SalesContextDeprecated } from "contexts/Sales";

function ProductSettingsAllergensCard({ data }) {
    const dispatch = useDispatch();

    //Consts&states
    const { productId, shopId } = data;
    const { t } = useTranslation();
    const actionData = useSelector((state) => state.action);
    const { lang } = useSelector((state) => state.ui);
    const { data: dataContext, loadingContext } = useContext(SalesContextDeprecated);
    const { executeQuery, refreshData } = useSelector((state) => state.sectionContent);
    const [loading, setLoading] = useState(true);
    const [allergensSelected, setAllergensSelected] = useState(null);
    const [labelsSelected, setLabelsSelected] = useState(null);
    const [otherFacetValuesSelected, setOtherFacetValuesSelected] = useState(null);
    const [allergens, setAllergens] = useState(null);
    const [enabledAllergens, setEnabledAllergens] = useState({ id: null, val: false });
    const [labels, setLabels] = useState(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const firstRenderWithData = useRef(false);
    const sectionName = "product-settings-allergens";
    const inputsName = [`${sectionName}-allergens`, `${sectionName}-labels`];
    const labelsSelectedStorage = actionData?.values?.["product-settings-allergens-labels"] || [];

    //queries&mutations
    useEffect(() => {
        if (dataContext?.allergens && dataContext?.labels && dataContext?.channel) {
            dataSetter(dataContext);
        }
    }, [dataContext]);

    useEffect(() => {
        if (loading && !refreshData) {
            if (!initialLoad) {
                setLoading(false);
                setLabelsSelected(labelsSelectedStorage);
            } else {
                setInitialLoad(false);
            }
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!refreshData) {
            if (executeQuery?.action && executeQuery.action === UPDATE_PRODUCT_ALLERGENS_AND_LABELS) {
                setLoading(false);
            }
        }
        // eslint-disable-next-line
    }, [refreshData]);

    //arrangeData
    const arrangeData = (data) => {
        let allergens = [];
        let labels = [];
        let allergensSelected = [];
        let labelsSelected = [];
        let otherFacetValuesSelected = [];

        if (data) {
            let facet = null;
            let channelToken = data.channel ? data.channel.token : null;
            const labelsCode = `${labelsPrefix}_${channelToken}`;
            if (data?.product?.facetValues && data.product.facetValues.length > 0) {
                data.product.facetValues.forEach((facetValue) => {
                    if (facetValue?.facet?.code === allergensPrefix) {
                        allergensSelected.push(facetValue.id);
                    } else if (facetValue?.facet?.code === labelsCode) {
                        labelsSelected.push(facetValue.id);
                    } else {
                        otherFacetValuesSelected.push(facetValue.id);
                    }
                });
            }
            if (data?.allergens?.items && data.allergens.items.length > 0) {
                facet = data.allergens.items[0];
                if (facet?.values && facet.values.length > 0) {
                    facet.values.forEach((item) => {
                        allergens.push({
                            id: item.id,
                            name: getFacetName(item.translations, lang),
                            selected: allergensSelected.includes(item.id) ? true : false,
                        });
                    });
                }
            }

            if (data?.productVariants?.items.length > 0) {
                const id = data?.productVariants?.items?.[0]?.id;
                const enabledAllergens = data?.productVariants?.items?.[0]?.customFields?.are_allergens_enabled
                    ? true
                    : false;
                setEnabledAllergens({ id: id, val: enabledAllergens });
            }

            if (data?.labels?.items && data.labels.items.length > 0) {
                facet = data.labels.items.filter((item) => item.code === labelsCode);
                if (facet && facet.length > 0 && facet[0].values && facet[0].values.length > 0) {
                    facet[0].values.forEach((item) => {
                        labels.push({
                            id: item.id,
                            name: getFacetName(item.translations, lang),
                            selected: labelsSelected.includes(item.id) ? true : false,
                        });
                    });
                }
            }
        }
        return { allergens, labels, allergensSelected, labelsSelected, otherFacetValuesSelected };
    };

    const dataSetter = (data) => {
        let allergens = [];
        let labels = [];
        let allergensSelecetd = [];
        let labelsSelected = [];
        let otherFacetValuesSelected = [];
        setLoading(true);
        if (data) {
            const {
                allergens: allergensList,
                labels: labelsList,
                allergensSelected: allergensSelectedList,
                labelsSelected: labelsSelectedList,
                otherFacetValuesSelected: otherFacetValuesSelectedList,
            } = arrangeData(data);
            allergens = allergensList;
            labels = labelsList;
            labelsSelected = labelsSelectedList;
            allergensSelecetd = allergensSelectedList;
            otherFacetValuesSelected = otherFacetValuesSelectedList;
        }
        Session.setSessionProp("updateSelectOptions", inputsName.join(","));
        setAllergens(allergens);
        setLabels(labels);
        setLabelsSelected(labelsSelected);
        setAllergensSelected(allergensSelecetd);
        setOtherFacetValuesSelected(otherFacetValuesSelected);
        setLoading(false);
    };

    const getFacetName = (translations, defaultLang) => {
        let response = "";
        if (defaultLang && translations && translations.length > 0) {
            let translation = translations.filter(
                (translationData) => translationData.languageCode && translationData.languageCode === defaultLang
            );
            response = translation && translation.length > 0 ? translation[0].name : "";
            if (!response) response = translations[0].name;
        }
        return response;
    };
    const updateAllergensAndLabels = (removeFacets) => {
        let facetValueIds = otherFacetValuesSelected ? otherFacetValuesSelected : [];

        let allergensSelectedValues = actionData.values[`${inputsName[0]}-name`];
        let labelsSelectedValues = actionData.values[`${inputsName[1]}-name`];
        if (allergensSelectedValues) facetValueIds = facetValueIds.concat(allergensSelectedValues);
        else facetValueIds = facetValueIds.concat(allergensSelected);
        if (labelsSelectedValues) facetValueIds = facetValueIds.concat(labelsSelectedValues);
        else facetValueIds = facetValueIds.concat(labelsSelected);
        if (removeFacets) {
            facetValueIds = facetValueIds.filter(function (facet) {
                return removeFacets.indexOf(facet) < 0;
            });
        }
        setLoading(true);
        dispatch(
            setExecuteQuery({
                action: UPDATE_PRODUCT_ALLERGENS_AND_LABELS,
                params: {
                    id: productId,
                    facetValueIds: facetValueIds,
                },
            })
        );
    };
    const handleCloseSelect = (isVisible, disabled) => {
        if (!loading && !isVisible && !disabled) {
            updateAllergensAndLabels();
        }
    };
    const handleRemoveOption = (value) => {
        if (!loading) {
            updateAllergensAndLabels([value]);
        }
    };

    //response
    return (
        <div className="w-full my-2">
            <div className="w-full text-left mb-2 flex items-center ">
                <span className="font-bold text-gray-900 text-xl">{t("allergens")}</span>

                <UseSwitch
                    adjust={!enabledAllergens?.id ? "hidden" : ""}
                    checked={enabledAllergens.val}
                    disabled={loading}
                    action={() => {
                        dispatch(
                            setExecuteQuery({
                                action: UPDATE_ENABLED_ALLERGENS,
                                params: {
                                    enabled: !enabledAllergens.val,
                                    id: enabledAllergens.id,
                                },
                            })
                        );
                        setLoading(true);
                        setEnabledAllergens({ ...enabledAllergens, val: !enabledAllergens });
                    }}
                />
            </div>
            <div className="w-full text-left mb-5">
                <span className="block mb-3">{t("select-product-allergens")}</span>
                <UseSelectWithSearch
                    data={{
                        disabled: loading,
                        id: `${inputsName[0]}-id`,
                        name: `${inputsName[0]}-name`,
                        optionData: allergens,
                        selectedIds: allergensSelected,
                        selectPlaceHolder: "select-allergens",
                        noSelect: true,
                        noShowSelectedOptionsInDropDownSection: true,
                        showSelectdOptionsInFooterSection: true,
                        selectedTextAdjust: "hidden",
                        optionsAdjust: "mt-11",
                        nonInitialOptionData: true,
                        onChangeComponentVisible: (value) => {
                            if (dataContext && firstRenderWithData.current) {
                                handleCloseSelect(value, loading);
                            }
                            if (dataContext && !firstRenderWithData.current) {
                                firstRenderWithData.current = true;
                            }
                        },
                        onRemoveOption: (value) => {
                            handleRemoveOption(value);
                        },
                        useOptionID: false,
                    }}
                />
            </div>
            <div className="w-full text-left">
                <span className="font-bold text-gray-900 text-xl block mb-2">
                    {t("{{capitalize, capitalize}}", {
                        capitalize: t("labels"),
                    })}
                </span>
                <div className=" mb-3">
                    {t("select-labels-like-show-product-details")}
                    <span
                        data-tip={t("each-product-can-have-a-maxium-labels", { number: 2 })}
                        className="ml-3 relative inline-block text-2xl text-gray-700"
                    >
                        <div
                            className=" icon icon-info "
                            style={{ position: "absolute", top: "-15px", left: "0" }}
                        ></div>
                    </span>
                </div>
                <UseSelectWithSearch
                    data={{
                        disabled: loading || (labelsSelected && labelsSelected.length >= 2),
                        id: `${inputsName[1]}-id`,
                        name: `${inputsName[1]}-name`,
                        optionData: labels,
                        selectedIds: labelsSelected,
                        selectPlaceHolder: "select-labels",
                        noSelect: true,
                        noShowSelectedOptionsInDropDownSection: true,
                        optionsAdjust: "mt-11",
                        showSelectdOptionsInFooterSection: true,
                        selectedTextAdjust: "hidden",
                        nonInitialOptionData: true,
                        onChangeComponentVisible: (value) => {
                            if (dataContext && firstRenderWithData.current) {
                                console.log(firstRenderWithData.current);
                                handleCloseSelect(value, loading || (labelsSelected && labelsSelected.length >= 2));
                            }
                            if (dataContext && !firstRenderWithData.current) {
                                firstRenderWithData.current = true;
                            }
                        },
                        onRemoveOption: (value) => {
                            handleRemoveOption(value);
                        },
                    }}
                />
            </div>
            <ReactTooltip
                place="bottom"
                type="light"
                offset={{ top: -8, left: -8 }}
                html={true}
                border={true}
                borderColor="#D3DAE1"
            />
        </div>
    );
}

export default ProductSettingsAllergensCard;
