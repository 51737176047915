import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { POLLINTERVAL_15S, capitalizeFirst } from "../../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import {
    executeVendureQuery,
    getVendureApiData,
    getVendureShopApiData,
} from "../../../../../hooks/Utils/Integrations/useVendure";
import {
    GET_VENDURE_ORDER,
    getDeliveryDetails,
    getOrderProductsInfo,
    GET_VENDURE_ORDER_SHOP_API,
} from "./GuestOrdersUtils";
import UseLoading from "../../../../Table/useLoading";

import {
    DELIVERY_METHOD_ROOM,
    DELIVERY_METHOD_PICKUP,
    DELIVERY_METHOD_GEOLOCATION,
    getDeliveryMethodIcon,
    useDeliveryLocationInfo,
} from "constants/sales";

const OrderPreview = () => {
    const [dataOrder, setDataOrder] = useState(null);
    const [loading, setLoading] = useState(true);
    const [comments, setComments] = useState("");
    const [productsInfo, setProductsInfo] = useState([]);
    const [pickupLocation, setPickupLocation] = useState(null);
    const { id } = useParams();

    const { t } = useTranslation();

    const getDeliveryLocationInfo = useDeliveryLocationInfo();

    const GET_GUEST_ORDERS = gql(`query {
        orderHistory(
          languageRef: "es"
          filter: {
            ID:${id}
          }
        ) {
          results{
            id
          roomName
          origRoomName
          customerName
          date
          total
          totalWithTax
          deliveryMethod
          deliveryLocation
          state
          schedule {
            end
            start
          }
          delayed
          shop {
            id
            token
            name 
          }
          }
        }
      }`);

    const GET_ORDER_SHOP_API = GET_VENDURE_ORDER_SHOP_API(id);

    const GET_ORDER = GET_VENDURE_ORDER(id);

    const [getGuestOrders, { data }] = useLazyQuery(GET_GUEST_ORDERS, {
        fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    const getVendureOrderInfo = async () => {
        const response = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: GET_ORDER },
            { "vendure-token": dataOrder?.shop?.token }
        );

        const responserOrderShopApi = await executeVendureQuery(
            getVendureShopApiData(),
            { queryBody: GET_ORDER_SHOP_API },
            { "vendure-token": dataOrder?.shop?.token }
        );

        if (response?.data && !response.errors) {
            const { order } = response.data;

            if (order) {
                setPickupLocation(getDeliveryLocationInfo(dataOrder?.deliveryMethod, dataOrder?.deliveryLocation));
                const comments = order.history.items
                    .filter((i) => i.data?.note)
                    .map((i) => i.data.note)
                    .join("\n");

                setLoading(false);
                setProductsInfo(getOrderProductsInfo(response, responserOrderShopApi));
                setComments(comments);
            }
        }
    };

    useEffect(() => {
        getGuestOrders();
    }, []);

    useEffect(() => {
        const result = data?.orderHistory?.results?.[0];
        if (result) {
            setDataOrder(result);
        }
    }, [data]);

    useEffect(() => {
        if (dataOrder) {
            getVendureOrderInfo();
        }
    }, [dataOrder]);

    const deliveryIcon = getDeliveryMethodIcon(dataOrder?.deliveryMethod);

    return (
        <div className=" bg-gray-100 h-full flex items-center justify-center w-full">
            <div style={{ minWidth: "40rem", maxWidth: "40rem" }} className=" px-14 py-10 bg-white">
                <div id="preview-shop-name" className=" pb-8 text-center text-xl font-bold">
                    {dataOrder?.shop?.name ? dataOrder.shop.name : ""}
                </div>
                <div className=" mt-2 mb-4">
                    <div className=" font-bold border-b  pb-2 border-gray-200 text-base mb-4">
                        {capitalizeFirst(t("information"))}
                    </div>
                    <div className=" flex items-center mb-4">
                        <i className=" icon icon-user-regular text-2xl text-gray-800 mr-4"></i>
                        <div id="preview-order-customer-name">
                            {dataOrder?.customerName ? dataOrder.customerName : ""}
                        </div>
                    </div>
                    <div className=" flex items-center mb-4 ">
                        <i className=" icon icon-rooms text-2xl text-gray-800 mr-4"></i>
                        <div id="preview-order-room-name">{dataOrder?.roomName ? dataOrder.roomName : ""}</div>
                    </div>
                    <div className=" flex items-center mb-4 ">
                        <i className={` icon icon-${deliveryIcon} text-2xl text-gray-800 mr-4`}></i>
                        <div>
                            {!getDeliveryDetails(dataOrder) ? (
                                <div className=" flex items-center">
                                    <p>{t("as-soon-as-possible")}</p>
                                    {pickupLocation?.place ? (
                                        <>
                                            <p className=" block mx-2">-</p>
                                            <p id="preview-order-pickup-location">{pickupLocation?.place}</p>
                                        </>
                                    ) : null}
                                    <p className=" block mx-2">-</p>
                                    <p id="preview-order-date">{new Date(dataOrder?.date).toLocaleDateString()}</p>
                                </div>
                            ) : (
                                <div className=" flex items-center">
                                    <div>
                                        <p id="preview-delivery-title">{getDeliveryDetails(dataOrder).firstTitle}</p>
                                    </div>
                                    <p className=" block mx-2">-</p>
                                    <div>
                                        <p id="preview-order-date">{new Date(dataOrder?.date).toLocaleDateString()}</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className=" mb-4">
                    <div className=" font-bold text-base border-b border-gray-200 pb-2">
                        {capitalizeFirst(t("order-summary"))}
                    </div>
                    <div>
                        {productsInfo.map((p, idxProduct) => {
                            return (
                                <div key={p.id} className=" mb-6 flex flex-col">
                                    <div>
                                        <span
                                            id={`preview-product${idxProduct}-name`}
                                            className=" inline-block mr-1"
                                        >{`${p.title}`}</span>
                                    </div>
                                    {p.extras.length > 0 && (
                                        <>
                                            <div className=" font-bold pl-6">Extras</div>
                                            {p.extras.map((pe, idxExtra) => {
                                                return (
                                                    <div
                                                        key={pe.name}
                                                        id={`preview-extra-name-product${idxProduct}-extra${idxExtra}`}
                                                        className=" pl-6"
                                                    >{`${pe.name}`}</div>
                                                );
                                            })}
                                        </>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className=" mb-4">
                    <div className=" font-bold text-base border-b border-gray-200 pb-2">
                        {capitalizeFirst(t("comments"))}
                    </div>
                    <p id="preview-comments">{comments}</p>
                </div>
            </div>
            {loading ? (
                <div className=" flex items-center justify-center absolute h-full w-full  bg-white top-0 left-0">
                    <UseLoading adjust={"contents"} />
                </div>
            ) : null}
        </div>
    );
};

export default OrderPreview;
