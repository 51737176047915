import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useComponentVisible from "../useComponentVisible";
import UseSelectOption from "./useSelectOption";
import { changeActionValues, setActionInput } from "../../actions/globalActions";
import { useTranslation } from "react-i18next";
import { capitalizeFirst, multipleTranslate } from "../../hooks/Utils/Utils";
import ReactTooltip from "components/ReactTooltip";
import { useLazyQuery } from "react-apollo";
import {
    getTvScreensLandings,
    getScreensContentTree,
    SCREEN,
    buildQueryMenusAndHomeData,
} from "../../hooks/Utils/Design/EditorUtils";
import { setTvScreensData, setPreviewMenuIdSelected } from "../../actions/designActions";
import { useParams } from "react-router-dom";
import { gql } from "apollo-boost";

const UseSelectPreviewScreen = ({ selectData, enabled = true, menuPreview = true }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id: idDesign } = useParams();
    const { validations } = useSelector((state) => state.action);
    const { cScreenData, previewMenuIdSelected } = useSelector((state) => state.design);
    const {
        id,
        name,
        containerOptionsAdjust,
        label,
        labelStyle,
        placeHolder,
        noTranslateAV,
        useActionInput,
        inputBgColor,
        inputActiveTextColor,
        inputActiveBgColor,
        inputHeight,
        arrowIconSize,
        adjustActiveValueContainer,
        containerOptionsStyles,
        containerSelecOptionStyle,
    } = selectData;
    const [activeValue, setActiveValue] = useState(selectData.activeValue);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [selectActive, setSelectActive] = useState({});
    const [passValidation, setPassValidation] = useState(true);
    const [showHomeMenu, setShowHomeMenu] = useState(null);
    const [isUsingHomeBg, setIsUsingHomeBg] = useState(null);
    const [screensTVData, setScreensTVData] = useState([]);
    const [screensTVDataMenus, setScreensTVDataMenus] = useState([]);
    const [extraInfo, setExtraInfo] = useState(["modified-background"]);
    const [queryMenusData, setQueryMenusData] = useState(`
    {
        contentInterface(designID: 0, id: 0) {
            menu {
                data
            }
            style
        }
    }`);
    const GET_SCREENS = gql`
        ${getScreensContentTree(idDesign)}
    `;

    const GET_MENUS_DATA = gql`
        ${queryMenusData}
    `;

    useEffect(() => {
        if (menuPreview) {
            setExtraInfo([...extraInfo, "disabled-menu"]);
        }
        // eslint-disable-next-line
    }, [menuPreview]);

    const [executeQuery, { data: screensData, loading }] = useLazyQuery(GET_SCREENS, { fetchPolicy: "network-only" });

    const [executeQueryMenusData, { data: screensDataMenus, loading: loadingDataMenus }] = useLazyQuery(
        GET_MENUS_DATA,
        {
            fetchPolicy: "network-only",
        }
    );

    useEffect(() => {
        executeQuery();

        return () => {
            dispatch(setPreviewMenuIdSelected(null));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (screensData && !loading && screensData?.contentTree?.screens?.categories) {
            const linkedScreens = cScreenData?.data?.linkedFrom ? cScreenData.data.linkedFrom : null;
            const screensTV = getTvScreensLandings(screensData, SCREEN.TYPE.TV, linkedScreens);
            setScreensTVData(screensTV);
            dispatch(setTvScreensData(screensTV));
        }
        // eslint-disable-next-line
    }, [screensData, loading, cScreenData]);

    useEffect(() => {
        if (screensTVData && screensTVData instanceof Array && screensTVData.length > 0) {
            setQueryMenusData(buildQueryMenusAndHomeData(screensTVData, idDesign));
            executeQueryMenusData();
        }
        // eslint-disable-next-line
    }, [screensTVData]);

    useEffect(() => {
        if (screensDataMenus && !loadingDataMenus) {
            const arrData = arrangeScreensTVDataMenus({ screensDataMenus, screensTVData, t });
            setScreensTVDataMenus(arrData);
        }
        // eslint-disable-next-line
    }, [screensDataMenus, loadingDataMenus]);

    //Actions
    useEffect(() => {
        const dataScreen = screensTVDataMenus.find((item) => {
            return Number(item.id) === Number(activeValue);
        });
        const dataToAction = { [id]: activeValue, bgStyles: dataScreen?.homeBgStyles };
        if (useActionInput) {
            dispatch(setActionInput(dataToAction));
        } else {
            dispatch(changeActionValues(dataToAction));
        }
        // eslint-disable-next-line
    }, [activeValue]);

    const handleChangeFilters = (e) => {
        setActiveValue(e.currentTarget.id);
        setIsComponentVisible(false);
    };

    useEffect(() => {
        if (typeof validations[id] !== "undefined") {
            setPassValidation(validations[id]);
        } else {
            setPassValidation(true);
        }
        // eslint-disable-next-line
    }, [validations]);

    useEffect(() => {
        if (cScreenData && cScreenData.contentStyle) {
            if (cScreenData.contentStyle.showHomeMenu) {
                setShowHomeMenu(true);
            } else {
                setShowHomeMenu(false);
            }
        }
    }, [cScreenData, activeValue]);

    useEffect(() => {
        if (cScreenData && cScreenData.contentStyle) {
            if (cScreenData.contentStyle.useHomeBackground) {
                setIsUsingHomeBg(true);
            } else {
                setIsUsingHomeBg(false);
            }
        }
    }, [cScreenData, activeValue]);

    //Listeners
    useLayoutEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({});
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    useEffect(() => {
        setActiveValue(previewMenuIdSelected);
    }, [previewMenuIdSelected]);

    const printActiveValue = () => {
        let _text = "";
        screensTVDataMenus.forEach((element) => {
            if (element.value === activeValue) {
                _text = element.text;
            }
        });
        return noTranslateAV ? _text : t(_text);
    };

    const printPlaceHolder = () => {
        let _text = "";
        if (placeHolder) {
            _text = t(placeHolder);
        } else {
            _text = t(name);
        }
        return _text;
    };

    return (
        <>
            {label ? (
                <span className={labelStyle ? labelStyle : "block pb-2 first-capital"}>
                    {multipleTranslate(label, t)}
                </span>
            ) : null}
            <div
                ref={ref}
                className={`w-full mt-1 h-8 rounded relative 
                    ${!passValidation ? "border border-red-100" : ""}   
                    ${inputBgColor ? inputBgColor : "bg-gray-200"} 
                    ${inputHeight ? inputHeight : "h-10"}
                    ${inputActiveBgColor ? inputActiveBgColor : selectActive.container}
                    `}
            >
                <div
                    className={`t-filter-ph ${selectActive.text} ${!enabled ? "disabled" : "text-gray-900"}`}
                    onClick={enabled ? () => setIsComponentVisible(!isComponentVisible ? true : false) : undefined}
                >
                    <div
                        className={`first-capital text-left 
                        ${adjustActiveValueContainer ? adjustActiveValueContainer : ""}
                        ${!activeValue && !inputActiveTextColor ? "text-gray-600" : ""} 
                        ${isComponentVisible && inputActiveTextColor ? inputActiveTextColor : ""}`}
                    >
                        {!activeValue ? printPlaceHolder() : printActiveValue()}
                        {(showHomeMenu && !isUsingHomeBg) ||
                        (isUsingHomeBg && !showHomeMenu) ||
                        (!isUsingHomeBg && !showHomeMenu) ? (
                            <span> *</span>
                        ) : null}
                    </div>
                </div>
                <span
                    className={`field-icon icon-chevron animated ${selectActive.icon} 
                    ${!enabled ? "disabled" : "text-gray-900"}
                     ${arrowIconSize ? arrowIconSize : ""}
                    `}
                    onClick={enabled ? () => setIsComponentVisible(!isComponentVisible ? true : false) : undefined}
                ></span>
                {isComponentVisible ? (
                    <div
                        className={`t-options-container p-2 ${containerOptionsAdjust}`}
                        style={
                            containerOptionsStyles && typeof containerOptionsStyles === "object"
                                ? containerOptionsStyles
                                : {}
                        }
                    >
                        {(showHomeMenu && !isUsingHomeBg) ||
                        (isUsingHomeBg && !showHomeMenu) ||
                        (!isUsingHomeBg && !showHomeMenu) ? (
                            <div className=" px-4 py-2 mb-2 text-base flex italic text-gray-600 border-b flex-col items-start">
                                {extraInfo.map((info) => {
                                    if (
                                        (showHomeMenu && info === "disabled-menu") ||
                                        (isUsingHomeBg && info === "modified-background")
                                    ) {
                                        return null;
                                    } else {
                                        return <span key={info}>*{capitalizeFirst(t(info))}</span>;
                                    }
                                })}
                            </div>
                        ) : null}
                        {screensTVDataMenus.map((option, index) => (
                            <div
                                className={`${
                                    containerSelecOptionStyle ? containerSelecOptionStyle : " h-10"
                                } hover:bg-gray-100 relative `}
                                onClick={handleChangeFilters}
                                id={option.value}
                                key={option.name}
                            >
                                <UseSelectOption
                                    selectName={name}
                                    value={option.text}
                                    translatable={false}
                                    selected={activeValue}
                                />
                                {option && option.subtext && menuPreview ? (
                                    <div
                                        className=" text-sm pl-2 pb-2 text-gray-500 cursor-pointer"
                                        style={{ marginTop: "-8px" }}
                                    >
                                        {option.subtext}
                                    </div>
                                ) : null}
                                {option.icon && option.icon.showIcon ? (
                                    <div
                                        data-tip={t(option.icon.tooltip ? option.icon.tooltip : "")}
                                        className={`icon ${
                                            option.icon.nameIcon ? option.icon.nameIcon : ""
                                        } text-2xl text-gray-800 cursor-help absolute`}
                                        style={{ top: "0.50rem", left: "0rem" }}
                                    ></div>
                                ) : null}
                            </div>
                        ))}
                        <ReactTooltip
                            place="bottom"
                            type="light"
                            offset={{ top: -8, left: -8 }}
                            html={true}
                            border={true}
                            multiline={true}
                            borderColor="#D3DAE1"
                        />
                    </div>
                ) : null}
            </div>
        </>
    );
};

const arrangeScreensTVDataMenus = (props) => {
    const { screensDataMenus, screensTVData, t } = props;

    const arrayDataMenus = Object.entries(screensDataMenus).map((e) => ({
        [e[0].replace("menuHomeBg", "")]: e[1],
    }));
    const objectDataMenus = arrayDataMenus.reduce((result, item) => {
        let key = Object.keys(item)[0];
        result[key] = item[key];
        return result;
    }, {});

    const arrData = screensTVData.map((screen) => {
        const menu = objectDataMenus[String(screen.id)] ? objectDataMenus[String(screen.id)] : null;
        if (menu && menu.menu && menu.menu.data) {
            const menuData = JSON.parse(menu.menu.data);
            const menuType = menuData.menuType ? menuData.menuType : "";
            let menuTypeText = "";
            if (menuType === "fixed-menu") {
                menuTypeText = "fixed";
            } else if (menuType === "hidden-menu") {
                menuTypeText = "hidden";
            } else {
                menuTypeText = "none";
            }
            screen.subtext = capitalizeFirst(t("type-of-menu")) + ": " + capitalizeFirst(t(menuTypeText));
        } else {
            screen.subtext = capitalizeFirst(t("type-of-menu")) + ": " + capitalizeFirst(t("none"));
        }
        screen.homeBgStyles = menu && menu.style ? JSON.parse(JSON.stringify(menu.style)) : null;
        screen.icon = { showIcon: screen.isLinked, tooltip: "linked-screen", nameIcon: "icon-action" };
        return screen;
    });

    return arrData;
};

export default UseSelectPreviewScreen;
