import React, { useState, useEffect } from "react";
import ReactInput from "react-currency-input-field";

import { currenciesSymbolPlaceBefore, getSymbolCurrency, nonDecimalCurrencies } from "../../hooks/Utils/SalesUtils";

const DECIMAL_PLACES = 2;

/**
 * @description
 * @component
 * @param {string} id - ID for the input
 * @param {number} value - Initial value
 * @param {boolean} disabled - Disable the input
 * @param {string} currencyCode - Currency code
 * @param {string} className - Additional classes
 * @param {number} minValue - Minimum value
 * @param {number} maxValue - Maximum value
 * @param {function} onChange - Function to call when the value changes
 * @returns {JSX.Element} - Currency input component
 * @example
 * <CurrencyInput id="input-id" value={0} disabled={false} currencyCode="USD" className="w-1/2" minValue={0} maxValue={1000} onChange={(value) => console.log(value)} />
 */
const CurrencyInput = ({
    id,
    value: initialValue,
    disabled,
    currencyCode,
    className,
    minValue,
    maxValue,
    onChange,
}) => {
    const [value, setValue] = useState(formatValue(initialValue) ?? null);
    const [floatValue, setFloatValue] = useState(initialValue ?? null);

    const currencySymbol = getSymbolCurrency({ currency_code: currencyCode });
    const isSuffix = currencyIsSuffix(currencyCode);
    const allowDecimals = currencyAllowDecimals(currencyCode);
    const stepValue = allowDecimals ? 0.1 : 1;

    useEffect(() => {
        if (initialValue !== floatValue) {
            setValue(formatValue(initialValue));
            setFloatValue(initialValue);
        }
    }, [initialValue]);

    const sumValue = (newValue) => {
        const newVal = Math.round((floatValue + newValue) * 100) / 100;
        setFloatValue(newVal);
        setValue(formatValue(newVal));
        if (onChange) {
            onChange(newVal);
        }
    };

    const fgStyle = `${disabled ? "text-gray-700" : "text-gray-900"}`;
    const bgStyle = `${disabled ? "bg-gray-300" : "bg-gray-200"}`;

    return (
        <div className={`flex items-center ${className || ""}`}>
            <div className="relative">
                <ReactInput
                    id={id ? `${id}-input` : null}
                    className={`${fgStyle} ${bgStyle} py-1 w-full rounded ${
                        isSuffix ? "px-3" : "pl-6 pr-3"
                    } disable-browser-buttons-numbers`}
                    disabled={disabled}
                    value={value}
                    defaultValue={initialValue}
                    min={minValue ?? 0}
                    max={maxValue ?? 1000}
                    allowNegativeValue={false}
                    allowDecimals={allowDecimals}
                    decimalsLimit={allowDecimals ? DECIMAL_PLACES : 0}
                    decimalScale={allowDecimals ? DECIMAL_PLACES : 0}
                    disableGroupSeparators={true}
                    onValueChange={(_value, _name, _values) => {
                        setValue(_value);
                        setFloatValue(_values?.float);
                        if (onChange) {
                            onChange(_values?.float);
                        }
                    }}
                />
                <div
                    id={id ? `${id}-symbol` : null}
                    className={`${fgStyle} absolute h-full top-0 ${isSuffix ? "right-0 pr-2" : "left-0 pl-2"}`}
                >
                    <span className="flex h-full items-center">{currencySymbol}</span>
                </div>{" "}
            </div>
            <div
                className={`flex flex-col ml-2 items-center justify-items-center ${
                    disabled ? "text-gray-400" : "text-gray-900"
                }`}
            >
                <span
                    id={id ? `${id}-inc` : null}
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        sumValue(stepValue);
                    }}
                    className={`icon text-sm mb-1 icon-chevron-up ${disabled ? "" : "cursor-pointer"}`}
                ></span>
                <span
                    id={id ? `${id}-dec` : null}
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        sumValue(stepValue * -1);
                    }}
                    className={`icon text-sm mt-1 icon-chevron ${disabled ? "" : "cursor-pointer"}`}
                ></span>
            </div>
        </div>
    );
};

const formatValue = (v) => (v !== null ? v.toFixed(DECIMAL_PLACES) : null);

const currencyAllowDecimals = (currencyCode) =>
    nonDecimalCurrencies ? !nonDecimalCurrencies.includes(currencyCode) : true;

const currencyIsSuffix = (currencyCode) =>
    currenciesSymbolPlaceBefore ? !currenciesSymbolPlaceBefore.includes(currencyCode) : true;

export default CurrencyInput;
