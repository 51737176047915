import { ComposeGridLayout } from "../../reducers/reducerDesign";
import { DEVICE, SCREEN } from "./Design/EditorUtils";
import i18next from "i18next";

export const TV_RATIO = [16, 9];
export const MOBILE_RATIO = [9, 16];

export const WIDGETS = {
    ALARM: {
        name: "alarm",
        icon: "alarm",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: false,
        availability: {
            screenTypes: [SCREEN.TYPE.GENERAL, SCREEN.TYPE.TV],
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: true,
        },
    },
    BUTTON: {
        name: "button",
        icon: "button-widget",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) =>
                    ![
                        SCREEN.TYPE.WELCOME,
                        SCREEN.TYPE.CCINSTRUCTION,
                        SCREEN.TYPE.CCPAIRINGSUCCESSFUL,
                        SCREEN.TYPE.PORTAL,
                    ].includes(t)
            ),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    CALENDAR: {
        name: "calendar",
        icon: "booking",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) => ![SCREEN.TYPE.PORTAL, SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL].includes(t)
            ),
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: false,
        },
    },
    CAROUSEL: {
        name: "carousel",
        icon: "images",
        hidden: process.env.REACT_APP_DEVELOPMENT !== "true",
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    CHATBUTTON: {
        name: "chat",
        icon: "language",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: [SCREEN.TYPE.GENERAL, SCREEN.TYPE.MOBILE],
            deviceTypes: [DEVICE.TYPE.MOBILE],
            tvMenu: false,
        },
    },
    CLOCK: {
        name: "clock",
        icon: "clock",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) => ![SCREEN.TYPE.PORTAL, SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL].includes(t)
            ),
            deviceTypes: [DEVICE.TYPE.TV],
            tvMenu: true,
        },
    },
    CONTAINER: {
        name: "container",
        icon: "checkbox-unchecked",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    HTML: {
        name: "HTML",
        icon: "embed2",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    ICON: {
        name: "icon",
        icon: "add_circle",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    IFRAME: {
        name: "iframe",
        icon: "embed",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    IMAGE: {
        name: "image",
        icon: "image",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: true,
        },
    },
    LANGSELECTION: {
        name: "langselector",
        icon: "language",
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: null,
            deviceTypes: null,
            tvMenu: false,
        },
    },
    LOGO: {
        name: "logo",
        icon: "logo",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: true,
        usingZRender: true,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: true,
        },
    },
    MESSAGES: {
        name: "messages",
        icon: "messaging-widget",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) =>
                    ![
                        SCREEN.TYPE.WELCOME,
                        SCREEN.TYPE.CCINSTRUCTION,
                        SCREEN.TYPE.CCPAIRINGSUCCESSFUL,
                        SCREEN.TYPE.PORTAL,
                    ].includes(t)
            ),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: true,
        },
    },
    QR: {
        name: "qr code",
        icon: "qrcode",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter(
                (t) => ![SCREEN.TYPE.PORTAL, SCREEN.TYPE.MOBILE, SCREEN.TYPE.CCPAIRINGSUCCESSFUL].includes(t)
            ),
            deviceTypes: [DEVICE.TYPE.TV, DEVICE.TYPE.DESKTOP],
            tvMenu: false,
        },
    },
    ROOMS: {
        name: "add room",
        icon: "rooms",
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: null,
            deviceTypes: null,
            tvMenu: false,
        },
    },
    SHOPITEM: {
        name: null,
        icon: null,
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: null,
            deviceTypes: null,
            tvMenu: false,
        },
    },
    SHOPORDERITEM: {
        name: null,
        icon: null,
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: null,
            deviceTypes: null,
            tvMenu: false,
        },
    },
    SLIDER: {
        name: "slider",
        icon: "widget-slider",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: [SCREEN.TYPE.GENERAL, SCREEN.TYPE.MOBILE],
            deviceTypes: [DEVICE.TYPE.MOBILE],
            tvMenu: false,
        },
    },
    TEXT: {
        name: "text",
        icon: "font-size",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    TOPBAR: {
        name: "screen title",
        icon: "font-size",
        hidden: true,
        isResizable: false,
        isDraggable: false,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: null,
            deviceTypes: null,
            tvMenu: false,
        },
    },
    TVMENUNAV: {
        name: "tvmenunav",
        icon: "navigation-horizontal",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: null,
            deviceTypes: null,
            tvMenu: true,
        },
    },
    VIDEO: {
        name: "video",
        icon: "play2",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    WEATHER: {
        name: "weather",
        icon: "sun",
        hidden: false,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: false,
        availability: {
            screenTypes: Object.values(SCREEN.TYPE).filter((t) => ![SCREEN.TYPE.PORTAL].includes(t)),
            deviceTypes: Object.values(DEVICE.TYPE),
            tvMenu: false,
        },
    },
    WIFILOGIN: {
        name: "loginsection",
        icon: "Wifi-access",
        hidden: true,
        isResizable: true,
        isDraggable: true,
        usingDriveRender: false,
        usingZRender: true,
        availability: {
            screenTypes: null,
            deviceTypes: null,
            tvMenu: false,
        },
    },
};

export const GetScreenDevicesTypes = ({ screenType }) => {
    switch (screenType) {
        case SCREEN.TYPE.CCINSTRUCTION:
        case SCREEN.TYPE.WELCOME:
        case SCREEN.TYPE.TV:
        case SCREEN.TYPE.CAST:
        case SCREEN.TYPE.SALES:
        case SCREEN.TYPE.EXPRESSCHECKOUT:
            return [DEVICE.TYPE.TV];
        case SCREEN.TYPE.CCPAIRINGSUCCESSFUL:
        case SCREEN.TYPE.MOBILE:
        case SCREEN.TYPE.SALESMOBILE:
            return [DEVICE.TYPE.MOBILE];
        case SCREEN.TYPE.GENERAL:
            return [DEVICE.TYPE.TV, DEVICE.TYPE.MOBILE];
        case SCREEN.TYPE.PORTAL:
            return [DEVICE.TYPE.DESKTOP, DEVICE.TYPE.MOBILE];
        default:
            return [];
    }
};

export const GetScreenDeviceSizes = ({ deviceType, screenType }) => {
    if (!deviceType) {
        // Use default device
        const devices = GetScreenDevicesTypes({ screenType });
        if (devices?.length > 0) {
            deviceType = devices[0];
        } else {
            deviceType = null;
        }
    }

    let sizes = {
        res: {
            w: 1920,
            h: 1080,
        },
        grid: {
            rows: 36,
            cols: 64,
        },
        maxGrid: {
            rows: 54,
            cols: 96,
        },
        name: deviceType,
        type: deviceType,
        orientation: "LANDSCAPE",
    };
    if (deviceType == DEVICE.TYPE.MOBILE) {
        sizes.res.w = 360;
        sizes.res.h = 640;
        sizes.grid.rows = 26;
        sizes.grid.cols = 12;
        sizes.maxGrid.rows = 42;
        sizes.maxGrid.cols = 24;
        sizes.orientation = "PORTRAIT";
    } else if (deviceType === DEVICE.TYPE.DESKTOP) {
        sizes.grid.rows = 135;
        sizes.grid.cols = 240;
        sizes.maxGrid.cols = 0;
        sizes.maxGrid.rows = 0;
    }

    return sizes;
};

export const GetWidgetGrid = ({ widgetType }) => {
    return {
        grid: { cols: 48, rows: 20 },
        maxGrid: { cols: 58, rows: 30 },
    };
};

export const GetScreenDevices = ({ screenType }) => {
    return GetScreenDevicesTypes({ screenType }).map((d) => {
        return GetScreenDeviceSizes({ deviceType: d });
    });
};

// WidgetAvailability check if widget is allowed in device and return the reason if not
export const WidgetAvailability = ({ widgetType, deviceType, screenType, i18n: t, isEditingTVMenu }) => {
    if (!t) t = (txt) => txt;

    const widget = WIDGETS[widgetType];
    if (!widget) {
        return {
            hidden: true,
            reason: `Unknown widget type ${widgetType}`,
        };
    }

    const availability = widget.availability;

    if (isEditingTVMenu) {
        // Check if widget is allowed in TV Menu
        return {
            hidden: !availability.tvMenu,
            reason: availability.tvMenu ? null : t("Widget disabled"),
        };
    }

    // Check if widget is allowed in screen type
    if (screenType && (!availability.screenTypes || !availability.screenTypes.includes(screenType))) {
        return {
            hidden: true,
            reason: t("Widget disabled for this screen type"),
        };
    }

    // Check if widget is allowed in device type
    if (deviceType && (!availability.deviceTypes || !availability.deviceTypes.includes(deviceType))) {
        return {
            disabled: true,
            reason:
                deviceType === DEVICE.TYPE.MOBILE
                    ? t("Widget disabled for mobile screens")
                    : availability.deviceTypes && availability.deviceTypes.includes(DEVICE.TYPE.MOBILE)
                    ? t("Widget enabled only for mobile screens")
                    : t("Widget disabled"),
        };
    }

    return {
        hidden: false,
        disabled: false,
        reason: null,
    };
};

// DefaultWidgetData return {initial data,initial style} when create widgets
export const DefaultWidgetData = ({ widgetType, lang, grid, fonts }) => {
    const content_t_es = i18next.getFixedT("es");
    const content_t_en = i18next.getFixedT("en");
    let defaultData,
        defaultStyle = null;
    switch (widgetType) {
        case "CALENDAR":
            defaultData = {
                format: "month",
                weekStart: "monday",
                typography: fonts.fontList.find((font) => font.name === "Lato"),
            };
            break;
        case "CLOCK":
            defaultData = {
                format: "digital",
                hours: "24",
                typography: fonts.fontList.find((font) => font.name === "Lato"),
            };
            break;
        case "MESSAGES":
            defaultData = {
                title: {
                    en: content_t_en("{{capitalize, capitalize}}", { capitalize: content_t_en("messages") }),
                    es: content_t_es("{{capitalize, capitalize}}", { capitalize: content_t_es("messages") }),
                },
                showIcon: true,
                lib: "fa",
                icon: "envelope",
            };
            defaultStyle = {
                iconPosition: "top",
                iconSize: 3,
                bold: true,
                alignment: "center",
                vAlignment: "center",
            };
            break;

        case "CHATBUTTON":
            defaultData = {
                bold: true,
                showIcon: true,
                title: { [lang]: "Chat reception" },
            };
            break;
        case "CONTAINER":
            defaultData = {
                margin: { w: 10, h: 10 },
                itemsMargin: { w: 10, h: 10 },
                grid: GetWidgetGrid({ widgetType }).grid,
            };
            break;
        case "TVMENU":
            defaultData = {
                buttonRC: ["home-portal"],
                menuType: "fixed-menu",
                blurMainScreen: true,
                margin: { w: 10, h: 0 },
                itemsMargin: { w: 5, h: 5 },
                grid: GetWidgetGrid({ widgetType }).grid,
            };
            break;
        case "ALARM":
            defaultData = {
                title: {
                    en: content_t_en("{{capitalize, capitalize}}", { capitalize: content_t_en("alarms") }),
                    es: content_t_es("{{capitalize, capitalize}}", { capitalize: content_t_es("alarms") }),
                },
                showIcon: true,
                icon: "alarm",
            };
            defaultStyle = {
                iconPosition: "top",
                iconSize: 3,
                bold: true,
                alignment: "center",
                vAlignment: "center",
            };
            break;
        case "WIFILOGIN":
            defaultData = { text: { es: "Conectar", en: "Connect" } };
            defaultStyle = {};
            break;
        case "TVMENUNAV":
            defaultData = {
                showIcon: true,
                fitItems: "false",
                sameSizeItems: "false",
                automaticSize: "true",
                itemsSize: 10,
            };
            defaultStyle = {
                iconSize: 3,
                iconPosition: "top",
                orientation: "horizontal",
                itemsMargin: 0,
                alignment: "center",
                vAlignment: "center",
            };
            break;
        case "SLIDER":
            defaultData = {
                mode: "simple",
                reelSize: 27,
                items: null,
                /*
                Item data:
                {
                    id: null,
                    name: "New item",
                    text: null,
                    actions: [],
                    libraryRef: null,
                    externalUrl: null,
                    visibility: true,
                    position: 0,
                }
                */
            };
            defaultStyle = {
                bgColor: "rgba(0,0,0,.2)",
                fgColor: "rgb(240, 240, 240)",
                items: {
                    bullets: {
                        fgColor: "rgb(240, 240, 240)",
                    },
                },
                alignment: "center",
                vAlignment: "center",
                bold: false,
                italic: false,
                radius: 0,
                font: null,
            };
            break;
        default:
            break;
    }
    return { defaultData, defaultStyle };
};

// DefaultWidgetSizes determine initial widget size in grid
export const DefaultWidgetSizes = ({ widgetType, deviceType, screenDevices }) => {
    let screenSizes = { w: 1, h: 1 };
    const device = GetScreenDeviceSizes({ deviceType });
    if (!screenDevices) screenDevices = [device];
    screenDevices &&
        screenDevices.map((d) => {
            if (d.type === deviceType) {
                screenSizes = { w: d.grid.cols, h: d.grid.rows };
            }
            return d;
        });
    let w = 1;
    let h = 1;
    let factor = 4;
    switch (deviceType) {
        case DEVICE.TYPE.MOBILE:
            w = screenSizes.w;
            if (widgetType === "BUTTON" || widgetType === "CHATBUTTON") {
                h = Math.max(1, parseInt(screenSizes.h / factor / 2));
            } else if (widgetType === "CONTAINER") {
                h = Math.max(1, parseInt(screenSizes.h / 2.2));
            } else if (widgetType === "LOGO") {
                w = Math.max(1, parseInt(screenSizes.w / 2));
                h = Math.max(1, parseInt(screenSizes.h / 6.5));
            } else if (widgetType === "ICON") {
                w = Math.max(1, parseInt(screenSizes.w / 5));
                h = Math.max(1, parseInt(screenSizes.h / 10));
            } else {
                h = Math.max(1, parseInt(screenSizes.h / factor));
            }
            break;
        case DEVICE.TYPE.DESKTOP:
        case DEVICE.TYPE.TV:
            if (widgetType === "QR") {
                factor = 10.5;
                h = Math.max(1, parseInt(screenSizes.h / 6.5));
            } else if (widgetType === "ICON") {
                factor = 13;
                h = Math.max(1, parseInt(screenSizes.h / 8));
            } else if (widgetType === "WEATHER") {
                factor = 3.4;
                h = Math.max(1, parseInt(screenSizes.h / 3.3));
            } else if (widgetType === "LOGO") {
                factor = 5.5;
                h = Math.max(1, parseInt(screenSizes.h / 6));
            } else if (widgetType === "BUTTON") {
                factor = 4.5;
                h = Math.max(1, parseInt(screenSizes.h / 6));
            } else if (widgetType === "CONTAINER") {
                factor = 4;
                h = Math.max(1, parseInt(screenSizes.h / 2.7));
            } else {
                h = Math.max(1, parseInt(screenSizes.h / factor));
            }
            w = Math.max(1, parseInt(screenSizes.w / factor));
            break;
        default:
            w = Math.max(1, parseInt(screenSizes.w / factor));
            h = Math.max(1, parseInt(screenSizes.h / factor));
    }
    return { w, h };
};

export const GetDeviceLayoutPosition = ({ widgetType, deviceType, deviceSizes, devicePosition, screen }) => {
    if (!deviceSizes && screen && deviceType) {
        deviceSizes = screen.devices.filter((d) => d.type === deviceType)[0];
    }
    const device = { ...GetScreenDeviceSizes({ deviceType }), ...deviceSizes };
    let pos = { ...DefaultWidgetSizes({ widgetType, deviceType, screenDevices: [device] }), ...devicePosition };

    if (deviceType === DEVICE.TYPE.MOBILE) {
        pos.w = device.grid.cols;
        pos.h = Math.max(1, parseInt(device.grid.rows / 4));
    }
    if (pos.w > device.grid.cols) pos.w = device.grid.cols;
    if (pos.h > device.grid.rows) pos.h = device.grid.rows;

    const maxX = deviceType === DEVICE.TYPE.MOBILE ? 0 : device.grid.cols - pos.w;
    const maxY = device.grid.rows * (deviceType === DEVICE.TYPE.MOBILE ? 5 : 1) - pos.h;

    const layout = ComposeGridLayout(screen, { name: deviceType });
    pos.x = 0;
    pos.y = 0;
    for (let x = 0; x <= maxX; x++) {
        let conflict = false;
        for (let y = 0; y <= maxY; y++) {
            conflict = false;
            if (layout) {
                // eslint-disable-next-line
                layout.map((i) => {
                    const outLeft = x >= i.x + i.w;
                    const outRight = x + pos.w <= i.x;
                    const outTop = y + pos.h <= i.y;
                    const outBottom = y >= i.y + i.h;
                    if (!outLeft && !outRight && !outTop && !outBottom) {
                        conflict = true;
                    }
                    return i;
                });
            }
            if (!conflict) {
                pos.x = x;
                pos.y = y;
                break;
            }
        }
        if (!conflict) {
            break;
        }
    }

    return pos;
};

export const CalulateWidgetData = (params, inContainer, screen) => {
    const { defaultData, defaultStyle } = DefaultWidgetData({
        widgetType: params.type,
        lang: screen ? screen.lang : null,
        grid: screen ? screen.grid : null,
        fonts: screen ? screen.fonts : null,
    });
    if (defaultStyle && inContainer) {
        defaultStyle.bgColor = params.style.bgColor;
    }
    const data = params.data || defaultData;
    const style = defaultStyle ? defaultStyle : params.style;
    const layoutPos = params.layoutPos
        ? params.layoutPos
        : inContainer
        ? params.widgetPos
        : WidgetInitialPosition({
              // Añado el widget en todos los layouts
              type: params.type,
              deviceType: params.deviceType,
              devicePosition: params.widgetPos,
              screenType: screen ? screen.type : null,
              screen: screen ? screen.data : null,
          });
    return { data, style, layoutPos };
};

// WidgetInitialPosition determine first position in all devices
export const WidgetInitialPosition = ({ type, deviceType, devicePosition, screenType, screen }) => {
    const screenDeviceTypes = GetScreenDevicesTypes({ screenType });
    let layoutPos = {};
    if (screenDeviceTypes) {
        screenDeviceTypes.map((t) => {
            const { disabled } = WidgetAvailability({ widgetType: type, deviceType: t, screenType });
            if (!disabled) {
                if (t === deviceType) {
                    layoutPos[t] = devicePosition;
                } else {
                    const area = GetDeviceLayoutPosition({ widgetType: type, deviceType: t, devicePosition, screen });
                    if (area) {
                        layoutPos[t] = area;
                    } else {
                        console.warn(t + " screen is full, unable to add this widget");
                    }
                }
            }
            return t;
        });
    } else {
        layoutPos = devicePosition;
    }
    return layoutPos;
};
