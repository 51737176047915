const Role = (role, t) => {
    const { name, accesses, id, users } = role;

    const dataType = "role";

    let listAccesses = [];

    accesses.forEach((access, index) => {
        listAccesses.push([index, t(access.name), "bg-zafiro-400"]);
    });

    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        executeSectionQuery: true,
        actions: [{ type: dataType, value: id, users }],
        editData: { name: name, accesses: accesses, id: id },
        mutationParams: { id: id, name: name },
        users,
        info: [
            {
                cellContent: "text",
                value: name,
                specialWidth: "w-3/12",
            },

            {
                cellContent: "labelsTruncate",
                value: listAccesses,
                specialWidth: "w-9/12 pr-6",
                cellAdjust: "truncate labels",
            },
        ],
    };
};

export default Role;
