import React, { useContext } from "react";
import { ReservationDetailsProvider } from "../Precheckin/ReservationDetails/ReservationDetailsContext";
import UseSectionHeader from "components/useSectionHeader";
import { PreCheckInContext } from "../Precheckin/ReservationDetails/ReservationDetailsContext";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "hooks/Utils/Utils";
import PreCheckinHistory from "../Precheckin/ReservationDetails/PreCheckinHistory";
import UseComponentLoading from "components/useComponentLoading";

const BookingInformationDetailsContainer = () => {
    return (
        <ReservationDetailsProvider>
            <BookingInformationDetails />
        </ReservationDetailsProvider>
    );
};

const BookingInformationDetails = () => {
    const { data: dataContext, setValuesContext, loading } = useContext(PreCheckInContext);
    const { t } = useTranslation();
    const { stayBookings } = dataContext || {};
    const { guests, code, numberOfRooms, numberOfGuests, arrivalDate, departureDate, reservationDate, observations } =
        stayBookings || {};
    const [guest] = guests || [];
    const { name, surname, email } = guest || {};
    const title = name && surname && code ? `${name} ${surname} - ${code}` : null;

    const ArrColumn = [
        {
            name: "booking-information",
            rows: [
                { key: "booking-number-code", val: code || null },
                {
                    key: "number-of-rooms",
                    val: numberOfRooms ? `${numberOfRooms} ${numberOfRooms > 1 ? t("rooms") : t("room")}` : null,
                },
                {
                    key: "number-of-guest",
                    val: numberOfGuests ? `${numberOfGuests} ${numberOfGuests > 1 ? t("guests") : t("guest")}` : null,
                },
                {
                    key: "arrival-departure-date",
                    val: `${arrivalDate ? `${arrivalDate} ${departureDate ? `- ${departureDate}` : ""}` : ""}`,
                },
            ],
        },
        {
            name: "holder-information",
            rows: [
                { key: "name", val: name || null },
                { key: "last-name", val: surname || null },
                { key: "email", val: email || null },
            ],
        },
        {
            name: "other-booking-information",
            rows: [
                { key: "Reservation-date", val: reservationDate || null },
                { key: "observations", val: observations || null },
            ],
        },
    ];

    return (
        <div className=" relative">
            <UseSectionHeader
                title={title}
                navToPreviousPage={true}
                customBreadCrumbs={[
                    {
                        name: "customers",
                        translate: true,
                        route: `/customers/guests/guest-list`,
                    },
                    {
                        name: "guest",
                        translate: true,
                        route: `/customers/guests/guest-list`,
                    },
                    {
                        name: "guest-list",
                        translate: true,
                        route: `/customers/guests/guest-list`,
                    },
                ]}
            />
            <div className=" w-full flex flex-col p-4 py-5 bg-white rounded">
                <div className=" w-full flex">
                    {ArrColumn.map((col) => {
                        return <RenderColumn name={col.name} rows={col.rows} />;
                    })}
                </div>
                <PreCheckinHistory />
            </div>
            {loading ? (
                <div className=" rounded absolute w-full h-full top-0 left-0 bg-white">
                    <UseComponentLoading />
                </div>
            ) : null}
        </div>
    );
};

const RenderColumn = ({ name, rows = [] }) => {
    const { t } = useTranslation();

    return (
        <div className=" w-2/7 flex font-bold ">
            <div className=" flex flex-col">
                <span className=" block mb-5">{t(name)}</span>
                <div>
                    {rows.map((row) => {
                        return (
                            <div className=" flex flex-col mb-8">
                                <div
                                    id={`booking-informations-label-${row.key}`}
                                    className=" text-sm mb-2  text-gray-800"
                                >
                                    {capitalizeFirst(t(row.key))}
                                </div>
                                <div id={`booking-informations-value-${row.key}`} className=" font-normal ">
                                    {row.val || "-"}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default BookingInformationDetailsContainer;
