import React, { useEffect, useState, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UseSelect from "../../../../../../Inputs/useSelect";
import { setExecuteQuery } from "../../../../../../../actions/sectionActions";
import { UPDATE_PRODUCT_PRICES } from "../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { executeVendureQuery, getVendureApiData } from "../../../../../../../hooks/Utils/Integrations/useVendure";
import _ from "lodash";
import { Session } from "../../../../../../../hooks/Utils/Session";
import UseCurrencyInput from "../../../../../../Table/UseCurrencyInput";
import {
    handleTaxRateChange,
    handleValueBeforeTaxChange,
    handleValueAfterTaxChange,
    updatePrice,
    getSymbolCurrency,
} from "../../../../../../../hooks/Utils/SalesUtils";
import { SalesContextDeprecated } from "contexts/Sales";
// import { useContext } from "react";

function ProductSettingsPriceCard({ data }) {
    const dispatch = useDispatch();

    //Consts&states
    const { productId } = data;
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { data: dataContext } = useContext(SalesContextDeprecated);
    const { values } = useSelector((state) => state.action);
    const [productVariantId, setProductVariantId] = useState(null);
    const [currencyCode, setCurrencyCode] = useState(null);
    const [priceWithoutTax, setPriceWithoutTax] = useState(null);
    const [taxCategoryId, setTaxCategoryId] = useState(null);
    const [priceWithTax, setPriceWithTax] = useState(null);
    const [priceFetched, setPriceFetched] = useState(0);
    const [taxCategories, setTaxCategories] = useState(null);
    const [pricesIncludeTax, setPricesIncludeTax] = useState(false);
    const [taxRate, setTaxRate] = useState(null);
    const isFirstRenderTaxCategorie = useRef(true);
    const [loading, setLoading] = useState(true);
    const sectionName = "product-settings-price";
    const inputsIds = [`${sectionName}-without-tax`, `${sectionName}-tax`, `${sectionName}-total`];
    const decimalSeparator = (1.1).toLocaleString().substring(1, 2);

    useEffect(() => {
        if (priceFetched > 0 && taxRate === null) {
            updatePrice(priceFetched, setPriceWithTax, setPriceWithoutTax);
        } else if (priceFetched > 0 && taxRate) {
            updatePrice(priceFetched, null, setPriceWithoutTax);
            handleTaxRateChange(taxRate, priceWithoutTax, decimalSeparator, setPriceWithTax);
        }
        // eslint-disable-next-line
    }, [priceFetched]);

    useEffect(() => {
        if (executeQuery?.action === UPDATE_PRODUCT_PRICES) {
            setLoading(true);
        }
    }, [executeQuery]);

    useEffect(() => {
        if (dataContext && dataContext?.channel) {
            setterData(dataContext);
            setLoading(false);
        }
    }, [dataContext]);

    useEffect(() => {
        if (values?.[inputsIds[1]]) {
            const taxCategoriData = _.find(taxCategories, { value: values[inputsIds[1]] });
            if (taxCategoriData) {
                setTaxCategoryId(taxCategoriData.value);
                setTaxRate(taxCategoriData.taxRateValue);
            }
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        if (taxRate > 0) {
            handleTaxRateChange(taxRate, priceWithoutTax, decimalSeparator, setPriceWithTax);
        }
        // eslint-disable-next-line
    }, [taxRate]);

    useEffect(() => {
        if (taxCategoryId) {
            if (!isFirstRenderTaxCategorie.current) {
                handleUpdatePrice();
            } else {
                isFirstRenderTaxCategorie.current = false;
            }
        }
        // eslint-disable-next-line
    }, [taxCategoryId]);

    //arrangeData
    const arrangeData = (data) => {
        let channel = null;
        let taxCategories = [];
        let productVariant = null;
        if (data) {
            if (data.channel) {
                channel = data.channel;
                if (channel.defaultTaxZone && data.taxRates && data.taxRates.items && data.taxRates.items.length > 0) {
                    data.taxRates.items
                        .filter((item) => item.zone.id === channel.defaultTaxZone.id)
                        .forEach((taxRate) => {
                            taxCategories.push({
                                value: taxRate.category.id,
                                text: `${taxRate.name} (${taxRate.value}%)`,
                                taxRateValue: taxRate.value,
                            });
                        });
                }
            }
            if (data?.productVariants?.items && data.productVariants.items.length > 0) {
                productVariant = data.productVariants.items[0];
            }
        }
        return { channel, taxCategories, productVariant };
    };

    const setterData = (data) => {
        let productVariantId = null;
        let currencyCode = null;
        let priceWithoutTax = null;
        let pricesIncludeTax = false;
        let taxCategoryId = null;
        let taxCategories = null;
        const { channel, taxCategories: taxCategoriesList, productVariant } = arrangeData(data);
        currencyCode = channel ? channel.currencyCode : null;

        taxCategories = taxCategoriesList;
        if (channel?.pricesIncludeTax) {
            pricesIncludeTax = true;
        }
        if (productVariant) {
            productVariantId = productVariant.id;
            priceWithoutTax = productVariant.price;
            taxCategoryId = productVariant.taxCategory ? productVariant.taxCategory.id : null;
        }
        setPricesIncludeTax(pricesIncludeTax);
        setProductVariantId(productVariantId);
        setCurrencyCode(currencyCode);
        setTaxCategoryId(taxCategoryId);
        setTaxCategories(taxCategories);
        setPriceFetched(priceWithoutTax);
    };

    const handleUpdatePrice = () => {
        let minimumUnitPriceWthoutTax = (parseFloat(String(priceWithoutTax).replace(decimalSeparator, ".")) || 0) * 100;
        const finalPrice = Math.round(minimumUnitPriceWthoutTax);

        dispatch(
            setExecuteQuery({
                action: UPDATE_PRODUCT_PRICES,
                params: {
                    id: productId,
                    productVariantId: productVariantId,
                    finalPrice: finalPrice,
                    taxCategoryId: taxCategoryId,
                },
            })
        );
    };

    //renders

    //response
    return (
        <div className="w-full my-2">
            <div className="w-full text-left  first-capital mb-5 ">
                <span className="font-bold text-gray-900 text-xl">{t("price")}</span>
            </div>
            <div className="w-full flex mb-3">
                <div className="w-6/12 text-left">
                    <span className="">{t("without-tax")}</span>
                </div>
                <div className="w-6/12">
                    <UseCurrencyInput
                        id={"product-settings-price-without-tax"}
                        inputData={{ name: inputsIds[0] }}
                        validation={true}
                        disabled={loading}
                        value={priceWithoutTax}
                        actionOnBlur={() => {
                            handleUpdatePrice();
                        }}
                        onChange={(values) =>
                            handleValueBeforeTaxChange(
                                values,
                                taxRate,
                                decimalSeparator,
                                setPriceWithoutTax,
                                setPriceWithTax
                            )
                        }
                        currencyCode={currencyCode}
                    />
                </div>
            </div>
            <div className="w-full flex mb-3">
                <div className="w-6/12 text-left">
                    <span className="">{t("tax")}</span>
                </div>
                <div className="w-6/12">
                    <UseSelect
                        enabled={!loading}
                        selectData={{
                            id: inputsIds[1],
                            name: "tax",
                            placeHolder: "tax",
                            data: taxCategories ? taxCategories : [],
                            noTranslateAV: true,
                            containerOptionsAdjust: "overflow-y-auto max-h-20",
                            activeValue: taxCategoryId,
                        }}
                    />
                </div>
            </div>
            <div className="w-full flex mb-3">
                <div className="w-6/12 text-left">
                    <span className="font-bold">{t("total")}</span>
                </div>
                <div className="w-6/12">
                    <UseCurrencyInput
                        id={"product-settings-price-total"}
                        inputData={{ name: inputsIds[2] }}
                        validation={true}
                        disabled={loading}
                        value={priceWithTax}
                        actionOnBlur={() => {
                            handleUpdatePrice();
                        }}
                        onChange={(values) =>
                            handleValueAfterTaxChange(
                                values,
                                taxRate,
                                decimalSeparator,
                                setPriceWithoutTax,
                                setPriceWithTax
                            )
                        }
                        currencyCode={currencyCode}
                    />
                </div>
            </div>
        </div>
    );
}

export default ProductSettingsPriceCard;
