import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
    openModal,
    closeModal,
    setModalContent,
    showGlobalLoading as setShowGlobalLoading,
    setLoadingModalContent,
} from "../../../../actions/uiActions";
import { gql } from "apollo-boost";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/react-hooks";
import MobileSettingsInformationCard from "./MobileSettingsInformationCard";
import ReactTooltip from "components/ReactTooltip";
import MobileSettingsChatFeature from "./MobileSettingsChatFeature";
import MobileSettingsLinksAndSocialNetworks from "./MobileSettingsLinksAndSocialNetworks";
import HTMLReactParser from "html-react-parser";
import UpdateMobileInformationCard from "./UpdateMobileInformationCard";
import { ACTIONS_MOBILE_SETTINGS } from "./MobileSettingsUtils";
import { setActionValues } from "actions/globalActions";

const MobileSettingsCards = ({ data, cardInEdition, setCardInEdition }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { permissions } = useSelector((state) => state.ui);
    const { validations } = useSelector((state) => state.action);
    const nativeApps = permissions?.hotel?.nativeApps ? true : false;

    function openModalDeleteMobileApp() {
        dispatch(
            setModalContent({
                title: t("delete-mobile-app-title-modal", { appName: data?.name }),
                bodyAdjust: "w-2/5 text-justify max-h-80 overflow-y-scroll",
                inputs: [{ type: "deleteMobileApp" }],
                noTranslateText: true,
                executeSectionQuery: true,
                buttons: [
                    { name: "cancel", style: "white", action: "close" },
                    {
                        action: ACTIONS_MOBILE_SETTINGS.UPDATE_SETTINGS_DELETE_MOBILE_APP,
                        name: "delete",
                        style: "red",
                    },
                ],
            })
        );
        dispatch(setActionValues({ id: data?.id }));
        dispatch(openModal());
    }

    return (
        <div className=" w-full ">
            {!nativeApps ? (
                <div className=" flex justify-between mb-4 ">
                    <span className=" text-xl font-bold">{`${data?.name}`}</span>
                    <button
                        className=" focus:outline-none text-zafiro-600 flex items-center"
                        onClick={openModalDeleteMobileApp}
                        id="mobile-settings-delete-app-button"
                    >
                        <i className=" icon icon-delete mr-2 text-xl "></i>
                        <span className=" font-bold">{`${t("delete-mobile-app")}`}</span>
                    </button>
                </div>
            ) : (
                <div className=" mb-4">
                    <span className=" text-xl font-bold">{`${data?.name}`}</span>
                    <div className=" flex justify-between mt-4">
                        <div>{HTMLReactParser(t("to-generate-or-update-mobile-apps"))}</div>
                        <button
                            className=" focus:outline-none text-zafiro-600 flex items-center"
                            id="mobile-settings-delete-app-button"
                            onClick={openModalDeleteMobileApp}
                        >
                            <i className=" icon icon-delete mr-2 text-xl "></i>
                            <span className=" font-bold">{`${t("delete-mobile-app")}`}</span>
                        </button>
                    </div>
                </div>
            )}

            <div className=" flex w-full">
                <div className=" mr-8 w-1/2">
                    <MobileSettingsInformationCard
                        data={data}
                        setCardInEdition={setCardInEdition}
                        cardInEdition={cardInEdition}
                    />
                </div>
                <div className=" w-1/2">
                    <MobileSettingsChatFeature
                        data={data}
                        setCardInEdition={setCardInEdition}
                        cardInEdition={cardInEdition}
                    />

                    <MobileSettingsLinksAndSocialNetworks
                        data={data}
                        setCardInEdition={setCardInEdition}
                        cardInEdition={cardInEdition}
                    />
                </div>
            </div>
            <ReactTooltip
                place="bottom"
                type="light"
                offset={{ top: -8, left: -8 }}
                html={true}
                border={true}
                // delay={0}
                borderColor="#D3DAE1"
            />
            <UpdateMobileInformationCard setCardInEdition={setCardInEdition} />
        </div>
    );
};

export default MobileSettingsCards;
