import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table";
//Utils
import ListRoomGroups from "../../../../hooks/GraphqlCalls/Hotel/Rooms/ListRoomGroups";

const RoomGroups = () => {
    ListRoomGroups();

    return (
        <>
            <UseSectionHeader title={["roomGroups"]} value={"demo"} />
            <UseTable />
        </>
    );
};

export default RoomGroups;
