const DeleteGroupModal = (id, groupName) => {
    return [
        {
            title: ["delete", groupName],
            text: "guest-group-delete",
            id: id,

            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete",
                    style: "red",
                    action: "guest-group-delete",
                },
            ],
        },
    ];
};

export default DeleteGroupModal;
