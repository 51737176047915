import { useEffect, useRef, useState } from "react";
import Alarm from "./models/Alarm";
import { gql } from "apollo-boost";
import { EXPIRED_ALARM, ACTIVE_ALARM, getTVChannelName } from "../../Utils/Customers/AlarmUtils";
import {
    setError,
    setLoading,
    setCustomError,
    cleanTableStore,
    setFolder,
    cleanSortCriteria,
    setRefreshData,
    setHeaders,
    setTableResults,
    setHeaderButtons,
    setCustomConfig,
    setSearchable,
    setTableType,
    setAvailableFilters,
    setCount,
    setCountPosition,
    setCheckable,
    setExtendedInfo,
    setActions,
    cleanActiveFilters,
} from "../../../actions/tableActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMSQuery } from "../useMSQuery";
import { cleanAction } from "../../../actions/globalActions";
import GetTableHeaders from "../../Utils/Table/Headers/GetCustomersTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { GetAvailableFilters } from "../../Utils/GetAvailableFilters";
import { POLLINTERVAL_15S } from "../../Utils/Utils";

const ListAlarms = () => {
    const { t } = useTranslation();
    //Store data
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, folder } = useSelector(
        (state) => state.table
    );
    const { channels } = useSelector((state) => state.sectionContent);

    //Data
    const sectionName = `list-alarms-${folder ? folder.name : ""}`;
    let folders = [ACTIVE_ALARM, EXPIRED_ALARM];

    //States
    const [sorting] = useState(`,orderBy:{field: "id", criteria: "desc"}`);

    //Actions
    const dispatch = useDispatch();
    const orderCriteria = useRef();

    //API
    const GET_ALARMS = gql`
        {
            alarms (page: ${currentPage}, size: ${perPage}${
        orderCriteria.current ? orderCriteria.current : sorting
    }${filterQuery}) {
                info {
                    count
                    page
                    size
                }
                results {
                    groupingID
                    isFromGuest
                    lastUpdate
                    rooms
                    startTime
                    state
                    stayguestName
                    stayguestSurname
                    tvChannelId
                    type
                  }
            }
            roomsData: alarms${
                folder && folder.value === EXPIRED_ALARM.value ? `(${EXPIRED_ALARM.defaultFilterExpired})` : ""
            }{
                results{
                    rooms
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_ALARMS, {
        // fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        if (!folder || folder.key !== ACTIVE_ALARM.key) {
            dispatch(setFolder(folders[0]));
        }
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
            dispatch(cleanActiveFilters());
            dispatch(cleanAction());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sortCriteria && sortCriteria.includes("last-update")) {
            orderCriteria.current = sortCriteria.replace("last-update", "lastUpdate");
        } else {
            orderCriteria.current = sortCriteria;
        }
    }, [sortCriteria]);

    useEffect(() => {
        if ((refreshData || folder) && refetch) {
            dispatch(setLoading(true));
            dispatch(cleanSortCriteria());
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData, folder]);

    useEffect(() => {
        // SET DEFAULT ALARM FOLDER IF NO FOLDER OR FOLDER IS NOT FROM ALARMS
        if (!folder || folder.key !== ACTIVE_ALARM.key) {
            dispatch(setFolder(folders[0]));
        }
        // eslint-disable-next-line
    }, [folder]);

    useEffect(() => {
        if (!loading && !error && data && data.alarms && networkStatus === 7) {
            //Get data
            dispatch(setHeaders(GetTableHeaders(sectionName)));
            const dataItems = arrangeData(data);
            dispatch(setTableResults(dataItems));

            //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            dispatch(setTableType("list-alarms"));
            dispatch(setAvailableFilters(GetAvailableFilters(sectionName, extractRoomsList(data))));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            dispatch(setCount(data.alarms.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setCheckable(false));
            dispatch(setExtendedInfo(true));
            dispatch(setActions(true));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }

            dispatch(
                setCustomConfig({
                    searchStyle: "col-span-3",
                    filtersStyle: "col-span-9",
                    batchStyle: "w-3/12",
                    folders: folders,
                })
            );
            dispatch(setCustomError(`${t("theres-not")} ${t("alarms")} ${t("available")}`));
            dispatch(setLoading(false));
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    const arrangeData = (data) => {
        const alarmsData = [];
        // eslint-disable-next-line
        data.alarms.results.map((alarm) => {
            alarmsData.push(Alarm({ ...alarm, channelName: getTVChannelName(channels, alarm.tvChannelId) }, t));
        });
        return alarmsData;
    };

    const extractRoomsList = (data) => {
        let roomArray = [];
        // eslint-disable-next-line
        data.roomsData.results.map((alarm) => {
            const rooms = Object.keys(JSON.parse(alarm.rooms));
            rooms.forEach((roomName) =>
                roomArray.filter((r) => r.id === roomName).length === 0
                    ? roomArray.push({
                          id: roomName,
                          value: roomName,
                          visible: true,
                      })
                    : null
            );
        });

        roomArray.sort((a, b) => a.value.localeCompare(b.value));

        return roomArray;
    };
};

export default ListAlarms;
