import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    setActions,
    setColumns,
    setCount,
    setCountPosition,
    setExtendedInfo,
    setHeaderButtons,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setFormNewContent,
    setCustomError,
    cleanActiveFilters,
    setError,
    setRefreshData,
    setAvailableFilters,
    setTableType,
    setActiveFilters,
    getFilterQuery,
} from "../../../actions/tableActions";
import { setCZCategories } from "../../../actions/uiActions";
import { setSectionContent, cleanParametesSection } from "../../../actions/sectionActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCZTableHeaders";
import GetTableForm from "../../Utils/Table/GetTableForm";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";

//Model
import AreaCategory from "./models/AreaCategory";

import { useTranslation } from "react-i18next";
import { useMSQuery } from "../useMSQuery";

const ListAreas = () => {
    const { t } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const {
        currentPage,
        perPage,
        filterQuery,
        sortCriteria,
        refreshData,
        loading: loadingTable,
    } = useSelector((state) => state.table);
    const [lastFilterQuery, setLastFilterQuery] = useState(null);
    //Data
    const { parametersSection } = useSelector((state) => state.sectionContent);
    const itemType = "areas";

    //States
    const [sorting] = useState(`,orderBy:{field: "category", criteria: "desc"}`);
    const [reloadContent, setReloadContent] = useState(false);
    const [now, setNow] = useState("");

    //Actions
    const dispatch = useDispatch();

    //API
    //ISO 8601 standard Monday(1) Sunday (7)
    const GET_CATEGORY_AREAS = gql`{
        commonzones: commonzones(page: ${currentPage}, size: ${perPage}, withActiveTimetable: "${now}"${
        sortCriteria ? sortCriteria : sorting
    }${filterQuery}) {
          info { count }
          results {
            bookingAllowed bookingFreq slotsPerBooking visibleDuration bookingNumber autoBooking notificationEmail
            capacityLimit currentComment currentStatus
            description id name nameTID ref
            parentCategoryID parentCategoryName
            schedule{
              ID dateType isEnabled
              name periodicityType type
              isActive
              timetableDates{
                dateList isActive
                timetableDateRanges{
                    timeRanges weekdayList isActive
                }}}}}
                bookingNumbers: commonzones(page: ${currentPage}, size: 100000, withActiveTimetable: "${now}"${sorting}${filterQuery}) {
                    info { count }
                    results {
                      bookingNumber
                      id }}
        cz_categories: cz_categories{
          info { count }
          results { id name }}}`;
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_CATEGORY_AREAS, {
        notifyOnNetworkStatusChange: true,
        pollInterval: 300000,
        fetchPolicy: "no-cache",
    });

    //Listeners
    useEffect(() => {
        if (lastFilterQuery && filterQuery === lastFilterQuery) {
            dispatch(setLoading(false));
        } else {
            setLastFilterQuery(filterQuery);
        }
        // eslint-disable-next-line
    }, [loadingTable, filterQuery]);

    useEffect(() => {
        //get offset
        const today = new window.ZDate();
        const offset = today.getTimezoneOffset() * 60000;
        setNow(new window.ZDate(window.ZDate.now() - offset).toISOString());

        if (parametersSection) {
            dispatch(setActiveFilters("category", parametersSection));
            dispatch(getFilterQuery({ category: parametersSection }));
            dispatch(cleanParametesSection());
        }
        dispatch(setLoading(true));
        executeQuery();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData && refetch) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    //Listeners

    useEffect(() => {
        if (!loading && !error && data && data.commonzones && reloadContent && networkStatus === 7) {
            //Get data
            const dataItems = arrangeData(data, langStrings);
            const tableHeaders = GetTableHeaders(itemType);
            const availableFilters = GetAvailableFilters(itemType, extractCategoryList(data.cz_categories));

            console.log(itemType);

            dispatch(
                setSectionContent({
                    categories: data.cz_categories.results,
                })
            );
            //Add to store  dispatch(
            dispatch(setLoading(true));
            dispatch(setTableType(itemType));
            dispatch(setSearchable());
            dispatch(setHeaders(tableHeaders));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.commonzones.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setHeaderButtons(GetTableHeaderButton(itemType)));
            dispatch(setFormNewContent(GetTableForm(itemType)));
            dispatch(setCustomError(t("no areas available")));
            //store categories for later use
            dispatch(setCZCategories(data.cz_categories.results));

            if (dataItems.length > 0) {
                dispatch(setColumns(dataItems[0].info.length));
                if (dataItems[0].actions) {
                    dispatch(setActions());
                }
            }
            dispatch(setExtendedInfo(true));
            dispatch(showRowForm(false));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, reloadContent, langStrings, networkStatus, sortCriteria]);

    useEffect(() => {
        setReloadContent(true);
        //history.go(0);
        // eslint-disable-next-line
    }, [langStrings]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        dispatch(setLoading(loading));
        // eslint-disable-next-line
    }, [loading]);
};

const extractCategoryList = (data) => {
    let catArray = [""];
    data.results.map((cat) => catArray.push([cat.name, cat.id, "int"]));

    catArray.sort(function (a, b) {
        return a[0] < b[0];
    });

    return catArray;
};

export const arrangeData = (data, langStrings) => {
    const tempArray = [];
    // eslint-disable-next-line
    data.commonzones.results.map((area) => {
        tempArray.push(AreaCategory({ area, langStrings }));
    });
    return tempArray;
};

export default ListAreas;
