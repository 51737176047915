import { DRAFTS_MSG, SENT_MSG, BIN_MSG, TEMPLATES_MSG } from "../../../Utils/Customers/MessagesUtils";
import { ALL_BG_LABEL, GUEST_BG_LABEL, ROOM_BG_LABEL } from "../../../Utils/Utils";

const Message = (message, t, folder) => {
    const { id, title, body, priority, sentTime, expirationTime, state, deletedTime, createdTime, destination } =
        message;
    const dataType = "message";
    const sentDate = new window.ZDate(sentTime);
    const deletedDate = new window.ZDate(deletedTime);
    const createdDate = new window.ZDate(createdTime);

    let priorityColor = "";

    switch (priority && priority.toLowerCase()) {
        case "standard":
            priorityColor = "text-green-100";
            break;
        case "important":
            priorityColor = "text-purple-700";
            break;
        case "priority":
            priorityColor = "text-orange-100";
            break;
        case "critical":
            priorityColor = "text-red-100";
            break;
        default:
            break;
    }
    const folderName = folder ? (folder.name === "templates" ? "new" : folder.name) : SENT_MSG.name;

    const cellSubject = {
        name: "subject",
        cellContent: "link",
        link: `/customers/messages/${folderName}/${id}`,
        value: title || `(${t("{{capitalize, capitalize}}", { capitalize: t("unspecified") })})`,
        specialWidth: "w-3/12",
    };
    const cellBody = {
        name: "body",
        cellContent: "text",
        value: body,
        specialWidth: "w-6/12",
    };
    const cellRecipents = {
        name: "recipients",
        cellContent: "labelsTruncate",
        value: destination ? createRecipientsLabels(destination, t) : null,
        specialWidth: state === DRAFTS_MSG.value ? "w-7/12" : "w-3/12",
    };
    const cellPriority = {
        name: "priority",
        cellContent: "icon-with-text",
        value: `${t("{{capitalize, capitalize}}", { capitalize: t(priority ? priority.toLowerCase() : "") })}`,
        icon: priority ? { name: "status-solid", adjust: `${priorityColor} text-xxs` } : null,
        specialWidth: "w-2/12",
        adjust: "truncate",
    };
    const cellSentDate = {
        name: "sent date",
        cellContent: "text",
        value: `${
            sentDate
                ? sentDate.toLocaleDateString([], {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                  })
                : ""
        } ${
            sentDate
                ? sentDate.toLocaleTimeString("es-ES", {
                      hour: "2-digit",
                      minute: "2-digit",
                  })
                : ""
        }`,
        specialWidth: "w-2/12",
    };
    const cellCreatedDate = {
        name: "created date",
        cellContent: "text",
        value: `${
            createdDate
                ? createdDate.toLocaleDateString([], {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                  })
                : ""
        } ${
            createdDate
                ? createdDate.toLocaleTimeString("es-ES", {
                      hour: "2-digit",
                      minute: "2-digit",
                  })
                : ""
        }`,
        specialWidth: "w-2/12",
    };
    const cellExpired = {
        cellContent: "text",
        value: hasExpired(expirationTime)
            ? `<span class="text-red-100">${t("{{capitalize, capitalize}}", {
                  capitalize: t("expired"),
              })}</span>`
            : "",
        specialWidth: "w-2/12",
    };
    const cellDeletedDate = {
        name: "delete date",
        cellContent: "text",
        value: `${
            deletedDate
                ? deletedDate.toLocaleDateString([], {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                  })
                : null
        } ${
            deletedDate
                ? deletedDate.toLocaleTimeString("es-ES", {
                      hour: "2-digit",
                      minute: "2-digit",
                  })
                : null
        }`,
        specialWidth: "w-4/12",
    };

    let infoCells = [];
    switch (state) {
        case SENT_MSG.value:
            infoCells.push(cellSubject, cellRecipents, cellPriority, cellSentDate, cellExpired);
            break;
        case BIN_MSG.value:
            // list-bin : asunto / destinatarios / fecha de elimnacion / estado (state)
            infoCells.push(cellSubject, cellRecipents, cellPriority, cellDeletedDate);
            break;
        case DRAFTS_MSG.value:
            // list-draft : asunto / destinatraios / prioridad
            infoCells.push(cellSubject, cellRecipents, cellPriority);
            break;
        default:
            break;
    }
    if (createdTime) {
        // is a template
        infoCells.push(cellSubject, cellBody, cellCreatedDate);
    }

    return {
        ...message,
        dataType: dataType,
        actions: state === BIN_MSG.value ? false : [{ type: dataType, value: state || TEMPLATES_MSG.value }],
        executeSectionQuery: true,
        info: infoCells,
    };
};

export default Message;

const hasExpired = (expirationTime) => {
    if (!expirationTime) {
        return false;
    }
    return new window.ZDate(expirationTime) < new window.ZDate();
};

const createRecipientsLabels = (destinations, t) => {
    // Color Background Label

    // filter order to show first all then -room type then -guest type
    const roomFilter = ["wings", "floors", "roomGroups", "rooms"];
    const guestFilters = ["stayGuests", "stayGroups", "countries", "languages"];
    const orderedFilter = roomFilter.concat(guestFilters);

    let _index = 0;
    // Label maker
    const newRecipient = (index, text, label) => {
        return [index, text, label];
    };

    let filtersApplied = [];
    if (destinations && destinations.all) {
        filtersApplied.push(newRecipient(_index, t("all", { count: 2 }), ALL_BG_LABEL));
        _index++;
    }
    if (destinations) {
        orderedFilter.forEach((key) => {
            if (destinations[key] && destinations[key].length > 0) {
                destinations[key].forEach((destination) => {
                    filtersApplied.push(
                        newRecipient(
                            _index,
                            destination.name
                                ? destination.name
                                : destination.ref
                                ? t("country:" + destination.ref.toLowerCase())
                                : null,
                            roomFilter.includes(key) ? ROOM_BG_LABEL : GUEST_BG_LABEL
                        )
                    );
                    _index++;
                });
            }
        });
    }

    return filtersApplied;
};
