import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table/index";
//Utils
import ListAccessPoint from "../../../../hooks/GraphqlCalls/Hotel/Monitoring/ListAccessPoint";

const MonitorAccessPoint = () => {
    ListAccessPoint();

    return (
        <>
            <UseSectionHeader
                title={["monitor-of", "access-point"]}
                buttonName={["export", "access-point"]}
                buttonColor="btn-blue"
                action={"function"}
                value={"demo"}
            />
            <UseTable />
        </>
    );
};

export default MonitorAccessPoint;
