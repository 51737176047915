import DeleteTopologyRoom from "../modals/DeleteTopologyRoom";

const TopologyRoom = (room) => {
    const { floorName, id, name, number, pmsNumber, roomTVs, wingName } = room;

    const dataType = "topology-room";

    let locations = [];

    roomTVs.forEach((rtv) => locations.push([rtv.id, rtv.name, "#47A1FF"]));

    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        roomTVs: roomTVs,
        info: [
            {
                name: "wing",
                cellContent: "text",
                value: `${wingName}`,
                specialWidth: "w-1.5/12",
            },
            {
                name: "floor",
                cellContent: "text",
                value: `${floorName}`,
                specialWidth: "w-1.5/12",
            },
            {
                name: "room-number",
                cellContent: "text",
                value: `${number}`,
                specialWidth: "w-1/12",
            },
            {
                name: "pms-number",
                cellContent: "text",
                value: `${pmsNumber}`,
                specialWidth: "w-1/12",
            },
            {
                name: "name",
                cellContent: "text",
                value: `${name}`,
                specialWidth: "w-2/12",
            },
            {
                name: "tvs",
                cellContent: "text",
                value: `${roomTVs.length}`,
                specialWidth: "w-1/12",
            },
            {
                name: "location",
                cellContent: "labels",
                value: locations,
                noCapitalice: true,
                specialWidth: "w-3/12",
            },
            {
                cellContent: "icon",
                value: id,
                evaluate: true,
                isAction: true,
                specialWidth: "w-0.5/12 text-right",
                icon: {
                    name: "edit",
                    id: id,
                    itemType: dataType,
                    action: "edit-topology-room",
                },
            },
            {
                cellContent: "icon",
                value: id,
                evaluate: true,
                specialWidth: "w-0.5/12",
                isAction: true,
                modal: DeleteTopologyRoom(id, `${name}`),
                name: "topology-room",
                icon: {
                    name: "delete",
                    values: id,
                    itemType: dataType,
                },
            },
        ],
    };
};

export default TopologyRoom;
