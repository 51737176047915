import React, { useEffect, useState } from "react";
import classNames from "classnames";

/**
 * @description
 * @component
 * @param {string} id - ID for the input
 * @param {string} label - Label for the input
 * @param {boolean} checked - Checked state of the input
 * @param {boolean} disabled - Disable the input
 * @param {function} onChange - Function to call when the input changes
 * @param {string} className - Additional classes
 * @param {(radio|checkbox)} design - Design of the input
 * @returns {JSX.Element} - Toggle component
 * @example
 * <Toggle id="input-id" checked={false} onChange={(value) => console.log(value)} className="w-1/2" />
 */
const Toggle = ({ id, label, checked: extChecked, disabled, onChange, value, className, design = "checkbox" }) => {
    const isRadio = design === "radio";

    const [checked, setChecked] = useState(extChecked);

    const isDisabledChecked = disabled && checked;
    const isDisabledNotChecked = disabled && !checked;
    const isEnabledChecked = !disabled && checked;
    const isEnabledNotChecked = !disabled && !checked;

    const isRadioEnabled = isRadio && !disabled;
    const isRadioDisabled = isRadio && disabled;
    const isRadioChecked = isRadio && checked;

    const isRadioEnabledChecked = isRadioEnabled && checked;
    const isRadioEnabledNotChecked = isRadioEnabled && !checked;
    const isRadioDisabledChecked = isRadioDisabled && checked;
    const isRadioDisabledNotChecked = isRadioDisabled && !checked;

    const baseClasses = classNames("flex items-center justify-center w-5 h-5", {
        "rounded-full border": isRadio,
        "bg-gray-200 border-gray-300": isRadioDisabled,
        "hover:border-zafiro-300": isRadioEnabled,
        "border-zafiro-300": isRadioChecked,
        "border-gray-400": isRadioEnabledNotChecked,
        rounded: !isRadio,
        "cursor-pointer": !disabled,
        "bg-white": !isRadioDisabledNotChecked && (!checked || isRadio),
        "bg-zafiro-400 hover:bg-zafiro-300 text-white": isEnabledChecked && !isRadio,
        "bg-gray-300 text-white": isDisabledChecked && !isRadio,
        "bg-gray-400 hover:bg-gray-300 text-gray-900": isEnabledNotChecked && !isRadio,
        "bg-gray-300 text-gray-900": isDisabledNotChecked && !isRadio,
    });

    const iconClasses = classNames({
        "w-3 h-3 rounded-full": isRadio,
        "icon icon-tick-right font-bold text-white": !isRadio,
        "bg-gray-400": isRadioDisabledChecked,
        "bg-zafiro-400": isRadioEnabledChecked,
        "bg-zafiro-400 hover:bg-zafiro-300 text-white": isEnabledChecked && !isRadio,
        "bg-gray-300 text-white": isDisabledChecked && !isRadio,
        "bg-gray-400 hover:bg-gray-300 text-gray-900": isEnabledNotChecked && !isRadio,
        "bg-gray-300 text-gray-900": isDisabledNotChecked && !isRadio,
    });

    const handleCheck = () => {
        if (isRadio && checked) {
            // Radio buttons can't be unchecked by itself
            return;
        }
        setChecked(!checked);
        if (onChange) {
            onChange({ checked: !checked, value });
        }
    };

    useEffect(() => {
        setChecked(extChecked);
    }, [extChecked]);

    return (
        <div className={`flex items-center ${className || ""}`}>
            <div id={id} className={`${baseClasses} ${label ? "mr-3" : ""}`} onClick={disabled ? null : handleCheck}>
                {checked || isRadio ? <div className={`${iconClasses}`} /> : null}
            </div>
            {label ? (
                <div
                    id={id + "-label"}
                    className={disabled ? "text-gray-400" : "cursor-pointer"}
                    onClick={disabled ? null : handleCheck}
                >
                    {label}
                </div>
            ) : null}
        </div>
    );
};

export default Toggle;
