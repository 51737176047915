import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const UseModalChannelUsedInProperties = ({ index }) => {
    const { data } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const [properties, setProperties] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (data?.usedInProjects) {
            setProperties(data.usedInProjects);
        }
    }, [data]);

    return (
        <div>
            <div className=" w-full flex justify-between">
                <span className=" font-bold">{t("properties")}</span>
                <span>{`${properties.length} ${t("properties")} `}</span>
            </div>
            <div
                className=" mt-2 border rounded border-gray-200 px-4 overflow-y-scroll "
                style={{ minHeight: "21.25rem", maxHeight: "21.25rem" }}
            >
                {properties.map((p) => {
                    return <div className=" py-4 border-b border-gray-200">{p}</div>;
                })}
            </div>
        </div>
    );
};

export default UseModalChannelUsedInProperties;
