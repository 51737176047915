const AssignedUser = (data, firstAssignedUserID, assignedUsersLength) => {
    const { id, name, email } = data;

    return {
        dataType: "assigned-user",
        id: id,
        adjust: "h-auto",
        actions: [
            {
                type: "assigned-user",
                value: id,
            },
        ],
        name: name,
        executeSectionQuery: true,
        actionComponentStyle: { left: "-160px" },
        info: [
            {
                cellContent: "text",
                value: name,
                specialWidth: "w-3/12 pl-1",
                wrap: "truncate",
            },
            {
                cellContent: "text",
                value: email,
                specialWidth: `w-8/12`,
                wrap: "truncate",
            },
            {
                cellContent: "icon",
                specialWidth: "w-1/12",
                adjust: "pl-10",
                isAction: true,
                disabledIcon: firstAssignedUserID === id && assignedUsersLength === 1 ? true : false,
                name: "user",
                modal: [
                    {
                        title: "delete",
                        value: name,
                        bodyAdjust: "w-3/12",
                        executeSectionQuery: true,
                        id: id,
                        inputs: [
                            {
                                type: "void",
                                text: "delete-user-confirm",
                            },
                        ],
                        buttons: [
                            { name: "cancel", style: "white", action: "close" },
                            {
                                name: "delete",
                                style: "red",
                                action: "delete-assigned-user",
                            },
                        ],
                    },
                ],
                icon: {
                    name: "delete",
                    adjust: `" text-2xl text-gray-${
                        firstAssignedUserID === id && assignedUsersLength === 1 ? "500" : "900"
                    }`,
                },
            },
        ],
    };
};

export default AssignedUser;
