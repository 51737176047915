import React, { useState } from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/";
//Utils
import ListCustomers from "../../../hooks/GraphqlCalls/Customers/ListCustomers";
import { useTranslation } from "react-i18next";
import ExportCustomers from "../../../hooks/GraphqlCalls/Customers/ExportCustomers";

const Customers = () => {
    const { t } = useTranslation();
    const [advancedFilters, setAdvancedFilters] = useState(false);
    const [startExport, setStartExport] = useState(false);
    ListCustomers(advancedFilters);

    return (
        <>
            {advancedFilters ? (
                <UseSectionHeader
                    title={["advanced-filter"]}
                    actionOnBack={() => {
                        setAdvancedFilters(false);
                    }}
                />
            ) : (
                <UseSectionHeader title={["list", "of", "customers"]} />
            )}
            <ExportCustomers startExport={startExport} setStartExport={setStartExport}></ExportCustomers>
            <div>
                <div className="flex justify-end w-full my-2">
                    <span
                        id="export-all-customer-list-button"
                        className="link clickable underline font-bold"
                        onClick={(e) => setStartExport(true)}
                    >
                        {t("export-all-customer-list")}
                    </span>
                    {!advancedFilters ? (
                        <span
                            id="advanced-filter-button"
                            className="ml-5 link clickable underline font-bold"
                            onClick={(e) => setAdvancedFilters(!advancedFilters)}
                        >
                            {t("advanced-filter")}
                        </span>
                    ) : null}
                </div>
                <UseTable />
            </div>
        </>
    );
};

export default Customers;
