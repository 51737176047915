import React from "react";

/**
 * @name SettingsCard.ParamsList
 * @description This component is a list of parameters with their descriptions
 * @component
 * @param {Array} data The list of parameters
 * @returns {JSX.Element} The UI of the component
 * @example <ParamsList data={[{name: "param1", description: "This is the description of param1"}]} />
 */
const ParamsList = ({ data }) => {
    return (
        <>
            {data?.length > 0 ? (
                <table>
                    {data.map((param) =>
                        param ? (
                            <tr key={param.name}>
                                <th className="w-1/12 text-left font-normal align-top whitespace-nowrap py-1 pr-2">
                                    {param.name}
                                </th>
                                <td id={param.id} className="text-left font-bold align-top py-1 px-2">
                                    {param.description}
                                </td>
                            </tr>
                        ) : null
                    )}
                </table>
            ) : null}
        </>
    );
};

export default ParamsList;
