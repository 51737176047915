import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import {
    setHeaderButtons,
    setCountPosition,
    setItemsPerpage,
    setTableResults,
    setBatchActive,
    setRefreshData,
    setCustomError,
    setSearchable,
    setCheckable,
    setLoading,
    setHeaders,
    setCount,
    setCustomConfig,
    cleanCheckedItems,
    setActions,
} from "../../../actions/tableActions";
import { POLLINTERVAL_15S } from "../../Utils/Utils";
import GetTableHeaders from "../../Utils/Table/Headers/GetServicesTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import CorporateChannel from "./models/CorporateChannel";

const ListCorporateChannels = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const SECTION_NAME = "list-corporate-channels";
    const { lang } = useSelector((state) => state.ui);
    const { currentPage, sortCriteria, perPage, refreshData, activeFilters } = useSelector((state) => state.table);

    const search = activeFilters?.search ? `filter: {search: "${activeFilters.search}"}` : "";

    const GET_CORPORATE_CHANNELS = gql`
        query {
            corporateTvChannelsSummary(page:${currentPage} , size:${perPage}
                ${sortCriteria ? sortCriteria : ""}
                ${search}
                ) {
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    logoRef
                    name
                    usedInProjects
                    usedInProjectsNum
                    videosNum
                }
            }
        }
    `;

    useEffect(() => {
        executeQuery();
        dispatch(setItemsPerpage(9));
        dispatch(setLoading(true));
        dispatch(setHeaders(GetTableHeaders(SECTION_NAME)));
        dispatch(setCustomError(t("no-corporate-channels-yet")));
        dispatch(setCountPosition("table-header"));
        dispatch(setHeaderButtons(GetTableHeaderButton(SECTION_NAME, {}, null, t)));
        dispatch(setBatchActive(BatchActions()));
        dispatch(setActions(true));
        dispatch(setSearchable(true));
        dispatch(setCheckable(true));
        dispatch(setCustomError(t("no-channels-yet")));
        dispatch(
            setCustomConfig({
                pagination: true,
            })
        );
        // eslint-disable-next-line
    }, []);

    const [executeQuery, { data, loading, refetch }] = useLazyQuery(GET_CORPORATE_CHANNELS, {
        pollInterval: POLLINTERVAL_15S,
    });

    useEffect(() => {
        executeQuery();
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (data?.corporateTvChannelsSummary?.results) {
            setTimeout(() => {
                dispatch(setLoading(false));
            }, 2000);
        }
        //eslint-disable-next-line
    }, [loading, sortCriteria]);

    useEffect(() => {
        const results = data?.corporateTvChannelsSummary?.results;
        if (results) {
            dispatch(setTableResults(arrangeData(results, t, lang)));
            dispatch(setLoading(false));
            dispatch(cleanCheckedItems());
            dispatch(setCount(data?.corporateTvChannelsSummary?.info?.count));
        }
        //eslint-disable-next-line
    }, [data]);

    return null;
};

const arrangeData = (data, t, lang) => {
    return data.map((item) => CorporateChannel({ data: item, t, lang }));
};

export default ListCorporateChannels;

const BatchActions = () => {
    const actions = [
        {
            name: "delete",
            action: "batch-delete-corporate-channels",
            executeSectionQuery: true,
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "delete-channels-confirm",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                { name: "delete", style: "red", action: "batch-delete-corporate-channels" },
            ],
        },
    ];
    return actions;
};
