import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

//Components
import UseEditableField from "../../../../components/useEditableField";
import UseButton from "../../../../components/useButton";

//Utils
import { GetLanguageOptions } from "../../../../hooks/Utils/LanguagesUtils";
import { calculateAge, bdDateTransform, bdDateToDateInput } from "../../../../hooks/Utils/Utils";
import { validateSection } from "../../../../hooks/Utils/Validations";

import { toast } from "react-toastify";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";

//Actions
import {
    setActionItemAffected,
    setActionName,
    setActionValidations,
    changeActionValues,
} from "../../../../actions/globalActions";
import { openModal, setModalContent, showGlobalLoading } from "../../../../actions/uiActions";
import { setExecuteQuery } from "../../../../actions/sectionActions";
import PhoneInput from "react-phone-input-2";
import { Session } from "../../../../hooks/Utils/Session";
import UseCustomPhoneInput from "components/Inputs/UseCustomPhoneInput";
import { GetNationalityName, GetNationalitiesOptions } from "hooks/Utils/NationalityUtils";
import { GET_COLOR_AND_TEXT_DOCUMENTATION_STATUS } from "../Precheckin/ReservationDetails/utils";
import EditDocumentation from "hooks/GraphqlCalls/Customers/modals/EditDocumentation";

const GuestData = ({ guestInfo }) => {
    //Actions
    const dispatch = useDispatch();

    //Store data
    const { lang } = useSelector((state) => state.ui);
    const { values } = useSelector((state) => state.action);
    const { groupsData } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);

    const precheckinAvailable = permissions?.guests?.preCheckin ? true : false;
    const { t } = useTranslation();
    const prefixId = "guest-data";

    //Session data
    const currentHotelData = Session.getSessionProp("currentHotel");
    const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};
    const { color: colorDocumentationStatus, text: textDocumentationStatus } = GET_COLOR_AND_TEXT_DOCUMENTATION_STATUS(
        guestInfo?.guestDocumentType,
        guestInfo?.guestDocumentNumber
    );

    //Data
    let groups = "";
    let groupsIds = [];
    if (guestInfo && guestInfo.guestGroup) {
        guestInfo.guestGroup.map(
            (group) => (groups += groups ? ` , ${group.name}` : group.name) && groupsIds.push(String(group.id))
        );
    }

    //State
    const [inEditionMode, setInEditionMode] = useState(false);

    //functions

    const startEdition = () => {
        dispatch(setActionItemAffected(guestInfo.id));
        dispatch(setActionName("edit-guest-data"));
        if (guestInfo.guestPhone) {
            dispatch(
                changeActionValues({
                    phone: guestInfo.guestPhone,
                })
            );
        }
        setInEditionMode(!inEditionMode);
    };

    const ensureValue = (value) => {
        return value ? value : "-";
    };

    const updateGuestData = () => {
        //Validate fields
        const validations = validateSection("guest-data", values, t);

        dispatch(setActionValidations(validations));
        const validationsArray = Object.values(validations);

        if (validationsArray.filter((item) => item === false).length > 0) {
            toast.error(t("errors-in-red"));
            return;
        }
        dispatch(showGlobalLoading(true));
        setInEditionMode(false);
        dispatch(
            setExecuteQuery({
                action: "updateGuestData",
                params: { id: guestInfo.guestID },
            })
        );
    };

    return (
        <div className="p-8">
            <div className="border-b border-gray-200 text-lg pb-2 flex justify-between">
                <div className="first-capital">{t("guest-information")}</div>
                {!currentHotel.blockGuestManagement && !inEditionMode ? (
                    <div
                        id="guest-data-start-edition-button"
                        className="icon-edit text-xl text-gray-600 cursor-pointer"
                        onClick={startEdition}
                    ></div>
                ) : null}
            </div>

            <div className="m-auto pt-8">
                <div className={`bg-zafiro-200 w-14 h-14 m-auto rounded-full`}>
                    <i className={`icon-user text-center pt-3 block text-3xl m-auto text-white`}></i>
                </div>
                <h1 className="font-bold text-lg mt-3 text-center">
                    {guestInfo.guestLoyaltyRef ? (
                        <span
                            className={`icon-loyal-customers text-base text-zafiro-600 pr-2`}
                            data-tip={capitalizeFirst(t("loyal-customer"))}
                            data-for="default-tooltip"
                        ></span>
                    ) : null}
                    {guestInfo.guestName} {guestInfo.guestSurname}
                </h1>

                <div className={`grid grid-cols-2 gap-x-4 ${inEditionMode ? "gap-y-4" : "gap-y-8"} pt-8`}>
                    <UseEditableField
                        data={{
                            id: `${prefixId}-input-pms-id`,
                            label: "pms-id",
                            value: ensureValue(guestInfo.pmsID),
                            inEditionMode: inEditionMode,
                            type: "text",
                            noEditionAdjust: "truncate",
                            tooltip: ensureValue(guestInfo.pmsID),
                        }}
                    ></UseEditableField>
                    <UseEditableField
                        data={{
                            id: `${prefixId}-input-title`,
                            label: "title",
                            value: ensureValue(guestInfo.guestTitle),
                            inEditionMode: inEditionMode,
                            type: "text",
                            noEditionAdjust: "truncate",
                            tooltip: ensureValue(guestInfo.guestTitle),
                        }}
                    ></UseEditableField>
                    <UseEditableField
                        data={{
                            id: `${prefixId}-input-name`,
                            label: "name",
                            value: ensureValue(guestInfo.guestName),
                            inEditionMode: inEditionMode,
                            required: true,
                            type: "text",
                            noEditionAdjust: "truncate",
                            tooltip: ensureValue(guestInfo.guestName),
                        }}
                    ></UseEditableField>
                    <UseEditableField
                        data={{
                            id: `${prefixId}-input-surname`,
                            label: "surname",
                            value: ensureValue(guestInfo.guestSurname),
                            inEditionMode: inEditionMode,
                            required: true,
                            type: "text",
                            noEditionAdjust: "truncate",
                            tooltip: ensureValue(guestInfo.guestSurname),
                        }}
                    ></UseEditableField>
                    <UseEditableField
                        data={{
                            id: `${prefixId}-input-nationality`,
                            label: "nationality",
                            value: ensureValue(
                                guestInfo && guestInfo.guestCountry
                                    ? GetNationalityName(guestInfo.guestCountry.toLowerCase())
                                    : null
                            ),
                            inEditionMode: inEditionMode,
                            type: "select-with-search",
                            oneSelected: true,
                            optionData: GetNationalitiesOptions(),
                            selectedIds: [guestInfo.guestCountry?.toUpperCase()],
                            tooltip: ensureValue(
                                guestInfo && guestInfo.guestCountry
                                    ? GetNationalityName(guestInfo.guestCountry.toLowerCase())
                                    : null
                            ),
                        }}
                    ></UseEditableField>
                    <UseEditableField
                        data={{
                            id: `${prefixId}-input-lang`,
                            label: "lang",
                            value: ensureValue(guestInfo.guestLang ? t("language:" + guestInfo.guestLang) : null),
                            inEditionMode: inEditionMode,
                            type: "select-with-search",
                            oneSelected: true,
                            optionData: GetLanguageOptions(),
                            selectedIds: [guestInfo.guestLang],
                            tooltip: ensureValue(guestInfo.guestLang ? t("language:" + guestInfo.guestLang) : null),
                        }}
                    ></UseEditableField>
                    <UseEditableField
                        data={{
                            id: `${prefixId}-input-guest-group`,
                            label: "guest-group",
                            value: ensureValue(groups),
                            inEditionMode: inEditionMode,
                            type: "select-with-search",
                            optionData: groupsData,
                            selectedIds: groupsIds,
                            tooltip: ensureValue(groups),
                            useOptionID: false,
                        }}
                    ></UseEditableField>
                    <UseEditableField
                        data={{
                            id: `${prefixId}-input-age`,
                            label: "age",
                            labelOnEdition: "birthdate",
                            value: inEditionMode
                                ? bdDateToDateInput(guestInfo.guestBirthdate)
                                : guestInfo.guestBirthdate
                                ? `${calculateAge(
                                      new window.ZDate(bdDateTransform(guestInfo.guestBirthdate))
                                  )} <span class="text-gray-800 text-sm">(${new window.ZDate(
                                      bdDateTransform(guestInfo.guestBirthdate)
                                  ).toLocaleDateString()})</span>`
                                : "-",
                            inEditionMode: inEditionMode,
                            dateAdjust: "text-sm",
                            type: "date",
                        }}
                    ></UseEditableField>

                    {inEditionMode ? (
                        <>
                            <div className="w-full col-span-2 text-gray-800 text-sm font-bold pb-0.5 first-capital whitespace-nowrap overflow-ellipsis overflow-hidden">
                                {t("phone")}
                            </div>
                            <div className="w-full col-span-2">
                                <UseCustomPhoneInput
                                    id={`${prefixId}-input-phone`}
                                    actionValues={true}
                                    value={guestInfo?.guestPhone ? guestInfo.guestPhone : null}
                                />
                            </div>
                        </>
                    ) : (
                        <UseEditableField
                            data={{
                                id: `${prefixId}-input-phone`,
                                label: "phone",
                                value: ensureValue(guestInfo.guestPhone),
                                inEditionMode: inEditionMode,
                                type: "text",
                                noEditionAdjust: "truncate",
                                styleAdjust: "col-span-2",
                                tooltip: ensureValue(guestInfo.guestPhone),
                            }}
                        ></UseEditableField>
                    )}
                    <UseEditableField
                        data={{
                            id: `${prefixId}-input-email`,
                            label: "email",
                            value: ensureValue(guestInfo.guestEmail),
                            inEditionMode: inEditionMode,
                            type: "text",
                            noEditionAdjust: "truncate",
                            styleAdjust: "col-span-2 break-all	",
                            tooltip: ensureValue(guestInfo.guestEmail),
                        }}
                    ></UseEditableField>
                </div>
                {!inEditionMode && precheckinAvailable ? (
                    <div className=" w-full mt-8">
                        <span className=" fieldLabel">{t("documentation")}</span>
                        <div className={" flex justify-between"}>
                            <span className={colorDocumentationStatus}>{textDocumentationStatus}</span>
                            <button
                                onClick={() => {
                                    dispatch(
                                        setModalContent(
                                            EditDocumentation({
                                                documentNumber: guestInfo?.guestDocumentNumber,
                                                documentType: guestInfo?.guestDocumentType,
                                                documentExtraInformation: guestInfo?.guestDocumentExtraInformation,
                                                id: guestInfo.guestID,
                                                name: guestInfo?.guestName,
                                                surname: guestInfo?.guestSurname,
                                            })
                                        )
                                    );
                                    dispatch(openModal());
                                }}
                                id={`${prefixId}-edit-documentation-button`}
                                className=" focus:outline-none font-bold text-zafiro-600"
                            >
                                {capitalizeFirst(t("edit"))}
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
            {inEditionMode ? (
                <div className="mt-6 pt-6 border-t border-gray-200 flex  justify-end">
                    <div id={`${prefixId}-cancel-button`} onClick={() => setInEditionMode(false)}>
                        <UseButton
                            buttonName={"cancel"}
                            buttonColor={"btn-white"}
                            adjust="w-24 min-w-0 mr-2 float-left "
                        />
                    </div>
                    <div id={`${prefixId}-save-button`} onClick={() => updateGuestData()}>
                        <UseButton buttonName={"save"} buttonColor={"btn-blue"} adjust="w-24 min-w-0" />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default GuestData;
