import React from "react";
import ParamsList from "components/SettingsCard/ParamsList";
import Section from "components/SettingsCard/Section";
import UseSwitch from "components/Inputs/useSwitch";
import IconButton from "components/Buttons/IconButton";
import InfoIcon from "components/InfoIcon";

/**
 * This component is a card that is used to display settings or information. It can be used to display a list of parameters, a section of settings, or any other content.
 * @component
 * @param {string} id The id of the card
 * @param {string} title The title of the card
 * @param {string} info Is a info icon with a tooltip that will be displayed next to the title
 * @param {string} warning Is a warning icon with a tooltip that will be displayed next to the title
 * @params {object} toggle Is a toggle button that will be displayed at the top right of the card. It should have an id, a checked value, and an action function
 * @param {object} edit Is a button that will be displayed at the top right of the card. It should have an onClick function and an id
 * @param {string} right A element that will be displayed at the top right of the card
 * @param {string} description A description of the card
 * @param {string} className Additional classes
 * @param {JSX.Element} children The content of the card *
 @returns {JSX.Element} The UI of the component
 * @example <SettingsCard title="Card Title" info="Card Info" edit={{onClick: () => {}}}>Card Content here</SettingsCard>
 */
const SettingsCard = ({ id, title, info, warning, toggle, edit, right, description, className, children }) => {
    const hasChildrens = typeof children?.filter === "function" && children.filter((child) => child).length > 0;
    return (
        <div className={`p-6 w-full bg-white rounded shadow-sm flex flex-col ${className}`}>
            {title || info || warning || edit || toggle || right ? (
                <div className={`flex justify-between items-center ${description || hasChildrens ? "mb-5" : null}`}>
                    <div className="flex items-center">
                        {title ? <div className="text-xl font-bold">{title}</div> : null}
                        {info ? <InfoIcon icon={"info"}>{info}</InfoIcon> : null}
                        {warning ? <InfoIcon icon={"warning"}>{warning}</InfoIcon> : null}
                    </div>
                    {edit || toggle || right ? (
                        <div className="flex items-center space-x-5">
                            {toggle ? (
                                <UseSwitch
                                    id={toggle.id || `${id}-toggle-button`}
                                    disabled={toggle.disabled}
                                    checked={toggle.checked}
                                    action={toggle.action}
                                    label={toggle.label}
                                    adjust="flex items-center"
                                />
                            ) : null}
                            {edit ? (
                                <IconButton
                                    type="edit"
                                    id={edit.id || `${id}-edit-button`}
                                    disabled={edit.disabled}
                                    onClick={edit.onClick}
                                />
                            ) : null}
                            {right ? <div className="flex items-center">{right}</div> : null}
                        </div>
                    ) : null}
                </div>
            ) : null}
            {description ? <div className="text-base mb-5">{description}</div> : null}
            {children}
        </div>
    );
};

SettingsCard.ParamsList = ParamsList;
SettingsCard.Section = Section;

export default SettingsCard;
