import React, { Children, useContext, useEffect, useState } from "react";

import UseRadio from "../../../../../useRadio";
import ReactTooltip from "components/ReactTooltip";
import { useSelector } from "react-redux";
import { WidgetContext } from "../../../../../../contexts/editor";
import { useTranslation } from "react-i18next";

const SettingsRadio = ({ children, toolbarID, title, value, selected, action, radioID, id }) => {
    const { t } = useTranslation();
    const { gridItems } = useSelector((state) => state.design);

    const evaluateWidgetActions = (_widget) => {
        // evaluate if any widget actions is 'zones'
        let foundZones = false;
        if (value === "section" && selected === "section" && _widget && _widget.actions.length > 0) {
            _widget.actions.forEach((action) => {
                if (action.type === "section" && action.value === "zones") {
                    foundZones = true;
                }
            });
        }
        return foundZones;
    };

    const [zonesSelected, setZonesSelected] = useState(
        evaluateWidgetActions(gridItems.find((widget) => widget.i === widgetID))
    );
    const { id: widgetID } = useContext(WidgetContext);

    useEffect(() => {
        setZonesSelected(evaluateWidgetActions(gridItems.find((widget) => widget.i === widgetID)));

        // eslint-disable-next-line
    }, [gridItems]);

    return (
        <div
            id={radioID}
            className="table w-full pb-1 cursor-pointer"
            onClick={(e) => {
                e.stopPropagation();
                return action ? action(value) : null;
            }}
        >
            <div className="first-capital mb-2">
                <div className="text-gray-800 first-capital">
                    <UseRadio
                        selected={selected}
                        value={value}
                        handleClick={() => {
                            return action ? action(value) : null;
                        }}
                        id={id}
                    />
                    <div className="ml-8 mb-2">
                        <span>{title}</span>
                        {zonesSelected ? (
                            <div
                                className={`inline-block ml-3 text-base cursor-default`}
                                data-tip={t("zones-action-warning-editor")}
                                onMouseOver={() => ReactTooltip.rebuild()}
                            >
                                <span className={` icon-warning warning left-0`}></span>
                                <span className={` icon-warning1 -ml-1em text-orange-100 left-0`}></span>
                                <span className={` icon-warning2 -ml-1em text-orange-100 left-0`}></span>
                                <ReactTooltip
                                    place="bottom"
                                    type="light"
                                    multiline={true}
                                    offset={{ top: -8, left: -8 }}
                                    html={true}
                                    border={true}
                                    className="w-96"
                                    borderColor="#D3DAE1"
                                />
                            </div>
                        ) : null}
                    </div>
                    {Children.toArray(children).map((ch) => {
                        const isReactElement = ch && typeof ch.type === "function";
                        return React.cloneElement(
                            ch,
                            !isReactElement
                                ? ch.props
                                : {
                                      disabled: selected !== value,
                                  }
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default SettingsRadio;
