import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setPage, setLoading } from "../../actions/tableActions";
//Utils
import { CalcPages } from "../../hooks/Utils/DataCalc/CalcPages";

const UsePagination = () => {
    //Store data
    const { t } = useTranslation();

    const { count, currentPage, perPage, disabled } = useSelector((state) => state.table);

    //Data
    const maxPage = Math.ceil(count / perPage);
    const pages = CalcPages(maxPage, currentPage);

    //States
    const [nextVisibility, setNextVisibility] = useState("100");
    const [prevVisibility, setPrevVisibility] = useState("");

    //Actions
    const dispatch = useDispatch();

    const handlerClickDirect = (e) => {
        if (disabled) {
            return;
        }
        dispatch(setLoading(true));
        dispatch(setPage(Number(e.currentTarget.dataset.id)));
    };

    //Listeners
    useEffect(() => {
        if (currentPage > 1) {
            setPrevVisibility("100");
        } else {
            setPrevVisibility("0");
        }
        if (currentPage < maxPage && maxPage > 1) {
            setNextVisibility("100");
        } else {
            setNextVisibility("0");
        }
        // eslint-disable-next-line
    }, [currentPage, maxPage]);

    return (
        <div className="t-pagination-container first-capital">
            {count <= perPage ? null : (
                <>
                    <div
                        className={`t-pagination-${disabled ? "disabled" : "index"} ${
                            prevVisibility === "0" ? "hidden" : ""
                        }`}
                    >
                        <div className="inline">
                            <div
                                id={"pagination-previous"}
                                className="flex items-center"
                                onClick={handlerClickDirect}
                                data-id={currentPage - 1}
                            >
                                <span className="t-pagination-left icon-chevron-left"></span>
                                <span className="first-capital">{t("previous")}</span>
                            </div>
                        </div>
                    </div>

                    {pages.map((page, index) => (
                        <div
                            id={`page-number-container-${page}`}
                            className={`t-pagination-${disabled ? "disabled" : "number"}`}
                            onClick={page === "..." || currentPage === page ? null : handlerClickDirect}
                            key={index}
                            data-id={page}
                        >
                            <p className="inline">
                                {currentPage === page ? (
                                    <span
                                        className={`t-pagination-active${disabled ? "-disabled" : ""}`}
                                        data-id={page}
                                    >
                                        {page}
                                    </span>
                                ) : null}
                                {page === "..." ? <span>{page}</span> : null}
                                {currentPage !== page && page !== "..." ? (
                                    <span
                                        id={`pagination-${page}`}
                                        className={`t-pagination-${disabled ? "disabled" : "index"}`}
                                        onClick={handlerClickDirect}
                                        data-id={page}
                                    >
                                        {page}
                                    </span>
                                ) : null}
                            </p>
                        </div>
                    ))}

                    <div
                        className={`t-pagination-${disabled ? "disabled" : "index"} ${
                            nextVisibility === "0" ? "hidden" : ""
                        }`}
                    >
                        <div className="inline">
                            <div
                                id={"pagination-next"}
                                className="flex items-center"
                                onClick={handlerClickDirect}
                                data-id={currentPage + 1}
                            >
                                <span className="first-capital">{t("next")}</span>
                                <span className="t-pagination-right icon-chevron-right"></span>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default UsePagination;
