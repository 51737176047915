export const QUERY_STAY_BOOKING_FILTER = (id) => {
    return `query {
        stayBookings(
          filter: {
            id: ${id}
          }
        ) {
          info {
            count
            page
            size
          }
          results {
            id
            code
            reservationDate
            arrivalDate
            departureDate
            numberOfGuests
            numberOfRooms
            observations
            pendingChangesSend
            state
            guests {
              birthDate
              countryRef
              documentExtraInformation
              documentNumber
              documentType
              email
              gender
              pmsID
              guestRole
              id
              languageRef
              name
              phoneNumber
              stayBookingRoomIndex
              surname
            }
            logs {
              createdAt
              id
              message
              stayBookingID
              subject
            }
          }
        }
      
      }
      `;
};

export const MUTATION_UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT = (data) => {
    return `mutation {
    updatePreCheckInSettingsTravelersReport(
      authorities: [${data.authorities ? `"${data.authorities}"` : ""}]
      user: "${data.user ? `${data.user}` : ""}"
      password: "${data.password ? `${data.password}` : ""}"
    ) {
      ok
      error
    }
  }
  `;
};

export const MUTATION_UPDATE_AUTOMATION_GUEST_REPORT = (deactivate) => {
    return `mutation{
    updatePreCheckInSettings(
      automaticSendingOfTheTravelersReport:${deactivate ? false : true}
    ){
      error
      ok
    }
  }`;
};

export const QUERY_PRE_CHECK_IN_SETTINGS_TRAVELERS_REPORT = () => {
    return `query{
      preCheckInSettingsTravelersReport{
        authorities
        password
        user
      }
      preCheckInSettings{
        automaticSendingOfTheTravelersReport
      }
  }`;
};

export const FAKE_MUTATION = () => {
    return `mutation {
    fakeMutation{
      error
      ok
    }
  }`;
};
