import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ShopContext } from "contexts/Sales/Shop";

import SettingsCard from "components/SettingsCard";
import UseSwitch from "components/Inputs/useSwitch";
import UseInputNumberArrows from "../../../../../Table/UseInputNumberArrows";

import currenciesVendure from "../../../../../../hooks/Utils/Integrations/vendure-currencies.json";
import { capitalizeFirst } from "../../../../../../hooks/Utils/Utils";
import { formatPriceValueVendure } from "../../../../../../hooks/Utils/SalesUtils";

import {
    DELIVERY_METHOD_ROOM,
    DELIVERY_METHOD_PICKUP,
    DELIVERY_METHOD_GEOLOCATION,
    DELIVERY_METHODS,
    sortDeliveryMethods,
    getDeliveryMethodText,
} from "constants/sales";

const ShopSettingsOrdersDelivery = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        data: dataContext,
        shippingConfig,
        pickupLocations,
        permissions,
        autoAcceptOrders,
        orderCancellationAllowed,
        limitMaxProductPerOrder,
        maxProductsPerOrder,
        allowScheduleDelivery,
        delayedDeliveryThreshold,
        updateConfig,
    } = useContext(ShopContext);

    const hasOrdersManagement = permissions?.ordersManagement;

    const availableLocationsCount = pickupLocations?.filter((location) => location.available).length || 0;

    const pickupEnabled = shippingConfig?.[DELIVERY_METHOD_PICKUP]?.enabled;

    const roomServiceHasPrice =
        shippingConfig?.[DELIVERY_METHOD_ROOM]?.enabled && shippingConfig?.[DELIVERY_METHOD_ROOM]?.price?.base > 0;
    const geolocationHasPrice =
        shippingConfig?.[DELIVERY_METHOD_GEOLOCATION]?.enabled &&
        shippingConfig?.[DELIVERY_METHOD_GEOLOCATION]?.price?.base > 0;
    const deliveryHasPrice = roomServiceHasPrice || geolocationHasPrice;

    const [currency, setCurrency] = useState(null);

    const [delayOrders, setDelayOrders] = useState(delayedDeliveryThreshold);
    const [productsPerOrder, setProductsPerOrder] = useState(maxProductsPerOrder);

    const prefixID = "shop-settings";

    useEffect(() => {
        const channel = dataContext?.channel;
        if (channel?.currencyCode) {
            setCurrency(currenciesVendure.currencies[channel.currencyCode]);
        }
        // eslint-disable-next-line
    }, [dataContext]);

    useEffect(() => {
        setProductsPerOrder(maxProductsPerOrder);
    }, [maxProductsPerOrder]);

    useEffect(() => {
        setDelayOrders(delayedDeliveryThreshold);
    }, [delayedDeliveryThreshold]);

    return (
        <SettingsCard title={t("orders-and-delivery")}>
            <SettingsCard.Section
                id="delivery-method-allow"
                title={t("Delivery method allowed")}
                edit={{
                    id: `${prefixID}-button-open-modal-delivery-method`,
                    onClick: () => {
                        navigate("delivery");
                    },
                }}
            >
                <div className=" w-full px-4 mb-5">
                    {shippingConfig
                        ? sortDeliveryMethods(DELIVERY_METHODS).map((key) => {
                              const title = t(getDeliveryMethodText(key));
                              const isEnabled = shippingConfig?.[key]?.enabled;
                              const priceBase = shippingConfig?.[key]?.price?.base / 100;
                              const hasPrice = isEnabled && priceBase > 0;
                              const isPickup = key === DELIVERY_METHOD_PICKUP;
                              const isRoomService = key === DELIVERY_METHOD_ROOM;

                              // To support historical id values...
                              let methodID;
                              if (isRoomService) {
                                  methodID = "room-service";
                              } else if (isPickup) {
                                  methodID = "pick-up";
                              } else {
                                  methodID = key;
                              }
                              const seleniumID = `${prefixID}-shipping-method-${methodID}-status`;
                              const statusID = `${seleniumID}-status`;
                              const priceID = `${seleniumID}-price`;
                              return (
                                  <div key={key} className="w-full my-3">
                                      <div className="flex justify-between w-full">
                                          <div className=" font-bold flex justify-between " style={{ width: "60%" }}>
                                              <div>{title}</div>
                                              <div
                                                  id={statusID}
                                                  className={` w text-center font-normal ${
                                                      isEnabled ? " text-green-100" : "text-gray-600"
                                                  } `}
                                              >
                                                  {isEnabled
                                                      ? capitalizeFirst(t("enabled"))
                                                      : capitalizeFirst(t("disabled"))}
                                              </div>
                                          </div>
                                          <div id={priceID} className="flex justify-end" style={{ width: "50%" }}>
                                              {hasPrice ? `${formatPriceValueVendure(priceBase, currency)}` : null}
                                          </div>
                                      </div>
                                      {isPickup ? (
                                          <div className="mt-2">
                                              <span>
                                                  {pickupEnabled && availableLocationsCount > 0
                                                      ? t("x locations", {
                                                            count: availableLocationsCount,
                                                        })
                                                      : null}
                                              </span>
                                          </div>
                                      ) : null}
                                  </div>
                              );
                          })
                        : null}
                    {deliveryHasPrice ? (
                        <div className="float-right text-sm text-gray-700">{t("tax-excluded")}</div>
                    ) : null}
                </div>
            </SettingsCard.Section>
            {hasOrdersManagement ? (
                <SettingsCard.Section
                    title={t("allow-orders-to-be-automatically-accepted")}
                    description={t("orders-will-be-automatically-accepted-and-printed")}
                    toggle={{
                        id: `${prefixID}-switch-allow-automatically-accepted-order`,
                        checked: autoAcceptOrders,
                        action: () => {
                            const setAutoAcceptOrders = !autoAcceptOrders;
                            updateConfig({
                                autoAcceptOrders: setAutoAcceptOrders,
                                orderCancellationAllowed: orderCancellationAllowed && !setAutoAcceptOrders,
                            }).then(() => {
                                toast.success(t("operation-successful"));
                            });
                        },
                    }}
                >
                    <div className=" w-full px-4">
                        <div className=" flex items-center mt-3 mb-2">
                            <span className={` ${autoAcceptOrders ? " text-gray-500" : ""} text-base font-bold `}>
                                {capitalizeFirst(t("allow-orders-cancelation"))}
                            </span>
                            <UseSwitch
                                id={`${prefixID}-switch-allow-orders-cancelation`}
                                adjust={"mt-2"}
                                checked={orderCancellationAllowed}
                                disabled={autoAcceptOrders}
                                action={() => {
                                    updateConfig({ orderCancellationAllowed: !orderCancellationAllowed }).then(() => {
                                        toast.success(t("operation-successful"));
                                    });
                                }}
                            />
                        </div>
                        <span className={` block mt-2 mb-4 ${autoAcceptOrders ? " text-gray-500" : ""}`}>
                            {t("customers-are-allowed-to-cancel-their-own-if-it-has-not-been-accepted-yet")}
                        </span>
                    </div>
                </SettingsCard.Section>
            ) : null}
            {hasOrdersManagement ? (
                <SettingsCard.Section title={t("delay-orders")} description={t("estimate-preparation-time-delayed")}>
                    <div className=" w-full px-4">
                        <div className=" flex w-full mt-2 mb-4 items-center">
                            <div className=" w-2/12 mr-4">
                                <UseInputNumberArrows
                                    inputData={{
                                        name: "time-delayed-shop-settings",
                                        id: "time-delayed-shop-settings",
                                    }}
                                    validation={true}
                                    value={delayOrders === null ? "" : delayOrders}
                                    onChange={(val) => {
                                        const newVal =
                                            val === "" ? null : parseInt(Math.max(Number(val), 0)).toString();
                                        setDelayOrders(newVal);
                                    }}
                                    id={`${prefixID}-input-number-time-delayed`}
                                    actionOnChange={(val) => {
                                        updateConfig({ delayedDeliveryThreshold: val }).then(() => {
                                            toast.success(t("operation-successful"));
                                        });
                                    }}
                                />
                            </div>

                            <div>{capitalizeFirst(t("approx-minutes"))}</div>
                        </div>
                    </div>
                </SettingsCard.Section>
            ) : null}
            <SettingsCard.Section
                title={t("allow-scheduled-delivery")}
                description={t("clients-could-schedule-order-24-hours")}
                toggle={{
                    id: `${prefixID}-switch-allow-scheduled-delivery`,
                    checked: allowScheduleDelivery,
                    action: () => {
                        updateConfig({ allowScheduleDelivery: !allowScheduleDelivery }).then(() => {
                            toast.success(t("operation-successful"));
                        });
                    },
                }}
            />
            <SettingsCard.Section
                title={t("number-of-products-allow-for-order")}
                toggle={{
                    id: `${prefixID}-switch-number-products-allowed-per-order`,
                    checked: limitMaxProductPerOrder,
                    action: () => {
                        updateConfig({ limitMaxProductPerOrder: !limitMaxProductPerOrder }).then(() => {
                            toast.success(t("operation-successful"));
                        });
                    },
                }}
            >
                <div className={` w-full px-4 mb-4 ${!hasOrdersManagement ? " pb-16" : ""} `}>
                    <div className=" flex items-center mt-4 w-full">
                        <div className=" w-2/12 mr-4">
                            <UseInputNumberArrows
                                inputData={{
                                    name: "number-products-allow-per-order",
                                    id: "number-products-allow-per-order",
                                }}
                                id={`${prefixID}-input-number-products-allow-per-order`}
                                validation={true}
                                value={productsPerOrder === null ? "" : productsPerOrder}
                                onChange={(val) => {
                                    const newVal = val === "" ? null : parseInt(Math.max(Number(val), 0)).toString();
                                    setProductsPerOrder(newVal);
                                }}
                                disabled={!limitMaxProductPerOrder}
                                actionOnChange={(val) => {
                                    if (limitMaxProductPerOrder) {
                                        updateConfig({ maxProductsPerOrder: val }).then(() => {
                                            toast.success(t("operation-successful"));
                                        });
                                    }
                                }}
                            />
                        </div>
                        <div className={`${!limitMaxProductPerOrder ? "text-gray-400" : ""}`}>
                            {capitalizeFirst(t("products"))}
                        </div>
                    </div>
                </div>
            </SettingsCard.Section>
        </SettingsCard>
    );
};

export default ShopSettingsOrdersDelivery;
