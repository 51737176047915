import React, { useState, useEffect, useMemo, useContext } from "react";

import TableContext from "./Context";

import Header from "./Header";
import Pages from "./Pages";
import { Body } from "./Body";
import { TableHeader } from "./TableHeader";
import { SearchDropDown } from "./SearchDropdown";
import { CheckList } from "./CheckList";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "hooks/Utils/Utils";
import FilterCCsGuestUsersContext from "components/Section/Hotel/context/FilterCCsGuestUsersContext";
import { SearchInput } from "./SearchInput";

/**
 * @description A table component
 * @param {Array} cols - The columns to display
 * @param {Array} rows - The rows to display
 * @param {Object} header - The header of the table
 * @param {Boolean} paginate - If the table should be paginated
 * @param {Boolean} search - If the table should have a search input
 * @param {Array} colsWidth - The width of each column
 * @param {Array} colsAlign - The alignment of each column
 * @param {Boolean} showCount - If the table should show the count of elements (default: true)
 * @param {Number} perPage - The number of elements per page (default: 6)
 * @returns {JSX.Element} The table component
 */
const ZTable = ({
    children,
    cols,
    rows,
    header,
    paginate,
    search,
    colsWidth,
    colsAlign,
    perPage,
    scrollable,
    availableFilters,
    filterData,
    showCount = true,
}) => {
    const [paginationIndex, setPaginationIndex] = useState(0);
    const [paginationLimit, setPaginationLimit] = useState(perPage);
    const [currentRows, setCurrentRows] = useState(rows);
    const [sort, setSort] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        if (paginate) {
            setCurrentRows(rows?.slice(paginationIndex, paginationIndex + paginationLimit));
        }
    }, [paginationIndex, paginationLimit]);

    useEffect(() => {
        setCurrentRows(rows);
    }, [rows]);

    const contextValue = useMemo(() => ({ header, cols, rows, sort, setSort }), [header, cols, rows, sort, setSort]); //sonar fix

    return (
        <TableContext.Provider value={contextValue}>
            <div>
                {search || showCount ? (
                    <div className="flex justify-between items-center py-2 mt-10">
                        <div>{search ? <SearchInput setSearchValue={setSearchValue} /> : null}</div>
                        {showCount ? <div className="text-gray-600">{`${rows?.length || 0} elements`}</div> : null}
                    </div>
                ) : null}
                {availableFilters ? (
                    <div className="flex items-center">
                        <p>{capitalizeFirst(t("filter-by"))}: </p>
                        <SearchDropDown filterData={filterData} title={t("device-model")} />
                        <CheckList filterData={filterData} title={t("ownership")} />
                    </div>
                ) : null}
                <div className={`${scrollable ? "overflow-scroll max-h-30" : ""}`}>
                    <table className="w-full mt-6">
                        {children || (
                            <>
                                <TableHeader colsWidth={colsWidth} header={header} />
                                <Body
                                    cols={cols}
                                    currentRows={currentRows}
                                    colsAlign={colsAlign}
                                    sort={sort}
                                    searchValue={searchValue}
                                />
                            </>
                        )}
                    </table>
                </div>
                {paginate ? (
                    <Pages
                        total={rows?.length}
                        perPage={perPage}
                        onChange={({ index, limit }) => {
                            setPaginationIndex(index);
                            setPaginationLimit(limit);
                        }}
                    />
                ) : null}
            </div>
        </TableContext.Provider>
    );
};

ZTable.Header = Header;
ZTable.Pages = Pages;

export default ZTable;
