import { gql } from "apollo-boost";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeGlobalAction, cleanAction } from "../../../actions/globalActions";
import { useMSQuery } from "../useMSQuery";
import {
    setError,
    setLoading,
    cleanTableStore,
    cleanSortCriteria,
    setRefreshData,
    setHeaders,
    setTableResults,
    setHeaderButtons,
    setSearchable,
    setTableType,
    setCount,
    setCountPosition,
    cleanActiveFilters,
    setFormNewContent,
    setCustomConfig,
    setCustomError,
} from "../../../actions/tableActions";
import GetTableHeaders from "../../Utils/Table/Headers/GetHotelTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import AccessProfile from "./models/AccessProfile";
import { setExecuteQuery, showRowForm } from "../../../actions/sectionActions";
import _ from "lodash";
import { capitalizeFirst, POLLINTERVAL_15S } from "../../Utils/Utils";
import { openModal, setModalContent } from "../../../actions/uiActions";

const ListAccessProfiles = (setNotConfigutarionAccessTypeWarning) => {
    const { t } = useTranslation();
    //Store data
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);
    const { rowForm } = useSelector((state) => state.sectionContent);
    //Data
    const sectionName = `list-access-profiles`;

    //States
    const [sorting] = useState(`,orderBy:{field: "name", criteria: "ASC"}`);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_ACCESS_PROFILES = gql`
        {
            allWifiAccessProfiles: wifiAccessProfiles {
                results {
                    id
                    name
                    accesstypes {
                        id
                        accesstypes_name
                    }
                }
            }
            wifiAccessProfiles: wifiAccessProfiles (page:${currentPage}, size:${perPage} ${filterQuery}${
        sortCriteria ? sortCriteria : sorting
    }){
                info {
                    count
                    page
                    size
                }
                results {
                    id
                    name
                    downloadbwmax
                    downloadbwmin
                    uploadbwmax
                    uploadbwmin
                    accesstypes{
                        id
                        accesstypes_name
                    }
                }
            }
            wifiAccessTypes: wifiAccessTypes {
                results {
                    id
                    accesstypes_name
                }
            }
        }
    `;
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_ACCESS_PROFILES, {
        // fetchPolicy: "network-only",
        pollInterval: POLLINTERVAL_15S,
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        executeQuery();
        return () => {
            dispatch(setCustomConfig(null));
            dispatch(cleanTableStore());
            dispatch(cleanActiveFilters());
            dispatch(cleanAction());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData && refetch) {
            dispatch(setLoading(true));
            dispatch(cleanSortCriteria());
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        if (rowForm) {
            return;
        }
        if (!loading && !error && data && data.wifiAccessProfiles && networkStatus === 7) {
            //Get data
            const dataItems = arrangeData(data.wifiAccessProfiles, arrangeWifiAccessTypes(data.wifiAccessTypes));
            //update states.
            if (setNotConfigutarionAccessTypeWarning)
                setNotConfigutarionAccessTypeWarning(
                    showNotConfigutarionAccessTypeWarning(data.allWifiAccessProfiles, data.wifiAccessTypes)
                );
            //Add to store
            dispatch(setHeaders(GetTableHeaders(sectionName)));
            dispatch(setTableResults(dataItems));
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            dispatch(setTableType(sectionName));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            dispatch(setCount(data.wifiAccessProfiles.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(showRowForm(false));
            dispatch(
                setFormNewContent({
                    data: null,
                })
            );
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
            dispatch(setCustomError(t("no-access-profile-yet")));
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    //handle&functions
    const handleChangeAccessType = (itemData) => {
        let oldIDs = itemData.oldIDs ? itemData.oldIDs : [];
        let newIDs = oldIDs.concat(itemData.newIDs ? itemData.newIDs : []);
        let duplicateElements = findDuplicates(newIDs);
        let differentWifiAccessProfiles = data.allWifiAccessProfiles.results.filter(
            (wifiAccessProfile) => wifiAccessProfile.id !== itemData.id
        );
        let accessTypesAlreadyInUse = [];
        if (duplicateElements != null && duplicateElements.length > 0)
            newIDs = newIDs.filter((newID) => !duplicateElements.includes(newID));
        if (newIDs && newIDs.length > 0) {
            if (differentWifiAccessProfiles && differentWifiAccessProfiles.length > 0) {
                // eslint-disable-next-line
                differentWifiAccessProfiles.map((differentWifiAccessProfile) => {
                    if (
                        differentWifiAccessProfile &&
                        differentWifiAccessProfile.accesstypes &&
                        differentWifiAccessProfile.accesstypes.length > 0
                    ) {
                        accessTypesAlreadyInUse = [
                            ...accessTypesAlreadyInUse,
                            ...differentWifiAccessProfile.accesstypes,
                        ];
                    }
                });
                accessTypesAlreadyInUse = accessTypesAlreadyInUse.filter((accessTypeAlreadyInUse) =>
                    newIDs.includes(accessTypeAlreadyInUse.id)
                );
            }
        }
        const params = {
            id: itemData.id,
            oldIDs: oldIDs.join(","),
            newIDs: newIDs.join(","),
        };
        if (accessTypesAlreadyInUse.length > 0) {
            accessTypesAlreadyInUse = _.map(
                _.uniqBy(accessTypesAlreadyInUse, "accesstypes_name"),
                function (accesssType) {
                    return translateWifiAccessType(accesssType.accesstypes_name);
                }
            );
            const modal = {
                id: itemData.id,
                title: "edit-access-type",
                value: itemData.name,
                useGlobalLoading: true,
                bodyAdjust: "w-3/12",
                executeSectionQuery: true,
                inputs: [
                    {
                        type: "void",
                        text: t("access-type-already-used", { accessTypes: accessTypesAlreadyInUse.join(", ") }),
                    },
                    {
                        type: "void",
                        text: t("access-type-already-used-confirm"),
                    },
                    {
                        type: "void",
                        text: t("access-type-already-used-description", {
                            accessTypes: accessTypesAlreadyInUse.join(", "),
                            accessProfile: itemData.name,
                        }),
                    },
                    {
                        type: "data",
                        value: params,
                    },
                ],
                buttons: [
                    { name: "cancel", style: "white", action: "close" },
                    {
                        name: "accept",
                        style: "blue",
                        action: "edit-access-profile-access-type",
                    },
                ],
            };
            dispatch(showRowForm(false));
            dispatch(setExecuteQuery(null));
            dispatch(
                changeGlobalAction({
                    actionName: "edit-access-profile-access-type",
                    itemsAffected: itemData.id,
                })
            );
            dispatch(setModalContent(modal));
            dispatch(openModal());
        } else {
            dispatch(showRowForm(false));
            dispatch(setLoading(true));
            dispatch(
                setExecuteQuery({
                    action: "edit-access-profile-access-type",
                    params: params,
                })
            );
        }
    };
    const findDuplicates = (arr) => {
        let sorted_arr = arr.slice().sort();
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
            if (sorted_arr[i + 1] === sorted_arr[i]) {
                results.push(sorted_arr[i]);
            }
        }
        return results;
    };

    const showNotConfigutarionAccessTypeWarning = (wifiAccessProfiles, wifiAccessTypes) => {
        let response = { show: false, accessTypes: [] };
        if (wifiAccessTypes && wifiAccessTypes.results && wifiAccessTypes.results.length > 0) {
            wifiAccessTypes.results
                .filter((wifiAccessType) => wifiAccessType.accesstypes_name !== "code")
                // eslint-disable-next-line
                .map((wifiAccessType) => {
                    let name = capitalizeFirst(translateWifiAccessType(wifiAccessType.accesstypes_name));
                    if (!response.accessTypes.includes(name)) {
                        let add = true;
                        if (wifiAccessProfiles && wifiAccessProfiles.results && wifiAccessProfiles.results.length > 0) {
                            // eslint-disable-next-line
                            wifiAccessProfiles.results.map((wifiAccessProfile) => {
                                if (
                                    wifiAccessProfile &&
                                    wifiAccessProfile.accesstypes &&
                                    wifiAccessProfile.accesstypes.length > 0
                                ) {
                                    if (
                                        wifiAccessProfile.accesstypes.filter(
                                            (accesstype) =>
                                                accesstype.accesstypes_name === wifiAccessType.accesstypes_name
                                        ).length > 0
                                    ) {
                                        add = false;
                                    }
                                }
                            });
                        }
                        if (add) {
                            response.accessTypes.push(name);
                        }
                    }
                });
        }
        if (response.accessTypes.length > 0) response.show = true;
        return response;
    };

    //arrangeFunctions
    const translateWifiAccessType = (name) => {
        let response = name;
        if (name && name !== "") {
            switch (name) {
                case "email":
                    response = t("email");
                    break;
                case "pms":
                    response = t("room-surname");
                    break;
                case "open":
                    response = t("open-access");
                    break;
                case "mac":
                    response = t("mac-exceptions");
                    break;
                default:
                    t(response);
                    break;
            }
        }
        return response;
    };

    const arrangeWifiAccessTypes = (wifiAccessTypes) => {
        let wifiAccessTypesGrouped = [];
        if (wifiAccessTypes && wifiAccessTypes.results && wifiAccessTypes.results.length > 0) {
            wifiAccessTypes.results
                .filter((wifiAccessType) => wifiAccessType.accesstypes_name !== "code")
                // eslint-disable-next-line
                .map((wifiAccessType) => {
                    let index = _.findIndex(
                        wifiAccessTypesGrouped,
                        (wifiAccessTypeGrouped) => wifiAccessTypeGrouped.value === wifiAccessType.accesstypes_name
                    );
                    if (index > -1) {
                        wifiAccessTypesGrouped[index].ids.push(wifiAccessType.id);
                    } else {
                        wifiAccessTypesGrouped.push({
                            value: wifiAccessType.accesstypes_name,
                            name: translateWifiAccessType(wifiAccessType.accesstypes_name),
                            ids: [wifiAccessType.id],
                        });
                    }
                });
        }

        const predefinedOrder = ["email", "pms", "code", "open", "mac"];
        wifiAccessTypesGrouped.sort((a, b) => {
            return predefinedOrder.indexOf(a.value) - predefinedOrder.indexOf(b.value);
        });

        return wifiAccessTypesGrouped;
    };

    const arrangeData = (wifiAccessProfiles, wifiAccessTypes) => {
        const response = [];
        // eslint-disable-next-line
        wifiAccessProfiles.results.map((wifiAccessProfile) => {
            response.push(AccessProfile(wifiAccessProfile, wifiAccessTypes, handleChangeAccessType, t));
        });
        return response;
    };
};

export default ListAccessProfiles;
