import NewRoomGroupModal from "../modals/NewRoomGroupModal";
import DeleteRoomGroupModal from "../modals/DeleteRoomGroupModal";
import RoomGroupViewModal from "../modals/RoomGroupViewModal";
const RoomGroup = (roomGroup, t) => {
    const dataType = "room-group";
    roomGroup = {
        ...roomGroup,
        dataType: dataType,
        info: [
            {
                name: "name",
                value: roomGroup.name,
                cellContent: "text",
                specialWidth: "w-4/12",
            },
            {
                name: "edit-room-group",
                value: [roomGroup.numberRooms, " ", roomGroup.numberRooms === 1 ? t("room") : t("rooms")],
                cellContent: roomGroup.numberRooms < 1 ? "text" : "edit-modal",
                modal: RoomGroupViewModal(roomGroup.name, roomGroup.id),
                specialWidth: "w-6/12",
            },
            {
                name: "room-group",
                cellContent: "icon",
                isAction: true,
                modal: NewRoomGroupModal(roomGroup.name, roomGroup.id),
                icon: {
                    name: "edit",
                },
                specialWidth: "w-1/12",
            },
            {
                name: "room-group",
                cellContent: "icon",
                isAction: true,
                modal: DeleteRoomGroupModal(roomGroup.id, roomGroup.name),
                icon: {
                    name: "delete",
                },
                specialWidth: "w-1/12",
            },
        ],
    };
    return roomGroup;
};

export default RoomGroup;
