import DeleteGroupModal from "../modals/DeleteGroupModal";
import NewGuestGroupModal from "../modals/NewGuestGroupModal";
import GuestListViewModal from "../modals/GuestListViewModal";
const Group = (group, data, langStrings) => {
    const { id, name, guestNumber } = group;

    const dataType = "guest-groups";
    return {
        dataType: dataType,
        id: id,
        info: [
            {
                name: "group-name",
                cellContent: "text",
                value: name,
                specialWidth: "w-4/12",
            },
            {
                name: "edit-guest-group",
                cellContent: guestNumber < 1 ? "text" : "edit-modal",
                modal: GuestListViewModal(data, name, id, langStrings),
                value: [guestNumber, " ", guestNumber === 1 ? langStrings["guest"] : langStrings["guests"]],
                specialWidth: "w-6/12",
            },
            {
                name: "guest-group",
                cellContent: "icon",
                isAction: true,
                modal: NewGuestGroupModal(name, id),
                icon: {
                    name: "edit",
                },
                /* cellContent: getManagementCellContent(bookingAllowed, currentStatus),
                value: getBookingManagementContent(
                  currentStatus,
                  langStrings["temp-closed"],
                  langStrings["manage-bookings"]
                ),
                link: getLinkContent(bookingAllowed, id),
                adjust: getManagementAdjust(currentStatus),
                hidden: getManagementVisibility(bookingAllowed, currentStatus),*/
                specialWidth: "w-1/12",
            },
            {
                name: "guest-group",
                cellContent: "icon",
                isAction: true,
                modal: DeleteGroupModal(id, name),
                icon: {
                    name: "delete",
                },
                /* cellContent: getManagementCellContent(bookingAllowed, currentStatus),
                value: getBookingManagementContent(
                  currentStatus,
                  langStrings["temp-closed"],
                  langStrings["manage-bookings"]
                ),
                link: getLinkContent(bookingAllowed, id),
                adjust: getManagementAdjust(currentStatus),
                hidden: getManagementVisibility(bookingAllowed, currentStatus),*/
                specialWidth: "w-1/12",
            },
        ],
    };
};

export default Group;
