import { useState } from "react";
import _ from "lodash";

import useDropdown from "./useDropdown";

const useSelect = (props) => {
    const config = {
        multiple: false,
        value: null,
        ...props,
    };

    const [value, setValue] = useState(config.value);

    const Dropdown = useDropdown(config);

    const isSelected = (comparedValue) => {
        let isSelected = false;
        if (config.multiple) {
            let result = _.findIndex(value, function (v) {
                return _.isEqual(comparedValue, v);
            });
            isSelected = result !== -1;
        } else {
            isSelected = _.isEqual(comparedValue, value);
        }
        return isSelected;
    };

    return {
        ...Dropdown,
        value,
        setValue,
        defaultValue: config.value,
        removeValue: (set) => {
            if (!config.multiple) {
                return setValue(null);
            } else {
                let newval = value;
                let result = _.findIndex(value, function (v) {
                    return _.isEqual(set, v);
                });
                newval.splice(result, 1);
                return setValue([...newval]);
            }
        },
        addValue: (set) => {
            if (!config.multiple) {
                return setValue(set);
            } else {
                let newval = value || [];
                newval.push(set);
                return setValue([...newval]);
            }
        },
        isSelected,
    };
};

export default useSelect;
