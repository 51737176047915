import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate as useHistory } from "react-router-dom";
import UseRadio from "../../useRadio";

const UseListCell = ({ cellData }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);
    const { t } = useTranslation();

    //Data
    const { listValues, name, title, adjust, row, column } = cellData || {};

    const history = useHistory();

    const renderItem = (listValue, index) => {
        const {
            hidden,
            evaluate,
            name,
            noShowName,
            nameAdjust,
            valueAdjust,
            dataType,
            value,
            icon,
            iconRight,
            iconAdjust,
            itemType,
            itemData,
            noColon,
            link,
        } = listValue;

        const onClickCell = (e) => {
            e.preventDefault();
            if (link) {
                history(link);
            }
        };

        let response = (
            <span key={index}>
                {!hidden ? (
                    <div className="block first-capital" onClick={(e) => onClickCell(e)}>
                        {icon && !iconRight ? (
                            <>
                                <div className={`icon-${icon} ${iconAdjust} float-left`}></div>
                            </>
                        ) : null}
                        {!itemType ? (
                            <>
                                {evaluate ? (
                                    <span className={`${nameAdjust}`}>
                                        {langStrings[`${name}-${dataType}-${value}`]}
                                    </span>
                                ) : name && !noShowName && value ? (
                                    <>
                                        <span className={`${nameAdjust}`}>
                                            {langStrings[name]}
                                            {!noColon ? ":" : null}{" "}
                                        </span>
                                        <span className={`${valueAdjust}`}>{value}</span>
                                    </>
                                ) : (
                                    <span id={`item-r${row}-c${column}-p${index}`} className={`${valueAdjust}`}>
                                        {value}
                                    </span>
                                )}
                            </>
                        ) : itemType === "radio" ? (
                            <UseRadio {...itemData} />
                        ) : null}
                        {icon && iconRight ? (
                            <>
                                <div className={`icon-${icon} ${iconAdjust} inline-block`}></div>
                            </>
                        ) : null}
                    </div>
                ) : null}
            </span>
        );
        return response;
    };

    return (
        <div className={`t-cell-list mr-1 ${adjust}`}>
            {title ? <div className="font-bold mb-2 first-capital">{t(name)}</div> : null}
            {listValues.map((listValue, index) => renderItem(listValue, index))}
        </div>
    );
};

export default UseListCell;
