import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

import { EditorContext, WidgetContext } from "../../../../../../contexts/editor";
import { setExecuteQuery } from "../../../../../../actions/sectionActions";
import Widget from "../../Widget";

const InputIcon = ({ toolbarID }) => {
    const { deviceType } = useContext(EditorContext);
    const { id: widgetID } = useContext(WidgetContext);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { gridItems } = useSelector((state) => state.design);

    const [selectIcons, setSelectIcons] = useState(false);

    //
    const RESULTS_INCREMENT = 50;
    let show = 0;

    library.add(fas);
    library.add(fab);
    let fasArray = Object.keys(library.definitions.fas);
    fasArray.splice(fasArray.indexOf("font-awesome-logo-full"), 1);
    let fabArray = Object.keys(library.definitions.fab);
    fabArray.splice(fabArray.indexOf("font-awesome-logo-full"), 1);
    fabArray.splice(fabArray.indexOf("angry"), 1);

    const allIcons = [
        { lib: "fa", icons: fasArray },
        { lib: "fab", icons: fabArray },
    ];
    const allIconsLength = allIcons[0].icons.length + allIcons[1].icons.length;

    const [search, setSearch] = useState(null);
    const [iconsShowing, setIconsShowing] = useState(RESULTS_INCREMENT);
    const [activeTimeout, setActiveTimeout] = useState(null);

    const icons = search
        ? [...allIcons].filter((set) => {
              if (search && set && set.icons) {
                  set.icons = set.icons.filter((icon) => icon.toLowerCase().includes(search.toLowerCase()));
                  return set.icons.length > 0;
              }
              return false;
          })
        : allIcons;

    const findIcons = (e) => {
        const value = e.target.value;
        if (activeTimeout) clearTimeout(activeTimeout);
        setActiveTimeout(setTimeout(() => setSearch(value), 333));
    };

    const onScrollCallback = (e) => {
        if (e.target.scrollTop + e.target.offsetHeight >= e.target.scrollHeight && iconsShowing < allIconsLength) {
            setIconsShowing(iconsShowing + RESULTS_INCREMENT);
        }
    };
    //

    const widget = gridItems ? gridItems.filter((w) => w && w.i === widgetID)[0] : null;
    const data = Widget.ParseData(widget ? widget.data : null, deviceType);
    const icon =
        data && data.icon
            ? {
                  icon: data.icon,
                  lib: data.lib || "fa",
              }
            : null;

    const updateValue = (icon, lib) => {
        dispatch(
            setExecuteQuery(
                Widget.UpdateData({
                    widget,

                    value: {
                        ...widget.data,
                        icon: icon,
                        lib: lib,
                    },
                })
            )
        );
    };
    const updateIcon = (icon, lib) => {
        setSelectIcons(false);
        updateValue(icon, lib);
    };

    return (
        <div className={"my-6 full-h align-middle"}>
            {selectIcons ? (
                <div className="max-w-sm mt-4">
                    <div className="relative">
                        <input
                            id={`icon_search_input`}
                            type="text"
                            className="t-filter-search  m-0 w-full"
                            placeholder={`${t("search")}...`}
                            onChange={findIcons}
                        ></input>
                        <span className="field-icon icon-search table mt-4" style={{ height: "2rem" }}></span>
                    </div>
                    <div
                        className={`mt-3 h-64 overflow-y-scroll overflow-x-hidden`}
                        onScroll={(e) => onScrollCallback(e)}
                    >
                        {icons
                            ? icons.map((set) =>
                                  set && set.icons
                                      ? set.icons.map((icon) =>
                                            search || (!search && show++ && show < iconsShowing) ? (
                                                <div
                                                    key={`${set.lib}-${icon} `}
                                                    className={`p-3 float-left border  mr-2 mb-2 rounded w-12 cursor-pointer text-center ${
                                                        icon && icon === icon.icon
                                                            ? "border-zafiro-200"
                                                            : "border-gray-200"
                                                    }`}
                                                    onClick={(e) => updateIcon(icon, set.lib)}
                                                >
                                                    <FontAwesomeIcon icon={[set.lib, icon]} size={"lg"} />
                                                </div>
                                            ) : null
                                        )
                                      : null
                              )
                            : null}
                    </div>
                </div>
            ) : (
                <>
                    <div
                        onClick={() => setSelectIcons(!selectIcons)}
                        className="inline-block border border-gray-200 p-2"
                    >
                        {icon ? <FontAwesomeIcon size="2x" icon={[icon.lib, icon.icon]} /> : "      "}
                    </div>
                    <div
                        onClick={() => setSelectIcons(!selectIcons)}
                        className="inline-block ml-10 cursor-pointer text-zafiro-400"
                    >
                        {t("Choose icon")}
                    </div>
                </>
            )}
        </div>
    );
};

export default InputIcon;
