import React from "react";

/**
 * This component is a icon with a tooltip
 * @component
 * @param {string} children The content of the tooltip
 * @param {(info | warning)} icon The icon to be displayed (default: info)
 * @returns {JSX.Element} The UI of the component
 * @example <InfoIcon>This is a tooltip</InfoIcon>
 */
const InfoIcon = ({ children, icon }) => {
    let iconClass;
    switch (icon) {
        case "warning":
            iconClass = "icon icon-warning text-orange-100";
            break;
        case "info":
        default:
            iconClass = "icon icon-info text-gray-700";
            break;
    }
    return (
        <span
            className={`${iconClass} ml-2 text-xl lg:text-2xl `}
            data-tip={children}
            data-for="dangerous-html-tooltip"
        ></span>
    );
};

export default InfoIcon;
