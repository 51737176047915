import React, { useState, useEffect } from "react";

const Pages = ({ total, onChange, perPage = 6 }) => {
    const [page, setPage] = useState(1);

    const pages = total > 0 ? Math.ceil(total / perPage) : 0;
    const pageIndex = (page - 1) * perPage;

    useEffect(() => {
        if (onChange) {
            onChange({ page, index: pageIndex, limit: perPage });
        }
    }, [page, pageIndex]);

    if (pages === 0) {
        return null;
    }

    return (
        <div className="flex justify-center items-center py-2">
            <button
                className={`px-3 py-1 mx-1 ${page === 1 ? "text-gray-400" : ""}`}
                disabled={page === 1}
                onClick={page === 1 ? null : () => setPage(page - 1)}
            >
                {"< Anterior"}
            </button>
            {Array.from({ length: pages }, (_, index) => (
                <button
                    key={`page_${index}`}
                    className={`px-3 py-1 mx-1 rounded-full border ${page === index + 1 ? "bg-gray-200" : "bg-white"}`}
                    onClick={() => setPage(index + 1)}
                >
                    {index + 1}
                </button>
            ))}
            <button
                className={`px-3 py-1 mx-1 ${page === pages ? "text-gray-400" : ""}`}
                disabled={page === pages}
                onClick={page === pages ? null : () => setPage(page + 1)}
            >
                {"Siguiente >"}
            </button>
        </div>
    );
};

export default Pages;
