import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
//Components
import UseSectionHeader from "../../useSectionHeader";
import WifiSettingsConfig from "../../../hooks/GraphqlCalls/Hotel/WifiSettingsConfig";
import UseNetworkGrid from "../../useNetworkGrid";
import { setActionInput } from "../../../actions/globalActions";
import SetWifiSettingsConfig from "../../../hooks/GraphqlCalls/Hotel/SetWifiSettingsConfing";
import { setNetworks } from "../../../actions/hotelActions";

const WifiSettings = () => {
    WifiSettingsConfig();
    SetWifiSettingsConfig();
    //Actions
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setActionInput({ save: false, refreshData: false }));
        return () => {
            dispatch(setActionInput(null));
            dispatch(setNetworks(false));
        };
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <UseSectionHeader title={["wifi-settings"]} />
            <div style={{ height: "75vh" }}>
                <UseNetworkGrid gridStyles="grid grid-rows-1 grid-flow-col gap-4" />
            </div>
        </>
    );
};
export default WifiSettings;
