import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Components
import UseDashboardCards from "../../../components/Section/DasboardCards/useDashboardCards";
import IntallationMap from "../../../components/InstallationMap";
import TopologyMap from "../../../components/Section/Hotel/Settings/TopologyMap";
//Actions
import { setDashboardCards, showGlobalLoading } from "../../../actions/uiActions";
import { setSectionContent, setRefreshContentData } from "../../../actions/sectionActions";

//Utils
import { POLLINTERVAL_15S, removeDiacritics } from "../../Utils/Utils";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const GetHotelMap = ({ topology }) => {
    const [filters, setFilters] = useState("");
    const [res, setRes] = useState([]);

    const { refreshData } = useSelector((state) => state.sectionContent);

    const dispatch = useDispatch();

    //API Call
    const GET_HOTEL_MAP = gql`
        {
            installationMap {
                info {
                    count
                }
                results {
                    id
                    name
                    pos
                    defaultPmsFormat
                    floors {
                        id
                        name
                        order
                        rooms {
                            id
                            name
                            numRoomTV
                            number
                            residents
                            status
                            recentGuests
                            warnings {
                                roomID
                                date
                                info
                                level
                                model
                                function
                            }
                        }
                    }
                }
            }
        }
    `;
    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_HOTEL_MAP, {
        pollInterval: POLLINTERVAL_15S,
    });

    const hotelMap = getTotals(data);
    let { wings, floors, rooms, warnings } = hotelMap;

    const loopWings = () => {
        let mapData = [];
        //Temp data format due to lack of status and warning data
        // eslint-disable-next-line
        data.installationMap.results.map((wing) => {
            let tempWing = {
                id: wing.id,
                name: wing.name,
                pos: wing.pos,
                defaultPmsFormat: wing.defaultPmsFormat,
                isWing: true,
                warnings:
                    wing.floors.filter(
                        (floor) =>
                            floor.rooms.filter(
                                (room) => room.warnings && room.warnings.filter((warn) => isWarning(warn)).length > 0
                            ).length > 0
                    ).length > 0,
                floors: [],
            };
            // eslint-disable-next-line
            wing.floors.map((floor) => {
                let tempFloor = {
                    id: floor.id,
                    name: floor.name,
                    order: floor.order,
                    isFloor: true,
                    warnings:
                        floor.rooms.filter(
                            (room) => room.warnings && room.warnings.filter((warn) => isWarning(warn)).length > 0
                        ).length > 0,
                    rooms: [],
                };
                // eslint-disable-next-line
                floor.rooms.map((room) => {
                    let tempRoom = {
                        id: room.id,
                        name: room.name,
                        numRoomTV: room.numRoomTV,
                        number: room.number,
                        isRoom: true,
                        status: room.residents && room.residents > 0 ? true : false,
                        recentGuests: room.recentGuests,
                        warnings:
                            room.warnings && room.warnings.filter((warn) => isWarning(warn)).length > 0
                                ? room.warnings
                                : false,
                    };
                    if (tempRoom.warnings) {
                        warnings++;
                    }
                    if (filters && filters !== "") {
                        if (
                            removeDiacritics(room.name.toLowerCase()).indexOf(removeDiacritics(filters.toLowerCase())) >
                                -1 ||
                            String(room.number).includes(removeDiacritics(filters.toLowerCase()))
                        ) {
                            tempFloor.rooms.push(tempRoom);
                        }
                    } else {
                        tempFloor.rooms.push(tempRoom);
                    }
                });
                tempWing.floors.push(tempFloor);
            });
            mapData.push(tempWing);
        });
        setRes(mapData);
        if (topology) {
            dispatch(
                setSectionContent({
                    data: mapData,
                })
            );
        }
    };

    //Data to dashboard cards
    useEffect(() => {
        if (!loading && !error && data && data.installationMap) {
            dispatch(showGlobalLoading(false));
            loopWings();
            //Prepare dashboardcards totals...
            const hotelMap = getTotals(data);
            const { wings, floors, rooms, warnings } = hotelMap;
            const dashboardCards = getDashboardCards(wings, floors, rooms, warnings);
            dispatch(setDashboardCards(dashboardCards));
        }
        // eslint-disable-next-line
    }, [data]);

    //DashboardCards to show
    const dashboardCards = getDashboardCards(wings, floors, rooms, warnings);

    //Filter change listener
    useEffect(() => {
        if (data?.installationMap) {
            loopWings();
        }
        // eslint-disable-next-line
    }, [filters]);

    useEffect(() => {
        dispatch(showGlobalLoading(true));
        dispatch(setDashboardCards(null));
        executeQuery();
        return () => {
            dispatch(setDashboardCards(null));
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(showGlobalLoading(true));
            if (refetch) {
                refetch();
            }
            setTimeout(function () {
                dispatch(showGlobalLoading(false));
            }, 5000);
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    if (dashboardCards.wings) {
        dispatch(setDashboardCards(dashboardCards));
    }

    return (
        <>
            {topology ? (
                <DndProvider backend={HTML5Backend}>
                    <TopologyMap res={res} filters={filters} setFilters={setFilters}></TopologyMap>
                </DndProvider>
            ) : (
                <>
                    <UseDashboardCards />
                    <IntallationMap res={res} filters={filters} setFilters={setFilters}></IntallationMap>
                </>
            )}
        </>
    );
};

const getDashboardCards = (wings, floors, rooms, warnings) => {
    return [
        {
            color: "white",
            headerNumber: wings,
            bodyText: "wings",
            icons: [{ name: "wing" }],
            visible: true,
            noHoverShadow: true,
        },
        {
            color: "white",
            headerNumber: floors,
            bodyText: "floors",
            icons: [{ name: "floor" }],
            visible: true,
            noHoverShadow: true,
        },
        {
            color: "white",
            headerNumber: rooms,
            bodyText: "rooms",
            icons: [{ name: "room" }],
            visible: true,
            noHoverShadow: true,
        },
        {
            color: "white",
            headerNumber: warnings,
            bodyText: "warnings",
            icons: [{ name: "warning" }],
            visible: true,
            noHoverShadow: true,
        },
    ];
};

const isWarning = (warning) => {
    if (warning.level < 2) {
        return true;
    }

    return false;
};

const getTotals = (data) => {
    let wings = 0;
    let floors = 0;
    let rooms = 0;
    let warnings = 0;
    if (data?.installationMap) {
        wings = data?.installationMap.results.length;
        // eslint-disable-next-line
        data.installationMap.results.map((wing) => {
            floors = floors + wing.floors.length;
            // eslint-disable-next-line
            wing.floors.map((floor) => {
                rooms += floor.rooms.length;
                // eslint-disable-next-line
                floor.rooms.map((room) => {
                    if (room.warnings) {
                        room.warnings.map((warning) => {
                            if (!isWarning(warning)) {
                                return null;
                            }
                            warnings++;
                            return warning;
                        });
                    }
                });
            });
        });
    }
    return { wings, floors, rooms, warnings };
};

export default GetHotelMap;
