import React from "react";
import { useTranslation } from "react-i18next";
import html from "sanitize-html";

/**
 * This component renders a room change info icon with a tooltip
 *
 * @component
 * @param {string} current The current room name
 * @param {string} previous The previous room name
 * @param {string} className The class name to apply to the component
 * @returns {null|JSX.Element} The room change info component
 * @example <RoomChangeInfo current="Presidential Suite" previous="Room 102" className="text-gray-600" />
 */
const RoomChangeInfo = ({ current = "", previous = "", className = "" }) => {
    const { t } = useTranslation();

    if (!previous || !current || current === previous) {
        return null;
    }

    const textInfo = t("There has been a room change");
    const htmlTooltip = `${textInfo} <span class="text-gray-700">${html(previous)}</span> &#8594; ${html(current)}`;

    return (
        <div
            className={`inline-block text-2xl icon-replace ${className}`}
            data-tip={htmlTooltip}
            data-for="dangerous-html-tooltip"
        ></div>
    );
};

export default RoomChangeInfo;
