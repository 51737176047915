import { useEffect, useState } from "react";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
    setCheckable,
    setHeaders,
    setTableResults,
    setAvailableFilters,
    setLoading,
    setError,
    setCount,
    setActions,
    setExtendedInfo,
    setColumns,
    setBatchActive,
    setSearchable,
    setRefreshData,
    setCountPosition,
    setTableType,
} from "../../../../actions/tableActions";
import GetTableHeaders from "../../../Utils/Table/Headers/GetHotelTableHeaders";
import HotelRoom from "./models/HotelRoom";
import GetAvailableFilters from "../../../Utils/GetAvailableFilters";
import { useMSQuery } from "../../useMSQuery";
//Others
import { Session } from "../../../Utils/Session";
import _ from "lodash";

function ListRooms() {
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);
    const { permissions } = useSelector((state) => state.ui);
    const guestAccess = permissions && permissions.product && permissions.product.guest;
    const [sorting] = useState(`,orderBy:{field: "room", criteria: "ASC"}`);
    const sectionName = "list-rooms";
    const dispatch = useDispatch();
    //API Call
    const GET_HOTEL_ROOMS_STATIC = gql`
        {
            hotelRoomsStatic: hotelRooms (${filterQuery}, layout:{page:${currentPage}, size:${perPage} ${
        sortCriteria ? sortCriteria : sorting
    }}) 
            {
                info { count }
                results { id roomNumber name group guests{id} warnings}
            }
        }
    `;
    const GET_HOTEL_ROOMS_POLLING = gql`
        {
            hotelRoomsPolling: hotelRooms (${filterQuery}, layout:{page:${currentPage}, size:${perPage} ${
        sortCriteria ? sortCriteria : sorting
    }}) 
            {
                info { count }
                results { id  status stayFrom stayTo lastStay warnings guests { id name }}
            }
        }
    `;
    const GET_FILTERS_DATA = gql`
        {
            hotelMap: installationMap {
                info {
                    count
                }
                results {
                    id
                    name
                    floors {
                        id
                        name
                    }
                }
            }
            roomGroups: roomGroups {
                info {
                    count
                }
                results {
                    id
                    name
                }
            }
        }
    `;

    const [executeRoomStaticQuery, hotelRoomStatic] = useMSQuery(GET_HOTEL_ROOMS_STATIC);
    const [executeRoomPollingQuery, hotelRoomPolling] = useMSQuery(GET_HOTEL_ROOMS_POLLING);
    const [executeFiltersQuery, filtersData] = useLazyQuery(GET_FILTERS_DATA, { fetchPolicy: "network-only" });

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            hotelRoomStatic.refetch();
            hotelRoomPolling.refetch();
            filtersData.refetch();
            dispatch(setRefreshData(false));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (hotelRoomStatic.error) {
            dispatch(setError(hotelRoomStatic.error));
        }
        if (hotelRoomPolling.error) {
            dispatch(setError(hotelRoomPolling.error));
        }
        if (filtersData.error) {
            dispatch(setError(filtersData.error));
        }
        // eslint-disable-next-line
    }, [hotelRoomStatic.error, hotelRoomPolling.error, filtersData.error]);

    useEffect(() => {
        if (
            !filtersData.error &&
            !filtersData.loading &&
            filtersData.data &&
            filtersData.data.hotelMap &&
            filtersData.data.hotelMap.results &&
            filtersData.data.roomGroups &&
            filtersData.data.roomGroups.results
        ) {
            const hotelMap = getHotelMap(filtersData.data.hotelMap.results);
            const hotelRoomGroups = getRoomGroups(filtersData.data.roomGroups.results);
            const availableFilters = GetAvailableFilters(sectionName, hotelMap, hotelRoomGroups, null, guestAccess);
            dispatch(setAvailableFilters(availableFilters));
        }
        // eslint-disable-next-line
    }, [filtersData]);

    useEffect(() => {
        dispatch(setLoading(true));
        executeRoomStaticQuery();
        executeRoomPollingQuery();
        executeFiltersQuery();
        dispatch(setTableType(sectionName));
        dispatch(setCheckable(true));
        dispatch(setSearchable());
        const tableHeaders = GetTableHeaders(sectionName, guestAccess);
        dispatch(setHeaders(tableHeaders));
        // eslint-disable-next-line
    }, []);

    const arrangeData = () => {
        let tempArray = [];
        // eslint-disable-next-line
        hotelRoomStatic.data.hotelRoomsStatic.results.map((hotelRoom) => {
            const pollingInfo = hotelRoomPolling.data.hotelRoomsPolling.results.find(
                (hotelInfo) => hotelInfo.id === hotelRoom.id
            );

            tempArray.push(HotelRoom({ ...hotelRoom, ...pollingInfo }, guestAccess));
        });
        return tempArray;
    };

    const getHotelMap = (hotelMap) => {
        let wings = [];
        let floors = [];
        let currentFloors;
        hotelMap.map((wing) => {
            if (!_.hasIn(wings, { id: wing.id })) {
                wings.push({
                    id: wing.id,
                    value: wing.name,
                    visible: true,
                });
            }
            currentFloors = wing.floors;
            if (currentFloors !== null && currentFloors.length > 0) {
                currentFloors.map((floor) => {
                    if (!_.hasIn(floors, { id: floor.id })) {
                        floors.push({
                            id: floor.id,
                            value: floor.name,
                            visible: true,
                            optGroup: wing.name,
                        });
                    }
                    return floor;
                });
            }
            return wing;
        });

        wings.sort(function (a, b) {
            return a.id - b.id;
        });
        return { wings, floors };
    };

    const getRoomGroups = (roomGroups) => {
        let _roomgroups = [];
        roomGroups.map((group, index) =>
            _roomgroups.push({
                id: group.id,
                value: group.name,
                visible: true,
            })
        );
        return _roomgroups;
    };

    useEffect(() => {
        if (
            !hotelRoomStatic.error &&
            !hotelRoomStatic.loading &&
            !hotelRoomPolling.error &&
            !hotelRoomPolling.loading &&
            hotelRoomStatic.networkStatus === 7 &&
            hotelRoomStatic.data &&
            hotelRoomStatic.data.hotelRoomsStatic &&
            hotelRoomStatic.data.hotelRoomsStatic.results &&
            hotelRoomPolling.data &&
            hotelRoomPolling.networkStatus === 7 &&
            hotelRoomPolling.data.hotelRoomsPolling &&
            hotelRoomPolling.data.hotelRoomsPolling.results
        ) {
            const roomsData = arrangeData();
            dispatch(setTableResults(roomsData));
            dispatch(setCountPosition("table-header"));
            dispatch(setBatchActive(BatchActions()));
            dispatch(setCount(Number(hotelRoomStatic.data.hotelRoomsStatic.info.count)));

            if (roomsData.length > 0) {
                dispatch(setColumns(roomsData[0].info.length));
                dispatch(setActions(roomsData[0].actions ? true : false));
                dispatch(setExtendedInfo(roomsData[0].extendedInfo ? true : false));
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [hotelRoomStatic, hotelRoomPolling]);

    useEffect(() => {
        if (hotelRoomStatic.loading || hotelRoomPolling.loading) {
            dispatch(setLoading(true));
        } else {
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [hotelRoomStatic.loading, hotelRoomPolling.loading]);
}

const BatchActions = () => {
    const actions = [
        {
            name: "add-to-group",
            action: "batch-room-group-assign",
            modalInputs: [
                {
                    type: "void",
                    adjust: "first-capital",
                    text: "rooms-to-add-to-group",
                },
                {
                    type: "batchSelection",
                    style: "mt-0 table",
                    columnPos: 0,
                },
                {
                    type: "void",
                    adjust: "first-capital",
                    style: "mb-0 mt-4",
                    text: "select-group",
                },
                {
                    selectPlaceHolder: "room-groups",
                    name: "room-groups",
                    optionsQuery: `{
                                data: roomGroups{
                                    info { count }
                                    results { id name  }
                                }  
                            }`,
                    ph: "select-group",
                    cStyle: "w-full",
                    // optionsAdjust: "mt-11",
                    sort: true,
                    type: "selectMultipleWithSearch",
                },
            ],
        },
    ];
    return actions;
};
export default ListRooms;
