import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table/index";
import UseDashboardCards from "../../DasboardCards/useDashboardCards";
import NSStatus, { networkServerErrorDiv } from "../../../../hooks/GraphqlCalls/Hotel/Monitoring/NSStatus";
import UseButton from "../../../useButton";
import useSelect from "../../../useSelect";
//Utils
import ListDevices from "../../../../hooks/GraphqlCalls/Hotel/Monitoring/ListDevices";
import UpdateListDevices from "../../../../hooks/GraphqlCalls/Hotel/UpdateListDevices";
import ExportDevices from "../../../../hooks/Utils/Hotel/Monitor/ExportDevices";
import AuthorizeAll from "../../../../hooks/GraphqlCalls/Hotel/Monitoring/modals/AuthorizeAll";
import SyncChromecasts from "../../../../hooks/GraphqlCalls/Hotel/Monitoring/modals/SyncChromecasts";
import { openModal, setModalContent } from "../../../../actions/uiActions";
import { Session } from "../../../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { CastError } from "./CastError";
import { parseBoolean } from "hooks/Utils/Utils";
import { useGetCCguestNetwork } from "hooks/GraphqlCalls/Hotel/Monitoring/useGetCCguestNetwork";
import { FilterCCsGuestUsersProvider } from "../context/FilterCCsGuestUsersContext";

const MonitorDevicesWithContext = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { serverError, customServerError } = useSelector((state) => state.table);
    const { permissions } = useSelector((state) => state.ui);
    const { tableLength } = useGetCCguestNetwork();
    const [startDataExport, setStartDataExport] = useState(false);
    const [monitorLink, setMonitorLink] = useState(null);
    const superUser = Session.getSessionProp("superUser");
    const networkWarning = parseBoolean(Session.getSessionProp("networkWarnings"));
    const hasCast = permissions?.product?.cast;

    const Select = useSelect();

    NSStatus({ clearTableResults: false, showGenericNotification: false, customServerError: true });
    ListDevices();

    const GET_MONITOR_LINK = gql(`query{
        getMonitorLink{
          link
        }
      }
      `);

    const [getMonitorLink, { data: dataMonitorLink }] = useLazyQuery(GET_MONITOR_LINK, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        getMonitorLink();
    }, []);

    useEffect(() => {
        const link = dataMonitorLink?.getMonitorLink?.link;
        if (link) {
            setMonitorLink(link);
        }
    }, [dataMonitorLink]);

    const exportButton = () => {
        setStartDataExport(true);
    };

    const authoriseAllChromecasts = () => {
        Select.close();
        const modal = AuthorizeAll();
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const syncCastingGateway = () => {
        Select.close();
        const modal = SyncChromecasts();
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    return (
        <>
            <UseSectionHeader title={["monitor-of", "devices", "and", "networks"]} value={"demo"} />
            <ExportDevices
                startDataExport={startDataExport}
                setStartDataExport={setStartDataExport}
                deviceType="all"
            ></ExportDevices>
            <UpdateListDevices />
            <UseDashboardCards />
            {hasCast && networkWarning && !serverError ? <CastError /> : null}
            {serverError ? networkServerErrorDiv(customServerError, t) : null}
            <div>
                <div className="inline-flex export-btn z-20">
                    {superUser && (
                        <div className="relative h-12 mr-2 mt-1">
                            <Select.Handler>
                                <UseButton
                                    icon={"superuser text-3xl"}
                                    buttonName={"es-staff-actions"}
                                    buttonColor={"btn-white"}
                                    dropdown={true}
                                    adjust="w-auto px-3 min-w-60 text-zafiro-800 font-bold"
                                    id={"es-staffs-actions-button"}
                                />
                            </Select.Handler>
                            <Select.Body className="dropdown-body-flags absolute max-h-80 overflow-y-scroll">
                                {[
                                    {
                                        key: "chromecast-authorize-all",
                                        name: t("chromecast-authorize-all"),
                                        action: authoriseAllChromecasts,
                                        link: false,
                                    },
                                    {
                                        key: "sync-casting-gateway",
                                        name: t("sync-casting-gateway"),
                                        action: syncCastingGateway,
                                        link: false,
                                    },
                                    {
                                        key: "check-zombie-machines",
                                        name: t("check-zombie-machines"),
                                        action: null,
                                        link: true,
                                    },
                                ].map((option) => {
                                    const style = "px-4 mb-2 cursor-pointer flex items-center text-gray-900";
                                    const styleIcon =
                                        "icon-external-link ml-1 text-2xl focus:outline-none text-gray-800 ";

                                    if (option.link) {
                                        return (
                                            <a
                                                className={style}
                                                id={`${option.key}-option`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={monitorLink}
                                            >
                                                {option.name}
                                                <i className={styleIcon}></i>
                                            </a>
                                        );
                                    } else {
                                        return (
                                            <div
                                                className={style}
                                                id={`${option.key}-option`}
                                                key={option.key}
                                                onClick={option.action}
                                            >
                                                {option.name}
                                            </div>
                                        );
                                    }
                                })}
                            </Select.Body>
                        </div>
                    )}
                    <div onClick={(e) => exportButton()}>
                        <UseButton
                            id={"monitor-devices-export-devices-button"}
                            buttonName={"export-devices"}
                            buttonColor={"btn-blue"}
                            adjust="w-auto px-4 min-w-24 mr-2 mt-1"
                        />
                    </div>
                </div>
                <UseTable tableLength={tableLength} />
            </div>
        </>
    );
};

export const MonitorDevices = () => (
    <FilterCCsGuestUsersProvider>
        <MonitorDevicesWithContext />
    </FilterCCsGuestUsersProvider>
);
