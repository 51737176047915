import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { formatDate } from "./services/formatDate";
import { Warning } from "./Warning";
import ReactTooltip from "components/ReactTooltip";
import { NetworkHeader } from "./NetworkHeader";

export const ChromeCastStatus = ({ mdnsInfo, called, loading }) => {
    const { t } = useTranslation();
    const { networkPerformanceData, lastUpdate, updateTimeMin, ccTotalNumber } = mdnsInfo || {};

    const lastUpdateDate = formatDate(lastUpdate);

    if (loading || !called) return null;

    return (
        <main className="bg-white">
            <div className="border-t border-gray-300 mr-8"></div>
            <section className="mb-6 p-4">
                <NetworkHeader title="ccs-status" lastUpdateDate={lastUpdateDate} updateTimeMin={updateTimeMin} />
                {ccTotalNumber === 0 ? (
                    <div className="mb-8">
                        <div className="flex items-center">
                            <span className="icon-info text-xl ml-3"></span>
                            <span className="ml-2">{t("no-cast-data")}</span>
                        </div>
                        <span className="ml-10 ">{t("not-possible-to-monitor")}</span>
                    </div>
                ) : null}
                <ul className="flex flex-col gap-4 list-disc ml-10">
                    {Object?.keys(networkPerformanceData)?.map((key) => {
                        return (
                            <Fragment key={key}>
                                <li>
                                    {capitalizeFirst(t(key))}:{" "}
                                    <span className="font-bold" id={`ccs-${key}`}>
                                        {typeof networkPerformanceData[key] === "object" ? (
                                            networkPerformanceData[key]?.empty ? (
                                                "-"
                                            ) : (
                                                <span data-tip data-for={`ccs-${key}`}>
                                                    {networkPerformanceData[key]?.value}
                                                    {networkPerformanceData[key]?.tooltip && (
                                                        <ReactTooltip id={`ccs-${key}`} place="right" effect="solid">
                                                            <span>{networkPerformanceData[key]?.tooltip}</span>
                                                        </ReactTooltip>
                                                    )}
                                                </span>
                                            )
                                        ) : (
                                            networkPerformanceData[key]
                                        )}
                                    </span>
                                    {networkPerformanceData[key]?.bandwidth ? (
                                        <div className="mt-2">
                                            {t("internet-bandwidth")}:{" "}
                                            <span className="font-bold">{networkPerformanceData[key]?.bandwidth}</span>
                                        </div>
                                    ) : null}
                                </li>
                                {ccTotalNumber !== 0 ? (
                                    <>
                                        {networkPerformanceData[key]?.warning &&
                                        (key === "cc-wifi-signal" || key === "ccs-latency") ? (
                                            <>
                                                <Warning
                                                    text={t(
                                                        key === "cc-wifi-signal"
                                                            ? "cc-signal-values"
                                                            : "cc-latency-values",
                                                        {
                                                            ccNumber: networkPerformanceData[key]?.textValue,
                                                        }
                                                    )}
                                                    color={"orange"}
                                                    ml={4}
                                                />
                                                <span className="ml-10 text-gray-800">{t("network-not-optimal")}</span>
                                            </>
                                        ) : null}
                                        {networkPerformanceData[key]?.warning?.minor &&
                                        key === "share-content-simultaneasly" ? (
                                            <Warning
                                                text={t("cc-not-reach-percent-of-hired")}
                                                color={networkPerformanceData[key]?.warning?.color}
                                            />
                                        ) : null}
                                        {networkPerformanceData[key]?.warning?.critical &&
                                        key === "share-content-simultaneasly" ? (
                                            <>
                                                <Warning
                                                    text={t("more-than-recommended")}
                                                    color={networkPerformanceData[key]?.warning?.color}
                                                    ml={4}
                                                />
                                                <span className="ml-10 my-1 text-gray-800 block w-4/5">
                                                    {t("user-sharing")}
                                                </span>
                                                <span className="ml-10 text-gray-800 block">
                                                    {t("ensure-optimal-experience", {
                                                        mbps: networkPerformanceData[key]?.warning?.textValue,
                                                    })}
                                                </span>
                                            </>
                                        ) : null}
                                    </>
                                ) : null}
                            </Fragment>
                        );
                    })}
                </ul>
            </section>
        </main>
    );
};
