import React from "react";

/**
 * @name IconButton
 * @description This component is a icon button
 * @component
 * @param {string} type The type of the button
 * @param {string} id The id of the button
 * @param {boolean} disabled If the button is disabled
 * @param {function} onClick The function to be called when the button is clicked
 * @returns {JSX.Element} The UI of the component
 * @example <IconButton type="edit" id="edit-button" onClick={() => {}} />
 */
const IconButton = ({ id, type, disabled, onClick }) => {
    const icon = getIcon(type);

    const disabledStyle = "text-gray-400";
    const defaultStyle = "cursor-pointer text-gray-800 hover:text-gray-900";
    const deleteStyle = "cursor-pointer text-gray-800 hover:text-red-500";
    const enabledStyle = type === "delete" ? deleteStyle : defaultStyle;

    return (
        <div
            className={`${icon} text-2xl ${disabled ? disabledStyle : enabledStyle}`}
            id={id}
            onClick={disabled ? null : onClick}
        ></div>
    );
};

const getIcon = (type) => {
    let iconClass;
    switch (type) {
        case "delete":
            iconClass = "icon icon-delete";
            break;
        case "edit":
            iconClass = "icon icon-edit";
            break;
        case "info":
            iconClass = "icon icon-info";
            break;
        case "warning":
            iconClass = "icon icon-warning";
            break;
        default:
            throw new Error(`Invalid icon type: ${type}`);
    }
    return iconClass;
};

export default IconButton;
