import {
    SET_SIDEBAR_STATUS,
    SET_MODAL_STATUS,
    SET_DASHBOARD_CARDS,
    SET_MODAL_CONTENT,
    SET_LOADING_CONTENT,
    GET_CZLIST_INFO,
    SHOW_LOGIN,
    SET_CHECKDD_NAMES,
    CLEAN_CHECKDD_NAMES,
    SET_LANG,
    SET_LANG_STRINGS,
    SHOW_GLOBAL_LOADING,
    SET_PERMISSIONS,
    SET_PATHS,
    SET_AGREGATED_PERMISSIONS,
    SET_ROOMTV_LIST,
    CLEAN_DASHBOARD_CARDS,
    RELOAD_BREADCRUMBS,
    SET_SECTION_STYLE,
    SHOW_MAIN_MENU,
    SET_DROPDOWN_MENU,
    SET_WARNINGS,
    SET_WARNINGS_MINIMIZED,
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION,
    SET_FONTS,
    SET_PROJECT_LANGS,
    SET_COUNTRIES,
    SET_LANGUAGES,
    SET_SELECTING_HOTEL_DROPDOWN,
} from "../actions/uiActions";

const initialState = {
    dashboardCards: false,
    modal: false,
    modalContent: false,
    loadingContent: false,
    sidebar: true,
    login: true,
    nameData: false,
    permissions: null,
    paths: null,
    reloadBreadcrumbs: false,
    sidebarMenu: null,
    sidebarSection: null,
    sectionStyle: null,
    showMainMenu: true,
    dropdownOpened: null,
    warnings: null,
    warningsMinimized: false,
    notifications: null,
    fonts: null,
    projectLangs: null,
    listCountriesAvailable: [],
    listLanguagesAvailable: [],
    selectingHotelDropdown: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_SIDEBAR_STATUS:
            return {
                ...state,
                sidebar: action.payload,
            };
        case SET_MODAL_STATUS:
            return {
                ...state,
                modal: action.payload,
            };
        case SET_DASHBOARD_CARDS:
            return {
                ...state,
                dashboardCards: action.payload,
            };
        case CLEAN_DASHBOARD_CARDS:
            return {
                ...state,
                dashboardCards: null,
            };
        case RELOAD_BREADCRUMBS:
            return {
                ...state,
                reloadBreadcrumbs: action.payload,
            };
        case SET_MODAL_CONTENT:
            return {
                ...state,
                modalContent: action.payload,
            };
        case SET_LOADING_CONTENT:
            return {
                ...state,
                loadingContent: action.payload,
            };
        case GET_CZLIST_INFO:
            return {
                ...state,
                CZList: action.payload,
            };
        case SET_ROOMTV_LIST:
            return {
                ...state,
                roomTvsList: action.payload,
            };
        case SHOW_LOGIN:
            return {
                ...state,
                login: action.payload,
            };
        case SET_CHECKDD_NAMES:
            return {
                ...state,
                checkboxDropdownNames: {
                    ...state.checkboxDropdownNames,
                    ...action.payload,
                },
            };
        case SET_SELECTING_HOTEL_DROPDOWN:
            return {
                ...state,
                selectingHotelDropdown: action.payload,
            };
        case CLEAN_CHECKDD_NAMES:
            return {
                ...state,
                checkboxDropdownNames: null,
            };
        case SET_LANG:
            return {
                ...state,
                lang: action.payload,
            };
        case SET_LANG_STRINGS:
            return {
                ...state,
                langStrings: action.payload,
            };
        case SHOW_GLOBAL_LOADING:
            return {
                ...state,
                showGlobalLoading: action.payload,
            };
        case SET_PERMISSIONS:
            return {
                ...state,
                permissions: action.payload,
            };
        case SET_PATHS:
            return {
                ...state,
                paths: action.payload,
            };
        case SET_AGREGATED_PERMISSIONS:
            return {
                ...state,
                agregatedPermissions: action.payload,
            };
        case SET_SECTION_STYLE:
            return {
                ...state,
                sectionStyle: action.payload,
            };
        case ADD_NOTIFICATION:
            if (state.notifications) {
                state.notifications.push(action.payload);
            } else {
                state.notifications = [action.payload];
            }
            return {
                ...state,
                notifications: [...state.notifications],
            };
        case REMOVE_NOTIFICATION:
            if (!state.notifications || state.notifications.length === 0) {
                return {
                    ...state,
                };
            }
            let notIndex = state.notifications.indexOf(action.payload);
            if (notIndex > -1) {
                state.notifications.splice(notIndex, 1);
            }
            return {
                ...state,
                notifications: [...state.notifications],
            };
        case SET_WARNINGS:
            return {
                ...state,
                warnings: action.payload,
            };
        case SET_WARNINGS_MINIMIZED:
            return {
                ...state,
                warningsMinimized: action.payload,
            };
        case SHOW_MAIN_MENU:
            return {
                ...state,
                showMainMenu: action.payload,
            };
        case SET_DROPDOWN_MENU:
            return { ...state, dropdownOpened: action.payload };
        case SET_FONTS:
            return { ...state, fonts: action.payload };
        case SET_PROJECT_LANGS:
            return { ...state, projectLangs: action.payload };
        case SET_COUNTRIES:
            return {
                ...state,
                listCountriesAvailable: action.payload,
            };
        case SET_LANGUAGES:
            return {
                ...state,
                listLanguagesAvailable: action.payload,
            };
        default:
            return state;
    }
}
