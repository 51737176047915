import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate as useHistory } from "react-router-dom";

//Components
import UseSectionHeader from "../../../../../../../useSectionHeader";
import UpdateExtras from "../../../../../../../../hooks/GraphqlCalls/Settings/UpdateExtras";
import UseButton from "../../../../../../../useButton";
import UseInputText from "../../../../../../../Inputs/useInputText";

//Utils
import { executeVendureQuery, getVendureApiData } from "../../../../../../../../hooks/Utils/Integrations/useVendure";
import { getExtraTypeAndSelection, getTranslationName } from "../../../../../../../../hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import { UPDATE_PRODUCT_EXTRAS } from "../../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { setExecuteQuery } from "../../../../../../../../actions/sectionActions";
import { showGlobalLoading } from "../../../../../../../../actions/uiActions";
import uuid from "react-uuid";
import _ from "lodash";

const ExtrasTranslate = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    //Consts&states
    const { id: shopId, productId } = useParams();
    const { t } = useTranslation();
    const { projectLangs } = useSelector((state) => state.ui);
    const { executeQuery, refreshData } = useSelector((state) => state.sectionContent);
    const [langDefault, setLangDefault] = useState(null);
    const [langs, setLangs] = useState(null);
    const [loading, setLoading] = useState(true);
    const [productName, setProductName] = useState(null);
    const [items, setItems] = useState([]);
    const [extrasData, setExtrasData] = useState([]);
    const [openTab, setOpenTab] = useState(0);
    const breadcrumbs = [
        {
            name: "products",
            translate: true,
            route: `/services/sales/shop/products/${shopId}`,
        },
        {
            name: productName,
            translate: false,
            route: `/services/sales/shop/${shopId}/product/${productId}`,
        },
        {
            name: "extra",
            translate: true,
            route: `/services/sales/shop/${shopId}/product/${productId}/extras`,
        },
    ];

    //queries&mutations
    const GET_DATA = `query{
        product: product(id:${productId}){
            translations{
                languageCode
                name
            }
        }
        extras: extras(productId:${productId}){
            id
            available
            type
            appliesTo
            taxCategory
            order
            translations{
                languageCode
                name
            }
            options{
                min
                max
                priceWithoutTax
                choices{
                    available
                    priceWithoutTax
                    taxCategory
                    order
                    translations{
                        languageCode
                        name
                    }
                }
            }
        }
    }`;

    //effects
    useEffect(() => {
        if (projectLangs) {
            const langs = arrangeDataLang(projectLangs);
            projectLangs.forEach((pl) => {
                if (pl?.isDefault) {
                    setLangDefault(pl.languageRef);
                }
            });
            setLangs(langs);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (langDefault && productId) {
            refetch();
        }
        // eslint-disable-next-line
    }, [langDefault, productId]);

    useEffect(() => {
        if (loading && !refreshData) {
            setLoading(false);
        }
        if (!refreshData) {
            if (executeQuery?.action === UPDATE_PRODUCT_EXTRAS) {
                refetch();
            }
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        dispatch(showGlobalLoading(loading));
        // eslint-disable-next-line
    }, [loading]);

    //arrangeData
    const arrangeDataLang = (projectLangs) => {
        const response = [];
        if (projectLangs && projectLangs.length > 0) {
            const languageRefs = projectLangs.map((projectLang) => projectLang.languageRef);
            const filtered = projectLangs.filter(
                ({ languageRef }, index) => !languageRefs.includes(languageRef, index + 1)
            );
            // eslint-disable-next-line
            let indexOfDefaultLang = null;
            filtered.forEach((projectLang, index) => {
                const name = t("language:" + projectLang.languageRef);
                response.push({
                    id: projectLang.languageRef,
                    name: name || projectLang.languageRef,
                    isDefault: projectLang.isDefault,
                });
                if (projectLang.isDefault) indexOfDefaultLang = index;
            });
            if (indexOfDefaultLang !== null) {
                response.push(response.splice(indexOfDefaultLang, 1)[0]);
            }
        }
        return response;
    };

    const arrangeData = (data, projectLangs, langDefault) => {
        let items = [];
        let product = null;
        let extras = [];
        if (data) {
            product = data.product ? data.product : null;
            extras = data.extras && data.extras.length > 0 ? data.extras : [];
            if (extras && extras.length > 0) {
                extras.forEach((extra) => {
                    if (extra?.options?.choices?.length > 0) {
                        extra.options.choices.forEach((choice) => {
                            choice.ref = uuid();
                        });
                    }
                });
            }
            if (projectLangs && projectLangs.length > 0) {
                projectLangs.forEach((projectLang) => {
                    let item = {
                        ref: projectLang.id,
                        name: projectLang.name,
                        extras: [],
                        isDefault: projectLang.isDefault,
                        translationsCompleted: true,
                    };
                    if (extras && extras.length > 0) {
                        extras.forEach((extra) => {
                            let translation = null;
                            let choices = [];
                            if (extra.translations && extra.translations.length > 0) {
                                translation = extra.translations.filter(
                                    (translationFiltered) => translationFiltered.languageCode === projectLang.id
                                );
                            }
                            if (translation && translation.length > 0) {
                                translation = translation[0];
                                if (!translation.name) {
                                    item.translationsCompleted = false;
                                }
                            } else {
                                item.translationsCompleted = false;
                            }
                            if (extra?.options?.choices?.length > 0) {
                                extra.options.choices.forEach((choice) => {
                                    let choiceTranslation = null;
                                    if (choice.translations && choice.translations.length > 0) {
                                        choiceTranslation = choice.translations.filter(
                                            (translationFiltered) => translationFiltered.languageCode === projectLang.id
                                        );
                                    }
                                    if (choiceTranslation && choiceTranslation.length > 0) {
                                        choiceTranslation = choiceTranslation[0];
                                        if (!choiceTranslation.name) {
                                            item.translationsCompleted = false;
                                        }
                                    } else {
                                        item.translationsCompleted = false;
                                    }
                                    let choiceData = {
                                        choiceRef: choice.ref,
                                        defaultName: getTranslationName(choice.translations, langDefault),
                                        translationValue: choiceTranslation?.name ? choiceTranslation.name : "",
                                        translationLang: projectLang.id,
                                    };
                                    choices.push(choiceData);
                                });
                            }
                            let typeAndSelection = getExtraTypeAndSelection(extra.type);
                            let extraData = {
                                extraId: extra.id,
                                defaultName: getTranslationName(extra.translations, langDefault),
                                choices: choices,
                                typeOfExtra: typeAndSelection.typeOfExtra,
                                typeOfSelection: typeAndSelection.typeOfSelection,
                                translationValue: translation?.name ? translation.name : "",
                                translationLang: projectLang.id,
                            };
                            item.extras.push(extraData);
                        });
                    }
                    items.push(item);
                });
            }
        }
        return { product, items, extras };
    };

    //handle&functions
    const refetch = async () => {
        setLoading(true);
        let items = [];
        let productName = null;
        let extrasData = null;
        const response = await executeVendureQuery(getVendureApiData(), { queryBody: GET_DATA });
        if (response?.data && !response.errors) {
            const {
                product: productData,
                items: itemsData,
                extras: extrasInfo,
            } = arrangeData(response.data, langs, langDefault);
            extrasData = extrasInfo;
            items = itemsData;
            productName = productData && langDefault ? getTranslationName(productData.translations, langDefault) : null;
        }
        setItems(items);
        setProductName(productName);
        setExtrasData(extrasData);
        setLoading(false);
    };

    const handleName = (value, index, indexExtra, indexChoice) => {
        let itemsClone = [...items];
        if (indexChoice === null) {
            itemsClone[index].extras[indexExtra].translationValue = value;
        } else {
            itemsClone[index].extras[indexExtra].choices[indexChoice].translationValue = value;
        }
        setItems(itemsClone);
    };

    const handleCancel = () => {
        history(-1);
    };

    const handleSave = () => {
        const extras = [];
        if (extrasData && extrasData.length > 0 && items && items.length > 0) {
            extrasData.forEach((extraData) => {
                let extra = { ...extraData };
                let translations = [];
                let choices = extra?.options?.choices?.length > 0 ? extra.options.choices : null;
                items.forEach((item) => {
                    if (item.extras && item.extras.length > 0) {
                        let itemExtra = item.extras.filter((filtered) => Number(filtered.extraId) === Number(extra.id));
                        if (itemExtra.length > 0) {
                            itemExtra = itemExtra[0];
                            translations.push({
                                languageCode: itemExtra.translationLang,
                                name: itemExtra.translationValue,
                            });
                            if (choices && choices.length > 0 && itemExtra.choices && itemExtra.choices.length > 0) {
                                choices.forEach((choice) => {
                                    let choiceTranslations =
                                        choice.translations && choice.translations.length > 0
                                            ? choice.translations
                                            : [];
                                    let choiceExtra = itemExtra.choices.filter(
                                        (filtered) => String(filtered.choiceRef) === String(choice.ref)
                                    );
                                    if (choiceExtra.length > 0) {
                                        choiceExtra = choiceExtra[0];
                                        let choiceTranslationIndex = _.findIndex(
                                            choiceTranslations,
                                            (filtered) =>
                                                String(filtered.languageCode) === String(choiceExtra.translationLang)
                                        );
                                        if (choiceTranslationIndex !== -1) {
                                            choiceTranslations[choiceTranslationIndex].name =
                                                choiceExtra.translationValue;
                                        } else {
                                            choiceTranslations.push({
                                                languageCode: choiceExtra.translationLang,
                                                name: choiceExtra.translationValue,
                                            });
                                        }
                                    }
                                    choice.translations = choiceTranslations;
                                });
                            }
                        }
                    }
                });
                extra.translations = translations;
                if (choices && choices.length > 0) extra.options.choices = choices;
                extras.push(extra);
            });
        }
        if (extras && extras.length > 0) {
            setLoading(true);
            dispatch(
                setExecuteQuery({
                    action: UPDATE_PRODUCT_EXTRAS,
                    params: {
                        items: extras,
                    },
                })
            );
        }
    };

    //renders

    //response
    return (
        <>
            <UseSectionHeader title="translate" customBreadCrumbs={breadcrumbs} navToPreviousPage={true} />
            <UpdateExtras />
            <div className="w-full main-container">
                <div className="flex flex-wrap">
                    <div className="w-full">
                        <ul className="flex mb-0 list-none flex-wrap flex-row" role="tablist">
                            {items && items.length > 0
                                ? items.map(
                                      (item, index) =>
                                          !item.isDefault && (
                                              <li key={index} className={`text-center`}>
                                                  <a
                                                      className={`first-capital px-10 py-3 block border-b-4  font-bold ${
                                                          openTab === index
                                                              ? `border-blue-600 t-link`
                                                              : `text-gray-800 border-b-0`
                                                      }`}
                                                      onClick={(e) => {
                                                          e.preventDefault();
                                                          setOpenTab(index);
                                                      }}
                                                      data-toggle="tab"
                                                      href={`#link${index}`}
                                                      role="tablist"
                                                  >
                                                      {item.name}{" "}
                                                      {item.translationsCompleted ? (
                                                          <img
                                                              alt="active btn"
                                                              className="float-right w-5 h-5 ml-1"
                                                              src={require(`../../../../../../../../assets/images/icons/save-circle-green.svg`)}
                                                          ></img>
                                                      ) : null}
                                                  </a>
                                              </li>
                                          )
                                  )
                                : null}
                        </ul>
                        <div className="relative flex flex-col min-w-0 break-words w-full ">
                            <div className={`py-5 flex-auto`}>
                                <div className="tab-content tab-space">
                                    {items && items.length > 0
                                        ? items.map(
                                              (item, index) =>
                                                  !item.isDefault && (
                                                      <div
                                                          key={`contentlink${index}`}
                                                          className={openTab === index ? "block" : "hidden"}
                                                          id={`link${index}`}
                                                      >
                                                          <div
                                                              className="w-full text-left overflow-y-scroll"
                                                              style={{ height: "50vh" }}
                                                          >
                                                              {item.extras.map((extra, indexExtra) => (
                                                                  <div className="flex w-full rounded border border-gray-200 px-8 py-3 mb-5">
                                                                      <div className="w-6/12 pr-10">
                                                                          <span className="font-bold block mb-3">
                                                                              {t(extra.typeOfExtra)}
                                                                          </span>
                                                                          <div className="w-full flex items-center align-middle">
                                                                              <div className="w-6/12">
                                                                                  <span className="">
                                                                                      {extra.defaultName}
                                                                                  </span>
                                                                              </div>
                                                                              <div className="w-6/12">
                                                                                  <UseInputText
                                                                                      id={`name-${index}-${indexExtra}`}
                                                                                      ph={
                                                                                          extra?.defaultName
                                                                                              ? extra.defaultName
                                                                                              : t("extra-name")
                                                                                      }
                                                                                      noTranslatePh={true}
                                                                                      value={extra.translationValue}
                                                                                      changeAction={(value) => {
                                                                                          handleName(
                                                                                              value,
                                                                                              index,
                                                                                              indexExtra,
                                                                                              null
                                                                                          );
                                                                                      }}
                                                                                  />
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                      <div className="w-6/12 pl-10">
                                                                          {extra.choices && extra.choices.length > 0 ? (
                                                                              <div className="max-h-64 overflow-y-scroll">
                                                                                  <span className="font-bold block mb-3 first-capital">
                                                                                      {t("items")}
                                                                                  </span>
                                                                                  {extra.choices.map(
                                                                                      (choice, indexChoice) => (
                                                                                          <div className="w-full flex items-center align-middle mb-3">
                                                                                              <div className="w-6/12">
                                                                                                  <span className="">
                                                                                                      {
                                                                                                          choice.defaultName
                                                                                                      }
                                                                                                  </span>
                                                                                              </div>
                                                                                              <div className="w-6/12 pr-1">
                                                                                                  <UseInputText
                                                                                                      id={`name-${index}-${indexExtra}-${indexChoice}`}
                                                                                                      ph={
                                                                                                          choice?.defaultName
                                                                                                              ? choice.defaultName
                                                                                                              : t(
                                                                                                                    "name"
                                                                                                                )
                                                                                                      }
                                                                                                      noTranslatePh={
                                                                                                          true
                                                                                                      }
                                                                                                      value={
                                                                                                          choice.translationValue
                                                                                                      }
                                                                                                      changeAction={(
                                                                                                          value
                                                                                                      ) => {
                                                                                                          handleName(
                                                                                                              value,
                                                                                                              index,
                                                                                                              indexExtra,
                                                                                                              indexChoice
                                                                                                          );
                                                                                                      }}
                                                                                                  />
                                                                                              </div>
                                                                                          </div>
                                                                                      )
                                                                                  )}
                                                                              </div>
                                                                          ) : null}
                                                                      </div>
                                                                  </div>
                                                              ))}
                                                          </div>
                                                      </div>
                                                  )
                                          )
                                        : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full flex justify-end h-10 mt-2 mr-4 mb-4">
                    <div className="w-auto inline-block mr-4" onClick={handleCancel}>
                        <UseButton buttonName="cancel" buttonColor="btn-blue-outline" adjust="px-8" />
                    </div>
                    <div className="w-auto inline-block" onClick={handleSave}>
                        <UseButton buttonName="save" buttonColor="btn-blue" adjust="px-8" />
                    </div>
                </div>
            </div>
        </>
    );
};
export default ExtrasTranslate;
