export const timer = () => {
    const time = [];
    for (let i = 0; i < 24; i++) {
        time.push({
            text: `${i.toString().length > 1 ? "" : "0"}${i}:00`,
            value: `${i.toString().length > 1 ? "" : "0"}${i}:00`,
        });
    }
    return time;
};
