import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Actions
import { showGlobalLoading } from "../../../actions/uiActions";
import { setActionInput } from "../../../actions/globalActions";
//Utils
import { toast } from "react-toastify";

function SetCastConfig() {
    //Store data
    const { actionData } = useSelector((state) => state.action);
    const { langStrings } = useSelector((state) => state.ui);
    const save = actionData && actionData.save ? actionData.save : false;
    const networkQuery = actionData && actionData.networkQuery ? actionData.networkQuery : "default";

    //Actions
    const dispatch = useDispatch();

    const getMutation = (networkQuery) => {
        switch (networkQuery) {
            case "ccpairing":
                return gql`mutation{
                    setChromecastSettings(philips:${actionData.ccpairingphilips}, enabled: ${actionData.ccpairingstatus}, pairingMode:"${actionData.ccpairingmode}")
                    {error ok}
                  }`;
            case "ccdailyreboot":
                const offset = getTimeZone();
                return gql`mutation{
                    setCCDailyReboot(enable: ${actionData.ccdailyrebootstatus}, rebootTime:"${
                    actionData.ccdailyrebootstime && actionData.ccdailyrebootstime !== ""
                        ? `${actionData.ccdailyrebootstime}:00${offset}`
                        : ``
                }")
                    {error ok}
                }`;
            case "reportccstatus":
                return gql`
                    mutation {
                        setChromecastSettings(emailNotify: ${actionData.castreportstatus}) {
                            ok
                            error
                        }
                    }
                `;
            case "permanentsocket":
                return gql`
                    mutation {
                        setChromecastSettings(socket: ${actionData.castpermanentsocket}) {
                            ok
                            error
                        }
                    }
                `;
            case "cclowsignal":
                return gql`
                    mutation {
                        setChromecastSettings(lowSignalMessage: ${actionData.lowSignalMessage}) {
                            ok
                            error
                        }
                    }
                `;
            default:
                return gql`
                    mutation {
                        setWifiSettings(kind: "Hotel", enabled: false, name: "dafault", password: "dafault") {
                            error
                            ok
                        }
                    }
                `;
        }
    };
    const NETWORK_QUERY = getMutation(networkQuery);
    const [executeMutation, { data }] = useMutation(NETWORK_QUERY, {
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            toast.error(langStrings["mutation-error"]);
        },
    });

    useEffect(() => {
        if (save) {
            dispatch(showGlobalLoading(true));
            dispatch(setActionInput({ save: false }));
            dispatch(setActionInput({ networkQuery: "" }));

            executeMutation();
            dispatch(setActionInput({ refreshData: true }));
        }
        // eslint-disable-next-line
    }, [save]);

    useEffect(() => {
        if (
            (data && data.setWifiSettings && data.setWifiSettings.ok) ||
            (data && data.setChromecastSettings && data.setChromecastSettings.ok) ||
            (data && data.setCCDailyReboot && data.setCCDailyReboot.ok)
        ) {
            toast.success(langStrings["operation-successful"]);
            dispatch(setActionInput({ refreshData: true }));
            dispatch(showGlobalLoading(false));
        } else if (
            (data && data.setWifiSettings && data.setWifiSettings.error) ||
            (data && data.setChromecastSettings && data.setChromecastSettings.error) ||
            (data && data.setCCDailyReboot && data.setCCDailyReboot.error)
        ) {
            toast.error(langStrings["mutation-error"]);
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);
}

function getTimeZone() {
    let offset = new window.ZDate().getTimezoneOffset(),
        o = Math.abs(offset);
    return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
}

export default SetCastConfig;
