import React, { useState } from "react";

//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table/index";
import ListAccessProfiles from "../../../hooks/GraphqlCalls/Hotel/ListAccessProfiles";
import UpdateAccessProfiles from "../../../hooks/GraphqlCalls/Hotel/UpdateAccessProfiles";
import { useTranslation } from "react-i18next";

//Utils
import Parser from "hooks/Utils/Parser";

const AccessProfiles = () => {
    const { t } = useTranslation();
    const [notConfigutarionAccessTypeWarning, setNotConfigutarionAccessTypeWarning] = useState(null);
    ListAccessProfiles(setNotConfigutarionAccessTypeWarning);

    const renderAccessTypes = (accessTypes) => {
        let response = [];
        if (accessTypes && accessTypes.length > 0) {
            // eslint-disable-next-line
            accessTypes.map((accessType) => {
                response.push(`<strong>"${accessType}"</strong>`);
            });
        }
        response = response.join(", ");
        let lastIndex = response.lastIndexOf(", ");
        if (lastIndex > -1) response = response.slice(0, lastIndex) + ` ${t("and")} ` + response.slice(lastIndex + 1);

        return response;
    };

    return (
        <>
            <UseSectionHeader title={["manage-access-profiles"]} navToSection="/hotel/property-settings/wifi/manager" />
            <UpdateAccessProfiles />
            <div>
                {notConfigutarionAccessTypeWarning && notConfigutarionAccessTypeWarning.show ? (
                    <div className="w-full bg-white text-left pt-5 pl-12">
                        <span className="icon icon-warning text-xl text-orange-100 mr-1"></span>
                        <span>
                            {Parser(
                                t("access-profile-no-associated-access-type-warning", {
                                    upload: "1,5",
                                    download: "5",
                                    accessTypes: renderAccessTypes(notConfigutarionAccessTypeWarning.accessTypes),
                                })
                            )}
                        </span>
                    </div>
                ) : null}
                <UseTable />
            </div>
        </>
    );
};
export default AccessProfiles;
