function DeleteRoomGroupModal(id, groupName) {
    return [
        {
            title: ["delete", groupName],
            text: "room-group-delete",
            id: id,

            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete",
                    style: "red",
                    action: "room-group-delete",
                },
            ],
        },
    ];
}

export default DeleteRoomGroupModal;
