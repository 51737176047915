import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import React, { useEffect, useState } from "react";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { toast } from "react-toastify";
import { Session } from "../../Utils/Session";

import { gql } from "apollo-boost";
import { capitalizeFirst, removeApolloCacheKeys } from "../../Utils/Utils";
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { cleanAction } from "../../../actions/globalActions";
import { withApollo } from "@apollo/react-hoc";
import { arrangeToastMessagesWhenFailsBatchActions } from "../../Utils/DesignUtils";
import { getProjectLangs } from "../../Utils/SalesUtils";
import UseToast from "../../../components/Notifications/useToast";
import { useParams } from "react-router-dom";

const UpdateShopZafiro = ({ client }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { permissions } = useSelector((state) => state.ui);
    const { values, actionData, itemsAffected } = useSelector((state) => state.action);
    const { checkedItems } = useSelector((state) => state.table);
    const { projectLangs } = useSelector((state) => state.ui);
    const vendureShops = Session.getSessionProp("vendure-shops");
    const hasOrdersManagement = permissions?.services?.shopsOrders;
    const { id } = useParams();
    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");
    const [langDefault, setLangDefault] = useState("en");
    const [shops, setShops] = useState("");
    const [isBatch, setIsBatch] = useState(false);

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const formatArray = (arr) => {
        const formattedArray = arr.map((item, index) => {
            const formattedItem = Object.entries(item).map(([key, value]) => `${key}: ${JSON.stringify(value)}`);
            const formattedObject = `{ ${formattedItem.join(", ")} }`;
            return index === arr.length - 1 ? formattedObject : formattedObject + ",";
        });

        return formattedArray.join(" ");
    };

    const convertObjectToArray = (obj) => {
        return Object.keys(obj).map((key) => {
            const id = key.match(/\d+/)[0];
            return { id, key, ...obj[key] };
        });
    };

    useEffect(() => {
        if (vendureShops) {
            setShops(JSON.parse(vendureShops));
        }
    }, [vendureShops]);

    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            setLangDefault(langDefault);
        }
        // eslint-disable-next-line
    }, [projectLangs]);

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            let errorMsg = err.message;
            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
        onCompleted() {
            dispatch(showGlobalLoading(false));
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            dispatch(cleanAction());
            removeApolloCacheKeys(client.cache, "all");
            dispatch(setExecuteQuery(null));
            if (!isBatch) {
                toast.success(capitalizeFirst(t("operation-successful")));
            }
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.mutationName = "";
            setIsBatch(false);
            switch (executeQuery.action) {
                case "create-shop":
                    execute = true;
                    setMutation(`mutation {
                        createShop(
                          languageCode: "${langDefault}"
                          currencyCode: "${values["add-shop-currency"]}"
                          name: { lang: "${langDefault}",
                          text: "${actionData["add-shop-name"]}" }
                          pricesIncludeTax: false
                          defaultTaxZoneID: ${values["add-shop-taxes-zone"]}
                          defaultShippingZoneID: ${values["add-shop-taxes-zone"]}
                          users:[${hasOrdersManagement ? values["assign-users-shop"] : ""}]
                          customFields: {
                            ${
                                values["add-shop-tax-category"]
                                    ? `defaultTaxCategoryID: ${values["add-shop-tax-category"]} `
                                    : ""
                            }
                            ${
                                values["add-shop-tax-rate-selected"]
                                    ? `defaultTaxID: ${values["add-shop-tax-rate-selected"]}`
                                    : ""
                            }
                            ${hasOrdersManagement ? `autoAcceptOrders: true` : ""}
                            ${`shippingConfig: "${JSON.stringify({
                                pickup: {
                                    price: {
                                        base: 0,
                                        taxRate: values["add-shop-tax-rate-selected-value"]
                                            ? values["add-shop-tax-rate-selected-value"]
                                            : 0,
                                        includesTax: true,
                                    },
                                    enabled: false,
                                },
                                delivery: {
                                    price: {
                                        base: 0,
                                        taxRate: values["add-shop-tax-rate-selected-value"]
                                            ? values["add-shop-tax-rate-selected-value"]
                                            : 0,
                                        includesTax: true,
                                    },
                                    enabled: true,
                                },
                            }).replace(/"/g, '\\"')}" `}
                          }
                        ) {
                          id
                          token
                          createdAt
                        }}`);

                    executeQuery.mutationName = "createShop";
                    executeQuery.closeModal = true;
                    break;
                case "edit-name-shop":
                    execute = true;
                    const arrNamesShop = [];
                    if (projectLangs && projectLangs.length > 0) {
                        projectLangs.forEach((lang) => {
                            const inputName =
                                document.querySelector(`#name-shop-${lang.languageRef}`) ||
                                document.querySelector(`#default-name-shop-${lang.languageRef}`);
                            if (inputName && inputName.value !== "") {
                                arrNamesShop.push({ lang: lang.languageRef, text: inputName.value });
                            } else {
                                arrNamesShop.push({ lang: lang.languageRef, text: "" });
                            }
                        });
                    }

                    const stringNamesShop = formatArray(arrNamesShop);

                    setMutation(`mutation {
                        updateShop(
                          id: ${values["id-shop"]}
                          languageCode: "${langDefault}"
                          name: [${stringNamesShop}]
                          customFields: {  }
                        ) {
                          id
                        }
                      }`);
                    executeQuery.mutationName = "updateShop";
                    executeQuery.closeModal = true;
                    executeQuery.multiple = false;
                    break;

                case "delete-shop":
                    execute = true;
                    setMutation(`mutation {
                            deleteShop(
                              id: ${Number(itemsAffected[0])}
                            ) {
                             ok
                            }
                          }`);
                    executeQuery.mutationName = "deleteShop";
                    executeQuery.closeModal = true;
                    break;
                case "delete-shops-batch":
                    execute = true;
                    let mutationDeleteShops = "mutation { ";
                    checkedItems.forEach((item) => {
                        mutationDeleteShops += `deleteShop${item}: deleteShop(id: ${item}) {
                    ok
                  }
                  `;
                    });
                    mutationDeleteShops += "}";
                    setMutation(mutationDeleteShops);
                    executeQuery.mutationName = "deleteShop";
                    executeQuery.closeModal = true;
                    executeQuery.multiple = true;
                    setIsBatch(true);
                    break;
                case "edit-email-orders":
                    execute = true;
                    const {
                        "emails-orders-shop": emailsOrders = [],
                        "emails-orders-users-shop": emailsOrdersUsers = [],
                    } = values || {};

                    const emailsOrdersToString = emailsOrders.map((item) => `"${item}"`).join(",");
                    const emailsOrdersUsersToString = emailsOrdersUsers.join(",");

                    setMutation(`mutation {
                        updateShop(
                          id: ${id}
                          destinationEmails: [
                            ${emailsOrdersToString}
                          ]
                          destinationUsers:[
                            ${emailsOrdersUsersToString}
                          ]
                        ) {
                          id
                          ok
                        }
                      }`);

                    executeQuery.mutationName = "updateShop";
                    executeQuery.closeModal = true;
                    break;
                default:
                    break;
            }
        }
        if (execute) {
            if (!showGlobalLoading) {
                dispatch(setLoading(true));
            }
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (data && executeQuery && !executeQuery.multiple && data[executeQuery.mutationName]) {
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (executeQuery.executeAnotherQuery) {
                dispatch(
                    setExecuteQuery({
                        action: executeQuery.executeAnotherQuery.action,
                        params: { ...executeQuery.executeAnotherQuery.params, ...data[executeQuery.mutationName] },
                    })
                );
            } else {
                dispatch(setExecuteQuery(null));
            }
        } else if (data && executeQuery && executeQuery.multiple) {
            const arrResponse = convertObjectToArray(data);
            const sucessResponse = [];
            const errorsResponse = [];

            arrResponse.forEach((item) => {
                if (item.ok) {
                    shops.forEach((element) => {
                        if (Number(element.id) === Number(item.id)) {
                            sucessResponse.push({ name: element.name });
                        }
                    });
                } else {
                    shops.forEach((element) => {
                        if (Number(element.id) === Number(item.id)) {
                            errorsResponse.push({ name: element.name });
                        }
                    });
                }
            });

            if (sucessResponse.length > 0) {
                toast.success(
                    <div className=" flex flex-col">
                        <span>{capitalizeFirst(t("next-shops-have-been-deleted"))}:</span>
                        {sucessResponse.map((item) => (
                            <span key={item.name}>&#x2022; {item.name}</span>
                        ))}
                    </div>
                );
            }

            if (errorsResponse.length > 0) {
                let msgs = arrangeToastMessagesWhenFailsBatchActions(errorsResponse);
                toast.warning(<UseToast title={t("titlesFailsActionsBatch")} msgs={msgs} minimize={false} />, {
                    autoClose: 3500,
                    className: "use-toast fail-products-delete",
                    closeButton: true,
                    closeOnClick: false,
                    draggable: false,
                    hideProgressBar: true,
                    icon: false,
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }

            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }

            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            dispatch(showGlobalLoading(false));
            removeApolloCacheKeys(client.cache, "all");
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg = getErrorMessage(data, executeQuery);

            toast.error(errorMsg ? errorMsg : t("mutation-error"));
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            if (errorMsg !== "Code already in use!") {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdateShopZafiro);

const getErrorMessage = (data, executeQuery) => {
    let errorMsg = null;

    if (data[executeQuery.action] && data[executeQuery.action].error) {
        if (data[executeQuery.action].error.msg) {
            errorMsg = data[executeQuery.action].error.msg;
        } else {
            errorMsg = data[executeQuery.action].error;
        }
    } else if (data[executeQuery.mutationName] && data[executeQuery.mutationName].error) {
        errorMsg = data[executeQuery.mutationName].error;
    }

    return errorMsg;
};
