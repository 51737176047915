import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Utils
import DayPicker, { DateUtils } from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import useComponentVisible from "../../components/useComponentVisible";
import { useTranslation } from "react-i18next";
import { capitalizeFirst } from "hooks/Utils/Utils";

//Actions
import { setActiveFilters, getFilterQuery, setPage } from "../../actions/tableActions";
import ReactInputMask from "react-input-mask";
import { parse } from "date-fns";

const useFilterDate = ({ index }) => {
    //Data
    const { t } = useTranslation();
    const numberOfMonths = 1;
    const getInitialState = () => {
        return {
            from: undefined,
            to: undefined,
        };
    };
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    //States
    const [rangeState, setRangeState] = useState(getInitialState());
    const { from, to } = rangeState;
    const modifiers = { start: from, end: to };
    const [selectActive, setSelectActive] = useState({
        icon: "",
        container: "",
        text: "",
    });

    //Store
    const { lang } = useSelector((state) => state.ui);
    const { langStrings } = useSelector((state) => state.ui);

    const { name } = useSelector((state) => state.table.availableFilters[index]);
    const activeFilters = useSelector((state) => state.table.activeFilters);

    //Actions
    const dispatch = useDispatch();

    const handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, rangeState);
        setRangeState(range);

        if (range.from) {
            dispatch(setActiveFilters("stayFrom", getFormattedDate(new window.ZDate(range.from))));
        }
        if (range.to) {
            dispatch(setActiveFilters("stayTo", getFormattedDate(new window.ZDate(range.to))));
        }
        if (range.from || range.to) {
            dispatch(setPage(Number(1)));
            dispatch(getFilterQuery(activeFilters));
        } else {
            dispatch(setActiveFilters("stayFrom", null));
            dispatch(setActiveFilters("stayTo", null));
            dispatch(getFilterQuery(activeFilters));
        }
    };

    const handleCleanInput = (type) => {
        if (type === "start") {
            if (rangeState?.to) {
                dispatch(setActiveFilters("stayTo", null));
            } else {
                dispatch(setActiveFilters("stayFrom", null));
            }
        } else {
            dispatch(setActiveFilters("stayTo", null));
        }
        dispatch(getFilterQuery(activeFilters));
        setRangeState((prevRange) => {
            return type === "start"
                ? {
                      from: null,
                      to: prevRange?.to,
                  }
                : {
                      from: prevRange?.from,
                      to: null,
                  };
        });
    };

    //Listeners
    useEffect(() => {
        if (!isComponentVisible) {
            setSelectActive({
                icon: "",
                container: "",
                text: "",
            });
        } else {
            setSelectActive({
                icon: "mr-4 -rotate-180",
                container: "bg-gray-100",
                text: "font-bold text-blue-100",
            });
        }
    }, [isComponentVisible]);

    //Funtions

    function getFormattedDate(date) {
        const year = date.getFullYear();
        const month = (1 + date.getMonth()).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");

        return year + "-" + month + "-" + day;
    }

    return (
        <div className={`inline-block w-filter-checkbox`} ref={ref}>
            <div
                className={`w-full h-10 bg-gray-200 rounded relative flex items-center pr-12 ${selectActive.container}`}
            >
                <div
                    id={`day-picker-${index}`}
                    className={`bg-transparent w-full pl-4 first-capital overflow-hidden text-gray-900 ${selectActive.text}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                >
                    {rangeState && rangeState.from ? (
                        `${new window.ZDate(rangeState.from)
                            .toLocaleDateString([], {
                                month: "2-digit",
                                day: "2-digit",
                            })
                            .padStart(2, "0")} - ${
                            rangeState.to
                                ? new window.ZDate(rangeState.to)
                                      .toLocaleDateString([], {
                                          month: "2-digit",
                                          day: "2-digit",
                                      })
                                      .padStart(2, "0")
                                : ""
                        }`
                    ) : (
                        <span className="text-gray-600">{langStrings[name]}</span>
                    )}
                </div>
                <span
                    id={`day-picker-icon-${index}`}
                    className={`field-icon icon-chevron animated ${selectActive.icon}`}
                    onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
                ></span>
                {isComponentVisible ? (
                    <div className="absolute left-0 w-auto bg-white shadow-lg rounded border border-gray-200 px-1 pt-2 z-100 t-checkboxes-container filterdate">
                        <div className="flex flex-col mb-2 gap-2">
                            <div className="flex flex-row items-center justify-center gap-4">
                                <span className="flex-1 text-center block font-bold">
                                    {capitalizeFirst(t("start_"))}
                                </span>
                                <DateInputComponent
                                    date={from}
                                    range={rangeState}
                                    type={"start"}
                                    onDateChange={(v) => {
                                        if (v["from"]) {
                                            handleDayClick(v["from"]);
                                        }
                                    }}
                                    removeButtonAction={() => {
                                        handleCleanInput("start");
                                    }}
                                />
                            </div>
                            <div className="flex flex-row items-center justify-center gap-4">
                                <span className="flex-1 text-center block font-bold">{capitalizeFirst(t("end_"))}</span>
                                <DateInputComponent
                                    date={to}
                                    range={rangeState}
                                    type={"end"}
                                    onDateChange={(v) => {
                                        if (v["to"]) {
                                            handleDayClick(v["to"]);
                                        }
                                    }}
                                    removeButtonAction={() => {
                                        handleCleanInput("end");
                                    }}
                                />
                            </div>
                        </div>

                        <DayPicker
                            firstDayOfWeek={1}
                            // navbarElement={<UseCalendarNavbar />}
                            className="Selectable"
                            numberOfMonths={numberOfMonths}
                            selectedDays={[from, { from, to }]}
                            modifiers={modifiers}
                            onDayClick={handleDayClick}
                            localeUtils={MomentLocaleUtils}
                            locale={lang}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default useFilterDate;

const formatDateToDisplay = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

const DateInputComponent = ({ date, range, onDateChange, type, removeButtonAction }) => {
    const [inputValue, setInputValue] = useState(date ? formatDateToDisplay(date) : "");

    const { t } = useTranslation();

    const handleInputChange = (event) => {
        const newInputValue = event.target.value;
        setInputValue(newInputValue);
    };

    const handleInputBlur = () => {
        const parsedDate = parse(inputValue, "dd/MM/yyyy", new Date());
        if (isValidDate(parsedDate)) {
            if (type === "start") {
                onDateChange({ from: parsedDate }, "start");
            } else if (type === "end") {
                onDateChange({ to: parsedDate }, "end");
            }
            setInputValue(formatDateToDisplay(parsedDate));
        }
    };

    const handleRemoveClick = () => {
        if (removeButtonAction) {
            removeButtonAction();
        }
    };

    useEffect(() => {
        setInputValue(date ? formatDateToDisplay(date) : "");
    }, [date]);

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    return (
        <div
            id={`date-input-container-${type}`}
            className=" date-input-container overflow-hidden h-10 relative flex rounded bg-gray-100"
        >
            <i style={{ top: "0.6rem", left: "0.5rem" }} className=" absolute icon icon-booking "></i>
            {date ? (
                <button
                    id={`date-input-container-${type}-remove`}
                    onClick={handleRemoveClick}
                    style={{ top: "0.6rem", right: "0.5rem" }}
                    className=" absolute h-4 w-4 flex items-center focus:outline-none justify-center bg-zafiro-600 rounded-full"
                >
                    <i className="icon icon-remove text-white text-xxs "></i>
                </button>
            ) : null}
            <ReactInputMask
                id={`date-input-container-${type}-input`}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                className=" pl-10 bg-gray-100 rounded w-full "
                mask={"99/99/9999"}
                value={inputValue}
                placeholder={t("date-abbreviation")}
            />
        </div>
    );
};
