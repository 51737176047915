const DigitalKey = (id, name, surname, type, rooms, isLoyal, t) => {
    const dataType = "digital-key";

    let guestName = {
        name: "guest",
        cellContent: "text",
        value: `${name} ${surname}`,
        specialWidth: "w-2/12",
    };
    if (isLoyal !== "")
        guestName = {
            name: "guest",
            cellContent: "icon-with-text",
            value: `${name} ${surname}`,
            icon: {
                name: "loyal-customers",
                adjust: "text-zafiro-600 text-base",
                tooltip: "loyal-guest",
            },
            specialWidth: "w-2/12",
        };

    let guestrooms = [];
    let listrooms = [];

    rooms.forEach((room, index) => {
        guestrooms.push({
            name: room.number,
            link: `/hotel/rooms/room/detail/${room.roomID ? room.roomID : room.RoomID}`,
        });
        listrooms.push([index, `${room.name}`, "bg-zafiro-800"]);
    });

    guestrooms.sort(function (a, b) {
        return a.name - b.name;
    });
    let accessCell = {
        name: "accesses",
        cellContent: "textWithTooltip",
        value: t("no-digital-key"),
        specialWidth: "w-4/12",
        icon: {
            name: "info",
            adjust: "absolute text-base pt-1 ml-3 text-gray-600",
        },
        tooltipData: "asociate-email-required",
        adjust: "first-capital",
    };
    if (isLoyal !== "") {
        accessCell = {
            name: "status",
            value: listrooms,
            cellContent: "labelsTruncate",
            cellAdjust: "truncate labels",
            specialWidth: "w-4/12",
        };
    }

    return {
        dataType: dataType,
        id: id,
        info: [
            guestName,
            {
                name: "type",
                cellContent: "text",
                value: type,
                specialWidth: "w-2/12",
                adjust: "first-capital",
            },
            {
                name: "roomNumber",
                cellContent: "link",
                multipleLink: guestrooms,
                specialWidth: "w-2/12",
            },
            accessCell,
        ],
    };
};

export default DigitalKey;
