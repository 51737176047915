const AuthorizeAll = () => {
    let modalInputs = [
        {
            text: ["chromecast-authorize-all-text"],
            type: "void",
        },
    ];
    let modal = {
        title: "chromecast-authorize-all",
        bodyAdjust: "w-4/12",
        inputs: modalInputs,
        executeSectionQuery: true,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "accept",
                style: "blue",
                action: "chromecast-authorize-all",
            },
        ],
    };
    return modal;
};

export default AuthorizeAll;
