import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";

//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setAvailableFilters,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setActions,
    setHeaderButtons,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCustomersTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
//Model
import Group from "./models/Group";
import { useTranslation } from "react-i18next";
import { useMSQuery } from "../useMSQuery";

const ListGroups = () => {
    const { t } = useTranslation();
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, searchString } = useSelector(
        (state) => state.table
    );
    const { langStrings } = useSelector((state) => state.ui);

    //States
    const [sorting] = useState(`,orderBy:{field: "ID", criteria: "desc"}`);
    //Data
    const sectionName = "guest-groups";

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_GUESTGROUPS_INFO = gql`{
    guestGroups: guestGroups(page: ${currentPage}, size: ${perPage}${
        sortCriteria ? sortCriteria : sorting
    }${filterQuery}){
      info { count }
      results { id guestNumber name }
    }
}`;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_GUESTGROUPS_INFO, {
        pollInterval: 300000,
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!loading && !error && data && data.guestGroups && networkStatus === 7) {
            //Get data
            const dataItems = arrangeData(data, langStrings);
            const tableHeaders = GetTableHeaders(sectionName);
            const availableFilters = GetAvailableFilters(sectionName);
            //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName, data)));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.guestGroups.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setExtendedInfo(false));
            dispatch(showRowForm(false));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            if (dataItems && dataItems[0] && dataItems[0].actions) {
                dispatch(setActions());
            }
            dispatch(setCustomError(searchString !== null ? t("no-results-found") : t("no guest groups available")));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus, sortCriteria]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        dispatch(setLoading(loading));
        // eslint-disable-next-line
    }, [loading]);
};

const arrangeData = (data, langStrings) => {
    const groupsData = [];
    // eslint-disable-next-line
    data.guestGroups.results.map((user) => {
        groupsData.push(Group(user, data, langStrings));
    });
    return groupsData;
};

export default ListGroups;
