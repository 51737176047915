import { GetCountryName } from "../../../Utils/CountriesUtils";
import { GetLanguageName } from "../../../Utils/LanguagesUtils";

const Customer = (user, mobileApps, properties, advancedFilters, t) => {
    const {
        id,
        ref,
        name,
        surname,
        email,
        signupApp,
        signupWifi,
        gender,
        birthDate,
        phone,
        country,
        language,
        logins,
        termsAccepted,
        marketingAccepted,
        emailVerified,
    } = user;
    const dataType = "customer";
    let signUpAppName =
        signupApp && mobileApps && mobileApps.filter((mobileApp) => mobileApp.url === signupApp).length > 0
            ? mobileApps.filter((mobileApp) => mobileApp.url === signupApp)[0].name
            : signupWifi && properties && properties.filter((property) => property.ref === signupWifi).length > 0
            ? properties.filter((property) => property.ref === signupWifi)[0].name
            : "";
    let info = [];
    let extendedInfo = [];
    if (advancedFilters) {
        let accessLabels = [];
        if (logins && logins.length > 0) {
            // eslint-disable-next-line
            logins.map((login, index) => {
                let loginAppName =
                    login.app && mobileApps && mobileApps.filter((mobileApp) => mobileApp.url === login.app).length > 0
                        ? mobileApps.filter((mobileApp) => mobileApp.url === login.app)[0].name
                        : login.wifi &&
                          properties &&
                          properties.filter((property) => property.ref === login.wifi).length > 0
                        ? properties.filter((property) => property.ref === login.wifi)[0].name
                        : "";

                if (accessLabels.filter((accessLabel) => accessLabel[1] === loginAppName).length < 1) {
                    accessLabels.push([index, loginAppName, "bg-blue-200"]);
                }
            });
        }
        info = [
            {
                name: "email",
                cellContent: "text",
                value: email,
                specialWidth: "w-3/12",
                adjust: "truncate",
            },
            {
                name: "accesses-text",
                cellContent: "labelsTruncate",
                value: accessLabels,
                specialWidth: "w-5/12",
                cellAdjust: "truncate labels",
            },
            {
                name: "communications",
                cellContent: "text",
                value: t(marketingAccepted ? "accepted" : "not-accepted"),
                adjust: marketingAccepted ? "text-green-100" : "",
                specialWidth: "w-2/12",
            },
            {
                name: "verification",
                cellContent: "text",
                value: t(emailVerified ? "verified-user" : "unverified"),
                adjust: emailVerified ? "text-green-100" : "",
                specialWidth: "w-2/12",
            },
        ];
        extendedInfo = [
            {
                name: "personal-details",
                title: true,
                cellContent: "list",
                specialWidth: "w-3/12",
                listValues: [
                    {
                        name: "name",
                        value: name ? name : `-`,
                    },
                    {
                        name: "surname",
                        value: surname ? surname : `-`,
                    },
                    {
                        name: "title-birthDate",
                        value: birthDate ? new window.ZDate(birthDate).toLocaleDateString() : `-`,
                        translateValue: false,
                    },
                ],
            },
            {
                cellContent: "list",
                specialWidth: "w-3/12",
                listValues: [
                    {
                        name: "mobile-phone",
                        value: phone ? phone : `-`,
                    },
                    {
                        name: "country",
                        value: country ? GetCountryName(country) : `-`,
                    },
                    {
                        name: "lang",
                        value: language ? GetLanguageName(language) : `-`,
                    },
                ],
            },
            {
                name: "signUp",
                title: true,
                cellContent: "list",
                specialWidth: "w-6/12",
                listValues: [
                    {
                        valueAdjust: "rounded py-1 px-4 m-1 text-white inline-block bg-blue-400 first-capital",
                        value: signUpAppName,
                    },
                ],
            },
        ];
    } else {
        info = [
            {
                name: "email",
                cellContent: "text",
                value: email,
                specialWidth: "w-3/12",
                adjust: "truncate",
            },
            {
                name: "name",
                cellContent: "text",
                value: name,
                specialWidth: "w-3/12",
            },
            {
                name: "surname",
                cellContent: "text",
                value: surname,
                specialWidth: "w-2/12",
            },
            {
                cellContent: "labels",
                value: [[0, `${signUpAppName}`, "#06376B"]],
                specialWidth: "w-3/12 truncate px-0",
                cellAdjust: "labels",
            },
            {
                name: "gdpr",
                cellContent: "text",
                value: t("gdpr"),
                adjust: `text-red-100 font-bold ${termsAccepted ? "hidden" : ""}`,
                specialWidth: "w-1/12",
                textTooltip: "customer-pending-gdpr",
            },
        ];
        extendedInfo = [
            {
                name: "personal-details",
                title: true,
                cellContent: "list",
                specialWidth: "w-6/12",
                listValues: [
                    {
                        name: "title-treatment",
                        value: gender ? `${t("{{capitalize, capitalize}}", { capitalize: t(gender) })}` : `-`,
                        translateValue: true,
                    },
                    {
                        name: "title-birthDate",
                        value: birthDate ? new window.ZDate(birthDate).toLocaleDateString() : `-`,
                        translateValue: false,
                    },
                    {
                        name: "mobile-phone",
                        value: phone ? phone : `-`,
                        translateValue: false,
                    },
                    {
                        name: "country",
                        value: country ? country : `-`,
                        translateValue: false,
                    },
                ],
            },
            {
                cellContent: "list",
                specialWidth: "w-6/12",
                listValues: [
                    {
                        name: "sent-commercial-communication",
                        nameAdjust: "block font-bold",
                        value: t(marketingAccepted ? "accepted" : "not-accepted"),
                        valueAdjust: `block ${marketingAccepted ? "text-green-100" : ""}`,
                        noColon: true,
                    },
                    {
                        name: "verification",
                        nameAdjust: "block font-bold mt-5",
                        value: t(emailVerified ? "verified-user" : "unverified"),
                        valueAdjust: `block ${emailVerified ? "text-green-100" : ""}`,
                        noColon: true,
                    },
                ],
            },
        ];
    }

    return {
        dataType: dataType,
        actions: !advancedFilters ? [{ type: dataType, value: ref }] : null,
        id: ref,
        info: info,
        extendedInfo: extendedInfo,
        logins: logins,
        mobileApps: mobileApps,
        properties: properties,
        idCustomer: id,
        marketingAccepted:marketingAccepted,
        actionComponentStyle: { width: "20rem", left: "-265px" },
    };
};

export default Customer;
