import { Session } from "../../../Utils/Session";
import channelUsedInProperties from "../modals/ChannelUsedInProperties";

const CorporateChannel = ({ data, t, lang }) => {
    const { id, logoRef, name, usedInProjectsNum, videosNum, usedInProjects } = data;
    return {
        dataType: "corporateChannel",
        id: id,
        executeSectionQuery: true,
        adjust: "h-auto",
        actions: [
            {
                type: "corporateChannel",
                value: id,
            },
        ],
        name: name,
        usedInProjectsNum: usedInProjectsNum,
        t: t,
        actionComponentStyle: { left: "-160px" },
        info: [
            {
                cellContent: "image",
                isFromUrl: true,
                urlPath: logoRef ? Session.getDasUrl(logoRef + "?lang=" + lang + "&w=70&h=70") : null,
                path: "icons/",
                type: "svg",
                name: "image",
                linkText: name,
                noImgText: true,
                specialWidth: "w-3/12 ",
                adjust: "w-18 h-12 object-cover rounded mr-4",
                notImgFontSize: "0.65rem",
                adjustNotImageIcon: "icon icon-channel text-gray-400 text-xl",
                iconError: "icon-channel text-gray-400 text-xl",
                adjustNotImage:
                    "border border-gray-300 flex items-center justify-center bg-gray-200 w-18 h-12 rounded mr-4",
                adjustText: " text-zafiro-600 cursor-pointer",
                value: name,
                redirectTo: `/services/tv-channels/corporate-channels/${id}`,
            },
            {
                cellContent: "text",
                value: `${videosNum} ${t("videos")}`,
                specialWidth: "w-3/12 pl-1",
                wrap: "truncate",
                id: `corporate-channels-number-videos-${id}`,
            },
            {
                cellContent: "button",
                modal: usedInProjectsNum > 0 ? channelUsedInProperties({ name, usedInProjects }) : null,
                adjust: usedInProjectsNum > 0 ? " font-bold text-zafiro-600 px-0 cursor-pointer" : " px-0",
                name: t("used-in-n-projects", { number: usedInProjectsNum }),
                specialWidth: "w-6/12 pl-1",
                wrap: "truncate",
            },
        ],
    };
};

export default CorporateChannel;
