import React, { useEffect, useLayoutEffect } from "react";
import UseTable from "../../../Table";
import UseSectionHeader from "../../../useSectionHeader";
import { useDispatch } from "react-redux";
import { cleanTableStore } from "../../../../actions/tableActions";
import ListOrderSummary from "../../../../hooks/GraphqlCalls/Sales/ListOrderSummary";

const OrderSummary = () => {
    const dispatch = useDispatch();

    useLayoutEffect(() => {
        dispatch(cleanTableStore());
    }, []);

    const breadcrumbs = [
        {
            name: "services",
            translate: true,
            route: "/services/",
        },
        {
            name: "sales",
            translate: true,
            route: "/services/",
        },
        {
            name: "orders",
            translate: true,
            route: "/services/sales/order-summary",
        },
    ];

    return (
        <>
            <UseSectionHeader title={"orders"} customBreadCrumbs={breadcrumbs} />
            <UseTable />
            <ListOrderSummary />
        </>
    );
};

export default OrderSummary;
