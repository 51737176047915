const EditEmailOrders = (data, t) => {
    return {
        title: "edit",
        value: t("email-to-receive-orders"),
        bodyAdjust: "w-8/12",
        executeSectionQuery: true,
        inputs: [
            {
                type: "editEmailOrders",
                data: data ? data : null,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "edit-email-orders",
            },
        ],
    };
};

export default EditEmailOrders;
