import GetIconColor from "../../../../Utils/DataCalc/GetIconColor";
import { parseBoolean } from "../../../../Utils/Utils";
import _ from "lodash";

const HotelRoom = (hotelRoom, guestAccess) => {
    const dataType = "room";
    const iconWarning =
        hotelRoom.warnings > 0
            ? {
                  name: "warning",
                  values: hotelRoom.warnings,
                  dataSet: dataType,
                  adjust: `${GetIconColor(dataType, 2)} text-xl`,
              }
            : {};
    const guestCells = getGuestCells(hotelRoom, guestAccess);

    let textWarning = "";

    if (hotelRoom.warnings > 0) {
        if (hotelRoom.warnings === 1) {
            textWarning = [hotelRoom.warnings, " ", "warning"];
        } else {
            textWarning = [hotelRoom.warnings, " ", "warnings"];
        }
    }

    // -- DATA -- //
    hotelRoom = {
        ...hotelRoom,
        dataType: dataType,
        actions: [{ type: dataType, value: hotelRoom.id }],
        info: [
            {
                name: dataType,
                value: !hotelRoom.name.includes(hotelRoom.room) ? hotelRoom.name : "",
                subvalue: hotelRoom.roomNumber ? hotelRoom.roomNumber : "",
                link: `/hotel/rooms/room/detail/${hotelRoom.id}`,
                cellContent: "link",
                specialWidth: "w-4/12",
            },
            {
                name: "group",
                label: {
                    text: hotelRoom.group.length > 0 ? hotelRoom.group[0] : "",
                },
                tipValue: hotelRoom.group.length - 1,
                tooltipData:
                    hotelRoom.group.length > 0 ? hotelRoom.group.slice(1, hotelRoom.group.length).join("<br>") : "",
                cellContent: "tagWithTooltip",
                specialWidth: "w-3/12",
            },
            ...guestCells,
            {
                name: "warning",
                value: textWarning,
                evaluate: true,
                type: dataType,
                cellContent: "icon-with-text",
                specialWidth: guestAccess ? "w-3/12" : "w-11/12",
                icon: iconWarning,
            },
        ],
    };
    return hotelRoom;
};

const getGuestCells = (hotelRoom, guestAccess) => {
    const response = [];
    const statusTag = parseBoolean(hotelRoom.status) ? [1, "occupied", "#47A1FF"] : [1, "empty", "#A7B5C4"];
    const guests =
        hotelRoom && hotelRoom.guests
            ? hotelRoom.guests.map((guest) => ({
                  name: guest.name,
                  id: guest.id,
              }))
            : [];
    const format = {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    };
    const stayList = () => {
        const stayFrom = new Date(hotelRoom.stayFrom);
        const stayTo = new Date(hotelRoom.stayTo);
        const lastStay = new Date(hotelRoom.lastStay);
        if (parseBoolean(hotelRoom.status)) {
            return [
                {
                    value: stayFrom.toLocaleDateString(undefined, format),
                },
                {
                    value: hotelRoom.stayTo ? stayTo.toLocaleDateString(undefined, format) : "-",
                },
            ];
        } else {
            return [
                {
                    name: "last-stay",
                    value: " ",
                },
                {
                    value: hotelRoom.lastStay ? lastStay.toLocaleDateString(undefined, format) : "-",
                },
            ];
        }
    };
    if (guestAccess) {
        response.push(
            {
                name: "status",
                value: [statusTag],
                cellContent: "labels",
                specialWidth: "w-3/12",
            },
            {
                name: "guest",
                cellContent: "icon-guest",
                link: "/customers/guests/guest-list/guest/",
                values: guests,
                specialWidth: "w-2/12",
            },
            {
                name: "stay",
                listValues: stayList(),
                cellContent: "list",
                specialWidth: "w-3/12",
            }
        );
    }
    return response;
};

export default HotelRoom;
