import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import translate from "../../lang/es/Global/translate";
//Routes
import { routes } from "../../routes";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
//Actions
import { setLangStrings } from "../../actions/uiActions";
//Utils
import { toast } from "react-toastify";
import { Session } from "../../hooks/Utils/Session";
//Translate
import GetLangStrings from "../../lang/getLangStrings";
import DisplayMenuSidebar from "../../hooks/Utils/DisplayMenuSidebar";
import { useQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { useSeleniumChecker } from "zdev";
import { pathsWithoutChainOrProjectRef } from "hooks/Utils/Utils";

const Section = () => {
    const menusDisplay = DisplayMenuSidebar();
    const paddings = `${menusDisplay.style.x} ${menusDisplay.style.y}`;
    //Store data
    const { lang, permissions, sectionStyle, warnings, warningsMinimized } = useSelector((state) => state.ui);
    const { impersonated } = useSelector((state) => state.auth);
    //States
    const [authLayout, setAuthLayout] = useState(false);

    const { check: checkPage } = useSeleniumChecker();

    //Data
    const isLoggedIn = Session.getSessionProp("token");
    const toastCookie = Session.getSessionProp("toast");
    const toastObject = toastCookie ? JSON.parse(toastCookie) : false;
    const location = useLocation();
    const isNonChainRefOrProjectPath = [...pathsWithoutChainOrProjectRef, "/"].includes(location.pathname);

    const GET_ACCESSES = gql`
        {
            accesses {
                results {
                    name
                    category
                }
            }
        }
    `;

    const { loading, data, error } = useQuery(GET_ACCESSES, {
        skip: isNonChainRefOrProjectPath || !isLoggedIn,
    });

    //Actions
    const dispatch = useDispatch();
    //Listeners
    useEffect(() => {
        if (isLoggedIn) {
            setAuthLayout(true);
        } else {
            setAuthLayout(false);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        //Gets lang strings
        let langStrings;
        if (lang && location) {
            langStrings = GetLangStrings(lang, location.pathname);
            dispatch(setLangStrings(langStrings));
        } else {
            langStrings = GetLangStrings("en", location.pathname);
            dispatch(setLangStrings(langStrings));
        }
        if (lang && toastObject && toastObject.type) {
            let message = langStrings[toastObject.text]
                ? `${langStrings[toastObject.text]} `
                : `${translate(toastObject.text)} `;
            if (toastObject.data) {
                message += toastObject.data;
            }

            toast[toastObject.type](message, {
                position: "top-right",
                autoClose: 3000,
            });
            Session.removeSessionProp("toast");
        }
        // eslint-disable-next-line
    }, [lang, location]);

    useEffect(() => {
        if (location) {
            const cLocation = Session.getSessionProp("current-location");
            Session.setSessionProp("previous-location", cLocation);
            Session.setSessionProp("current-location", location.pathname);
        }

        // Check page
        checkPage();

        // eslint-disable-next-line
    }, [location]);

    const authLayoutClass = !authLayout ? "" : " right-0 bg-gray-100 ";

    return (
        <div
            id="sectionContent"
            className={sectionStyle ? sectionStyle : `${paddings} ${authLayoutClass}`}
            style={{
                height: `calc(100vh ${!menusDisplay.hideHeader ? "- 72px" : ""} - ${warnings ? "5" : "0"}rem  + ${
                    warningsMinimized ? "3" : "0"
                }rem - ${impersonated ? "2" : "0"}rem)`,
                overflow: "scroll",
            }} //72 pixel correspond to max menu height
        >
            {permissions ? (
                <Routes>
                    {routes
                        .filter(
                            (route) => typeof route.section !== "undefined" || typeof route.redirect !== "undefined"
                        )
                        .map((route, index) => (
                            <Route
                                key={index}
                                {...route}
                                element={
                                    route.redirect !== undefined ? <Navigate to={route.redirect} /> : <route.section />
                                }
                            />
                        ))}
                </Routes>
            ) : null}
        </div>
    );
};

export default Section;
