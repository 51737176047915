import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "@apollo/react-hooks";
import { useEffect, useState } from "react";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { toast } from "react-toastify";

import { gql } from "apollo-boost";
import { capitalizeFirst } from "../../Utils/Utils";
import { setLoading, setRefreshData, cleanCheckedItems } from "../../../actions/tableActions";
import { cleanAction } from "../../../actions/globalActions";
import { withApollo } from "@apollo/react-hoc";
import { removeApolloCacheKeys } from "../../Utils/Utils";

const UpdatePasscode = ({ client }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { executeQuery } = useSelector((state) => state.sectionContent);
    const { values } = useSelector((state) => state.action);
    const { checkedItems } = useSelector((state) => state.table);

    //State
    const [mutation, setMutation] = useState("mutation{checkIn}");

    const MUTATION_OBJECT = gql`
        ${mutation}
    `;

    const [executeMutation, { data }] = useMutation(MUTATION_OBJECT, {
        errorPolicy: "all",
        onError(err) {
            //this control is in this onError, because errorPolicy =all is not working like in queryes
            //Custom errors
            // let validations = {};
            let errorMsg = err.message;

            dispatch(setExecuteQuery(null));
            dispatch(showGlobalLoading(false));
            toast.error(errorMsg);
        },
    });

    useEffect(() => {
        let execute = false;
        if (executeQuery) {
            executeQuery.mutationName = "";
            switch (executeQuery.action) {
                case "add-passcode":
                    execute = true;
                    const limitOfDevices =
                        values && values["passcode-limit-of-devices"] && Number(values["passcode-limit-of-devices"]) > 0
                            ? Number(values["passcode-limit-of-devices"])
                            : "";

                    const limitOfDays =
                        values &&
                        values["passcode-limit-of-devices-days"] &&
                        Number(values["passcode-limit-of-devices-days"]) > 0
                            ? Number(values["passcode-limit-of-devices-days"])
                            : "";

                    const limitOfHours =
                        values &&
                        values["passcode-limit-of-devices-hours"] &&
                        Number(values["passcode-limit-of-devices-hours"]) > 0
                            ? Number(values["passcode-limit-of-devices-hours"])
                            : "";

                    const renovationDays =
                        values && values["passcode-renovation-days"] && Number(values["passcode-renovation-days"]) > 0
                            ? Number(values["passcode-renovation-days"])
                            : "";

                    const renovationHours =
                        values && values["passcode-renovation-hours"] && Number(values["passcode-renovation-hours"]) > 0
                            ? Number(values["passcode-renovation-hours"])
                            : "";

                    setMutation(`
                    mutation {
                        createWifiAccessCode(
                          code: "${values["passcode-code"]}"
                          accessprofileId: ${Number(values["passcode-access-profile"])}
                          ${limitOfDevices ? `maxusers: ${limitOfDevices}` : ""}
                          ${limitOfHours ? `durationHours: ${limitOfHours}` : ""}
                          ${limitOfDays ? `durationDays: ${limitOfDays}` : ""}
                          ${renovationDays ? `renovationDays: ${renovationDays}` : ""}
                          ${renovationHours ? `renovationHours: ${renovationHours}` : ""}
                        ) {
                          error
                          ok
                          __typename
                        }
                      }
                    `);
                    executeQuery.mutationName = "createWifiAccessCode";
                    executeQuery.closeModal = true;
                    break;
                case "delete-passcode":
                    execute = true;
                    setMutation(`
                    mutation {
                        deleteWifiAccessCode(
                          ID:[${Number(values["passcode-id"])}]
                        ) {
                          error
                          ok
                          __typename
                        }
                      }
                    `);
                    executeQuery.mutationName = "deleteWifiAccessCode";
                    executeQuery.closeModal = true;
                    break;
                case "delete-passcodes":
                    execute = true;
                    setMutation(`
                    mutation {
                        deleteWifiAccessCode(
                          ID:[${checkedItems}]
                        ) {
                          error
                          ok
                          __typename
                        }
                      }
                    `);
                    dispatch(cleanCheckedItems());
                    executeQuery.mutationName = "deleteWifiAccessCode";
                    executeQuery.closeModal = true;
                    break;
                default:
                    break;
            }
        }
        if (execute) {
            if (!showGlobalLoading) dispatch(setLoading(true));
            if (!executeQuery.hideLoading) {
                dispatch(showGlobalLoading(true));
            }

            setTimeout(function () {
                executeMutation();
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    useEffect(() => {
        if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].isOk || data[executeQuery.action].ok)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].isOk || data[executeQuery.mutationName].ok)))
        ) {
            if (executeQuery.closeModal) {
                dispatch(setModalContent(false));
                dispatch(closeModal());
            }
            if (!executeQuery.params || (executeQuery.params && !executeQuery.params.noCleanAction)) {
                dispatch(cleanAction());
            }

            if (!executeQuery.preventToast) {
                toast.success(capitalizeFirst(t(executeQuery.customToast || "operation-successful")));
            }
            dispatch(setExecuteQuery(null));
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            dispatch(showGlobalLoading(false));
            removeApolloCacheKeys(client.cache, "all");
        } else if (
            data &&
            executeQuery &&
            ((data[executeQuery.action] && (data[executeQuery.action].errorMsg || data[executeQuery.action].error)) ||
                (data[executeQuery.mutationName] &&
                    (data[executeQuery.mutationName].errorMsg || data[executeQuery.mutationName].error)))
        ) {
            const errorMsg =
                data[executeQuery.action] && data[executeQuery.action].error
                    ? data[executeQuery.action].error.msg
                        ? data[executeQuery.action].error.msg
                        : data[executeQuery.action].error
                    : data[executeQuery.mutationName] && data[executeQuery.mutationName].error
                    ? data[executeQuery.mutationName].error
                    : null;

            toast.error(
                errorMsg
                    ? errorMsg === "Code already in use!"
                        ? t("code-already-in-use")
                        : errorMsg
                    : t("mutation-error")
            );
            dispatch(showGlobalLoading(false));
            dispatch(setExecuteQuery(null));
            if (errorMsg !== "Code already in use!") {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
        }
        // eslint-disable-next-line
    }, [data]);

    return null;
};

export default withApollo(UpdatePasscode);
