import { useEffect } from "react";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { Session } from "../../../hooks/Utils/Session";

const PropertyConfig = (projectID) => {
    const currentHotelData = projectID ? null : Session.getSessionProp("currentHotel");
    const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : null;
    const id = projectID || (currentHotel ? currentHotel.id : null);

    const GET_PROJECT_CONFIG = gql`
        query Config($id: Int64!) {
            properties(id: $id) {
                results {
                    information {
                        chatURL
                        chatPublic
                    }
                }
            }
        }
    `;
    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_PROJECT_CONFIG, {
        pollInterval: 0,
    });

    const results = data && data.properties ? data.properties.results : null;
    const config = results && results.length > 0 ? results[0].information : null;

    useEffect(() => {
        executeQuery({
            variables: { id: id },
        });
    }, [executeQuery, id]);

    return { config, loading, error, refetch };
};

export default PropertyConfig;
