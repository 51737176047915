import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { showGlobalLoading } from "../../actions/uiActions";
import { changeActionValues } from "../../actions/globalActions";
import { useTranslation } from "react-i18next";

//API
import { gql } from "apollo-boost";
import { SCREEN } from "../../hooks/Utils/Design/EditorUtils";
import { useLazyQuery } from "@apollo/react-hooks";
import { Subcategories } from "sales-layout-library/web";
import { IFrame } from "../useIframe";
import ShopProducts from "../Section/Design/Sales/layouts/ShopProducts";
import { DEVICE } from "../../hooks/Utils/Design/EditorUtils";

const UseModalTemplates = ({ index }) => {
    const { t } = useTranslation();

    //States
    const [templates, setTemplates] = useState([]);
    const [type, setType] = useState("DEFAULT");
    const [templateSelected, setTemplateSelected] = useState();

    //Store data
    const actionData = useSelector((state) => state.action);
    const currentLang = localStorage.getItem("lang");

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_TEMPLATES = gql`
    {
        templates (filter:{type:${type}}, lang:"${currentLang || "en"}", render:{audio:false, autoplay:false}  ){
            results{
         name
         id
         isEnabled
         HTML       
            }  
        }
    }`;

    const [getTemplatesQuery, { data }] = useLazyQuery(GET_TEMPLATES, { fetchPolicy: "network-only" });

    useEffect(() => {
        if (actionData?.values?.["content-type"]?.[0]) {
            setType(actionData.values["content-type"][0]);
        }
    }, [actionData]);
    useEffect(() => {
        if (type && type !== "DEFAULT") {
            dispatch(showGlobalLoading(true));
            getTemplatesQuery();
        }
        // eslint-disable-next-line
    }, [type]);

    useEffect(() => {
        if (templateSelected) {
            dispatch(
                changeActionValues({
                    "template-id": templateSelected,
                })
            );
        }
        // eslint-disable-next-line
    }, [templateSelected]);

    useEffect(() => {
        if (data?.templates) {
            setTemplates(data.templates.results);
            setTimeout(
                function () {
                    setTemplatesBody(this);
                }.bind(data.templates.results),
                500
            );
        }
        // eslint-disable-next-line
    }, [data]);

    //Functions

    const setTemplatesBody = (templatesData) => {
        dispatch(showGlobalLoading(false));
        templatesData.map((template) =>
            template.isEnabled
                ? (document.querySelector(`#previewContainer_${template.id}`).srcdoc = template.HTML)
                : null
        );
    };

    const printTemplate = (id, name) => {
        const isDefault = id === -99;

        const isSalesTV = type === SCREEN.TYPE.SALES;
        const isSalesMobile = type === SCREEN.TYPE.SALESMOBILE;
        const isSales = isSalesTV || isSalesMobile;
        const isMobile = type === "CCPAIRINGSUCCESSFUL" || type === "MOBILE" || type === "SALESMOBILE";
        const isSelected = templateSelected === id;

        const defaultBlank = (
            <div
                title="preview"
                className={`relative inline-block overflow-hidden bg-white border border-gray-200 h-full ${
                    isMobile ? "w-1/3" : "w-full"
                }`}
                style={{ height: "82%" }}
            ></div>
        );

        const changeSalesiFrameStyles = () => {
            const iframe = document.getElementById("newSalesPredefinedScreen");
            if (iframe) {
                const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                const cssContent = `
                   *,html {
                        overflow: hidden!important;
                       
                    }
                `;
                const styleElement = iframeDocument.createElement("style");
                styleElement.innerHTML = cssContent;
                iframeDocument.head.appendChild(styleElement);
            }
        };

        const defaultSalesTV = () => (
            <>
                <IFrame id="newSalesPredefinedScreen" onLayoutCallBack={changeSalesiFrameStyles}>
                    <Subcategories templating={{ css: {} }} />
                </IFrame>
                <div className="absolute top-0 left-0 bottom-0 right-0"></div>
            </>
        );

        const defaultSalesMobile = () => (
            <div className="bg-gray-100 text-center p-1">
                <div className="relative inline-block overflow-hidden border-gray-200 w-1/3">
                    <ShopProducts device={DEVICE.TYPE.MOBILE} width={80} />
                </div>
            </div>
        );

        const templatePreview = () => (
            <div
                className={`relative inline-block overflow-hidden bg-red-200 border border-gray-200 h-full ${
                    isMobile ? "w-1/3" : "w-full"
                }`}
                style={{ height: "82%" }}
            >
                <iframe
                    title="preview"
                    allow=""
                    sandbox="allow-scripts"
                    style={{
                        width: "400%",
                        height: "400%",
                        transform: "scale(0.25)",
                        transformOrigin: "0 0",
                        WebkitTransform: "scale(0.25)",
                        WebkitTransformOrigin: "0 0",
                        MozTransform: "scale(0.25)",
                        MozTransformOrigin: "0 0",
                        msTransform: "scale(0.25)",
                        msTransformOrigin: "0 0",
                        msZoom: "0.25",
                        OTransform: "scale(0.25)",
                        OTransformOrigin: "0 0",
                    }}
                    id={`previewContainer_${id}`}
                ></iframe>
                <div className="absolute top-0 left-0 bottom-0 right-0"></div>
            </div>
        );

        return (
            <div
                className={`relative overflow-hidden text-center bg-gray-100 rounded ${
                    isSelected ? "border-4 border-zafiro-400 " : "border border-gray-300"
                }`}
                style={{
                    left: `0`,
                    height: `14vw`,
                }}
                id={`template-option-${id < 0 ? "default" : id}`}
                onClick={(e) => setTemplateSelected(id)}
                key={id}
            >
                {isDefault && isSalesTV ? defaultSalesTV() : null}
                {isDefault && isSalesMobile ? defaultSalesMobile() : null}
                {!isSales && isDefault ? defaultBlank : null}
                {!isDefault ? templatePreview() : null}
                <div
                    className={`${isSelected ? "text-zafiro-400 font-bold" : null} first-capital pb-6 pt-3 text-center`}
                >
                    {name ? t(name) : "-"}
                </div>
            </div>
        );
    };

    if (type === "DEFAULT") {
        return <div style={{ minHeight: "20rem" }}></div>;
    }
    return (
        <>
            <div className="first-capital mb-2">{t("select-template")}:</div>

            <div className="rounded grid w-full grid-cols-4 gap-4" style={{ rowGap: "3vh" }}>
                {printTemplate(
                    -99,
                    type === SCREEN.TYPE.WELCOME || type === SCREEN.TYPE.SALES || type === SCREEN.TYPE.SALESMOBILE
                        ? t("default-sequence")
                        : t("blank screen")
                )}
                {templates.map((template) => (template?.isEnabled ? printTemplate(template.id, template.name) : null))}
            </div>
        </>
    );
};

export default UseModalTemplates;
