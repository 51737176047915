const AccountUser = (user, chains, startImpersonation) => {
    const { id, chainID, ref, fullName, email, hasCorporate, brands, properties } = user;
    const dataType = "user";
    let isEditable = true;
    let sendPassword = true;
    let listProperties = [];
    let disableCheckbox = false;
    let temporalIndex = 0;

    let accountName = null;

    if (brands.length > 0) {
        brands.forEach((brand) => {
            listProperties.push([temporalIndex, `${brand.name}`, "bg-orange-100"]);
            temporalIndex++;
        });
    }
    if (properties.length > 0) {
        properties.forEach((property) => {
            listProperties.push([temporalIndex, `${property.name}`, "bg-teal-500"]);
            temporalIndex++;
        });
    }

    const chain = chains.filter((chain) => parseInt(chain.id) === parseInt(chainID));
    if (chain) {
        accountName = chain[0].name;
    }
    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        executeSectionQuery: true,
        actions: [{ type: dataType, value: id }],
        isEditable: isEditable,
        sendPassword: sendPassword,
        disableCheckbox: disableCheckbox,
        info: [
            {
                cellContent: "text",
                value: fullName,
                specialWidth: "w-2/12",
                icon: hasCorporate ? "corporate-access" : null,
                iconAdjust: "text-lg",
                iconTooltip: "corporate-access",
                tooltip: "",
                wrap: "truncate",
            },

            {
                cellContent: "text",
                value: email,
                specialWidth: "w-3/12",
                wrap: "truncate",
            },
            /*{
                cellContent: "text",
                value: email,
                specialWidth: "w-3/12",
            },*/
            {
                cellContent: "labels",
                value: accountName ? [[0, `${accountName}`, "#6554C0"]] : [],
                specialWidth: "w-2/12",
                cellAdjust: "labels",
            },
            {
                cellContent: "labelsTruncate",
                value: listProperties,
                specialWidth: "w-3/12",
                cellAdjust: "labels",
            },
            {
                cellContent: "button",
                name: "impersonate user",
                buttonColor: "blue",
                action: startImpersonation,
                actionParams: {
                    ref: ref,
                    fullName: fullName,
                    email: email,
                },
                specialWidth: "w-2/12",
            },
            /*,
            {
                name: "room-number",
                cellContent: "text",
                value: `55`,
                specialWidth: "w-3/12",
            },
            {
                name: "pms-number",
                cellContent: "text",
                value: `666`,
                specialWidth: "w-2/12",
            },*/
        ],
    };
};

export default AccountUser;
