import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
//Actions
import { showGlobalLoading } from "../../../actions/uiActions";
import { setActionInput } from "../../../actions/globalActions";
//Utils
import { toast } from "react-toastify";
import { escapeSpecialChars } from "../../Utils/Utils";

function SetWifiSettingsConfig() {
    //Store data
    const { actionData } = useSelector((state) => state.action);
    const { langStrings } = useSelector((state) => state.ui);
    const save = actionData && actionData.save ? actionData.save : false;
    const networkQuery = actionData && actionData.networkQuery ? actionData.networkQuery : "default";

    //Actions
    const dispatch = useDispatch();

    const getMutation = (networkQuery) => {
        switch (networkQuery) {
            case "wifipmsaccess":
                const id = parseInt(actionData && actionData.wifipmsaccessid);
                const uploadBw = parseFloat(actionData && actionData.wifipmsaccessuploadbw);
                const donwloadBw = parseFloat(actionData && actionData.wifipmsaccessdownloadbw);
                const maxDevices = parseInt(actionData && actionData.wifipmsaccessmaxdevices);

                const finalId = id ? id : 0;
                const finalUploadBw = isNaN(uploadBw) ? 1 : uploadBw;
                const finalDownloadBw = isNaN(donwloadBw) ? 1 : donwloadBw;
                const finalMaxDevices = isNaN(maxDevices) ? 1 : maxDevices;

                return gql`mutation {
                    updatePMSAccess(id: ${finalId}, uploadBw: ${finalUploadBw}, downloadBw: ${finalDownloadBw}, maxDevices: ${finalMaxDevices}) {
                        id
                        ok
                        error
                    }
                }`;
            case "hotelwifi":
                return gql`mutation{
                    setWifiSettings(kind:"Hotel", name: "${escapeSpecialChars(actionData.hotelwifiname)}", password: "${
                    actionData.hotelwifipass && actionData.hotelwifipass !== undefined
                        ? escapeSpecialChars(actionData.hotelwifipass)
                        : ""
                }", hidden:false, auth:"")
                    { error ok}
                }`;
            default:
                return gql`
                    mutation {
                        setWifiSettings(kind: "Hotel", enabled: false, name: "default", password: "default") {
                            error
                            ok
                        }
                    }
                `;
        }
    };
    const NETWORK_QUERY = getMutation(networkQuery);
    const [executeMutation, { data }] = useMutation(NETWORK_QUERY, {
        onError(err) {
            console.log(err);
            dispatch(showGlobalLoading(false));
            toast.error(langStrings["mutation-error"]);
        },
    });

    useEffect(() => {
        if (save) {
            dispatch(showGlobalLoading(true));
            dispatch(setActionInput({ save: false }));
            dispatch(setActionInput({ networkQuery: "" }));

            executeMutation();
            dispatch(setActionInput({ refreshData: true }));
        }
        // eslint-disable-next-line
    }, [save]);

    useEffect(() => {
        if (
            (data && data.updatePMSAccess && data.updatePMSAccess.ok === true) ||
            (data && data.setWifiSettings && data.setWifiSettings.ok === true)
        ) {
            toast.success(langStrings["operation-successful"]);
            dispatch(setActionInput({ refreshData: true }));
            dispatch(showGlobalLoading(false));
        } else if (
            (data && data.updatePMSAccess && data.updatePMSAccess.error) ||
            (data && data.setWifiSettings && data.setWifiSettings.error)
        ) {
            toast.error(langStrings["mutation-error"]);
            dispatch(showGlobalLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);
}

export default SetWifiSettingsConfig;
