import { DELETE_PRODUCT_EXTRA } from "../../../Utils/Integrations/ShopSettingsUtils";

const DeleteExtra = (id, name) => {
    return {
        title: ["delete", name],
        text: "delete-extra-description",
        id: id,
        executeSectionQuery: true,
        useGlobalLoading: true,
        inputs: [],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "delete",
                style: "red",
                action: DELETE_PRODUCT_EXTRA,
            },
        ],
    };
};

export default DeleteExtra;
