import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { useNavigate as useHistory } from "react-router-dom";

//Components
import UseIcon from "../useIcon";
//Actions
import { openModal, setModalContent } from "../../actions/uiActions";
import { changeActionValues, changeGlobalAction } from "../../actions/globalActions";
import { showRowForm, setExecuteQuery } from "../../actions/sectionActions";
import { setRefreshData, setRowInEdition } from "../../actions/tableActions";
//Utils
import getAvailableActions from "../../hooks/Utils/DataCalc/GetAvailableActions";
import getActionModalContent from "../../hooks/Utils/DataCalc/getActionModalContent";
import useComponentVisible from "../../components/useComponentVisible";
import { toast } from "react-toastify";
import { Session } from "../../hooks/Utils/Session";
import GetRowActionModalButtons from "../../hooks/Utils/Table/GetRowActionModalButtons";
import { useTranslation } from "react-i18next";
import Parser from "hooks/Utils/Parser";
import ReactTooltip from "components/ReactTooltip";

const UseRowActions = ({
    actions,
    id,
    dataItem,
    rowIndex,
    depth,
    setHideRow,
    resultsIndex,
    adjust,
    componentStyle,
    dataType,
    subrowData,
}) => {
    //Store data
    const { t } = useTranslation();
    const { langStrings } = useSelector((state) => state.ui);
    const { rowForm } = useSelector((state) => state.sectionContent);
    const { results } = useSelector((state) => state.table);
    const { dataType: storeDataType } = useSelector((state) =>
        state.table.results[resultsIndex]?.dataType ? { dataType: state.table.results[resultsIndex].dataType } : {}
    );
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const finalDataType = () => {
        return storeDataType ? storeDataType : dataType;
    };
    //Data
    const isHeader = actions ? "block" : "hidden";
    const icon = {
        name: "row-actions",
    };

    //History
    const history = useHistory();

    //States
    const [active, setActive] = useState("");
    const [prevResult, setPrevResult] = useState(results[resultsIndex]);
    const [openUpwards, setOpenUpwards] = useState(false);

    //Actions
    const dispatch = useDispatch();

    const availableActions = () => {
        let res = [];
        if (actions) {
            if (!subrowData) {
                res = getAvailableActions(actions[0].type, actions[0].value, results[resultsIndex], t);
            } else {
                res = getAvailableActions(actions[0].type, actions[0].value, subrowData, t);
            }
        }
        return res;
    };

    const rowActions = availableActions();

    const showModal = (e, action) => {
        //Get action modal content...
        const actionInputs = getActionModalContent(
            e.currentTarget.dataset.action_name,
            finalDataType(),
            e.currentTarget.dataset,
            !subrowData ? results[resultsIndex] : subrowData,
            t
        );
        let modal = {
            id: id,
            title: e.currentTarget.dataset.modal_title
                ? e.currentTarget.dataset.modal_title
                : e.currentTarget.dataset.action_name,
            text: actionInputs.text,
            value: ["room", "chromecast", "tv", "device", "companion"].includes(finalDataType())
                ? dataItem.name
                : dataItem.info[0].value,
            executeSectionQuery: dataItem.executeSectionQuery,
            czRef: dataItem.ref,
            inputs: actionInputs,
            noCapitalizeTitle: true,
            buttons: GetRowActionModalButtons(e.currentTarget.dataset.action_name),
        };

        if (e.currentTarget.dataset.email) {
            modal.email = e.currentTarget.dataset.email;
        }
        if (e.currentTarget.dataset.name) {
            modal.name = e.currentTarget.dataset.name;
        }
        if (e.currentTarget.dataset.action_name === "edit-design") {
            let currentHotel =
                Session.getSessionProp("currentHotel") && Session.getSessionProp("currentHotel") !== ""
                    ? JSON.parse(Session.getSessionProp("currentHotel"))
                    : [];
            let isCorporateAccess = currentHotel && currentHotel.ref === "CORPORATE" ? true : false;
            if (isCorporateAccess) {
                modal.bodyAdjust = "w-10/12";
                modal.twoColumns = true;
            }
        }

        switch (e.currentTarget.dataset.action_name) {
            case "add-filter":
            case "handle-mac-exceptions":
                modal.bodyAdjust = "w-4/4";
                break;
            case "device-edit-tv-inputs":
                modal.bodyAdjust = "w-5/12";
                break;

            case "edit-input-group":
                modal.bodyAdjust = "w-8/12";
                break;

            case "manage-tvs-assigned":
                modal.bodyAdjust = "w-10/12";
                break;

            case "set-as-unavailable-shop":
            case "delete-shop":
            case "set-as-available-shop":
            case "edit-name-shop":
            case "device-detach-from-property":
            case "delete-grid":
            case "device-delete-device":
            case "device-uninstall":
            case "device-change-device-function":
            case "staff-generate-password":
            case "staff-delete":
                modal.bodyAdjust = "w-1/12";
                break;

            default:
                break;
        }

        if (
            e.currentTarget.dataset.action_name === "media-library-update-media" ||
            e.currentTarget.dataset.action_name === "media-library-move-content" ||
            e.currentTarget.dataset.action_name === "media-library-delete-permanently" ||
            e.currentTarget.dataset.action_name === "font-library-delete-permanently"
        ) {
            modal.useGlobalLoading = true;
        }

        const initialInputData = () => {
            let selectedValues = {};
            if (modal.inputs) {
                // eslint-disable-next-line
                modal.inputs.forEach((input) => {
                    selectedValues[input.name] = input.selected;
                });
            }
            return selectedValues;
        };

        dispatch(
            changeGlobalAction({
                actionName: e.currentTarget.dataset.action_name,
                itemsAffected: [e.currentTarget.dataset.id],
                params: dataItem?.params ? dataItem.params : null,
                values: initialInputData(),
            })
        );
        if (action.action_values) {
            dispatch(changeActionValues({ ...action.action_values }));
        }
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const showEditRowForm = (e) => {
        if (rowForm) {
            toast.error(
                `${langStrings["please"]}, ${langStrings["save-before"]} ${langStrings["edit-anothero"]} ${langStrings["staff-member"]}`
            );
        } else {
            dispatch(
                changeGlobalAction({
                    actionName: e.currentTarget.dataset.action_name,
                    itemsAffected: [e.currentTarget.dataset.id],
                    values: {
                        name: e.currentTarget.dataset.name,
                        email: e.currentTarget.dataset.email,
                        roles: e.currentTarget.dataset.roles,
                    },
                })
            );
            setHideRow(true);
            dispatch(setRowInEdition(resultsIndex));
            dispatch(showRowForm(true));
        }
    };

    //API
    const getMutation = () => {
        let mutation = ``;
        mutation += `mutation{
          updateCommonZone(
              ${id && Number.isInteger(id) ? `id: ${id},` : ``}
            currentStatus: true
          ){ error id ok}}`;

        return mutation;
    };
    const mutationChain = getMutation();
    const DYNAMIC_MUTATION = gql`
        ${mutationChain}
    `;
    const [executeMutation, { data }] = useMutation(DYNAMIC_MUTATION);

    const directExecute = (e) => {
        executeMutation();
    };
    const executeSectionQuery = (action, dataItem) => {
        if (dataItem?.params) {
            dispatch(
                changeGlobalAction({
                    params: dataItem?.params ? dataItem.params : null,
                })
            );
        }
        dispatch(
            setExecuteQuery({
                action: action,
                params: { id: id },
            })
        );
    };

    const directLink = (link) => {
        history(link);
    };
    //Listeners
    useEffect(() => {
        if (isComponentVisible) {
            setActive("t-action-active");
        } else {
            setActive("");
        }
    }, [isComponentVisible]);

    useEffect(() => {
        if (isComponentVisible && results) {
            const currentResult = results[resultsIndex];
            if (prevResult.uniqueRowValue !== currentResult.uniqueRowValue) {
                setIsComponentVisible(false);
                setPrevResult(currentResult);
            }
        }
        // eslint-disable-next-line
    }, [results]);

    useEffect(() => {
        if (data?.updateCommonZone?.error && data.updateCommonZone.error === "") {
            dispatch(setRefreshData(true));
            toast.success(langStrings["operation-successful"]);
            dispatch(setModalContent(false));
        } else if (data?.updateCommonZone?.error && data.updateCommonZone.error !== "") {
            toast.error(langStrings["mutation-error"]);
        }
        if (data?.updateCommonZone?.ok) {
            dispatch(setRefreshData(true));
            toast.success(langStrings["operation-successful"]);
        }
        // eslint-disable-next-line
    }, [data]);

    const element = document.getElementById("sectionContent");
    useEffect(() => {
        if (isComponentVisible && element) {
            setOpenUpwards(element.scrollHeight - element.clientHeight > 30);
        }
        return () => {
            setOpenUpwards(false);
        };
    }, [isComponentVisible, element]);

    return (
        <div ref={ref} className={`t-actions-icon relative overflow-visible ${adjust}`}>
            <div
                id={`actions-button${rowIndex >= 0 ? `-r${rowIndex}` : `-p${resultsIndex}`}${
                    depth >= 0 ? `-d${depth}` : ""
                }`}
                onClick={() => setIsComponentVisible(!isComponentVisible ? true : false)}
            >
                <UseIcon
                    id={`actions-button${rowIndex >= 0 ? `-r${rowIndex}` : `-p${resultsIndex}`}${
                        depth >= 0 ? `-d${depth}` : ""
                    }-icon`}
                    icon={icon}
                    adjust={`text-lg z-100 ${isHeader} ${active} ${
                        rowActions.length > 0 ? "cursor-pointer" : "opacity-25"
                    }`}
                />
            </div>
            {isComponentVisible && rowActions.length > 0 ? (
                <div
                    id="actions-container"
                    className="t-actions-container"
                    style={{ top: openUpwards ? "-200px" : "", componentStyle }}
                    onClick={() => setIsComponentVisible(false)}
                >
                    <div className="t-actions-background">
                        <div id="action" className="">
                            {rowActions.length > 0 ? (
                                rowActions.map((action, index) => {
                                    if (action) {
                                        return (
                                            <>
                                                <div
                                                    id={`opt-row-actions-${
                                                        action.action ?? action.name ?? "r" + index
                                                    }`}
                                                    key={index}
                                                    className={`t-action-container first-capital ${
                                                        action?.disable > 0 ? "disabled" : ""
                                                    }`}
                                                    data-action_name={action.action ? action.action : action.name}
                                                    data-id={id}
                                                    data-ref={dataItem.ref}
                                                    data-name={
                                                        dataItem.dataType === "chromecast"
                                                            ? dataItem.name
                                                            : dataItem.info[0].value
                                                    }
                                                    data-email={dataItem.info[1].value}
                                                    data-roles={
                                                        dataItem.info.length > 2 ? dataItem.info[2].value : null
                                                    }
                                                    data-description={
                                                        dataItem.extendedInfo ? dataItem.extendedInfo[0].value : null
                                                    }
                                                    data-mac={dataItem.dataType === "chromecast" ? dataItem.mac : ""}
                                                    data-roomid={
                                                        dataItem.dataType === "chromecast" ? dataItem.roomID : ""
                                                    }
                                                    data-modal_title={action.modal_title}
                                                    data-tip={action.tooltip ? t(action.tooltip) : ""}
                                                    onClick={(e) =>
                                                        !action?.disable
                                                            ? action.link
                                                                ? directLink(action.link)
                                                                : !action.noModal
                                                                ? showModal(e, action)
                                                                : action.directAction
                                                                ? directExecute(e)
                                                                : action.executeSectionQuery
                                                                ? executeSectionQuery(action.executeSectionQuery)
                                                                : showEditRowForm(e)
                                                            : null
                                                    }
                                                >
                                                    {Parser(t(action.name))}
                                                </div>
                                                {action.tooltip ? (
                                                    <ReactTooltip
                                                        place="bottom"
                                                        type="light"
                                                        offset={{ top: -8, left: -8 }}
                                                        html={true}
                                                        border={true}
                                                        borderColor="#D3DAE1"
                                                    />
                                                ) : null}
                                            </>
                                        );
                                    }
                                })
                            ) : (
                                <span className="t-noaction-container">{t("no-actions")}</span>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default UseRowActions;
