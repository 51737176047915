import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
//Components
import { Link } from "react-router-dom";
//Actions
import { closeModal, setModalContent } from "../actions/uiActions";

const useButton = ({
    buttonName,
    buttonColor,
    buttonNoTranslate,
    link,
    icon,
    iconPos,
    dropdown,
    action,
    adjust,
    disabled,
    customClass,
    noPaddingDefault,
    id,
}) => {
    //Store data
    const { t } = useTranslation();

    //States
    const [fullBtnName, setFullBtnName] = useState("");
    const [border, setBorder] = useState(buttonColor === "btn-white" ? true : false);

    //Actions
    const dispatch = useDispatch();

    const clickHandler = () => {
        dispatch(closeModal());
        dispatch(setModalContent(false));
    };

    //Listeners
    useEffect(() => {
        if (buttonNoTranslate) {
            setFullBtnName(buttonName);
        } else if (buttonName) {
            setFullBtnName(getFullBtnName(buttonName, t));
        }
        // eslint-disable-next-line
    }, [buttonName, buttonNoTranslate]);

    useEffect(() => {
        setBorder(buttonColor === "btn-white" ? true : false);
    }, [buttonColor]);

    let defaultClass = `${adjust} ${buttonColor} py-1 rounded first-capital`;
    if (disabled) {
        defaultClass += " cursor-pointer";
    }
    if (border) {
        defaultClass += " btn-blue-outline";
    }
    if (dropdown) {
        defaultClass += " justify-between";
    }

    return (
        <>
            {link ? (
                <Link to={link} id={id ?? null} onClick={clickHandler}>
                    <div id={id || `btn-${buttonName}`} className={`${buttonColor} rounded cursor-pointer ${adjust}`}>
                        {icon ? <div className={`icon-${icon}`}></div> : null}
                        {Array.isArray(buttonName) ? (
                            buttonName.map((term) => `${t(term)} `)
                        ) : (
                            <div className="first-capital">{t(buttonName)}</div>
                        )}
                        {icon ? <div className={`icon-${icon}`}></div> : null}
                    </div>
                </Link>
            ) : null}
            {action ? (
                <>
                    {action === "send-mail-reminder" ? (
                        <a
                            href="mailto:users@zafiro.cloud?subject=ZAFIRO%20Manager%20Password%20reminder"
                            className={`${buttonColor} ${border ? "btn-blue-outline " : ""}  ${
                                !noPaddingDefault ? "p-4" : ""
                            } rounded cursor-pointer`}
                        >
                            {icon && (!iconPos || iconPos === "LEFT") ? (
                                <div className={`pr-2 icon-${icon}`}></div>
                            ) : null}
                            <div className="first-capital">{fullBtnName}</div>
                            {icon && iconPos === "RIGTH" ? <div className={`pl-2 icon-${icon}`}></div> : null}
                        </a>
                    ) : (
                        <button
                            id={id ?? null}
                            onClick={(e) => {
                                if (!disabled && typeof action === "function") {
                                    action(e);
                                }
                            }}
                            className={`${buttonColor} ${!noPaddingDefault ? "p-4" : ""} rounded  ${
                                disabled
                                    ? "cursor-default btn-disabled-outline hover:bg-transparent text-gray-400   hover:text-gray-400 "
                                    : ("cursor-pointer", border ? "btn-blue-outline  " : "")
                            } ${customClass ? customClass : ""} ${adjust}`}
                        >
                            {icon && (!iconPos || iconPos === "LEFT") ? (
                                <div className={`pr-2 icon-${icon}`}></div>
                            ) : null}
                            {fullBtnName && <div className="first-capital">{fullBtnName}</div>}
                            {icon && iconPos === "RIGTH" ? <div className={`pl-2 icon-${icon}`}></div> : null}
                        </button>
                    )}
                </>
            ) : null}
            {!link && !action ? (
                <div id={id || null} className={defaultClass}>
                    <div className="flex items-center">
                        {icon ? <div className={`pr-2 icon-${icon}`}></div> : null}
                        <div className="first-capital">{fullBtnName}</div>
                    </div>
                    {dropdown ? (
                        <div
                            className={`icon-chevron h-full pl-3 pt-2 text-gray-700 border-l ml-2 border-gray-400`}
                        ></div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};

const getFullBtnName = (buttonName, t) => {
    let res = ``;
    if (Array.isArray(buttonName)) {
        // eslint-disable-next-line
        buttonName.forEach((term, index) => {
            if (index !== 0) {
                res += ` `;
            }
            res += t(term);
        });
    } else {
        res += t(buttonName);
    }
    return res;
};

export default useButton;
