const DeletePasscode = (id, code) => {
    return [
        {
            title: ["delete", code],
            text: "delete-passcode",
            id: "passcode-" + id,
            executeSectionQuery: true,

            inputs: [
                {
                    name: "passcode-id",
                    text: "",
                    type: "text",
                    value: id ? id : "",
                    style: "hidden",
                },
            ],
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete",
                    style: "red",
                    action: "delete-passcode",
                },
            ],
        },
    ];
};

export default DeletePasscode;
