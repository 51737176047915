import DeletePasscode from "../modals/DeletePasscode";
const Passcode = (passcode, accessProfiles, t, capitalizeFirst, sectionName) => {
    const { ID: id, code, accessprofileID, maxusers, usersnumber, renovation, duration, init } = passcode;

    const pActive = sectionName === "list-passcodes-active";

    const expirationTime = passcode && passcode.expirationDate ? new window.ZDate(passcode.expirationDate) : null;
    const parseExpirationTime = expirationTime
        ? expirationTime.toLocaleTimeString("es-ES", {
              hour: "2-digit",
              minute: "2-digit",
              year: "numeric",
              month: "numeric",
              day: "numeric",
          })
        : "";

    const secondsToDate = (s, format = false) => {
        let text = "";
        const seconds = Math.round(s % 0x3c);
        const hours = Math.floor(s / 0xe10);
        const minutes = Math.floor(s / 0x3c) % 0x3c;
        const days = Math.floor(s / 86400);
        if (s < 60) {
            text = `${seconds} ${format ? capitalizeFirst(t("seconds")) : "s"}`;
        } else if (s >= 60 && s < 3600) {
            text = `${minutes} ${format ? capitalizeFirst(t("minutes")) : "m"}`;
        } else if (s >= 3600 && s < 86400) {
            if (minutes === 0) {
                text = `${hours} ${format ? capitalizeFirst(t(hours === 1 ? "hour" : "hours")) : "h"}`;
            } else {
                text = `${hours} ${format ? capitalizeFirst(t(hours === 1 ? "hour" : "hours")) : "h"} ${minutes} ${
                    format ? capitalizeFirst(t("minutes")) : "m"
                }`;
            }
        } else if (s >= 86400) {
            const hoursDays = Math.floor((s % 86400) / 0xe10);
            if (hoursDays === 0) {
                text = `${days} ${format ? capitalizeFirst(t(days === 1 ? "day" : "days")) : "d"}`;
            } else {
                text = `${days} ${format ? capitalizeFirst(t(days === 1 ? "day" : "days")) : "d"}  ${hoursDays} ${
                    format ? capitalizeFirst(t(hoursDays === 1 ? "hour" : "hours")) : "h"
                }`;
            }
        }
        return text;
    };

    const getInfoAP = (id, type) => {
        if (
            (accessProfiles[id] && accessProfiles[id][type]) ||
            (accessProfiles[id] && accessProfiles[id][type] === 0)
        ) {
            if (type === "ubwmin" || type === "ubwmax" || type === "dbwmin" || type === "dbwmax") {
                if (String(accessProfiles[id][type]) === "0") {
                    return "-";
                }
            }

            return accessProfiles[id][type];
        } else {
            return null;
        }
    };

    const tooltipInfoAP = () => {
        return `
        <div class=" flex flex-col text-gray-900 items-start "> 
            <div class="font-bold mb-2" >${capitalizeFirst(t("min-bandwidth"))}</div> 
            <div class=" mb-2 flex items-center justify-center w-full">
            <span class=" icon icon-maximum text-3xl" ></span> 
            ${getInfoAP(accessprofileID, "ubwmin")} 
            Mb/s
            </div>
            <div class=" mb-2 flex items-center justify-center w-full">
            <span class=" icon icon-minimum text-3xl" ></span> 
            ${getInfoAP(accessprofileID, "dbwmin")} 
            Mb/s
            </div>
            <div class="font-bold mb-2" >${capitalizeFirst(t("max-bandwidth"))}</div> 
            <div class=" mb-2 flex items-center justify-center w-full">
            <span class=" icon icon-maximum text-3xl" ></span> 
            ${getInfoAP(accessprofileID, "ubwmax")} 
            Mb/s
            </div>
            <div class=" mb-2 flex items-center justify-center w-full">
            <span class=" icon icon-minimum text-3xl" ></span> 
            ${getInfoAP(accessprofileID, "dbwmax")} 
            Mb/s
            </div>
        </div>
        `;
    };

    return {
        dataType: "passcode",
        id: id,
        adjust: "h-auto",
        executeSectionQuery: true,
        info: [
            {
                cellContent: "text",
                value: code ? code : "",
                specialWidth: pActive ? "w-2/12" : "w-3/12",
                iconAdjust: "text-lg",
                tooltip: "",
                wrap: "truncate",
            },
            {
                cellContent: "labelsCustomTooltip",
                value: getInfoAP(accessprofileID, "name")
                    ? [
                          [
                              0,
                              `${getInfoAP(accessprofileID, "name")}`,
                              "#0F63BD",
                              {
                                  id: getInfoAP(accessprofileID, "name") + accessprofileID + id,
                                  content: tooltipInfoAP(),
                              },
                          ],
                      ]
                    : [],
                specialWidth: "w-3/12",
                cellAdjust: "labels",
            },
            {
                cellContent: "text",
                value: maxusers ? maxusers : capitalizeFirst(t("unlimited")),
                subvalue: usersnumber
                    ? capitalizeFirst(
                          t("used-n-times", {
                              usersnumber,
                          })
                      )
                    : null,
                specialWidth: pActive ? "w-2/12 pl-3" : "w-3/12 pl-1",
                iconAdjust: "text-lg",
                tooltip: "",
                wrap: "truncate",
            },
            {
                cellContent: "textWithTooltip",
                value: duration ? secondsToDate(duration) : capitalizeFirst(t("unlimited")),
                tooltipData: capitalizeFirst(t("connection-time")) + " " + secondsToDate(renovation, true),
                specialWidth: pActive ? "w-2/12 pl-3" : "w-2/12 pl-1",
                adjust: "flex items-center",
                icon: duration
                    ? {}
                    : { name: "info", adjust: "pl-1 text-2xl text-gray-700", tooltip: "enter-name-and-translations" },
                wrap: "truncate",
            },
            {
                cellContent: "textWithTooltip",
                value: init ? capitalizeFirst(t("started")) : capitalizeFirst(t("not-started")),
                tooltipData: capitalizeFirst(t("expiration")) + ": " + parseExpirationTime,
                specialWidth: pActive ? "w-2/12" : "hidden",
                adjust: `flex items-center ${!init ? "text-gray-600" : ""}`,
                icon:
                    init && duration !== 0
                        ? {
                              name: "info",
                              adjust: "pl-1 text-2xl text-gray-700",
                              tooltip: "enter-name-and-translations",
                          }
                        : {},
                wrap: "truncate",
            },
            {
                // name: "preview",
                iconTooltip: "preview",
                iconAdjust: "text-2xl  text-gray-800",
                value: "preview",
                translate: true,
                link: `/customers/passcode-configuration/preview/${id}/`,
                target: "_blank",
                icon: "externalink-",
                external: true,
                cellContent: "link",
                specialWidth: pActive ? "w-0.5/12 " : "hidden",
            },
            {
                name: "passcode",
                cellContent: "icon",
                isAction: true,
                modal: DeletePasscode(id, code),
                icon: {
                    name: "delete",
                },
                specialWidth: pActive ? "w-0.5/12" : "w-1/12 justify-end ",
                iconAdjust: "text-gray-800",
            },
        ],
    };
};

export default Passcode;
