import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeGlobalAction } from "../../actions/globalActions";
import Parser from "hooks/Utils/Parser";
import { multipleTranslate } from "../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import ReactTooltip from "components/ReactTooltip";
import { Session } from "../../hooks/Utils/Session";

const UsePlainText = ({ index }) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    //Store data
    const { langStrings, lang } = useSelector((state) => state.ui);
    const { ref: projectRef } = useSelector((state) => state.hotel);
    const {
        text,
        isLink,
        action,
        link,
        linkText,
        adjust,
        value,
        icon,
        adjustIcon,
        iconTooltip,
        avoidGetFullText = false,
    } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { czRef: zoneRef, title: modalTitle } = useSelector((state) => state.ui.modalContent);
    const actionData = useSelector((state) => state.action);

    //States
    const [fullText, setFullText] = useState(false);

    const monitorURL = Session.getStaffAppUrl(`#/zonecard?zone=${zoneRef}&project=${projectRef}&lang=${lang}`);
    //Listeners
    useEffect(() => {
        updateLinkMonitor();
        if (langStrings && !avoidGetFullText) {
            setFullText(getFullText(text, t, value, action));
        } else {
            setFullText([text]);
        }
        // eslint-disable-next-line
    }, [langStrings]);

    useEffect(() => {
        updateLinkMonitor();
        // eslint-disable-next-line
    }, [monitorURL]);

    const updateLinkMonitor = () => {
        if (modalTitle === "area-see-link") {
            setFullText([monitorURL]);
            dispatch(
                changeGlobalAction({
                    ...actionData,
                    values: {
                        link: monitorURL,
                    },
                })
            );
        }
    };

    return (
        <>
            {isLink && action && action === "send-mail-reminder" ? (
                <a
                    href="mailto:users@zafiro.cloud?subject=ZAFIRO%20Manager%20Password%20reminder&body="
                    className="text-blue-300 hover:text-blue-200 font-bold cursor-pointer"
                >
                    users@zafiro.cloud
                </a>
            ) : null}
            {isLink && !action ? (
                <a href={link} className="text-blue-300 hover:text-blue-200 font-bold cursor-pointer">
                    {linkText}
                </a>
            ) : null}
            {!isLink && fullText ? (
                <>
                    {fullText.map((ftext, index) => (
                        <div className={`block ${adjust}`} key={ftext}>
                            <p className=" block">{Parser(ftext)}</p>
                            {icon ? (
                                <>
                                    <i
                                        data-tip={iconTooltip ? t(iconTooltip) : ""}
                                        className={` icon icon-${icon} ${adjustIcon}`}
                                    ></i>
                                    <ReactTooltip
                                        place="bottom"
                                        type="light"
                                        offset={{ top: -8, left: -8 }}
                                        html={true}
                                        border={true}
                                        borderColor="#D3DAE1"
                                    />
                                </>
                            ) : null}
                        </div>
                    ))}
                </>
            ) : null}
        </>
    );
};

const getFullText = (text, langStrings, value, action) => {
    if (action === "replace") {
        if (Array.isArray(value)) {
            let res = langStrings[text] ? [langStrings[text]] : [text];
            // eslint-disable-next-line
            value.forEach((word, index) => {
                res = [res[0].replace(`%${index}`, word)];
            });
            return res;
        }
        return langStrings(text) ? [langStrings(text).replace("%0", value)] : [text];
    } else if (action === "tVariables") {
        return [langStrings(text, value)];
    }
    return [multipleTranslate(text, langStrings)];
};

export default UsePlainText;
