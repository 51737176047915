import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Session } from "../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import { addNotification, openModal, setModalContent, setWarnings } from "../../actions/uiActions";
import { toast } from "react-toastify";

import AWS from "aws-sdk/global";
import AWSMqttClient from "aws-mqtt";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useLocation } from "react-router-dom";
import { pathsWithoutChainOrProjectRef } from "hooks/Utils/Utils";

const Notifications = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();

    //States
    const [client, setClient] = useState();

    const isNonChainRefOrProjectPath = [...pathsWithoutChainOrProjectRef, "/"].includes(location.pathname);

    const GET_CREDENTIALS = gql`
        {
            mqttCredentials {
                credentials {
                    accessKeyID
                    expiration
                    secretAccessKey
                    sessionToken
                }
                region
                brokerURL
                environmentNamespace
            }
        }
    `;

    const { data } = useQuery(GET_CREDENTIALS, {
        skip: isNonChainRefOrProjectPath,
    });

    useEffect(() => {
        if (data && data.mqttCredentials && data.mqttCredentials.credentials) {
            MqttConnect(data.mqttCredentials);
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        // eslint-disable-next-line
    }, [client && client.connected]);

    //Petición para obtener credentials
    // curl --cert f00efeb5dc3309bef22b4f5f0e56b5f0ea37dea34b6b7bc63601c78fe2711647-certificate.pem.crt --key f00efeb5dc3309bef22b4f5f0e56b5f0ea37dea34b6b7bc63601c78fe2711647-private.pem.key -H "x-amzn-iot-thingname: zafiro" --cacert AmazonRootCA1.pem https://c2z82pv34z3t94.credentials.iot.eu-west-1.amazonaws.com/role-aliases/zafiro-iot/credentials

    //Petición para publicar mensajes
    //curl --tlsv1.2 --cacert AmazonRootCA1.pem --cert f00efeb5dc3309bef22b4f5f0e56b5f0ea37dea34b6b7bc63601c78fe2711647-certificate.pem.crt --key f00efeb5dc3309bef22b4f5st POST --data "hola edu" "https://a2brtuh1r6pvev-ats.iot.eu-west-1.amazonaws.com:8443/topics/zafiro?qos=1"

    //Message popup : {\"userID\" :\"all\", \"message\" : {\"title\" :\"Titulo del mensaje\",\"body\" :\"Cuerpo del mensaje\"}}
    //update complete: {\"userID\" :\"all\",\"action\" :\"updateCompleted\"}

    const MqttConnect = (data) => {
        const credentials = data.credentials;
        const topicPrefix = data.environmentNamespace ? `${data.environmentNamespace}/` : "";
        const topic = (topicName) => {
            return `${topicPrefix}${topicName}`;
        };
        const awsClient = new AWSMqttClient({
            region: data.region,
            //credentials: AWS.config.credentials,
            credentials: new AWS.Credentials({
                accessKeyId: credentials.accessKeyID,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
                expiration: credentials.expiration,
            }),
            endpoint: data.brokerURL.split(":")[0], // NOTE: get this value with `aws iot describe-endpoint`
            expires: 600, // Sign url with expiration of 600 seconds
            clientId: "mqtt-client-" + Math.floor(Math.random() * 100000 + 1), // clientId to register with MQTT broker. Need to be unique per client
            will: {
                topic: "WillMsg",
                payload: "Connection Closed abnormally..!",
                qos: 0,
                retain: false,
            },
        });

        awsClient.on("connect", () => {
            console.log("Connected to MQTT Broker");

            awsClient.subscribe(topic(`zafiro-manager`));
            console.log(`Subscribed to zafiro-manager`);

            // if (Session.getSessionProp("chainId")) {
            //     awsClient.subscribe(topic(`zafiro-chain-${Session.getSessionProp("chainId")})`);
            //     console.log(`Subcribed to zafiro-chain-${Session.getSessionProp("chainId")}`);
            // }
            // const currentHotelData = Session.getSessionProp("currentHotel");
            // if (currentHotelData) {
            //     const hotelData = JSON.parse(currentHotelData);
            //     if (hotelData && hotelData.id && hotelData.id !== "0") {
            //         awsClient.subscribe(topic(`zafiro-project-${hotelData.id})`);
            //         console.log(`Subcribed to zafiro-project-${hotelData.id}`);
            //     }
            // }
        });

        awsClient.on("message", (topic, message) => {
            const msgText = String.fromCharCode.apply(null, message);
            // console.log(topic, message);

            if (!msgText) {
                return;
            }
            let messageData = null;
            try {
                try {
                    // eslint-disable-next-line
                    messageData = JSON.parse(eval(msgText));
                } catch (error) {
                    messageData = JSON.parse(msgText);
                }
            } catch (error) {
                console.log("Message ignored. Error parsing message");
                return;
            }
            console.log(messageData);
            if (
                messageData.userID &&
                (String(messageData.userID) === Session.getSessionProp("userID") ||
                    String(messageData.userID) === "all")
            ) {
                console.log("process notification");

                if (messageData.message) {
                    showMessage(messageData.message);
                } else if (messageData.action) {
                    executeAction(messageData);
                }
            }
        });

        awsClient.on("close", () => {});
        awsClient.on("offline", () => {
            console.log("offline");
        });
        setClient(awsClient);
    };

    const showMessage = (message) => {
        if (!message) {
            return;
        }

        dispatch(
            setModalContent({
                title: message.title || null,
                bodyAdjust: "w-3/5 text-justify max-h-80 overflow-y-scroll",
                inputs: [
                    {
                        text: message.body,
                        type: "htmltext",
                    },
                ],
                buttons: [
                    {
                        name: "accept",
                        style: "blue",
                        action: "close",
                    },
                ],
            })
        );
        dispatch(openModal());
    };

    const executeAction = (messageData) => {
        switch (messageData.action) {
            case "updateCompleted":
                dispatch(setWarnings(null));
                dispatch(addNotification("updateCompleted"));
                Session.setSessionProp("userAdvicedUpdate", "false");
                toast.success(t("System update completed"));

                break;

            default:
                break;
        }
    };

    return null;
};

export default Notifications;
