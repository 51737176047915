import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData, setRefreshContentShopData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, getVendureApiData, uploadVendureMutation } from "../../Utils/Integrations/useVendure";
import { arrangeToastMessagesUploadingFiles } from "../../Utils/DesignUtils";
import UseToast from "../../../components/Notifications/useToast";
import { initialParamsMutation } from "../../Utils/SalesUtils";

const SalesBackgroundUpdate = ({ client }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { useGlobalLoading } = useSelector((state) => state.ui.modalContent);
    const { executeQuery, refreshContentShopData } = useSelector((state) => state.sectionContent);

    useEffect(() => {
        const paramsMutation = { ...initialParamsMutation };

        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            paramsMutation.execute = true;
            switch (executeQuery.action) {
                case "create-asset-image":
                    paramsMutation.mutation = `mutation Mutation($file: Upload!) {
                        createAssets(input: [{ file: $file }]) {
                          ... on Asset {
                            id
                            name
                            fileSize
                          }
                          ... on ErrorResult {
                            message
                          }
                        }
                      }`;
                    paramsMutation.aditionalHeader = { "vendure-token": executeQuery.params.token };
                    paramsMutation.mutationFile = { file: executeQuery.params.file, mutationName: "createAssets" };
                    paramsMutation.executeAnotherQuery = {
                        action: "get-image-preview",
                        params: { ...executeQuery.params },
                    };
                    paramsMutation.refreshShopData = true;
                    paramsMutation.useLoading = false;
                    paramsMutation.avoidRefresh = true;
                    break;
                case "create-asset-image-category":
                    paramsMutation.mutation = `mutation Mutation($file: Upload!) {
                        createAssets(input: [{ file: $file }]) {
                          ... on Asset {
                            id
                            name
                            fileSize
                          }
                          ... on ErrorResult {
                            message
                          }
                        }
                      }`;
                    paramsMutation.mutationFile = { file: executeQuery.params.file, mutationName: "createAssets" };
                    paramsMutation.executeAnotherQuery = {
                        action: "get-image-preview-category",
                        params: { ...executeQuery.params },
                    };
                    paramsMutation.aditionalHeader = { "vendure-token": executeQuery.params.token };
                    paramsMutation.refreshShopData = true;
                    paramsMutation.avoidRefresh = true;
                    paramsMutation.useLoading = false;
                    break;
                case "get-image-preview-category":
                    paramsMutation.mutation = `query{
                        asset(id: ${executeQuery.params.id}){
                          preview
                        }
                      }`;
                    paramsMutation.executeAnotherQuery = {
                        action: "set-image-to-category",
                        params: { ...executeQuery.params },
                    };
                    paramsMutation.useLoading = false;
                    paramsMutation.avoidToast = true;
                    paramsMutation.mutationName = "asset";
                    paramsMutation.refreshShopData = true;
                    paramsMutation.avoidRefresh = true;
                    break;
                case "get-image-preview":
                    paramsMutation.mutation = `query{
                        asset(id: ${executeQuery.params.id}){
                          preview
                        }
                      }`;
                    paramsMutation.executeAnotherQuery = {
                        action: "set-image-to-shop",
                        params: { ...executeQuery.params },
                    };
                    paramsMutation.avoidToast = true;
                    paramsMutation.mutationName = "asset";
                    paramsMutation.refreshShopData = true;
                    paramsMutation.useLoading = false;
                    paramsMutation.avoidRefresh = true;
                    break;
                case "set-image-to-shop":
                    paramsMutation.mutation = `mutation {
                        updateChannel(
                          input: { id: ${executeQuery.params.idShop}, customFields:{cover_url: "${executeQuery.params.preview}?preset=small" } }
                        ) {
                          __typename
                        }
                      }`;
                    paramsMutation.mutationName = "updateChannel";
                    paramsMutation.avoidToast = true;
                    paramsMutation.refreshShopData = true;
                    paramsMutation.useLoading = false;
                    break;
                case "set-image-to-category":
                    paramsMutation.mutation = `mutation {
                        updateCollection(
                          input: {
                            id: "${executeQuery.params.idCategory}"
                                  customFields:{
                                    image_url:"${executeQuery.params.preview}"
                            }
                          }
                        ) {
                          id
                        }
                      }
                      `;
                    paramsMutation.mutationName = "updateChannel";
                    paramsMutation.refreshShopData = true;
                    paramsMutation.useLoading = false;
                    paramsMutation.avoidToast = true;
                    break;
                default:
                    paramsMutation.mutation = null;
                    paramsMutation.execute = false;
                    break;
            }
        }

        if (paramsMutation.execute) {
            if (useGlobalLoading) dispatch(showGlobalLoading(true));
            if (paramsMutation.useLoading) dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation(paramsMutation);
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async (paramsMutation) => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }
        let errorMutation = false;
        let toastId = "";
        let files = null;
        if (paramsMutation.mutationFile) {
            files = [
                {
                    name: paramsMutation.mutationFile.file.name,
                    error: errorMutation,
                    textTooltip: paramsMutation.mutationFile.file.name,
                    status: 1,
                    ref: "",
                    size: paramsMutation.mutationFile.file.size,
                },
            ];
        }
        let response = "";
        if (!paramsMutation.mutationFile) {
            response = await executeVendureQuery(getVendureApiData(), { queryBody: paramsMutation.mutation });
            if (response && response.data && !response.data.errors) {
                if (paramsMutation.mutationName) {
                    let mutationObject = response.data[paramsMutation.mutationName];
                    if (
                        mutationObject &&
                        mutationObject.result &&
                        mutationObject.result.toUpperCase().includes("NOT")
                    ) {
                        errorMutation = true;
                    } else if (paramsMutation.executeAnotherQuery) {
                        paramsMutation.executeAnotherQuery.params = {
                            ...paramsMutation.executeAnotherQuery.params,
                            ...mutationObject,
                        };
                        dispatch(
                            setExecuteQuery({
                                action: paramsMutation.executeAnotherQuery.action,
                                params: paramsMutation.executeAnotherQuery.params,
                            })
                        );
                    }
                }
            } else {
                errorMutation = true;
            }
        } else {
            let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

            toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false,
                className: "use-toast",
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                hideProgressBar: true,
                icon: false,
            });

            response = await uploadVendureMutation(
                getVendureApiData(),
                {
                    queryBody: paramsMutation.mutation,
                    file: paramsMutation.mutationFile.file,
                },
                typeof paramsMutation.aditionalHeader === "object" ? paramsMutation.aditionalHeader : {}
            );

            if (response && response.data && !response.data.errors) {
                if (paramsMutation.mutationFile.mutationName) {
                    let mutationObject = response.data[paramsMutation.mutationFile.mutationName];
                    if (
                        mutationObject &&
                        mutationObject.result &&
                        mutationObject.result.toUpperCase().includes("NOT")
                    ) {
                        errorMutation = true;
                    } else if (
                        mutationObject instanceof Array &&
                        mutationObject.length > 0 &&
                        paramsMutation.executeAnotherQuery
                    ) {
                        paramsMutation.executeAnotherQuery.params = {
                            ...paramsMutation.executeAnotherQuery.params,
                            ...mutationObject[0],
                        };
                    }
                }
            } else {
                errorMutation = true;
            }
        }

        if (!errorMutation) {
            if (paramsMutation.mutationFile && !paramsMutation.errorMutation) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 2;
                });

                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });

                if (paramsMutation.executeAnotherQuery) {
                    dispatch(
                        setExecuteQuery({
                            action: paramsMutation.executeAnotherQuery.action,
                            params: paramsMutation.executeAnotherQuery.params,
                        })
                    );
                }
            } else if (!paramsMutation.avoidToast) {
                toast.success(t("operation-successful"));
            }
            if (paramsMutation.refreshShopData && !paramsMutation.avoidRefresh) {
                dispatch(setRefreshContentShopData({ ...refreshContentShopData, state: true }));
                setTimeout(() => {
                    dispatch(setRefreshContentShopData(false));
                }, 1000);
                dispatch(setRefreshData(true));
            } else if (!paramsMutation.avoidRefresh) {
                dispatch(setRefreshContentData(true));
                dispatch(setRefreshData(true));
            }
            dispatch(setExecuteQuery(null));
            dispatch(cleanAction());
            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
        } else {
            dispatch(setExecuteQuery(null));
            if (useGlobalLoading) dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            if (paramsMutation.mutationFile) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 4;
                    file["errorMessage"] = "";
                });
                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });
            } else {
                toast.error(t("mutation-error"));
            }
        }
    };

    return null;
};

export default withApollo(SalesBackgroundUpdate);
