const AddCompanion = ({ isEdit, data }) => {
    return {
        title: "add-companion",
        bodyAdjust: "w-3/12",
        executeSectionQuery: true,
        inputs: [
            {
                type: "editCompanion",
                data: data,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "add-companion",
            },
        ],
    };
};

export default AddCompanion;
