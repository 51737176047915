import React from "react";
import { useSelector } from "react-redux";
import { parseTranslation, getProjectLangs } from "../../hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import { Session } from "../../hooks/Utils/Session";

const UseModalTaxInUse = ({ index }) => {
    const { data: modalData } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { projectLangs } = useSelector((state) => state.ui);
    const { t } = useTranslation();

    const projects = JSON.parse(Session.getSessionProp("projects")) || [];
    const idTax = modalData?.taxZone?.id ? Number(modalData.taxZone.id) : null;
    const shopsRelatedToTax =
        idTax && modalData?.shopsData?.length > 0
            ? modalData.shopsData.filter((s) => Number(s.defaultTaxZone.id) === idTax)
            : [];

    const shopsToRender = shopsRelatedToTax.map((s) => {
        const projectMatch = projects.find((p) => p.ref === s.projectRef);
        const projectName = projectMatch ? projectMatch.name : "";
        return {
            name: parseTranslation(s.name, getProjectLangs(projectLangs, t).langDefault),
            projectName: projectName,
        };
    });

    return (
        <div>
            <div className=" w-full flex justify-between mb-2">
                <span className=" font-bold">{t("shops-word")}</span>
                <span>{`${shopsToRender.length} ${t("shops-word")}`}</span>
            </div>
            <div
                style={{ minHeight: "21.45rem", maxHeight: "21.45rem", overflowY: "scroll" }}
                className=" border rounded border-gray-200"
            >
                {shopsToRender.map((s) => {
                    return (
                        <div key={s.name} className=" flex border-b mx-4 border-gray-200 py-3 ">
                            <span className=" font-bold block w-1/2">{s.name}</span>
                            <span className=" block w-1/2">{s.projectName}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default UseModalTaxInUse;
