import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import EditEmailOrders from "hooks/GraphqlCalls/Sales/modals/EditEmailsOrders";
import { setModalContent, openModal } from "actions/uiActions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { ShopContext } from "contexts/Sales/Shop";
import SettingsCard from "components/SettingsCard";

const ShopSettingsEmail = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const { data: dataContext } = useContext(ShopContext);
    const [numbersEmails, setNumbersEmails] = useState(0);

    useEffect(() => {
        if (dataContext?.destinationEmails && dataContext?.destinationUsers) {
            setNumbersEmails(dataContext?.destinationEmails.length + dataContext?.destinationUsers.length);
        }
    }, [dataContext]);

    const handleModalShopSettings = () => {
        const modalContent = EditEmailOrders({ id }, t);

        dispatch(setModalContent(modalContent));
        dispatch(openModal());
    };

    return (
        <SettingsCard
            title={t("email-to-receive-orders")}
            warning={numbersEmails < 1 ? t("no-email-added-yet") : null}
            edit={{
                id: "shop-settings-email-button-modal",
                onClick: handleModalShopSettings,
            }}
            description={t("select-users-receive-an-email-order-received")}
        >
            <span id={"shop-settings-number-emails"} className=" text-base">{`${numbersEmails} emails`}</span>
        </SettingsCard>
    );
};

export default ShopSettingsEmail;
