const NewShop = (data) => {
    return {
        title: "add-shop",
        bodyAdjust: "w-3/12",
        executeSectionQuery: true,
        inputs: [
            {
                type: "newShop",
                data: data ? data : null,
            },
            {
                type: "text",
                name: "id-shop",
                cStyle: "hidden",
                style: "hidden",
                value: data && data.id ? data.id : null,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "create-shop",
            },
        ],
    };
};

export default NewShop;
