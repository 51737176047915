import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setAvailableFilters,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setActions,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCustomersTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import GetCountriesList from "../../Utils/CountriesUtils";
//Model
import Customer from "./models/Customer";
import { useTranslation } from "react-i18next";
import { useMSQuery } from "../useMSQuery";
import { POLLINTERVAL_15S } from "../../Utils/Utils";

const ListCustomers = (advancedFilters) => {
    const { t } = useTranslation();
    //Store data
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, searchString } = useSelector(
        (state) => state.table
    );

    //Data
    const sectionName = "list-customers";

    //States
    const [sorting] = useState(`,orderBy:{field: "id", criteria: "desc"}`);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_CUSTOMER_INFO = gql`{
        customers: customers(page: ${currentPage}, size: ${perPage}${
        sortCriteria ? sortCriteria : sorting
    }${filterQuery}){
        info { count }
        results { id ref name surname email signupApp signupWifi gender birthDate phone country language lastAccess termsAccepted marketingAccepted emailVerified logins{app date wifi}}}
        mobileApps: mobileApps{
        results { name shortName url }}
        countries: countries(page: 1, size: 1000, byCustomer: true){
        results { information{ name } ref}}
        properties: properties{
        results{ ref id name }
  }
        
    }`;
    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_CUSTOMER_INFO, {
        pollInterval: POLLINTERVAL_15S,
    });

    //Listeners
    useEffect(() => {
        dispatch(cleanTableStore());
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);

    useEffect(() => {
        if (!loading && !error && data && data.customers && networkStatus === 7) {
            //Get data
            const dataItems = arrangeData(data);
            const tableHeaders = GetTableHeaders(sectionName, { advancedFilters: advancedFilters });
            const availableFilters = GetAvailableFilters(sectionName, arrangeFilters(data), advancedFilters);
            //Add to store
            dispatch(setLoading(true));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.customers.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setExtendedInfo(true));
            dispatch(showRowForm(false));
            dispatch(setActions());
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(
                setCustomError(
                    searchString !== null
                        ? t("no-results-found")
                        : `${t("theres-not")} ${t("customers")} ${t("available")}`
                )
            );
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus, advancedFilters]);

    useEffect(() => {
        if (advancedFilters && filterQuery !== "") {
            dispatch(cleanTableStore());
            dispatch(cleanActiveFilters());
            dispatch(setRefreshData(true));
        }
        // eslint-disable-next-line
    }, [advancedFilters]);

    const arrangeData = (data) => {
        const customersData = [];
        const mobileApps = data.mobileApps ? data.mobileApps.results : [];
        const properties = data.properties ? data.properties.results : [];
        // eslint-disable-next-line
        data.customers.results.map((user) => {
            customersData.push(Customer(user, mobileApps, properties, advancedFilters, t));
        });
        return customersData;
    };

    const arrangeFilters = (data) => {
        let response = {
            signUps: [],
            access: [],
            countries: [],
        };
        if (data.mobileApps && data.mobileApps.results) {
            // eslint-disable-next-line
            data.mobileApps.results.map((mobileApp) => {
                if (response.signUps.filter((signUpFilter) => signUpFilter.id === mobileApp.url).length < 1) {
                    response.signUps.push({
                        id: `"${mobileApp.url}"`,
                        value: mobileApp.name,
                        visible: true,
                    });
                }
                if (response.access.filter((accessFilter) => accessFilter.id === mobileApp.url).length < 1) {
                    response.access.push({
                        id: `"${mobileApp.url}"`,
                        value: mobileApp.name,
                        visible: true,
                    });
                }
            });
        }
        if (data.properties && data.properties.results) {
            // eslint-disable-next-line
            data.properties.results.map((property) => {
                if (response.signUps.filter((signUpFilter) => signUpFilter.id === property.ref).length < 1) {
                    response.signUps.push({
                        id: `"${property.ref}"`,
                        value: property.name,
                        visible: true,
                    });
                }
                if (response.access.filter((accessFilter) => accessFilter.id === property.ref).length < 1) {
                    response.access.push({
                        id: `"${property.ref}"`,
                        value: property.name,
                        visible: true,
                    });
                }
            });
        }
        if (data.countries) {
            response.countries = GetCountriesList(data, t);
        }
        return response;
    };
};

export default ListCustomers;
