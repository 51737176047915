import { capitalizeFirst } from "../../../Utils/Utils";

const UpdateOrder = (props = {}) => {
    const {orderData = {}, actionData = {}, t} = props;
    const {id, shopName} = orderData;
    const name = `${shopName} ${id}`;
    return {
        title: `${name} - ${capitalizeFirst(t(actionData?.title))}`,
        noTranslateTitle:true,
        text: t(actionData?.action?.description,{ order: id, shop: shopName }),
        noTranslateText:true,
        id: id,
        executeSectionQuery: true,
        useGlobalLoading: true,
        inputs: [],
        buttons: [
            { name: "close", style: "white", action: "close" },
            {
                name: actionData?.action?.buttonName,
                style: actionData?.action?.buttonStyle,
                action: actionData?.action?.name,
            },
        ],
    };
};
export default UpdateOrder;