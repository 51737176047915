import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import SalesProvider from "contexts/Sales";

import GuestOrders from "./GuesOrders.js/GuestOrders";

import GuestData from "./GuestData";
import StayInformation from "./StayInformation";
import Comments from "./Comments";
import GuestCompanions from "./GuestCompanions";
import GuestBookings from "./GuestBookings";

import GuestDigitalKey from "./GuestDigitalKey";
import { Session } from "../../../../hooks/Utils/Session";
import UseTabs from "../../../useTabs";
import GuestAlarms from "./GuestAlarms";
import { useTranslation } from "react-i18next";
import { openModal, setModalContent } from "actions/uiActions";
import AddCompanion from "hooks/GraphqlCalls/Customers/modals/AddCompanion";

const GuestInfo = () => {
    const { t } = useTranslation();
    //Store
    const { data } = useSelector((state) => state.sectionContent);
    const { langStrings, permissions } = useSelector((state) => state.ui);

    const hasBookings = permissions && permissions.goOutSafe && permissions.goOutSafe.bookings;
    const hasDigitalKeys = permissions && permissions.guests && permissions.guests.digitalKey;
    const hasAlarmsPermission = permissions && permissions.guests && permissions.guests.alarms;
    const hasProductSales = permissions && permissions.services && permissions.services.productSALES;

    //Session data
    const currentHotelData = Session.getSessionProp("currentHotel");
    const currentHotel = currentHotelData ? JSON.parse(currentHotelData) : {};

    //Actions
    const dispatch = useDispatch();

    //Data
    let tableOptions = [{ name: "companions", value: "companions" }];

    if (hasBookings) {
        tableOptions.push({ name: "bookings", value: "bookings" });
    }

    if (hasDigitalKeys) {
        tableOptions.push({ name: "digital-key", value: "numberOfKeys" });
    }

    if (hasProductSales) {
        tableOptions.push({ name: "orders", value: "orders" });
    }

    //State
    const [guestInfo, setGuestInfo] = useState();
    const [tableMode, setTableMode] = useState("companions");

    //Listeners
    useEffect(() => {
        if (data && data.guestID) {
            setGuestInfo(() => {
                const companions = data.companions.filter((val) => val.loyaltyRef);
                if (data?.guestLoyaltyRef) {
                    companions.push({ loyaltyRef: data?.guestLoyaltyRef });
                }
                return { ...data, numberOfKeys: companions };
            });
        }
    }, [data]);

    //Functions
    const addCompanion = () => {
        dispatch(setModalContent(AddCompanion({ isEdit: false, data: { rooms: guestInfo?.rooms || [] } })));
        dispatch(openModal());
    };

    return (
        <>
            {guestInfo ? (
                <div className="grid grid-cols-3 gap-8 mb-12">
                    <div className="bg-white">
                        <GuestData guestInfo={guestInfo}></GuestData>
                    </div>
                    <div className="bg-white">
                        <StayInformation guestInfo={guestInfo}></StayInformation>
                    </div>
                    <div className="bg-white px-8 py-5">
                        {hasAlarmsPermission ? (
                            <UseTabs
                                color={`blue-600`}
                                titles={[
                                    { name: t("comments"), id: "comments-tab" },
                                    { name: t("alarms"), id: "alarms-tab" },
                                ]}
                                contents={[<Comments guestInfo={guestInfo} />, <GuestAlarms />]}
                                config={{ even: true, pX: "" }}
                            />
                        ) : (
                            <UseTabs
                                color={`blue-600`}
                                titles={[{ name: t("comments"), id: "comments-tab" }]}
                                contents={[<Comments guestInfo={guestInfo} />]}
                                config={{ even: true, pX: "" }}
                            />
                        )}
                    </div>
                    <div className="bg-white col-span-3 min-h-80 p-6">
                        <div className="h-12">
                            {tableOptions.map((option) => (
                                <div
                                    id={`table-options-${option.value}`}
                                    key={option.value}
                                    onClick={(e) => setTableMode(option.name)}
                                    className={`first-capital px-4 pb-3 min-w-40 w-auto float-left border-b font-bold cursor-pointer ${
                                        tableMode === option.value
                                            ? " border-zafiro-700 text-zafiro-700"
                                            : "border-gray-300"
                                    } text-center`}
                                >{`${langStrings[option.name]} (${
                                    guestInfo[option.value] ? guestInfo[option.value].length : 0
                                })`}</div>
                            ))}
                        </div>
                        <div>
                            {tableMode === "companions" ? (
                                <>
                                    <GuestCompanions />
                                    {!currentHotel.blockGuestManagement ? (
                                        <div
                                            id="companions-add-companion-button"
                                            onClick={(e) => addCompanion()}
                                            className="font-bold text-zafiro-400 text-lg pl-8 cursor-pointer"
                                        >
                                            <span className="icon icon-add pr-2"></span>{" "}
                                            <span className="first-capital inline-block">
                                                {langStrings["add-companion"]}
                                            </span>
                                        </div>
                                    ) : null}
                                </>
                            ) : null}
                            {tableMode === "bookings" ? (
                                <>
                                    <Link to={"/common-zones/booking/categories"}>
                                        <div className="text-right -mt-6 relative float-right font-bold text-zafiro-400 text-lg pl-8 cursor-pointer">
                                            <span className="first-capital inline-block">
                                                {langStrings["view-all-guest-bookings"]}
                                            </span>
                                        </div>
                                    </Link>
                                    <GuestBookings data={guestInfo.bookings}></GuestBookings>
                                </>
                            ) : null}
                            {tableMode === "orders" ? (
                                <SalesProvider>
                                    <GuestOrders />
                                </SalesProvider>
                            ) : null}
                            {tableMode === "digital-key" ? <GuestDigitalKey /> : null}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default GuestInfo;
