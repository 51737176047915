import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import { setSectionContent } from "../../../actions/sectionActions";
import { setActionData } from "../../../actions/globalActions";
import {
    setCount,
    setCountPosition,
    setExtendedInfo,
    setHeaderButtons,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    setFormNewContent,
    setCustomError,
    cleanActiveFilters,
    setError,
    setRefreshData,
    setTableType,
    setCustomConfig,
    setAvailableFilters,
    cleanTableStore,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetSettingsTableHeaders";
import GetTableForm from "../../Utils/Table/GetTableForm";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { Session } from "../../Utils/Session";
//Model
import Language from "./models/Language";
import { useTranslation } from "react-i18next";
import { useMSQuery } from "../useMSQuery";
import { setProjectLangs } from "actions/uiActions";

import _ from "lodash";

const ListLanguages = () => {
    const { t } = useTranslation();
    //Store  data
    const { currentPage, perPage, searchString, filterQuery, refreshData } = useSelector((state) => state.table);
    const cookieCHotel = Session.getSessionProp("currentHotel");
    const HOTEL_ID = cookieCHotel ? JSON.parse(cookieCHotel).id : "";
    const { ref } = useSelector((state) => state.hotel);
    const isCorporate = ref === "CORPORATE";

    const reg = /,?search\s?:\s?"[^"]+"/g;
    const filteredFilterQuery = filterQuery.replaceAll(reg, "").replace(",filter:{}", "");

    //Data
    const itemType = "list-languages";

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_LANGUAGES = gql`
    {
        projectLangs: projectLangs(page:1,size:200 ${
            filteredFilterQuery ? filteredFilterQuery : HOTEL_ID ? `,filter: {projectID: ${HOTEL_ID}}` : ""
        }){
            results{
                chainId,
                isDefault,
                languageRef,
                projectId,
                projectRef
            }
        }
        allProjectLangs: projectLangs{
            results{
                chainId,
                isDefault,
                languageRef,
                projectId,
                projectRef
            }
        }
        languages: languages(byCustomer:false){
            results{
                ref
            }
        }
    }`;
    const [executeQuery, { data, loading, error, refetch }] = useMSQuery(GET_LANGUAGES);
    //Listeners
    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    const normalize = (s) => {
        let r = (s + "").toLowerCase();
        const non_asciis = {
            a: "[àáâãäå]",
            ae: "æ",
            c: "ç",
            e: "[èéêë]",
            i: "[ìíîï]",
            n: "ñ",
            o: "[òóôõö]",
            oe: "œ",
            u: "[ùúûűü]",
            y: "[ýÿ]",
        };
        for (const i in non_asciis) {
            r = r.replace(new RegExp(non_asciis[i], "g"), i);
        }
        return r;
    };

    const storeDefaultLang = (data) => {
        let langs = [];

        const setDefaultLangSession = (lang, isDefault = false) => {
            if (isDefault) {
                Session.setSessionProp("managerDefLang", lang.languageRef);
            }
        };

        if (data.projectLangs) {
            langs = data.projectLangs.results;
            langs.forEach((lang) => setDefaultLangSession(lang, lang.isDefault));
        }

        const uniqueLanguages = {};
        const filteredArrayLanguages = [];

        langs.forEach((element) => {
            if (!uniqueLanguages[element.languageRef]) {
                uniqueLanguages[element.languageRef] = true;
                filteredArrayLanguages.push(element);
            }
        });

        dispatch(setProjectLangs(filteredArrayLanguages));
    };

    useEffect(() => {
        if (!loading && !error && data && data.projectLangs) {
            const sortedProjectLangsAlphabetically = data.projectLangs.results.sort((a, b) =>
                t("language:" + a.languageRef).localeCompare(t("language:" + b.languageRef))
            );

            const sortedByDefaultValue = sortedProjectLangsAlphabetically.sort(
                (a, b) => Number(b.isDefault) - Number(a.isDefault)
            );
            storeDefaultLang({ projectLangs: data.allProjectLangs });
            const allProjectLangs = sortedByDefaultValue.filter((lang) => {
                if (searchString && lang) {
                    const ref = lang.languageRef;
                    const name = t("language:" + ref);
                    if (ref !== searchString && normalize(name).search(normalize(searchString)) === -1) {
                        return null;
                    }
                }
                return lang;
            });
            const languages = getLanguagesList(data.languages.results, allProjectLangs, t);
            const end = perPage * (currentPage || 1);
            const start = end - perPage;
            const pageProjectLangs = allProjectLangs.filter((lang, index) =>
                index >= start && index < end ? lang : null
            );
            const newLangOptions = languages.filter((item) => item.visible === true);
            dispatch(
                setSectionContent({
                    languages: newLangOptions,
                    count: newLangOptions.length,
                })
            );
            dispatch(setActionData({ name: "" }));
            //Gets formatted results
            const dataItems = arrangeData(pageProjectLangs, languages, isCorporate);
            //Gets this table headers
            const tableHeaders = GetTableHeaders(itemType);
            //Filter by project
            const availableFilters = GetAvailableFilters(itemType, extractPropertiesList(), null, isCorporate);
            //Shows loader component
            dispatch(setLoading(true));
            //Adds results to store
            dispatch(setTableResults(dataItems));
            //Config table
            //Shows search
            dispatch(setSearchable(true));
            dispatch(setTableType(itemType));
            //Sets table headers
            dispatch(setHeaders(tableHeaders));
            //Sets filters
            dispatch(setAvailableFilters(availableFilters));
            //Sets total count
            dispatch(setCount(allProjectLangs.length));
            dispatch(setCountPosition("table-header"));
            //Sets table header buttons
            dispatch(setHeaderButtons(GetTableHeaderButton(itemType, null, isCorporate)));
            //Sets in row add new form
            dispatch(setFormNewContent(GetTableForm(itemType)));
            //Sets custom error
            dispatch(setCustomError(`${t("theres-not")} ${t("lang")} ${t("available")}`));

            //Set custom config
            dispatch(
                setCustomConfig({
                    searchSuffix: "lang",
                    searchStyle: "col-span-3",
                    filtersStyle: "col-span-9",
                })
            );

            //Sets if rows are expandible
            dispatch(setExtendedInfo(false));
            //Sets initial visibility of row form
            dispatch(showRowForm(false));
            //If no added filters, cleans current ones from previous pages
            if (filteredFilterQuery === "" && searchString === "") {
                dispatch(cleanActiveFilters());
            }
            //Hides loader compoent
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, currentPage, searchString]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);
};

const getLanguagesList = (list, projectLangs, t) => {
    let langs = [];
    list.map((lang) => {
        langs.push({
            id: lang.ref,
            name: t("language:" + lang.ref),
            value: lang.ref,
            visible:
                projectLangs != null && projectLangs.length > 0 && _.some(projectLangs, { languageRef: lang.ref })
                    ? false
                    : true,
        });
        return lang;
    });
    return langs;
};

export const arrangeData = (pageProjectLangs, languages, isCorporate) => {
    const tempArray = [];
    let lang = null;
    // eslint-disable-next-line
    pageProjectLangs.map((language) => {
        lang = languages !== null && languages.length > 0 ? _.find(languages, { id: language.languageRef }) : null;
        tempArray.push(Language(language, lang != null ? lang.name : "", isCorporate));
    });
    return tempArray;
};

const extractPropertiesList = () => {
    let propertiesArray = [];
    if (Session.getSessionProp("projects") !== null && Session.getSessionProp("projects") !== undefined) {
        // eslint-disable-next-line
        JSON.parse(Session.getSessionProp("projects")).map((project) => {
            if (project.ref && project.ref !== "CORPORATE") {
                propertiesArray.push([project.name, project.id, "str"]);
            }
        });
    }
    return propertiesArray;
};

export default ListLanguages;
