import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import translate from "../../lang/es/hotel/translate";
//Components
import TableRow from "./useRow";
import TableRowGroup from "./useRowGroup";
import UseCZFormAreaCreate from "./useCZFormAreaCreate";
import UseCZFormAreaUpdate from "./useCZFormAreaUpdate";
import UseCZFormCategoryCreate from "./useCZFormCategoryCreate";
import UseCZFormCategoryUpdate from "./useCZFormCategoryUpdate";
import UseCZFormBookingCreate from "./useCZFormBookingCreate";
import UseCZFormStaffCreate from "./useCZFormStaffCreate";
import UseCZFormStaffUpdate from "./useCZFormStaffUpdate";
import UseCZFormCapacityUpdate from "./useCZFormCapacityUpdate";
import UseCZFormBookingUpdate from "./useCZFormBookingUpdate";
import UseFormCompanion from "./useFormCompanion";
import UseFormTopologyRoom from "./useFormTopologyRoom";
import UseFormPropertyTag from "./useFormPropertyTag";
import UseFormAccessProfile from "./useFormAccessProfile";
//Actions
import { showRowForm } from "../../actions/sectionActions";
//Utils
import { toast } from "react-toastify";
import { setLoading, setPage } from "../../actions/tableActions";
import { capitalizeFirst } from "../../hooks/Utils/Utils";
import UseLanguageFormCreate from "./UseLanguageFormCreate";
//NS Error div
import { networkServerErrorDiv } from "../../hooks/GraphqlCalls/Hotel/Monitoring/NSStatus";
import UseFormMediaLibraryFolder from "./useFormMediaLibraryFolder";
import ReactTooltip from "components/ReactTooltip";
import UseEditPredefinedChannel from "./UseEditPredefinedChannel";
import { useTranslation } from "react-i18next";

const UseRows = () => {
    //Store data
    const { results, showRowGroups, dateFilter, loading, idsSubrows } = useSelector((state) => state.table);
    const { langStrings, showGlobalLoading } = useSelector((state) => state.ui);
    const { t } = useTranslation();
    const { rowForm } = useSelector((state) => state.sectionContent);
    const { actionName } = useSelector((state) => state.action);
    const {
        serverError,
        customServerError,
        customError,
        rowInEdition,
        customConfig,
        activeFilters,
        currentPage,
        searchString,
    } = useSelector((state) => state.table);
    //Actions
    const dispatch = useDispatch();
    //Listeners
    useEffect(() => {
        if (dateFilter && rowForm) {
            if (dateFilter !== "") {
                const currentDate = dateFilter;
                const today = new window.ZDate();
                if (currentDate < today && currentDate.toLocaleDateString() !== today.toLocaleDateString()) {
                    dispatch(showRowForm(false));
                    toast.error(translate("error-book-past"));
                }
            }
        }
        // eslint-disable-next-line
    }, [dateFilter]);

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [results, customError]);

    useEffect(() => {
        if (actionName === "update-booking") {
            dispatch(setLoading(true));
        }
        // eslint-disable-next-line
    }, [actionName]);

    const noResultsDiv = () => {
        //in case that the user delete the unique element of the current page
        if (currentPage > 1) {
            dispatch(setPage(currentPage - 1));
        }

        return (
            <div className="text-center">
                <h1 className="mt-5 pt-5" id="no-result-message">
                    {capitalizeFirst(
                        customConfig && customConfig.searchNoResultText && activeFilters && activeFilters.search
                            ? langStrings[customConfig.searchNoResultText]
                            : customError
                            ? customError
                            : searchString !== null
                            ? langStrings["no-results-found"]
                            : langStrings["no-results"]
                    )}
                </h1>
            </div>
        );
    };

    const renderSubRows = (subItem, index, depth = 0) => {
        if (subItem.subrowData && subItem.subrowData.length > 0) {
            return (
                <React.Fragment key={subItem.id}>
                    <TableRow index={index} depth={depth} subrowData={subItem} />
                    {idsSubrows.includes(Number(subItem.id)) &&
                        subItem.subrowData.map((nestedSubItem, subIndex) =>
                            renderSubRows(nestedSubItem, subIndex, depth + 1)
                        )}
                </React.Fragment>
            );
        } else {
            return <TableRow index={index} key={subItem.id} depth={depth} subrowData={subItem} />;
        }
    };

    //Functions
    const getEditForm = () => {
        return (
            <div id="forms">
                {rowForm && actionName === "edit-area-name" ? <UseCZFormAreaUpdate /> : null}
                {rowForm && actionName === "edit-category-name" ? <UseCZFormCategoryUpdate /> : null}
                {rowForm && actionName === "staff-edit" ? <UseCZFormStaffUpdate /> : null}
                {rowForm && actionName === "capacity-edit" ? <UseCZFormCapacityUpdate /> : null}
                {rowForm && actionName === "update-booking" ? <UseCZFormBookingUpdate /> : null}
                {rowForm && actionName === "edit-companion" ? <UseFormCompanion /> : null}
                {rowForm && actionName === "edit-topology-room" ? <UseFormTopologyRoom /> : null}
                {rowForm && actionName === "edit-tag-values" ? <UseFormPropertyTag /> : null}
                {rowForm &&
                (actionName === "edit-media-library-folder" || actionName === "edit-media-library-media") ? (
                    <UseFormMediaLibraryFolder />
                ) : null}
                {rowForm && actionName === "edit-access-profile" ? <UseFormAccessProfile /> : null}
                {rowForm && actionName === "preset-channel-edit" ? <UseEditPredefinedChannel /> : null}
            </div>
        );
    };
    return (
        <>
            <div id="forms">
                {rowForm && actionName === "new-cz-area" ? <UseCZFormAreaCreate /> : null}
                {rowForm && actionName === "new-cz-category" ? <UseCZFormCategoryCreate /> : null}
                {rowForm && actionName === "new-area-booking" ? <UseCZFormBookingCreate /> : null}
                {rowForm && actionName === "new-staff-member" ? <UseCZFormStaffCreate /> : null}
                {rowForm && actionName === "add-companion" ? <UseFormCompanion /> : null}
                {rowForm && actionName === "add-topology-room" ? <UseFormTopologyRoom /> : null}
                {rowForm && actionName === "new-language" ? <UseLanguageFormCreate /> : null}
                {rowForm && actionName === "add-media-library-folder" ? <UseFormMediaLibraryFolder /> : null}
                {rowForm && actionName === "add-access-profile" ? <UseFormAccessProfile /> : null}
                {rowForm && actionName === "add-preset-channel" ? <UseEditPredefinedChannel action={"create"} /> : null}
            </div>
            {!loading && !showGlobalLoading && (results.length === 0 || !results) ? (
                serverError && (!customServerError || !customServerError.enabled) ? (
                    networkServerErrorDiv(customError, t)
                ) : (
                    noResultsDiv()
                )
            ) : (
                <div
                    id="rows"
                    className={`${customConfig ? customConfig.rowsContainerAdjust : null}`}
                    onScroll={(e) =>
                        customConfig && customConfig.onScrollCallback ? customConfig.onScrollCallback(e) : null
                    }
                >
                    {results
                        ? results.map((item, index) => (
                              <div key={index}>
                                  {showRowGroups && item.category ? <TableRowGroup index={index} /> : null}
                                  {rowInEdition !== null && index === rowInEdition && rowForm ? (
                                      getEditForm()
                                  ) : (
                                      <TableRow index={index} />
                                  )}
                                  {idsSubrows.includes(Number(item.id)) && item.subrowData.length > 0 ? (
                                      <div className="relative">
                                          <div
                                              style={{ height: "0.5px", top: "-1px" }}
                                              className=" absolute bg-gray-200 w-full h-3"
                                          ></div>
                                          {item.subrowData.map((subItem, index) => {
                                              let isFirst = index === 0;
                                              return renderSubRows(subItem, index, isFirst);
                                          })}
                                          <div
                                              style={{ height: "0.5px", bottom: "1px" }}
                                              className=" absolute bottom-0 left-0 bg-gray-200 w-full h-3"
                                          ></div>
                                      </div>
                                  ) : null}
                              </div>
                          ))
                        : null}
                </div>
            )}
        </>
    );
};

export default UseRows;
