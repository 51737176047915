import React, { useEffect, useState } from "react";
import { CastError } from "./Monitor/CastError";
import UseSectionHeader from "components/useSectionHeader";
import { DropdownMonitor } from "../Monitor/DropdownMonitor";
import { useTranslation } from "react-i18next";
import { useIsDhcp } from "../Monitor/hooks/useIsDhcp";
import { useDispatch, useSelector } from "react-redux";
import NSStatus, { networkServerErrorDiv } from "hooks/GraphqlCalls/Hotel/Monitoring/NSStatus";
import Loading from "components/Loading";
import { Session } from "hooks/Utils/Session";
import { openModal, setModalContent } from "actions/uiActions";
import { AreYouSure, modal } from "../Monitor/modalContent/ValidateNetworkModal";
import { NetworkAlertsProvider } from "contexts/NetworkWarnings/NetworkAlerts";
import { useDhcpInfo } from "../Monitor/hooks/useDhcpInfo";
import { useMdnsInfo } from "../Monitor/hooks/useMdnsInfo";
import { useNetworkInfo } from "../Monitor/hooks/useNetworkInfo";
import { NetworkComponent } from "../Monitor/NetworkComponent";
import { CastingWarning } from "../Monitor/CastingWarning";

export const NetworkWarningWithContext = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(true);

    const { serverError, customServerError } = useSelector((state) => state.table);
    const superUser = Session.getSessionProp("superUser");

    const { isDhcp } = useIsDhcp();
    const { dhcpInfo, called: dhcpCalled, loading: dhcpLoading, refetch: dhcpRefetch } = useDhcpInfo();
    const { mdnsInfo, called: mdnsCalled, loading: mdnsLoading, refetch: mdnsRefetch } = useMdnsInfo();
    const { networkInfo, called: networkCalled, loading: networkLoading, refetch: networkRefetch } = useNetworkInfo();
    const { isDhcpData, isAllData } = isDhcp || {};

    const castNetworkClass = isDhcpData ? "grid-cols-2" : "";
    const hasDhcp = isDhcpData ? (
        <NetworkComponent dhcpInfo={dhcpInfo} called={dhcpCalled} loading={dhcpLoading} type="DhcpInfo" />
    ) : null;

    const dispatch = useDispatch();

    NSStatus({ customServerError: true });

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    const handleClick = () => {
        dispatch(setModalContent(modal(<AreYouSure refetch={{ dhcpRefetch, mdnsRefetch, networkRefetch }} />)));
        dispatch(openModal());
    };

    return (
        <>
            <div className="flex items-center justify-between">
                <UseSectionHeader title={t("network-warnings")} />
                {superUser ? (
                    <button
                        className={`w-auto px-4 min-w-24 mr-2 mt-1 py-1 rounded first-capital ${
                            serverError ? "btn-gray" : "btn-blue"
                        }`}
                        disabled={serverError}
                        onClick={() => handleClick()}
                    >
                        <i className="icon-superuser text-2xl mr-2"></i>
                        {t("validate-networks")}
                    </button>
                ) : null}
            </div>
            {!isLoading ? (
                <>
                    {isAllData && !serverError ? (
                        <CastError />
                    ) : (
                        <CastingWarning text={serverError ? t("no-network-available") : t("awaiting-information")} />
                    )}
                    <p className="mb-8 flex items-center gap-2">
                        <span className="icon-info text-2xl"></span>
                        {t("network-warnings-email")}
                    </p>
                    {isAllData && !serverError ? (
                        <>
                            <DropdownMonitor title={t("cast-network")} type={"cast"}>
                                <div className={`grid ${castNetworkClass}`}>
                                    {hasDhcp}
                                    <NetworkComponent
                                        mdnsInfo={mdnsInfo}
                                        called={mdnsCalled}
                                        loading={mdnsLoading}
                                        type="MdnsInfo"
                                    />
                                </div>
                                <div className="grid grid-cols-2">
                                    <NetworkComponent
                                        mdnsInfo={mdnsInfo}
                                        called={mdnsCalled}
                                        loading={mdnsLoading}
                                        type="Traffic"
                                    />
                                    <NetworkComponent
                                        mdnsInfo={mdnsInfo}
                                        called={mdnsCalled}
                                        loading={mdnsLoading}
                                        type="ChromeCastStatus"
                                    />
                                </div>
                            </DropdownMonitor>
                            <DropdownMonitor title={t("user-network")} type={"user"}>
                                <NetworkComponent
                                    networkInfo={networkInfo}
                                    called={networkCalled}
                                    loading={networkLoading}
                                    type={"NetworkInfo"}
                                />
                                <NetworkComponent
                                    networkInfo={networkInfo}
                                    called={networkCalled}
                                    loading={networkLoading}
                                    type={"Traffic"}
                                />
                            </DropdownMonitor>
                        </>
                    ) : null}
                </>
            ) : (
                <Loading adjust={"absolute"} />
            )}
        </>
    );
};

export const NetworkWarning = () => {
    return (
        <NetworkAlertsProvider>
            <NetworkWarningWithContext />
        </NetworkAlertsProvider>
    );
};
