import React, { useEffect, useState } from "react";

/**
 * @description A text input component
 * @param {string} id - The id of the input
 * @param {string} value - The initial value of the input
 * @param {string} placeholder - The placeholder of the input
 * @param {function} onChange - The function to call when the input value changes
 * @param {boolean} autoFocus - If the input should be focused on render
 * @param {boolean} disabled - If the input should be disabled
 * @param {boolean} required - If the input is required
 * @param {string} className - The extended class name of the input
 * @returns {JSX.Element} The text input component
 * @example
 * <TextInput id="name" value="John Doe" placeholder="Enter your name" onChange={(value) => console.log(value)} required={true} />
 */
const TextInput = ({ id, value, placeholder, onChange, autoFocus, disabled, required, className }) => {
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
        const input = document.getElementById(id);
        if (input) {
            input.value = value;
        }
        if (autoFocus) {
            setTimeout(() => {
                const input = document.getElementById(id);
                if (input) {
                    input.focus();
                }
            }, 100);
        }
    }, []);

    const defaultClass = "w-full rounded py-1 px-4 leading-7 long bg-gray-200";
    const disabledClass = disabled ? "bg-gray-300 text-gray-700" : "";
    const requiredClass = !disabled && required && !currentValue ? "border border-red-100" : "";

    return (
        <input
            id={id}
            type="text"
            placeholder={placeholder}
            onChange={(e) => {
                setCurrentValue(e.target.value);
                if (onChange) {
                    onChange(e.target.value);
                }
            }}
            disabled={disabled}
            className={`${defaultClass} ${className} ${disabledClass} ${requiredClass} `}
        />
    );
};

export default TextInput;
