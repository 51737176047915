import {
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_DELIVERED,
    ORDER_STATUS_INPROGRESS,
    ORDER_DELAYED,
    ORDER_STATUS_PENDING,
    ORDER_STATUS_READY,
    ORDER_STATUS_EXPIRED,
    ORDER_STATUS_DELIVERY_FAILED,
    DELIVERY_METHOD_ROOM,
    DELIVERY_METHOD_PICKUP,
    //DELIVERY_METHOD_GEOLOCATION,
    getStatusText as getOrderStatusText,
    getStatusColor as getOrderStatusColor,
    getDeliveryMethodIcon,
    getDeliveryMethodText,
} from "constants/sales";

export const ORDER_TYPE_RECEIVED = "received";
export const ORDER_TYPE_READY = "ready";
export const ORDER_TYPE_ARCHIVED = "archived";

export const ORDER_STATUS = {
    [ORDER_STATUS_CANCELLED]: {
        value: ORDER_STATUS_CANCELLED,
        enumValue: ORDER_STATUS_CANCELLED,
        title: getOrderStatusText(ORDER_STATUS_CANCELLED),
        adjust: getOrderStatusColor(ORDER_STATUS_CANCELLED),
    },
    [ORDER_STATUS_DELIVERY_FAILED]: {
        value: ORDER_STATUS_DELIVERY_FAILED,
        enumValue: ORDER_STATUS_DELIVERY_FAILED,
        title: getOrderStatusText(ORDER_STATUS_DELIVERY_FAILED),
        adjust: getOrderStatusColor(ORDER_STATUS_DELIVERY_FAILED),
    },
    [ORDER_DELAYED]: {
        value: ORDER_DELAYED,
        enumValue: ORDER_DELAYED,
        title: getOrderStatusText(ORDER_DELAYED),
        adjust: getOrderStatusColor(ORDER_DELAYED),
    },
    [ORDER_STATUS_DELIVERED]: {
        value: ORDER_STATUS_DELIVERED,
        enumValue: ORDER_STATUS_DELIVERED,
        title: getOrderStatusText(ORDER_STATUS_DELIVERED),
        adjust: getOrderStatusColor(ORDER_STATUS_DELIVERED),
    },
    [ORDER_STATUS_INPROGRESS]: {
        value: ORDER_STATUS_INPROGRESS,
        enumValue: ORDER_STATUS_INPROGRESS,
        title: getOrderStatusText(ORDER_STATUS_INPROGRESS),
        adjust: getOrderStatusColor(ORDER_STATUS_INPROGRESS),
    },
    [ORDER_STATUS_PENDING]: {
        value: ORDER_STATUS_PENDING,
        enumValue: ORDER_STATUS_PENDING,
        title: getOrderStatusText(ORDER_STATUS_PENDING),
        adjust: getOrderStatusColor(ORDER_STATUS_PENDING),
    },
    [ORDER_STATUS_READY]: {
        value: ORDER_STATUS_READY,
        enumValue: ORDER_STATUS_READY,
        title: getOrderStatusText(ORDER_STATUS_READY),
        adjust: getOrderStatusColor(ORDER_STATUS_READY),
    },
    [ORDER_STATUS_EXPIRED]: {
        value: ORDER_STATUS_EXPIRED,
        enumValue: ORDER_STATUS_EXPIRED,
        title: getOrderStatusText(ORDER_STATUS_EXPIRED),
        adjust: `font-bold ${getOrderStatusColor(ORDER_STATUS_EXPIRED)}`,
    },
};

export const ORDER_DELIVERY_METHODS = {
    roomDelivery: {
        value: DELIVERY_METHOD_ROOM,
        title: getDeliveryMethodText(DELIVERY_METHOD_ROOM),
        iconName: getDeliveryMethodIcon(DELIVERY_METHOD_ROOM),
    },
    pickUp: {
        value: DELIVERY_METHOD_PICKUP,
        title: getDeliveryMethodText(DELIVERY_METHOD_PICKUP),
        iconName: getDeliveryMethodIcon(DELIVERY_METHOD_PICKUP),
    },
    /*geolocation: {
        value: DELIVERY_METHOD_GEOLOCATION,
        title: getDeliveryMethodText(DELIVERY_METHOD_GEOLOCATION),
        iconName: getDeliveryMethodIcon(DELIVERY_METHOD_GEOLOCATION),
    },*/
};
export const ORDER_ACTIONS = {
    cancel: {
        name: "cancel",
        title: "cancel",
        action: {
            name: "order-to-cancel",
            description: "cancel-order-description",
            buttonName: "cancel-order",
            buttonStyle: "red",
        },
    },
    accept: {
        name: "accept",
        title: "accept",
        action: {
            name: "order-to-accept",
            description: "accept-order-description",
            buttonName: "accept-order",
            buttonStyle: "blue",
        },
    },
    ready: {
        name: "ready",
        title: "ready",
        action: {
            name: "order-to-ready",
            description: "ready-order-description",
            buttonName: "ready-order",
            buttonStyle: "blue",
        },
    },
    delivered: {
        name: "delivered",
        title: "delivered",
        action: {
            name: "order-to-delivered",
            description: "delivered-order-description",
            buttonName: "delivered-order",
            buttonStyle: "blue",
        },
    },
};
const padFix = (n) => {
    return ("00" + n).match(/\d{2}$/);
};

export const formatDate = (date, format) => {
    switch (format) {
        case "date":
            return date.getFullYear() + "-" + padFix(date.getMonth() + 1) + "-" + padFix(date.getDate());
        case "time":
            return padFix(date.getHours()) + ":" + padFix(date.getMinutes());
        default:
            return date;
    }
};
