const DeleteTopologyRoom = (id, name) => {
    return [
        {
            title: ["delete", name],
            text: "topology-delete-room",
            id: id,
            executeSectionQuery: true,

            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete",
                    style: "red",
                    action: "topology-delete-room",
                },
            ],
        },
    ];
};

export default DeleteTopologyRoom;
