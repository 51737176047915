import { escapeSpecialChars } from "../../Utils/Utils";
import { GetScreenDevices } from "../../Utils/Widgets";
import { SCREEN, WELCOME_SEQUENCE } from "../../Utils/Design/EditorUtils";
import i18next from "i18next";

const VIDEO_MARGIN_SCREEN = JSON.stringify(
    JSON.stringify({
        margin: {
            h: 0,
            w: 0,
        },
        itemsMargin: {
            h: 0,
            w: 0,
        },
        textToContinueHorizontalAlign: "right",
        textToContinueVerticalAlign: "bottom",
        textToContinue: true,
    })
);

const GENERAL_MARGIN_SCREEN = (showContinue) => {
    return JSON.stringify(
        JSON.stringify({
            margin: {
                h: 10,
                w: 10,
            },
            itemsMargin: {
                h: 10,
                w: 10,
            },
            textToContinueHorizontalAlign: "right",
            textToContinueVerticalAlign: "bottom",
            textToContinue: showContinue,
        })
    );
};

export const getContentByWelcomeTypeScreen = (welcomeTypeScreen, lang) => {
    const devices = GetScreenDevices({ screenType: SCREEN.TYPE.WELCOME });
    let mutationDeviceContent =
        "[" +
        devices
            .map((d) => {
                return `{ type: "${d.type}", name: "${d.name}", orientation: "${d.orientation}", res: { h: ${d.res.h}, w: ${d.res.w} }, grid: { cols: ${d.grid.cols}, rows: ${d.grid.rows} } }`;
            })
            .join(" ") +
        "]";
    const generalMargin =
        welcomeTypeScreen === WELCOME_SEQUENCE.LANGSELECTION
            ? GENERAL_MARGIN_SCREEN(false)
            : GENERAL_MARGIN_SCREEN(true);
    return `{
        contentStyle: ${welcomeTypeScreen === WELCOME_SEQUENCE.VIDEO ? VIDEO_MARGIN_SCREEN : generalMargin}    
        devices: ${mutationDeviceContent}
        welcomeType: ${welcomeTypeScreen}
        ${
            welcomeTypeScreen !== WELCOME_SEQUENCE.WIDGETS
                ? `welcomeContentWidgets: [{
                        ${insertWidgetByType(welcomeTypeScreen, lang)}
                  }]`
                : ""
        }
    }`;
};

const insertWidgetByType = (screenType, lang) => {
    const devices = GetScreenDevices({ screenType: SCREEN.TYPE.WELCOME });
    const grid = devices?.[0].grid || { cols: 64, rows: 36 };
    //start config for video widget
    let _type = "VIDEO";
    let _widgetPos = { TV: { x: 0, y: 0, w: grid.cols, h: grid.rows } };
    let _widgetData = { autoplay: true, repeat: false };
    switch (screenType) {
        case WELCOME_SEQUENCE.LANGSELECTION:
            const content_t_es = i18next.getFixedT("es");
            const content_t_en = i18next.getFixedT("en");
            _type = "LANGSELECTION";
            _widgetPos = {
                TV: {
                    x: Math.round((grid.cols * 2) / 7),
                    y: Math.round(grid.rows / 7),
                    w: Math.round((grid.cols * 3) / 7),
                    h: Math.round((grid.rows * 2) / 3),
                },
            };
            _widgetData = { title: {} };
            // add es & en translations to widget text
            _widgetData.title["es"] = content_t_es("select-lang");
            _widgetData.title["en"] = content_t_en("select-lang");
            break;
        case WELCOME_SEQUENCE.VIDEO:
            _type = "VIDEO";
            break;
        default:
            break;
    }
    return `type: "${_type}"
            layoutPos:  ${JSON.stringify(JSON.stringify(_widgetPos))}
            widgetStyle: ${JSON.stringify(JSON.stringify({}))}
            data: ${JSON.stringify(JSON.stringify(_widgetData))}
            pos:1`;
};

export const setWelcomeMutation = (welcomeQuery, setMutation, actionData) => {
    switch (welcomeQuery.action) {
        case "add-welcome-screen":
            if (
                welcomeQuery &&
                welcomeQuery.params &&
                welcomeQuery.params.welcome &&
                actionData.values &&
                actionData.values["welcome-type"]
            ) {
                setMutation(`
                    mutation{
                        createWelcomeScreenContent(
                            designId: ${welcomeQuery.params.welcome.designId}
                            welcomeId: ${welcomeQuery.params.welcome.welcomeId}
                            welcomeContent: ${getContentByWelcomeTypeScreen(actionData.values["welcome-type"][0], {
                                lang: actionData.lang,
                                texts: actionData.texts,
                                managerLang: actionData.managerLang,
                            })}
                            
                        ){ error ok }}
                `);
            } else {
                welcomeQuery.noExecute = true;
            }
            welcomeQuery.mutationName = "createWelcomeScreenContent";
            break;
        case "welcome-edit-name":
            setMutation(`
                mutation{
                    updateWelcomeScreen(
                        name: "${escapeSpecialChars(actionData.values["screen-name"])}"
                        designId: ${welcomeQuery.params.value.designId}
                        id: ${welcomeQuery.params.value.welcomeId}
                    ){ error ok }}
            `);
            welcomeQuery.mutationName = "updateWelcomeScreen";
            break;
        case "move-welcomescreen-content":
            setMutation(`
                mutation{moveWelcomeScreenContent(
                    pos:${welcomeQuery.params.newPos}
                    designId:${welcomeQuery.params.designId}
                    contentId:${welcomeQuery.params.contentId} 
                    welcomeId:${welcomeQuery.params.welcomeId} 
                ){error id ok}}`);
            welcomeQuery.mutationName = "moveWelcomeScreenContent";
            welcomeQuery.hideLoading = true;
            welcomeQuery.noRefresh = true;
            break;
        case "publish-welcome-as-default":
            // publish as default will enabled with ptarget ALL
            setMutation(`
                mutation{
                    updateWelcomeScreen(
                        isEnabled: true
                        designId: ${welcomeQuery.params.designId}
                        id: ${welcomeQuery.params.welcomeId}
                    ){ error ok }}
            `);
            welcomeQuery.mutationName = "updateWelcomeScreen";
            break;
        case "duplicate-welcome-content":
            setMutation(`
                mutation{
                    duplicateWelcome(
                        id: ${welcomeQuery.params.welcomeId}
                        sourceDesignId: ${welcomeQuery.params.designId}
                    ){ error ok }}
            `);
            welcomeQuery.mutationName = "duplicateWelcome";
            break;
        case "welcome-delete":
            setMutation(`
                mutation{
                    deleteWelcomeScreen(
                        designId: ${welcomeQuery.params.value.designId}
                        id: ${welcomeQuery.params.value.welcomeId}
                    ){ error ok }}
            `);
            welcomeQuery.mutationName = "deleteWelcomeScreen";
            break;
        case "delete-welcome-content":
            setMutation(`
                mutation{
                    deleteWelcomeScreenContent(
                        contentId: ${welcomeQuery.params.value.contentId}
                        designId: ${welcomeQuery.params.value.designId}
                        welcomeId: ${welcomeQuery.params.value.welcomeId}
                    ){ error ok }}
            `);
            welcomeQuery.mutationName = "deleteWelcomeScreenContent";
            break;
        case "duplicate-welcome-screen":
            setMutation(`
                mutation{
                    duplicateWelcomeContent(
                        designId: ${welcomeQuery.params.designId}
                        welcomeId: ${welcomeQuery.params.welcomeId}
                        contentId: ${welcomeQuery.params.contentId}
                    ){ error ok }}
            `);
            welcomeQuery.mutationName = "duplicateWelcomeContent";
            break;
        default:
            break;
    }
    return welcomeQuery;
};
