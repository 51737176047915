import EditProperty from "../modals/EditProperty";
const Property = (property, allBrandsForEdit) => {
    const { id, name, brands } = property;
    const dataType = "property";

    let listBrands = [];
    if (brands)
        brands.forEach((brand, index) => {
            listBrands.push([index, `${brand.name}`, "bg-orange-100"]);
        });

    return {
        ...property,
        dataType: dataType,
        adjust: "h-auto",
        // mutationParams: { id: id, name: name },
        info: [
            {
                cellContent: "text",
                value: name,
                specialWidth: "w-3/12",
            },

            {
                cellContent: "labelsTruncate",
                value: listBrands,
                specialWidth: "w-8/12",
                cellAdjust: "truncate labels",
            },
            {
                name: "property",
                cellContent: "icon",
                isAction: true,
                modal: EditProperty(name, id, allBrandsForEdit),
                icon: {
                    name: "edit",
                },
                specialWidth: "w-1/12",
            },
        ],
    };
};

export default Property;
