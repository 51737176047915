import {
    CC_SYNC_ACTION_PENDING,
    GET_CCS_GUEST_NETWORK_FILTER_INFO,
    GET_TABLE_DATA,
    UPDATE_CC_INFORMATION,
} from "components/Section/Hotel/Services/getTableInformation";
import { transformForTable } from "hooks/GraphqlCalls/Hotel/Monitoring/useGetCCguestNetwork";
import {
    createModel,
    createSubModel,
    createZafiro,
} from "hooks/GraphqlCalls/Hotel/Monitoring/useGetCCsGuestNetworkFilterInfo";
import React, { createContext, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "react-apollo";

const STEP_UPDATE = 1;
const STEP_SYNC_STATE = 2;
const STEP_GET_INFO = 3;
const STEP_FINISH = 4;

export const UserNetworkContext = createContext({
    updatedData: null, // Updated data
    called: false, // CC data has been fetched
    loading: false, // CC data is being fetched
    isPending: false, // CC data is being updated
    updatedFilter: null, // Updated data
    update: () => {}, // Update cc data
});

export const UserNetworkProvider = ({ children }) => {
    const [step, setStep] = useState(null);
    const [updatedData, setUpdatedData] = useState(null);
    const [updatedFilter, setUpdateFilter] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const [controlStep, setControlStep] = useState(false);

    const [update, { data: updateResponse }] = useMutation(UPDATE_CC_INFORMATION);
    const [syncState, { data: syncResponse }] = useLazyQuery(CC_SYNC_ACTION_PENDING, {
        pollInterval: 20000,
    });
    const [getUpdatedData, { data: tableData }] = useLazyQuery(GET_TABLE_DATA, {
        fetchPolicy: "network-only",
    });

    const [getFilters, { data: filterData }] = useLazyQuery(GET_CCS_GUEST_NETWORK_FILTER_INFO, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        switch (step) {
            case STEP_UPDATE:
                //console.log("Updating chrome cast information...");
                update();
                break;
            case STEP_SYNC_STATE:
                //console.log("Checking sync state...");
                syncState();
                break;
            case STEP_GET_INFO:
                //console.log("Getting updated data...");
                getUpdatedData();
                getFilters();
                break;
            case STEP_FINISH:
                //console.log("Finished updating chrome cast information.");
                if (tableData?.getCCsGuestNetworkInfo?.data) {
                    setUpdatedData(
                        transformForTable(
                            tableData?.getCCsGuestNetworkInfo,
                            tableData?.getCCsGuestNetworkInfo.lastUpdate
                        )
                    );
                    setIsPending(false);
                }
                if (filterData?.getCCsGuestNetworkFilterInfo) {
                    setUpdateFilter({
                        models: createModel(filterData?.getCCsGuestNetworkFilterInfo?.models),
                        subModels: createSubModel(filterData?.getCCsGuestNetworkFilterInfo?.models),
                        ownership: createZafiro(filterData?.getCCsGuestNetworkFilterInfo?.ownership),
                    });
                }
                setStep(null);
                break;
            default:
            // do nothing
        }
    }, [step]);

    useEffect(() => {
        if (step === STEP_UPDATE && updateResponse) {
            if (updateResponse.syncCCUserNetwork?.ok) {
                setStep(STEP_SYNC_STATE);
                setIsPending(true);
            } else {
                setStep(null);
            }
        }
    }, [updateResponse]);

    useEffect(() => {
        if (step === STEP_SYNC_STATE && syncResponse) {
            if (syncResponse.getCCSyncActionPending) {
                if (syncResponse.getCCSyncActionPending.isPending) {
                    setControlStep(true);
                } else if (controlStep && !syncResponse.getCCSyncActionPending.isPending) {
                    setStep(STEP_GET_INFO);
                    setControlStep(false);
                }
            } else {
                setStep(null);
            }
        }
    }, [controlStep, syncResponse?.getCCSyncActionPending?.isPending]);

    useEffect(() => {
        if (step === STEP_GET_INFO && tableData) {
            setStep(STEP_FINISH);
        }
    }, [tableData]);

    return (
        <UserNetworkContext.Provider
            value={{
                updatedData,
                isPending,
                update: () => {
                    if (!step) {
                        setStep(STEP_UPDATE);
                    }
                },
                setUpdatedData,
                updatedFilter,
            }}
        >
            {children}
        </UserNetworkContext.Provider>
    );
};
