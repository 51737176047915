import { GET_CCS_GUEST_NETWORK_FILTER_INFO } from "components/Section/Hotel/Services/getTableInformation";
import { useEffect, useState } from "react";
import { useLazyQuery } from "react-apollo";

export const useGetCCsGuestNetworkFilterInfo = () => {
    const [ccsGuestNetworkFilterInfo, setCCsGuestNetworkFilterInfo] = useState(null);

    const [executeGetFilters, { data }] = useLazyQuery(GET_CCS_GUEST_NETWORK_FILTER_INFO, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        executeGetFilters();
    }, []);

    useEffect(() => {
        if (data) {
            setCCsGuestNetworkFilterInfo({
                models: createModel(data?.getCCsGuestNetworkFilterInfo?.models),
                subModels: createSubModel(data?.getCCsGuestNetworkFilterInfo?.models),
                ownership: createZafiro(data?.getCCsGuestNetworkFilterInfo?.ownership),
            });
        }
    }, [data]);

    return { ccsGuestNetworkFilterInfo };
};

export const createModel = (data) => {
    return data?.map((item) => ({
        id: item?.type,
        name: item?.type,
        isChecked: false,
    }));
};

export const createSubModel = (data) => {
    return data
        ?.flatMap((item) =>
            item?.models.map((model) => ({
                id: item?.type,
                name: model,
                isChecked: false,
            }))
        )
        .filter((item, index, array) => array.findIndex((elem) => elem.name === item.name) === index);
};

export const createZafiro = (data) => {
    return data?.map((item) => ({
        id: item,
        name: item === "ZAFIRO" ? "Zafiro" : "No Zafiro",
        isChecked: false,
    }));
};
