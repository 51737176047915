import React, { useContext, useState } from "react";

import TableContext from "./Context";

import ErrorInfo from "components/ErrorInfo";

const Header = ({ cols: pCols, header: pHeader, colsAlign: pColsAlign }) => {
    const { cols: ctxCols, header: ctxHeader, colsAlign: ctxColsAlign, setSort } = useContext(TableContext);

    const cols = pCols || ctxCols;
    const header = pHeader || ctxHeader;
    const colsAlign = pColsAlign || ctxColsAlign;

    const [headerData, setHeaderData] = useState(header);

    const error = (() => {
        if (!cols || cols.length === 0) {
            return "No columns provided";
        } else if (!header) {
            return "No header provided";
        }
        return null;
    })();

    const handleSort = (element, index) => {
        const headerSorted = headerData.map((header) => {
            if (header[cols[index]] === element[cols[index]]) {
                if (header.icon === "icon-table-sort") {
                    setSort({
                        order: "desc",
                        col: cols[index],
                    });
                    return { ...header, icon: "icon-sort-desc" };
                } else if (header.icon === "icon-sort-desc") {
                    setSort({
                        order: "asc",
                        col: cols[index],
                    });
                    return { ...header, icon: "icon-sort-asc" };
                } else {
                    setSort(null);
                    return { ...header, icon: "icon-table-sort" };
                }
            } else if (header.sort) {
                return { ...header, icon: "icon-table-sort" };
            }
            return header;
        });
        setHeaderData(headerSorted);
    };

    return (
        <thead className="sticky z-10 top-0 bg-white" style={{ boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.08)" }}>
            {error ? (
                <tr className="text-center">
                    <td colSpan={cols?.length || 1} className=" px-4 py-10">
                        <ErrorInfo useDefault={false}>{error}</ErrorInfo>
                    </td>
                </tr>
            ) : (
                <tr className="text-left">
                    {cols.map((col, index) => (
                        <th
                            key={`col-${col}`}
                            className={`px-4 py-2 ${colsAlign?.[index] ? `text-${colsAlign[index]}` : null} ${
                                headerData[index].sort ? "cursor-pointer" : null
                            }`}
                            onClick={() => handleSort(headerData[index], index)}
                        >
                            {headerData[index][col]}
                            {headerData[index].sort ? (
                                <span
                                    className={`${headerData[index].icon} ml-2`}
                                    id={`${headerData[index][col]}-th`}
                                ></span>
                            ) : null}
                        </th>
                    ))}
                </tr>
            )}
        </thead>
    );
};

export default Header;
