export const SET_ACTIVE_HOTEL = "SET_ACTIVE_HOTEL";
export const SET_NETWORKS_DATA = "SET_NETWORKS_DATA";
export const CLEAR_PROJECT_REF = "CLEAR_PROJECT_REF";
export const SET_PROJECT_REF = "SET_PROJECT_REF";

export function setActiveHotel(currentHotelData) {
    //Action to load active hotel data in hotel store (id,ref,name,location)
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_HOTEL,
            payload: currentHotelData,
        });
    };
}

export function setNetworks(data) {
    //Sets store.networks an obj with all data and config for networkCard component
    return (dispatch) => {
        dispatch({
            type: SET_NETWORKS_DATA,
            payload: data,
        });
    };
}

export function clearProjectRef() {
    // clears store.hotel.ref
    return (dispatch) => {
        dispatch({
            type: CLEAR_PROJECT_REF,
        });
    };
}

export function setProjectRef(projectRef, global) {
    // sets store.hotel.projectRef
    return (dispatch) => {
        dispatch({
            type: SET_PROJECT_REF,
            playload: { ref: projectRef, global: global ? global : false },
        });
    };
}
