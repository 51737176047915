import React from "react";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import UseRadio from "../../../useRadio";
import UseButton from "../../../useButton";
import { preventNegativeValues, parseSecondsToDaysHours, UPDATE_EMAIL, tooltipHTMLDataAccessProfile } from "./Utils";
import AccessTypesCB from "./AccessTypesCB";
import AccessTypesSwitch from "./AccessTypesSwitch";

function getEmailCheckboxData(sectionName, emailFields) {
    return [
        {
            name: "name",
            dataField: emailFields,
            keyField: "email-name",
            singleValue: false,
            id: `${sectionName}-name-checkbox`,
        },
        {
            name: "surname",
            dataField: emailFields,
            keyField: "email-surname",
            singleValue: false,
            id: `${sectionName}-surname-checkbox`,
        },
        {
            name: "birthdate",
            dataField: emailFields,
            keyField: "email-date-of-birth",
            singleValue: false,
            id: `${sectionName}-date-of-birth-checkbox`,
        },
        {
            name: "country",
            dataField: emailFields,
            keyField: "email-country",
            singleValue: false,
            id: `${sectionName}-country-checkbox`,
        },
    ];
}

function getEmailSwitchData(sectionName, emailFields) {
    return [
        {
            dataField: emailFields,
            keyField: "email-name",
            id: `${sectionName}-name-switch`,
        },
        {
            dataField: emailFields,
            keyField: "email-surname",
            id: `${sectionName}-surname-switch`,
        },
        {
            dataField: emailFields,
            keyField: "email-date-of-birth",
            id: `${sectionName}-date-of-birth-switch`,
        },
        {
            dataField: emailFields,
            keyField: "email-country",
            id: `${sectionName}-country-switch`,
        },
    ];
}

const AccessTypesEmail = ({
    emailData,
    setEmailFields,
    emailFields,
    setCardEdition,
    emailFieldsBackup,
    setKeepCurrentPreview,
    setSelectMutation,
}) => {
    const { t } = useTranslation();
    const sectionName = "access-type-email";
    const emailCheckboxData = getEmailCheckboxData(sectionName, emailFields);
    const emailSwitchData = getEmailSwitchData(sectionName, emailFields);

    const accessProfile =
        emailData && emailData.additionalAccessProfileData ? emailData.additionalAccessProfileData : null;
    const idAccessProfile = emailData && emailData.accessprofiles_id;

    return (
        <div>
            <div className=" text-left">{t("users-will-have-10-minutes-to-verify-address")}</div>
            <div className=" flex w-full mt-4">
                <div className=" w-1/2 mr-10">
                    {/* ACCESS-PROFILE-EMAIL */}
                    <div className=" pl-2 border-b text-left font-bold border-gray-400">
                        {capitalizeFirst(t("access-profile"))}
                    </div>
                    <div className=" w-full flex mt-4 ml-2">
                        {String(idAccessProfile) === "0" ? (
                            <div className=" font-normal rounded flex flex-col items-start text-gray-800 truncate ">
                                <span className=" block">
                                    {`${capitalizeFirst(t("default"))} (${capitalizeFirst(t("max-bandwidth"))}) `}
                                </span>
                                <div className=" flex items-start mt-2 ">
                                    <span className=" icon icon-maximum text-gray-800 text-2xl"></span>
                                    <span className=" text-gray-800">
                                        {accessProfile && accessProfile.uploadBWMax / 1000000} Mb/s
                                    </span>
                                </div>
                                <div className=" flex items-start mt-2 ">
                                    <span className=" icon icon-minimum text-gray-800 text-2xl"></span>
                                    <span className=" text-gray-800">
                                        {accessProfile && accessProfile.downloadBWMax / 1000000} Mb/s
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div
                                    data-tip={tooltipHTMLDataAccessProfile(accessProfile, t, capitalizeFirst)}
                                    data-for="dangerous-html-tooltip"
                                    className=" bg-zafiro-600 font-bold py-2 px-2 rounded flex items-center text-white truncate "
                                >
                                    {accessProfile && accessProfile.name ? accessProfile.name : ""}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className=" w-1/2">
                    {/* CONFIGURATION-EMAIL */}
                    <div className=" pl-2 border-b text-left font-bold border-gray-400">
                        {capitalizeFirst(t("configuration"))}
                    </div>
                    <div className=" w-full flex flex-col mt-4 ml-2 text-left">
                        <div>
                            {capitalizeFirst(t("email-validation-every-login"))}
                            <span className=" ml-3 relative inline-block text-2xl text-gray-700 ">
                                <div
                                    data-tip={` <div>
                                            <p class="text-left" >${t(
                                                "validation-will-be-always-required-for-unrergistered-users"
                                            )}</p>
                                            <p class="text-left mt-2" >${capitalizeFirst(
                                                t("by-checking-yes-user-does-not-validate-email")
                                            )}</p>
                                            </div>  `}
                                    data-for="dangerous-html-tooltip"
                                    className=" icon icon-info"
                                    style={{ position: "absolute", top: "-15px", left: "0" }}
                                ></div>
                            </span>
                        </div>
                        <div className=" mt-2">
                            <div
                                className=" mb-3"
                                onClick={() => {
                                    setEmailFields({ ...emailFields, "email-breakconn": true });
                                }}
                            >
                                <UseRadio
                                    id={`${sectionName}-validation-required-radio-true`}
                                    selected={emailFields && emailFields["email-breakconn"]}
                                    value={true}
                                />
                                <div className=" ml-6 -mt-1">{capitalizeFirst(t("yes"))}</div>
                            </div>
                            <div
                                onClick={() => {
                                    setEmailFields({ ...emailFields, "email-breakconn": false });
                                }}
                            >
                                <UseRadio
                                    id={`${sectionName}-validation-required-radio-false`}
                                    selected={emailFields && emailFields["email-breakconn"]}
                                    value={false}
                                />
                                <div className=" ml-6 -mt-1">{capitalizeFirst(t("no"))}</div>
                            </div>
                        </div>
                    </div>
                    <div className=" w-full flex flex-col mt-4 ml-2 text-left">
                        {/* DURATION-EMAIL */}
                        <div className=" text-gray-800">
                            {capitalizeFirst(t("define-connection-time"))}
                            <span className=" ml-3 relative inline-block text-2xl text-gray-700 ">
                                <div
                                    data-tip={capitalizeFirst(t("user-reenter-credentials-after-that-time"))}
                                    data-for="dangerous-html-tooltip"
                                    className=" icon icon-info"
                                    style={{ position: "absolute", top: "-15px", left: "0" }}
                                ></div>
                            </span>
                        </div>
                        <div className=" mt-2 w-2/4 flex mb-8">
                            <div className=" w-1/2 flex flex-col">
                                <span className=" text-gray-800 text-sm mb-2 font-bold">
                                    {capitalizeFirst(t("days"))}
                                </span>
                                <input
                                    className="t-filter-input  p-2 "
                                    placeholder="--"
                                    min={0}
                                    id={`${sectionName}-email-renovation-days`}
                                    max={9999}
                                    defaultValue={
                                        emailFields && emailFields["email-renovation"]
                                            ? parseSecondsToDaysHours(emailFields["email-renovation"]).d
                                            : 1
                                    }
                                    onChange={(e) => {
                                        e.target.value = preventNegativeValues(e.target.value);
                                    }}
                                    type={"number"}
                                ></input>
                            </div>

                            <div className=" w-1/2 flex flex-col">
                                <span className=" text-gray-800 text-sm mb-2 font-bold">
                                    {capitalizeFirst(t("hours"))}
                                </span>
                                <input
                                    className="t-filter-input p-2 "
                                    placeholder="--"
                                    min={0}
                                    id={`${sectionName}-email-renovation-hours`}
                                    max={9999}
                                    defaultValue={
                                        emailFields && emailFields["email-renovation"]
                                            ? parseSecondsToDaysHours(emailFields["email-renovation"]).h
                                            : ""
                                    }
                                    onChange={(e) => {
                                        e.target.value = preventNegativeValues(e.target.value);
                                    }}
                                    type={"number"}
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className=" pl-2 border-b text-left font-bold border-gray-400">
                    {capitalizeFirst(t("content"))}
                </div>
                <div className=" flex pl-2">
                    <div className=" w-1/2 mr-10">
                        <div className="  mt-4 mb-4 text-gray-800 text-left">{capitalizeFirst(t("first-step"))}</div>
                        <div className=" text-left">
                            {capitalizeFirst(t("check-you-want-add-social-network-option"))}
                        </div>
                        <div className=" relative flex items-center w-auto mt-4">
                            <AccessTypesCB
                                data={{
                                    name: "social-networks-google",
                                    dataField: emailFields,
                                    keyField: "email-social-networks",
                                    singleValue: true,
                                    id: `${sectionName}-email-social-networks`,
                                }}
                                setState={setEmailFields}
                            />
                            <div
                                data-tip={t("option-unavailable-in-some-devices")}
                                data-for="dangerous-html-tooltip"
                                className=" icon icon-info ml-2 -mt-5 text-2xl text-gray-700 "
                            ></div>
                        </div>
                    </div>
                    <div className=" w-1/2">
                        <div className=" mt-4 mb-4 text-gray-800 text-left">{capitalizeFirst(t("second-step"))}</div>
                        <div className=" text-left">
                            {capitalizeFirst(t("ask-for-aditional-information-requested-not-registered"))}
                        </div>
                        <div className=" text-right mr-14 text-gray-800 mt-2">{capitalizeFirst(t("mandatory"))}</div>
                        <div className=" w-full flex">
                            <div className=" mt-3  w-1/2 ">
                                <AccessTypesCB data={emailCheckboxData[0]} setState={setEmailFields} />
                                <AccessTypesCB data={emailCheckboxData[1]} setState={setEmailFields} />
                                <AccessTypesCB data={emailCheckboxData[2]} setState={setEmailFields} />
                                <AccessTypesCB data={emailCheckboxData[3]} setState={setEmailFields} />
                            </div>
                            <div className=" mt-3 mr-14  w-1/2  ">
                                <AccessTypesSwitch data={emailSwitchData[0]} setState={setEmailFields} />
                                <AccessTypesSwitch data={emailSwitchData[1]} setState={setEmailFields} />
                                <AccessTypesSwitch data={emailSwitchData[2]} setState={setEmailFields} />
                                <AccessTypesSwitch data={emailSwitchData[3]} setState={setEmailFields} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className=" flex space-x-3 justify-end mx-10 mb-6 mt-6">
                    <div
                        onClick={() => {
                            setCardEdition(null);
                            setEmailFields({ ...emailFieldsBackup });
                        }}
                    >
                        <UseButton id={`${sectionName}-cancel`} buttonName="cancel" buttonColor="btn-white" />
                    </div>
                    <div
                        onClick={() => {
                            setSelectMutation(UPDATE_EMAIL);
                            setKeepCurrentPreview(true);
                        }}
                    >
                        <UseButton id={`${sectionName}-save`} buttonName="save" buttonColor="btn-blue" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccessTypesEmail;
