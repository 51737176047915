import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ACCEPTEDFORMATS, getFileTooltip, checkFileType } from "./Utils/FileUtils";
import { useDispatch } from "react-redux";
import { setRefreshData } from "actions/tableActions";

const useUpload = (params) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const contentTypes = params.contentTypes;
    const title = params.title;
    const filesResume = params.filesResume;
    const inputPlaceholder = params.placeholder || "";
    const onSuccess = params.onSuccess || null;
    const onError = params.onError || null;

    const [config, setConfig] = useState({
        accept: [],
        multiple: false,
        contentTypeTooltip: "",
    });
    const [files, setFiles] = useState();

    useEffect(() => {
        let accept = [];
        let maxSize = 0;
        let multiple = false;
        let contentTypeTooltip = "";
        if (contentTypes && contentTypes.length > 0) {
            // eslint-disable-next-line
            contentTypes.map((contentType) => {
                if (contentType.multiple) multiple = true;
                accept = [...accept, ...ACCEPTEDFORMATS[contentType.type]];
                contentTypeTooltip += getFileTooltip(contentType, t);
            });
        }
        setConfig({ accept, maxSize, multiple, contentTypeTooltip });
        // eslint-disable-next-line
    }, [contentTypes]);

    const handleChange = async (e) => {
        let returnFiles = [];
        const files = e.target.files;
        let errorHappen = null;
        if (files != null && files.length > 0) {
            Array.from(files).forEach((file, index) => {
                let foundValidType = false;
                if (!errorHappen) {
                    if (contentTypes && contentTypes.length > 0) {
                        contentTypes.map((contentType) => {
                            if (!errorHappen && !foundValidType) {
                                // Check type & size
                                foundValidType =
                                    params.allowAllTypes || checkFileType(file, ACCEPTEDFORMATS[contentType.type])
                                        ? contentType.type
                                        : null;
                                if (
                                    foundValidType &&
                                    contentType.maxSize &&
                                    (!file.size || file.size / 1000 / 1000 > contentType.maxSize)
                                ) {
                                    errorHappen = `${file.name} ${t("error-exceeds-allowed-size")}`;
                                }
                            }
                            return contentType;
                        });
                        if (!foundValidType) {
                            errorHappen = `${file.name} ${t("error-file-not-valid-dataType")}`;
                        }
                    }
                }
                if (foundValidType && !errorHappen) {
                    returnFiles.push({
                        type: foundValidType,
                        file: file,
                    });
                }
                return file;
            });
        }
        if (!errorHappen && onSuccess) {
            setFiles(returnFiles);
            onSuccess(returnFiles);
        } else if (errorHappen && onError) {
            onError(errorHappen || "Unknown error");
        }
        e.stopPropagation();
        e.preventDefault();
        return;
    };

    const showResume = files && filesResume;

    const view = (
        <>
            {title ? <div className="font-bold mb-2">{title}</div> : null}
            <div className={`${!showResume && "border border-dashed border-gray-500 "} relative`}>
                <input
                    type="file"
                    placeholder={inputPlaceholder}
                    className={`cursor-pointer relative block opacity-0 w-full h-full ${
                        showResume ? "hidden" : "p-20"
                    } z-50`}
                    onChange={handleChange}
                    id={params.id || "select-files-input"}
                    accept={config.accept ? config.accept.join(",") : "*"}
                    multiple={config.multiple}
                ></input>
                {showResume ? (
                    files.map((file, ind) => {
                        return (
                            <div key={`file-${ind}`}>
                                <div id={`file-${ind}`} className="w-auto float-left">
                                    {file.file.name}
                                </div>
                                <div
                                    className="icon icon-delete float-left btn-link cursor-pointer ml-2"
                                    style={{ lineHeight: "150%" }}
                                    onClick={() => {
                                        setFiles(null);
                                        document.getElementById(params.id).value = "";
                                        dispatch(setRefreshData(true));
                                    }}
                                ></div>
                            </div>
                        );
                    })
                ) : (
                    <div className="text-center p-10 absolute top-0 right-0 left-0 m-auto">
                        <h4 className="font-bold mb-2">
                            {t(config.multiple ? "drop-files-to-upload" : "drop-file-to-upload")}
                        </h4>
                        <p className="mb-2">{t("or")}</p>
                        <p className="btn-blue w-6/12 m-auto mb-2">
                            {t(config.multiple ? "select-files" : "select-file")}
                        </p>
                        <div className="text-xs">
                            {t("maximum-upload-file-size")}
                            <span
                                className={`icon-info text-lg align-middle m-2 relative z-100`}
                                data-tip={`${config.contentTypeTooltip}`}
                                data-for="dangerous-html-tooltip"
                            ></span>
                        </div>
                    </div>
                )}
            </div>
        </>
    );

    return { view };
};

export default useUpload;
