import React from "react";
//Components
import UseSectionHeader from "../../useSectionHeader";
import UseTable from "../../Table";
//Utils
import ListPasscodes from "../../../hooks/GraphqlCalls/Customers/ListPasscodes";
import UpdatePasscode from "../../../hooks/GraphqlCalls/Customers/UpdatePasscode";

const PasscodesConfiguration = () => {
    ListPasscodes();

    return (
        <>
            <UpdatePasscode />
            <UseSectionHeader title={["passcodes-configuration"]} />
            <UseTable />
        </>
    );
};

export default PasscodesConfiguration;
