import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withApollo } from "@apollo/react-hoc";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showGlobalLoading } from "../../../../actions/uiActions";
import { capitalizeFirst, removeApolloCacheKeys } from "../../../../hooks/Utils/Utils";

//UTils
import { CSVLink } from "react-csv";
import _ from "lodash";

const ExportDevices = ({ startDataExport, setStartDataExport, client }) => {
    //Constants.
    const { sortCriteria } = useSelector((state) => state.table);
    const activeFilters = useSelector((state) => state.table.activeFilters);
    const hide_ips = ["127.0.0.1"];
    const applyFilters = arrangeFilters(activeFilters);

    //Variables.
    let d = new window.ZDate();
    let exportFileName = `devices_${d.getDate()}_${d.getMonth() + 1
        }_${d.getFullYear()}.csv`;

    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //States.
    const [exportData, setExportData] = useState([]);
    const [exportHeaders, setExportHeaders] = useState([]);

    //Actions
    const dispatch = useDispatch();

    const GET_ALL_CHROMECASTS_DATA = gql`{
    chromecastsExport: devices(page:1, size:10000 ${sortCriteria} ${applyFilters ? `, filter:{${applyFilters}}` : ``}){
        info{ count }
        results{id:mac name ip model roomID roomNumber lastActivity lastActivityDate location roomTVName warning{info level roomID actionInfo}  wifiSignal}} 
    }`;

    const [executeCCAllDataQuery, allExportData] = useLazyQuery(GET_ALL_CHROMECASTS_DATA, {
        notifyOnNetworkStatusChange: false,
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });

    useEffect(() => {
        if (startDataExport) {
            dispatch(showGlobalLoading(true));
            executeCCAllDataQuery();
        }
        // eslint-disable-next-line
    }, [startDataExport]);

    useEffect(() => {
        if (
            startDataExport &&
            allExportData &&
            allExportData.data &&
            allExportData.data.chromecastsExport &&
            allExportData.data.chromecastsExport.results
        ) {
            const ccs = allExportData.data.chromecastsExport.results;
            let rows = [];
            setExportHeaders([
                { label: capitalizeFirst(langStrings["mac"]), key: "mac" },
                { label: capitalizeFirst(langStrings["state"]), key: "state" },
                { label: capitalizeFirst(langStrings["ip"]), key: "ip" },
                { label: capitalizeFirst(langStrings["name"]), key: "name" },
                { label: capitalizeFirst(langStrings["room"]), key: "room" },
                { label: capitalizeFirst(langStrings["location"]), key: "location" },
                { label: capitalizeFirst(langStrings["authorized"]), key: "authorized" },
                { label: capitalizeFirst(langStrings["online"]), key: "connected" },
                { label: capitalizeFirst(langStrings["last-connection"]), key: "lastconnection" },
                { label: capitalizeFirst(langStrings["last-activity"]), key: "casting" },
                { label: capitalizeFirst(langStrings["wifi-signal"]), key: "signal" },
            ]);
            ccs.map((cc) =>
                rows.push({
                    mac: cc.id ? cc.id : "",
                    state: cc && cc.warning && cc.warning.info ? cc.warning.info : "",
                    ip: cc.ip && !hide_ips.includes(cc.ip) ? cc.ip : "",
                    name: cc.name ? cc.name : "",
                    room: cc.roomNumber ? cc.roomNumber : "",
                    location: cc.roomTVName ? cc.roomTVName : "",
                    authorized:
                        cc && cc.warning && cc.warning.info && cc.warning.info === "unauthorized"
                            ? langStrings["no"]
                            : langStrings["yes"],
                    connected:
                        cc && cc.warning && cc.warning.info && cc.warning.info === "online"
                            ? langStrings["yes"]
                            : langStrings["no"],
                    lastconnection: cc.lastActivityDate ? new window.ZDate(cc.lastActivityDate).toLocaleString() : "",
                    casting: cc.lastActivity ? cc.lastActivity : "",
                    signal: cc.wifiSignal ? cc.wifiSignal : "",
                })
            );
            setExportData(rows);
            dispatch(showGlobalLoading(false));
            setTimeout(function () {
                document.querySelector("#exportLink").click();
                //remove apollo cache for next downloads
                removeApolloCacheKeys(client.cache, "chromecastsExport");
                setStartDataExport(false);
            }, 200);
        }
        // eslint-disable-next-line
    }, [allExportData.data]);

    return (
        <CSVLink
            id={"exportLink"}
            className="hidden"
            data={exportData}
            headers={exportHeaders}
            filename={exportFileName}
        ></CSVLink>
    );
};

const arrangeFilters = (filters) => {
    let currentFilters = "";
    if (!_.isEmpty(filters)) {
        for (const [key, value] of Object.entries(filters)) {
            if (value) {
                if (value.charAt(0) === "[" || value === "true" || value === "false") {
                    currentFilters += `${key}:${value},`;
                } else {
                    currentFilters += `${key}:"${value}",`;
                }
            }
        }
    }
    return currentFilters;
}

export default withApollo(ExportDevices);
