import { useEffect, useState } from "react";
//API
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
//Redux
import { useDispatch, useSelector } from "react-redux";

import { setNetworks } from "../../../actions/hotelActions";
import { setActionInput } from "../../../actions/globalActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import Portals from "./models/Portals";
import PortalsWifi from "./models/PortalsWifi";

const WifiSettingsConfig = () => {
    const dispatch = useDispatch();
    const { actionData } = useSelector((state) => state.action);
    const { permissions } = useSelector((state) => state.ui);
    const { t } = useTranslation();

    const wifiProductEnabled = permissions && permissions.hotel && permissions.hotel.wifiConfig;

    const [portalsWS, setPortalsWS] = useState([]);
    const [portalsWifi, setPortalsWifi] = useState([]);

    //API Call
    const WIFI_SETTINGS = gql`
        {
            hotelwifi: wifiSettings(kind: "Hotel") {
                results {
                    name
                    password
                    enabled
                }
            }
        }
    `;

    const { data: dataWS, loading: loadingWS, error: errorWS, refetch: refetchWS } = useQuery(WIFI_SETTINGS);

    const arrangeDataWS = () => {
        let tempArray = [];
        // eslint-disable-next-line
        Object.keys(dataWS).forEach((element) => {
            tempArray[element] = { ...dataWS[element] };
        });
        return Portals(tempArray);
    };

    useEffect(() => {
        if (wifiProductEnabled) {
            setPortalsWifi(PortalsWifi(t));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (actionData && actionData.refreshData) {
            refetchWS();
            dispatch(showGlobalLoading(false));
            dispatch(setActionInput({ refreshData: false }));
        }
        // eslint-disable-next-line
    }, [actionData]);

    useEffect(() => {
        if (!loadingWS && !errorWS && dataWS) {
            const portalsDataWS = arrangeDataWS();
            setPortalsWS(portalsDataWS);
            dispatch(setNetworks(portalsDataWS));
        }
        // eslint-disable-next-line
    }, [dataWS]);

    useEffect(() => {
        if (portalsWifi.length > 0 && portalsWS.length > 0) {
            dispatch(setNetworks(false));
            dispatch(setNetworks([portalsWS[0], portalsWifi[0]]));
        }
        // eslint-disable-next-line
    }, [portalsWifi, portalsWS]);
};

export default WifiSettingsConfig;
