import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useDrag, useDrop } from "react-dnd";
import {
    getSymbolCurrency,
    handleTaxRateChange,
    handleValueAfterTaxChange,
    handleValueBeforeTaxChange,
    updatePrice,
} from "../../../../../../../../hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import UseInputText from "../../../../../../../Inputs/useInputText";
import UseSwitch from "../../../../../../../Inputs/useSwitch";
import UseSelect from "../../../../../../../Inputs/useSelect";
import UseCurrencyInput from "../../../../../../../Table/UseCurrencyInput";
import _ from "lodash";

function ExtraGroupItemChoicesEditCard({
    itemNumber,
    choice,
    index,
    moveCard,
    handleDeleteChoice,
    handleMoveChoices,
    extraData,
    updateChoiceData,
}) {
    //Consts&states
    const { id, ref: refKey } = choice;
    const { channel, taxCategories } = extraData;
    const { t } = useTranslation();
    const { values, validations } = useSelector((state) => state.action);
    const [taxRate, setTaxRate] = useState(null);
    const [name, setName] = useState(null);
    const [available, setAvailable] = useState(null);
    const [priceWithoutTax, setPriceWithoutTax] = useState(null);
    const [taxCategory, setTaxCategory] = useState(null);
    const [priceWithTax, setPriceWithTax] = useState(null);
    const [loadInitialData, setLoadInitialData] = useState(true);
    const style = { float: "left" };
    const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
    const sectionName = `product-edit-choice-itemNumber-${itemNumber}`;
    const inputsIds = [
        `${sectionName}-name`,
        `${sectionName}-available`,
        `${sectionName}-without-tax`,
        `${sectionName}-tax-r${index}`,
        `${sectionName}-total`,
    ];

    //queries&mutations

    //effects
    useEffect(() => {
        arrangeData();
        // eslint-disable-next-line
    }, [choice]);

    useEffect(() => {
        if (values) {
            if (values[inputsIds[3]]) {
                const taxCategoriData = _.find(taxCategories, { value: values[inputsIds[3]] });
                if (taxCategoriData) {
                    setTaxCategory(taxCategoriData.value);
                    setTaxRate(taxCategoriData.taxRateValue);
                }
            }
        }
        // eslint-disable-next-line
    }, [values]);

    useEffect(() => {
        if (taxRate > 0) {
            handleTaxRateChange(taxRate, priceWithoutTax, decimalSeparator, setPriceWithTax);
        }
        // eslint-disable-next-line
    }, [taxRate, loadInitialData]);

    useEffect(() => {
        updateChoiceData({
            name: name,
            available: available,
            priceWithoutTax: priceWithoutTax,
            taxCategory: taxCategory,
            priceWithTax: priceWithTax,
            loadInitialData: loadInitialData,
        });
        // eslint-disable-next-line
    }, [name, available, priceWithoutTax, taxCategory, priceWithTax, loadInitialData]);

    //arrangeData
    const arrangeData = () => {
        if (choice) {
            setName(choice.name);
            setAvailable(choice.available);
            setPriceWithoutTax(choice.priceWithoutTax);
            setTaxCategory(choice.taxCategory ? `${choice.taxCategory}` : null);
            setPriceWithTax(choice.priceWithTax);
            if (choice.loadInitialData) {
                setTaxRate(null);
                let taxRate = null;
                if (choice.taxCategory && taxCategories && taxCategories.length > 0) {
                    const taxCategoriData = _.find(taxCategories, { value: choice.taxCategory });
                    if (taxCategoriData) {
                        taxRate = taxCategoriData.taxRateValue;
                    }
                }
                if (choice.priceWithoutTax > 0 && taxRate === null) {
                    updatePrice(choice.priceWithoutTax, setPriceWithTax, setPriceWithoutTax);
                } else if (choice.priceWithoutTax > 0 && taxRate) {
                    updatePrice(choice.priceWithoutTax, null, setPriceWithoutTax);
                    handleTaxRateChange(taxRate, choice.priceWithoutTax, decimalSeparator, setPriceWithTax);
                }
                setTaxRate(taxRate);
                setLoadInitialData(false);
            }
        }
    };

    //handle&functions
    const [{ handlerId }, drop] = useDrop({
        accept: "extraChoiceCard",
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        drop(item) {
            handleMoveChoices(item.index);
        },
        hover(item, monitor) {
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }

            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
    });
    const [{ opacity }, drag, preview] = useDrag({
        type: "extraChoiceCard",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
        end: (item, monitor) => {
            const didDrop = monitor.didDrop();
            if (!didDrop) {
                const dragIndex = item.index;
                const hoverIndex = index;
                moveCard(dragIndex, hoverIndex, true);
                item.index = hoverIndex;
            }
        },
    });

    //renders

    //response
    return (
        <div
            className={`w-full bg-white p-2 border-b-2 border-gray-200`}
            style={{ ...style, opacity }}
            ref={preview}
            data-handler-id={handlerId}
        >
            <div className="w-full h-full" ref={drop}>
                <div className="flex items-center align-middle w-full justify-between">
                    <div className="flex items-center">
                        <span
                            ref={drag}
                            className="icon icon-drag-and-drop text-gray-800 text-3xl mr-4 cursor-move"
                        ></span>
                        <div className="w-96">
                            <UseInputText
                                id={inputsIds[0]}
                                ph="name"
                                value={choice.name}
                                changeAction={(value) => {
                                    setName(value);
                                }}
                                adjust={`${
                                    typeof validations[inputsIds[0]] !== "undefined" && !validations[inputsIds[0]]
                                        ? "border border-red-100"
                                        : ""
                                }`}
                            />
                        </div>
                    </div>
                    <div className="flex">
                        <div className="flex">
                            <span className="">{t("{{capitalize, capitalize}}", { capitalize: t("available") })}</span>
                            <UseSwitch
                                id={inputsIds[1]}
                                checked={choice.available}
                                action={(value) => {
                                    setAvailable(value);
                                }}
                            />
                        </div>
                        <span
                            className="icon icon-delete text-xl text-gray-800 cursor-pointer z-10 ml-3"
                            onClick={() => {
                                handleDeleteChoice();
                            }}
                        ></span>
                    </div>
                </div>
                <div className="w-full pl-11">
                    <span className="font-bold text-lg block my-3">
                        {t("{{capitalize, capitalize}}", { capitalize: t("price") })}
                    </span>
                    <div className="w-full flex space-x-16">
                        <div className="">
                            <span className="block pb-3">
                                {t("{{capitalize, capitalize}}", { capitalize: t("without-tax") })}
                            </span>
                            <div className="w-28 z-10">
                                <UseCurrencyInput
                                    inputData={{ name: inputsIds[2] }}
                                    id={inputsIds[2]}
                                    validation={true}
                                    value={choice.priceWithoutTax}
                                    onChange={(values) =>
                                        handleValueBeforeTaxChange(
                                            values,
                                            taxRate,
                                            decimalSeparator,
                                            setPriceWithoutTax,
                                            setPriceWithTax
                                        )
                                    }
                                    currencyCode={channel?.currencyCode}
                                />
                            </div>
                        </div>
                        <div className="">
                            <span className="block pb-2">
                                {t("{{capitalize, capitalize}}", { capitalize: t("tax") })}
                            </span>
                            <div className="w-52 z-10 cursor-pointer">
                                <UseSelect
                                    selectData={{
                                        id: inputsIds[3],
                                        name: "tax",
                                        placeHolder: "tax",
                                        data: taxCategories ? taxCategories : [],
                                        noTranslateAV: true,
                                        containerOptionsAdjust: "overflow-y-auto max-h-20",
                                        activeValue: choice.taxCategory,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="">
                            <span className="block pb-3">
                                {t("{{capitalize, capitalize}}", { capitalize: t("total") })}
                            </span>
                            <div className="w-28 z-10 cursor-pointer">
                                <UseCurrencyInput
                                    id={inputsIds[4]}
                                    inputData={{ name: inputsIds[4] }}
                                    validation={true}
                                    value={choice.priceWithTax}
                                    onChange={(values) =>
                                        handleValueAfterTaxChange(
                                            values,
                                            taxRate,
                                            decimalSeparator,
                                            setPriceWithoutTax,
                                            setPriceWithTax
                                        )
                                    }
                                    currencyCode={channel?.currencyCode}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ExtraGroupItemChoicesEditCard;
