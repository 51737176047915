import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import ReactInputMask from "react-input-mask";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { changeActionValues } from "actions/globalActions";
import { useSelector } from "react-redux";

const UseCustomPhoneInput = ({
    value,
    actionValues = false,

    fieldActionValues = "phone",
    onChange,
    height = null,
    id = null,
}) => {
    const [phone, setPhone] = useState("");
    const [dialCode, setDialCode] = useState(null);
    const [finalPhone, setFinalPhone] = useState("");
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [flagDropdownOpen, setFlagDropdownOpen] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (actionValues) {
            dispatch(
                changeActionValues({
                    [fieldActionValues]: `${finalPhone}`,
                })
            );
        }

        if (typeof onChange === "function") {
            onChange(finalPhone);
        }
        // eslint-disable-next-line
    }, [finalPhone]);

    useEffect(() => {
        assignIdsCustomPhoneInput(id);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (value) {
            setFinalPhone(value);
            setPhone(value.split(" ")[1]);
            setDialCode(value.split(" ")[0]);
        } else {
            setDialCode("+34");
            setPhone("");
            setSelectedCountry("es");
        }
        // eslint-disable-next-line
    }, [value]);

    useEffect(() => {
        resetInitialStyles(id);
        const cleanupObserver = observeFlagDropdown(setFlagDropdownOpen, id);
        return cleanupObserver;
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        customFlagContainerStyles({ dialCode, flagDropdownOpen, id });
        // eslint-disable-next-line
    }, [dialCode, flagDropdownOpen]);

    useEffect(() => {
        if (phone) {
            setFinalPhone(`${dialCode} ${phone}`);
        } else {
            setFinalPhone(``);
        }
        // eslint-disable-next-line
    }, [phone, dialCode]);

    const handleCountryChange = (value, countryData) => {
        const fullDialCode = `+${countryData.dialCode}`;
        setDialCode(fullDialCode);
        setSelectedCountry(countryData.countryCode);
        setPhone("");
    };

    return (
        <div
            id={`phone-input-container${id ? "-" + id : ""}`}
            className=" flex"
            style={{ height: height ? height : null }}
        >
            <div
                className=" relative bg-gray-200 flag-selector
                     rounded  "
                style={{ width: "10rem" }}
            >
                <PhoneInput
                    country={selectedCountry}
                    enableSearch={true}
                    value={dialCode}
                    inputProps={{
                        id: `custom-phone-input-selector-flag${id ? "-" + id : ""}`,
                    }}
                    onChange={handleCountryChange}
                    inputClass="w-full bg-gray-200 rounded pl-3 p-2"
                    inputStyle={{
                        display: "none",
                    }}
                    containerStyle={{
                        display: "flex",
                        alignItems: "center",
                        height: "35px",
                        width: "100%",
                        position: "absolute",
                        justifyContent: "center",
                    }}
                    buttonStyle={{
                        background: "transparent",
                        border: "none",
                    }}
                />
            </div>
            <div className={`w-full input-react-tel-input${id ? "-" + id : ""} ml-4`}>
                <PhoneInput
                    country={selectedCountry}
                    inputProps={{
                        disableDropdown: true,
                        id: "custom-phone-input-number",
                    }}
                    onChange={(value) => {
                        setPhone(value);
                    }}
                    value={phone}
                    alwaysDefaultMask={true}
                    disableDropdown={true}
                    disableCountryCode={true}
                    placeholder="000 00 00 00"
                    inputClass="w-full bg-gray-200 rounded pl-3 p-2"
                    inputStyle={{
                        width: "100%",
                        background: "#E9EDF0",
                        border: "none",
                        paddingLeft: "10px",
                        height: height ? height : null,
                    }}
                    buttonStyle={{
                        display: "none",
                    }}
                />
            </div>
        </div>
    );
};

export default UseCustomPhoneInput;

const observeFlagDropdown = (setFlagDropdownOpen, id) => {
    const flagContainer = document.querySelector(
        ` #phone-input-container${id ? "-" + id : ""} .react-tel-input .selected-flag`
    );

    if (!flagContainer) return;

    const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === "class") {
                const isOpen = flagContainer.classList.contains("open");
                customCountrySelector(id);
                setFlagDropdownOpen(isOpen);
            }
        });
    });

    observer.observe(flagContainer, { attributes: true });

    return () => observer.disconnect();
};

const resetInitialStyles = (id) => {
    const flag = document.querySelector(
        `#phone-input-container${id ? "-" + id : ""} .react-tel-input .selected-flag .flag`
    );
    const arrow = document.querySelector(
        `#phone-input-container${id ? "-" + id : ""} .react-tel-input .selected-flag .flag .arrow`
    );

    if (flag) {
        flag.style.position = "relative";
        flag.style.top = "0";
        flag.style.marginTop = "0";
    }

    if (arrow) {
        arrow.style.display = "none";
    }
};

const customFlagContainerStyles = ({ dialCode, flagDropdownOpen, id }) => {
    const flagContainer = document.querySelector(
        `#phone-input-container${id ? "-" + id : ""} .react-tel-input .selected-flag`
    );

    if (!flagContainer) {
        return;
    }

    flagContainer.style.display = "flex";
    flagContainer.style.width = "100%";
    flagContainer.style.alignItems = "center";
    flagContainer.style.justifyContent = "center";
    flagContainer.style.backgroundColor = "rgba(233, 237, 240, 1)";

    if (flagContainer) {
        const existingDiv = flagContainer.querySelector(".custom-dial-code");
        const existingChevron = flagContainer.querySelector(`.arrow-custom`);
        if (existingDiv) {
            existingDiv.remove();
        }

        if (existingChevron) {
            existingChevron.remove();
        }
        flagContainer.insertAdjacentHTML(
            "beforeend",
            `<div class="custom-dial-code" style="margin-left: 10px;" >${dialCode}</div>`
        );

        flagContainer.insertAdjacentHTML(
            "beforeend",
            `<div class=" icon arrow-custom icon-chevron${flagDropdownOpen ? "-up" : ""} ml-2 text-sm" ></div>`
        );
    }
};

const customCountrySelector = (id) => {
    const countrySelector = document.querySelector(
        `#phone-input-container${id ? "-" + id : ""} .react-tel-input .country-list`
    );
    const countrySelectorSearch = document.querySelector(
        `#phone-input-container${id ? "-" + id : ""} .react-tel-input .country-list .search`
    );
    const countrySelectorSearchIcon = document.querySelector(
        `#phone-input-container${id ? "-" + id : ""} .react-tel-input .country-list .search .search-emoji`
    );
    const countrySelectorSearchInput = document.querySelector(
        `#phone-input-container${id ? "-" + id : ""} .react-tel-input .country-list .search .search-box`
    );

    if (countrySelector && countrySelectorSearchIcon && countrySelectorSearchInput && countrySelectorSearch) {
        countrySelectorSearch.style.position = "sticky";
        countrySelectorSearch.style.top = "0";
        countrySelectorSearch.style.left = "0";

        countrySelector.style.borderRadius = "0.25rem";
        countrySelector.style.fontFamily = "Lato";
        countrySelector.style.fontSize = "1rem";

        countrySelectorSearchIcon.textContent = "";

        countrySelectorSearchInput.id = "custom-phone-input-search-country";
        countrySelectorSearchInput.style.width = "93%";
        countrySelectorSearchInput.style.border = "none";
        countrySelectorSearchInput.style.padding = "0.5rem";
        countrySelectorSearchInput.style.fontFamily = "Lato";
        countrySelectorSearchInput.classList.add("text-gray-900");
        countrySelectorSearchInput.classList.add("bg-gray-200");
        countrySelectorSearchInput.placeholder = i18next.t("search");

        countrySelectorSearchIcon.classList.add("icon");
        countrySelectorSearchIcon.classList.add("icon-search");
        countrySelectorSearchIcon.style.position = "absolute";
        countrySelectorSearchIcon.style.right = "2rem";
        countrySelectorSearchIcon.style.top = "1.25rem";

        let countries = countrySelector.querySelectorAll(".country");
        countries.forEach((country, index) => {
            country.id = `country-${index}`;
        });
    }
};

const assignIdsCustomPhoneInput = (id) => {
    const phoneInputContainer = document.querySelector(
        `#phone-input-container${id ? "-" + id : ""} .flag-selector .react-tel-input`
    );
    const phoneInputNumber = document.querySelector(
        `#phone-input-container${id ? "-" + id : ""} .input-react-tel-input .react-tel-input`
    );

    if (phoneInputNumber) {
        const selectedPhoneInputNumber = phoneInputNumber.querySelector(".form-control");
        if (selectedPhoneInputNumber) {
            selectedPhoneInputNumber.id = `custom-phone-input-number${id ? "-" + id : ""}`;
        }
    }

    if (phoneInputContainer) {
        phoneInputContainer.id = "custom-react-tel-input";

        const selectedFlagElement = phoneInputContainer.querySelector(".selected-flag");
        if (selectedFlagElement) {
            selectedFlagElement.id = `custom-selected-flag${id ? "-" + id : ""}`;
        }

        const flagDropdownElement = phoneInputContainer.querySelector(".flag-dropdown");
        if (flagDropdownElement) {
            flagDropdownElement.id = `custom-flag-dropdown${id ? "-" + id : ""}`;
        }
    }
};
