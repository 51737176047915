import React from "react";
import LibTooltip from "react-tooltip";

/// Wrap ReactTooltip with default values and html always to false
const ReactTooltip = (props) => {
    const config = {
        delayShow: 250,
        type: "light",
        backgroundColor: "#fff",
        borderColor: "#BDC8D3", // text-gray-400
        arrowColor: "#fff",
        className: "font-normal",
        multiline: true,
        border: true,
        wrapper: "span",
        offset: { top: -8, left: -8 },
        ...props,
        html: props?.dangerousHtml, // Inject raw HTML? (This is a security risk)
    };
    return <LibTooltip {...config} />;
};

ReactTooltip.rebuild = LibTooltip.rebuild;
ReactTooltip.show = LibTooltip.show;
ReactTooltip.hide = LibTooltip.hide;

export default ReactTooltip;
