import React, { useState, useEffect, forwardRef, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeActionValues } from "../actions/globalActions";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import ReactInputMask from "react-input-mask";

const UseInputDate = ({ data }) => {
    const { t } = useTranslation();
    const {
        name,
        placeholder,
        date,
        empty,
        disabled,
        styleAdjust,
        fullwidth,
        onChange,
        validation,
        nonInitialDate,
        noCapitalizePlaceholder,
        id,
        minDate,
    } = data;
    const { validations } = useSelector((state) => state.action);
    const { lang } = useSelector((state) => state.ui);
    const idContainer = id ? `${id}-date-picker-container` : null;

    const [valueDate, setValueDate] = useState(empty ? null : date);

    useEffect(() => {
        if (nonInitialDate) {
            setValueDate(date);
        }
    }, [date]);

    const dispatch = useDispatch();
    registerLocale("es", es);

    const padFix = (n) => {
        return ("00" + n).match(/\d{2}$/);
    };

    const formatDate = (date, format) => {
        switch (format) {
            case "date":
                return date.getFullYear() + "-" + padFix(date.getMonth() + 1) + "-" + padFix(date.getDate());
            case "time":
                return padFix(date.getHours()) + ":" + padFix(date.getMinutes());
            default:
                return date;
        }
    };

    const onDatePickerSet = (date) => {
        if (date) {
            const date_value = formatDate(date, "date");
            setValueDate(date_value);
            dispatch(changeActionValues({ [name]: date_value }));
            if (typeof onChange === "function") {
                onChange(date_value);
            }
        } else {
            setValueDate(null);
            dispatch(changeActionValues({ [name]: null }));
            if (typeof onChange === "function") {
                onChange(null);
            }
        }
    };

    //Listeners
    useEffect(() => {
        if (!empty) {
            dispatch(changeActionValues({ [name]: date }));
        }
        setTimeout(() => {
            assignIdToCloseIconButton(`#${idContainer}`, id);
        }, 500);
        // eslint-disable-next-line
    }, []);

    const DateInput = forwardRef(({ value, onClick, onFocus, onChange }, ref) => (
        <div className=" relative">
            <ReactInputMask
                mask={"99/99/9999"}
                required
                id={id ? id : "select-date"}
                className={`w-full pl-10  bg-gray-200 rounded  p-2 ${styleAdjust} ${
                    (typeof validations[name] !== "undefined" && !validations[name]) || validation === false
                        ? "border border-red-100"
                        : ""
                }  ${disabled ? "bg-gray-300" : ""}`}
                onClick={onClick}
                onFocus={onFocus}
                onChange={onChange}
                value={value}
                ref={ref}
                tabIndex={0}
                placeholder={
                    noCapitalizePlaceholder
                        ? t(placeholder ? placeholder : "select-date")
                        : t("{{capitalize, capitalize}}", {
                              capitalize: placeholder ? t(placeholder) : t("select-date"),
                          })
                }
            />
            <i
                style={{
                    top: "50%",
                    transform: "translateY(-50%)",
                }}
                className="icon absolute top-5 left-5 icon-booking mr-2 font-black text-gray-700"
            ></i>
        </div>
    ));

    const isABirthdateInput = name === "birthDate";

    return (
        <div id={idContainer} className={`datepicker ${fullwidth ? " w-full" : ""}`}>
            <DatePicker
                id={`${id ?? "date-picker"}`}
                selected={valueDate ? new Date(new window.ZDate(valueDate).toDate()) : null}
                onChange={onDatePickerSet}
                dateFormat="dd/MM/yyyy"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                placeholderText={placeholder}
                disabled={disabled ? true : false}
                isClearable={!disabled}
                customInput={<DateInput />}
                locale={lang}
                maxDate={isABirthdateInput ? new Date() : null}
                minDate={typeof minDate === "function" ? minDate() : null}
            />
        </div>
    );
};

export default UseInputDate;

const assignIdToCloseIconButton = (selectorIdContainer, id) => {
    const selectorButton = document.querySelector(`${selectorIdContainer} .react-datepicker__close-icon`);
    if (selectorButton && id) {
        selectorButton.id = `${id}-delete-date-button`;
    }
};
