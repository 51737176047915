export const GetIconColor = (type, value, warning) => {
    value = parseInt(value);
    switch (type) {
        case "tv":
        case "chromecast":
        case "room":
            if (value === 0) {
                if (type === "tv") {
                    return "fail";
                }
                return "";
            } else if (value === 1) {
                return "fail";
            } else if (value === 2) {
                return "warning";
            } else if (value === 3) {
                return "disabled";
            } else if (value === 4) {
                return "success";
            } else {
                return "";
            }
        case "stb":
            if (value === 1) {
                return "fail";
            } else if (value === 4) {
                return "success";
            } else {
                return "fail";
            }
        default:
            return "";
    }
};

export default GetIconColor;
