import React, { useContext, useEffect, useState } from "react";
import { Session } from "../../../../hooks/Utils/Session";
import UseSectionHeader from "../../../useSectionHeader";
import { executeVendureQuery, getVendureApiData } from "../../../../hooks/Utils/Integrations/useVendure";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { cleanTableStore, setLoading } from "../../../../actions/tableActions";
import { useDispatch, useSelector } from "react-redux";
import ListCategories from "../../../../hooks/GraphqlCalls/Sales/ListCategories";
import UseTable from "../../../Table";
import { useParams } from "react-router-dom";
import UpdateListCategories from "../../../../hooks/GraphqlCalls/Sales/UpdateListCategories";
import InformationCard from "./components/EditShopCategories/InformationCard";
import ShopSettingsImage from "./components/ShopSettings/ShopSettingsImage";
import { setRefreshContentData, setExecuteQuery } from "../../../../actions/sectionActions";
import { setColorPickerReference } from "../../../../actions/designActions";
import UseLoading from "../../../Table/useLoading";
import UseSwitch from "../../../Inputs/useSwitch";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import ProductsCard from "./components/EditShopCategories/ProductsCard";
import { parseVendureTranslation } from "../../../../hooks/Utils/SalesUtils";
import { getProjectLangs } from "hooks/Utils/SalesUtils";
import ShopHeadMenu from "./ShopHeadMenu";
import { SalesContextDeprecated } from "contexts/Sales";

const EditShopCategories = () => {
    const { refreshData } = useSelector((state) => state.sectionContent);

    const dispatch = useDispatch();
    const tokenShop = Session.getSessionProp("tokenShop");
    const { projectLangs } = useSelector((state) => state.ui);
    const { data: dataContext } = useContext(SalesContextDeprecated);
    const { id, idCat } = useParams();
    const [infoCategory, setInfoCategory] = useState(null);
    const [data, setData] = useState(null);
    const [availableCategory, setAvailableCategory] = useState(false);
    const [nameHeader, setNameHeader] = useState("");
    const [facetCategory, setFacetCategory] = useState(null);
    const [parentsFacets, setFacetsParents] = useState([]);
    const [handlerLoading, setHandlerLoading] = useState(true);
    const [collectionParentData, setCollectionParentData] = useState([]);
    const [breadCrumbs, setBreadCrumbs] = useState([
        {
            name: "categories",
            translate: false,
            route: `/services/sales/shop/categories/${id}`,
        },
    ]);
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(cleanTableStore());
        // eslint-disable-next-line
    }, []);

    const fetchParentsInfo = async (data) => {
        const response = await executeVendureQuery(getVendureApiData(), {
            queryBody: getParentsInfoQuery({
                excludeId: data?.excludeId,
                collectionBreadcrumbs: data?.collectionBreadcrumbs,
            }),
            "vendure-token": tokenShop,
        });

        if (response && response.data && !response.errors) {
            const idsCollections = filterCollectionIds({
                excludeId: data?.excludeId,
                collectionBreadcrumbs: data?.collectionBreadcrumbs,
            });
            const langDefault = getProjectLangs(projectLangs, t).langDefault;

            const currentCollection = data?.currentCollection;
            const parentFacet = getFacetValueCollection(currentCollection);
            const parentsIds = filterCollectionIds({
                collectionBreadcrumbs: currentCollection.breadcrumbs,
                excludeId: idCat,
            });

            const enabledCollection = currentCollection?.customFields?.enabled ? true : false;
            const facetsCollection = [];
            const parentCollectionInfo = [];
            const collectionParentData = [];
            const canHaveChildren = currentCollection?.customFields?.can_have_children ? true : false;
            const depth = calculateCollectionDepth({
                excludeId: data?.excludeId,
                collectionBreadcrumbs: data?.collectionBreadcrumbs,
            });
            const rootCollection = currentCollection?.breadcrumbs?.find((el) => el.name === "__root_collection__");
            const transLationName = parseVendureTranslation(currentCollection.translations, langDefault);
            const dataCollection = response.data;

            idsCollections.forEach((id) => {
                if (dataCollection[`collection${id}`]) {
                    parentCollectionInfo.push({
                        id: id,
                        translationName: parseVendureTranslation(
                            dataCollection[`collection${id}`].translations,
                            langDefault
                        ),
                        slug: dataCollection[`collection${id}`].slug,
                        name: dataCollection[`collection${id}`].name,
                    });
                    facetsCollection.push(getFacetValueCollection(dataCollection[`collection${id}`]));
                    collectionParentData.push(dataCollection[`collection${id}`]);
                }
            });
            setInfoCategory({
                value: enabledCollection,
                canHaveChildren: canHaveChildren,
                depth: depth,
                transLationName: transLationName,
                parents: parentCollectionInfo,
                id: currentCollection.id,
                rootCollection: rootCollection,
                parentsIds: parentsIds,
            });

            if (parentFacet) {
                facetsCollection.push(parentFacet);
            }

            if (currentCollection) {
                collectionParentData.push({
                    id: currentCollection.id,
                    filters: currentCollection.filters,
                    translations: currentCollection.translations,
                    name: currentCollection.name,
                    slug: currentCollection.slug,
                });
            }
            setCollectionParentData(collectionParentData);
            setFacetsParents(facetsCollection);
        } else {
            toast.error(t("mutation-error"));
        }
    };

    useEffect(() => {
        let timerId;

        if (dataContext?.collection) {
            setData(dataContext?.collection);

            const {
                transLationName,
                currentCollection,
                enabledCollection,
                parentCollectionIds,
                parentFacet,
                canHaveChildren,
                rootCollection,
            } = arrangeCollection({ data: dataContext, idCat: idCat, projectLangs: projectLangs, t: t });

            const parentsIds = filterCollectionIds({
                collectionBreadcrumbs: currentCollection.breadcrumbs,
                excludeId: idCat,
            });

            const collectionParentData = [];

            setNameHeader(transLationName);
            setAvailableCategory(enabledCollection);
            setFacetCategory(parentFacet);
            if (parentCollectionIds?.length > 0) {
                fetchParentsInfo({
                    currentCollection: dataContext.collection,
                    excludeId: dataContext.collection.id,
                    collectionBreadcrumbs: dataContext.collection.breadcrumbs,
                });
            } else {
                collectionParentData.push({
                    id: currentCollection.id,
                    filters: currentCollection.filters,
                    translations: currentCollection.translations,
                    name: currentCollection.name,
                    slug: currentCollection.slug,
                });
                setCollectionParentData(collectionParentData);
                setFacetsParents([parentFacet]);
                setInfoCategory({
                    canHaveChildren: canHaveChildren,
                    depth: 1,
                    parents: [],
                    rootCollection: rootCollection,
                    transLationName: transLationName,
                    value: enabledCollection,
                    id: dataContext.collection.id,
                    parentsIds: parentsIds,
                });
            }
            timerId = setTimeout(() => {
                setHandlerLoading(false);
            }, 2000);
        }

        return () => {
            if (timerId) {
                clearTimeout(timerId);
            }
        };
    }, [dataContext]);

    const buildBreadcrumbs = (infoCategory, idShop) => {
        if (infoCategory.parents instanceof Array) {
            const breadCrum = [
                {
                    name: capitalizeFirst(t("categories")),
                    translate: false,
                    route: `/services/sales/shop/categories/${id}`,
                },
            ];
            infoCategory.parents.forEach((item) => {
                breadCrum.push({
                    name: item?.translationName,
                    translate: false,
                    route: `/services/sales/shop/categories/${idShop}/edit-category/${item.id}`,
                });
            });
            return breadCrum;
        }
    };

    useEffect(() => {
        if (refreshData) {
            dispatch(setRefreshContentData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (infoCategory?.parents) {
            setBreadCrumbs(buildBreadcrumbs(infoCategory, id));
        }
        // eslint-disable-next-line
    }, [infoCategory]);

    useEffect(() => {
        setHandlerLoading(true);
        // eslint-disable-next-line
    }, [idCat]);

    return (
        <div className=" relative">
            <ShopHeadMenu />
            <UseSectionHeader
                navToPreviousPage={true}
                customBreadCrumbs={breadCrumbs}
                title={nameHeader}
                noCapitalize={true}
            />
            <div className=" absolute flex mt-10 top-0 right-0">
                <span style={{ marginTop: "-0.125rem" }}>{capitalizeFirst(t("available"))}</span>
                <UseSwitch
                    id={"edit-available-state-category-switch"}
                    checked={availableCategory}
                    action={() => {
                        dispatch(
                            setExecuteQuery({
                                action: "set-state-category",
                                params: {
                                    active: !availableCategory,
                                    id: idCat,
                                },
                            })
                        );
                        setAvailableCategory(!availableCategory);
                    }}
                />
            </div>
            <div className=" flex mb-8 w-full ">
                <div className=" w-1/2 mr-4">
                    <InformationCard data={data} nameHeader={nameHeader} setNameHeader={setNameHeader} />
                </div>
                <div className=" w-1/2 ml-4">
                    {infoCategory?.depth === 1 ? <ShopSettingsImage data={data} isCategory={true} /> : null}
                </div>
            </div>
            <div
                className={`bg-white rounded shadow-sm pt-6 relative ${
                    infoCategory && infoCategory.canHaveChildren ? "" : "hidden"
                } `}
                onClick={() => {
                    dispatch(setColorPickerReference(null));
                }}
            >
                <div className=" flex flex-col z-200 px-6 ">
                    <span className=" text-xl font-bold"> {t("subcategories")} </span>
                    <span className=" mt-4"> {t("add-subcategories-you-want-to-be-in-this-category")} </span>
                </div>
                <UseTable />
            </div>
            <div
                className={`bg-white rounded shadow-sm relative ${
                    infoCategory && infoCategory.canHaveChildren ? "hidden" : ""
                } `}
                onClick={() => {
                    dispatch(setColorPickerReference(null));
                }}
            >
                {infoCategory?.canHaveChildren === false ? (
                    <ProductsCard
                        infoCategory={infoCategory}
                        facetCategory={facetCategory}
                        parentsFacets={parentsFacets}
                        collectionParentData={collectionParentData}
                    />
                ) : null}
            </div>
            {handlerLoading ? (
                <div className=" absolute flex items-center justify-center top-0 left-0 w-full h-full bg-white z-500 rounded ">
                    <UseLoading adjust={"contents"} />
                </div>
            ) : null}
            <UpdateListCategories />
            {infoCategory?.canHaveChildren ? (
                <ListCategories
                    idCategory={idCat}
                    levelCategory={infoCategory?.depth}
                    availableCategory={availableCategory}
                />
            ) : null}
        </div>
    );
};

export default EditShopCategories;

const getParentsInfoQuery = ({ collectionBreadcrumbs, excludeId }) => {
    const filteredCollections = collectionBreadcrumbs
        .filter((item) => item.name !== "__root_collection__" && item.id !== excludeId)
        .map(
            (item) => `    collection${item.id}: collection(id: "${item.id}") {
                id
                slug
                name
                translations {
                    languageCode
                    name
                    slug
                    id
                }
                filters {
                    args {
                      name
                      value
                    }
                }
            }`
        );

    if (filteredCollections.length === 0) {
        return null;
    }

    return `query {\n${filteredCollections.join("\n")}\n}`;
};

const calculateCollectionDepth = ({ collectionBreadcrumbs, excludeId }) => {
    return collectionBreadcrumbs.filter((item) => item.name !== "__root_collection__" && item.id !== excludeId).length;
};

const filterCollectionIds = ({ collectionBreadcrumbs, excludeId }) => {
    return collectionBreadcrumbs
        .filter((collection) => collection.id !== excludeId && collection.name !== "__root_collection__")
        .map((collection) => collection.id);
};

const getFacetValueCollection = (data) => {
    const facetValueIdObj = data.filters.flatMap((filter) => filter.args).find((arg) => arg.name === "facetValueIds");

    if (facetValueIdObj) {
        return JSON.parse(facetValueIdObj.value).join(", ");
    }

    return "";
};

const arrangeCollection = ({ data, idCat, projectLangs, t }) => {
    const currentCollection = data.collection;

    const parentCollectionIds = filterCollectionIds({
        excludeId: data.collection.id,
        collectionBreadcrumbs: data.collection.breadcrumbs,
    });

    const parentsIds = filterCollectionIds({
        collectionBreadcrumbs: currentCollection.breadcrumbs,
        excludeId: idCat,
    });

    const enabledCollection = currentCollection?.customFields?.enabled ? true : false;
    const canHaveChildren = currentCollection?.customFields?.can_have_children ? true : false;
    const rootCollection = currentCollection?.breadcrumbs?.find((el) => el.name === "__root_collection__");

    const langDefault = getProjectLangs(projectLangs, t).langDefault;
    const transLationName = parseVendureTranslation(currentCollection.translations, langDefault);
    const parentFacet = getFacetValueCollection(currentCollection);

    return {
        transLationName,
        enabledCollection,
        parentFacet,
        parentCollectionIds,
        currentCollection,
        canHaveChildren,
        rootCollection,
        parentsIds,
    };

    // console.log(da);
};
