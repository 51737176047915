import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import UseTitle from "components/useTitle";
import ZTable from "components/ZTable";
import Loading from "components/Loading";
import { formatDate } from "../Monitor/services/formatDate";
import { useGetCCsGuestNetworkFilterInfo } from "hooks/GraphqlCalls/Hotel/Monitoring/useGetCCsGuestNetworkFilterInfo";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { useGetCCguestNetwork } from "hooks/GraphqlCalls/Hotel/Monitoring/useGetCCguestNetwork";
import { UserNetworkContext } from "contexts/NetworkWarnings/UserNetwork";
import ReactTooltip from "components/ReactTooltip";

export const ChromeCastWarnings = () => {
    const [open, setOpen] = useState(false);
    const { tableLength } = useGetCCguestNetwork();
    const { t } = useTranslation();

    return (
        <div className="flex items-center mr-1">
            <i className="icon icon-warning warning mr-1 text-2xl"></i>
            <p>
                {t("user-network")}:{" "}
                <span className="text-blue-300 font-bold cursor-pointer" onClick={() => setOpen(true)}>
                    {tableLength} {t("Chromecasts")}
                </span>
            </p>
            {open ? (
                <div className="fixed w-full h-full left-0 top-0 items-center flex flex-wrap justify-center overflow-y-scroll z-203">
                    <div className="modal-background" onClick={() => setOpen(false)}></div>
                    <div className="modal-body mt-4 mb-4 w-full h-1/2 m-0 relative">
                        <div className="p-10">
                            <ChromeCastUserNetwork t={t} />
                        </div>
                        <div className="py-8 text-center flex justify-end mr-10">
                            <button className="btn-blue" onClick={() => setOpen(false)}>
                                {capitalizeFirst(t("close"))}
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const ChromeCastUserNetwork = ({ t }) => {
    const { serverError } = useSelector((state) => state.table);
    const { updatedData, isPending, update, updatedFilter } = useContext(UserNetworkContext);
    const { ccsGuestNetworkFilterInfo } = useGetCCsGuestNetworkFilterInfo();

    return (
        <>
            <UseTitle text={t("ccs-guest-network")} />
            {!isPending ? (
                <>
                    <div className="flex items-center gap-3 absolute right-10">
                        <p>
                            {capitalizeFirst(t("last-update"))}: {formatDate(updatedData?.lastUpdate)}
                        </p>
                        <button
                            className={`px-2  ${
                                serverError ? "btn-gray" : "btn-white text-zafiro-800 font-bold btn-blue-outline"
                            } py-1 rounded first-capital `}
                            onClick={update}
                            disabled={serverError}
                            data-for={serverError ? "server_error_tooltip" : "update_tooltip"}
                            data-tip
                        >
                            {capitalizeFirst(t("update"))}
                        </button>
                        <ReactTooltip
                            id={serverError ? "server_error_tooltip" : "update_tooltip"}
                            delayShow={500}
                            type="light"
                        >
                            {serverError ? t("non-response-server") : t("take-few-minutes")}
                        </ReactTooltip>
                    </div>
                    {ccsGuestNetworkFilterInfo ? (
                        <ZTable
                            search={true}
                            cols={["name", "deviceModel", "tvLocation", "ip", "ownership"]}
                            scrollable={true}
                            availableFilters={true}
                            filterData={updatedFilter ?? ccsGuestNetworkFilterInfo}
                            header={[
                                {
                                    name: t("name"),
                                    sort: true,
                                    icon: "icon-table-sort",
                                },
                                {
                                    deviceModel: t("device-model"),
                                    sort: true,
                                    icon: "icon-table-sort",
                                },
                                {
                                    tvLocation: t("tv-locations"),
                                    sort: true,
                                    icon: "icon-table-sort",
                                },
                                {
                                    ip: t("ip"),
                                    sort: false,
                                },
                                {
                                    ownership: t("ownership"),
                                    sort: true,
                                    icon: "icon-table-sort",
                                },
                            ]}
                            rows={updatedData?.tableContent}
                            showCount={false}
                        ></ZTable>
                    ) : (
                        <div className="flex flex-col justify-center items-center gap-20 mt-16">
                            <Loading adjust={"block"} />
                        </div>
                    )}
                </>
            ) : (
                <div className="flex flex-col justify-center items-center gap-20 mt-16">
                    <p className="font-bold text-gray-800">{t("take-few-minutes")}</p>
                    <Loading adjust={"block"} />
                </div>
            )}
        </>
    );
};
