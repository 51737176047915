const SyncChromecasts = () => {
    let modalInputs = [
        {
            text: ["sync-casting-gateway-text"],
            type: "void",
        },
    ];
    let modal = {
        title: "sync-casting-gateway",
        bodyAdjust: "w-4/12",
        inputs: modalInputs,
        executeSectionQuery: true,
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "accept",
                style: "blue",
                action: "sync-chromecasts",
            },
        ],
    };
    return modal;
};

export default SyncChromecasts;
