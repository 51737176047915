import React from "react";
import { useParams } from "react-router-dom";
//Components

//Utils
import PreviewRoom from "../../../../hooks/GraphqlCalls/Hotel/Rooms/RoomPreview";

const RoomPreview = () => {
    const { roomtvid, lang } = useParams();

    const currentLangManager = localStorage.getItem("lang");

    const isALangParameter = lang ? lang : currentLangManager;

    PreviewRoom(roomtvid, isALangParameter);
    return (
        <div className="w-full h-full bg-gray-100">
            <iframe
                width="1280"
                height="720"
                id="roomPreviewFrame"
                title="roomPreviewFrame"
                className=" mx-auto overflow-hidden"
            ></iframe>
        </div>
    );
};

export default RoomPreview;
