import { UPCOMING_GUESTS_STATUS } from "hooks/Utils/Customers/GuestsUtils";
import i18n from "i18n";
const ParseDate = (date) => {
    return date
        ? `${date.toLocaleDateString([], {
              year: "2-digit",
              month: "2-digit",
              day: "2-digit",
          })}`
        : `-`;
};

const Guest = (user, folderName) => {
    const {
        id,
        email,
        name,
        surname,
        loyaltyRef,
        guestGroups,
        rooms,
        code,
        guests,
        state,
        numberOfGuests,
        numberOfRooms,
        arrivalDate,
        departureDate,
        pendingChangesSend,
    } = user;
    const dataType = `guest-${folderName}`;

    let groups = "";
    if (guestGroups?.length > 0) {
        guestGroups.map((group) => (groups += groups ? ` - ${group.name}` : group.name));
    }

    let guestrooms = [];
    if (rooms?.length > 0) {
        rooms.map((room) =>
            guestrooms.push({
                name: room.number,
                link: `/hotel/rooms/room/detail/${room.roomID}`,
            })
        );
    }

    guestrooms.sort(function (a, b) {
        return a.name - b.name;
    });

    let from,
        to = null;
    if (rooms?.length > 0) {
        rooms.map((room) =>
            room.stayFrom && (!from || new window.ZDate(room.stayFrom) < from)
                ? (from = new window.ZDate(room.stayFrom))
                : null
        );
        rooms.map((room) =>
            room.stayTo && (!to || new window.ZDate(room.stayTo) > to) ? (to = new window.ZDate(room.stayTo)) : null
        );
    }

    let main_guest = guests?.filter((guest) => guest.guestRole === "MAIN")[0];

    const info =
        folderName === "currents"
            ? [
                  {
                      name: "name",
                      cellContent: "link",
                      value: `${name} ${surname}`,
                      icon: loyaltyRef ? "loyal-customers" : null,
                      iconAdjust: "text-lg",
                      iconTooltip: "loyal-guest",
                      link: `/customers/guests/guest-list/guest/${id}`,
                      specialWidth: "w-3/12",
                  },
                  {
                      name: "email",
                      cellContent: "text",
                      value: email,
                      specialWidth: "w-3/12",
                      adjust: "truncate",
                  },
                  {
                      name: "groups",
                      cellContent: "value",
                      value: groups,
                      specialWidth: "w-2/12 max-h-full overflow-hidden ",
                  },
                  {
                      name: "rooms",
                      cellContent: "link",
                      multipleLink: guestrooms,
                      specialWidth: "w-1.5/12",
                      permissionToLink: { section: "hotel", attribute: "roomList" },
                  },
                  {
                      name: "signupDate",
                      cellContent: "value",
                      value: `${
                          from
                              ? `${from.toLocaleDateString([], {
                                    year: "2-digit",
                                    month: "2-digit",
                                    day: "2-digit",
                                })} ${from.toLocaleTimeString("es-ES", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                })}`
                              : `-`
                      }\n${
                          to
                              ? `${to.toLocaleDateString([], {
                                    year: "2-digit",
                                    month: "2-digit",
                                    day: "2-digit",
                                })} ${to.toLocaleTimeString("es-ES", {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                })}`
                              : `-`
                      }`,
                      specialWidth: "w-1.5/12 whitespace-prewrap",
                  },
                  {
                      name: "booking-management",
                      specialWidth: "w-1/12",
                  },
              ]
            : [
                  {
                      name: "code",
                      cellContent: "link",
                      link: `/customers/guests/guest-list/reservation-details/${id}`,
                      value: code,
                      specialWidth: "w-2/12 truncate mr-4",
                  },
                  {
                      name: "email",
                      cellContent: "text",
                      value: main_guest?.email,
                      specialWidth: "w-3/12",
                      adjust: "truncate",
                  },
                  {
                      name: "name",
                      cellContent: "text",
                      value: `${main_guest ? `${main_guest?.name} ${main_guest?.surname}` : ""}`,
                      specialWidth: "w-1.5/12 max-h-full overflow-hidden text-center",
                  },
                  {
                      name: "booking",
                      cellContent: "booking",
                      value: { room: numberOfRooms, guest: numberOfGuests },
                      specialWidth: "w-1.5/12",
                  },
                  {
                      name: "stayDate",
                      cellContent: "date",
                      value: `${ParseDate(new window.ZDate(arrivalDate))} ${
                          departureDate ? ParseDate(new window.ZDate(departureDate)) : "-"
                      }`,
                      specialWidth: "w-2/12",
                  },
                  {
                      name: "status",
                      cellContent: "upcomingCheckInsStatus",
                      specialWidth: `${`w-2/12 ${pendingChangesSend ? "hidden" : "block"} `}`,
                      value: UPCOMING_GUESTS_STATUS[state],
                  },
                  {
                      name: "status",
                      cellContent: "text",
                      specialWidth: `${`w-2/12 ${pendingChangesSend ? "block" : " hidden"} `}`,
                      value: pendingChangesSend ? i18n.t("data-in-modification") : "",
                  },
                  {
                      name: "all-information",
                      value: user,
                  },
              ];
    return {
        dataType: dataType,
        id: id,
        adjust: "h-auto",
        actions: [{ type: dataType, value: id }],
        rooms: rooms,
        guestID: id,
        info: info,
        executeSectionQuery: !(folderName == "currents"),
    };
};

export default Guest;
