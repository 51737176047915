import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//API
import { withApollo } from "@apollo/react-hoc";

//ACTIONS
import { setLoading, setRefreshData } from "../../../actions/tableActions";
import { setExecuteQuery, setRefreshContentData } from "../../../actions/sectionActions";
import { showGlobalLoading, setModalContent, closeModal } from "../../../actions/uiActions";
import { cleanAction } from "../../../actions/globalActions";

//UTILS
import { toast } from "react-toastify";
import { removeApolloCacheKeys } from "../../Utils/Utils";
import { useTranslation } from "react-i18next";
import { executeVendureQuery, getVendureApiData, uploadVendureMutation } from "../../Utils/Integrations/useVendure";
import _ from "lodash";
import { Session } from "../../Utils/Session";

import {
    UPDATE_ENABLED_ALLERGENS,
    UPDATE_PRODUCT_ADD_ASSET,
    UPDATE_PRODUCT_ALLERGENS_AND_LABELS,
    UPDATE_PRODUCT_ASSETS,
    UPDATE_PRODUCT_AVAILABLE,
    UPDATE_PRODUCT_CREATE_ASSET,
    UPDATE_PRODUCT_DELETE_ASSET,
    UPDATE_PRODUCT_DESCRIPTION,
    UPDATE_PRODUCT_FEATURED_IMAGE,
    UPDATE_PRODUCT_GET_ASSETS,
    UPDATE_PRODUCT_NAME_AND_DESCRIPTION,
    UPDATE_PRODUCT_NAME_TRANSLATIONS,
    UPDATE_PRODUCT_PRICES,
    UPDATE_PRODUCT_SETTINGS,
} from "../../Utils/Integrations/ShopSettingsUtils";
import { arrangeToastMessagesUploadingFiles } from "../../Utils/DesignUtils";
import UseToast from "../../../components/Notifications/useToast";

const UpdateProducts = ({ client }) => {
    const { t } = useTranslation();
    const { executeQuery } = useSelector((state) => state.sectionContent);
    const actionData = useSelector((state) => state.action);
    const { useGlobalLoading } = useSelector((state) => state.ui.modalContent);
    const { projectLangs } = useSelector((state) => state.ui);
    const dispatch = useDispatch();
    const currentTokenShop = Session.getSessionProp("tokenShop");

    useEffect(() => {
        let execute = false;
        let mutation = null;
        let executeAnotherQuery = null;
        let avoidToast = false;
        let noCleanAction = false;
        let mutationFile = null;
        let aditionalHeader = null;
        if (executeQuery) {
            executeQuery.closeModal = true;
            executeQuery.toastMsg = null;
            executeQuery.mutationName = "";
            execute = true;
            switch (executeQuery.action) {
                case UPDATE_PRODUCT_NAME_AND_DESCRIPTION:
                    mutation = `
                    mutation{
                        updateProduct(
                            input:{
                                id:${executeQuery.params.id},
                                translations:{
                                    id:${executeQuery.params.translationId},
                                    languageCode:${executeQuery.params.languageCode},
                                    name:"${executeQuery.params.name}",
                                    description:"${executeQuery.params.description}"
                                }
                            }){
                            id
                            __typename
                        }
                    }
                    `;
                    executeQuery.mutationName = "updateProduct";
                    noCleanAction = true;
                    break;
                case UPDATE_PRODUCT_PRICES:
                    mutation = `
                        mutation{
                            updateProductVariants(
                                input:{
                                    id:${executeQuery.params.productVariantId}
                                    price: ${executeQuery.params.finalPrice ? executeQuery.params.finalPrice : `0`}
									${executeQuery.params.taxCategoryId ? ` taxCategoryId: ${executeQuery.params.taxCategoryId}` : ``}
                                }){
                                id
                                __typename
                            }
                        }
                        `;
                    executeQuery.mutationName = "updateProductVariants";
                    aditionalHeader = { "vendure-token": currentTokenShop };
                    noCleanAction = true;
                    break;
                case UPDATE_PRODUCT_ALLERGENS_AND_LABELS:
                    mutation = `
                        mutation{
                            updateProduct(
                                input:{
                                    id:${executeQuery.params.id},
                                    facetValueIds: [${
                                        executeQuery.params.facetValueIds &&
                                        executeQuery.params.facetValueIds.length > 0
                                            ? `${executeQuery.params.facetValueIds.join(",")}`
                                            : ``
                                    }]
                                }){
                                id
                                __typename
                            }
                        }
                        `;
                    executeQuery.mutationName = "updateProduct";
                    noCleanAction = true;
                    break;
                case UPDATE_PRODUCT_SETTINGS:
                    mutation = `
                        mutation{
                            updateProductVariants(
                                input:{
                                    id:${actionData.itemsAffected[0]}
                                    customFields: {
                                        max_units_per_order_enabled: ${actionData.values["active-max-quantity-per-order"]}
                                        max_units_per_order: ${actionData.values["max-quantity-per-order"] > 0 ? actionData.values["max-quantity-per-order"] : null}
                                    }
                                }){
                                id
                                __typename
                            }
                        }
                        `;
                    executeQuery.mutationName = "updateProductVariants";
                    break;
                case UPDATE_PRODUCT_DELETE_ASSET:
                    if (executeQuery.params.newFeaturedAssetId && executeQuery.params.productId) {
                        executeAnotherQuery = {
                            action: UPDATE_PRODUCT_FEATURED_IMAGE,
                            extraParams: { ...executeQuery.params },
                        };
                    }
                    mutation = `
                            mutation{
                                deleteAsset(input: { assetId: ${executeQuery.params.id}, force: true, deleteFromAllChannels: true }) {
                                    result
                                    message
                                    __typename
                                }
                            }
                        `;
                    executeQuery.mutationName = "deleteAsset";
                    noCleanAction = true;
                    break;
                case UPDATE_PRODUCT_ASSETS:
                    mutation = `
                        mutation{
                            updateProduct(
                                input:{
                                    id:${executeQuery.params.productId},
                                    featuredAssetId:${executeQuery.params.newFeaturedAssetId},
                                    assetIds:[${executeQuery.params.assetIds}]
                                }
                            ){
                                id
                                __typename
                            }
                        }
                        `;
                    executeQuery.mutationName = "updateProduct";
                    noCleanAction = true;
                    break;
                case UPDATE_ENABLED_ALLERGENS:
                    mutation = `mutation{
                        updateProductVariants(
                            input:{
                                id:${executeQuery.params.id}
                                customFields: {
                                    are_allergens_enabled:${executeQuery.params.enabled}
                                }
                            }){
                            id
                            __typename
                        }
                    }`;
                    executeQuery.mutationName = "updateProductVariants";
                    break;
                case UPDATE_PRODUCT_DESCRIPTION:
                    const descriptionsLanguages = [];
                    const translationsObj =
                        actionData && actionData.actionData && actionData.actionData["data-translations-obj"]
                            ? actionData.actionData["data-translations-obj"]
                            : {};
                    projectLangs.forEach((lang) => {
                        const selector = document.querySelector("#change-description-product-" + lang.languageRef);
                        if (selector) {
                            if (selector.value && selector.value.trim() !== "") {
                                descriptionsLanguages.push({
                                    lang: lang.languageRef,
                                    name: selector.value ? selector.value : "",
                                });
                            }
                        }
                    });
                    let stringLangs = ``;
                    descriptionsLanguages.forEach((lang) => {
                        stringLangs += `{ languageCode: ${lang.lang}, description: "${lang.name}" , 
						name: "${
                            translationsObj[lang.lang] && translationsObj[lang.lang]["name"]
                                ? translationsObj[lang.lang]["name"]
                                : "\u200b"
                        }" ,
						slug: "${
                            translationsObj[lang.lang] && translationsObj[lang.lang]["slug"]
                                ? translationsObj[lang.lang]["slug"]
                                : "\u200b"
                        }" ,
						${
                            translationsObj[lang.lang] && String(translationsObj[lang.lang]["id"])
                                ? `id: ${translationsObj[lang.lang]["id"]}`
                                : ""
                        }
					} ,`;
                    });
                    mutation = `mutation {
						updateProduct(input: {
							id: ${actionData.actionData["id-product"]},
							translations: [
							${stringLangs}
							]
						}) {
							id
							name
							description
						}
						}
						  `;
                    aditionalHeader = { "vendure-token": currentTokenShop };
                    executeQuery.mutationName = "updateProduct";
                    break;
                case UPDATE_PRODUCT_CREATE_ASSET:
                    mutation = `mutation Mutation($file: Upload!) {
						createAssets(input: [{ file: $file }]) {
							... on Asset {
								id
								name
                            	fileSize
							}
							... on ErrorResult {
								message
							}
						}
                    }`;
                    aditionalHeader = { "vendure-token": executeQuery.params.token };
                    mutationFile = { file: executeQuery.params.file, mutationName: "createAssets" };
                    executeAnotherQuery = {
                        action: UPDATE_PRODUCT_GET_ASSETS,
                        useResponsePrevQueryData: {
                            name: "id",
                            path: "createAssets[0].id",
                        },
                        extraParams: { ...executeQuery.params },
                    };
                    noCleanAction = true;
                    break;
                case UPDATE_PRODUCT_GET_ASSETS:
                    mutation = `
						query {
							product(id: ${executeQuery.params.productId}) {
								id
								assets {
									id
								}
							}
						}
					`;
                    executeAnotherQuery = {
                        action: UPDATE_PRODUCT_ADD_ASSET,
                        useResponsePrevQueryData: {
                            name: "assets",
                            path: "product.assets",
                        },
                        extraParams: { ...executeQuery.params },
                    };
                    noCleanAction = true;
                    avoidToast = true;
                    break;
                case UPDATE_PRODUCT_ADD_ASSET:
                    let newAssetAsFeaturedAsset = false;
                    let newFeaturedAssetId = executeQuery.params.id;
                    let assetIds =
                        executeQuery.params.assets && executeQuery.params.assets.length > 0
                            ? _.map(executeQuery.params.assets, "id")
                            : [];
                    if (!assetIds || assetIds.length < 1) newAssetAsFeaturedAsset = true;
                    assetIds.push(newFeaturedAssetId);
                    mutation = `
						mutation{
							updateProduct(input:{
								id:${executeQuery.params.productId},
								assetIds:[${assetIds}],
								${newAssetAsFeaturedAsset ? `featuredAssetId:${newFeaturedAssetId}` : ``}
							}){
								id
								__typename
							}
						}
					`;
                    executeQuery.mutationName = "updateProduct";
                    noCleanAction = true;
                    avoidToast = true;
                    break;
                case UPDATE_PRODUCT_FEATURED_IMAGE:
                    mutation = `
                        mutation{
                            updateProduct(input:{id:${executeQuery.params.productId},featuredAssetId:${executeQuery.params.newFeaturedAssetId}}){
                                id
                                __typename
                            }
                        }
                        `;
                    executeQuery.mutationName = "updateProduct";
                    noCleanAction = true;
                    break;
                case UPDATE_PRODUCT_AVAILABLE:
                    mutation = `
                        mutation{
                            updateProduct(input:{id:${executeQuery.params.productId},enabled:${executeQuery.params.available}}){
                                id
                                __typename
                            }
                        }
                        `;
                    executeQuery.mutationName = "updateProduct";
                    noCleanAction = true;
                    break;
                case UPDATE_PRODUCT_NAME_TRANSLATIONS:
                    let translationMutations = "";
                    if (
                        actionData &&
                        actionData.values &&
                        actionData.values["translations"] &&
                        actionData.values["translations"].length > 0
                    ) {
                        actionData.values["translations"].forEach((translation) => {
                            if (translation.value && translation.value !== "") {
                                translationMutations += `{
                                    languageCode: ${translation.languageRef}
                                    name: "${translation.value}"
									${translation.id ? `id: ${translation.id}` : `slug:"${translation.languageRef}",description:" "`}
                                }`;
                            }
                        });
                    }
                    mutation = `
                        mutation{
                            updateProduct(input:{
								id:${actionData.itemsAffected},
								translations: [${translationMutations}]
							}){
                                id
                                __typename
                            }
                        }
                        `;
                    executeQuery.mutationName = "updateProduct";
                    break;
                default:
                    mutation = null;
                    execute = false;
                    break;
            }
        }

        if (execute) {
            if (useGlobalLoading) dispatch(showGlobalLoading(true));
            dispatch(setLoading(true));
            setTimeout(function () {
                executeMutation(
                    mutation,
                    executeAnotherQuery,
                    avoidToast,
                    noCleanAction,
                    mutationFile,
                    aditionalHeader
                );
            }, 100);
        }
        // eslint-disable-next-line
    }, [executeQuery]);

    const executeMutation = async (
        mutation,
        executeAnotherQuery,
        avoidToast,
        noCleanAction,
        mutationFile = null,
        aditionalHeader = null
    ) => {
        if (executeQuery.closeModal) {
            dispatch(setModalContent(false));
            dispatch(closeModal());
            dispatch(setRefreshContentData(false));
        }
        let errorMutation = false;
        let response = null;
        let files = [];
        let toastId = null;

        if (mutationFile) {
            files = [
                {
                    name: mutationFile.file.name,
                    error: errorMutation,
                    textTooltip: mutationFile.file.name,
                    status: 1,
                    ref: "",
                    size: mutationFile.file.size,
                },
            ];
            let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);
            toastId = toast.warning(<UseToast title={title} msgs={msgs} minimize={true} />, {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: false,
                className: "use-toast",
                closeButton: false,
                closeOnClick: false,
                draggable: false,
                hideProgressBar: true,
                icon: false,
            });
            response = await uploadVendureMutation(
                getVendureApiData(),
                {
                    queryBody: mutation,
                    file: mutationFile.file,
                },
                typeof aditionalHeader === "object" ? aditionalHeader : {}
            );

            if (response && response.data && !response.data.errors) {
                if (mutationFile.mutationName) {
                    let mutationObject = response.data[mutationFile.mutationName];
                    if (
                        mutationObject &&
                        mutationObject.result &&
                        mutationObject.result.toUpperCase().includes("NOT")
                    ) {
                        errorMutation = true;
                    } else if (mutationObject instanceof Array && mutationObject.length > 0 && executeAnotherQuery) {
                        executeAnotherQuery.params = { ...executeAnotherQuery.params, ...mutationObject[0] };
                    }
                }
            } else {
                errorMutation = true;
            }
        } else {
            response = await executeVendureQuery(
                getVendureApiData(),
                { queryBody: mutation },
                typeof aditionalHeader === "object" ? aditionalHeader : {}
            );
            if (response && response.data && !response.data.errors) {
                if (executeQuery.mutationName) {
                    let mutationObject = response.data[executeQuery.mutationName];
                    if (
                        mutationObject &&
                        mutationObject.result &&
                        mutationObject.result.toUpperCase().includes("NOT")
                    ) {
                        errorMutation = true;
                    }
                }
            } else {
                errorMutation = true;
            }
        }

        if (!errorMutation) {
            if (mutationFile) {
                files.forEach((file) => {
                    file["status"] = 2;
                });

                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);

                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });
            } else if (!avoidToast) {
                toast.success(t("operation-successful"));
            }
            dispatch(setRefreshContentData(true));
            dispatch(setRefreshData(true));
            dispatch(setExecuteQuery(null));
            if (useGlobalLoading) dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            if (!noCleanAction) dispatch(cleanAction());
            if (executeQuery.cacheKeyToDelete) {
                removeApolloCacheKeys(client.cache, executeQuery.cacheKeyToDelete);
            }
            if (executeAnotherQuery) {
                let params = executeAnotherQuery.extraParams ? executeAnotherQuery.extraParams : {};
                if (executeAnotherQuery.useResponsePrevQueryData) {
                    let param = executeAnotherQuery.useResponsePrevQueryData;
                    params[param.name] = _.get(response.data, param.path);
                }
                dispatch(
                    setExecuteQuery({
                        action: executeAnotherQuery.action,
                        params: params,
                    })
                );
            }
        } else {
            dispatch(setExecuteQuery(null));
            if (useGlobalLoading) dispatch(showGlobalLoading(false));
            dispatch(setLoading(false));
            if (mutationFile) {
                // eslint-disable-next-line
                files.forEach((file) => {
                    file["status"] = 4;
                    file["errorMessage"] = "";
                });
                let [title, msgs] = arrangeToastMessagesUploadingFiles(t, files, files.length);
                toast.update(toastId, {
                    render: <UseToast title={title} msgs={msgs} minimize={false} />,
                    className: "use-toast",
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: 2500,
                    closeButton: true,
                });
            } else {
                toast.error(t("mutation-error"));
            }
        }
    };

    return null;
};

export default withApollo(UpdateProducts);
