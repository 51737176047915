import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActionInput } from "../../actions/globalActions";

function UseCheckBox({
    id,
    checked,
    name,
    nameStyle,
    translatable,
    enabled = true,
    preventClick,
    adjust = "",
    onClick,
    useAllSpace = false,
    forceUpdateChecked = false,
    error,
}) {
    const dispatch = useDispatch();
    const { langStrings } = useSelector((state) => state.ui);
    const [isActive, setIsActive] = useState(checked);
    const [checkboxActive, setCheckboxActive] = useState({
        checked: false,
        visible: "hidden",
        background: "block",
        text: "",
    });

    //Actions
    useEffect(() => {
        const dataToAction = { [id]: isActive };
        dispatch(setActionInput(dataToAction));
        // eslint-disable-next-line
    }, [isActive]);

    const clickHandler = (e) => {
        if (!enabled || preventClick?.current) {
            return null;
        }
        const switchValue = isActive ? false : true;
        setIsActive(switchValue);
        if (onClick) {
            onClick(switchValue);
        }
    };

    useEffect(() => {
        if (forceUpdateChecked) {
            setIsActive(checked);
        } else if (checked) {
            setIsActive(checked);
        }
    }, [checked]);

    //Listeners
    useEffect(() => {
        if (isActive) {
            setCheckboxActive({
                checked: true,
                visible: "block",
                background: "hidden",
                text: "text-blue-300",
            });
        } else {
            setCheckboxActive({
                checked: false,
                visible: "hidden",
                background: "block",
                text: "",
            });
        }
    }, [isActive]);

    return (
        <div className="w-full h-10 my-auto table-cell align-middle">
            <div className={`block relative ${enabled ? "cursor-pointer" : " text-gray-500"} ${adjust}`}>
                <div
                    className={`${useAllSpace && "w-full"} ml-8 ${
                        enabled && "hover:text-zafiro-400"
                    } first-capital text-left ${!enabled & "disabled"} ${nameStyle}`}
                    onClick={clickHandler}
                >
                    {translatable ? langStrings[name] : name}
                </div>
                <input
                    id={`${id ? id : "checkbox"}`}
                    type="checkbox"
                    className={`absolute left-0 w-5 h-5 checkbox ${enabled && "cursor-pointer"} z-50 ${
                        checkboxActive.visible
                    } ${isActive && !enabled && "disabled hidden"}`}
                    checked={checkboxActive.checked}
                    onChange={clickHandler}
                    style={{ top: " 0.25rem" }}
                ></input>
                {isActive && !enabled ? (
                    <div
                        className=" cursor-not-allowed rounded-sm w-5 h-5  absolute bg-gray-300 "
                        style={{ top: "0.25rem" }}
                    >
                        <span
                            style={{
                                display: "inline-block",
                                width: "1.25rem",
                                height: "1.25rem",
                                position: "absolute",
                                top: "-3px",
                                left: "-2px",
                                transform: "rotate(40deg)",
                            }}
                        >
                            <div
                                style={{
                                    position: "absolute",
                                    width: "3px",
                                    height: "13px",
                                    backgroundColor: "white",
                                    left: "11px",
                                    top: "2px",
                                }}
                            ></div>
                            <div
                                style={{
                                    position: "absolute",
                                    width: "4px",
                                    height: "3px",
                                    backgroundColor: "white",
                                    left: "8px",
                                    top: "12px",
                                }}
                            ></div>
                        </span>
                    </div>
                ) : null}
                <span
                    id={`${id ? `${id}-` : ""}${"checkbox-background"}`}
                    className={`absolute ${error ? "border border-red-100" : " border border-transparent"} ${
                        isActive && !enabled ? "hidden" : ""
                    } left-0 w-5 h-5 bg-gray-200 ${enabled ? "hover:bg-gray-300" : ""} ${checkboxActive.background}`}
                    style={{ top: "0.25rem" }}
                    onClick={clickHandler}
                ></span>
            </div>
        </div>
    );
}

export default UseCheckBox;
