import React from "react";
import { Session } from "../../hooks/Utils/Session";
import NotFound from "../../components/Section/NotFound";

const OldManager = () => {
    const oldManagerConfigData = Session.getLSProp("oldManagerConfig");
    const oldManagerConfig = oldManagerConfigData ? JSON.parse(oldManagerConfigData) : {} ;
    const oldManager = oldManagerConfig?.url
        ? {
              path: oldManagerConfig.url + "manager/index.php",
              autoLogin: !oldManagerConfig.users,
          }
        : null;
    const getURL = (manager,oldManagerConfig) => {
        let url = window.location.origin + manager.path;
        if (manager.autoLogin) {
            url += "?tokenCloud=" + oldManagerConfig?.tokenCloud;
        }
        return url;
    };
    return (
        <>
            {oldManager ? (
                <iframe title="manage3.8.3" className="w-full h-full absolute" src={getURL(oldManager,oldManagerConfig)}></iframe>
            ) : (
                <div className="flex h-screen ">
                    <div className="m-auto">
                        <NotFound />
                    </div>
                </div>
            )}
        </>
    );
};

export default OldManager;
