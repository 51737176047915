import { dateString } from "../../../Utils/Utils";

const CustomersModalMutationDispatcher = (dataAction) => {
    let customerMutation = "";
    switch (dataAction.actionName) {
        case "logout-devices":
            customerMutation += `mutation{logOutKey(
                ref: "${dataAction.itemsAffected[0]}"
                date: "${dateString(new window.ZDate())}"`;
            break;
        case "deactivate-commercial-communications":
            customerMutation += `mutation{updateCustomer(
                ID: "${dataAction && dataAction.inputData ? dataAction.inputData.idCustomer : ``}"
                marketingAccepted: false
                `;
            break;
        case "activate-commercial-communications":
            customerMutation += `mutation{updateCustomer(
                ID: "${dataAction && dataAction.inputData ? dataAction.inputData.idCustomer : ``}"
                marketingAccepted: true
                `;
            break;
        default:
            break;
    }
    return customerMutation;
};

export default CustomersModalMutationDispatcher;
