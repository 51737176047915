import React from "react";
import UseNewGuestForm from "./useNewGuestForm";
import { useTranslation } from "react-i18next";

const NewGuestData = () => {
    const { t } = useTranslation();
    return (
        <div className="p-8">
            <div className="border-b border-gray-200 text-lg pb-2 flex justify-between">
                <div className="first-capital font-bold">{t("guest-information")}</div>
            </div>
            <UseNewGuestForm />
        </div>
    );
};

export default NewGuestData;
