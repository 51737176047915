import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { FILTER_ORDER_DELAYED } from "constants/sales";

import { MonitorContext } from "contexts/Sales/Monitor";
import { ShopContext } from "contexts/Sales/Shop";

import {
    ORDER_TYPE_RECEIVED,
    ORDER_TYPE_READY,
    ORDER_TYPE_ARCHIVED,
} from "../../../../../../hooks/Utils/Services/OrdersUtils";

import Loading from "components/Loading";

const OrderMonitorTypes = ({ setDelaysToParent }) => {
    const { t } = useTranslation();

    const { id, token } = useContext(ShopContext);
    const { setCurrentType, lang, counts, orders, updating, error } = useContext(MonitorContext);

    const [selected, setSelected] = useState(ORDER_TYPE_RECEIVED);
    const [totals, setTotals] = useState({
        current: 0,
        delayedCurrent: 0,
        received: 0,
        delayedReceived: 0,
        ready: 0,
        delayedReady: 0,
    });
    const [items, setItems] = useState(null);

    useEffect(() => {
        setTotals({
            current: orders?.length || null,
            delayedCurrent: orders?.filter((item) => item.delayed)?.length || null,
            received: counts?.received?.total || null,
            delayedReceived: counts?.received?.delayed || null,
            ready: counts?.ready?.total || null,
            delayedReady: counts?.ready?.delayed || null,
        });
    }, [orders, counts]);

    const types = [ORDER_TYPE_RECEIVED, ORDER_TYPE_READY, ORDER_TYPE_ARCHIVED];

    useEffect(() => {
        if (token || !id) {
            setCurrentType(selected);
            setDelaysToParent(delayQueries({ types, selected, token, lang }));
        }
    }, [id, token, lang, selected]);

    useEffect(() => {
        setItems(
            types.map((type) => {
                let title = type,
                    count = 0,
                    delayed = 0;

                if (type === selected) {
                    count = totals.current;
                    delayed = totals.delayedCurrent;
                }

                if (type === ORDER_TYPE_READY) {
                    title = t("ready-for-delivery");
                    count = totals.ready || 0;
                    delayed = selected === ORDER_TYPE_READY ? totals.delayedCurrent : totals.delayedReady;
                } else if (type === ORDER_TYPE_RECEIVED) {
                    title = t("received");
                    count = totals.received || 0;
                    delayed = selected === ORDER_TYPE_RECEIVED ? totals.delayedCurrent : totals.delayedReceived;
                } else if (type === ORDER_TYPE_ARCHIVED) {
                    title = t("archived");
                }

                return {
                    id: type,
                    title,
                    count: type === ORDER_TYPE_ARCHIVED ? null : count,
                    dot: type === ORDER_TYPE_ARCHIVED ? null : delayed > 0,
                    loading: type === selected && !error && updating,
                    selected: type === selected,
                };
            })
        );
    }, [selected, totals]);

    return (
        <div className="inline-flex rounded-md shadow-sm">
            {items?.map((item, index) => {
                const elementID = `order-monitor-type-${item.id}`;
                const showCount = item.count || item.count === 0;
                const showDot = item.dot;

                const bgClass = item.id === selected ? `bg-zafiro-600 text-white` : `bg-white hover:bg-gray-100`;
                let roundedClass;
                if (index === 0) {
                    roundedClass = "rounded-l-lg";
                } else if (index === items.length - 1) {
                    roundedClass = "rounded-r-lg";
                }

                return (
                    <button
                        id={elementID}
                        key={elementID}
                        type="button"
                        className={`relative px-10 py-2 min-w-42 h-14 ${bgClass} ${roundedClass}`}
                        onClick={() => {
                            setSelected(item.id);
                        }}
                    >
                        {item.loading ? (
                            <Loading zoom={30} adjust="absolute" style={{ right: "auto", left: ".5rem" }} />
                        ) : null}
                        {item.title}

                        {showCount || showDot ? (
                            <div className="mx-2 inline-block relative">
                                {showCount ? (
                                    <span
                                        className={`${
                                            item.id === selected
                                                ? `bg-gray-100 text-zafiro-600`
                                                : `bg-zafiro-600 text-gray-100`
                                        } text-sm rounded-lg px-2`}
                                    >
                                        {item.count}
                                    </span>
                                ) : null}
                                {showDot && (
                                    <i
                                        className="absolute bg-red-100 w-3 h-3 rounded-lg"
                                        style={{ right: "-6px", top: "0px" }}
                                    ></i>
                                )}
                            </div>
                        ) : null}
                    </button>
                );
            })}
        </div>
    );
};

// get delayed orders for another types
const delayQueries = ({ types, selected, token, lang }) => {
    return (
        (types
            ? types
                  .filter((type) => type !== ORDER_TYPE_ARCHIVED && type != selected)
                  .map(
                      (type) => `
                        ${type}Delayed: monitoringOrders(
                            type: ${type}
                            languageRef: "${lang}"
                            filter: {state: [${FILTER_ORDER_DELAYED}]${token ? `,shop: ["${token}"]` : ``}}
                        ){items{id}}
                    `
                  )
            : null) || ""
    );
};

export default OrderMonitorTypes;
