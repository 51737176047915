import DeleteAccessProfile from "../modals/DeleteAccessProfile";

const AccessProfile = (accessProfile, wifiAccessTypes, handleChangeAccessType, t) => {
    const dataType = "access-profile";
    const wifiAccessTypesList = [];
    if (wifiAccessTypes && wifiAccessTypes.length > 0) {
        // eslint-disable-next-line
        wifiAccessTypes.map((wifiAccessType) => {
            let selected = null;
            let oldIDs = [];
            if (accessProfile.accesstypes && accessProfile.accesstypes.length > 0) {
                // eslint-disable-next-line
                accessProfile.accesstypes.map((accessType) => {
                    if (accessType.accesstypes_name === wifiAccessType.value) selected = accessType.accesstypes_name;
                    oldIDs.push(accessType.id);
                });
            }
            let wifiAccessTypeOption = {
                itemType: "radio",
                itemData: {
                    id:`${accessProfile.name}-${wifiAccessType.value}`,
                    isCheckboxType: true,
                    value: wifiAccessType.value,
                    selected: selected,
                    text: wifiAccessType.name,
                    handleClick: handleChangeAccessType,
                    handleClickData: {
                        id: accessProfile.id,
                        oldIDs: oldIDs,
                        newIDs: wifiAccessType.ids,
                        name: accessProfile.name,
                    },
                },
            };
            wifiAccessTypesList.push(wifiAccessTypeOption);
        });
    }

    return {
        ...accessProfile,
        id: accessProfile.id,
        dataType: dataType,
        executeSectionQuery: true,
        info: [
            {
                name: "name",
                value: accessProfile.name,
                cellContent: "text",
                specialWidth: "w-3/12",
            },
            {
                name: "bwMin",
                cellContent: "list",
                listValues: [
                    {
                        name: "uploadbwmin",
                        value: t("x mb/s", {
                            number: accessProfile.uploadbwmin > 0.0 ? accessProfile.uploadbwmin : "-",
                        }),
                        translateValue: false,
                        icon: "back1",
                        iconAdjust: "animated rotate-90 ml-1 mr-2 pt-1",
                        noShowName: true,
                    },
                    {
                        name: "downloadbwmin",
                        value: t("x mb/s", {
                            number: accessProfile.downloadbwmin > 0.0 ? accessProfile.downloadbwmin : "-",
                        }),
                        translateValue: false,
                        icon: "back1",
                        iconAdjust: "animated -rotate-90 mr-3 pt-1",
                        noShowName: true,
                    },
                ],
                specialWidth: "w-2/12",
            },
            {
                name: "bwMax",
                cellContent: "list",
                listValues: [
                    {
                        name: "uploadbwmax",
                        value: t("x mb/s", {
                            number: accessProfile.uploadbwmax > 0.0 ? accessProfile.uploadbwmax : "-",
                        }),
                        translateValue: false,
                        icon: "back1",
                        iconAdjust: "animated rotate-90 ml-1 mr-2 pt-1",
                        noShowName: true,
                    },
                    {
                        name: "downloadbwmax",
                        value: t("x mb/s", {
                            number: accessProfile.downloadbwmax > 0.0 ? accessProfile.downloadbwmax : "-",
                        }),
                        translateValue: false,
                        icon: "back1",
                        iconAdjust: "animated -rotate-90 mr-3 pt-1",
                        noShowName: true,
                    },
                ],
                specialWidth: "w-2/12",
            },
            {
                name: "emptyCell",
                value: "",
                cellContent: "text",
                specialWidth: "w-1/12",
            },
            {
                name: "accessType",
                cellContent: "list",
                listValues: wifiAccessTypesList,
                specialWidth: "w-3/12",
            },
            {
                cellContent: "icon",
                value: accessProfile.id,
                evaluate: true,
                specialWidth: "w-0.5/12",
                isAction: true,
                icon: {
                    name: "edit",
                    id: accessProfile.id,
                    itemType: dataType,
                    action: "edit-access-profile",
                },
            },
            {
                cellContent: "icon",
                value: accessProfile.id,
                evaluate: true,
                specialWidth: "w-0.5/12",
                isAction: true,
                modal: DeleteAccessProfile(accessProfile.id, accessProfile.name),
                name: "access-profile",
                icon: {
                    name: "delete",
                    values: accessProfile.id,
                    itemType: dataType,
                },
            },
        ],
    };
};

export default AccessProfile;
