import React, { useEffect } from "react";
import UseSectionHeader from "../../useSectionHeader";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useLazyQuery } from "react-apollo";
import { useDispatch, useSelector } from "react-redux";
import { Session } from "hooks/Utils/Session";
import { getFromDAS } from "hooks/Utils/Utils";
import { getMobileAppearancesQuery } from "./MobileAppearances/mobileAppearancesUtils";
import { getMobileAppQuery, getMobileAppDesign } from "../Hotel/MobileSettings/MobileSettingsUtils";
import { setActionName, setActionValues } from "actions/globalActions";
import { showGlobalLoading as setShowGlobalLoading, setModalContent, openModal } from "actions/uiActions";
import UpdateMobileAppearance from "hooks/GraphqlCalls/Design/UpdateMobileAppearance";
import { setRefreshContentData } from "actions/sectionActions";

export const MobileApparences = () => {
    const { t } = useTranslation();
    const { lang } = useSelector((state) => state.ui);
    const { refreshData } = useSelector((state) => state.sectionContent);
    const projectRef = Session.getSessionProp("projectRef");
    const GET_DATA_MOBILE_APP = gql(getMobileAppQuery(projectRef));
    const dispatch = useDispatch();
    const [executeQueryData, { data: app }] = useLazyQuery(GET_DATA_MOBILE_APP, {
        fetchPolicy: "network-only",
        onCompleted: () => {
            executeQueryDesigns();
        },
    });
    const GET_DESIGN_MOBILE_APP = gql(getMobileAppDesign(app?.mobileApps?.results[0]?.id));
    const [executeQueryDesigns, { data: design }] = useLazyQuery(GET_DESIGN_MOBILE_APP, {
        fetchPolicy: "network-only",
        onCompleted: () => {
            dispatch(setShowGlobalLoading(false));
            dispatch(setRefreshContentData(false));
        },
    });

    const openChangeAppearanceModal = () => {
        dispatch(setActionName("update-mobile-appearance"));
        dispatch(
            setModalContent({
                title: `${app?.mobileApps?.results[0]?.name} - ${t("choose-appearance")}`,
                bodyAdjust: "w-2/5",
                useGlobalLoading: true,
                executeSectionQuery: true,
                inputs: [
                    {
                        text: `${t("appearance")}*`,
                        textStyle: "font-bold capitalize",
                        instructions: "select-appearance-description",
                        name: "appearance",
                        type: "selectMultipleWithSearch",
                        optionsQuery: getMobileAppearancesQuery(design?.mobileAppTheme?.id),
                        cStyle: "w-full",
                        forceSelection: true,
                        orderOptions: true,
                        groupsNames: [
                            { name: "LOCAL", text: "property-appearances" },
                            { name: "CORPORATE", text: "general-appearances" },
                        ],
                        noSelect: true,
                        oneSelected: true,
                        translateOptions: true,
                    },
                ],
                buttons: [
                    { name: "cancel", style: "white", action: "close" },
                    {
                        name: "save",
                        style: "blue",
                        action: "update-mobile-appearance",
                    },
                ],
            })
        );
        if (app?.mobileApps?.results && app?.mobileApps?.results?.length > 0) {
            dispatch(setActionValues({ appId: app?.mobileApps?.results[0]?.id }));
        }
        dispatch(openModal());
    };

    useEffect(() => {
        if (executeQueryData) {
            executeQueryData();
            dispatch(setShowGlobalLoading(true));
        }
        return () => {};
    }, []);

    useEffect(() => {
        if (refreshData) {
            executeQueryDesigns();
            dispatch(setShowGlobalLoading(true));
        }
        return () => {};
    }, [refreshData]);

    return (
        <>
            <UpdateMobileAppearance></UpdateMobileAppearance>
            <UseSectionHeader title={["general-mobile-app"]} value={"demo"} />
            <div className=" pb-8">
                <p>{t("general-mobile-app-description-1")}</p>
                <p>{t("general-mobile-app-description-2")}</p>
            </div>
            <div className="w-full bg-white p-2 rounded-md min-h-10 flex items-center justify-center">
                {app?.mobileApps?.results.length > 0 ? (
                    <div className="px-8 flex flex-row w-full justify-between">
                        <div className="flex gap-3 justify-center items-center">
                            {app?.mobileApps?.results[0]?.config?.iconAssetRef ? (
                                <div className=" w-12 h-12 bg-gray-100 border border-gray-300 flex items-center justify-center rounded">
                                    <img
                                        className="h-full w-full object-scale-down"
                                        src={getFromDAS(app?.mobileApps?.results[0]?.config?.iconAssetRef, lang)}
                                    ></img>
                                </div>
                            ) : (
                                <i className="icon icon-no-image mt-1"></i>
                            )}
                            <span className=" font-bold">{app?.mobileApps?.results[0]?.name}</span>
                        </div>
                        <div className="flex w-1/2 gap-2 items-center">
                            <span className=" font-bold capitalize">{t("appearance")}:</span>
                            <span id="edit-mobile-appearance-name-selected">{design?.mobileAppTheme?.name}</span>
                            <button
                                id="edit-mobile-appearance-button"
                                className="icon icon-edit mt-1 cursor-pointer"
                                onClick={openChangeAppearanceModal}
                            ></button>
                        </div>
                    </div>
                ) : (
                    <span>{t("no-mobile-added-yet")}</span>
                )}
            </div>
        </>
    );
};
