export const PriorityOptions = (t) => {
    return [
        {
            id: "standard",
            name: t("{{capitalize, capitalize}}", { capitalize: t("standard") }),
            description: t("standard-msg-description"),
        },
        {
            id: "important",
            name: t("{{capitalize, capitalize}}", { capitalize: t("important") }),
            description: t("important-msg-description"),
        },
        {
            id: "priority",
            name: t("{{capitalize, capitalize}}", { capitalize: t("priority-type") }),
            description: t("priority-msg-description"),
        },
        {
            id: "critical",
            name: t("{{capitalize, capitalize}}", { capitalize: t("critical") }),
            description: t("critical-msg-description"),
        },
    ];
};

export const SENT_MSG = { name: "sent", value: "SENT", key: "messages-state" };
export const DRAFTS_MSG = { name: "drafts", value: "DRAFT", key: "messages-state" };
export const TEMPLATES_MSG = { name: "templates", value: "TEMPLATES", key: "messages-state" };
export const BIN_MSG = { name: "bin", value: "DELETED", key: "messages-state" };

export function arraysAreEqual(ary1, ary2) {
    if (!ary1 || (ary1 && ary1.length === 0 && ary2 && ary2.length === 0)) {
        return false;
    }
    return JSON.stringify(ary1) === JSON.stringify(ary2);
}

export const getDateFormated = (date) => {
    // formated : DD/MM/YY hh:mm
    if (!date) {
        return null;
    }
    const sentDate = new window.ZDate(date);
    return `${sentDate.toLocaleDateString([], {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
    })} ${sentDate.toLocaleTimeString("es-ES", {
        hour: "2-digit",
        minute: "2-digit",
    })}`;
};

export function isInViewport(elem) {
    let distance = elem.getBoundingClientRect();
    return distance.top < (window.innerHeight || document.documentElement.clientHeight) && distance.bottom > 0;
}

export const mapReasonToDestination = (reason) => {
    let destinationKey = "all";
    switch (reason) {
        case "all":
            destinationKey = "all";
            break;
        case "countryRefs":
            destinationKey = "countries";
            break;
        case "languageRefs":
            destinationKey = "languages";
            break;
        case "roomIDs":
            destinationKey = "rooms";
            break;
        case "roomGroupIDs":
            destinationKey = "roomGroups";
            break;
        case "floorIDs":
            destinationKey = "floors";
            break;
        case "wingIDs":
            destinationKey = "wings";
            break;
        case "stayGuestIDs":
            destinationKey = "stayGuests";
            break;
        case "stayGroupIDs":
            destinationKey = "stayGroups";
            break;
        default:
            destinationKey = "all";
            break;
    }
    return destinationKey;
};
