import React from "react";
import { useSelector } from "react-redux";
import sanitizeHtml from "sanitize-html";
import { getTextWidth } from "../../../hooks/Utils/Utils";

const UseTagTruncateCell = ({ cellData, rowIndex, colIndex }) => {
    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //order tags to show in same orden
    cellData.value.sort(function (a, b) {
        if (a[0] > b[0]) {
            return 1;
        }
        if (a[0] < b[0]) {
            return -1;
        }
        // a must be equal to b
        return 0;
    });

    const printLabel = (val, index) => {
        if (!val) {
            return;
        }
        let tooltipText = [];
        if (Array.isArray(val[3])) {
            val[3].forEach((elem) => {
                tooltipText.push(
                    `<div class="${cellData.noCapitalice ? "" : "first-capital"}">${
                        langStrings[elem] ? langStrings[elem] : elem
                    }</div>`
                );
            });
        } else if (typeof val[3] === "object" && val[3]) {
            tooltipText.push(val[3].content ? val[3].content : "");
        }

        return (
            <div
                id={cellData.rowIndex + val[1]}
                className={`rounded py-1 px-4 m-1 text-white inline-block  ${val[2]} ${
                    cellData.noCapitalice ? "" : "first-capital"
                } `}
                key={index}
                data-tip={sanitizeHtml(tooltipText.join(" "), {
                    allowedAttributes: { div: ["class"] },
                })}
                data-delay-show="1000"
                data-label="true"
                data-for="dangerous-html-tooltip"
            >
                {!cellData.name ? (langStrings[val[1]] ? langStrings[val[1]] : val[1]) : null}
                {cellData.name ? val[5] ? <span className={`icon ${val[5]}`}></span> : val[1] : null}
            </div>
        );
    };
    const printTruncate = () => {
        if (!cellData.value || cellData.value.length === 0) {
            return;
        }
        let itemsFit = 0;
        let itemsWidth = 0;

        const cellDiv = document.querySelector(`#r${cellData.rowIndex}_c${cellData.colIndex}`);
        if (cellDiv) {
            const cWidth = cellDiv.clientWidth;
            const cFont = getComputedStyle(cellDiv).fontSize;
            cellData.value.forEach((elem) => {
                const itemWidth =
                    parseFloat(
                        getTextWidth(
                            langStrings[elem[1]] ? langStrings[elem[1]] : elem[1],
                            cFont,
                            null,
                            `rounded py-1 px-4 m-1 text-white inline-block ${elem[2]}`
                        )
                    ) + 10; // add 20 to avoid multiple lines
                if (itemsWidth + itemWidth < cWidth) {
                    itemsFit++;
                    itemsWidth = itemsWidth + itemWidth;
                }
            });
        }

        let cellContent = [];
        let tooltipText = [];
        for (let index = 0; index < itemsFit; index++) {
            const element = cellData.value[index];
            cellContent.push(printLabel(element, index));
        }
        if (cellData.value[0][1] === "TV+CAST") {
            if (cellData.value.length - itemsFit !== 2) {
                const firstValue = cellData.value[0][1];
                if (firstValue === null) return;
                const formattedValue = langStrings[firstValue] ? langStrings[firstValue] : firstValue;
                const tooltipTextContent = firstValue === "TV+CAST" ? formattedValue.slice(3) : formattedValue;
                tooltipText.push(`<div class="font-bold">${tooltipTextContent}</div>`);
            } else {
                const firstValue = cellData.value[0][1];
                const formattedValue = langStrings[firstValue] ? langStrings[firstValue] : firstValue;
                tooltipText.push(`<div class="font-bold">${formattedValue}</div>`);
            }
        } else {
            cellData.value.forEach((elem) => {
                tooltipText.push(
                    `<div class="${cellData.noCapitalice ? "" : "first-capital"}">${
                        langStrings[elem[1]] ? langStrings[elem[1]] : elem[1]
                    }</div>`
                );
            });
        }

        return (
            <>
                {cellContent.map((elem) => elem)}
                {cellData.value.length - itemsFit > 0 ? (
                    <span
                        className="w-10 ml-2 text-zafiro-600 font-normal cursor-default"
                        data-tip={tooltipText.join(" ")}
                        data-for="dangerous-html-tooltip"
                    >
                        +{cellData.value.length - itemsFit}
                    </span>
                ) : null}
            </>
        );
    };

    return <>{printTruncate()}</>;
};

export default UseTagTruncateCell;
