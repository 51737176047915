import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    cleanTableStore,
    setCount,
    setCountPosition,
    setError,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setRefreshData,
    setActions,
    setHeaderButtons,
    setAvailableFilters,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetSettingsTableHeaders";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import { Session } from "../../Utils/Session";
import { useTranslation } from "react-i18next";

//Model
import Role from "./models/Role";
import { useMSQuery } from "../useMSQuery";

const ListRoles = () => {
    const { t } = useTranslation();
    //Store data
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData } = useSelector((state) => state.table);

    //Data
    const sectionName = "list-roles";
    const cookieCHotel = Session.getSessionProp("currentHotel");
    const HOTEL_ID = cookieCHotel ? JSON.parse(cookieCHotel).id : "";
    const projects = JSON.parse(Session.getSessionProp("projects"));
    const projectData = projects ? projects.filter((project) => parseInt(project.id) === parseInt(HOTEL_ID))[0] : null;

    //States
    const [sorting] = useState(`,orderBy:{field: "id", criteria: "desc"}`);

    //Actions
    const dispatch = useDispatch();

    //API
    const GET_ROLES_INFO = gql`
        {
            roles(page: ${currentPage}, size: ${perPage}${sortCriteria ? sortCriteria : sorting}${filterQuery}){
               info { count }
              results{ id name users accesses{ id:name name} }}
        }`;
    const GET_FILTERS_DATA = gql`
        {
              projectAccesses : permissions (projectCode:"${
                  projectData ? (projectData.ref === "CORPORATE" ? projectData.ref : projectData.code) : ""
              }" ${projectData && projectData.ref === "CORPORATE" ? " ,allChainPermissions:true" : ""})      
              accesses (orderBy:{field:"category",criteria:"asc"}){
                info { count }
                results { id:name name category }
              }
        }`;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_ROLES_INFO);
    const [executeFiltersQuery, filtersData] = useLazyQuery(GET_FILTERS_DATA);

    //Listeners
    useEffect(() => {
        dispatch(setLoading(true));
        dispatch(cleanTableStore());
        const tableHeaders = GetTableHeaders(sectionName);
        dispatch(setSearchable(true));
        dispatch(setHeaders(tableHeaders));
        dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
        dispatch(setCountPosition("table-header"));
        dispatch(showRowForm(false));
        executeQuery();
        executeFiltersQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (!loading && !error && data && data.roles && networkStatus === 7) {
            dispatch(setLoading(true));
            //Get data
            const dataItems = arrangeData(data, t);
            dispatch(setTableResults(dataItems));
            dispatch(setCount(data.roles.info.count));
            //Add to store

            dispatch(setActions());
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, networkStatus]);

    useEffect(() => {
        if (
            !filtersData.error &&
            !filtersData.loading &&
            filtersData &&
            filtersData.data &&
            filtersData.data.projectAccesses &&
            filtersData.data.accesses
        ) {
            const availableFilters = GetAvailableFilters(sectionName, extractAccessesList(filtersData.data, t));
            dispatch(setAvailableFilters(availableFilters));
        }
        // eslint-disable-next-line
    }, [filtersData]);

    useEffect(() => {
        if (error) {
            dispatch(setError(error));
        }
        if (filtersData.error) {
            dispatch(setError(filtersData.error));
        }

        // eslint-disable-next-line
    }, [error, filtersData]);
};

const arrangeData = (data, t) => {
    const roleData = [];
    // eslint-disable-next-line
    data.roles.results.map((role) => {
        roleData.push(Role(role, t));
    });
    return roleData;
};

const extractAccessesList = (data, t) => {
    let accessesArray = [];
    data.accesses.results.map((accesse) =>
        data.projectAccesses.includes(accesse.name) || accesse.category === "General Settings"
            ? accessesArray.push({
                  value: t(accesse.name),
                  id: accesse.name,
                  visible: true,
              })
            : null
    );
    accessesArray.sort((a, b) => String(a.value).localeCompare(String(b.value)));
    return accessesArray;
};

export default ListRoles;
