import { formatBytes } from "../../../Utils/Utils";
import { Session } from "../../../../hooks/Utils/Session";

const MediaLibrary = (
    content,
    viewMode,
    libraryTreeOptions,
    sideBarRef,
    lastUpdateInterfaz,
    searching,
    pendingTranscode
) => {
    const { ref, name, path, parentRef, type, lastModified, size } = content;
    const display = searching ? path + (type === "folder" ? "" : (path === "/" ? "" : "/") + name) : name;
    const dataType = "media-library";
    const lastModifiedDate = new window.ZDate(lastModified);
    let adds = [];
    if (viewMode !== "map") {
        adds.push(
            {
                name: "type",
                cellContent: "text",
                value: type === "asset" ? "image" : type,
                specialWidth: "w-2/12",
                adjust: "first-capital",
                translate: true,
                hidden: viewMode === "map" ? true : false,
            },
            {
                name: "lastModified",
                cellContent: "text",
                value: lastModifiedDate.toLocaleString(),
                specialWidth: "w-3/12",
                hidden: viewMode === "map" ? true : false,
            },
            {
                name: "size",
                cellContent: "text",
                value: formatBytes(size),
                specialWidth: "w-2/12",
                hidden: viewMode === "map" ? true : false,
            }
        );
    }
    const posterSrc = Session.getDasUrl(`thumb/${ref}?w=152&h=140&lastUpdateInterfaz=${lastUpdateInterfaz}`);
    const assetSrc = Session.getDasUrl(`${ref}?w=152&h=140&lastUpdateInterfaz=${lastUpdateInterfaz}`);

    return {
        dataType: dataType,
        actions: [{ type: dataType, value: ref }],
        id: ref,
        type: type,
        libraryTreeOptions: libraryTreeOptions,
        viewMode: viewMode,
        adjust: viewMode === "map" ? "inline-block mr-5 ml-5 mb-5 card-item-container" : null,
        info: [
            {
                isFromUrl: type !== "folder",
                urlPath: type !== "folder" ? (type === "video" ? posterSrc : assetSrc) : "",
                path: "icons/",
                name: type === "folder" ? "folder" : "image",
                type: "svg",
                cellContent: "image",
                link:
                    type === "folder"
                        ? `/design/library/${ref}/${sideBarRef}`
                        : `/design/library/${parentRef}/${ref}/${sideBarRef}`,
                linkAdjust:
                    viewMode === "map"
                        ? type !== "folder"
                            ? "flex content-center m-auto"
                            : "flex content-center w-full"
                        : "",
                value: display,
                specialWidth:
                    viewMode === "map"
                        ? "w-12/12 text-center pt-0 pb-0 card-item-container-image"
                        : "w-1/12 text-center",
                cellAdjust:
                    viewMode === "map" ? "w-full h-40 flex content-center" : searching ? "w-14 h-8" : "w-28 h-16",
                adjust:
                    viewMode === "map"
                        ? `${type === "folder" ? "w-1/2 h-full" : "max-w-full max-h-full"} m-auto align-middle z-0`
                        : `${type === "folder" ? "w-full" : "max-w-full"} max-h-full m-auto align-middle`,
            },
            {
                name: "name",
                cellContent: "link",
                value: display ? display : name,
                specialWidth: viewMode === "map" ? "w-12/12 text-center pb-0 pt-0 mb-5" : "w-4/12 overflow-hidden",
                link:
                    type === "folder"
                        ? `/design/library/${ref}/${sideBarRef}`
                        : `/design/library/${parentRef}/${ref}/${sideBarRef}`,
                adjust: "truncate",
                textTooltip: `${viewMode === "map" ? display : ""}`,
                icon: pendingTranscode ? "warning-lines" : null,
                iconAdjust: "text-xl text-orange-500",
                iconTooltip: "transcode-pending",
                iconRight: true,
                linkAdjust: "flex",
            },
            ...adds,
        ],
    };
};

export default MediaLibrary;
