export const GetSalesTablesHeaders = (section, props) => {
    let res = [];
    //Sort icon
    const tableSortIcon = { name: "table-sort" };

    //Special width classes
    const w112 = "w-1/12";
    const w212 = "w-2/12";
    const w312 = "w-3/12";
    const w412 = "w-4/12";
    const w512 = "w-5/12";
    // const w612  "w-6/12";
    //const w712  "w-7/12";
    //const w812  "w-8/12";
    const w912 = "w-9/12";
    //const w1012  "w-10/12";
    const w1112 = "w-11/12";

    //Header types
    const assignedUsers = { name: "assigned-users", sortName: "total_users" };
    const state = { name: "state-word", sortName: "available" };
    const name = { name: "name", sortName: "name" };
    const email = { name: "email", sortName: "email" };
    const taxes = { name: "taxes", sortName: "taxes" };
    const categories = { name: "categories", sortName: "nameCategories" };
    const numberProducts = { name: "numberProducts", sortName: "numberProducts" };
    const price = { name: "price", sortName: "price" };
    const shop = { name: "shop" };
    const pending = { name: "pending" };
    const inProgress = { name: "in-progress" };
    const ready = { name: "ready" };
    const emptyCell = { name: "" };

    switch (section) {
        case "list-shops":
            name.icon = tableSortIcon;
            assignedUsers.icon = tableSortIcon;
            name.specialWidth = w412;
            assignedUsers.specialWidth = w412;
            state.specialWidth = w412;
            emptyCell.specialWidth = w412;
            if (props.hasOrdersManagement) {
                res.push(name, assignedUsers, state, emptyCell);
            } else {
                res.push(name, state, emptyCell);
            }

            break;
        case "list-categories":
            categories.icon = tableSortIcon;
            categories.specialWidth = w412;
            numberProducts.icon = tableSortIcon;
            numberProducts.specialWidth = w412;
            emptyCell.specialWidth = w412;
            if (props.levelCategory > 0) {
                categories.name = "name";
            }
            res.push(categories, numberProducts, emptyCell);
            break;
        case "list-products":
            name.icon = tableSortIcon;
            name.specialWidth = w512;
            taxes.icon = tableSortIcon;
            taxes.specialWidth = w212;
            price.icon = tableSortIcon;
            price.specialWidth = w212;
            price.tooltip = "price-listed-without-tax";
            state.icon = tableSortIcon;
            state.specialWidth = w212;
            emptyCell.specialWidth = w112;
            res.push(name, taxes, price, state, emptyCell);
            break;
        case "list-assigned-users":
            name.icon = tableSortIcon;
            name.specialWidth = w312;
            email.icon = tableSortIcon;
            email.specialWidth = w912;
            res.push(name, email);
            break;
        case "list-order-summary":
            shop.specialWidth = w412;
            pending.specialWidth = w212;
            inProgress.specialWidth = w212;
            ready.specialWidth = w212;
            emptyCell.specialWidth = w112;
            res.push(shop, pending, inProgress, ready, emptyCell);
            break;
        default:
            res = [""];
    }
    return res;
};

export default GetSalesTablesHeaders;
