import { useEffect } from "react";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Redux
import { useDispatch, useSelector } from "react-redux";

import { setNetworks } from "../../../actions/hotelActions";
import { setActionInput } from "../../../actions/globalActions";
import { showGlobalLoading } from "../../../actions/uiActions";
import Casts from "./models/Casts";

const CastConfig = () => {
    const dispatch = useDispatch();
    const { actionData } = useSelector((state) => state.action);

    //API Call
    const GET_WIFI_SETTING = gql`
        {
            ccpairing: chromecastSettings(kind: "") {
                results {
                    cec
                    enabled
                    pairingMode
                    philips
                    socket
                }
            }
            castwifi: wifiSettings(kind: "Cast") {
                results {
                    auth
                    enabled
                    hidden
                    name
                    password
                }
            }
            ccdailyreboot: chromecastsDailyReboot(kind: "") {
                results {
                    enable
                    rebootTime
                }
            }
            ccemailnotify: chromecastSettings {
                results {
                    enabled
                    emailNotify
                    cec
                    socket
                    philips
                    pairingMode
                }
            }
            cclowsignal: chromecastSettings {
                results{
                    lowSignalMessage
                }
            }
        }
    `;
    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_WIFI_SETTING, {
        pollInterval: 0,
    });

    const arrangeData = () => {
        let tempArray = [];
        // eslint-disable-next-line
        Object.keys(data).forEach((element) => {
            tempArray[element] = { ...data[element].results };
        });
        return Casts(tempArray);
    };

    useEffect(() => {
        if (actionData && actionData.refreshData) {
            refetch();
            dispatch(showGlobalLoading(false));
            dispatch(setActionInput({ refreshData: false }));
        }
        // eslint-disable-next-line
    }, [actionData]);
    
    useEffect(() => {
        if (!loading && !error && data) {
            const networksData = arrangeData();
            dispatch(setNetworks(networksData));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);
};

export default CastConfig;
