import React from "react";
import { capitalizeFirst } from "../../../../hooks/Utils/Utils";
import { useTranslation } from "react-i18next";
import uuid from "react-uuid";

export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_PMS = "UPDATE_PMS";
export const UPDATE_PASSCODE = "UPDATE_PASSCODE";
export const UPDATE_OPEN = "UPDATE_OPEN";

export const preventNegativeValues = (value) => {
    return Math.abs(value);
};

export const parseSecondsToDaysHours = (s) => {
    const days = Math.floor(s / 86400);
    const hours = Math.floor((s % 86400) / 0xe10);

    if (days > 0 && hours > 0) {
        return { d: days, h: hours };
    } else if (days > 0 && hours <= 0) {
        return { d: days, h: null };
    } else if (days <= 0 && hours > 0) {
        return { d: null, h: hours };
    } else if (days <= 0 && hours <= 0) {
        return { d: null, h: null };
    }
};

export const generateCardsIDs = () => {
    const arrIDs = [];
    for (let i = 0; i < 4; i++) {
        arrIDs.push(uuid());
    }
    return arrIDs;
};

export const generateCardsInfo = (cardsIDs) => {
    return [
        {
            name: "email",
            subname: "email",
            id: cardsIDs[0],
            access_type: "email",
        },
        {
            name: "room-surname",
            subname: "room-surname",
            id: cardsIDs[1],
            access_type: "pms",
        },
        {
            name: "passcode",
            subname: "passcode",
            id: cardsIDs[2],
            access_type: "code",
        },
        {
            name: "open-access",
            subname: "open-access",
            id: cardsIDs[3],
            access_type: "open",
        },
    ];
};

export const getFieldsEmailData = (data) => {
    return {
        "email-id": getValueFromData(false, data, "id", true),
        "email-breakconn": getValueFromData(false, data, "breakconn", true),
        "email-renovation": getValueFromData(false, data, "renovation", true),
        "email-social-networks": getValueFromData(false, data, "rrss", true),
        "email-name": getValueFromData(true, data, "name", false),
        "email-surname": getValueFromData(true, data, "surname", false),
        "email-date-of-birth": getValueFromData(true, data, "age", false),
        "email-country": getValueFromData(true, data, "country", false),
        "email-email": getValueFromData(true, data, "email", false),
    };
};

export const getFieldsPmsData = (data) => {
    return {
        "pms-id": getValueFromData(false, data, "id", true),
        "pms-breakconn": getValueFromData(false, data, "breakconn", true),
        "pms-devices-option": data && data.usersNumber === 0 ? "unlimited" : "custom",
        "pms-email": getValueFromData(true, data, "email", false),
        "pms-name": getValueFromData(true, data, "name", false),
        "pms-surname": getValueFromData(true, data, "surname", false),
        "pms-date-of-birth": getValueFromData(true, data, "age", false),
        "pms-country": getValueFromData(true, data, "country", false),
        "pms-users-number": getValueFromData(false, data, "usersNumber", true),
    };
};

export const getFieldsCodeData = (data) => {
    return {
        "code-id": getValueFromData(false, data, "id", true),
        "code-breakconn": getValueFromData(false, data, "breakconn", true),
        "code-email": getValueFromData(true, data, "email", false),
        "code-name": getValueFromData(true, data, "name", false),
        "code-surname": getValueFromData(true, data, "surname", false),
        "code-date-of-birth": getValueFromData(true, data, "age", false),
        "code-country": getValueFromData(true, data, "country", false),
    };
};

export const getFieldsOpenData = (data) => {
    return {
        "open-id": getValueFromData(false, data, "id", true),
        "open-breakconn": getValueFromData(false, data, "breakconn", true),
        "open-renovation": getValueFromData(false, data, "renovation", true),
    };
};

export const validRenovationTime = (typeAccess) => {
    const renovationDays = document.querySelector(`#${typeAccess}-renovation-days`);
    const renovationHours = document.querySelector(`#${typeAccess}-renovation-hours`);
    if (renovationDays && renovationHours) {
        if (Number(renovationDays.value) <= 0 && Number(renovationHours.value) <= 0) {
            renovationHours.classList.add("border", "border-red-100");
            renovationDays.classList.add("border", "border-red-100");
            return false;
        } else {
            renovationHours.classList.remove("border", "border-red-100");
            renovationDays.classList.remove("border", "border-red-100");
            return true;
        }
    }
};

export const parseRenovationToSeconds = (typeAccess) => {
    const renovationDays = document.querySelector(`#${typeAccess}-renovation-days`);
    const renovationHours = document.querySelector(`#${typeAccess}-renovation-hours`);
    if (renovationDays && renovationHours) {
        return renovationDays.value * 86400 + renovationHours.value * 3600;
    }
};

export const numberDevices = (typeAccess) => {
    const numberValue = document.querySelector(`#${typeAccess}-devices-per-room`);
    if (numberValue) {
        return Number(numberValue.value);
    }
};

export const tooltipHTMLDataAccessProfile = (accessProfileData, t, capitalizeFirst) => {
    if (accessProfileData) {
        let uploadMin;
        let downloadMin;
        let uploadMax;
        let downloadMax;

        if (accessProfileData.name === "Default Access Profile") {
            uploadMin =
                Number(accessProfileData.uploadBWMin / 1000000) === 0 ? "-" : accessProfileData.uploadBWMin / 1000000;

            downloadMin =
                Number(accessProfileData.downloadBWMin / 1000000) === 0
                    ? "-"
                    : accessProfileData.downloadBWMin / 1000000;

            uploadMax =
                Number(accessProfileData.uploadBWMax / 1000000) === 0 ? "-" : accessProfileData.uploadBWMax / 1000000;

            downloadMax =
                Number(accessProfileData.downloadBWMax / 1000000) === 0
                    ? "-"
                    : accessProfileData.downloadBWMax / 1000000;
        } else {
            uploadMin = Number(accessProfileData.uploadBWMin) === 0 ? "-" : accessProfileData.uploadBWMin;

            downloadMin = Number(accessProfileData.downloadBWMin) === 0 ? "-" : accessProfileData.downloadBWMin;

            uploadMax = Number(accessProfileData.uploadBWMax) === 0 ? "-" : accessProfileData.uploadBWMax;

            downloadMax = Number(accessProfileData.downloadBWMax) === 0 ? "-" : accessProfileData.downloadBWMax;
        }

        return `
        <div class="flex flex-col text-gray-900 items-center"> 
            <div class="font-bold">${capitalizeFirst(t("min-bandwidth"))}</div> 
            <div class="flex items-center">
                <div class="icon icon-maximum text-1xl"></div>
                <div class="flex items-center mt-1">${uploadMin} Mb/s</div>
            </div>
            <div class="flex items-center">
                <div class="icon icon-minimum text-1xl"></div>
                <div class="flex items-center mt-1">${downloadMin} Mb/s</div>
            </div>
            <div class="font-bold">${capitalizeFirst(t("max-bandwidth"))}</div> 
            <div class="flex items-center">
                <div class="icon icon-maximum text-1xl"></div>
                <div class="flex items-center mt-1">${uploadMax} Mb/s</div>
            </div>
            <div class="flex items-center">
                <div class="icon icon-minimum text-1xl"></div>
                <div class="flex items-center mt-1">${downloadMax} Mb/s</div>
            </div>
        </div>
        `;
    } else {
        return `<p>Error</p>`;
    }
};

export const getValueFromData = (isAdditional, data, field, singleValue) => {
    if (data && isAdditional && data.additionalData && data.additionalData[field] && !singleValue) {
        return {
            enabled: data.additionalData[field].enabled,
            mandatory: data.additionalData[field].mandatory,
        };
    } else if (data && isAdditional && data.additionalData && !data.additionalData[field] && !singleValue) {
        return {
            enabled: false,
            mandatory: false,
        };
    }

    if (
        data &&
        !isAdditional &&
        (data[field] === false || data[field] === true || data[field] || data[field] === 0) &&
        singleValue
    ) {
        return data[field];
    } else if (data && !isAdditional && singleValue) {
        return false;
    }
};

export const isFieldEnabled = (fields, field) => {
    return fields && fields[field] && fields[field].enabled;
};

export const isFieldMandatory = (fields, field) => {
    return fields && fields[field] && fields[field].mandatory;
};

export const RenderInputPreview = ({ label, placeholder, mandatory, id }) => {
    const { t } = useTranslation();
    return (
        <div className=" mb-6 flex flex-col items-start ">
            <span className=" block text-sm font-medium text-gray-800 mb-2 " id={`${id}-label`}>
                {capitalizeFirst(t(label)) + " "}
                {mandatory ? "*" : ""}
            </span>
            <div id={id} className=" flex justify-start w-full text-base px-4 py-1 rounded bg-gray-200 text-gray-500">
                {placeholder}
            </div>
        </div>
    );
};

export const RenderCheckboxPreview = ({ text, id }) => {
    const { t } = useTranslation();

    return (
        <div className=" flex mb-1">
            <div className=" relative w-4 h-4 mr-4">
                <div
                    id={id}
                    className=" border absolute  border-gray-500 bg-gray-100 rounded-sm w-4 h-4"
                    style={{ top: "0.25rem" }}
                ></div>
            </div>
            <div className=" text-left text-base " id={`${id}-label`}>
                {capitalizeFirst(t(text))}
            </div>
        </div>
    );
};

export const RenderSocialNetworks = ({ id }) => {
    const { t } = useTranslation();

    return (
        <div className=" w-full ">
            <div className=" flex flex-col justify-center items-center ">
                <div
                    id={id}
                    className=" flex border px-5 justify-center items-center py-2 rounded mb-6 "
                    style={{ minWidth: "95%", maxWidth: "95%", border: "1px solid rgba(50, 50, 50, 0.20)" }}
                >
                    <div className=" w-6 h-6 mr-4">
                        <img src={require("../../../../assets/images/icons/google.svg")} alt="google logo"></img>
                    </div>
                    <span className=" block font-bold" style={{ color: "#323232" }}>
                        {t("connect-with-google")}
                    </span>
                </div>
                <div className=" border-b border-gray-400 mb-6 " style={{ minWidth: "110%", maxWidth: "110%" }}></div>
            </div>
        </div>
    );
};

export const RenderCheckBoxTerms = ({ id }) => {
    const { t } = useTranslation();

    return (
        <div className=" flex  mb-1">
            <div className=" relative w-4 h-4 mr-4">
                <div
                    id={id}
                    className=" border absolute  border-gray-500 bg-gray-100 rounded-sm w-4 h-4"
                    style={{ top: "0.25rem" }}
                ></div>
            </div>
            <div className=" text-left text-base " id={`${id}-label`}>
                {`${t("i-read-and-accept-the")} `}
                <span className={` text-base  font-bold text-zafiro-600 `}>
                    {capitalizeFirst(t("terms & conditions"))}
                </span>
                {` ${t("and")} `}
                <span className={` text-base  font-bold text-zafiro-600 `}>{capitalizeFirst(t("privacy-policy"))}</span>
            </div>
        </div>
    );
};

export const RenderButtonPreview = ({ text, id }) => {
    const { t } = useTranslation();

    return (
        <div className=" w-full flex justify-center mt-6  ">
            <div className=" px-8 py-1 bg-zafiro-600 text-white rounded" id={id}>
                {capitalizeFirst(t(text))}
            </div>
        </div>
    );
};

export const RenderFirstStep = ({ stepPreview, setStepPreview }) => {
    const { t } = useTranslation();

    return (
        <div
            id="first-step"
            onClick={() => {
                if (!stepPreview || stepPreview === 1) {
                    return;
                }
                setStepPreview(1);
            }}
            className={` flex flex-row h-10 gap-2 items-center justify-center cursor-pointer ${
                !stepPreview || stepPreview === 1 ? "text-gray-500" : "text-gray-800"
            } `}
        >
            <div className="icon-chevron-left text-xl"></div>
            <div>{capitalizeFirst(t("first-step"))}</div>
        </div>
    );
};

export const RenderSecondStep = ({ enabled, stepPreview, setStepPreview }) => {
    const { t } = useTranslation();

    return (
        <div
            id="second-step"
            onClick={() => {
                if (!enabled) {
                    return;
                }
                setStepPreview(2);
            }}
            className={` flex flex-row h-10 gap-2 items-center justify-center cursor-pointer ${
                !enabled || stepPreview === 2 ? "text-gray-500" : "text-gray-800"
            } `}
        >
            <div>{capitalizeFirst(t("second-step"))}</div>
            <div className=" icon icon-chevron-right text-xl"></div>
        </div>
    );
};

export const generateContent = (fieldEnabled, breakconn) => {
    let content;

    if (!fieldEnabled && !breakconn) {
        content = (
            <div
                className=" absolute bg-gray-200 h-5 w-5 rounded-full"
                style={{ left: "-0.1rem", width: "1.30rem" }}
            ></div>
        );
    } else if (!fieldEnabled && breakconn) {
        content = (
            <div
                className=" absolute border-4 border-gray-300 bg-white h-5 w-5 rounded-full"
                style={{ left: "-0.1rem", width: "1.30rem" }}
            ></div>
        );
    } else {
        content = null;
    }

    return content;
};

export const generateContent2 = (fieldEnabled, breakconn) => {
    let content;
    if (!fieldEnabled && !breakconn) {
        content = (
            <div
                className=" absolute border-4 border-gray-300 bg-white h-5 w-5 rounded-full"
                style={{ left: "-0.1rem", width: "1.30rem" }}
            ></div>
        );
    } else if (!fieldEnabled && breakconn) {
        content = (
            <div
                className=" absolute bg-gray-200 h-5 w-5 rounded-full"
                style={{ left: "-0.1rem", width: "1.30rem" }}
            ></div>
        );
    } else {
        content = null;
    }
    return content;
};
