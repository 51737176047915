import React from "react";
import { useSelector } from "react-redux";

const UseLoading = ({ adjust }) => {
    //Store data
    const { error } = useSelector((state) => state.table);

    return (
        <>
            {!error || error === "" ? (
                <div className={`t-loading ${adjust}`}>
                    <div className="lds-roller">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default UseLoading;
