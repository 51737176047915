import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";

const useCharge = ({ type }) => {
    const query = gql`
        query charge($orderID: ID!, $type: ChargeType!) {
            charges(filter: { extId: $orderID, type: $type }) {
                results {
                    cancelAt
                    pmsStatus
                }
            }
        }
    `;
    const [load, { data, loading, error, called }] = useLazyQuery(query, {
        fetchPolicy: "network-only",
        errorPolicy: "all",
    });

    return {
        called,
        load: ({ orderID }) => {
            if (load) {
                load({ variables: { orderID, type } });
            }
        },
        loading,
        error,
        ready: called && !loading && !error,
        data: data?.charges?.results?.[0] || null,
    };
};

export default useCharge;
