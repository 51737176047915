import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseInputText from "../Inputs/useInputText";
import { useTranslation } from "react-i18next";
import { changeActionValues } from "../../actions/globalActions";
import HTMLReactParser from "html-react-parser";

export const UseModalAddMobileApp = () => {
    function onChange(value) {
        dispatch(changeActionValues({ "app-name": value }));
    }

    const [nameApp, setNameApp] = useState("");
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { validations } = useSelector((state) => state.action);
    const { permissions } = useSelector((state) => state.ui);
    const nativeApps = permissions?.hotel?.nativeApps ? true : false;

    useEffect(() => {
        onChange(nameApp);
    }, [nameApp]);

    return (
        <>
            <p className="mb-2">{t("add-mobile-app-description-modal")}</p>
            <input
                type="text"
                id="add-mobile-app-name"
                className={`bg-gray-200 p-2 w-full ${
                    validations?.["app-name"] === false ? "border border-red-100" : ""
                }`}
                onChange={(e) => {
                    const value = e.target.value;
                    const domainRegex = /^[a-zA-Z0-9- ]*$/;
                    if (domainRegex.test(value) || value === "") {
                        setNameApp(value);
                    }
                }}
                placeholder={t("write-name-ph")}
                value={nameApp}
            ></input>
            {nativeApps && (
                <div className="mt-2 flex">
                    <i className="icon icon-warning text-orange-100 text-xl pr-2 pt-1" />
                    <span className=" text-left">{HTMLReactParser(t("add-native-app-message"))}</span>
                </div>
            )}
        </>
    );
};
