import translate from "../../../../lang/es/CommonZones/translate";
import { minutesToHours } from "../../../Utils/Utils";

const BookingCategory = (booking, displayedDates, commonzone) => {
    const dataType = "bookings";
    const updateBooking = "update-booking";
    const { id, peopleAmount, name, phone, email, status, bookingDate, bookingDone, reasonCanceled } = booking;
    const category = getCategory(displayedDates, bookingDate, id);
    const categoryName = getCategoryName(displayedDates, bookingDate);

    return {
        dataType: dataType,
        id: id,
        category: category,
        categoryName: categoryName,
        areaName: commonzone.name,
        bookingDate: bookingDate,
        info: [
            {
                name: "hour",
                cellContent: "text",
                value: new window.ZDate(bookingDate).toLocaleTimeString("es-ES", {
                    hour: "2-digit",
                    minute: "2-digit",
                }),
                specialWidth: "w-1/12",
            },
            {
                name: "peopleAmount",
                cellContent: "text",
                value: peopleAmount,
                specialWidth: "w-1/12",
            },
            {
                name: "name",
                cellContent: "text",
                value: name,
                specialWidth: "w-2/12",
            },
            {
                name: "phone",
                cellContent: "text",
                value: phone,
                specialWidth: "w-2/12",
            },
            {
                name: "email",
                cellContent: "text",
                value: email,
                specialWidth: "w-2/12",
                adjust: "truncate",
            },
            {
                name: "status",
                cellContent: "select",
                id: id,
                value: status.toString(),
                dataType: dataType,
                translate: true,
                specialWidth: "w-2/12",
                values: [
                    {
                        name: "state",
                        value: "0",
                        translate: true,
                        style: "",
                        itemType: dataType,
                    },
                    {
                        name: "state",
                        value: "1",
                        translate: true,
                        style: "success",
                        itemType: dataType,
                    },
                    {
                        name: "state",
                        value: "2",
                        translate: true,
                        style: "fail",
                        itemType: dataType,
                    },
                ],
                selected: status === 3 ? 2 : status,
                translatable: true,
            },
            {
                name: "booking-modify",
                cellContent: "btn-form-launcher",
                value: id,
                translate: true,
                specialWidth: "w-2/12",
                hidden: getModifyVisibility(status),
                launchForm: true,
                data: { id: id, action: updateBooking, name: updateBooking },
                isButton: true,
                buttonColor: "blue-outline",
                action: updateBooking,
            },
        ],
        extendedInfo: [
            {
                name: "booking-data",
                cellContent: "list",
                title: true,
                specialWidth: "w-4/12",
                listValues: [
                    {
                        name: "day-hour",
                        value: `${new window.ZDate(bookingDate).toLocaleDateString()} - ${new window.ZDate(
                            bookingDate
                        ).toLocaleTimeString("es-ES", {
                            hour: "2-digit",
                            minute: "2-digit",
                        })}`,
                    },
                    { name: "people", value: peopleAmount },
                    {
                        name: "duration",
                        value: commonzone
                            ? minutesToHours(parseInt(commonzone.bookingFreq) * parseInt(commonzone.slotsPerBooking))
                            : 0,
                    },
                    {
                        name: "booking-done",
                        value: new window.ZDate(bookingDone).toLocaleDateString(),
                    },
                ],
            },
            {
                name: "client-data",
                cellContent: "list",
                title: true,
                specialWidth: "w-4/12",
                listValues: [{ value: name }, { name: "phone", value: phone }, { name: "email", value: email }],
            },
            {
                name: "state",
                cellContent: "list",
                title: true,
                specialWidth: "w-4/12",
                listValues: [
                    {
                        name: "status",
                        value: status,
                        evaluate: true,
                        dataType: dataType,
                    },
                    {
                        name: "reason-cancelled",
                        value: reasonCanceled,
                        dataType: dataType,
                        hidden: getReasonVisibility(status),
                    },
                ],
            },
        ],
    };
};

//Format date
export const formattedDate = (bookingDate) => {
    //ISO 8601 standard Monday(1) Sunday (7)
    const weekDays = [
        translate("day-00"),
        translate("day-01"),
        translate("day-02"),
        translate("day-03"),
        translate("day-04"),
        translate("day-05"),
        translate("day-06"),
    ];
    const months = [
        translate("month-0"),
        translate("month-1"),
        translate("month-2"),
        translate("month-3"),
        translate("month-4"),
        translate("month-5"),
        translate("month-6"),
        translate("month-7"),
        translate("month-8"),
        translate("month-9"),
        translate("month-10"),
        translate("month-11"),
    ];
    const tempDate = new window.ZDate(bookingDate);
    const day = tempDate.getDate();
    //ISO 8601 standard Monday(1) Sunday (7)
    const weekDay = tempDate.getDay();
    const month = tempDate.getMonth();
    const year = tempDate.getFullYear();

    return `${weekDays[weekDay]} ${day} ${translate("of")} ${months[month]} ${translate("of")} ${year}`;
};

export const getCategory = (displayedDates, bookingDate, id) => {
    let res;
    if (!displayedDates || !displayedDates.includes(new window.ZDate(bookingDate).toLocaleDateString())) {
        res = id;
    } else {
        res = false;
    }
    return res;
};

export const getCategoryName = (displayedDates, bookingDate) => {
    let res;
    if (!displayedDates || !displayedDates.includes(new window.ZDate(bookingDate).toLocaleDateString())) {
        res = formattedDate(bookingDate);
    } else {
        res = false;
    }
    return res;
};

export const getModifyVisibility = (status) => {
    let res;
    if (status === 2 || status === 3) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export const getReasonVisibility = (status) => {
    let res;
    if (status < 2) {
        res = true;
    } else {
        res = false;
    }
    return res;
};

export default BookingCategory;
