import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HashRouter } from "react-router-dom";

//Toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Components
import Header from "./components/Header";
import Section from "./components/Section";
import Sidebar from "./components/Sidebar";
import UseGlobalLoading from "./components/useGlobalLoading";
import UseModal from "./components/useModal";
import Permissions from "./hooks/Utils/Permissions";
import Userguiding from "./hooks/Utils/Userguiding";
import { Session } from "./hooks/Utils/Session";
import Impersonated from "./components/Section/Impersonated";
import Warning from "./components/Section/Warning";
import SalesBackgroundUpdate from "./hooks/GraphqlCalls/Sales/SalesBackgroundUpdate";
//Actions
import { setLanguage, showGlobalLoading } from "./actions/uiActions";
//Apollo
import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-boost";
import { ApolloProvider } from "react-apollo";
//Utils
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import LoadFonts from "./hooks/GraphqlCalls/MediaLibrary/LoadFonts";
import Notifications from "./components/Section/Notifications";
import ReactTooltip from "components/ReactTooltip";
import { includeWidgetRenderLibrary } from "./hooks/Utils/Utils";
import { UserNetworkProvider } from "contexts/NetworkWarnings/UserNetwork";

const AppContent = () => {
    Session.init();
    const { i18n } = useTranslation();
    //Actions
    const dispatch = useDispatch();

    //Store data
    // to reload ApolloClient with new token
    const { token: rdxToken } = useSelector((state) => state.auth);
    const [token, setToken] = useState(Session.getSessionProp("token"));

    const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData: {
            __schema: {
                types: [], // no types provided
            },
        },
    });
    const cache = new InMemoryCache({ fragmentMatcher });

    useEffect(() => {
        console.log("TOKEN CHANGED TO: " + rdxToken + " create new apolloClient");
    }, [rdxToken]);

    const client = new ApolloClient({
        uri: Session.getApiUrl(),
        headers: token ? { Authorization: token } : null,
        cache,
        onError: ({ networkError, graphQLErrors, operation }) => {
            //Catch Unauthorized errors
            if (graphQLErrors) {
                dispatch(showGlobalLoading(false));
                graphQLErrors.map((e) => {
                    if (e.message.match(/unauthorized/i)) {
                        if (e.path.includes("getMonitorAvailability")) {
                            //TODO esto es un apaño temporal, hay que investigar por que la query se ejecuta de forma automatica a veces, siendo una lazyQuery
                            return;
                        }
                        Session.close();
                        if (window.location.hash && window.location.hash !== "#/login") {
                            window.location.reload();
                        }
                    }
                });
            }
        },
    });

    const currentLang = localStorage.getItem("lang");

    useEffect(() => {
        // Todo esto no es necesario cuando actualicemos a la v5 de react-tooltip
        const foundNewTooltip = (node) => {
            if (!node) return false;
            if (node.nodeType === 1 && node.hasAttribute("data-tip")) {
                return true;
            }
            if (node.childNodes.length === 0) return false;
            for (let child of node.childNodes) {
                if (foundNewTooltip(child)) {
                    return true;
                }
            }
            return false;
        };
        const observer = new MutationObserver((mutationsList) => {
            for (let mutation of mutationsList) {
                if (mutation.type === "childList") {
                    for (let node of mutation.addedNodes) {
                        if (foundNewTooltip(node)) {
                            ReactTooltip.rebuild();
                            return;
                        }
                    }
                }
            }
        });
        observer.observe(document.getElementById("root"), { attributes: false, childList: true, subtree: true });
        //

        window.addEventListener("visibilitychange", onVisibilityChange);
        includeWidgetRenderLibrary();
        //Sets lang to store
        if (!currentLang) {
            //set default lang
            const defaultLang = i18n.language.split("-")[0];
            Session.setLang(defaultLang);
            i18n.changeLanguage(defaultLang);
            dispatch(setLanguage(defaultLang));
        } else {
            i18n.changeLanguage(currentLang);
            dispatch(setLanguage(currentLang));
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    //Manage different project in different tabs
    const onVisibilityChange = (isVisible) => {
        if (!document.hidden) {
            Cookies.set("restoreSession", token ? true : false);

            //Copy session data that I want to maintain in new tab
            Session.saveBackup();
        }
    };

    const toolTipConfig = {
        place: "bottom",
        type: "light",
        offset: { top: -8, left: -8 },
        border: true,
        borderColor: "#D3DAE1",
        className: "max-w-2xl",
    };

    const toastConfig = {
        position: "top-right",
        className: "z-505 mt-24 first-capital",
        draggable: true,
        pauseOnHover: true,
        closeOnClick: true,
        autoClose: 3000,
    };

    return (
        <ApolloProvider client={client}>
            <HashRouter>
                <Permissions token={token} />
                <Userguiding />
                <Impersonated setApolloToken={setToken} />
                <Notifications />
                <Warning />

                {/* DON'T REMOVE */}
                <div>
                    <UseGlobalLoading />
                    <Header />
                    <Sidebar />
                    <LoadFonts />
                    <UserNetworkProvider>
                        <Section />
                    </UserNetworkProvider>
                    <UseModal />
                    <SalesBackgroundUpdate />
                    <ToastContainer {...toastConfig} />
                    <ReactTooltip id="default-tooltip" multiline={true} {...toolTipConfig} />
                    <ReactTooltip id="dangerous-html-tooltip" dangerousHtml={true} {...toolTipConfig} />
                </div>
            </HashRouter>
        </ApolloProvider>
    );
};

export default AppContent;
