import React, { useEffect } from "react";
import { closeModal, openModal, setModalContent } from "actions/uiActions";
import UseTitle from "components/useTitle";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import { useGetReport } from "../hooks/useGetReport";
import { capitalizeFirst } from "hooks/Utils/Utils";
import { formatDate } from "../services/formatDate";
import { useIsPending } from "../hooks/useIsPending";
import { useLastReport } from "../hooks/useLastReport";

export const modal = (component) => {
    return {
        id: "1",
        useGlobalLoading: true,
        bodyAdjust: "w-4/12 m-0",
        executeSectionQuery: true,
        inputs: [
            {
                type: "component",
                component: component,
            },
        ],
    };
};

export const AreYouSure = ({ refetch }) => {
    const { t } = useTranslation();
    const { isPending } = useIsPending();
    const dispatch = useDispatch();

    const reportModal = () => {
        dispatch(
            setModalContent({
                id: "1",
                useGlobalLoading: true,
                bodyAdjust: "w-4/12 m-0",
                executeSectionQuery: true,
                inputs: [
                    {
                        type: "component",
                        component: <ReportContent refetch={refetch} />,
                    },
                ],
            })
        );
        dispatch(openModal());
    };

    const lastReportModal = () => {
        dispatch(
            setModalContent({
                id: "1",
                useGlobalLoading: true,
                bodyAdjust: "w-4/12 m-0",
                executeSectionQuery: true,
                inputs: [
                    {
                        type: "component",
                        component: <LastReportContent t={t} />,
                    },
                ],
            })
        );
        dispatch(openModal());
    };

    return (
        <div className="flex flex-col items-center">
            <h2 className="text-xl font-bold">{t("validate-networks")}</h2>
            <p className="my-6">{t("pair-check")}</p>
            <div className="flex gap-4">
                <button className="btn-white p-4 rounded  btn-blue-outline" onClick={() => dispatch(closeModal())}>
                    {capitalizeFirst(t("cancel"))}
                </button>
                <button
                    className={`${isPending ? "btn-gray" : "btn-blue"}  p-4 rounded first-capital`}
                    disabled={isPending}
                    onClick={() => reportModal()}
                >
                    {t("pair-and-online")}
                </button>
            </div>
            <p className="my-4">{t("or")}</p>
            <button className="btn-blue p-4 rounded first-capital" onClick={() => lastReportModal()}>
                {t("show-last-report")}
            </button>
        </div>
    );
};

const ReportContent = ({ refetch }) => {
    const { isPending, textData } = useGetReport();
    const { t } = useTranslation();

    useEffect(() => {
        if (!isPending) {
            refetch.mdnsRefetch();
            refetch.networkRefetch();
            refetch.dhcpRefetch();
        }
    }, [isPending, refetch]);

    return (
        <>
            {isPending || isPending === null ? (
                <div className="flex flex-col justify-center items-center gap-6">
                    <Loading adjust={"block"} />
                    <p className="font-bold text-gray-800">{t("take-few-minutes")}</p>
                </div>
            ) : (
                <>
                    <div className="flex items-center justify-between">
                        <div className="flex gap-3 items-center">
                            <UseTitle text={t("last-report")} />
                            {textData?.isValid ? (
                                <i className="icon-tick-right text-green-100"></i>
                            ) : (
                                <i className="icon-remove text-red-100"></i>
                            )}
                        </div>
                        <span className="text-gray-800">{formatDate(textData?.lastUpdate)}</span>
                    </div>
                    <div className="mt-8 ml-20">{convertIntoList(textData?.information)}</div>
                </>
            )}
        </>
    );
};

const convertIntoList = (text) => {
    const list = text?.split("\n");
    return (
        <ul className="flex gap-2 flex-col">
            {list?.map((item, index) => (
                <li key={index} className="list-disc">
                    {item}
                </li>
            ))}
        </ul>
    );
};

const LastReportContent = ({ t }) => {
    const { lastUpdate, information, isValid } = useLastReport();

    return (
        <>
            {information ? (
                <ReportComponent lastUpdate={lastUpdate} information={information} t={t} isValid={isValid} />
            ) : (
                <p>{t("no-last-report")}</p>
            )}
        </>
    );
};

const ReportComponent = ({ lastUpdate, information, isValid, t }) => {
    return (
        <>
            <div className="flex items-center justify-between">
                <div className="flex gap-3 items-center">
                    <UseTitle text={t("last-report")} />
                    {isValid ? (
                        <i className="icon-tick-right text-green-100"></i>
                    ) : (
                        <i className="icon-remove text-red-100"></i>
                    )}
                </div>
                <span className="text-gray-800">{formatDate(lastUpdate)}</span>
            </div>
            <div className="mt-8 ml-20">{convertIntoList(information)}</div>
        </>
    );
};
