const EditNameShop = (data) => {
    return {
        title: "edit-name",
        bodyAdjust: "w-3/12",
        executeSectionQuery: true,
        inputs: [
            {
                type: "editNameShop",
                data: data ? data : null,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "edit-name-shop",
            },
        ],
    };
};

export default EditNameShop;
