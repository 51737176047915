//REACT
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Utils
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//ACTIONS
import { setSectionContent } from "../../../../actions/sectionActions";
import { setRefreshData } from "../../../../actions/tableActions";
import { QUERY_ALARMS_PART } from "../../../Utils/Customers/AlarmUtils";
import { showGlobalLoading } from "../../../../actions/uiActions";
import { POLLINTERVAL_15S } from "../../../Utils/Utils";

const RoomDetailGuestInfo = ({ roomId }) => {
    const dispatch = useDispatch();
    const { refreshData } = useSelector((state) => state.table);
    const [guestData, setGuestData] = useState(null);

    const GET_ROOM_INFO = gql`{
        guest: stays(roomID:${roomId}){
    		info{count}
    		results{
                guestID guestLang guestCountry guestName guestSurname guestTitle guestLoyaltyRef guestGroup{name} companions{name surname rooms{name}} rooms{RoomID number name stayGuestRoomID parentalcode} ${QUERY_ALARMS_PART}
            }
        }
    }`;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_ROOM_INFO, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (!loading && !error && data && data.guest && data.guest.results) {
            dispatch(showGlobalLoading(false));
            if (data.guest.info.count > 0) {
                setGuestData(data.guest.results[0]);
                dispatch(setSectionContent({ guest: data.guest.results[0] }));
            } else {
                setGuestData(null);
                dispatch(setSectionContent({ guest: null }));
            }
        } else if (!loading && !error && data && !data.guest) {
            setGuestData(null);
            dispatch(setSectionContent({ guest: null }));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        if (refreshData) {
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        if (loading) setGuestData("loading");
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        executeQuery();
        // eslint-disable-next-line
    }, []);

    return guestData;
};

export default RoomDetailGuestInfo;
