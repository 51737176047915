import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import RoomOrdersModal from "../../../../hooks/GraphqlCalls/Customers/modals/RoomOrdersModal";
import { openModal, setModalContent } from "../../../../actions/uiActions";
import { useDispatch, useSelector } from "react-redux";
import GetGuestOrders from "../../Customers/Guest/GuesOrders.js/GetGuestOrders";

const RoomDetailOrders = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const { room, guest } = useSelector((state) => state.sectionContent);
    const [loading, setLoading] = useState(true);
    const [ordersCount, setOrdersCount] = useState(0);

    const guestID = guest?.guestID;
    const guestRoomID = guest && room ? guest?.rooms?.find((r) => r.RoomID === room?.id)?.stayGuestRoomID : null;

    return (
        <div className=" bg-white rounded my-8 p-8">
            <GetGuestOrders
                guestID={guestID}
                guestRoomID={guestRoomID}
                setCount={setOrdersCount}
                setLoading={setLoading}
            />
            <div className=" w-full flex justify-between ">
                <div className=" text-lg font-bold">{t("orders")} </div>
                <i className=" icon icon-cart text-gray-800 text-3xl"></i>
            </div>
            <button
                onClick={
                    isNaN(ordersCount)
                        ? null
                        : () => {
                              const modalContent = RoomOrdersModal({ room });
                              dispatch(setModalContent(modalContent));
                              dispatch(openModal());
                          }
                }
                id="room-detail-show-orders"
                disabled={loading}
                className={` ${loading ? " text-gray-700" : "text-blue-600"} focus:outline-none mt-2 text-lg text-left`}
            >
                {t("show-orders")}
                {isNaN(ordersCount) ? (
                    <div className="text-red-500 text-sm">{t("mutation-error")}</div>
                ) : (
                    ` (${ordersCount})`
                )}
            </button>
        </div>
    );
};

export default RoomDetailOrders;
