import { useEffect, useState } from "react";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
    setCheckable,
    setHeaders,
    setTableResults,
    setLoading,
    setError,
    setCount,
    setBatchActive,
    cleanTableStore,
    setSearchable,
    setRefreshData,
    setCountPosition,
    setHeaderButtons,
} from "../../../../actions/tableActions";
import GetTableHeaders from "../../../Utils/Table/Headers/GetHotelTableHeaders";
import GetTableHeaderButton from "../../../Utils/Table/GetTableHeaderButton";
import RoomGroup from "./models/RoomGroup";
import { useTranslation } from "react-i18next";

function ListRoomGroups() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { currentPage, perPage, sortCriteria, refreshData, filterQuery } = useSelector((state) => state.table);
    const sectionName = "list-room-groups";
    const [sorting] = useState(`,orderBy:{field: "name", criteria: "ASC"}`);

    const GET_HOTEL_ROOMS = gql`
        {
            roomGroups: roomGroups (page:${currentPage}, size:${perPage} ${filterQuery}${
        sortCriteria ? sortCriteria : sorting
    }){
                info {
                    count
                }
                results {
                    id
                    name
                    numberRooms
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch }] = useLazyQuery(GET_HOTEL_ROOMS, {
        fetchPolicy: "network-only",
    });

    const arrangeData = () => {
        let tempArray = [];
        data.roomGroups.results.map((roomGroup) => {
            tempArray.push(RoomGroup(roomGroup, t));
            return null;
        });
        return tempArray;
    };

    useEffect(() => {
        if (!loading && !error && data && data.roomGroups) {
            dispatch(setLoading(true));
            dispatch(setCheckable(false));
            dispatch(setSearchable(true));
            dispatch(setHeaders(GetTableHeaders(sectionName)));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            dispatch(setCountPosition("table-header"));
            dispatch(setBatchActive(false));
            dispatch(setCount(Number(data.roomGroups.info.count)));
            const roomsData = arrangeData();
            dispatch(setTableResults(roomsData));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        dispatch(cleanTableStore());
        executeQuery();
        return () => {
            dispatch(cleanTableStore());
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error]);
}

export default ListRoomGroups;
