import React, { useCallback, useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

//Components
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import UseSectionHeader from "../../../../../../../useSectionHeader";
import UpdateExtras from "../../../../../../../../hooks/GraphqlCalls/Settings/UpdateExtras";
import UseButton from "../../../../../../../useButton";
import ExtraItemCard from "./ExtraItemCard";
import NewExtra from "../../../../../../../../hooks/GraphqlCalls/Sales/modals/NewExtra";
import DeleteExtra from "../../../../../../../../hooks/GraphqlCalls/Sales/modals/DeleteExtra";

//Utils
import { executeVendureQuery, getVendureApiData } from "../../../../../../../../hooks/Utils/Integrations/useVendure";
import {
    getExtraTypeAndSelection,
    getTranslationName,
    getProjectLangs,
} from "../../../../../../../../hooks/Utils/SalesUtils";
import { useTranslation } from "react-i18next";
import {
    ADD_PRODUCT_EXTRA,
    DELETE_PRODUCT_EXTRA,
    UPDATE_PRODUCT_EXTRA,
    UPDATE_PRODUCT_EXTRAS,
} from "../../../../../../../../hooks/Utils/Integrations/ShopSettingsUtils";
import { setExecuteQuery } from "../../../../../../../../actions/sectionActions";
import { changeGlobalAction } from "../../../../../../../../actions/globalActions";
import { openModal, setModalContent, showGlobalLoading } from "../../../../../../../../actions/uiActions";
import update from "immutability-helper";
import _ from "lodash";
import { SalesExtrasProvider } from "components/Section/Services/Sales/SalesContext";
import { SalesContextDeprecated } from "contexts/Sales";
import ShopHeadMenu from "components/Section/Services/Sales/ShopHeadMenu";

const Extras = () => {
    const dispatch = useDispatch();

    //Consts&states
    const { id: shopId, productId } = useParams();
    const { t } = useTranslation();
    const { projectLangs } = useSelector((state) => state.ui);
    const { executeQuery, refreshData } = useSelector((state) => state.sectionContent);
    const [langDefault, setLangDefault] = useState(null);
    const [loading, setLoading] = useState(true);
    const [productVariant, setProductVariant] = useState(null);
    const [channel, setChannel] = useState(null);
    const [taxCategories, setTaxCategories] = useState([]);
    const [productName, setProductName] = useState(null);
    const [items, setItems] = useState([]);
    const [editingItemId, setEditingItemId] = useState(null);
    const [defaultTaxID, setDefaultTaxID] = useState(null);
    const breadcrumbs = [
        {
            name: "products",
            translate: true,
            route: `/services/sales/shop/products/${shopId}`,
        },
        {
            name: productName,
            translate: false,
            route: `/services/sales/shop/${shopId}/product/${productId}`,
        },
    ];

    //queries&mutations
    const GET_DATA = arrangeQuery({ shopId, productId });

    //effects
    useEffect(() => {
        if (projectLangs) {
            const { langDefault } = getProjectLangs(projectLangs, t);
            setLangDefault(langDefault);
        }
        if (langDefault && productId) {
            refetch();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (langDefault && productId) {
            refetch();
        }
        // eslint-disable-next-line
    }, [langDefault, productId]);

    useEffect(() => {
        if (loading && !refreshData) {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [refreshData]);

    useEffect(() => {
        dispatch(showGlobalLoading(loading));
        // eslint-disable-next-line
    }, [loading]);

    useEffect(() => {
        if (!refreshData) {
            if (
                executeQuery &&
                executeQuery.action &&
                (executeQuery.action === UPDATE_PRODUCT_EXTRA ||
                    executeQuery.action === UPDATE_PRODUCT_EXTRAS ||
                    executeQuery.action === DELETE_PRODUCT_EXTRA ||
                    executeQuery.action === ADD_PRODUCT_EXTRA)
            ) {
                refetch();
            }
        }
        // eslint-disable-next-line
    }, [refreshData]);

    //handle&functions
    const refetch = async () => {
        setLoading(true);
        setEditingItemId(null);
        let channel = null;
        let productName = null;
        let product = null;
        let productVariant = null;
        let items = [];
        let taxCategories = [];
        let defaultTax = null;
        const response = await executeVendureQuery(getVendureApiData(), { queryBody: GET_DATA });
        if (response && response.data && !response.errors) {
            const {
                channel: channelData,
                product: productData,
                productVariant: productVariantData,
                items: itemsData,
                taxCategories: taxCategoriesData,
            } = arrangeData(response.data);
            channel = channelData;
            product = productData;
            productVariant = productVariantData;
            items = itemsData;
            taxCategories = taxCategoriesData;
            defaultTax = response.data.taxRates.items.find((tax) => tax.id === channelData.customFields.default_tax.id);
            defaultTax = defaultTax.category.id;
            productName = product && langDefault ? getTranslationName(product.translations, langDefault) : null;
        }
        setChannel(channel);
        setProductVariant(productVariant);
        setItems(items);
        setProductName(productName);
        setTaxCategories(taxCategories);
        setLoading(false);
        setDefaultTaxID(defaultTax);
    };

    const handleDeleteItem = (itemId, index) => {
        let item = items[index];
        const modal = DeleteExtra(item.id, getTranslationName(item.translations, langDefault));
        setEditingItemId(null);
        dispatch(setExecuteQuery(null));
        dispatch(
            changeGlobalAction({
                actionName: DELETE_PRODUCT_EXTRA,
                itemsAffected: [item.id],
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    const handleEditItem = (itemId) => {
        setEditingItemId(itemId);
    };

    const handleAvailableItem = (itemId, index, value) => {
        let item = items[index];
        setLoading(true);
        dispatch(
            setExecuteQuery({
                action: UPDATE_PRODUCT_EXTRA,
                params: {
                    id: item.id,
                    type: item.type,
                    available: value,
                    order: item.order,
                    appliesTo: item.appliesTo,
                    taxCategory: item.taxCategory,
                    options: item.options,
                    translations: item.translations,
                },
            })
        );
    };

    const handleMoveItems = (index) => {
        let requestData = [];
        let itemsClone = [...items];
        itemsClone.forEach((item, index) => {
            delete item["opacity"];
            requestData.push({
                id: item.id,
                type: item.type,
                available: item.available,
                order: index + 1,
                appliesTo: item.appliesTo,
                taxCategory: item.taxCategory,
                options: item.options,
                translations: item.translations,
            });
        });
        setItems(itemsClone);
        setLoading(true);
        dispatch(
            setExecuteQuery({
                action: UPDATE_PRODUCT_EXTRAS,
                params: {
                    items: requestData,
                },
            })
        );
    };

    const moveItemCard = useCallback(
        (dragIndex, hoverIndex, noAddOpacity = false) => {
            let dragCard = items[dragIndex];
            dragCard.opacity = !noAddOpacity ? 0 : null;
            setItems(
                update(items, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                })
            );
        },
        [items]
    );

    const handleAddExtra = () => {
        const modal = NewExtra({
            appliesTo: productId,
            taxCategory: defaultTaxID ?? productVariant.taxCategory.id,
            order: items.length + 1,
            langDefault: langDefault,
        });
        setEditingItemId(null);
        dispatch(setExecuteQuery(null));
        dispatch(
            changeGlobalAction({
                actionName: ADD_PRODUCT_EXTRA,
            })
        );
        dispatch(setModalContent(modal));
        dispatch(openModal());
    };

    //renders

    //response
    return (
        <SalesExtrasProvider>
            <ShopHeadMenu />
            <UseSectionHeader title="extra" customBreadCrumbs={breadcrumbs} navToPreviousPage={true} />
            <UpdateExtras />
            <div className="w-full">
                <div className="flex items-center w-full justify-end">
                    {items.length > 0 && (
                        <Link
                            to={`/services/sales/shop/${shopId}/product/${productId}/extras/translate`}
                            className="text-zafiro-600 cursor-pointer mr-5"
                        >
                            {t("translate")}
                        </Link>
                    )}
                    <UseButton
                        id={"add-extra-table-button"}
                        buttonName="add-extra"
                        buttonColor="btn-blue"
                        adjust="px-8 min-w-24"
                        action={handleAddExtra}
                    />
                </div>
            </div>
            <div className="w-full my-4">
                <DndProvider backend={HTML5Backend}>
                    {items && items.length > 0 ? (
                        items.map((item, index) => (
                            <ExtraItemCard
                                key={`extra-item-${index}`}
                                item={item}
                                index={index}
                                langDefault={langDefault}
                                extraData={{
                                    channel: channel,
                                    taxCategories: taxCategories,
                                    editingItemId: editingItemId,
                                    setEditingItemId: setEditingItemId,
                                    loading: loading,
                                    setLoading: setLoading,
                                    defaultTaxID: defaultTaxID,
                                }}
                                moveCard={moveItemCard}
                                handleDeleteItem={() => {
                                    handleDeleteItem(item.id, index);
                                }}
                                handleEditItem={() => {
                                    handleEditItem(item.id);
                                }}
                                handleAvailableItem={(value) => {
                                    handleAvailableItem(item.id, index, value);
                                }}
                                handleMoveItems={(index) => {
                                    handleMoveItems(index);
                                }}
                            />
                        ))
                    ) : (
                        <div className={`w-full bg-white rounded shadow p-2 mb-4`}>
                            <div className="w-full h-full text-center py-4">
                                <span>{t("no-extras-yet")}</span>
                            </div>
                        </div>
                    )}
                </DndProvider>
            </div>
        </SalesExtrasProvider>
    );
};

//arrangeData
const arrangeQuery = (props) => {
    const { shopId, productId } = props;

    return `query{
        channel: channel(id:${shopId}){
			currencyCode
            defaultTaxZone{
				id
			}
            customFields{
                default_tax{
                    id
                    name
                }
            }
		}
        product: product(id:${productId}){
            translations{
                languageCode
                name
            }
        }
        productVariants: productVariants(productId:${productId}){
            items{
                id
                taxCategory{
                    id
                }
            }
        }
        extras: extras(productId:${productId}){
            id
            available
            type
            appliesTo
            taxCategory
            order
            translations{
                languageCode
                name
            }
            options{
                min
                max
                priceWithoutTax
                choices{
                    available
                    priceWithoutTax
                    taxCategory
                    order
                    translations{
                        languageCode
                        name
                    }
                }
            }
        }
        taxRates: taxRates{
            items{
                id
                name
                value
                zone{
                    id
                }
                category{
                    id
                }
            }
        }
    }`;
};

const arrangeData = (data) => {
    let channel = null;
    let product = null;
    let productVariant = null;
    let items = [];
    let taxCategories = [];
    if (data) {
        product = data.product ? data.product : null;
        productVariant =
            data.productVariants && data.productVariants.items && data.productVariants.items.length > 0
                ? data.productVariants.items[0]
                : null;
        if (data.channel) {
            channel = data.channel;
            if (channel.defaultTaxZone && data.taxRates && data.taxRates.items && data.taxRates.items.length > 0) {
                data.taxRates.items
                    .filter((item) => item.zone.id === channel.defaultTaxZone.id)
                    .forEach((taxRate) => {
                        taxCategories.push({
                            value: taxRate.category.id,
                            text: `${taxRate.name} (${taxRate.value}%)`,
                            taxRateValue: taxRate.value,
                        });
                    });
            }
        }
        if (data.extras && data.extras.length > 0) {
            data.extras.forEach((extra) => {
                let typeAndSelection = getExtraTypeAndSelection(extra.type);
                items.push({
                    ...extra,
                    typeOfExtra: typeAndSelection.typeOfExtra,
                    typeOfSelection: typeAndSelection.typeOfSelection,
                });
            });
            items = _.orderBy(items, ["order"], ["asc"]);
        }
    }
    return { channel, product, productVariant, items, taxCategories };
};

export default Extras;
