import React from "react";
import { Provider } from "react-redux";
import { UploadFileContextProvider } from "contexts/UploadFile/uploadFileContext";
import store from "./store";
import AppContent from "./AppContent";
import OldManager from "./components/Section/OldManager";
import Support from "./components/Section/Support";

//CSS
import "react-tippy/dist/tippy.css";
import "./main.css";

function App() {
    // Both ZAFIRO_TV and ZAFIRO_Support are manually rewritten to index.html in lighttpd.conf
    // Any new location must have a corresponding rewrite in the configuration file to avoid a 404 error

    if (document.location.pathname === "/ZAFIRO_TV") {
        return <OldManager />;
    }

    if (document.location.pathname === "/ZAFIRO_Support") {
        return <Support />;
    }

    if (document.location.pathname.startsWith("/kibana")) {
        return <p>/kibana is not configured in this server</p>;
    }

    return (
        <UploadFileContextProvider>
            <Provider store={store}>
                <AppContent />
            </Provider>
        </UploadFileContextProvider>
    );
}

export default App;
