import Customers from "../components/Section/Customers/Customers";
import Guests from "../components/Section/Customers/Guest/Guests";
import GuestDetail from "../components/Section/Customers/Guest/GuestDetail";
import GuestGroups from "../components/Section/Customers/Guest/GuestGroups";
import { GuestConfig } from "components/Section/Customers/Guest/GuestConfig";
import BookingList from "../components/Section/Customers/BookingList";
import Customer from "../components/Section/Customers/Customer";
import AddGuest from "../components/Section/Customers/Guest/AddGuest";
import Messages from "../components/Section/Customers/Message/Messages";
import NewMessage from "../components/Section/Customers/Message/NewMessage";
import Alarms from "../components/Section/Customers/Alarms";
import PasscodesConfiguration from "../components/Section/Customers/PasscodesConfiguration";
import PasscodesPreview from "../components/Section/Customers/PasscodesPreview";
import { newRoute, newRedirect } from "./utils";
import ReservationDetailsContainer from "components/Section/Customers/Precheckin/ReservationDetails/ReservationDetailsContainer";
import BookingInformationDetailsContainer from "components/Section/Customers/Guest/BookingInformationDetails";
import { PreCheckInInformation } from "components/Section/Customers/Guest/PreCheckInInformation";
import AutomationGuestReport from "components/Section/Customers/Guest/AutomationGuestReport";

const sectionID = "customers";
const packageID = "guests";

const attrAlarms = "alarms";
const attrGuests = "guestList";
const attrGroups = "guestGroups";
const attrSettings = "guestConfig";
const attrCustomers = "loyaltyCustomers";
const attrMessages = "messages";
const attrPasscode = "wifiPasscodes";
const attrPreCheckin = "preCheckin";

const route = newRoute(sectionID, packageID);
const redirect = newRedirect(sectionID);

export const customersRoutes = [
    route("/", {
        redirect: redirect("/guests"),
    }),

    route("/guests", {
        attribute: attrGuests,
        redirect: redirect("/guests/guest-list"),
    }),
    route("/guests/guest-list", {
        section: Guests,
        attribute: attrGuests,
    }),
    /*
    route("/guests/general-settings", {
        section: GuestConfig,
        attribute: attrSettings,
    }),
    */
    route("/guests/guest-list/check-in", {
        section: AddGuest,
        attribute: attrGuests,
    }),
    /*
    route("/guests/general-settings/guest-report", {
        section: AutomationGuestReport,
        attribute: attrSettings,
    }),
    */
    route("/guests/guest-list/guest/:id", {
        section: GuestDetail,
        attribute: attrGuests,
    }),

    route("/guests/guest-list/reservation-information/:id", {
        section: BookingInformationDetailsContainer,
        attribute: attrPreCheckin,
    }),

    route("/guests/guest-list/reservation-details/:id", {
        section: ReservationDetailsContainer,
        attribute: attrPreCheckin,
    }),
    /*
    route("/guests/general-settings/pre-check-in-information", {
        section: PreCheckInInformation,
        attribute: attrPreCheckin,
    }),
    */
    route("/guests/guest-groups", {
        section: GuestGroups,
        attribute: attrGroups,
    }),

    route("/loyal-customers", {
        section: Customers,
        attribute: attrCustomers,
    }),

    route("/passcode-configuration", {
        section: PasscodesConfiguration,
        attribute: attrPasscode,
    }),
    route("/passcode-configuration/preview/:id", {
        hideSidebar: true,
        hideHeader: true,
        section: PasscodesPreview,
        attribute: attrPasscode,
    }),

    route("/messages", {
        attribute: attrMessages,
        redirect: redirect("/messages/list"),
    }),
    route("/messages/list", {
        section: Messages,
        attribute: attrMessages,
    }),
    route("/messages/new", {
        section: NewMessage,
        attribute: attrMessages,
    }),
    route("/messages/:msgFolder/:id", {
        section: NewMessage,
        attribute: attrMessages,
    }),

    route("/alarms", {
        attribute: attrAlarms,
        redirect: redirect("/alarms/list"),
    }),
    route("/alarms/list", {
        section: Alarms,
        attribute: attrAlarms,
    }),

    // route("/prechecking/booking-list", {
    //     section: BookingList,
    // }),

    route("/customer/configuration", {
        section: Customer,
    }),
];
