import React, { useEffect, useState, useRef, useCallback } from "react";
import CurrencyInput, { formatValue } from "react-currency-input-field";
import { useDispatch } from "react-redux";
import { setActionInput } from "../../actions/globalActions";
import { currenciesSymbolPlaceBefore, getSymbolCurrency, nonDecimalCurrencies } from "../../hooks/Utils/SalesUtils";

const UseCurrencyInput = ({
    value,
    onChange,
    maxNumber,
    inputData,
    actionOnChange,
    disabled,
    validation,
    actionOnBlur,
    adjust,
    id,
    currencyCode,
    forceIntegerNumbers = false,
}) => {
    const dispatch = useDispatch();
    const firstRenderValue = useRef(false);
    const [maxValue, setmaxValue] = useState(1000);
    const decimalSeparator = (1.1).toLocaleString().substring(1, 2);
    const prefixId = "currency-input";

    const handleValueChange = () => {
        const dataToAction = { [inputData.name]: value };
        dispatch(setActionInput(dataToAction));

        if (typeof actionOnChange === "function" && firstRenderValue.current) {
            const timeOutActionOnChange = setTimeout(() => {
                actionOnChange();
            }, 1500);
            return () => clearTimeout(timeOutActionOnChange);
        }
    };

    useEffect(() => {
        if (value) {
            if (!firstRenderValue.current) {
                firstRenderValue.current = true;
                handleValueChange();
            } else if (parseInt(String(value).replace(decimalSeparator, ".")) >= 0) {
                handleValueChange();
            }
        }
        // eslint-disable-next-line
    }, [value]);

    const handleContainerBlur = useCallback(
        (e) => {
            if (!e.currentTarget.contains(e.relatedTarget)) {
                if (typeof actionOnBlur === "function") {
                    actionOnBlur();
                }
            }
        },
        [actionOnBlur]
    );

    useEffect(() => {
        if (maxNumber) {
            setmaxValue(maxNumber);
        }
        // eslint-disable-next-line
    }, []);

    const updateValue = (change, value, decimalSeparator, onChange) => {
        const newValue = parseFloat(String(value).replace(decimalSeparator, ".")) + change;

        if (newValue >= 0) {
            const values = {
                float: forceIntegerNumbers ? newValue : newValue.toFixed(2),
                formatted: formatValue({
                    value: String(forceIntegerNumbers ? newValue : newValue.toFixed(2)),
                    decimalSeparator: decimalSeparator,
                    groupSeparator: "",
                }),
            };
            onChange(values);
        }
    };

    const handleDecrease = () => {
        let valueToDecrease = nonDecimalCurrencies.includes(currencyCode) ? -1 : -0.01;
        if (forceIntegerNumbers) {
            valueToDecrease = -1;
        }
        updateValue(valueToDecrease, value, decimalSeparator, onChange);
    };

    const handleIncrease = () => {
        let valueToIncrease = nonDecimalCurrencies.includes(currencyCode) ? 1 : 0.01;
        if (forceIntegerNumbers) {
            valueToIncrease = 1;
        }
        updateValue(valueToIncrease, value === null ? 0 : value, decimalSeparator, onChange);
    };

    const symbolPlace = currenciesSymbolPlaceBefore.includes(currencyCode) ? "LEFT" : "RIGHT";

    return (
        <div className=" flex items-center " onBlur={handleContainerBlur}>
            <div className=" relative ">
                {symbolPlace === "LEFT" && currencyCode ? (
                    <span
                        id={id ? `${prefixId}-placeholder-${id}` : null}
                        className={` absolute  text-gray-600 ${disabled ? "bg-gray-300" : "bg-gray-200"} `}
                        style={{ top: "50%", left: "8px", zIndex: "10", transform: "translateY(-50%)" }}
                    >
                        {getSymbolCurrency({ currency_code: currencyCode })}
                    </span>
                ) : null}
                <CurrencyInput
                    id={id ? `${prefixId}-${id}` : null}
                    name="input-name"
                    value={value}
                    min={0.0}
                    max={String(maxValue)}
                    onBlur={(e) => {
                        let currentValue = 0;
                        if (e.target.value) {
                            currentValue = parseFloat(String(e.target.value).replace(decimalSeparator, "."));
                        }
                        updateValue(0, currentValue, decimalSeparator, onChange);
                    }}
                    decimalsLimit={2}
                    className={`t-filter-input ${disabled ? " bg-gray-300 text-gray-700" : ""} w-full p-2 ${
                        currencyCode ? (symbolPlace === "RIGHT" ? " pr-10" : " pl-6") : ""
                    } } disable-browser-buttons-numbers ${!validation ? "border border-red-100 " : ""}${
                        !validation ? "border border-red-100" : ""
                    } ${adjust}`}
                    allowNegativeValue={false}
                    disableGroupSeparators={true}
                    decimalSeparator={decimalSeparator}
                    onValueChange={(value, name, values) => {
                        if (typeof onChange === "function") {
                            onChange(values);
                        }
                    }}
                />
                {symbolPlace === "RIGHT" && currencyCode ? (
                    <span
                        id={id ? `${prefixId}-placeholder-${id}` : null}
                        className={` absolute  text-gray-600 ${disabled ? "bg-gray-300" : "bg-gray-200"} `}
                        style={{ top: "50%", right: "8px", transform: "translateY(-50%)" }}
                    >
                        {getSymbolCurrency({ currency_code: currencyCode })}
                    </span>
                ) : null}
            </div>
            <div className={` flex flex-col ml-2 items-center justify-items-center text-gray-900`}>
                <span
                    id={id ? `${prefixId}-increase-arrow-${id}` : null}
                    tabIndex={0}
                    // id={`${idComponent}_increase`}
                    className={`${disabled ? " text-gray-400" : ""} cursor-pointer icon icon-chevron-up mb-2 text-sm`}
                    onClick={() => {
                        if (disabled) return;
                        if (typeof onChange === "function") {
                            handleIncrease();
                        }
                    }}
                ></span>
                <span
                    id={id ? `${prefixId}-decrease-arrow-${id}` : null}
                    tabIndex={0}
                    // id={`${idComponent}_decrease`}
                    className={`${disabled ? " text-gray-400" : ""} cursor-pointer icon icon-chevron text-sm`}
                    onClick={() => {
                        if (disabled) return;
                        if (typeof onChange === "function") {
                            handleDecrease();
                        }
                    }}
                ></span>
            </div>
        </div>
    );
};

export default UseCurrencyInput;
