import React from "react";
//Components
import UseSectionHeader from "../../../useSectionHeader";
import UseTable from "../../../Table";
//Utils
import ListRooms from "../../../../hooks/GraphqlCalls/Hotel/Rooms/ListRooms";

const Rooms = () => {
    ListRooms();

    return (
        <>
            <UseSectionHeader title={["list-of", "rooms"]} value={"demo"} />
            <UseTable />
        </>
    );
};

export default Rooms;
