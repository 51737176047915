import NewRoomGroupModal from "../modals/NewRoomGroupModal";
const RoomGroupViewModal = (name, roomGroupId) => {
    const roomsQuery = `
    {
        data: hotelRooms(filter: {roomGroupID:${roomGroupId}})
        {
            results { id name }
        }
    }`;
    return [
        {
            id: roomGroupId,
            bodyAdjust: "w-4/12",
            title: [name, "-", "room-group"],
            textAdjust: "font-bold pb-3",
            inputs: [
                {
                    text: ["rooms-added", ":"],
                    name: "rooms-of-group",
                    ph: "rooms",
                    type: "selectedView",
                    optionsQuery: roomsQuery,
                },
            ],

            buttons: [
                {
                    name: "edit",
                    style: "link",
                    customClass: "pl-0 pt-2 pb-2 cursor-pointer",
                    action: "load-next-modal",
                },
                { name: "accept", style: "blue", action: "close" },
            ],
            buttonAlignment: "between",
        },
        NewRoomGroupModal(name, roomGroupId)[0],
    ];
};

export default RoomGroupViewModal;
