import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { SalesContext } from "contexts/Sales";
import { ShopContext } from "contexts/Sales/Shop";

import { PMS_STATUS_DISABLED, PMS_STATUS_UNKNOWN } from "constants/charges";

import {
    ORDER_STATUS_CANCELLED,
    ORDER_STATUS_DELIVERED,
    ORDER_STATUS_PENDING,
    ORDER_STATUS_EXPIRED,
    ORDER_STATUS_INPROGRESS,
    ORDER_STATUS_READY,
    DELIVERY_METHOD_PICKUP,
    DELIVERY_METHOD_GEOLOCATION,
    useDeliveryLocationInfo,
} from "constants/sales";

import {
    executeVendureQuery,
    getVendureApiData,
    getVendureShopApiData,
} from "../../../../../../hooks/Utils/Integrations/useVendure";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { formatValue } from "react-currency-input-field";
import { capitalizeFirst, normalizeToSearch } from "../../../../../../hooks/Utils/Utils";
import { decimalSeparator, getSymbolCurrency, formatPriceValueVendure } from "../../../../../../hooks/Utils/SalesUtils";
import { changeGlobalAction } from "../../../../../../actions/globalActions";
import { openModal, setModalContent } from "../../../../../../actions/uiActions";
import { ORDER_ACTIONS } from "../../../../../../hooks/Utils/Services/OrdersUtils";
import { motion } from "framer-motion";
import { setExecuteQuery } from "actions/sectionActions";
import UpdateOrder from "../../../../../../hooks/GraphqlCalls/Sales/modals/UpdateOrder";
import UseComponentLoading from "../../../../../useComponentLoading";
import vendureCurrencies from "../../../../../../hooks/Utils/Integrations/vendure-currencies.json";
import RoomChangeInfo from "components/RoomChangeInfo";
import OrderStatus from "components/OrderStatus";

import useCharge from "hooks/Data/useCharge";

import ErrorInfo from "components/ErrorInfo";
import Loading from "components/Loading";

// Set the cache time for the extended info in seconds
const EXTENDED_INFO_CACHE = 15;

const OrderMonitorRow = ({ rowId, order, animations = true, orderCollapsed, setOrderCollapsed }) => {
    const { t } = useTranslation();
    const { permissions } = useContext(SalesContext);
    const { id } = useContext(ShopContext);

    const getDeliveryLocationInfo = useDeliveryLocationInfo();

    const dispatch = useDispatch();

    const [loadingOrder, setLoadingOrder] = useState(false);
    const [extendedInfo, setExtendedInfo] = useState(null);

    const isMonitoringAll = !id;
    const hasPMSManagement = permissions?.pmsManagement;

    const currentStatus = order?.statusData?.value;
    const isPending = currentStatus === ORDER_STATUS_PENDING;
    const isExpired = currentStatus === ORDER_STATUS_EXPIRED;
    const isCancelled = currentStatus === ORDER_STATUS_CANCELLED;
    const isDelivered = currentStatus === ORDER_STATUS_DELIVERED;
    const isDelayed = order?.delayed;
    const status = isExpired ? order?.prevStatusData?.value : currentStatus;

    const { load: loadCharge, loading: loadingCharge, error: errorCharge, data: charge } = useCharge({ type: "SHOP" });

    const showExtendedInfo = orderCollapsed === order?.id;

    const loading = loadingCharge || loadingOrder;

    const pmsStatus = charge?.pmsStatus || PMS_STATUS_UNKNOWN;

    //queries&mutations
    const GET_VENDURE_ORDER = `
        query{
            order(id:${order?.id}){
                id
                totalWithTax
                currencyCode
                payments{
                    id
                }
                fulfillments{
                    id
                }
                history{
                    items{
                        data
                    }
                }
                shippingLines{
                    shippingMethod{
                        code
                    }
                }
                lines{
                    id
                    quantity
                    productVariant{
                        name
                    }
                }
                customFields{
                    deliveryLocation
                }
            }
        }
    `;
    const GET_VENDURE_ORDER_INFO = `
        query{
            orderInfo(orderIds:[${order?.id}]){
                id
                customFields {
                    pickUp{
                        location
                    }
                    items {
                        name
                        quantity
                        extras {
                            ... on OrderInfoNormalAndNumericExtras {
                                name
                                quantity
                            }
                            ... on OrderInfoComboboxAndMulticomboboxExtras {
                                name
                                options {
                                    name
                                    quantity
                                }
                            }
                        }
                    }
                }
            }
        }
    `;

    const loadOrder = () => {
        setLoadingOrder(true);
        getOrderInfo()
            .then((data) => {
                if (data) {
                    setExtendedInfo({ data, updated: new Date().getTime() });
                } else {
                    toast.error(t("error-mutation"));
                    setExtendedInfo(null);
                }
                setLoadingOrder(false);
            })
            .catch(() => {
                toast.error(t("error-mutation"));
                setLoadingOrder(false);
                setExtendedInfo(null);
            });
    };

    useEffect(() => {
        if (
            showExtendedInfo &&
            (!extendedInfo || new Date().getTime() - extendedInfo?.updated > EXTENDED_INFO_CACHE * 1000)
        ) {
            if (hasPMSManagement) {
                loadCharge({ orderID: order?.id });
            }
            loadOrder();
        }
    }, [showExtendedInfo]);

    const handleAction = async ({ actionName }) => {
        if (actionName) {
            let actionData = null;
            Object.keys(ORDER_ACTIONS).forEach((key) => {
                if (ORDER_ACTIONS[key].name === actionName) {
                    actionData = ORDER_ACTIONS[key];
                }
            });
            if (actionData) {
                let orderInfo = extendedInfo?.data;
                if (!orderInfo) {
                    await getOrderInfo()
                        .then((data) => {
                            if (data) {
                                orderInfo = data;
                            } else {
                                toast.error(t("error-mutation"));
                            }
                        })
                        .catch(() => {
                            toast.error(t("error-mutation"));
                        });
                }
                if (orderInfo) {
                    let values = {
                        ...orderInfo,
                        shopToken: order?.shop?.token,
                    };
                    if (actionData.name === ORDER_ACTIONS.cancel.name) {
                        let modal = UpdateOrder({ orderData: order, actionData: actionData, t });
                        dispatch(
                            changeGlobalAction({
                                actionName: actionData.action.name,
                                itemsAffected: [order.id],
                                values: values,
                            })
                        );
                        dispatch(setModalContent(modal));
                        dispatch(openModal());
                    } else {
                        /**
                        Action Ready params:
                        {
                            shopToken: "",
                            orderData: {
                                id: "",
                                fulfillments: [],
                                lines: [
                                    {
                                        id: "",
                                        quantity: 1
                                    }
                                ],
                            }
                        }
                        */
                        dispatch(
                            setExecuteQuery({
                                action: actionData.action.name,
                                params: values,
                            })
                        );
                        setExtendedInfo(null);
                    }
                } else {
                    alert("orderInfo is null");
                }
            }
        }
    };

    const getOrderInfo = async () => {
        const shopToken = order?.shop?.token;
        let response = null;
        const resVendureAdmin = await executeVendureQuery(
            getVendureApiData(),
            { queryBody: GET_VENDURE_ORDER },
            { "vendure-token": shopToken }
        );
        if (resVendureAdmin?.data && !resVendureAdmin?.errors) {
            const resVendureShop = await executeVendureQuery(
                getVendureShopApiData(),
                { queryBody: GET_VENDURE_ORDER_INFO },
                { "vendure-token": shopToken }
            );
            response = await arrangeExtendedInfo({
                orderData: resVendureAdmin.data.order,
                orderInfo: resVendureShop?.data?.orderInfo ? resVendureShop?.data?.orderInfo[0] : null,
            });
        }
        return response;
    };

    const arrangeExtendedInfo = async (props = {}) => {
        const { orderData, orderInfo = null } = props;
        let response = null;
        if (orderData) {
            response = {
                id: orderData?.id,
                orderDetails: null,
                deliveryDetails: null,
                comments: null,
                total: null,
                orderData: { ...orderData },
            };
            if (orderData.history && orderData.history.items && orderData.history.items.length > 0) {
                let commentData = orderData.history.items.filter((item) => item.data?.note !== undefined);
                if (commentData && commentData.length > 0) {
                    response.comments = commentData[0].data.note;
                }
            }
            if (orderData.shippingLines && orderData.shippingLines.length > 0) {
                const deliveryMethod = orderData.shippingLines[0].shippingMethod?.code;
                const deliveryLocation = orderData?.customFields?.deliveryLocation;
                const info = getDeliveryLocationInfo(deliveryMethod, deliveryLocation, orderInfo);
                response.deliveryDetails =
                    info?.place &&
                    (deliveryMethod === DELIVERY_METHOD_PICKUP || deliveryMethod === DELIVERY_METHOD_GEOLOCATION)
                        ? capitalizeFirst(`${t("pick-up-place")}: ${info?.place}`)
                        : info?.name;
            }
            if (orderData.totalWithTax && orderData.totalWithTax > 0.0) {
                let total = formatValue({
                    value: String((orderData.totalWithTax / 100).toFixed(2)),
                    decimalSeparator: decimalSeparator,
                    groupSeparator: "",
                });
                response.total = `${total} ${
                    orderData?.currencyCode
                        ? getSymbolCurrency({
                              currency_code: vendureCurrencies.currencies[orderData.currencyCode].code,
                          })
                        : ``
                }`;
            }
            if (orderData.lines && orderData.lines.length > 0) {
                response.orderDetails = [];
                for (const line of orderData.lines) {
                    let title = `${line.quantity}x ${line.productVariant?.name}`;
                    let extras = [];
                    if (orderInfo?.customFields?.items) {
                        let orderInfoItem = null;
                        orderInfo.customFields.items.forEach((item) => {
                            if (
                                normalizeToSearch(item.name).includes(normalizeToSearch(line.productVariant?.name)) &&
                                item.quantity === line.quantity
                            ) {
                                orderInfoItem = item;
                            }
                        });
                        if (orderInfoItem?.extras) {
                            orderInfoItem.extras.forEach((extraData) => {
                                if (extraData) {
                                    if (extraData.options && extraData.options.length > 0) {
                                        extraData.options.forEach((optionData) => {
                                            extras.push({
                                                name: optionData?.name,
                                                quantity: optionData?.quantity,
                                            });
                                        });
                                    } else {
                                        extras.push({
                                            name: extraData?.name,
                                            quantity: extraData?.quantity,
                                        });
                                    }
                                }
                            });
                        }
                    }
                    response.orderDetails.push({
                        title: title,
                        extras: extras,
                    });
                }
            }
        }
        return response;
    };

    //Response
    return (
        <div key={order?.id}>
            <div className={`border-b border-gray-${isPending ? "300" : "100"}`}>
                <OrderEffect
                    animations={animations}
                    setOrderCollapse={(id) => {
                        setOrderCollapsed(orderCollapsed === id ? null : id);
                    }}
                    order={order}
                    status={currentStatus}
                    render={
                        <>
                            <div className="t-cell w-2/12">
                                <div className="w-full">
                                    <div className="flex justify-start items-center">
                                        <span
                                            id={`r${rowId}-c0-open-order-info`}
                                            className={`icon-chevron animated mr-4 ml-6 ${
                                                showExtendedInfo ? "-rotate-180" : ""
                                            }`}
                                        ></span>
                                        <div className="">
                                            <span className="block">{order?.id}</span>
                                            {isMonitoringAll ? (
                                                <span className="block text-gray-900 font-bold">{order?.shopName}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="t-cell w-2/12">
                                <div className="w-full">
                                    <span
                                        className={`block ${
                                            order?.parsedCustomerName === t("checked-out") ? "text-gray-600" : ""
                                        }`}
                                    >
                                        {order?.parsedCustomerName}
                                    </span>
                                    <span className="flex text-gray-800">
                                        {order?.roomName}
                                        <RoomChangeInfo
                                            className="ml-3"
                                            current={order?.roomName}
                                            previous={order?.origRoomName}
                                        />
                                    </span>
                                </div>
                            </div>
                            <div className="t-cell w-2/12">
                                <div className="w-full">
                                    <span className="block">{order?.parsedOrderHour}</span>
                                    <span className="block">{order?.parsedOrderDate}</span>
                                </div>
                            </div>
                            <div className="t-cell w-2/12">
                                <DeliveryCell
                                    {...{
                                        delivery: order?.deliveryMethodData,
                                        update: {
                                            date: order.updatedAtDate,
                                            hour: order.updatedAtHour,
                                        },
                                        isExpired,
                                        isCancelled,
                                        isDelivered,
                                    }}
                                />
                            </div>
                            <div className="t-cell w-4/12">
                                <div className="w-full pr-2">
                                    <div className="flex justify-between items-center">
                                        <div className="">
                                            <OrderStatus
                                                status={status}
                                                expired={isExpired}
                                                delayed={isDelayed}
                                                animate={animations}
                                            />
                                        </div>
                                        <div className="">
                                            <div className="flex justify-between items-center space-x-12">
                                                <div className="flex justify-between items-center space-x-2">
                                                    <MainActions
                                                        rowId={rowId}
                                                        status={currentStatus}
                                                        handleAction={handleAction}
                                                    />
                                                </div>
                                                <div className="flex justify-between items-center space-x-2">
                                                    <ExtraActions
                                                        rowId={rowId}
                                                        orderId={order?.id}
                                                        status={currentStatus}
                                                        handleAction={handleAction}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                />
                {showExtendedInfo ? (
                    <div className="t-extended-container items-baseline px-10">
                        {!loading ? (
                            <>
                                <div className="t-cell w-5/12">
                                    <div className="w-full">
                                        <div className="w-full block mb-5">
                                            <span className="font-bold">{t("order-details")}:</span>
                                            <span className="ml-2">
                                                <OrderDetails
                                                    products={extendedInfo?.data?.orderDetails}
                                                    rowId={rowId}
                                                />
                                            </span>
                                        </div>
                                        <div className="w-full block">
                                            <span className="font-bold">{t("total")}: </span>
                                            <span className="ml-2">
                                                {extendedInfo?.data?.total ??
                                                    formatPriceValueVendure(0, {
                                                        code: extendedInfo?.data?.orderData?.currencyCode,
                                                    })}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="t-cell w-5/12">
                                    <div className="w-full">
                                        <div className="w-full block mb-5">
                                            <span className="font-bold">{t("delivery-details")}:</span>
                                            <span className="ml-2" id={`delivery-details-order-${rowId}`}>
                                                {extendedInfo?.data?.deliveryDetails}
                                            </span>
                                        </div>
                                        <div className="w-full block">
                                            <span className="font-bold capitalize">{t("comments")}: </span>
                                            <span className="ml-2" id={`comments-order-${rowId}`}>
                                                {extendedInfo?.data?.comments}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {hasPMSManagement ? (
                                    <div className="t-cell w-2/12">
                                        <div className="w-full text-center">
                                            <span className="font-bold">{t("PMS charges")}</span>:
                                            <span className="ml-2">
                                                {loadingCharge ? (
                                                    <Loading zoom={20} className="mt-2" />
                                                ) : errorCharge ? (
                                                    <ErrorInfo>{errorCharge}</ErrorInfo>
                                                ) : (
                                                    <OrderStatus
                                                        className="inline-block"
                                                        pmsStatus={isPending ? null : pmsStatus}
                                                        colorize={false}
                                                    />
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}
                            </>
                        ) : (
                            <div className="w-full text-center">
                                <UseComponentLoading />
                            </div>
                        )}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default OrderMonitorRow;

const OrderEffect = (props = {}) => {
    const { setOrderCollapse, order, render, status, animations = true } = props;
    let response = (
        <div
            className={`t-info-container cursor-pointer ${
                status === ORDER_STATUS_PENDING ||
                ((status === ORDER_STATUS_INPROGRESS || status === ORDER_STATUS_PENDING) &&
                    order?.shop?.autoAcceptOrders)
                    ? "bg-gray-200"
                    : ""
            }`}
            onClick={(e) => setOrderCollapse(order?.id)}
        >
            {render}
        </div>
    );

    if (
        (status === ORDER_STATUS_PENDING && !order?.isPreloaded && !order?.shop?.autoAcceptOrders) ||
        (status === ORDER_STATUS_INPROGRESS && !order?.isPreloaded && order?.shop?.autoAcceptOrders)
    ) {
        response = animations ? (
            <motion.div
                initial="pending"
                animate="normal"
                transition={{
                    repeat: 5,
                    repeatType: "reverse",
                    duration: 1,
                }}
                variants={{
                    pending: {
                        backgroundColor: "#D3DAE1",
                        transition: { duration: 1 },
                    },
                    normal: {
                        backgroundColor: "#fff",
                        transitionEnd: {
                            backgroundColor: "#E9EDF0",
                        },
                    },
                }}
                className={`t-info-container cursor-pointer`}
                onClick={() => {
                    setOrderCollapse(order?.id);
                }}
            >
                {render}
            </motion.div>
        ) : (
            <div
                className={`t-info-container cursor-pointer`}
                style={{
                    backgroundColor: "#D3DAE1",
                }}
                onClick={() => setOrderCollapse(order?.id)}
            >
                {render}
            </div>
        );
    }

    return response;
};

const DeliveryCell = ({ delivery, update, isExpired, isCancelled, isDelivered }) => {
    const { t } = useTranslation();

    let iconName = delivery?.iconName;
    let firstTitle = delivery?.firstTitle;
    let secondTitle = delivery?.secondTitle;
    let mapLink = delivery?.mapLink;
    if (isCancelled || isDelivered || isExpired) {
        if (update?.hour) {
            firstTitle = update.hour;
        }
        if (update?.date) {
            secondTitle = update.date;
        }
        if (isCancelled) {
            iconName = "delete-editor";
        } else if (isExpired) {
            iconName = "logout";
        }
    }
    return (
        <div className="w-full">
            <div className="flex justify-start items-start">
                <span
                    className={`icon icon-${iconName} text-2xl mr-2`}
                    data-tip={delivery?.title}
                    data-for="default-tooltip"
                ></span>
                <div className="">
                    {firstTitle && <span className="block">{firstTitle}</span>}
                    {secondTitle && <span className="block">{secondTitle}</span>}
                    {mapLink && (
                        <span className="block">
                            <a
                                href={mapLink}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-300 hover:text-blue-500 focus:outline-none font-bold"
                            >
                                {t("Show map")}
                            </a>
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

const OrderDetails = (props = {}) => {
    const { products, rowId } = props;
    const { t } = useTranslation();
    let response = [];
    if (products && products.length > 0) {
        products.forEach((product, index) => {
            response.push(
                <div className="w-full mb-2" key={`${product.title}-${index}`}>
                    <span className="block" id={`product-name-order-${rowId}-product-${index}`}>
                        {product.title}
                    </span>
                    <div className="w-full pl-3" key={`extras-container-${index}`}>
                        {product.extras && product.extras.length > 0 && (
                            <div className="block pl-3 text-gray-600">
                                {t("extras")}:{" "}
                                {product.extras.map((extra, idxExtra) => {
                                    return (
                                        <>
                                            {idxExtra > 0 && <span key={`blank-space-${idxExtra}`}>, </span>}
                                            <span
                                                key={`product${index}-extra${idxExtra}`}
                                                id={`extra-name-product-${index}-extra-${idxExtra}`}
                                            >
                                                {`${extra.quantity}x ${extra.name}`}
                                            </span>
                                        </>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }
    return <div className="w-full">{response}</div>;
};

const MainActions = ({ status, handleAction, rowId }) => {
    const { t } = useTranslation();
    let response = null;
    const handleButtonAction = (e, action) => {
        e.preventDefault();
        e.stopPropagation();
        handleAction({ actionName: action });
    };
    if (status) {
        switch (status) {
            case ORDER_STATUS_INPROGRESS:
                response = (
                    <>
                        <button
                            id={`r${rowId}-${ORDER_ACTIONS.cancel.title}-button`}
                            onClick={(e) => {
                                handleButtonAction(e, ORDER_ACTIONS.cancel.name);
                            }}
                            className="cursor-pointer bg-white hover:bg-gray-200 border border-blue-400 text-blue-400 px-8 py-1 rounded-md w-32"
                        >
                            {capitalizeFirst(t(ORDER_ACTIONS.cancel.title))}
                        </button>
                        <button
                            id={`r${rowId}-${ORDER_ACTIONS.ready.title}-button`}
                            onClick={(e) => {
                                handleButtonAction(e, ORDER_ACTIONS.ready.name);
                            }}
                            className="cursor-pointer bg-blue-400 hover:bg-blue-600 hover:border-blue-600 border border-blue-400 text-white px-8 py-1 rounded-md w-32"
                        >
                            {capitalizeFirst(t(ORDER_ACTIONS.ready.title))}
                        </button>
                    </>
                );
                break;
            case ORDER_STATUS_PENDING:
                response = (
                    <>
                        <button
                            id={`r${rowId}-${ORDER_ACTIONS.cancel.title}-button`}
                            onClick={(e) => {
                                handleButtonAction(e, ORDER_ACTIONS.cancel.name);
                            }}
                            className="cursor-pointer bg-white hover:bg-gray-200 border border-blue-400 text-blue-400 px-8 py-1 rounded-md w-32"
                        >
                            {capitalizeFirst(t(ORDER_ACTIONS.cancel.title))}
                        </button>
                        <button
                            id={`r${rowId}-${ORDER_ACTIONS.accept.title}-button`}
                            onClick={(e) => {
                                handleButtonAction(e, ORDER_ACTIONS.accept.name);
                            }}
                            className="cursor-pointer bg-blue-200 hover:bg-blue-400 hover:border-blue-400 border border-blue-200 text-white px-8 py-1 rounded-md w-32"
                        >
                            {capitalizeFirst(t(ORDER_ACTIONS.accept.title))}
                        </button>
                    </>
                );
                break;
            case ORDER_STATUS_READY:
                response = (
                    <>
                        <button
                            id={`r${rowId}-${ORDER_ACTIONS.delivered.title}-button`}
                            onClick={(e) => {
                                handleButtonAction(e, ORDER_ACTIONS.delivered.name);
                            }}
                            className="cursor-pointer bg-blue-300 hover:bg-blue-500 hover:border-blue-500 border border-blue-300 text-white px-8 py-1 rounded-md w-32"
                        >
                            {capitalizeFirst(t(ORDER_ACTIONS.delivered.title))}
                        </button>
                    </>
                );
                break;
        }
    }
    return response;
};

const ExtraActions = (props = {}) => {
    const { status, orderId, rowId } = props;
    let response = null;
    if (status) {
        response = (
            <>
                <a
                    id={`r${rowId}-monitor-button`}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`/#/services/sales/order-monitor/preview/${orderId}/`}
                    className={"icon-external-link text-2xl text-gray-800 focus:outline-none "}
                ></a>
            </>
        );
    }
    return response;
};
