import { GetNationalitiesOptions } from "hooks/Utils/NationalityUtils";
import { capitalizeFirst } from "hooks/Utils/Utils";
import i18n from "i18n";
export const BOOKING_INFORMATION = "booking";
export const GUESTS = "guests";
export const PRE_CHECKIN_HISTORY = "pre-checkin-history";
export const MAIN_GUEST_ROLE = "MAIN";
export const PENDING_COMPLETION_STATE = "PENDING_COMPLETION";
export const PENDING_VALIDATION_STATE = "PENDING_VALIDATION";
export const PENDING_MODIFICATION_STATE = "PENDING_MODIFICATION";
export const VALIDATED = "VALIDATED";
export const COMPLETED = "COMPLETED";

export const EXCEPT_THIS_SYMBOLS_FROM_INPUT_NUMBER = ["e", "E", "+", "-", "."];

export const verifyCheckInAvailableArrivalDate = (date) => {
    const currentDate = new Date();
    const arrivalDate = new Date(date);
    return currentDate < arrivalDate;
};

export const formatAvalibaleOnCheckInDate = (date) => {
    const formatDate = new Date(date);
    let day = formatDate.getUTCDate().toString();
    if (day.length === 1) {
        day = "0".concat("", day);
    }
    let month = (formatDate.getUTCMonth() + 1).toString();
    if (month.length === 1) {
        month = "0".concat("", month);
    }
    const year = formatDate.getUTCFullYear().toString().slice(2, 4);
    return `${day}/${month}/${year}`;
};

export const SUBJECT_TRANSLATIONS = {
    DATA_RECEIVED: capitalizeFirst(i18n.t("received-data")),
    VALIDATED_DATA: capitalizeFirst(i18n.t("validated-data")),
    REMINDER_SENT: capitalizeFirst(i18n.t("reminder-sending")),
    PRE_CHECK_IN_SENT: capitalizeFirst(i18n.t("pre-check-in-sending")),
    CORRECTION_REQUEST: capitalizeFirst(i18n.t("modification-request")),
};

export const DOCUMENTS = [
    { id: "NID", name: capitalizeFirst(i18n.t("national_id")) },
    { id: "PASSPORT", name: capitalizeFirst(i18n.t("passport")) },
    { id: "DRIVER_LICENSE", name: capitalizeFirst(i18n.t("driving-license")) },
    { id: "RESIDENCE_PERMIT", name: capitalizeFirst(i18n.t("residence-permit")) },
];

export const AUTHORITIES = [
    { id: "POLICE", name: "Policía Nacional" },
    { id: "CIVIL_GUARD", name: "Guardia Civil" },
    { id: "SQUAD_MOSSOS", name: "Mossos d'Esquadra" },
    { id: "ERTZAINTZA", name: "Ertzaintzal" },
];

export const GENDERS = [
    { id: "male", name: capitalizeFirst(i18n.t("male-gender")) },
    { id: "female", name: capitalizeFirst(i18n.t("female-gender")) },
];

export const GET_COLOR_AND_TEXT = (state) => {
    if (PENDING_COMPLETION_STATE === state) {
        return {
            color: "text-gray-800",
            text: capitalizeFirst(i18n.t("pending-fill-in")),
        };
    } else if (PENDING_VALIDATION_STATE === state) {
        return {
            color: "text-orange-100",
            text: capitalizeFirst(i18n.t("pending-validation")),
        };
    } else if (PENDING_MODIFICATION_STATE === state) {
        return {
            color: "text-gray-600",
            text: capitalizeFirst(i18n.t("pending-modification")),
        };
    } else if (VALIDATED === state) {
        return {
            color: "text-green-100",
            text: capitalizeFirst(i18n.t("validated")),
        };
    } else if (COMPLETED === state) {
        return {
            color: "text-green-500",
            text: capitalizeFirst(i18n.t("completed")),
        };
    } else {
        return {
            color: "",
            text: "",
        };
    }
};

export const GET_COLOR_AND_TEXT_DOCUMENTATION_STATUS = (docType, docNumber) => {
    if (docType && docNumber) {
        return { text: i18n.t("documentation-added"), color: "text-green-100" };
    } else {
        return { text: i18n.t("pending-to-add"), color: "text-gray-600" };
    }
};

export const COMPOSE_MUTAION_UPDATE_GUEST_DATA = (values) => {
    let objDifferences = {};

    for (let [key, value] of Object.entries(values)) {
        if (key.startsWith("initial_")) {
            let keyWithoutPrefix = key.replace(/^initial_/, "");
            if (values.hasOwnProperty(keyWithoutPrefix) && values[keyWithoutPrefix] !== value) {
                objDifferences[keyWithoutPrefix] = values[keyWithoutPrefix];
            }
        }
    }

    console.log(objDifferences);

    let mutationStringParts = [];
    for (let [key, value] of Object.entries(objDifferences)) {
        switch (key) {
            case "title":
                mutationStringParts.push(`title: "${value}"`);
                break;
            case "name":
                mutationStringParts.push(`name: "${value}"`);
                break;
            case "surname":
                mutationStringParts.push(`surname: "${value}"`);
                break;
            case "email":
                mutationStringParts.push(`email: "${value}"`);
                break;
            case "birthday":
                mutationStringParts.push(`birthDate: "${value}"`);
                break;
            case "gender":
                mutationStringParts.push(`gender: ${value}`);
                break;
            case "mobilePhone":
                mutationStringParts.push(`phoneNumber: "${value}"`);
                break;
            case "language":
                mutationStringParts.push(`languageRef: "${value}"`);
                break;
            case "nationality":
                mutationStringParts.push(`countryRef: "${value}"`);
                break;
        }
    }

    const mutationString = mutationStringParts.join(",\n");

    return {
        mutationString,
        emailChanged: objDifferences["email"] ? true : false,
        roomChanged: objDifferences.room ? { newRoom: objDifferences.room, oldRoom: values?.initial_room || 0 } : null,
    };
};

export const BOOKING_INFORMATION_INITIAL_DATA = {
    PERSONAL_INFORMATION: {
        numberOfRooms: null,
        numberOfGuests: null,
        arrivalDate: null,
        departureDate: null,
    },
    BOOKING_HOLDER: {
        name: null,
        surname: null,
        email: null,
        id: null,
    },
    OBSERVATIONS: null,
    MORE_INFO: {
        state: null,
        reservationDate: null,
    },
};

export const GUEST_INFORMATION_INITIAL_DATA = {
    name: null,
    surname: null,
    gender: null,
    birthDate: null,
    email: null,
    phoneNumber: null,
    countryRef: null,
    stayBookingRoomIndex: null,
    documentType: null,
    documentNumber: null,
    dateOfIssue: null,
    id: null,
    index: null,
};

export const HANDLER_INTEGER_INPUT = (event, updateDataFunction) => {
    const value = event.target.value;
    const intValue = parseInt(value, 10);
    if (!Number.isNaN(intValue) && intValue.toString() === value) {
        updateDataFunction(intValue);
    } else if (value === "") {
        updateDataFunction("");
    }
};

const emailIsValid = (email) => {
    const mailformat =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return email && email.match(mailformat) ? true : false;
};

export const ARRANGE_BOOKING_INFORMATION_DATA = (data) => {
    const {
        numberOfGuests,
        numberOfRooms,
        arrivalDate,
        departureDate,
        guests,
        reservationDate,
        state,
        observations,
        pendingChangesSend,
    } = data;
    const initialData = { ...BOOKING_INFORMATION_INITIAL_DATA };
    const personalInfoUpdates = { numberOfGuests, numberOfRooms, arrivalDate, departureDate, pendingChangesSend };
    Object.entries(personalInfoUpdates).forEach(([key, value]) => {
        if (value) {
            initialData.PERSONAL_INFORMATION[key] = value;
        } else {
            initialData.PERSONAL_INFORMATION[key] = null;
        }
    });
    if (guests?.length > 0) {
        const mainGuest = guests.find((guest) => guest.guestRole === MAIN_GUEST_ROLE);
        if (mainGuest) {
            const { name, surname, email, id, pmsID } = mainGuest;
            Object.assign(initialData.BOOKING_HOLDER, { name, surname, email, id, pmsID });
        }
        initialData.GUESTS = guests.filter((guest) => guest.guestRole !== MAIN_GUEST_ROLE);
    }
    const moreInfoUpdates = { reservationDate, state };
    Object.entries(moreInfoUpdates).forEach(([key, value]) => {
        if (value) {
            initialData.MORE_INFO[key] = value;
        }
    });
    initialData.OBSERVATIONS = observations;

    return initialData;
};
export const ARRANGE_GUEST_INFORMATION_DATA = (data) => {
    if (data.guests) {
        let guests = data.guests
            .map((guest) => {
                const expeditionDate = guest.documentExtraInformation?.expeditionDate;

                let { documentExtraInformation, ...newGuest } = guest;

                if (expeditionDate && expeditionDate.trim() !== "") {
                    newGuest.dateOfIssue = expeditionDate.trim();
                }

                return newGuest;
            })
            .filter((guest) => guest.name !== null);

        const mainGuestIndex = guests.findIndex((guest) => guest.guestRole === "MAIN");
        if (mainGuestIndex > -1) {
            const [mainGuest] = guests.splice(mainGuestIndex, 1);
            guests.unshift(mainGuest);
        }
        guests = guests.map((guest, index) => ({ ...guest, index }));

        return guests;
    } else {
        return [];
    }
};
export const VALIDATE_BOOKING_INFORMATION_FIELDS = (data) => {
    const validations = {};
    const reasons = [];
    const trimCheck = (value) => value === null || value.trim() === "";
    const fieldsToCheck = {
        name: data.BOOKING_HOLDER.name,
        surname: data.BOOKING_HOLDER.surname,
        email: data.BOOKING_HOLDER.email,
    };
    Object.entries(fieldsToCheck).forEach(([key, value]) => {
        if (trimCheck(value)) {
            validations[key] = true;
        }
    });
    if (Number(data.PERSONAL_INFORMATION.numberOfRooms) <= 0) {
        validations.numberOfRooms = true;
    }
    if (Number(data.PERSONAL_INFORMATION.numberOfGuests) <= 0) {
        validations.numberOfGuests = true;
    }
    Object.entries(fieldsToCheck).forEach(([key, value]) => {
        if (trimCheck(value)) {
            validations[key] = true;
            if (!reasons.includes("there-are-empty-fields")) {
                reasons.push("there-are-empty-fields");
            }
        } else if (key === "email" && !emailIsValid(value)) {
            validations[key] = true;
            reasons.push("invalid email");
        }
    });

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const arrivalDateString = data.PERSONAL_INFORMATION.arrivalDate;
    const arrivalDate = arrivalDateString ? new Date(arrivalDateString + "T00:00:00") : null;
    const departureDateString = data.PERSONAL_INFORMATION.departureDate;
    const departureDate = departureDateString ? new Date(departureDateString + "T00:00:00") : null;
    if (!arrivalDate || arrivalDate < today) {
        validations.arrivalDate = true;
        if (!arrivalDate) {
            reasons.push("there-are-not-arrival-date-selected");
        }
        if (arrivalDate && arrivalDate < today) {
            reasons.push("the-arrival-date-cannot-be-earlier-than-the-current-date");
        }
    }
    if (arrivalDate && departureDate && arrivalDate > departureDate) {
        validations.arrivalDate = true;
        validations.departureDate = true;
        reasons.push("the-arrival-date-cannot-be-later-than-the-departure-date");
    }
    return { validations, reasons };
};
export const HANDLER_UPDATE_FIELD_GUEST = ({ index, field, value, data, setData }) => {
    if (field && index >= 0 && index < data.length) {
        const updatedData = [...data];
        updatedData[index] = { ...updatedData[index], [field]: value };
        setData(updatedData);
    }
};

export const FORMAT_DATE_TO_DDMMYYYY = (dateString) => {
    if (!dateString) {
        return "";
    }
    const parts = dateString.split("-");
    if (parts.length === 3) {
        return `${parts[2]}/${parts[1]}/${parts[0]}`;
    } else {
        return "";
    }
};

export const VALIDATE_GUEST_FIELDS = (data) => {
    const validations = {};
    let reasons = [];

    data.forEach((guest) => {
        const hasValidField = Object.keys(guest).some(
            (key) =>
                key !== "index" &&
                guest[key] !== null &&
                guest[key] !== "" &&
                (typeof guest[key] !== "string" || guest[key].trim() !== "")
        );

        if (hasValidField) {
            validations[guest.index] = {
                name: !guest.name || guest.name.trim() === "",
                surname: !guest.surname || guest.surname.trim() === "",
                email: !guest.email || guest.email.trim() === "" || !emailIsValid(guest.email),
            };

            // Verificar campos requeridos vacíos
            if (
                validations[guest.index].name ||
                validations[guest.index].surname ||
                !guest.email ||
                guest.email.trim() === ""
            ) {
                if (!reasons.includes("there-are-empty-fields")) {
                    reasons.push("there-are-empty-fields");
                }
            }

            // Verificar email inválido por formato
            if (guest.email && guest.email.trim() !== "" && !emailIsValid(guest.email)) {
                if (!reasons.includes("invalid email")) {
                    reasons.push("invalid email");
                }
            }
        }
    });

    Object.keys(validations).forEach((index) => {
        if (!validations[index].name && !validations[index].surname && validations[index].email !== true) {
            delete validations[index];
        }
    });

    return { validations, reasons };
};
export const FILTER_NON_EMPTY_GUESTS = (data) => {
    return data.filter((guest) => {
        const keys = Object.keys(guest).filter((key) => key !== "index");
        const isNotEmpty = keys.some((key) => {
            const value = guest[key];
            return value !== null && (typeof value !== "string" || value.trim() !== "");
        });

        return isNotEmpty;
    });
};

export const UPDATE_BOOKING_INFORMATION = "UPDATE_BOOKING_INFORMATION";
export const UPDATE_STAY_GUEST_TO_STAY_BOOKING = "UPDATE_STAY_GUEST_TO_STAY_BOOKING";
export const VALIDATE_DATA = "VALIDATE_DATA";
export const RESEND_PRE_CHECK_IN = "RESEND_PRE_CHECK_IN";
export const REQUEST_MODIFICATION = "REQUEST_MODIFICATION";
export const ACTIVATE_AUTOMATION_GUEST_REPORT = "ACTIVATE_AUTOMATION_GUEST_REPORT";
export const UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT = "UPDATE_PRE_CHECK_IN_TRAVELERS_REPORT";
export const DEACTIVATE_AUTOMATION_GUEST_REPORT = "DEACTIVATE_AUTOMATION_GUEST_REPORT";

export const verifyGuest = (stayBookingId, numberOfRooms, values) => {
    let mutation = "";
    let countNumberOfNewGuests = 0;
    for (let index = 0; index < numberOfRooms; index++) {
        countNumberOfNewGuests += values[`modal-checkin-guest-name-select-${index}`].filter(
            (item) => typeof item === "string" && item.includes("guest")
        ).length;
    }
    for (let index = 0; index < countNumberOfNewGuests; index++) {
        mutation += `addStayGuestToStayBooking${index}: addStayGuestToStayBooking(stayBookingID:${stayBookingId}) {
            error 
            id
            ok
        }
        `;
    }

    return { ok: countNumberOfNewGuests === 0, mutation: mutation, countNumberOfNewGuests: countNumberOfNewGuests };
};

export const mapRooms = (numberOfRooms, values, newIds) => {
    const rooms = [];
    let indexNewIds = 0;
    const noIdsGuestAsignation = {};
    let mainGuestID = values[`modal-checkin-main-guest-select`][0];

    for (let index = 0; index < numberOfRooms; index++) {
        let guests = values[`modal-checkin-guest-name-select-${index}`].map((val) => {
            if (typeof val === "string" && val.includes("guest")) {
                if (noIdsGuestAsignation[val]) {
                    return noIdsGuestAsignation[val];
                } else {
                    indexNewIds++;
                    noIdsGuestAsignation[val] = newIds[indexNewIds - 1];
                    if (mainGuestID == val) {
                        mainGuestID = noIdsGuestAsignation[val];
                    }
                    return newIds[indexNewIds - 1];
                }
            } else {
                return val;
            }
        });
        rooms.push(`{roomID:${values[`modal-checkin-room-select-${index}`]},guestIDs:[${guests}]}`);
    }
    if (
        typeof values[`modal-checkin-main-guest-select`][0] === "string" &&
        values[`modal-checkin-main-guest-select`][0].includes("guest")
    ) {
        mainGuestID = noIdsGuestAsignation[values[`modal-checkin-main-guest-select`][0]];
    } else {
        mainGuestID = values[`modal-checkin-main-guest-select`][0];
    }
    return { rooms, mainGuestID };
};

export const BUILD_STAY_GUEST_TO_STAY_BOOKING_MUTATION = (guest, index, stayBookingId) => {
    const fields = [
        `stayBookingID: ${stayBookingId}`,
        ...Object.keys(guest)
            .filter((key) => guest[key] !== null && key !== "id" && key !== "index" && key !== "__typename")
            .filter((key) => !(typeof guest[key] === "string" && guest[key].trim() === ""))
            .map((key) => {
                if (typeof guest[key] === "string" && key !== "gender") {
                    return `${key === "phone" ? "phoneNumber" : key}: "${guest[key].trim()}"`;
                }
                return `${key}: ${guest[key]}`;
            }),
    ].join(", ");

    return `
        addStayGuestToStayBooking${index}: addStayGuestToStayBooking(${fields}) {
            error 
            id
            ok
        }`;
};

export const BUILD_UPDATE_GUEST_MUTATION = (guest, index) => {
    const { id, dateOfIssue, ...restData } = guest;

    let documentExtraInformationField =
        dateOfIssue && dateOfIssue.trim() !== ""
            ? `documentExtraInformation: { expeditionDate: "${dateOfIssue.trim()}" }`
            : "";

    delete restData.__typename;
    delete restData.index;
    delete restData.phoneNumber;

    const guestDataFields = Object.keys(restData)
        .filter(
            (key) =>
                restData[key] !== null &&
                restData[key] !== "" &&
                (typeof restData[key] !== "string" || restData[key].trim() !== "")
        )
        .map((key) => {
            if (key === "gender") {
                return `${key}: ${restData[key]}`;
            } else if (key === "countryRef" && typeof restData[key] === "string") {
                return `${key}: "${restData[key].toUpperCase()}"`;
            } else if (typeof restData[key] === "string") {
                return `${key === "phone" ? "phoneNumber" : key}: "${restData[key].trim()}"`;
            } else if (Array.isArray(restData[key])) {
                return `${key}: [${restData[key].join(", ")}]`;
            } else {
                return `${key}: ${restData[key]}`;
            }
        });

    if (documentExtraInformationField) {
        guestDataFields.push(documentExtraInformationField);
    }
    const mutation = `
    updateGuest${index}: updateGuest(
        id: ${id},
        data: {${guestDataFields.join(", ")}}
    ) {
        error
        id
        ok
    }
    `;

    return mutation;
};

export const PARSE_AND_FORMAT_DATE = (dateString) => {
    //Function to parse "2024-03-08T15:13:56.231036+01:00" to  "08/03/2024 15:13"
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}`;
};

export const CONVERT_DATE_YMD_TO_DMY = (date) => {
    if (!date) {
        return null;
    }
    const dateParts = date.split("-");
    return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
};

export const GROUP_INPUTS_ARR_GUEST_SECTION = (t, roomOptions) => {
    return [
        [
            { placeholder: `${capitalizeFirst(t("ex"))} John`, keyInput: "name", type: "text", name: "name" },
            {
                placeholder: `${capitalizeFirst(t("ex"))} Scott`,
                keyInput: "surname",
                type: "text",
                name: "surname",
            },
            { keyInput: "gender", type: "select", optionData: GENDERS, name: "gender" },
            { keyInput: "birthDate", type: "date", name: "date-of-birth" },
        ],
        [
            {
                placeholder: "smith@gmail.com",
                keyInput: "email",
                type: "text",
                name: "email-address",
            },
            {
                keyInput: "phoneNumber",
                type: "phone",
                name: "phone-number",
            },
            {
                keyInput: "countryRef",
                type: "select",
                name: "nationality",
                hideSearch: false,
                optionData: GetNationalitiesOptions(),
            },
        ],
        [
            {
                keyInput: "documentType",
                optionData: DOCUMENTS,
                name: "document",
                type: "select",
            },
            {
                keyInput: "documentNumber",
                placeholder: "0000000",
                type: "text",
                name: "document-number",
            },
            {
                keyInput: "dateOfIssue",
                name: "date-of-issue",
                type: "date",
            },
        ],
        [
            {
                keyInput: "stayBookingRoomIndex",
                name: "room",
                type: "select",
                optionData: roomOptions,
            },
        ],
    ];
};

export const GET_ID_OF_EXTRA_GUESTS = (guests, numToKeep) => {
    const excessCount = guests.length - numToKeep;

    if (excessCount > 0) {
        return guests.slice(numToKeep).map((guest) => guest.id);
    } else {
        return [];
    }
};
