import React from "react";
import Header from "./Header";

export const TableHeader = ({ colsWidth, header }) => {
    return (
        <>
            {colsWidth ? (
                <colgroup>
                    {colsWidth.map((width, index) => (
                        <col key={index} style={{ width: `${width}%` }} />
                    ))}
                </colgroup>
            ) : null}
            {header ? <Header /> : null}
        </>
    );
};
