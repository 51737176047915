import { Session } from "./hooks/Utils/Session";
const { DateTime } = require("luxon");

export const SessionTimeZone = () => {
    const currentHotelData = Session.getSessionProp("currentHotel");
    if (currentHotelData) {
        const data = JSON.parse(currentHotelData);
        if (data && data.timeZone) {
            return data.timeZone;
        }
    }
    return "UTC";
};

export const SessionLanguage = () => {
    //TODO
    return "en";
};

const SessionDate = (value) => {
    const timezone = SessionTimeZone();
    const language = SessionLanguage();
    const valueType = typeof value;
    let date = null;
    switch (valueType) {
        case "object":
            date = DateTime.fromObject(value, { zone: timezone });
            break;
        case "number":
            date = DateTime.fromMillis(value, { zone: timezone });
            break;
        case "string":
            date = DateTime.fromISO(value, { zone: timezone });
            if (!date.isValid) {
                date = DateTime.fromRFC2822(value, { zone: timezone });
            }
            if (!date.isValid) {
                date = DateTime.fromHTTP(value, { zone: timezone });
            }
            if (!date.isValid) {
                date = DateTime.fromJSDate(value, { zone: timezone });
            }
            if (!date.isValid) {
                date = DateTime.fromSQL(value, { zone: timezone });
            }
            break;
        default:
            date = DateTime.now().setZone(timezone);
    }
    if (!date) {
        console.log({ value: value });
        // eslint-disable-next-line
        throw "Unable to create date";
    } else if (!date.isValid) {
        console.log({ value: value, date: date });
        // eslint-disable-next-line
        throw "Invalid DateTime: " + date.invalidExplanation;
    }
    date.setLocale(language);
    return date;
};

export const SessionDateToNew = () => {
    const _sessionDate = SessionDate();
    return new Date(
        `${_sessionDate.toLocaleString({ year: "numeric" })}-${_sessionDate.toLocaleString({
            month: "2-digit",
        })}-${_sessionDate.toLocaleString({ day: "2-digit" })}T${_sessionDate.toLocaleString({
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        })}`
    );
};

// Transitional class from native Date to luxon DateTime
// Warning: do not use this class, use SessionDate() instead
window.ZDate = class {
    timeZone;
    datetime;
    constructor() {
        this.timeZone = SessionTimeZone();
        if (arguments.length > 1) {
            if (arguments.length >= 3 && arguments.length <= 7) {
                this.datetime = DateTime.fromObject({
                    year: arguments[0],
                    month: arguments[1] + 1,
                    day: arguments[2],
                    hour: arguments[3] || 0,
                    minute: arguments[4] || 0,
                    second: arguments[5] || 0,
                    millisecond: arguments[6] || 0,
                });
            } else {
                console.error("Unexpected arguments in new ZDate");
                console.log(arguments);
            }
        } else {
            if (arguments.length > 0) {
                const value = arguments[0];
                if (value === undefined || value === "" || value === null) {
                    this.datetime = DateTime.now();
                } else if (typeof value === "string") {
                    this.datetime = undefined;
                    const formatDates = ["yyyy-MM-dd", "yyyy/MM/dd", "dd/MM/yyyy", "EEE MMM dd yyyy"];
                    const formatTimes = ["", " HH:mm", " HH:mm:ss", " HH:mm:ss.u", " HH:mm:ss ZZZ", " HH:mm:ss.u ZZZ"];
                    formatDates.forEach((dateFmt) => {
                        formatTimes.forEach((timeFmt) => {
                            const fmt = dateFmt + timeFmt;
                            if (!this.datetime || !this.datetime.isValid) {
                                this.datetime = DateTime.fromFormat(value, fmt, {
                                    zone: this.timeZone,
                                    locale: "en-Gb",
                                });
                            }
                        });
                    });
                    if (!this.datetime || !this.datetime.isValid) {
                        this.datetime = DateTime.fromISO(value, { zone: this.timeZone });
                    }
                    if (!this.datetime || !this.datetime.isValid) {
                        this.datetime = DateTime.fromRFC2822(value, { zone: this.timeZone });
                    }
                    if (!this.datetime || !this.datetime.isValid) {
                        this.datetime = DateTime.fromHTTP(value, { zone: this.timeZone });
                    }
                    if (!this.datetime || !this.datetime.isValid) {
                        let date = new Date(value);
                        if (window.ZDate.checkDate(date)) {
                            this.datetime = DateTime.fromISO(date.toISOString(), { zone: this.timeZone });
                        } else {
                            console.warn('Input value "' + value + '" is not a valid Date');
                        }
                    }
                } else if (typeof value === "number") {
                    this.datetime = DateTime.fromMillis(value, { zone: this.timeZone });
                    if (!this.datetime || !this.datetime.isValid) {
                        this.datetime = DateTime.fromSeconds(value, { zone: this.timeZone });
                    }
                } else if (value instanceof window.ZDate) {
                    this.datetime = value.datetime;
                } else if (value instanceof Date) {
                    this.datetime = DateTime.fromISO(value.toISOString(), { zone: this.timeZone });
                } else {
                    let date = new Date(value);
                    if (window.ZDate.checkDate(date)) {
                        this.datetime = DateTime.fromISO(date.toISOString(), { zone: this.timeZone });
                        console.error("ZDate need ISO string or number, not " + typeof value);
                        console.log(value);
                    } else {
                        console.warn('Input value "' + value + '" is not a valid Date');
                    }
                }
            } else {
                this.datetime = DateTime.now();
            }
        }

        if (this.datetime) {
            if (!this.datetime.isValid) {
                console.error("Invalid datetime: " + this.datetime.invalidExplanation);
                console.log(arguments);
            }
            this.datetime = this.datetime.setZone(this.timeZone);
        } else {
            console.error("Invalid ZDate, unknown datetime");
        }

        return this;
    }
    isValid() {
        return this.datetime && this.datetime.isValid;
    }
    get() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime;
    }
    toString() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.toString();
    }

    toLocaleString(locale, params) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        let d = this.datetime;
        if (locale && locale.length > 0) {
            d = d.setLocale(locale);
        } else {
            d = d.setLocale("en-Gb");
        }
        if (params) {
            return d.toLocaleString(params);
        }
        return d.toLocaleString({
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hourCycle: "h23",
        });
    }
    //https://tc39.es/ecma402/#datetimeformat-objects
    toLocaleDateString(locale, params) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        if (params) {
            return this.toLocaleString(locale, params);
        } else {
            let d = this.datetime;
            if (locale && locale.length > 0) {
                d = d.setLocale(locale);
            } else {
                d = d.setLocale("en-Gb");
            }
            return d.toLocaleString({ year: "numeric", month: "2-digit", day: "2-digit" });
        }
    }
    toLocaleTimeString(locale, params) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        if (params) {
            return this.toLocaleString(locale, params);
        } else {
            let d = this.datetime;
            if (locale && locale.length > 0) {
                d = d.setLocale(locale);
            } else {
                d = d.setLocale("en-Gb");
            }
            return d.toLocaleString({ hour: "2-digit", minute: "2-digit", hourCycle: "h23" });
        }
    }
    toISOString() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.toUTC().toISO();
    }
    toDate() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.toISO({ includeOffset: false });
    }
    setFullYear(value) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        this.datetime = this.datetime.set({ year: value });
        return this;
    }
    setHours(value) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        this.datetime = this.datetime.set({ hour: value });
        return this;
    }
    setMilliseconds(value) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        this.datetime = this.datetime.set({ millisecond: value });
        return this;
    }
    setMinutes(value) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        this.datetime = this.datetime.set({ minute: value });
        return this;
    }
    setMonth(value) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        this.datetime = this.datetime.set({ month: value + 1 });
        return this;
    }
    setSeconds(value) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        this.datetime = this.datetime.set({ second: value });
        return this;
    }
    setDate(value) {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        this.datetime = this.datetime.set({ day: value });
        return this;
    }
    getDay() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.weekday;
    }
    getFullYear() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.year;
    }
    getHours() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.hour;
    }
    getMilliseconds() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.millisecond;
    }
    getMinutes() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.minute;
    }
    getMonth() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.month - 1;
    }
    getSeconds() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.second;
    }
    getDate() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.day;
    }
    getTime() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.toMillis();
    }
    getUTCFullYear() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.toUTC().year;
    }
    getTimezoneOffset() {
        if (!this.datetime) {
            return "Invalid ZDate";
        }
        return this.datetime.offset * -1;
    }
    getTimezone() {
        return this.timeZone;
    }

    static now() {
        return DateTime.now().setZone(SessionTimeZone()).toMillis();
    }
    static checkDate(date) {
        if (!date || !(date instanceof Date)) {
            return false;
        }
        return !isNaN(date.getTime());
    }
};

export default SessionDate;
