const DeleteAccessProfile = (id, name) => {
    return [
        {
            title: ["delete", name],
            text: "delete-access-profile-description",
            id: id,
            executeSectionQuery: true,
            buttons: [
                { name: "cancel", style: "white", action: "close" },
                {
                    name: "delete",
                    style: "red",
                    action: "delete-access-profile",
                },
            ],
        },
    ];
};

export default DeleteAccessProfile;
