import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Session } from "../../hooks/Utils/Session";
import { useTranslation } from "react-i18next";
import { useShowReleaseNotes } from "../Header/IconInfo";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";
import { setWarnings, setWarningsMinimized } from "../../actions/uiActions";
import { parseBoolean } from "../../hooks/Utils/Utils";

const Warning = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { showReleaseNotes } = useShowReleaseNotes();

    //Store data
    const { impersonated } = useSelector((state) => state.auth);
    const { warnings } = useSelector((state) => state.ui);

    //State
    const [minimized, setMinimized] = useState(false);
    const [height, setHeight] = useState("h-20");

    const UPDATE_USER = gql`mutation {
        updateUser(
                ref: "${Session.getSessionProp("userRef")}"
                advicedUpdate: true
            ) {
                error
                ok
            }
        }`;

    const [executeMutation] = useMutation(UPDATE_USER, {
        onError(err) {
            console.log(err);
        },
    });

    //Listeners
    useEffect(() => {
        if (Session.getSessionProp("userWarnings")) {
            const warnings = JSON.parse(Session.getSessionProp("userWarnings"));
            dispatch(setWarnings(warnings && warnings.length > 0 ? warnings : null));
        }

        if (
            !Session.getSessionProp("superUser") &&
            Session.getSessionProp("userAdvicedUpdate") &&
            !parseBoolean(Session.getSessionProp("userAdvicedUpdate"))
        ) {
            //mark user as notified only if not impersonating
            if (!impersonated) {
                executeMutation();
            }
            Session.setSessionProp("userAdvicedUpdate", "true");
            showReleaseNotes();
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setHeight(minimized ? "h-8" : "h-20");

        if (Session.getSessionProp("userWarnings")) {
            dispatch(setWarningsMinimized(minimized));
        }
        // eslint-disable-next-line
    }, [minimized]);

    return (
        <>
            {warnings && warnings[0] ? (
                <div id="warnings" className={`relative z-100 w-full bg-blue-100 ${height}`}>
                    <div className={` ${height} z-10 w-full flex items-center text-white`}>
                        <div
                            className={`icon clickable icon-chevron${minimized ? "" : "-up"}  text-2xl p-4`}
                            onClick={(e) => setMinimized(!minimized)}
                        ></div>
                        <div>
                            <div
                                className={`icon icon-alert bg-white text-orange-100  ${
                                    minimized ? "text-lg" : "text-2xl"
                                } m-4 mr-3`}
                                style={{ borderRadius: "16rem 16rem 16rem 16rem" }}
                            ></div>
                        </div>
                        {warnings[0].type === "SYSTEM_UPDATE" ? (
                            <div>
                                <div className={`first-capital ${minimized ? "text-base" : "text-lg font-bold"}`}>
                                    {t("system update")}
                                </div>
                                {!minimized ? (
                                    <div className="">
                                        <span>{warnings[0].message}</span>
                                        <span
                                            className="pl-4 link clickable underline text-white"
                                            onClick={(e) => showReleaseNotes()}
                                        >
                                            {" "}
                                            {t("{{capitalize, capitalize}}", {
                                                capitalize: t("read more"),
                                            })}
                                            .
                                        </span>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default Warning;
