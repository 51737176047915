import React, { useRef } from "react";

import { useTranslation } from "react-i18next";
import LogoPreview from "../Section/Design/Appearances/LogoPreview";
import useComponentVisible from "../useComponentVisible";

function useSelectOptionWithPreview({ index, option, selected, select, disabled }) {
    //Store data
    const { t } = useTranslation();
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    let showTooltipTO = useRef();
    let isComponentVisibleRef = useRef(isComponentVisible);
    isComponentVisibleRef.current = isComponentVisible;

    const showTooltip = (event) => {
        setIsComponentVisible(true);
        showTooltipTO.current = setTimeout(function () {
            if (isComponentVisibleRef.current) {
                const el = document.querySelector(`#preview-logo-type-${index}`);
                if (el) {
                    el.classList.remove("hidden");
                    el.style.top = event.pageY + "px";
                    el.style.left = event.pageX + "px";
                }
            }
        }, 1000);
    };
    const hideTooltip = () => {
        setIsComponentVisible(false);
        isComponentVisibleRef.current = false;
        clearTimeout(showTooltipTO.current);
        const el = document.querySelector(`#preview-logo-type-${index}`);
        el.classList.add("hidden");
    };
    const moveTooltip = (event) => {
        const el = document.querySelector(`#preview-logo-type-${index}`);
        el.style.top = event.pageY + 6 + "px";
        el.style.left = event.pageX + "px";
    };

    const nothingHappens = (event) => {
        event.stopPropagation();
        event.preventDefault();
    };

    const display = option ? option.display || option.value : null;
    return (
        <div
            className={`h-11 w-full  ${disabled ? "cursor-default " : "cursor-pointer hover:bg-gray-200"} ${
                selected && selected.value === option.value ? "hover:bg-gray-200" : ""
            }`}
            onMouseOver={disabled ? null : (event) => showTooltip(event)}
            onMouseLeave={disabled ? null : () => hideTooltip()}
            onMouseMove={disabled ? null : (event) => moveTooltip(event)}
            onClick={disabled ? (event) => nothingHappens(event) : () => select()}
            id={`option-with-preview-container-${index}`}
        >
            <div
                className={`t-select-option p-2 h-full ${
                    selected && selected.value === option.value ? "font-bold text-blue-100" : ""
                } ${disabled ? "text-gray-600" : "hover:text-blue-100"}`}
                name={option.value}
                value={option.value}
                id={`option-with-preview-${index}`}
            >
                <div className="mx-auto w-2/6 h-full">
                    {option.asset ? (
                        <img
                            id={`option-with-preview-img-${index}`}
                            alt={t("logo")}
                            src={option.asset}
                            style={{ maxWidth: "6.5rem", maxHeight: "1.8rem" }}
                        />
                    ) : (
                        <LogoPreview logoType={option.value} className="mr-4 rounded" style={{ padding: 0 }} />
                    )}
                </div>
                <div className="mx-auto w-4/6" id={`option-with-preview-text-${index}`}>
                    {display ? t(display) : null}
                </div>
            </div>
            <div
                id={`preview-logo-type-${index}`}
                className={`fixed top-auto bg-white text-gray-900 font-normal text-center border-gray-300 border shadow mt-4 p-3 text-base hidden items-center align-middle`}
            >
                <div className="mx-auto px-12">
                    {option.asset ? (
                        <img alt={t("logo")} src={option.asset} />
                    ) : (
                        <LogoPreview
                            logoType={option.value}
                            size={10}
                            className="mx-auto rounded"
                            style={{ width: "15rem", height: "8rem" }}
                        />
                    )}
                </div>
                <div className="mx-auto mt-4">{display ? t(display) : null}</div>
            </div>
        </div>
    );
}

export default useSelectOptionWithPreview;
