const translate = (term) => {
    const translations = {
        //Main menú

        //No COVID
        hotel: "Hotel",
        guest: "Huésped",
        design: "Diseño",
        tv: "TV",
        advertising: "Publicidad",
        mobile: "Móvil",

        //COVID
        clients: "Clientes",
        customers: "Clientes",
        "loyal-customers": "Clientes registrados",
        "common-zones": "Zonas comunes",
        devices: "Dispositivos",
        chat: "Chat",

        //Sidebar

        //No COVID
        installation: "Instalación",
        monitoring: "Monitorización",
        map: "Mapa",
        "wifi-networks": "Redes WiFi",
        rooms: "Habitaciones",
        "service-config": "Configuración de servicios",

        //COVID
        "clients-list": "Listado de clientes",
        preckecking: "Pre-Checking",
        client: "Cliente",
        booking: "Reservas",
        "capacity-monitor": "Monitorización de aforo",
        "areas-activities": "Áreas y actividades",
        keys: "Llaves",
        "remote-controllers": "Mandos",
        configuration: "Configuración",
        "assigned-hotels": "Propiedades asignados",
        cleaning: "Limpieza",
        staff: "Staff",
        prechecking: "Pre check-in",
        "booking-list": "Listado de reservas",

        //Page headers
        "list-of": "Listado de",
        "map-of": "Mapa de",
        "monitoring-of": "Monitorización de",
        export: "Exportar",
        add: "Nuevo",
        erase: "Eliminar",

        //Login
        "sign-in": "Acceder",
        email: "Email",
        password: "Contraseña",
        "welcome-zafiro": "Bienvenido a ZAFIRO",
        "password-reminder": "¿Has olvidado la contraseña?",
        "error-email": "Por favor, compruebe el email, no es correcto.",
        "error-pass": "Por favor, compruebe la contraseña, no es correcta.",
        "access-granted": "Muchas gracias, acceso autorizado",
        "select-hotel": "Selecciona el hotel",
        "no-hotels-available": "Lo sentimos, no hemos encontrado ningún hotel disponible",
        "accessing-hotel": "Accediendo al hotel...",
        "enter-logout": "Por favor, escoja una opción",
        logout: "Cerrar sesión",

        //General Errors
        "error-booking-category-list": "Lo sentimos, en este momento no es posible mostrar las categorías de reservas.",
        "error-cleaning-list": "Lo sentimos, en este momento no es posible mostrar la información de limpieza.",
        "error-czcategory-list": "Lo sentimos, en este momento no es posible mostrar las categorías de zonas comunes.",
        "error-projects": "Lo sentimos, no ha sido posible obtener sus hoteles.",
        "error-customers-list": "Lo sentimos, no ha sido posible obtener la información de clientes.",
        "error-staff-list": "Lo sentimos, no ha sido posible obtener la información del staff.",

        //Toasts
        "operation-successful": "Las modificaciones se han realizado con éxito.",
        "wrong-credentials": "Usuario o contraseña no válidos.",
        "hotel-selected": "Se ha seleccionado el hotel",

        //General
        male: "Señor",
        female: "Señora",
        "close-session": "Cerrar sesión",
        lang: "Idioma",
        //Language selection
        "select-language": "Selecciona tu idioma",
        //customers
        "title-treatment": "Tratamiento",
        "title-birthDate": "Nacimiento",
        "title-phone": "Teléfono",
    };
    return translations[term];
};

export default translate;
