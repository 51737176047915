import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeActionValues } from "../../actions/globalActions";
import _ from "lodash";

const UseModalTranslations = ({ index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    //Store
    const { data, name } = useSelector((state) => state.ui.modalContent.inputs[index]);
    const { validations } = useSelector((state) => state.action);
    const { projectLangs } = useSelector((state) => state.ui);

    //States
    const [items, setItems] = useState([]);
    const [defaultLang, setDefaultLang] = useState(null);
    const [placeHolder, setPlaceHolder] = useState("");

    //Listeners
    useEffect(() => {
        let { items, defaultLang } = arrangeItems(projectLangs, data && data.translations ? data.translations : null);
        setItems(items);
        setDefaultLang(defaultLang);
        setPlaceHolder(defaultLang ? defaultLang.value : "");
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(changeActionValues({ [name]: items }));
        // eslint-disable-next-line
    }, [items]);

    //Arrange functions
    const arrangeItems = (projectLangs, translations) => {
        let items = [];
        let defaultLang = null;
        if (projectLangs && projectLangs.length > 0) {
            projectLangs.map((projectLang) => {
                projectLang.langName = t("language:" + projectLang.languageRef);
            });
            projectLangs = _.orderBy(projectLangs, ['langName'],['asc']);
            projectLangs.forEach((projectLang, index) => {
                let item = {
                    id: null,
                    value: "",
                    languageRef: projectLang.languageRef,
                    isDefault: projectLang.isDefault,
                };
                let itemTranslation = translations
                    ? translations.filter((translation) => translation.languageRef === item.languageRef)
                    : null;
                if (itemTranslation && itemTranslation.length > 0) {
                    itemTranslation = itemTranslation[0];
                    item.id = itemTranslation.id;
                    item.value = itemTranslation.value;
                }
                items.push(item);
                if (item.isDefault) {
                    item.index = index;
                    defaultLang = item;
                }
            });
        }
        return { items, defaultLang };
    };

    //handle functions
    const handleChange = (index, name, value) => {
        let itemsClone = [...items];
        itemsClone[index][name] = value;
        setItems(itemsClone);
    };

    return (
        <>
            {defaultLang ? (
                <>
                    <div className="w-full pb-4">
                        <div className="first-capital ">{t("default-lang")}:</div>
                    </div>
                    <div className="w-full flex my-2 items-center pb-4">
                        <div className="w-3/12 pl-3">
                            <span className="font-bold">{t("language:" + defaultLang.languageRef)}</span>
                        </div>
                        <div className="w-9/12">
                            <input
                                type="text"
                                className={`w-full h-10 bg-gray-200 rounded p-2 hover:text-zafiro-400
                                ${
                                    validations && validations[`modalTranslation_value${defaultLang.index}`] === false
                                        ? " border border-red-100 "
                                        : ""
                                }`}
                                value={items[defaultLang.index].value}
                                onChange={(e) => {
                                    setPlaceHolder(e.target.value);
                                    handleChange(defaultLang.index, "value", e.target.value);
                                }}
                            />
                        </div>
                    </div>
                </>
            ) : null}
            {items && items.length > 0 ? (
                <>
                    <div className="w-full">
                        <div className="first-capital pb-4">{t("translations")}:</div>
                    </div>
                    {items.map((item, index) =>
                        !item.isDefault ? (
                            <div className="w-full flex mb-5 items-center">
                                <div className="w-3/12 pl-3">
                                    <span className="font-bold">{t("language:" + item.languageRef)}</span>
                                </div>
                                <div className="w-9/12">
                                    <input
                                        type="text"
                                        className={`w-full h-10 bg-gray-200 rounded p-2 hover:text-zafiro-400
                                        ${
                                            validations && validations[`modalTranslation_value${index}`] === false
                                                ? " border border-red-100 "
                                                : ""
                                        }`}
                                        value={item.value}
                                        onChange={(e) => handleChange(index, "value", e.target.value)}
                                        placeholder={placeHolder}
                                    />
                                </div>
                            </div>
                        ) : null
                    )}
                </>
            ) : null}
        </>
    );
};

export default UseModalTranslations;
