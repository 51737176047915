import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withApollo } from "@apollo/react-hoc";

//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
//Actions

//UTils
import { CSVLink } from "react-csv";
import { Session } from "../../Utils/Session";
import { showGlobalLoading } from "../../../actions/uiActions";
import { capitalizeFirst, removeApolloCacheKeys } from "../../Utils/Utils";

const ExportCustomers = ({ startExport, setStartExport, client }) => {
    //Constantes
    const cookieCHotel = Session.getSessionProp("currentHotel");
    const HOTEL_NAME = cookieCHotel ? JSON.parse(cookieCHotel).name : "";

    //Store data
    const { langStrings } = useSelector((state) => state.ui);

    //States
    const [sorting] = useState(`,orderBy:{field: "id", criteria: "desc"}`);
    const [exportData, setExportData] = useState([]);
    const [exportHeaders, setExportHeaders] = useState([]);

    //Actions
    const dispatch = useDispatch();

    const GET_CUSTOMER_INFO = gql`{
        customers: customers(page:1, size: 10000${sorting}){
        info { count }
        results { id ref name surname email signupApp signupWifi gender birthDate phone country language lastAccess termsAccepted logins{app date wifi}}}
        mobileApps: mobileApps{
        results { name shortName url }}
        properties: properties{
        results{ ref id name }}
    }`;

    const [executeQuery, dataExport] = useLazyQuery(GET_CUSTOMER_INFO);

    useEffect(() => {
        if (startExport) {
            dispatch(showGlobalLoading(true));
            executeQuery();
        }
        // eslint-disable-next-line
    }, [startExport]);

    useEffect(() => {
        if (dataExport && dataExport.data && dataExport.data.customers && dataExport.data.customers.results) {
            const customers = dataExport.data.customers.results;
            setExportHeaders([
                { label: capitalizeFirst(langStrings["email"]), key: "email" },
                { label: capitalizeFirst(langStrings["name"]), key: "name" },
                { label: capitalizeFirst(langStrings["surname"]), key: "surname" },
                { label: capitalizeFirst(langStrings["signUp"]), key: "signUp" },
                { label: capitalizeFirst(langStrings["accesses-text"]), key: "access" },
            ]);
            let rows = [];
            customers.map((customer) => rows.push(arrangeCustomerData(customer, dataExport.data)));

            setExportData(rows);
            dispatch(showGlobalLoading(false));
            setTimeout(function () {
                document.querySelector("#exportLink").click();
                //remove apollo cache for next downloads
                removeApolloCacheKeys(client.cache, "customers");
                setStartExport(false);
            }, 200);
        }
        // eslint-disable-next-line
    }, [dataExport.data]);

    const arrangeCustomerData = (customer, data) => {
        const mobileApps = data.mobileApps ? data.mobileApps.results : [];
        const properties = data.properties ? data.properties.results : [];
        let signUpApp =
            customer.signupApp &&
            mobileApps &&
            mobileApps.filter((mobileApp) => mobileApp.url === customer.signupApp).length > 0
                ? mobileApps.filter((mobileApp) => mobileApp.url === customer.signupApp)[0].name
                : customer.signupWifi &&
                  properties &&
                  properties.filter((property) => property.ref === customer.signupWifi).length > 0
                ? properties.filter((property) => property.ref === customer.signupWifi)[0].name
                : "";
        let accessApps = [];
        if (
            customer.logins &&
            customer.logins.length > 0 &&
            ((mobileApps && mobileApps.length > 0) || (properties && properties.length > 0))
        ) {
            // eslint-disable-next-line
            customer.logins.map((login) => {
                let mobileAppName =
                    login.app && mobileApps && mobileApps.filter((mobileApp) => mobileApp.url === login.app).length > 0
                        ? mobileApps.filter((mobileApp) => mobileApp.url === login.app)[0].name
                        : login.wifi &&
                          properties &&
                          properties.filter((property) => property.ref === login.wifi).length > 0
                        ? properties.filter((property) => property.ref === login.wifi)[0].name
                        : "";
                if (accessApps.filter((accessApp) => accessApp === mobileAppName).length < 1) {
                    accessApps.push(mobileAppName);
                }
            });
        }
        return {
            email: customer.email ? customer.email : "",
            name: customer.name ? customer.name : "",
            surname: customer.surname ? customer.surname : "",
            signUp: signUpApp,
            access: accessApps.join(","),
        };
    };

    return (
        <CSVLink
            id={"exportLink"}
            className="hidden"
            data={exportData}
            headers={exportHeaders}
            filename={`${HOTEL_NAME ? HOTEL_NAME : "hotel"}-customers.csv`}
        ></CSVLink>
    );
};

export default withApollo(ExportCustomers);
