import React from "react";
import { formatDate } from "./services/formatDate";
import { useTranslation } from "react-i18next";
import { Warning } from "./Warning";
import { NetworkHeader } from "./NetworkHeader";

export const Traffic = ({ mdnsInfo, called, loading, networkInfo }) => {
    const { t } = useTranslation();
    const { anomalousTrafficData, lastUpdate, updateTimeMin } = mdnsInfo || networkInfo || {};
    const {
        ipListTrafficFromOtherNetwork,
        isRoutingToCastNetwork,
        isRoutingToGuestNetwork,
        isTrafficFromOtherNetwork,
    } = anomalousTrafficData || {};
    const lastUpdateDate = formatDate(lastUpdate);

    const criticalWarningText = isRoutingToGuestNetwork ? t("routing-alert-security") : t("routing-alert-guest");

    if (loading || !called) return null;

    return (
        <div className={`bg-white ${!networkInfo ? "border-r" : ""} `}>
            {!networkInfo ? <div className="border-t border-gray-300 ml-8"></div> : null}
            <section className={`p-4`}>
                <NetworkHeader
                    title="network-traffic"
                    lastUpdateDate={lastUpdateDate}
                    updateTimeMin={updateTimeMin}
                    adjustLastUpdate={"mr-4"}
                />
                <section className="mb-10">
                    {isTrafficFromOtherNetwork && (isRoutingToCastNetwork || isRoutingToGuestNetwork) ? (
                        <>
                            <Warning text={t("routing-alert")} color={"red"} ml={4} />
                            <span className="ml-10 mt-2 text-gray-800 w-2/3 block">{criticalWarningText}</span>
                            <div className="flex ml-2 flex-col mt-4">
                                <Warning text={t("traffic-detected")} color="orange" />
                                <ul id="another-traffic-detected">
                                    {ipListTrafficFromOtherNetwork?.map((warning) => (
                                        <li className="text-gray-800 ml-10" key={warning}>
                                            - {t("ip")}: {warning}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    ) : !isTrafficFromOtherNetwork && !isRoutingToCastNetwork && !isRoutingToGuestNetwork ? (
                        <p className="mx-4 text-gray-700">{t("anomalous-traffic")}</p>
                    ) : isRoutingToCastNetwork || isRoutingToGuestNetwork ? (
                        <>
                            <Warning text={t("routing-alert")} color={"red"} ml={4} />
                            <span className="ml-10 mt-2 text-gray-800 w-2/3 block">{criticalWarningText}</span>
                        </>
                    ) : isTrafficFromOtherNetwork ? (
                        <>
                            <div className="flex ml-2 flex-col">
                                <Warning text={t("traffic-detected")} color="orange" />
                                <ul id="another-traffic-detected">
                                    {ipListTrafficFromOtherNetwork?.map((warning) => (
                                        <li className="text-gray-800 ml-10" key={warning}>
                                            - {t("ip")}: {warning}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    ) : null}
                </section>
            </section>
        </div>
    );
};
