import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Actions
import { setSpecificDays } from "../../actions/globalActions";
//Components
import DayPicker, { DateUtils } from "react-day-picker";
import UseCalendarNavbar from "./useCalendarNavbar";
//Utils
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";

const useCalendarDays = () => {
    //Store data
    const { lang } = useSelector((state) => state.ui);
    const { specificDates } = useSelector((state) => state.action);

    //States
    const [selectedDays, setSelectedDays] = useState([]);

    //Actions
    const dispatch = useDispatch();

    const handleDayClick = (day, { selected }) => {
        const daysSelected = selectedDays;
        if (selected) {
            const selectedIndex = daysSelected.findIndex((selectedDay) =>
                DateUtils.isSameDay(new window.ZDate(selectedDay), new window.ZDate(day))
            );
            daysSelected.splice(selectedIndex, 1);
        } else {
            daysSelected.push(day.toString());
        }
        const uniqueSelectedDays = daysSelected.filter(
            (daySelected, index, self) => self.indexOf(daySelected) === index
        );
        setSelectedDays(uniqueSelectedDays);
        dispatch(setSpecificDays(uniqueSelectedDays));
    };

    const currentDates = () => {
        //Convert Strings to dates
        let tempArray = [];
        if (specificDates) {
            // eslint-disable-next-line
            specificDates.map((specificDate) => {
                tempArray.push(new window.ZDate(specificDate));
            });
        }
        return tempArray;
    };

    const datosToDisplay = currentDates();

    useEffect(() => {
        setSelectedDays(currentDates());
        // eslint-disable-next-line
    }, []);

    return (
        <div>
            {/*ISO 8601 standard Monday(1) Sunday (7)*/}
            <DayPicker
                firstDayOfWeek={1}
                navbarElement={<UseCalendarNavbar />}
                selectedDays={datosToDisplay}
                onDayClick={handleDayClick}
                localeUtils={MomentLocaleUtils}
                locale={lang}
            />
        </div>
    );
};

export default useCalendarDays;
