const EditNameCategory = (data) => {
    return {
        title: "translate",
        value: data && data.nameCategory ? data.nameCategory : "",
        idCategory: data && data.idCategory ? data.idProduct : "",
        // bodyAdjust: "w-9/12",
        executeSectionQuery: true,
        useGlobalLoading: true,
        inputs: [
            {
                type: "editNameCategory",
                data: data ? data : null,
            },
        ],
        buttons: [
            { name: "cancel", style: "white", action: "close" },
            {
                name: "save",
                style: "blue",
                action: "edit-names-category",
            },
        ],
    };
};

export default EditNameCategory;
