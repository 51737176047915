import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//API
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { useMSQuery } from "../../../hooks/GraphqlCalls/useMSQuery";
//Actions
import { showRowForm } from "../../../actions/sectionActions";
import {
    setAvailableFilters,
    setCount,
    setCountPosition,
    setError,
    setExtendedInfo,
    setHeaders,
    setLoading,
    setSearchable,
    setTableResults,
    cleanActiveFilters,
    setCustomError,
    setRefreshData,
    setActions,
    setCheckable,
    setBatchActive,
    setHeaderButtons,
    setTableType,
    getFilterQuery,
    setCustomConfig,
    setFolder,
    cleanTableStore,
    cleanSortCriteria,
    setInformativeMessage,
} from "../../../actions/tableActions";
//Utils
import GetTableHeaders from "../../Utils/Table/Headers/GetCustomersTableHeaders";
import GetAvailableFilters from "../../Utils/GetAvailableFilters";
import GetTableHeaderButton from "../../Utils/Table/GetTableHeaderButton";
import { CURRENT_GUESTS, FUTURE_GUESTS } from "hooks/Utils/Customers/GuestsUtils";
//Model
import Guest from "./models/Guest";
import { useTranslation } from "react-i18next";
import { POLLINTERVAL_15S } from "../../Utils/Utils";
import { Session } from "hooks/Utils/Session";

const ListGuests = () => {
    const { t } = useTranslation();
    const { currentPage, perPage, filterQuery, sortCriteria, refreshData, activeFilters, searchString, folder } =
        useSelector((state) => state.table);
    const { permissions } = useSelector((state) => state.ui);

    const hasPreCheckInManagement = permissions?.guests?.preCheckin;

    //States
    const [sorting] = useState(`,orderBy:{field: "ID", criteria: "desc"}`);

    //Data
    const sectionName = `list-guests-${folder?.name}`;
    const folders = [
        { ...CURRENT_GUESTS, adjust: "w-44" },
        { ...FUTURE_GUESTS, adjust: "w-44" },
    ];

    //Actions
    const dispatch = useDispatch();

    //API
    const constFilter = "filter:{checkedOut:false}";

    const GET_FUTURE_CHECK_IN = `{
        guests: stayBookings(
            page:${currentPage},
            size:${perPage}${sortCriteria ? sortCriteria : sorting},
            ${
                activeFilters["state"]
                    ? filterQuery.replace(
                          `state : "${activeFilters["state"]}"`,
                          `state : ["${
                              (activeFilters["state"] ? activeFilters["state"].toUpperCase() : "PENDING_COMPLETION",
                              "PENDING_VALIDATION",
                              "PENDING_MODIFICATION",
                              "VALIDATED")
                          }"]`
                      )
                    : filterQuery
                    ? filterQuery.replace(
                          "filter:{",
                          `filter: { state: ["PENDING_COMPLETION","PENDING_VALIDATION","PENDING_MODIFICATION","VALIDATED"],`
                      )
                    : ` filter: {state: ["PENDING_COMPLETION","PENDING_VALIDATION","PENDING_MODIFICATION","VALIDATED"]}`
            }
          ){
            info{
              count
              page
              size
            }
            results{
              id
              code
              reservationDate
              arrivalDate
              departureDate
              numberOfGuests
              numberOfRooms
              pendingChangesSend
              observations
              state
              guests{
                birthDate
                countryRef
                documentExtraInformation
                documentNumber
                documentType
                email
                gender
                guestRole
                id
                languageRef
                name
                phoneNumber
                stayBookingRoomIndex
                surname
              }
            }
          }
    }`;

    const GET_CURRENT_GUESTS = `{
        guests: guests(
            page: ${currentPage}, 
            size: ${perPage}${sortCriteria ? sortCriteria : sorting}${
        filterQuery ? `${filterQuery.slice(0, -1)}, checkedOut:false}` : constFilter
    } mainGuest:true ){
          info { 
            count 
            }
          results { 
            birthDate 
            countryRef 
            email 
            id 
            languageRef  
            name 
            phone 
            surname 
            loyaltyRef 
            rooms{
                StayGuestRoomID 
                roomID 
                number 
                stayFrom 
                stayTo
            } 
            guestGroups{ 
                id 
                name
            }
        }
    }
      
}`;

    const GET_GUESTS_INFO = gql`
        ${folder?.name == "currents" ? GET_CURRENT_GUESTS : GET_FUTURE_CHECK_IN}
    `;

    const FILTERS_INFO = gql`
        {
            rooms: guests(mainGuest: true, filter: { checkedOut: false }) {
                results {
                    rooms {
                        roomID
                        name
                        number
                    }
                    guestGroups {
                        id
                        name
                    }
                }
            }
        }
    `;

    const [executeQuery, { data, loading, error, refetch, networkStatus }] = useMSQuery(GET_GUESTS_INFO, {
        pollInterval: POLLINTERVAL_15S,
        fetchPolicy: "no-cache",
        nextFetchPolicy: "no-cache",
    });
    const [executeFilterQuery, filtersData] = useLazyQuery(FILTERS_INFO, {
        pollInterval: 60000,
    });

    //Listeners
    useEffect(() => {
        executeQuery();
        executeFilterQuery();
        if (hasPreCheckInManagement) {
            dispatch(
                setCustomConfig({
                    folders: folders,
                })
            );
        }

        const isAFolderGuest = folders.some((f) => f?.name === folder?.name);

        if (isAFolderGuest) {
            dispatch(setFolder(folder));
        } else {
            dispatch(setFolder(folders[0]));
        }
        // eslint-disable-next-line
        return () => {
            dispatch(cleanTableStore());
        };
    }, []);

    useEffect(() => {
        dispatch(getFilterQuery(activeFilters));
    }, [activeFilters, dispatch]);

    useEffect(() => {
        if (refreshData) {
            dispatch(setLoading(true));
            refetch();
            dispatch(setRefreshData(false));
        }
        // eslint-disable-next-line
    }, [refreshData, dispatch]);

    useEffect(() => {
        if (
            !loading &&
            !error &&
            data &&
            data.guests &&
            filtersData.data &&
            filtersData.data.rooms &&
            networkStatus === 7
        ) {
            //Get data
            const dataItems = arrangeData(data, folder);
            const tableHeaders = GetTableHeaders(sectionName);
            const availableFilters = GetAvailableFilters(
                sectionName,
                extractRoomsList(filtersData.data.rooms),
                extractGroupList(filtersData.data.rooms)
            );
            //Add to store
            dispatch(setLoading(true));
            dispatch(setTableType(sectionName));
            dispatch(setSearchable(true));
            dispatch(setHeaders(tableHeaders));
            dispatch(setHeaderButtons(GetTableHeaderButton(sectionName)));
            dispatch(setAvailableFilters(availableFilters));
            dispatch(setTableResults(dataItems));
            dispatch(setCheckable(true));
            dispatch(setBatchActive(BatchActions(folder?.name)));
            dispatch(setCount(data.guests.info.count));
            dispatch(setCountPosition("table-header"));
            dispatch(setExtendedInfo(false));
            dispatch(showRowForm(false));
            if (filterQuery === "") {
                dispatch(cleanActiveFilters());
            }
            if (dataItems && dataItems[0] && dataItems[0].actions) {
                dispatch(setActions());
            }
            dispatch(setCustomError(searchString !== null ? t("no-results-found") : t("no guests available")));
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line
    }, [data, filtersData.data, networkStatus, dispatch]);

    useEffect(() => {
        dispatch(setError(error));
        // eslint-disable-next-line
    }, [error, dispatch]);

    useEffect(() => {
        dispatch(cleanActiveFilters());
        dispatch(setLoading(true));
        dispatch(setTableResults([]));
        dispatch(cleanSortCriteria());
        if (folder?.name === FUTURE_GUESTS.name) {
            dispatch(setInformativeMessage("bookings-remain-30-days"));
        } else {
            dispatch(setInformativeMessage(""));
        }
    }, [folder]);
};

const arrangeData = (data, folder) => {
    const guestsData = [];
    // eslint-disable-next-line
    data?.guests?.results?.map((user) => {
        guestsData.push(Guest(user, folder?.name));
    });
    return guestsData;
};

const BatchActions = (folder) => {
    const actions =
        folder === "currents"
            ? [
                  {
                      name: "assign-to-group",
                      action: "batch-assign-to-group",
                      modalInputs: [
                          {
                              type: "void",
                              adjust: "first-capital",
                              text: "guests-to-add-to-group",
                          },
                          {
                              type: "batchSelection",
                              style: "mt-0 table",
                              columnPos: 0,
                          },
                          {
                              type: "void",
                              adjust: "first-capital",
                              style: "mb-0 mt-4",
                              text: "select-group",
                          },
                          {
                              selectPlaceHolder: "guest-groups",
                              name: "guest-groups",
                              optionsQuery: `{
                                data: guestGroups{
                                info { count }
                                results { id name  }
                                }  
                            }`,
                              oneSelected: true,
                              ph: "select-group",
                              cStyle: "w-full",
                              sort: true,
                              type: "selectMultipleWithSearch",
                          },
                      ],
                  },
                  {
                      name: "checkout",
                      action: "checkout",
                      modalInputs: [
                          // {
                          //     type: "void",
                          //     adjust: "first-capital",
                          //     text: "guests-to-checkout",
                          // },
                          {
                              type: "checkout",
                              style: "mt-0 table",
                              data: { isBatch: true, checkAll: true },
                              columnPos: 0,
                          },
                      ],
                  },
              ]
            : [
                  {
                      name: "delete",
                      action: "delete-pre-check-ins",
                      modalInputs: [
                          {
                              type: "void",
                              adjust: "first-capital",
                              text: "delete-pre-check-in-confirm",
                          },
                          {
                              type: "batchSelection",
                              style: "mt-0 table",
                              columnPos: 0,
                          },
                      ],
                  },
              ];
    return actions;
};

const extractRoomsList = (data) => {
    let roomArray = [];
    data.results.map((item) =>
        item.rooms.map((room) =>
            roomArray.filter((r) => r.id === room.roomID).length === 0
                ? roomArray.push({
                      id: room.roomID,
                      value: room.number,
                      visible: true,
                  })
                : null
        )
    );

    roomArray.sort(function (a, b) {
        return a.value - b.value;
    });

    return roomArray;
};

const extractGroupList = (data) => {
    let groupsArray = [];
    data.results.map((item) =>
        item.guestGroups.map((group) =>
            groupsArray.filter((r) => r.id === group.id).length === 0
                ? groupsArray.push({
                      id: parseInt(group.id),
                      value: group.name,
                      visible: true,
                  })
                : null
        )
    );
    groupsArray.sort((a, b) => a.value.localeCompare(b.value));
    return groupsArray;
};

export default ListGuests;
