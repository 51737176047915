import { useLazyQuery } from "react-apollo";
import { gql } from "apollo-boost";

const useProjectConfig = ({ concept, pkg }) => {
    const query = gql`
        query projectConfig($concept: String) {
            projectConfig(concept: $concept) {
                results {
                    concept
                    package
                    value
                }
            }
        }
    `;
    const [load, { data, loading, error, called }] = useLazyQuery(query, {
        fetchPolicy: "cache-first",
        variables: { concept },
    });

    const configResults = pkg
        ? data?.projectConfig?.results?.filter((c) => c?.package === pkg)
        : data?.projectConfig?.results;

    // Specific value for concept + package item
    const conceptValue =
        concept && pkg && Array.isArray(configResults)
            ? configResults.find((c) => c?.concept === concept)?.value
            : null;

    return {
        called,
        load,
        loading,
        error,
        ready: called && !loading && !error,
        data: concept && pkg ? conceptValue : configResults,
    };
};

export default useProjectConfig;
