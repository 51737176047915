import React, { useEffect } from "react";
import UseTable from "../../../Table";
import UseSectionHeader from "../../../useSectionHeader";
import ListShop from "../../../../hooks/GraphqlCalls/Sales/ListShop";
import UpdateShop from "../../../../hooks/GraphqlCalls/Sales/UpdateShop";
import { setSaveHeadMenu } from "../../../../actions/sectionActions";
import { useDispatch } from "react-redux";
import UpdateShopZafiro from "../../../../hooks/GraphqlCalls/Sales/UpdateShopZafiro";
const Shops = () => {
    const dispatch = useDispatch();

    const breadcrumbs = [
        {
            name: "services",
            translate: true,
            route: "/services/",
        },
        {
            name: "sales",
            translate: true,
            route: "/services/",
        },
        {
            name: "shop",
            translate: true,
            route: "/services/sales/shop",
        },
    ];

    useEffect(() => {
        dispatch(setSaveHeadMenu(null));
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <UseSectionHeader title={["shop-list"]} customBreadCrumbs={breadcrumbs} />
            <UseTable />
            <UpdateShop />
            <UpdateShopZafiro />
            <ListShop />
        </>
    );
};

export default Shops;
