import React from "react";
import { Session } from "../../hooks/Utils/Session";

const Support = () => {
    return (
        <>
            <iframe
                title="manage3.8.3"
                className="w-full h-full absolute"
                src={`${Session.getLSProp("supportLink")}`}
            ></iframe>
        </>
    );
};

export default Support;
