import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
//Components
import DayPicker from "react-day-picker";
//Actions
import { setDateFilter } from "../../actions/tableActions";
//Utils
import MomentLocaleUtils from "react-day-picker/moment";
import "moment/locale/es";

const UseInputCalendar = ({ setIsComponentVisible }) => {
    //Store data
    const { langStrings, lang } = useSelector((state) => state.ui);
    const { dateFilter } = useSelector((state) => state.table);

    //states
    const [calendarLang, setCalendarLang] = useState("");

    //Actions
    const dispatch = useDispatch();

    const handleDayClick = (day) => {
        dispatch(setDateFilter(day));
        setIsComponentVisible(false);
    };

    useEffect(() => {
        if (lang) {
            setCalendarLang(lang);
        }
    }, [lang]);

    return (
        <>
            {/*ISO 8601 standard Monday(1) Sunday (7)*/}
            <DayPicker
                showOutsideDays
                firstDayOfWeek={1}
                navbarElement={Navbar}
                //month={new window.ZDate(2017, 4, 5)}
                todayButton={langStrings["today"]}
                onTodayButtonClick={(day) => handleDayClick(day)}
                onDayClick={(day) => handleDayClick(day)}
                selectedDays={dateFilter !== "" ? dateFilter : null}
                onDayChange={(day) => handleDayClick(day)}
                localeUtils={MomentLocaleUtils}
                locale={calendarLang}
            />
        </>
    );
};

function Navbar({ onPreviousClick, onNextClick }) {
    const styleLeft = {
        float: "left",
    };
    const styleRight = {
        float: "right",
    };
    return (
        <div className="absolute h-10 w-full">
            <div
                style={styleLeft}
                className="px-2 pt-6 icon-chevron-left cursor-pointer hover:text-blue-200 text-2xl text-gray-600"
                onClick={() => onPreviousClick()}
            ></div>
            <div
                style={styleRight}
                className="px-2 pt-6 icon-chevron-right cursor-pointer hover:text-blue-200 text-2xl text-gray-600"
                onClick={() => onNextClick()}
            ></div>
        </div>
    );
}

export default UseInputCalendar;
