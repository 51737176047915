import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate as useHistory } from "react-router-dom";
import UseButton from "./useButton";
import UseInputText from "./Table/useInputText";
import UseSelect from "./Inputs/useSelect";
import UseLabel from "./Inputs/useLabel";
import UseSwitch from "./Inputs/useSwitch";
import UseCheckBox from "./Inputs/useCheckBox";
import UseInputNumber from "./Table/useInputNumber";

import { setActionInput } from "../actions/globalActions";

function UseNetworkCard({ network }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const { langStrings } = useSelector((state) => state.ui);
    const [connected, setConnected] = useState(network && network.status ? network.status.value : null);
    const [rebootEnabled, setRebootEnabled] = useState(network && network.rebootEnabled ? network.rebootEnabled : null);
    const NETWORKSETTING = "networkSettings";
    const NETWORKSETTINGNOCAPITALIZE = "networkSettingsNoCapitalize";
    const handleSwitchOnOff = () => {
        setConnected(!connected);
    };
    const handleCheckboxReboot = () => {
        setRebootEnabled(!rebootEnabled);
    };

    const handleClick = (networkId) => {
        dispatch(setActionInput({ save: true }));
        dispatch(setActionInput({ networkQuery: networkId }));
    };

    const showStatusSwitch = network.status !== undefined;
    const activeCheckboxReboot = network.id === "ccdailyreboot";
    const isAReportCCStatus = network.id === "reportccstatus";
    return (
        <div className={`${network.adjust} bg-white text-center h-auto pt-12 pb-6 px-2 rounded relative`}>
            {showStatusSwitch ? (
                <div className="text-right w-fit-content align-middle absolute right-4 top-4">
                    <UseSwitch checked={network.status.value} action={handleSwitchOnOff} id={network.status.id} />
                </div>
            ) : null}
            <div className="w-96 m-auto">
                {/* ICON */}
                <div
                    className={`networkIcon ${
                        showStatusSwitch ? (connected ? "networkIconOn" : "networkIconOff") : ""
                    }`}
                >
                    <div className=" -mt-2">
                        <i className={`icon-${network.icon} -mt-20 text-4xl m-auto text-white`}></i>
                    </div>
                </div>
                {/* TITLE */}
                <h1 className="font-bold text-3xl mt-3">{langStrings[network.title]}</h1>
                {/* DESCRIPTION */}
                <div className="font-normal text-gray-800">
                    {langStrings[network.description ? network.description : `${network.title}-description`]}
                </div>
                {/* SUBTITLE */}
                <h1 className="font-bold text-base mt-3">{network.subtitle}</h1>
                {/* FIELDS */}
                <div
                    className={`m-auto mt-5 grid grid-flow-row gap-y-4 ${
                        isAReportCCStatus ? " w-12/12 -ml-3" : "w-9/12"
                    }`}
                >
                    {network.fields.map((field, index) => {
                        switch (field.type) {
                            case "hidden":
                                return (
                                    <div key={index} className="hidden">
                                        <UseInputText
                                            inputData={{ name: field.id }}
                                            validation={true}
                                            currentValue={field.value}
                                        />
                                    </div>
                                );
                            case "input":
                                return (
                                    <div key={index}>
                                        <UseLabel label={field.name} zoneStyles={NETWORKSETTING} translatable={true} />
                                        <div className="mt-1">
                                            <UseInputText
                                                id={field.id}
                                                inputData={{ name: field.id }}
                                                ph={langStrings[field.name]}
                                                validation={true}
                                                currentValue={field.value}
                                            />
                                        </div>
                                    </div>
                                );
                            case "inputNumber":
                                return (
                                    <div key={index}>
                                        <UseLabel
                                            label={field.name}
                                            zoneStyles={
                                                field.id === "wifipmsaccessuploadbw" ||
                                                field.id === "wifipmsaccessdownloadbw" ||
                                                field.id === "wifipmsaccessmaxdevices"
                                                    ? NETWORKSETTINGNOCAPITALIZE
                                                    : NETWORKSETTING
                                            }
                                            translatable={true}
                                        />
                                        <div className="mt-1">
                                            <UseInputNumber
                                                inputData={{ name: field.id }}
                                                ph={langStrings[field.name]}
                                                validation={true}
                                                currentValue={field.value}
                                                valueHandler={field.valueHandler ? field.valueHandler : null}
                                                maxNumber={9999}
                                            />
                                        </div>
                                    </div>
                                );
                            case "checkbox":
                                return (
                                    <div key={index} className={"min-h-4 table"}>
                                        <UseCheckBox
                                            id={field.id}
                                            checked={field.value}
                                            name={field.name}
                                            enabled={field.enabled}
                                            translatable={true}
                                            onClick={handleCheckboxReboot}
                                            useAllSpace={isAReportCCStatus}
                                        />
                                    </div>
                                );
                            case "select":
                                return (
                                    <div key={index}>
                                        <UseLabel label={langStrings[field.name]} zoneStyles={NETWORKSETTING} />
                                        <UseSelect
                                            selectData={field}
                                            enabled={activeCheckboxReboot ? rebootEnabled : field.enabled}
                                        />
                                    </div>
                                );
                            case "text":
                                return (
                                    <div key={index}>
                                        <UseLabel label={field.name} zoneStyles={NETWORKSETTING} translatable={true} />
                                        <div className="mt-1">
                                            <div className="text-left text-gray-800 w-full bg-gray-200 rounded p-2  long">
                                                {field.value}
                                            </div>
                                        </div>
                                    </div>
                                );
                            default:
                                break;
                        }
                        return null;
                    })}
                    {network.save ? (
                        <div className="w-32 mt-3 mx-auto" onClick={() => handleClick(network.id)}>
                            <UseButton id={`${network.id}-save-button`} buttonName="save" buttonColor="btn-blue" />
                        </div>
                    ) : network.redirect ? (
                        <div
                            id={`${network.id}-redirect-button`}
                            className="mt-3 mx-auto"
                            onClick={() => history(network.redirectValue)}
                        >
                            <UseButton buttonName={network.redirectNameButton} adjust="px-3" buttonColor="btn-blue" />
                        </div>
                    ) : (
                        <div className="pt-5"></div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default UseNetworkCard;
