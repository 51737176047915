import FilterCCsGuestUsersContext from "components/Section/Hotel/context/FilterCCsGuestUsersContext";
import { capitalizeFirst } from "hooks/Utils/Utils";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const SearchDropDown = ({ filterData, title }) => {
    const [showFilter, setShowFilter] = useState(false);
    const [value, setValue] = useState(filterData?.models);
    const [subValue, setSubValue] = useState(filterData?.subModels);
    const [selectedItems, setSelectedItems] = useState([]);
    const [search, setSearch] = useState("");
    const [valueCopy, setValueCopy] = useState(value);
    const { setModel } = useContext(FilterCCsGuestUsersContext);
    const { t } = useTranslation();

    const handleOnChange = (name, id) => {
        const updatedModel = value.map((item) => {
            if (name === item.name && id === item.id) {
                const updatedSubModel = subValue.filter((submodel) => submodel.id === item.id);
                updatedSubModel.map((sub) => (sub.isChecked = !item.isChecked));
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });
        const updatedCopyModel = valueCopy.map((item) => {
            if (name === item.name && id === item.id) {
                const updatedSubModel = subValue.filter((submodel) => submodel.id === item.id);
                updatedSubModel.map((sub) => (sub.isChecked = !item.isChecked));
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });
        const selected = subValue.filter((sub) => sub.isChecked);
        setSelectedItems(selected);
        setModel(selected);
        setValue(updatedModel);
        setValueCopy(updatedCopyModel);
    };

    useEffect(() => {
        return () => {
            setModel([]);
        };
    }, []);

    const handleSubOnChange = (name, id) => {
        const updatedSubModel = subValue.map((item) => {
            if (name === item.name && id === item.id) {
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });

        setSelectedItems(updatedSubModel.filter((sub) => sub.isChecked));
        setModel(updatedSubModel.filter((sub) => sub.isChecked));
        updatedModelIfAllSubModelChecked(updatedSubModel, value);
        setSubValue(updatedSubModel);
    };
    const updatedModelIfAllSubModelChecked = (subModels, models) => {
        const updatedModel = models.map((model) => {
            const subModel = subModels.filter((sub) => sub.id === model.id);
            const allSubModelChecked = subModel.every((sub) => sub.isChecked);

            return { ...model, isChecked: allSubModelChecked };
        });

        setValue(updatedModel);
        setValueCopy(updatedModel);
    };

    const handleRemoveSelectedItem = (index) => {
        const updatedSubModel = subValue.map((item) => {
            if (selectedItems[index].name === item.name && selectedItems[index].id === item.id) {
                return { ...item, isChecked: !item.isChecked };
            }
            return item;
        });
        updatedModelIfAllSubModelChecked(updatedSubModel, value);
        setSubValue(updatedSubModel);
        setSelectedItems(updatedSubModel.filter((sub) => sub.isChecked));
        setModel(updatedSubModel.filter((sub) => sub.isChecked));
    };

    const handleClick = () => {
        setShowFilter(!showFilter);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showFilter && !event.target.closest(".bg-gray-200")) {
                setShowFilter(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showFilter]);

    useEffect(() => {
        const filteredValue = value?.filter((item) => {
            const subItems = subValue?.filter((sub) => sub.id === item.id);
            const subItemsMatch = subItems?.some((sub) => sub.name.toLowerCase().includes(search.toLowerCase()));
            return item.name.toLowerCase().includes(search.toLowerCase()) || subItemsMatch;
        });
        setValueCopy(filteredValue);
        if (search === "") {
            setValueCopy(value);
        }
    }, [search, value, subValue]);

    return (
        <div className="flex items-center my-8 relative">
            <div>
                <div className="mx-2 p-2 bg-gray-200 rounded relative">
                    <div onClick={handleClick} className="flex items-center justify-between cursor-pointer w-48">
                        {selectedItems?.length > 0 ? (
                            <p className="ml-2 text-black">
                                {selectedItems?.length} {t("selected")}
                            </p>
                        ) : (
                            <p className="ml-2 text-gray-600">{title}</p>
                        )}
                        <span
                            className={`${showFilter ? "-rotate-180 mr-4" : ""} icon-chevron animated field-icon`}
                        ></span>
                    </div>
                    {showFilter ? (
                        <div className="absolute bg-white border p-4 mt-2 top-10 w-full rounded-md z-100 max-h-40 overflow-y-scroll">
                            <div className="w-full relative">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="rounded-md py-1 px-2 mb-4 w-full bg-gray-200 "
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <span className="field-icon icon-search" style={{ top: "-6px" }}></span>
                            </div>
                            {selectedItems?.length > 0 ? (
                                <>
                                    <h5 className="mb-2 font-bold">{capitalizeFirst(t("selected"))}:</h5>
                                    <div className="flex flex-wrap gap-2 mb-6">
                                        {selectedItems?.map((item, index) => (
                                            <div
                                                className="bg-zafiro-400 items-center gap-2 rounded-lg px-2 py-1 flex text-white"
                                                key={item.id}
                                            >
                                                <span key={item.id}>{item.name}</span>
                                                <span
                                                    className="icon-remove text-white cursor-pointer"
                                                    onClick={() => handleRemoveSelectedItem(index)}
                                                ></span>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            ) : null}
                            {valueCopy?.map((item, index) => (
                                <div className="flex justify-center flex-col cursor-pointer" key={item?.name}>
                                    <div className="flex items-center mb-4">
                                        <input
                                            id={item?.name}
                                            type="checkbox"
                                            className={`w-5 h-5 checkbox z-50 block checkbox mr-2 truncate ${
                                                item.isChecked ? "block" : "hidden"
                                            }`}
                                            value={item?.name}
                                            checked={valueCopy[index].isChecked}
                                            onChange={() => handleOnChange(item.name, item.id)}
                                        />
                                        <span
                                            id={item?.name}
                                            className={`w-5 h-5 bg-gray-200 hover:bg-gray-300 ${
                                                !item.isChecked ? "block" : "hidden"
                                            } mr-2 rounded-sm`}
                                            onClick={() => handleOnChange(item.name, item.id)}
                                        ></span>
                                        <span className="hover:text-zafiro-400 font-bold text-gray-900">
                                            {item?.name}
                                        </span>
                                    </div>
                                    {subValue
                                        ?.filter((submodel) => submodel?.id === item?.id)
                                        .map((sub, index) => (
                                            <div className="ml-6 mb-4 cursor-pointer" key={sub?.name}>
                                                <div className="flex items-center">
                                                    <input
                                                        id={sub?.id}
                                                        type="checkbox"
                                                        className={`w-5 h-5 checkbox z-50 block checkbox absolute left-8 ${
                                                            sub?.isChecked ? "block" : "hidden"
                                                        }`}
                                                        checked={sub.isChecked}
                                                        onChange={() => handleSubOnChange(sub.name, sub.id)}
                                                    />
                                                    <span
                                                        id={sub.id}
                                                        className={`w-5 h-5 bg-gray-200 hover:bg-gray-300 absolute left-8 ${
                                                            !sub?.isChecked ? "block" : "hidden"
                                                        } mr-4`}
                                                        onClick={() => handleSubOnChange(sub.name, sub.id)}
                                                    ></span>
                                                    <span className="hover:text-zafiro-400 ml-6">{sub?.name}</span>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
