import React, { useContext } from "react";

import { useTranslation } from "react-i18next";
import { EditorContext } from "../../../../../contexts/editor";
import Widget from "../../../../Widget";
import { vw2px } from "../../../../../hooks/Utils/DesignUtils";
import TopBar from "./topbar";
import { coverElement, highlightElement, coverColor, highlightColor } from "./utils";
import { capitalizeFirst } from "../../../../../hooks/Utils/Utils";

const ShopOrders = ({ device: forceDeviceType, width: forceWidth, templating }) => {
    const { t: tr } = useTranslation();
    const { lang, deviceType, editorSize } = useContext(EditorContext);

    const t = (label, data) => {
        return tr(label, { lng: lang, ...(data || {}) });
    };

    const deviceProps = {
        device: forceDeviceType || deviceType,
        deviceWidth: forceWidth || editorSize?.width,
    };

    const size = (s) => vw2px(s, deviceProps.deviceWidth, 3);

    const css = templating?.css;

    const highlightActive = css?.highlight;
    const hlBackground = highlightActive === "general";
    const hlSection1 = highlightActive === "section1";

    const styles = {
        global: {
            fontFamily: templating?.textFont?.name,
            color: css?.fgColor,
            backgroundColor: highlightActive && !hlBackground ? coverColor(css?.bgColor) : css?.bgColor,
            textAlign: "left",
            width: "100%",
            minHeight: "100%",
            cursor: "default",
        },
    };

    const itemProps = {
        ...deviceProps,
        type: "SHOPORDERITEM",
        config: {
            highlightColor: highlightColor,
            name: t("shop-name"),
            number_product: t("number-products"),
            price_product: "00,00 €",
            delivery_place: t("delivery-place"),
            date_abbreviation: ` hh:mm ${t("date-abbreviation")}`,
            state: capitalizeFirst(t("state")),
            highlightActive: highlightActive,
        },
        style: {
            bgColor: css?.items?.details?.bgColor ? css.items.details.bgColor : null,
            fgColor: css?.items?.details?.fgColor ? css.items.details.fgColor : null,
        },
        texts: {
            name: t("shop-name"),
            delivery: t("delivery-time"),
        },
    };

    const renderShopItems = () => {
        return new Array(5).fill(null).map((_, index) => (
            <div key={index}>
                {index === 0 ? (
                    <div
                        style={{
                            height: size(1.5),
                            width: "100%",
                            backgroundColor: "transparent",
                            ...(highlightActive && hlBackground ? highlightElement() : null),
                        }}
                    ></div>
                ) : null}
                <div
                    style={{
                        ...((highlightActive && hlBackground) || (highlightActive && hlSection1)
                            ? coverElement()
                            : null),
                    }}
                >
                    <Widget {...itemProps} />
                </div>
                <div
                    style={{
                        height: size(1),
                        width: "100%",
                        backgroundColor: "transparent",
                        ...(highlightActive && hlBackground ? highlightElement() : null),
                    }}
                ></div>
            </div>
        ));
    };

    return (
        <div style={styles.global}>
            <div
                style={{
                    position: "sticky",
                    zIndex: 10,
                    top: 0,
                }}
            >
                <TopBar
                    title={t("my-orders")}
                    config={{ search: false }}
                    device={deviceType}
                    width={forceWidth}
                    templating={templating}
                />
            </div>
            <div style={{ position: "relative", padding: `0 ${size(1.5)}` }}>{renderShopItems()}</div>
        </div>
    );
};

export default ShopOrders;
