import React, { useContext, useEffect, useState } from "react";
import { NetworkAlertsContext } from "contexts/NetworkWarnings/NetworkAlerts";

export const DropdownMonitor = ({ title, children, type }) => {
    const CAST = "cast";
    const USER = "user";
    const [isOpen, setIsOpen] = useState(true);
    const [castWarning, setCastWarning] = useState();
    const [userWarning, setUserWarning] = useState();
    const {
        dhcpCriticalWarning,
        mdnsCriticalWarning,
        networkCriticalWarning,
        dhcpLowWarning,
        mdnsLowWarning,
        networkLowWarning,
    } = useContext(NetworkAlertsContext);
    const openDropDown = () => setIsOpen(!isOpen);

    useEffect(() => {
        if (dhcpCriticalWarning || mdnsCriticalWarning) {
            setCastWarning(<span className={`icon icon-warning text-red-100`}></span>);
        } else if (dhcpLowWarning || mdnsLowWarning) {
            setCastWarning(<span className={`icon icon-warning text-orange-100`}></span>);
        }
    }, [dhcpCriticalWarning, mdnsCriticalWarning, , dhcpLowWarning, mdnsLowWarning]);

    useEffect(() => {
        if (networkCriticalWarning) {
            setUserWarning(<span className={`icon icon-warning text-red-100`}></span>);
        } else if (networkLowWarning) {
            setUserWarning(<span className={`icon icon-warning text-orange-100`}></span>);
        }
    }, [networkLowWarning, networkCriticalWarning]);

    return (
        <>
            <div className={`bg-white ${isOpen ? "rounded-t-md" : "rounded-md"} mt-8`}>
                <div className="p-8 flex gap-2 items-center">
                    <div
                        className={`icon-chevron  mt-2 mr-2 cursor-pointer text-gray-800 text-xl animated ${
                            isOpen && "-rotate-180"
                        }`}
                        id="chevron-dropdown"
                        onClick={openDropDown}
                    />
                    <h1 className="font-bold text-base">{title}</h1>
                    {type === CAST ? (castWarning && !isOpen ? castWarning : null) : null}
                    {type === USER ? (userWarning && !isOpen ? userWarning : null) : null}
                </div>
                <div className={`${isOpen && "border-b border-gray-300"} mx-8`}></div>
            </div>
            {isOpen ? <>{children}</> : null}
        </>
    );
};
